import {ODIcons, ODIcon, ODVSpace} from '@mdpp/od-react-belt'
import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import moment from 'moment/moment'

import { GQLNotice } from '@mdpp/common/lib/@types/server.schema'
import { WebUtils } from '../../../utils/webUtils'
import { EvixCard } from '../../../common/card/EvixCard'
import { EvixCardHeader } from '../../../common/card/EvixCardHeader'
import {COLORS} from '../../../styles/colors'
interface IRecentNoticeListProps {
  notices: GQLNotice[]
  viewItemLink: (id: number) => string
  viewMoreLink: string
}

export const RecentNoticeList: React.FC<IRecentNoticeListProps> = props => {
  const { notices, viewMoreLink, viewItemLink } = props
  return (
    <EvixCard>
      <EvixCardHeader title='공지사항' moreView link={viewMoreLink} />
      <NoticeList>
        {notices.map(notice => {
          const now = moment()
          const lastDate = moment(notice.createdAt).add(2, 'weeks')
          const isNew = lastDate.diff(now) > 0
          return (
            <Link to={viewItemLink(notice.noticeId)} style={{ textDecoration: 'none' }} key={notice.noticeId}>
              <NoticeRow>
                {isNew ? (
                  <>
                    <NewNoticeTitle>{notice.title}</NewNoticeTitle>
                    <NewStatus>NEW</NewStatus>
                  </>
                ) : (
                  <NoticeTitle>{notice.title}</NoticeTitle>
                )}
                <NoticeTime>{WebUtils.formatDateTime(notice.createdAt)}</NoticeTime>
              </NoticeRow>
            </Link>
          )
        })}
      </NoticeList>
    </EvixCard>
  )
}

const NewStatus = styled.span`
  border: 1px solid;
  border-radius: 5px;
  padding: 2px 5px;
  height: 18px;
  line-height: 1;
  margin-right: auto;
  color: ${COLORS.red100};
  font-size: 12px;
  font-weight: 600;
  margin-top: 3px;
`
const NoticeList = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 24px;
`

const NoticeRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 12px 15px;
  border-bottom: solid 1px var(--gray200);
  :hover {
    background-color: #F6F7F8;
  }
`
const NoticeTitle = styled.div`
  font-size: 15px;
  color: var(--gray800);
  /* color: var(--gray700); */
  cursor: pointer;
  :hover {
    background-color: rgb(244, 246, 248); 
  }
`
const NewNoticeTitle = styled.div`
  font-size: 15px;
  color: var(--gray800);
  cursor: pointer;
  margin-right: 5px;
`
const NoticeTime = styled.div`
  font-size: 14px;
  color: var(--gray600);
`