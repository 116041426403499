import React from 'react'
import { Modal } from 'reactstrap'
import styled from 'styled-components'
import { COLORS } from '../../../styles/colors'
import {
  ButtonWrapper,
  ConfirmTitle,
  ConfirmTitleWrap,
  Container,
  Content,
  Header,
  Wrapper,
} from '../../style/ModalStyle'
import IconClose from '../../../assets/common/icon-close.svg'
import IconWarningBlue from '../../../assets/common/warning-blue.svg'
import { SwitchWrap, ToggleLabel } from '../../style/CommonStyle'
import Toggle from 'react-toggle'
import { EvixButton, EvixButtonTheme } from '../../EvixButton'

type ODModalSelectorProps = {
  style: object
}

type ODModalCompProps = {
  isOpen: boolean
}

export type OPEN_STATUS = {
  smartTreatment: boolean
  untactTreatment: boolean
}

export function useIsOpenModal(options: ODModalSelectorProps) {
  const { style } = options
  const [smartTreatment, setSmartTreatment] = React.useState(false)
  const [untactTreatment, setUntactTreatment] = React.useState(false)
  const [isOpen, setIsOpen] = React.useState(false)
  const onClickYes = React.useRef<() => void>(() => undefined)
  const onClickNo = React.useRef<() => void>(() => undefined)
  const onSmartToggle = React.useRef<() => void>(() => undefined)
  const onUntactToggle = React.useRef<() => void>(() => undefined)
  const smartTreatmentRef = React.useRef(false)
  const untactTreatmentRef = React.useRef(false)
  const hospitalNameRef = React.useRef('')
  const doctorNameRef = React.useRef('')

  const choose = React.useCallback(
    (
      smartIsOn: boolean,
      treatmentIsOn: boolean,
      hospitalName: string,
      doctorName: string
    ): Promise<OPEN_STATUS | null> => {
      return new Promise(resolve => {
        setSmartTreatment(smartIsOn)
        setUntactTreatment(treatmentIsOn)
        smartTreatmentRef.current = smartIsOn
        untactTreatmentRef.current = treatmentIsOn
        doctorNameRef.current = doctorName
        hospitalNameRef.current = hospitalName

        onSmartToggle.current = () => {
          setSmartTreatment(!smartTreatmentRef.current)
          smartTreatmentRef.current = !smartTreatmentRef.current
        }

        onUntactToggle.current = () => {
          setUntactTreatment(!untactTreatmentRef.current)
          untactTreatmentRef.current = !untactTreatmentRef.current
        }

        onClickYes.current = () => {
          setIsOpen(false)
          resolve({
            smartTreatment: smartTreatmentRef.current,
            untactTreatment: untactTreatmentRef.current,
          })
        }
        onClickNo.current = () => {
          setIsOpen(false)
          resolve(null)
        }
        setIsOpen(true)
      })
    },
    []
  )

  const [Component] = React.useState(() => {
    return (props: ODModalCompProps) => (
      <Modal isOpen={props.isOpen} toggle={() => onClickNo.current()} style={style}>
        <Wrapper>
          <Header>
            <img src={IconClose} onClick={() => onClickNo.current()} alt="close icon" />
          </Header>

          <Container className="pd40">
            <Content>
              <ConfirmTitleWrap className="center">
                <ConfirmTitle>공개여부 변경</ConfirmTitle>
              </ConfirmTitleWrap>

              <InfoWrap>
                <img src={IconWarningBlue} alt="icon warning" />
                <span>
                  {doctorNameRef.current} / {hospitalNameRef.current}
                </span>
              </InfoWrap>

              <Box>
                <div>
                  <BoxTitle>스마트 진료</BoxTitle>
                  <TitleInfo>공개 / 비공개 설정</TitleInfo>
                </div>
                <SwitchWrap style={{ marginTop: 0 }}>
                  <Toggle checked={smartTreatmentRef.current} icons={false} onChange={() => onSmartToggle.current()} />
                  <ToggleLabel>
                    <span>비공개</span>
                    <span>공개</span>
                  </ToggleLabel>
                </SwitchWrap>
              </Box>
              <Box className="mg-b40">
                <div>
                  <BoxTitle>비대면 진료</BoxTitle>
                  <TitleInfo>공개 / 비공개 설정</TitleInfo>
                </div>
                <SwitchWrap style={{ marginTop: 0 }}>
                  <Toggle
                    checked={untactTreatmentRef.current}
                    icons={false}
                    onChange={() => onUntactToggle.current()}
                  />
                  <ToggleLabel>
                    <span>비공개</span>
                    <span>공개</span>
                  </ToggleLabel>
                </SwitchWrap>
              </Box>
            </Content>

            {/* 버튼 영역 */}
            <ButtonWrapper className="pd0">
              <EvixButton
                theme={EvixButtonTheme.H48WhiteInline}
                style={{ flex: 1 }}
                onClick={() => onClickNo.current()}
                disabled={false}
              >
                {'취소'}
              </EvixButton>
              <EvixButton
                theme={EvixButtonTheme.H48BlackInline}
                style={{ flex: 1 }}
                onClick={() => onClickYes.current()}
                disabled={false}
              >
                {'변경'}
              </EvixButton>
            </ButtonWrapper>
          </Container>
        </Wrapper>
      </Modal>
    )
  })

  return { Component, choose, props: { isOpen } }
}

const InfoWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 4px;
  padding-bottom: 10px;
  padding-top: 22px;

  > img {
    width: 13px;
    height: 13px;
  }

  > span {
    font-size: 13px;
    color: ${COLORS.GRAY42};
    line-height: 24px;
    font-weight: 500;
  }
`

const Box = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  border-radius: 4px;
  border: solid 1px #e0e1e4;
  height: 100px;
  padding: 20px;
  margin-bottom: 10px;

  &.mg-b40 {
    margin-bottom: 40px;
  }
`

const BoxTitle = styled.div`
  font-size: 15px;
  color: ${COLORS.GRAY42};
  line-height: 1;
  font-weight: bold;
  padding-bottom: 10px;
`

const TitleInfo = styled.div`
  font-size: 13px;
  color: ${COLORS.GRAY42};
  line-height: 1;
  font-weight: 500;
`
