import { ODVSpace } from '@mdpp/od-react-belt'
import React from 'react'
import styled from 'styled-components'
import { EvixCard } from '../../../common/card/EvixCard'
import { WaitingPharmacyProfileEdit } from '../../../common/waitingPharmacy/detail/WaitingPharmacyProfileEdit'

interface IAdminPharmacistProfilePageProps {
  waitingPharmacistId: number
  fromManager?: boolean
  fromAdmin?: boolean
}

export const AdminWaitingPhramacyProfilePage: React.FC<IAdminPharmacistProfilePageProps> = props => {
  const { waitingPharmacistId, fromManager = false, fromAdmin = true } = props

  return (
    <EvixCard>
      <Wrapper>
        <WaitingPharmacyProfileEdit
          fromManager={fromManager}
          fromAdmin={fromAdmin}
          waitingPharmacistId={waitingPharmacistId}
        />
        <ODVSpace h={24} />
      </Wrapper>
    </EvixCard>
  )
}

const Wrapper = styled.div`
  padding: 10px;
`
