import { ODHSpace, ODIcon, ODIcons, useODMutation, useODQuery } from '@mdpp/od-react-belt'
import moment from 'moment-timezone'
import React, { useEffect } from 'react'
import styled, { css } from 'styled-components'
import {
  GQLChats,
  GQLChatsSendInput,
  GQLListableChatsInput,
  GQLReservation,
  GQLRESERVATION_STATUS,
} from '@mdpp/common/lib/@types/server.schema'
import { EvixChatInputBox } from '../../../../common/chat/EvixChatInputBox'
import {
  EvixChatMessageType,
  EvixChatMessageWrapper,
  IEvixChatMessageNormal,
} from '../../../../common/chat/EvixChatMessageWrapper'
import { GQL_GET_CHATS, GQL_SEND_CHATS } from '../../../../common/gqls_common'
import { useInterval } from '../../../../utils/useInterval'
import { uniqBy } from 'lodash'

import { ImageButton, BP } from '../../../../common/RebeccaButton'
import SVGClose from '../../../../assets/room/close.svg'
import SVGMessage from '../../../../assets/room/send_message.svg'
import TESTImg from './send_message.svg'
import TT from '../../../../assets/win_pc.svg'

import SVGChat from '../../../../assets/room/chat.svg'
import SVGNewChat from '../../../../assets/room/chat_new.svg'


interface IRoomChatSectionProps {
  reservation: GQLReservation
  chatRoomId: number | null
  finishedMedical?: boolean
}

const Wrapper = styled.div`
  bottom: 0;
  background: white;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  // width: 100%;
  // height: 100vh;
  // max-width: 379px;
  max-width: 392px;
  min-width: 392px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1), 0px 8px 40px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  margin: 20px 16px 20px 0px;

  ${(p: { finishedMedical?: boolean }) =>
    p.finishedMedical &&
    css`
      position: static;
      flex: 1;
      width: auto;
      border: 0;
      box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.15);
      border-radius: 5px;
    `}

  > :not(:first-child) {
    margin-top: 10px;
  }
`

const ChatScrollArea = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;

  > :not(:first-child) {
    margin-top: 30px;
  }
`

const ChatNotAvailable = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 75px;
  background-color: var(--gray200);
  font-size: 14px;
  color: var(--gray500);
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
`

const ChatHeader = styled.header`
  display: flex;
  width: 100%;
  height: 66px;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #E1E3E5;
`

const ChatText = styled.span`
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: -0.5px;
  margin: 21px 0px 21px 16px;
`

const ChatIconWrapper = styled.div`
  & > i {
    width: 17px;
    height: 17px;
    font-size: 17px;
    color: var(--mpPrimary500);
  }
`

const ImgTest = styled.button`
  width: 100px;
  height: 36px;
  background-size: 24px 24px;
  background-repeat: no-repeat;
  background-image: url(${TT});
  background-color: red;
  
  &:hover {
    background-image: url(${SVGMessage});
  }
`

const IconWrapper = styled.div`
  margin: 23px 19px 0px 0px;
  display: flex;
  justify-content: center;
  align-items: start;
`

const chatButton = {
  backgroundPositionX: 'center',
  backgroundSize: 32,
  width: 48,
  height: 48,
  padding: 16,
}

type ListChatsResponse = ODListableResponseType<GQLChats>

type ODListableResponseType<T> = {
  list: Array<T>
  totalCount: number
  page: number
  pageSize: number
}

export const RoomChatSection: React.FC<IRoomChatSectionProps> = props => {
  const { reservation, chatRoomId, finishedMedical } = props
  const scrollTarget = React.useRef<HTMLDivElement | null>(null)

  const isChatAvailable = reservation?.status === GQLRESERVATION_STATUS.IN_PROGRESS
  const apiGetChats = useODQuery<GQLListableChatsInput, ListChatsResponse>(GQL_GET_CHATS)
  const apiSendMessage = useODMutation<GQLChatsSendInput, GQLChats>(GQL_SEND_CHATS)

  const [chats, setChats] = React.useState<GQLChats[]>([])
  const [showChat, setShowChat] = React.useState(false)
  const [newChat, setNewChat] = React.useState(false)

  const chatImg = {
    default: SVGChat,
  }

  const newChatImg = {
    default: SVGNewChat,
  }

  const closeImg = {
    default: SVGClose,
  }

  const scrollToBottom = () => {
    const node: HTMLDivElement | null = scrollTarget.current

    if (node) {
      node.scrollTop = node.scrollHeight
    }
  }

  const callApiGetChats = async () => {
    if (!chatRoomId) {
      return
    }

    const lastChatsId = chats.length > 0 ? chats[chats.length - 1].chatId : null
    const result = await apiGetChats({ chatRoomId, lastChatsId, page: null, pageSize: null, filter: null })

    if (result.list.length > 0) {
      if (showChat) {
        setChats(old => uniqBy([...old, ...result.list], 'chatId'))
        scrollToBottom()
        setNewChat(false)
      } else {
        setNewChat(true)
      }
    }
  }

  const handleSendMessage = async (message: string) => {
    if (!chatRoomId) {
      return
    }
    await apiSendMessage({ chatRoomId, message })
    await callApiGetChats()
  }

  const handleAttachFile = (file: File) => {
    // console.log(25)
  }

  useInterval(async () => {
    await callApiGetChats()
  }, 3000)

  useEffect(() => {
    ;(async () => {
      await callApiGetChats()
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getTime = (t: any) => {
    const now = moment();
    const created = moment(t)
    const time = now.minute() - created.minute()
    return time
  }

  return (
    <>
    {showChat ? (
      <Wrapper finishedMedical={finishedMedical}>
      <ChatHeader>
        <ChatText>채팅</ChatText>
        <ImageButton type={BP.TYPE.InvisibleWithIcon} images={closeImg} onClick={() => (setShowChat(false))} style={{marginRight: 16}}></ImageButton>
      </ChatHeader>
      <ChatScrollArea ref={scrollTarget}>
        {chats.map(chat => {
          if (chat.sender === 'DOCTOR') {
            const message: IEvixChatMessageNormal = {
              messageType: EvixChatMessageType.Normal,
              isMine: true,
              // name: chat.reservation.doctor.name,
              name: '',
              // name: 'Doctor',
              // time: moment(chat.createdAt).format('YYYY.MM.DD HH:mm'),
              time: `${getTime(chat.createdAt)}분 전`,
              message: chat.message,
            }
            return <EvixChatMessageWrapper key={`${chat.chatId}`} message={message} />
          } else {
            const message: IEvixChatMessageNormal = {
              messageType: EvixChatMessageType.Normal,
              isMine: false,
              name: chat.reservation.patient.name ?? 'patient',
              // time: moment(chat.createdAt).format('YYYY.MM.DD HH:mm'),
              time: `${getTime(chat.createdAt)}분 전`,
              message: chat.message,
            }
            return <EvixChatMessageWrapper key={`${chat.chatId}`} message={message} />
          }
        })}
      </ChatScrollArea>
      {isChatAvailable && <EvixChatInputBox onSendMessage={handleSendMessage} onAttachFile={handleAttachFile} />}
      {!isChatAvailable && <ChatNotAvailable>사용 가능한 상태가 아닙니다.</ChatNotAvailable>}
    </Wrapper>
    )
  :
  <IconWrapper>
    <ImageButton type={BP.TYPE.InvisibleWithIcon} images={newChat ? newChatImg : chatImg} onClick={() => (setShowChat(true))} style={chatButton}></ImageButton>
  </IconWrapper>
  }
  </>
  )
}
