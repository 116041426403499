import React from 'react'
import moment from "moment/moment"

export const PERIOD = {
  ALL: '전체',
  HALF_YEAR: '6개월',
  YEAR: '1년',
  SELECT: '선택',
}
interface FilterProps {
  startDate : string
  endDate : string
  onPeriodChanged : (period: string, startDate: string, endDate: string) => void // 전체, 1년, 6개, 상세 선택
  onStatusChanged : (status: string[]) => void // 입원, 외래, 조제, 검진
  onCategoryChanged : (category: string[]) => void // 투약, 검사, 시술 등 처방
}

export const Filter: React.FC<FilterProps> = props => {
  const { onPeriodChanged, onStatusChanged, onCategoryChanged } = props
  const [filterStartDate, setFilterStartDate] = React.useState('')
  const [filterEndDate, setFilterEndDate] = React.useState('')
  const [period, setPeriod] = React.useState<string>('전체')
  const [status, setStatus] = React.useState<string[]>(['입원', '외래', '조제', '검진'])
  const [category, setCategory] = React.useState<string[]>(['투약', '검사', '시술 등 처방'])

  React.useEffect(() => {
    if(filterStartDate === '' && props.startDate)
      setFilterStartDate(props.startDate);
    if(filterEndDate === '' && props.endDate)
      setFilterEndDate(props.endDate)
  }, [props.startDate, props.endDate])

  React.useEffect(() => {
    onPeriodChanged(period, filterStartDate, filterEndDate)
  }, [period, filterStartDate, filterEndDate])

  React.useEffect(() => {
    onStatusChanged(status)
  }, [status])

  React.useEffect(() => {
    onCategoryChanged(status)
  }, [category])

  const removeStatusItem = (delItem : string) => {
    const newArr = status.filter(item => item !== delItem)
    setStatus(newArr);
  };

  const getStatusItem = () => {
    return (
        <div className="tab-wrap">
          <div className={ status.includes('입원') ? "tab black selected" : "tab black"} onClick={(e) =>  {
            status.includes('입원') ? removeStatusItem('입원') : setStatus([...status, '입원'])
          }}>
            <span>입원</span>
          </div>
          <div className={ status.includes('외래') ? "tab black selected" : "tab black"} onClick={(e) => {
            status.includes('외래') ? removeStatusItem('외래') : setStatus([...status, '외래'])
          }}>
            <span>외래</span>
          </div>
          <div className={ status.includes('조제') ? "tab black selected" : "tab black"} onClick={(e) => {
            status.includes('조제') ? removeStatusItem('조제') : setStatus([...status, '조제'])
          }}>
            <span>조제</span>
          </div>
          <div className={ status.includes('검진') ? "tab black selected" : "tab black"} onClick={(e) => {
            status.includes('검진') ? removeStatusItem('검진') : setStatus([...status, '검진'])
          }}>
            <span>검진</span>
          </div>
        </div>
    )
  }

  const removeCategoryItem = (delItem : string) => {
    const newArr = category.filter(item => item !== delItem)
    setCategory(newArr);
  };

  const getCategoryItem = () => {
    return (
        <div className="tab-wrap">
          <div className={category.includes('투약') ? "tab black selected" : "tab black"} onClick={(e) => {
            category.includes('투약') ? removeCategoryItem('투약') : setCategory([...category, '투약'])
          }}>
            <span>투약</span>
          </div>
          <div className={category.includes('검사') ? "tab black selected" : "tab black"} onClick={(e) => {
            category.includes('검사') ? removeCategoryItem('검사') : setCategory([...category, '검사'])
          }}>
            <span>검사</span>
          </div>
          <div className={category.includes('시술 등 처방') ? "tab black selected" : "tab black"} onClick={(e) => {
            category.includes('시술 등 처방') ? removeCategoryItem('시술 등 처방') : setCategory([...category, '시술 등 처방'])
          }}>
            <span>시술 등 처방</span>
          </div>
        </div>
    )
  }

  return (
    <div className="filter-wrap">
      {/* 기간선택 */}
      <div className="title">기간선택</div>
      <div className="tab-wrap">
        <div className={period === PERIOD.ALL ? "tab selected" : "tab"} onClick={(e) => {
          setPeriod(PERIOD.ALL)
        }}>
          <span>전체</span>
        </div>
        <div className={period === PERIOD.YEAR? "tab selected" : "tab"} onClick={(e)=> {
          setPeriod(PERIOD.YEAR)
        }}>
          <span>1년</span>
        </div>
        <div className={period === PERIOD.HALF_YEAR ? "tab selected" : "tab"} onClick={(e) => {
          setPeriod(PERIOD.HALF_YEAR)
        }}>
          <span>6개월</span>
        </div>
        <div className={period === PERIOD.SELECT ? "tab selected" : "tab"} onClick={(e) => setPeriod(PERIOD.SELECT)}>
          <span>선택</span>
        </div>
      </div>
      {/* 기간 영역 */}
      {period === '선택' &&
        <div className="form-wrap">
          <div className="input-block">
            <label className="label focus">시작</label>
            <div className="input-wrap">
              <input type="date" className="input" style={{fontSize: 15, width: 130}} value={moment(filterStartDate).format('yyyy-MM-DD')} onInput={(e: any) => {
                console.log(e.target.value)
                setFilterStartDate(e.target.value)
                setPeriod(PERIOD.SELECT)
              }}/>
            </div>
          </div>
          <span className="dash">-</span>
          <div className="input-block">
            <label className="label focus">종료</label>
            <div className="input-wrap">
              <input type="date" className="input" style={{fontSize: 15, width: 130}} value={moment(filterEndDate).format('yyyy-MM-DD')} onInput={(e: any) => {
                console.log(e.target.value)
                setFilterEndDate(e.target.value)
                setPeriod(PERIOD.SELECT)
              }}/>
            </div>
          </div>
        </div>
      }

      <div className="title">기록구분</div>
      { getStatusItem() }
      {/*<div className="title">항목구분</div>*/}
      {/*{ getCategoryItem() }*/}
    </div>
  )
}
