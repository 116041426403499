import { IODListableEventContext, ODListableOption } from '@mdpp/od-react-belt'
import { GQLDateTime, GQLDoctor, GQLWaitingDoctor } from '@mdpp/common/lib/@types/server.schema'

export const WaitingDoctorPrimaryKey = 'waitingDoctorId'

// modifiable fields only.
export interface ICTWaitingDoctor
  extends Pick<
    GQLWaitingDoctor,
    | 'waitingDoctorId'
    | 'email'
    | 'name'
    | 'licenseName'
    | 'hospitalName'
    | 'cellPhone'
    | 'recommender'
    | 'holdReason'
    | 'birthDate'
  > {
  // more fields, mapped (if needed)
}

export interface IDoctorListableOption extends ODListableOption {
  filter: string
}

export interface IDoctorEntityContext extends IODListableEventContext<GQLWaitingDoctor> {
  //
}
