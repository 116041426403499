import { produce } from 'immer'
import * as React from 'react'
import { GQLEvixAdmin, GQLEvixAdminLoginInput } from '@mdpp/common/lib/@types/server.schema'
import { AppOptions, I_ADMIN_OPTIONS, LOCAL_STORAGE_KEY_TOKEN } from '../../AppOptions'
import { useAdminAPIs } from './useAdminAPIs'

interface IAdminAppContextType {
  state: IAdminAppReducerState
  sideBarState: number
  login: (input: GQLEvixAdminLoginInput) => Promise<GQLEvixAdmin>
  logout: () => Promise<void>
  refreshProfile: () => Promise<GQLEvixAdmin>
  refreshSidebar: () => void
}

export enum LOGIN_STATE {
  Checking = 'Checking',
  LoggedOut = 'LoggedOut',
  LoggingIn = 'LoggingIn',
  LoggedIn = 'LoggedIn',
  LoggingOut = 'LoggingOut',
}

interface IAdminAppContextProviderProps {}

interface IAdminAppReducerState {
  loginState: LOGIN_STATE
  profile: GQLEvixAdmin | null
}

enum AdminAppActionType {
  SetLoggedIn = 'EvixAdmin/SetLoggedIn',
  SetLoggedOut = 'EvixAdmin/SetLoggedOut',
  SetAppConfig = 'EvixAdmin/SetAppConfig',
  SetNodeClick = 'EvixAdmin/SetNodeClick', // whenever directory view's node is clicked, count++
}

interface IAdminAppActionSetLoggedIn {
  type: AdminAppActionType.SetLoggedIn
  profile: GQLEvixAdmin
}

interface IAdminAppActionSetLoggedOut {
  type: AdminAppActionType.SetLoggedOut
}

type AdminAppReducerAction = IAdminAppActionSetLoggedIn | IAdminAppActionSetLoggedOut

interface IAdminAppReducer extends React.Reducer<IAdminAppReducerState, AdminAppReducerAction> {}

function createAppReducer(): IAdminAppReducer {
  return function (state: IAdminAppReducerState, action: AdminAppReducerAction): IAdminAppReducerState {
    return produce(state, draft => {
      switch (action.type) {
        case AdminAppActionType.SetLoggedIn:
          draft.loginState = LOGIN_STATE.LoggedIn
          draft.profile = action.profile
          break
        case AdminAppActionType.SetLoggedOut:
          draft.loginState = LOGIN_STATE.LoggedOut
          break
        default:
          return
      }
    })
  }
}

const actionSetLoggedIn = (profile: GQLEvixAdmin): IAdminAppActionSetLoggedIn => ({
  type: AdminAppActionType.SetLoggedIn,
  profile,
})
const actionSetLoggedOut = (): IAdminAppActionSetLoggedOut => ({ type: AdminAppActionType.SetLoggedOut })

function createInitialAppReducerState(): IAdminAppReducerState {
  return {
    loginState: LOGIN_STATE.Checking,
    profile: null,
  }
}

function createAdminAppContext(appOptions: I_ADMIN_OPTIONS) {
  const Context: React.Context<IAdminAppContextType> = React.createContext<IAdminAppContextType>(
    // tslint:disable-next-line
    {} as IAdminAppContextType
  )

  const DoctorProvider: React.FC<IAdminAppContextProviderProps> = props => {
    const { children } = props

    const { loginAdmin: apiLogin, getAdminProfile: apiGetProfile, logout: apiLogout } = useAdminAPIs()

    const [sideBarState, setSideBarState] = React.useState(0)
    const [state, dispatch] = React.useReducer<IAdminAppReducer>(createAppReducer(), createInitialAppReducerState())
    const setLoggedIn = React.useCallback((profile: GQLEvixAdmin) => dispatch(actionSetLoggedIn(profile)), [dispatch])

    const checkLogin = React.useCallback(async () => {
      try {
        // const appConfig = await apiGetAppConfig()
        // dispatch(actionSetAppConfig(appConfig))

        const profile = await apiGetProfile()
        setLoggedIn(profile)
      } catch (ex) {
        // 로그인 실패, 로그아웃된 상태
        dispatch(actionSetLoggedOut())
      }
    }, [apiGetProfile, setLoggedIn])

    const login = React.useCallback(
      async (data: GQLEvixAdminLoginInput): Promise<GQLEvixAdmin> => {
        const res = await apiLogin(data)
        localStorage.setItem(LOCAL_STORAGE_KEY_TOKEN, res.token)
        setLoggedIn(res.me)

        return res.me
      },
      [apiLogin, setLoggedIn]
    )

    const refreshSidebar = () => {
      if (sideBarState === 0) {
        setSideBarState(1)
      } else {
        setSideBarState(0)
      }
    }

    const logout = React.useCallback(async () => {
      await apiLogout()
      const managerEmail = localStorage.getItem('managerEmail')
      const doctorEmail = localStorage.getItem('doctorEmail')
      const adminEmail = localStorage.getItem('adminEmail')
      const pharmacyEmail = localStorage.getItem('pharmacyEmail')
      localStorage.clear()
      managerEmail ? localStorage.setItem('managerEmail', managerEmail) : console.log('not remember manager !')
      doctorEmail ? localStorage.setItem('doctorEmail', doctorEmail) : console.log('not remember doctorEmail !')
      adminEmail ? localStorage.setItem('adminEmail', adminEmail) : console.log('not remember adminEmail !')
      pharmacyEmail ? localStorage.setItem('pharmacyEmail', pharmacyEmail) : console.log('not remember pharmacyEmail !')
      dispatch(actionSetLoggedOut())
    }, [apiLogout])

    const refreshProfile = React.useCallback(async () => {
      const profile = await apiGetProfile()
      setLoggedIn(profile)
      return profile
    }, [apiGetProfile, setLoggedIn])

    React.useEffect(() => {
      // noinspection JSIgnoredPromiseFromCall
      checkLogin()
    }, [checkLogin])

    const context: IAdminAppContextType = {
      state,
      sideBarState,
      login,
      logout,
      refreshProfile,
      refreshSidebar,
    }
    return <Context.Provider value={context}>{children}</Context.Provider>
  }

  return { Context, Provider: DoctorProvider }
}

const { Context: AppContext, Provider } = createAdminAppContext(AppOptions)

export const AdminAppProvider = Provider
export const AdminAppContext = AppContext

export function useAdminAppContext(): IAdminAppContextType {
  return React.useContext(AppContext)
}
