import { ODVSpace } from '@mdpp/od-react-belt'
import React from 'react'
import styled from 'styled-components'

interface IAppVersionNoticeBoxProps {}

export const AppVersionNoticeBox: React.FC<IAppVersionNoticeBoxProps> = props => {
  return (
    <Wrapper>
      <Row>
        <GrayBig>필수 업데이트</GrayBig>
        <BlueSmall>(업데이트 없이 앱 사용 불가)</BlueSmall>
      </Row>
      <Row>
        <GraySub>
          사용자의 앱 버전이 가장 최신에 필수 업데이트로 체크된 버전보다 낮은 경우 강제 업데이트 안내 팝업을 노출합니다.
        </GraySub>
      </Row>
      <ODVSpace h={20} />
      <Row>
        <GrayBig>선택 업데이트</GrayBig>
        <BlueSmall>(업데이트 없이 앱 사용 가능)</BlueSmall>
      </Row>
      <Row>
        <GraySub>
          사용자의 앱 버전이 가장 최신에 선택 업데이트로 체크된 버전보다 낮은 경우 업데이트 추천 팝업을 노출합니다.
        </GraySub>
      </Row>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  background-color: #f7f7f7;
  display: flex;
  flex-direction: column;
  border-left: 2px solid var(--gray300);
  padding: 20px 20px;
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
`

const GrayBig = styled.div`
  font-size: 16px;
  font-weight: bold;
  line-height: 1.86;
  color: var(--gray600);
`

const BlueSmall = styled.div`
  font-size: 12px;
  line-height: 2.17;
  color: var(--mpPrimary500);
  margin-left: 10px;
`

const GraySub = styled.div`
  font-size: 14px;
  line-height: 1.86;
  color: var(--gray500);
`
