import React from 'react'
import { PageContainer, Page, PageHeader, PageTitle } from '../../common/page/Page'
import { PharmacyHasHospitalListableTable } from './list/PharmacyHasHospitalListableTable'

interface IAdminHospitalPharmacyList {
  hospitalId: number
}

export const AdminPharmacyHasHospitalListPage: React.FC<IAdminHospitalPharmacyList> = props => {
  return (

    <PageContainer>
      <Page>
        <PageHeader>
          <PageTitle>병원 {'>'} 약국</PageTitle>
        </PageHeader>
        <PharmacyHasHospitalListableTable hospitalId={props.hospitalId} />
      </Page>
    </PageContainer>
  )
}
