import styled from "styled-components/macro";
import {COLORS} from '../../styles/colors'

export const PageContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
`

export const Page = styled.div`
  max-width: 1280px;
  width: 100%;
  padding: 0 50px; 
  display: flex;
  flex-direction: column; 
  justify-items: center;

  @media(max-width: 992px) {
    padding: 0 20px; 
    min-width: 650px;
  }
`
export const PageHeader = styled.div`
  padding: 36px 0 24px 0;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  gap: 16px;

  &.title-description {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }
`

export const PageContent = styled.div`
  width: 100%;
  padding-bottom: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
`

export const PageTitle = styled.h2`
  display: flex;
  align-items: center;
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 30px;
  color: ${COLORS.BLACK};
  margin-bottom: 0;

  &.title-description {
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
  }
`

export const TitleDescription = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  font-weight: 500;

  > img {
    width: 13px;
    height: 13px;
    vertical-align: top;
  }

  > span {
    font-family: Noto Sans KR;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: normal;
    color: ${COLORS.GRAY42};
  }

  &.line {
    position: relative;
    padding-left: 14px;

    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 4px;
      width: 3px;
      height: 36px;
      background-color: #007EFF;
    }
  }
`