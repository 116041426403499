import React from 'react'
import _ from 'lodash'
import arrowUp from '../assets/mydata/ic_action24px_arrow_up.png'
import arrowDown from '../assets/mydata/ic_action24px_arrow_down.png'
import {MEDICAL_DATA_SHARE_ITEM} from "@mdpp/common/lib/mydata/PublicDataType";

interface MyHealthCheckUpProps {
    info?: MEDICAL_DATA_SHARE_ITEM | null
}
const getListItem= (item:any,first:string,second:string) => {
    console.log('item : ',item , ' , first : ',first, ' , second: ',second)
     let returnValue
     const a = item.list.find((e:any) => e.name === first)
     return a.list.find((f:any) => f.name === second )
}

export const MyHealthCheckUp: React.FC<MyHealthCheckUpProps> = props => {
    const { info } = props
    //console.log('MyDetailData info : ',info);

    if (!info) {
        return null
    }
    // const metrology = info.publicMedicalCheckData?.healthCheck.list
    const healthCheckList = info.publicMedicalCheckData?.healthCheck.list;
    //console.log('healthCheckList : ',healthCheckList);
    let metrology;//계측검사
    let urinalysis;//요검사
    let blood //혈액검사
    let imaging //영상검사
    let osteoporosis //골다공증
    if(healthCheckList){
        metrology = healthCheckList.find((e:any) => e.classification === '계측검사')
        urinalysis = healthCheckList.find((e:any) => e.classification ==='요검사')
        blood = healthCheckList.find((e:any) => e.classification ==='혈액검사')
        imaging = healthCheckList.find((e:any) => e.classification ==='영상검사')
        osteoporosis = healthCheckList.find((e:any) => e.classification ==='골다공증')

        //console.log('metrology : ',metrology)
        //console.log('urinalysis : ',urinalysis)
        //console.log('blood : ',blood)
        //console.log('imaging : ',imaging)
        //console.log('osteoporosis : ',osteoporosis)
    }

    return (
        <div className="detail-wrap"> 

            {/* 종합판정 */}
            <div className="total-result">
                <div className="total-title">종합판정</div>
                {/* success, warning, critical, primary */}
                {
                    info.publicMedicalCheckData?.result &&
                    <div className="total-diagnosis critical"><span>{info.publicMedicalCheckData?.result}</span></div>
                }
                <div className="total-type">
                    <span>검진구분 · {info.publicMedicalCheckData?.type}</span>
                </div>
            </div>
            

            <div className="divide-bg"></div>

            <div className="section pd23">          
                {/* 종합소견 */}
                <div className="title pb-16">종합소견</div>
                <div className="content">
                    {/*<span className="pb-12">생활습관관리</span>*/}
                    <div className="text-faint">
                        {info.publicMedicalCheckData?.opinion?info.publicMedicalCheckData?.opinion:(<span>{`제공가능한 기록이 없습니다.`}</span>)}
                    </div>
                </div>
            </div>

            {
                !info.publicMedicalCheckData?.type.includes('구강') &&
                    info.publicMedicalCheckData?.healthCheck.list &&
                (
                    <>
                        {
                            metrology &&
                            (
                                <>
                                    <div className="divide-bg"></div>
                                    <div className="section pd23">
                                        <div className="title pb-16">{metrology.classification}</div>
                                        {
                                            metrology.list.map((m:any) => (
                                                <>
                                                    {/* 비만 */}
                                                    <div className="content mb-32" key={_.uniqueId()}>
                                                        <span key={_.uniqueId()}>{m.name}</span>
                                                        <div className="toggle-box" key={_.uniqueId()}>
                                                            {
                                                                m.list.map((mm: any) => (
                                                                    <>
                                                                        <div className="inner f-row" key={_.uniqueId()}>
                                                                            <div className="name pb-0" key={_.uniqueId()}>{mm.name}</div>
                                                                            <div className="other f-row" key={_.uniqueId()}>
                                                                                <span>{mm.value}</span>
                                                                                <span className="unit">{mm.ref.unit}</span>
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                ))
                                                            }
                                                        </div>
                                                    </div>

                                                </>
                                                ))
                                        }
                                    </div>
                                </>
                            )
                        }


                        {
                            urinalysis &&
                            (
                                <>
                                    <div className="divide-bg"></div>

                                    <div className="section pd23">
                                    {/* 요검사 */}
                                    <div className="title pb-16">요검사</div>
                                    <div className="content">
                                        <span>신장질환</span>
                                        <div className="toggle-box">
                                            <div className="inner f-row">
                                                <div className="name pb-0">요단백</div>
                                                <div className="other f-row">
                                                    <span>{(getListItem(urinalysis,'신장질환','요단백')).value}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                </>
                            )
                        }

                        {
                            blood &&
                            (
                                <>
                                <div className="divide-bg"></div>

                                <div className="section pd23">
                                    {/* 혈액검사 */}

                                        <div className="title pb-16">{blood.classification}</div>
                                        {
                                            blood.list.map((m:any) => (
                                                <>
                                                    <div className="content mb-32" key={_.uniqueId()}>
                                                        <span key={_.uniqueId()}>{m.name}</span>
                                                        <div className="toggle-box" key={_.uniqueId()}>

                                                            {
                                                                m.list.map((mm:any)=> (
                                                                    <>
                                                                        <div className="inner f-row" key={_.uniqueId()}>
                                                                            <div className="name pb-0" key={_.uniqueId()}>{mm.name}</div>
                                                                            <div className="other f-row" key={_.uniqueId()}>
                                                                                <span>{mm.value}</span>
                                                                                <span className="unit">{mm.ref.unit}</span>
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                ))
                                                            }

                                                        </div>
                                                    </div>
                                                </>
                                            ))
                                        }
                        </div>
                                </>
                            )
                        }


                        {
                            imaging &&
                            (
                                <>
                                <div className="divide-bg"></div>

                                <div className="section pd23">
                                    {/* 영상검사 */}
                                    <div className="title pb-16">{imaging.classification}</div>
                                    {
                                        imaging.list.map((m: any) => (
                                            <>
                                                <div className="content">
                                                    <span>{m.name}</span>
                                                    <div className="toggle-box">
                                                        {
                                                            m.list.map((mm: any) => (
                                                                <div className="inner f-row">
                                                                    <div className="name pb-0">{mm.name}</div>
                                                                    <div className="other f-row">
                                                                        <span>{mm.value}</span>
                                                                    </div>
                                                                </div>
                                                            ))

                                                        }

                                                    </div>
                                                </div>
                                            </>
                                        ))
                                    }
                                </div>
                                </>
                            )
                        }

                        {
                            osteoporosis &&
                            (
                                <>
                                    <div className="divide-bg"></div>

                                    <div className="section pd23">
                                        {/* 기타 골다공증 */}
                                        <div className="title pb-16">{osteoporosis.classification}</div>
                                        {
                                            osteoporosis.list.map((m: any) => (
                                                <>
                                                    <div className="content">
                                                        <span>{m.name}</span>
                                                        <div className="toggle-box">
                                                            {
                                                                m.list.map((mm: any) => (
                                                                    <div className="inner f-row">
                                                                        <div className="name pb-0">{mm.name}</div>
                                                                        <div className="other f-row">
                                                                            <span>{mm.value==''?'-':mm.value}</span>
                                                                        </div>
                                                                    </div>
                                                                ))

                                                            }

                                                        </div>
                                                    </div>
                                                </>
                                            ))
                                        }
                                    </div>
                                </>
                            )
                        }
                        <div className="divide-bg"></div>



                        {/*<div className="section pd23">*/}
                        {/*    /!* 추가항목 *!/*/}
                        {/*    <div className="title pb-16">추가항목</div>*/}
                        {/*    <div className="content mb-32">*/}
                        {/*        <span>연령별 평가항목</span>*/}
                        {/*        <div className="toggle-box">*/}
                        {/*            <div className="inner f-row">*/}
                        {/*                <div className="name pb-0">B형감염</div>*/}
                        {/*                <div className="other f-row">*/}
                        {/*                    <span>항체없음</span>*/}
                        {/*                </div>*/}
                        {/*            </div>*/}
                        {/*            <div className="inner f-row">*/}
                        {/*                <div className="name pb-0">우울증</div>*/}
                        {/*                <div className="other f-row">*/}
                        {/*                    <span>우울증없음</span>*/}
                        {/*                </div>*/}
                        {/*            </div>*/}
                        {/*            <div className="inner f-row">*/}
                        {/*                <div className="name pb-0">인지기능장애</div>*/}
                        {/*                <div className="other f-row">*/}
                        {/*                    <span>특이소견 없음</span>*/}
                        {/*                </div>*/}
                        {/*            </div>*/}
                        {/*            <div className="inner f-row">*/}
                        {/*                <div className="name pb-0">골밀도 검사</div>*/}
                        {/*                <div className="other f-row">*/}
                        {/*                    <span>정상</span>*/}
                        {/*                </div>*/}
                        {/*            </div>*/}
                        {/*            <div className="inner f-row">*/}
                        {/*                <div className="name pb-0">노인신체기능검사</div>*/}
                        {/*                <div className="other f-row">*/}
                        {/*                    <span>정상</span>*/}
                        {/*                </div>*/}
                        {/*            </div>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}

                        {/*    <div className="content mb-32">*/}
                        {/*        <span>노인기능평가(문진)</span>*/}
                        {/*        <div className="toggle-box">*/}
                        {/*            <div className="inner f-row">*/}
                        {/*                <div className="name pb-0">낙상</div>*/}
                        {/*                <div className="other f-row">*/}
                        {/*                    <span>정상</span>*/}
                        {/*                </div>*/}
                        {/*            </div>*/}
                        {/*            <div className="inner f-row">*/}
                        {/*                <div className="name pb-0">일상생활 수행능력</div>*/}
                        {/*                <div className="other f-row">*/}
                        {/*                    <span>일상생활 도움필요</span>*/}
                        {/*                </div>*/}
                        {/*            </div>*/}
                        {/*            <div className="inner f-row">*/}
                        {/*                <div className="name pb-0">예방접종</div>*/}
                        {/*                <div className="other f-row">*/}
                        {/*                    <span>접종 필요 없음</span>*/}
                        {/*                </div>*/}
                        {/*            </div>*/}
                        {/*            <div className="inner f-row">*/}
                        {/*                <div className="name pb-0">배뇨장애</div>*/}
                        {/*                <div className="other f-row">*/}
                        {/*                    <span>정상</span>*/}
                        {/*                </div>*/}
                        {/*            </div>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*</div>*/}

                    </>
                )
            }
        </div>
    )
}
