import React, { useEffect, useState } from 'react'
import { DateRangePicker } from 'react-dates'
import 'react-dates/initialize'
import moment, {Moment} from "moment";

interface IDatePicker {
  dateRange: string[]
  onChange: (changedValue: string[]) => void
  isOutsideRange ?: (day: Moment) => boolean
  wrapperStyle?: object
}

const dafaultOutsideRange = () => false;

export const DatePicker: React.FC<IDatePicker> = props => {
  const { onChange, isOutsideRange, dateRange, wrapperStyle } = props

  useEffect(() => {
    if (dateRange.length > 1) {
      let s: any = moment(dateRange[0])
      s = s.isValid() ? s : null
      let e: any = moment(dateRange[1])
      e = e.isValid() ? e : null
      setStartDate(s)
      setEndDate(e)
    }
  }, [dateRange])

  const [startDate, setStartDate] = useState(moment())
  const [endDate, setEndDate] = useState(moment())
  const [focusedInput, setFocusedInput] = useState(null)

  const handleDatesChange = ({ startDate, endDate }: any) => {
    setStartDate(startDate)
    setEndDate(endDate)
    onChange([startDate?.format('YYYY-MM-DD') ?? '', endDate?.format('YYYY-MM-DD') ?? ''])
  }

  return (
    <div className="App" style={wrapperStyle}>
      <DateRangePicker
        displayFormat={'YYYY-MM-DD'}
        startDate={startDate}
        startDateId="tata-start-date"
        endDate={endDate}
        endDateId="tata-end-date"
        onDatesChange={handleDatesChange}
        focusedInput={focusedInput}
        onFocusChange={(focusedInput: any) => setFocusedInput(focusedInput)}
        isOutsideRange={isOutsideRange ?? dafaultOutsideRange}
      />
    </div>
  )
}
