import {ODEntity, ODEntityInput} from '@mdpp/od-react-belt'
import gql from 'graphql-tag'
import React from 'react'
import styled from 'styled-components'
import {
	GQLMedicalSubject,
	GQLMedicalSubjectCreationInput,
	GQLMedicalSubjectUpdateInput,
} from '@mdpp/common/lib/@types/server.schema'
import {SiteUrls} from '../../../SiteUrls'
import {COLORS} from '../../../styles/colors'
import {useODEntityAPI} from '../../../utils/odGQLEntityLoader'
import {
	GQL_CREATE_MEDICAL_SUBJECT,
	GQL_DELETE_MEDICAL_SUBJECT,
	GQL_GET_MEDICAL_SUBJECT,
	GQL_UPDATE_MEDICAL_SUBJECT,
} from '../../gqls_common'
import {ICTMedicalSubject, MedicalSubjectPrimaryKey} from '../MedicalSubjectCommon'
import {TableList} from "../../list/TableList";
import { PageContainer, Page, PageHeader, PageTitle, PageContent } from '../../page/Page'
import { EvixCard } from '../../card/EvixCard'

interface IMedicalSubjectEditPageProps {
	idEditing: number | null
}

const DEFAULT_DATA: Partial<ICTMedicalSubject> = {
	medicalSubjectId: 0,
	name: ``,
}

export const MedicalSubjectEditPage: React.FC<IMedicalSubjectEditPageProps> = props => {
	const {idEditing} = props

	const apis = useODEntityAPI<GQLMedicalSubject,
		ICTMedicalSubject,
		GQLMedicalSubjectCreationInput,
		GQLMedicalSubjectUpdateInput>({
		createGQL: gql(GQL_CREATE_MEDICAL_SUBJECT),
		updateGQL: gql(GQL_UPDATE_MEDICAL_SUBJECT),
		readGQL: gql(GQL_GET_MEDICAL_SUBJECT),
		deleteGQL: gql(GQL_DELETE_MEDICAL_SUBJECT),
		primaryKeyName: MedicalSubjectPrimaryKey,
	})

	const Wrapper = styled.div`
		display: flex;
		flex-direction: column;
		gap: 32px;
		padding: 10px;
	`


	return (
		<PageContainer>
        <Page>
          <PageHeader>
            <PageTitle>진료과목 관리</PageTitle>
          </PageHeader>
					<PageContent>
					<EvixCard>
						<ODEntity
							resourceId={idEditing}
							api={apis}
							saveButtonName="저장"
							titleCreation="추가"
							createSuccessTitle="성공"
							createSuccessMessage="추가하였습니다."
							titleUpdate="수정"
							updateSuccessTitle="성공"
							updateSuccessMessage="수정하였습니다."
							urlAfterDelete={(item: ICTMedicalSubject) => SiteUrls.Admin.MedicalSubject.Main}
							deleteSuccessTitle="성공"
							deleteSuccessMessage="삭제하였습니다."
							defaultCreateClientData={DEFAULT_DATA}
							urlAfterCreation={() => SiteUrls.Admin.MedicalSubject.Main}
							urlAfterUpdate={() => SiteUrls.Admin.MedicalSubject.Main}
							noCardWrap
							footerProps={{
								deleteButtonStyle: {
									borderColor: COLORS.DANGER,
									color: COLORS.DANGER,
									backgroundColor: COLORS.RED10,
								},
							}}
							deleteButtonName="삭제"
						>
							<Wrapper>
							<TableList name="title" label="진료과목">
								<ODEntityInput
									keyPath="name"
									label="진료과목"
									name="name"
									placeholder="진료과목을 입력해주세요."
									inputType="text"
								/>
							</TableList>
							</Wrapper>
							<div style={{marginTop: 30, marginBottom: 30}}>
								<hr/>
							</div>
						</ODEntity>
					</EvixCard>
					</PageContent>
				</Page>
      </PageContainer>
	)
}
