import React from 'react'
import moment from 'moment'
import _ from 'lodash'
import iconOption from '../assets/mydata/ic_ui_options.png'
import iconPublic from '../assets/mydata/ic_pictogram24px_public_data.png'
import iconHospital from '../assets/mydata/ic_pictogram24px_hospital.png'
import { MEDICAL_DATA_SHARE_ITEM } from '@mdpp/common/lib/mydata/PublicDataType'
import { PRESCRIPTION, OBSERVATION } from '@mdpp/common/lib/mydata/HospitalDataTypes'

interface CardProps {
  cardInfoList: MEDICAL_DATA_SHARE_ITEM[]
  onClicked?: (info: MEDICAL_DATA_SHARE_ITEM) => void
}

const EMPTY_CONTENT = `제공가능한 기록이 없습니다.`
const EMPTY_PROCEDURE_CONTENT = `검사나 처치에 대한 처방정보가 없습니다.`

export const Card: React.FC<CardProps> = props => {
  const { cardInfoList, onClicked } = props

  const getStatusBadge = (classType: string) => {
    if (classType.includes('조제')) {
      return <span className="status-badge green">조제</span>
    } else if (classType.includes('외래')) {
      return <span className="status-badge yellow">외래</span>
    } else if (classType.includes('입원')) {
      return <span className="status-badge purple">입원</span>
    } else {
      return <span className="status-badge blue">검진</span>
    }
  }

  const getDateTime = (data: MEDICAL_DATA_SHARE_ITEM) => {
    //공공
    if (data.type === 0) {
      if (data.publicTreatmentAndMedicationData!.period === '1')
        return <span className="date">{moment(data.dateTime).format('YYYY.MM.DD')}</span>
      else
        return (
          <span className="date">
            {moment(data.dateTime!).format('YYYY.MM.DD')} ~
            {moment(data.dateTime!).add(data.publicTreatmentAndMedicationData!.period, 'day').format('YYYY.MM.DD')}(
            {data.publicTreatmentAndMedicationData!.period}일간)
          </span>
        )
    } else {
      //병원
      if (data.class != '입원') return <span className="date">{moment(data.dateTime).format('YYYY.MM.DD')}</span>
      // console.log('start : ',moment(data.hospitalData?.visit.end).)
      //console.log('end : ', Math.round(moment.duration((moment(data.hospitalData?.visit.end)).diff(moment(data.hospitalData?.visit.start))) .asDays() ) )
      else
        return (
          <span className="date">
            {moment(data.hospitalData?.visit.start!).format('YYYY.MM.DD')} ~
            {moment(data.hospitalData?.visit.end!).format('YYYY.MM.DD')}
            {/* UTC KSD 인경우 NaN 발생 하여, format을 제거*/}
            {/*({ moment.duration((moment(data.hospitalData?.visit.end,'YYYY.MM.DD')).diff(moment(data.hospitalData?.visit.start,'YYYY.MM.DD'))).asDays() }일간)</span>*/}
            (
            {Math.round(
              moment
                .duration(moment(data.hospitalData?.visit.end).diff(moment(data.hospitalData?.visit.start)))
                .asDays()
            )}
            일간)
          </span>
        )
    }
  }

  const getContent = (info: MEDICAL_DATA_SHARE_ITEM) => {
    let result: string | null = EMPTY_CONTENT
    // console.log('info : ',info)
    //공공
    if (info.type === 0) {
      if (
        info.publicTreatmentAndMedicationData?.class.includes('조제') ||
        info.publicTreatmentAndMedicationData?.class.includes('입원')
      ) {
        const medicine = info.publicTreatmentAndMedicationData.medication
        if (medicine && medicine.length == 1) {
          result = medicine && `${medicine[0].medicineKr}`
        } else {
          result = medicine && `${medicine[0].medicineKr} 외 ${medicine.length - 1}건`
        }
      } else if (info.publicTreatmentAndMedicationData?.class.includes('외래')) {
        result = EMPTY_CONTENT
      }
    } else if (info.type === 1) {
      const medicalCheck = info.publicMedicalCheckData
      // @ts-ignore
      result = medicalCheck && `${medicalCheck.result}`
    } else {
      if (info.class?.includes('외래') && info.hospitalData?.prescription[0]?.medication.length == 0) {
        result = EMPTY_CONTENT
      }
      if (info.publicTreatmentAndMedicationData?.class.includes('외래')) {
        // @ts-ignore
        const medicine = info.hospitalData.prescription
        if (medicine.length > 1) {
          const mainItem: any = getMainDisease(medicine)
          //console.log('mainItem: ', mainItem )
          result = medicine && `${mainItem?.name} 외 ${medicine.length - 1}건`
        } else {
          result = medicine && `${medicine[0]?.condition[0]?.disease.name}`
        }
      }
    }

    return result
  }

  const getProcedureOrTestContent = (info: MEDICAL_DATA_SHARE_ITEM) => {
    let result: string | null = ''
    //console.log('info : ',info)
    let observationCount = 0
    let procedureCount = 0
    let count = 0
    //공공
    if (info.type === 3) {
      // @ts-ignore
      const observation = info.hospitalData.observation
      if (observation.length > 0) {
        const observationContent: any = getObservation(observation)
        //console.log('observationContent : ',observationContent)
        result = observationContent.content
        observationCount = observationContent.count
      }
      // @ts-ignore
      const procedure = info.hospitalData.prescription
      if (result === '' && procedure.length > 0) {
        const procedureContent: any = getProcedure(procedure)
        //console.log('procedureContent : ',procedureContent)
        result = procedureContent.content
        count = procedureContent.count
      }

      if (observationCount > 0 && procedure.length > 0) {
        count = observationCount + getProcedure(procedure).count
      }
    }

    if (count > 1) result = `${result} 외 ${count - 1}건`
    if (result === '') result = EMPTY_PROCEDURE_CONTENT
    return result
  }

  const getObservation = (observationData: OBSERVATION[]) => {
    let observationContents: string | null = EMPTY_PROCEDURE_CONTENT
    let count = 0
    observationData.forEach(items => {
      if (items.name) {
        observationContents = items.name
        count = count + 1
      }
    })

    return { content: observationContents, count: count }
  }
  const getProcedure = (prescriptionData: PRESCRIPTION[]) => {
    let procedureContents: string | null = EMPTY_PROCEDURE_CONTENT
    let count = 0
    prescriptionData.forEach(items => {
      if (items.procedure.length > 0) {
        procedureContents = items.procedure[0]?.name
        count = count + items.procedure.length
      }
    })
    //if(count > 1) return `${procedureContents} 외 ${count-1}건`
    return { content: procedureContents, count: count }
  }

  const getMainDisease = (prescriptionData: PRESCRIPTION[]) => {
    let main: any
    prescriptionData.some(items => {
      if (items.condition[0]?.disease.main === '주' || items.condition[0]?.disease.main === 'MAIN') {
        main = items.condition[0]?.disease
      }
    })
    return main
  }

  return (
    <div className="card-wrap">
      {/*<div className="epi-card selected">*/}
      {/*  <div className="status-wrap">*/}
      {/*    <span className="status-badge purple">입원</span>*/}
      {/*    <span className="date">2021.03.12 ~ 2021.04.12 (31일)</span>*/}
      {/*  </div>*/}
      {/*  <div className="column">*/}
      {/*    <div className="hospital">*/}
      {/*      <span>한국대학병원한국대학병원한국대학병원한국대학병원</span>*/}
      {/*      <img src={iconOption} />*/}
      {/*    </div>*/}
      {/*    <div className="diagnosis">클라미디아에 의한 기타 질환, 본태성 고혈압</div>*/}
      {/*    <div className="procedure">절제술,항암치료,면역치료</div>*/}
      {/*    <div className="info">*/}
      {/*      <img src={iconHospital} />*/}
      {/*      <span>출처: 해당의료기관</span>*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*</div>*/}
      {cardInfoList.map(info =>
        info.publicTreatmentAndMedicationData ? (
          // 카드가 선택되면,  selected class를 추가해주세요.
          <div
            className={`epi-card`}
            onClick={() => {
              onClicked && onClicked(info)
            }}
            key={_.uniqueId()}
          >
            <div className="status-wrap">
              {getStatusBadge(info.publicTreatmentAndMedicationData?.class)}
              {getDateTime(info)}
            </div>
            <div className="column">
              <div className="hospital">
                <span>{info.publicTreatmentAndMedicationData?.orgName}</span>
                {/*<img src={iconOption} />*/}
              </div>
              {getContent(info) === EMPTY_CONTENT ? (
                <div className="procedure white">{EMPTY_CONTENT}</div>
              ) : (
                <div className="procedure">{getContent(info)}</div>
              )}
              {/*<div className="diagnosis">클라미디아에 의한 기타 질환, 본태성 고혈압</div>*/}
              {/* <div className="procedure">절제술,항암치료,면역치료</div> */}
              {/* <div className="procedure white">제공가능한 기록이 없습니다.</div> */}
              <div className="info">
                {
                  <>
                    <img src={iconPublic} />
                    <span>출처: 국민건강보험공단</span>
                  </>
                }
              </div>
            </div>
          </div>
        ) : info.publicMedicalCheckData ? (
          <div
            className="epi-card"
            onClick={() => {
              onClicked && onClicked(info)
            }}
            key={_.uniqueId()}
          >
            <div className="status-wrap">
              {getStatusBadge(info.class)}
              {getDateTime(info)}
            </div>
            <div className="column">
              <div className="hospital">
                <span>{info.orgName}</span>
                {/*<img src={iconOption} />*/}
              </div>
              <div className="diagnosis">{info.publicMedicalCheckData.type}검진</div>
              {getContent(info) == '' || getContent(info) === EMPTY_CONTENT ? (
                <div className="procedure white">{EMPTY_CONTENT}</div>
              ) : (
                <div className="procedure">{getContent(info)}</div>
              )}
              {/*<div className="diagnosis">클라미디아에 의한 기타 질환, 본태성 고혈압</div>*/}
              {/* <div className="procedure">절제술,항암치료,면역치료</div> */}
              {/* <div className="procedure white">제공가능한 기록이 없습니다.</div> */}
              <div className="info">
                {
                  <>
                    <img src={iconPublic} />
                    <span>출처: 국민건강보험공단</span>
                  </>
                }
              </div>
            </div>
          </div>
        ) : (
          <div
            className="epi-card"
            onClick={() => {
              onClicked && onClicked(info)
            }}
            key={_.uniqueId()}
          >
            <div className="status-wrap">
              {getStatusBadge(info.class)}
              {getDateTime(info)}
            </div>
            <div className="column">
              <div className="hospital">
                {info.hospitalData?.orgName ? <span>{info.hospitalData?.orgName}</span> : <span>{info.orgName}</span>}
                {/*<img src={iconOption} />*/}
              </div>
              {getContent(info) === EMPTY_CONTENT ? (
                <div className="procedure white">{EMPTY_CONTENT}</div>
              ) : (
                <div className="diagnosis">{getContent(info)}</div>
              )}
              {/*<div className="diagnosis">클라미디아에 의한 기타 질환, 본태성 고혈압</div>*/}
              {getProcedureOrTestContent(info) === EMPTY_PROCEDURE_CONTENT ? (
                <div className="procedure white">{EMPTY_PROCEDURE_CONTENT}</div>
              ) : (
                <div className="procedure">{getProcedureOrTestContent(info)}</div>
              )}

              {/* <div className="procedure white">제공가능한 기록이 없습니다.</div> */}
              <div className="info">
                {
                  <>
                    <img src={iconHospital} />
                    {info.hospitalData?.orgName ? (
                      <span>출처: {info.hospitalData?.orgName}</span>
                    ) : (
                      <span>출처: {info.orgName}</span>
                    )}
                  </>
                }
              </div>
            </div>
          </div>
        )
      )}
      {/*<div className="epi-card">*/}
      {/*  <div className="status-wrap">*/}
      {/*    <span className="status-badge yellow">외래</span>*/}
      {/*    <span className="date">2021.03.12 ~ 2021.04.12 (31일간)</span>*/}
      {/*  </div>*/}
      {/*  <div className="column">*/}
      {/*    <div className="hospital">*/}
      {/*      <span>한국대학병원</span>*/}
      {/*      <img src={iconOption} />*/}
      {/*    </div>*/}
      {/*    <div className="diagnosis">클라미디아에 의한 기타 질환, 본태성 고혈압</div>*/}
      {/*    <div className="procedure">절제술,항암치료,면역치료</div>*/}
      {/*    <div className="info">*/}
      {/*      <img src={iconPublic} />*/}
      {/*      <span>출처: 공공</span>*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*</div>*/}

      {/*<div className="epi-card">*/}
      {/*  <div className="status-wrap">*/}
      {/*    <span className="status-badge green">조제</span>*/}
      {/*    <span className="date">2021.03.12 ~ 2021.04.12 (31일간)</span>*/}
      {/*  </div>*/}
      {/*  <div className="column">*/}
      {/*    <div className="hospital">*/}
      {/*      <span>한국대학병원</span>*/}
      {/*      <img src={iconOption} />*/}
      {/*    </div>*/}
      {/*    <div className="diagnosis">클라미디아에 의한 기타 질환, 본태성 고혈압</div>*/}
      {/*    <div className="procedure">절제술,항암치료,면역치료</div>*/}
      {/*    <div className="info">*/}
      {/*      <img src={iconPublic} />*/}
      {/*      <span>출처: 공공</span>*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*</div>*/}

      {/*<div className="epi-card">*/}
      {/*  <div className="status-wrap">*/}
      {/*    <span className="status-badge blue">검진</span>*/}
      {/*    <span className="date">2021.03.12 ~ 2021.04.12 (31일간)</span>*/}
      {/*  </div>*/}
      {/*  <div className="column">*/}
      {/*    <div className="hospital">*/}
      {/*      <span>한국대학병원</span>*/}
      {/*      <img src={iconOption} />*/}
      {/*    </div>*/}
      {/*    <div className="diagnosis">클라미디아에 의한 기타 질환, 본태성 고혈압</div>*/}
      {/*    <div className="procedure">절제술,항암치료,면역치료</div>*/}
      {/*    <div className="info">*/}
      {/*      <img src={iconPublic} />*/}
      {/*      <span>출처: 공공</span>*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*</div>*/}
    </div>
  )
}
