// @ts-ignore
import { AppSidebarToggler } from '@coreui/react'
import { ODHSpace, ODIcon, ODIcons, ODVLine } from '@mdpp/od-react-belt'
import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import MediPangPangLogo from "../../assets/mediPangPang_BI_v1.0_197_51@3x.png"
import { SiteUrls } from '../../SiteUrls'
import { COLORS } from '../../styles/colors'
import { useManagerAppContext } from '../context/ManagerAppContext'
import { ManagerDropdown } from './ManagerDropdown'
import LogoHospital from '../../assets/common/bi-mdpp-hospital-small.png'

interface IEvixHeaderProps {}

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding: 0 20px;

  @media only screen and (max-width: 360px) {
    padding: 0 10px;
  }
`

const IconWrapper = styled.div`
  font-size: 15px;
  color: ${COLORS.GRAY500};
`

const HospitalName = styled.span`
  font-size: 12px;
  font-weight: bold;
  color: ${COLORS.GRAY500};
  margin-top: 1px;
  max-height: 40px;
  overflow: hidden;
`
const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
`

export const ManagerHeader: React.FC<IEvixHeaderProps> = props => {
  const {
    state: { profile },
  } = useManagerAppContext()

  const hospitalName = profile?.hospital?.name ?? '-'

  return (
    <Wrapper style={{display: 'flex', alignItems: 'center'}}>
      <div style={{ display: 'flex'}}>
        <AppSidebarToggler className="d-lg-none" display="md" mobile />
        <TitleWrapper>
         <Link to={SiteUrls.Manager.Root} style={{ textDecoration: 'none', display: 'inline-block' }}>
            <img src={LogoHospital} className='logo-manager' alt="Medi PangPang Logo" /> 
          </Link>
        </TitleWrapper>
      </div>
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <IconWrapper>
          <ODIcon icon={ODIcons.CoreHospital} />
        </IconWrapper>
        <ODHSpace w={5} />
        <HospitalName>{hospitalName}</HospitalName>
        <ODHSpace w={12} />
        <ODVLine height={13} color={COLORS.GRAY300} style={{ marginTop: 3 }} />
        <ODHSpace w={11} />
        <ManagerDropdown />
      </div>
    </Wrapper>
  )
}
