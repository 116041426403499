import { useODMutation, useODQuery } from '@mdpp/od-react-belt'
import {
  GQLChangeAccountPasswordInput,
  GQLDoctor,
  GQLDoctorApprovalHoldInput,
  GQLDoctorCreationByDoctorInput,
  GQLDoctorFindIdInput,
  GQLDoctorFindIdOutput,
  GQLDoctorLicenseCheckInput,
  GQLDoctorUpdateEmailInput,
  GQLDoctorUpdateEmailOutput,
  GQLDoctorUpdateInputByManager,
  GQLFaq,
  GQLHospital,
  GQLInquiry,
  GQLInquiryCreationInput,
  GQLListableDataShare,
  GQLListableDataShareInput,
  GQLListableDiagName,
  GQLListableDiagNameInput,
  GQLListableDoctor,
  GQLListableDoctorInput,
  GQLListableFaq,
  GQLListableFaqInput,
  GQLListableHospital,
  GQLListableHospitalInput,
  GQLListableInquiry,
  GQLListableInquiryInput,
  GQLListableMedicalSubject,
  GQLListableMedicalSubjectInput,
  GQLListableNotice,
  GQLListableNoticeInput,
  GQLListablePatient,
  GQLListablePatientInput,
  GQLListablePopupInfo,
  GQLListableScheduleSetting,
  GQLListableScheduleSettingInput,
  GQLListableWaitingDoctorInput,
  GQLListableWaitingDoctorOutput,
  GQLNotice,
  GQLOkResponse,
  GQLPatient,
  GQLPharmacyHasHospital,
  GQLPharmacyHasHospitalCreationInput,
  GQLPopupInfo,
  GQLPopupInfoListInput,
  GQLPopupInfoSearchInput,
  GQLReservation,
  GQLReservationPaymentInput,
  GQLResetAccountPasswordInput,
  GQLScheduleSetting,
  GQLScheduleSettingUpdateInput,
  GQLSingleIDInput,
  GQLUpdateReservationByDoctorInput,
  GQLUpdateReservationDiagnosisInput,
  GQLWaitingDoctor,
  GQLWaitingDoctorUpdateInput,
  GQLCreateWaitingPharmacistInput,
  GQLWaitingPharmacist,
  GQLUpdateWaitingPharmacistEmailAndSendAuthMailInput,
  GQLUpdateWaitingPharmacistEmailAndSendAuthMailOutput,
  GQLCheckLicenseNumberPharmacistInput,
  GQLPharmacistFindIdOutput,
  GQLPharmacistFindIdInput,
  GQLListableWaitingPharmacistInput,
  GQLListableWaitingPharmacistOutput,
  GQLListablePharmacistOutput,
  GQLPharmacist,
  GQLPharmacyUpdateInput,
  GQLApproveWaitingPharmacistByAdminOutput,
  GQLHoldWaitingPharmacistByAdminInput,
  GQLUpdateWaitingPharmacistInput,
  GQLDoctorUpdateScheduleInput,
} from '@mdpp/common/lib/@types/server.schema'
import { AppOptions } from '../../AppOptions'
import {
  GQL_CHANGE_ACCOUNT_PASSWORD,
  GQL_CREATE_INQUIRY,
  GQL_CREATE_PHARMACY_HAS_HOSPITAL,
  GQL_DELETE_DOCTOR_SCHEDULE,
  GQL_FINISH_RESERVATION,
  GQL_GET_FAQ,
  GQL_GET_HOSPITAL_OF_MANAGER,
  GQL_GET_INQUIRY,
  GQL_GET_NOTICE,
  GQL_GET_PATIENT,
  GQL_GET_POPUP_INFO,
  GQL_LIST_POPUP_INFO,
  GQL_LIST_DIAG_NAME,
  GQL_LIST_DOCTOR,
  GQL_LIST_FAQ,
  GQL_LIST_HOSPITAL,
  GQL_LIST_INQUIRY,
  GQL_LIST_MEDICAL_SUBJECTS,
  GQL_LIST_NOTICE,
  GQL_LIST_PATIENT,
  GQL_LIST_SCHEDULE_SETTING,
  GQL_PAY_RESERVATION,
  GQL_READ_DOCTOR,
  GQL_REMOVE_PHARMACY_HAS_HOSPITAL,
  GQL_RESET_ACCOUNT_PASSWORD,
  GQL_START_RESERVATION,
  GQL_UPDATE_DOCTOR,
  GQL_UPDATE_RESERVATION_BY_MANAGER,
  GQL_UPDATE_RESERVATION_DIAGNOSIS,
  GQL_UPDATE_SCHEDULE_SETTING,
  GQL_LIST_PATIENT_MYDATA,
  GQL_DOCTOR_FIND_ID,
  GQL_CHECK_LICENSE_NAME,
  GQL_CREATE_DOCTOR_BY_DOCTOR,
  GQL_SEND_AUTH_MAIL_AGAIN,
  GQL_UPDATE_EMAIL_AND_SEND_AUTH_MAIL,
  GQL_LIST_WAITING_DOCTOR,
  GQL_UPDATE_WAITING_DOCTOR,
  GQL_APPROVED_BY_ADMIN,
  GQL_HOLD_BY_ADMIN,
  GQL_DELETE_WAITING_DOCTOR,
  GQL_CREATE_WAITING_PHARMACIST,
  GQL_SEND_WAITING_PHARMACIST_MAIL_AGAIN,
  GQL_UPDATE_WAITING_PHARMACIST_EMAIL_AND_SEND_AUTH_MAIL,
  GQL_CHECK_LICENSE_NAME_PHARMACIST,
  GQL_PHARMACIST_FIND_ID,
  GQL_LIST_WAITING_PHARMACIST,
  GQL_LIST_PHARMACIST,
  GQL_UPDATE_PHARMACIST,
  GQL_APPROVE_WAITING_PHARMACIST_BY_ADMIN,
  GQL_HOLD_WAITING_PHARMACIST_BY_ADMIN,
  GQL_GET_WAITING_PHARMACIST,
  GQL_DELETE_WAITING_PHARMACIST,
  GQL_UPDATE_WAITING_PHARMACIST,
  GQL_LIST_HOSPITAL_FOR_PHARMACY_CHOOSE,
  GQL_GET_WAITING_DOCTOR,
  GQL_UPDATE_DOCTOR_SCHEDULE,
} from '../gqls_common'

export function useCommonAPIs() {
  const simulateDelay = AppOptions.SIMULATE_DELAY
  const listNotice = useODQuery<GQLListableNoticeInput, GQLListableNotice>(GQL_LIST_NOTICE, simulateDelay)
  const getNotice = useODQuery<GQLSingleIDInput, GQLNotice>(GQL_GET_NOTICE, simulateDelay)
  const listMedicalSubjects = useODQuery<GQLListableMedicalSubjectInput, GQLListableMedicalSubject>(
    GQL_LIST_MEDICAL_SUBJECTS,
    simulateDelay
  )

  const listHospital = useODQuery<Partial<GQLListableHospitalInput>, GQLListableHospital>(
    GQL_LIST_HOSPITAL,
    simulateDelay
  )
  const getHospitalOfManager = useODQuery<void, GQLHospital>(GQL_GET_HOSPITAL_OF_MANAGER, simulateDelay)

  const getWaitingDoctor = useODQuery<Partial<GQLWaitingDoctorUpdateInput>, GQLWaitingDoctor>(
    GQL_GET_WAITING_DOCTOR,
    simulateDelay
  )

  const getDoctor = useODQuery<GQLSingleIDInput, GQLDoctor>(GQL_READ_DOCTOR, simulateDelay)
  const listDoctor = useODQuery<GQLListableDoctorInput, GQLListableDoctor>(GQL_LIST_DOCTOR, simulateDelay)
  const listWaitingDoctor = useODQuery<Partial<GQLListableWaitingDoctorInput>, GQLListableWaitingDoctorOutput>(
    GQL_LIST_WAITING_DOCTOR,
    simulateDelay
  )

  const updateDoctor = useODMutation<Partial<GQLDoctorUpdateInputByManager>, GQLDoctor>(
    GQL_UPDATE_DOCTOR,
    simulateDelay
  )
  const updateWaitDoctor = useODMutation<Partial<GQLWaitingDoctorUpdateInput>, GQLWaitingDoctor>(
    GQL_UPDATE_WAITING_DOCTOR,
    simulateDelay
  )
  const approveApi = useODMutation<GQLSingleIDInput, GQLDoctor>(GQL_APPROVED_BY_ADMIN, simulateDelay)
  const holdApi = useODMutation<GQLDoctorApprovalHoldInput, GQLWaitingDoctor>(GQL_HOLD_BY_ADMIN, simulateDelay)
  const deleteWaitDoctor = useODMutation<GQLSingleIDInput, GQLOkResponse>(GQL_DELETE_WAITING_DOCTOR, simulateDelay)
  const deleteDoctorSchedule = useODMutation<Partial<GQLSingleIDInput>, GQLOkResponse>(
    GQL_DELETE_DOCTOR_SCHEDULE,
    simulateDelay
  )

  const listPatient = useODQuery<GQLListablePatientInput, GQLListablePatient>(GQL_LIST_PATIENT, simulateDelay)

  const createPharmacyHasHospital = useODMutation<GQLPharmacyHasHospitalCreationInput, GQLPharmacyHasHospital>(
    GQL_CREATE_PHARMACY_HAS_HOSPITAL,
    simulateDelay
  )
  const removePharmacyHasHospital = useODMutation<GQLSingleIDInput, GQLOkResponse>(
    GQL_REMOVE_PHARMACY_HAS_HOSPITAL,
    simulateDelay
  )

  const finishReservation = useODMutation<GQLSingleIDInput, GQLOkResponse>(GQL_FINISH_RESERVATION, simulateDelay)

  const updateReservationByManager = useODMutation<Partial<GQLUpdateReservationByDoctorInput>, GQLReservation>(
    GQL_UPDATE_RESERVATION_BY_MANAGER,
    simulateDelay
  )
  const getPatient = useODQuery<GQLSingleIDInput, GQLPatient>(GQL_GET_PATIENT)

  const getPopupInfo = useODQuery<GQLPopupInfoSearchInput, GQLPopupInfo>(GQL_GET_POPUP_INFO)

  const listPopupInfo = useODQuery<GQLPopupInfoListInput, GQLListablePopupInfo>(GQL_LIST_POPUP_INFO)

  const resetAccountPassword = useODMutation<GQLResetAccountPasswordInput, GQLOkResponse>(GQL_RESET_ACCOUNT_PASSWORD)
  const changeAccountPassword = useODMutation<GQLChangeAccountPasswordInput, GQLOkResponse>(GQL_CHANGE_ACCOUNT_PASSWORD)

  const listScheduleSetting = useODQuery<GQLListableScheduleSettingInput, GQLListableScheduleSetting>(
    GQL_LIST_SCHEDULE_SETTING
  )
  const updateScheduleSetting = useODMutation<GQLScheduleSettingUpdateInput, GQLScheduleSetting>(
    GQL_UPDATE_SCHEDULE_SETTING
  )

  const listDiagNames = useODQuery<GQLListableDiagNameInput, GQLListableDiagName>(GQL_LIST_DIAG_NAME)
  const updateReservationDiagnosis = useODMutation<GQLUpdateReservationDiagnosisInput, GQLReservation>(
    GQL_UPDATE_RESERVATION_DIAGNOSIS
  )
  const requestPaymentForReservation = useODMutation<GQLReservationPaymentInput, GQLReservation>(GQL_PAY_RESERVATION)
  const startReservation = useODMutation<GQLSingleIDInput, GQLReservation>(GQL_START_RESERVATION)

  const listFaq = useODQuery<GQLListableFaqInput, GQLListableFaq>(GQL_LIST_FAQ)
  const getFaq = useODQuery<GQLSingleIDInput, GQLFaq>(GQL_GET_FAQ)

  const listInquiry = useODQuery<GQLListableInquiryInput, GQLListableInquiry>(GQL_LIST_INQUIRY)
  const getInquiry = useODQuery<GQLSingleIDInput, GQLInquiry>(GQL_GET_INQUIRY)
  const createInquiry = useODMutation<GQLInquiryCreationInput, GQLInquiry>(GQL_CREATE_INQUIRY)
  const listPatientMyData = useODQuery<GQLListableDataShareInput, GQLListableDataShare>(GQL_LIST_PATIENT_MYDATA)

  const doctorFindId = useODMutation<GQLDoctorFindIdInput, GQLDoctorFindIdOutput>(GQL_DOCTOR_FIND_ID)
  const checkLicense = useODQuery<GQLDoctorLicenseCheckInput, GQLOkResponse>(GQL_CHECK_LICENSE_NAME)
  const createDoctorByDoctor = useODMutation<Partial<GQLDoctorCreationByDoctorInput>, GQLWaitingDoctor>(
    GQL_CREATE_DOCTOR_BY_DOCTOR
  )
  const sendAuthMailAgain = useODQuery<GQLSingleIDInput, GQLOkResponse>(GQL_SEND_AUTH_MAIL_AGAIN)
  const updateEmailAndSendAuthMail = useODMutation<GQLDoctorUpdateEmailInput, GQLDoctorUpdateEmailOutput>(
    GQL_UPDATE_EMAIL_AND_SEND_AUTH_MAIL
  )

  const pharmacistFindId = useODMutation<GQLPharmacistFindIdInput, GQLPharmacistFindIdOutput>(GQL_PHARMACIST_FIND_ID)
  const checkLicenseNumberPharmacist = useODQuery<GQLCheckLicenseNumberPharmacistInput, GQLOkResponse>(
    GQL_CHECK_LICENSE_NAME_PHARMACIST
  )
  const listWaitingPharmacist = useODQuery<
    Partial<GQLListableWaitingPharmacistInput>,
    GQLListableWaitingPharmacistOutput
  >(GQL_LIST_WAITING_PHARMACIST, simulateDelay)
  const createWaitingPharmacist = useODMutation<Partial<GQLCreateWaitingPharmacistInput>, GQLWaitingPharmacist>(
    GQL_CREATE_WAITING_PHARMACIST
  )
  const getWaingPharmacist = useODMutation<GQLSingleIDInput, GQLWaitingPharmacist>(
    GQL_GET_WAITING_PHARMACIST,
    simulateDelay
  )
  const updateWaitingPharmacist = useODMutation<Partial<GQLUpdateWaitingPharmacistInput>, GQLWaitingPharmacist>(
    GQL_UPDATE_WAITING_PHARMACIST,
    simulateDelay
  )

  const sendWaitingPharmacistMailAgain = useODQuery<GQLSingleIDInput, GQLOkResponse>(
    GQL_SEND_WAITING_PHARMACIST_MAIL_AGAIN
  )
  const updateWaitingPharmacistEmailAndSendMail = useODMutation<
    GQLUpdateWaitingPharmacistEmailAndSendAuthMailInput,
    GQLUpdateWaitingPharmacistEmailAndSendAuthMailOutput
  >(GQL_UPDATE_WAITING_PHARMACIST_EMAIL_AND_SEND_AUTH_MAIL)

  const listPharmacist = useODQuery<GQLListablePatientInput, GQLListablePharmacistOutput>(
    GQL_LIST_PHARMACIST,
    simulateDelay
  )
  const updatePharmacist = useODMutation<Partial<GQLPharmacyUpdateInput>, GQLPharmacist>(
    GQL_UPDATE_PHARMACIST,
    simulateDelay
  )
  const deleteWaitingPharmacist = useODMutation<GQLSingleIDInput, GQLOkResponse>(
    GQL_DELETE_WAITING_PHARMACIST,
    simulateDelay
  )

  const approveWaitingPharmacistByAdmin = useODMutation<GQLSingleIDInput, GQLApproveWaitingPharmacistByAdminOutput>(
    GQL_APPROVE_WAITING_PHARMACIST_BY_ADMIN,
    simulateDelay
  )
  const holdWaitingPharmacistByAdmin = useODMutation<GQLHoldWaitingPharmacistByAdminInput, GQLWaitingPharmacist>(
    GQL_HOLD_WAITING_PHARMACIST_BY_ADMIN,
    simulateDelay
  )

  const listHospitalForPharmacyChoose = useODQuery<Partial<GQLListableHospitalInput>, GQLListableHospital>(
    GQL_LIST_HOSPITAL_FOR_PHARMACY_CHOOSE,
    simulateDelay
  )

  const updateDoctorSchedule = useODMutation<Partial<GQLDoctorUpdateScheduleInput>, GQLDoctor>(
    GQL_UPDATE_DOCTOR_SCHEDULE,
    simulateDelay
  )

  return {
    listNotice,
    getNotice,
    listMedicalSubjects,
    listHospital,
    getHospitalOfManager,
    getDoctor,
    listDoctor,
    listWaitingDoctor,
    updateDoctor,
    updateWaitDoctor,
    approveApi,
    holdApi,
    deleteWaitDoctor,
    deleteDoctorSchedule,
    listPatient,
    createPharmacyHasHospital,
    removePharmacyHasHospital,
    finishReservation,
    updateReservationByManager,
    getPatient,
    resetAccountPassword,
    changeAccountPassword,
    listScheduleSetting,
    updateScheduleSetting,
    listDiagNames,
    updateReservationDiagnosis,
    requestPaymentForReservation,
    startReservation,
    listFaq,
    getFaq,
    listInquiry,
    getInquiry,
    createInquiry,
    getPopupInfo,
    listPopupInfo,
    listPatientMyData,
    doctorFindId,
    checkLicense,
    createDoctorByDoctor,
    sendAuthMailAgain,
    updateEmailAndSendAuthMail,
    createWaitingPharmacist,
    sendWaitingPharmacistMailAgain,
    updateWaitingPharmacistEmailAndSendMail,
    checkLicenseNumberPharmacist,
    pharmacistFindId,
    listPharmacist,
    listWaitingPharmacist,
    updatePharmacist,
    approveWaitingPharmacistByAdmin,
    holdWaitingPharmacistByAdmin,
    getWaingPharmacist,
    deleteWaitingPharmacist,
    updateWaitingPharmacist,
    listHospitalForPharmacyChoose,
    getWaitingDoctor,
    updateDoctorSchedule,
  }
}
