import { ODIcons, SingleQueryWrapper, useODQuery2 } from '@mdpp/od-react-belt'
import React from 'react'
import styled from 'styled-components'
import { EvixDashboardCard } from '../../common/card/EvixDashboardCard'
import { ODHSpace } from '@mdpp/od-react-belt'
interface IAdminDashboardContainerProps {}

const GQL_DOCTOR_DASHBOARD = `
query {
  systemStat {
    numPatients
    numDoctors
    numReservations
  }
}
`

export const AdminDashboardContainer: React.FC<IAdminDashboardContainerProps> = props => {
  const { api } = useODQuery2<any, any>(GQL_DOCTOR_DASHBOARD, { skip: true })

  return (
    <Wrapper>
      <SingleQueryWrapper deps={[]} getter={() => api({})}>
        {({ data }) => {
          const {
            systemStat: { numPatients, numDoctors, numReservations },
          } = data
          return (
            <>
             <CardWrap>
                <EvixDashboardCard
                  title="총 가입 환자"
                  mainColor="var(--second)"
                  contents={`${numPatients.toLocaleString()}`}
                />
            </CardWrap>
             <CardWrap>
              <EvixDashboardCard
                title="등록 의사"
                icon={ODIcons.CoreClipboard}
                mainColor="#f2ac39"
                contents={`${numDoctors.toLocaleString()}`}
              />
              </CardWrap>
              <CardWrap>
                <EvixDashboardCard
                  title="진료 예약"
                  icon={ODIcons.Inquiry}
                  mainColor="var(--mpPrimary500)"
                  contents={`${numReservations.toLocaleString()}`}
                />
              </CardWrap>
            </>
          )
        }}
      </SingleQueryWrapper>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  max-width: 1280px;
  width: 100%;
  padding: 36px 50px;
  display: flex;
  flex-direction: row; 
  justify-items: center;
  gap: 24px;

  @media only screen and (max-width: 570px) {
    flex-direction: column;    
    padding: 20px;
  }
`

const CardWrap = styled.div`
  flex: 1 
`