/* tslint:disable:no-console */
// @ts-ignore
import AgoraRTC from 'agora-rtc-sdk'
import React from 'react'
import { AppOptions } from '../../../../AppOptions'
import { useEvixRoom } from '../context/EvixRoomContext'
import { VIDEO_TAG_ID } from '../RoomCommon'

interface IStreamStateChangeEvent {
  attr: unknown
  stream: any
  type: 'mute-video' | 'unmute-video' | 'mute-audio' | 'unmute-audio' | 'stream-updated'
  uid: string
}

export function useAgoraVideoRoom(
  userId: string,
  channelName: string,
  token: string,
  videoTagIdLocalStream: string,
  videoTagIdRemoteStream: string,
  videoTagIdScreenStream: string,
  onJoinError: (msg: string) => void
) {
  const [client] = React.useState(() => AgoraRTC.createClient({ mode: 'live', codec: 'h264' }))
  const [screenClient] = React.useState(() => AgoraRTC.createClient({ mode: 'rtc', codec: 'vp8' }))

  const localStream = React.useRef<AgoraRTC.Stream | null>(null)
  const screenStream = React.useRef<AgoraRTC.Stream | null>(null)

  // const remoteStreams = React.useRef<{ [key: string]: AgoraRTC.Stream }>({})
  const [, setRemoteStream] = React.useState<AgoraRTC.Stream | null>(null)
  const {
    setPatientJoined,
    setShareScreenOnOff,
    setSelectedVideoId,
    isMicOn,
    setMicOnOff,
    isVideoOn,
    setVideoOnOff,
    setIsPatientMicOn,
    setIsPatientVideoOn,
  } = useEvixRoom()

  const localScreenStreamUids = React.useRef<any[]>([])

  const enableScreenSharing = React.useCallback(() => {
    screenClient.init(
      AppOptions.AGORA_APP_ID,
      () => {
        if (screenStream.current) {
          screenStream.current.init(
            () => {
              localScreenStreamUids.current.push(screenStream.current!.getId())
              screenStream.current!.play(videoTagIdScreenStream)
              // screenClient.publish(screenStream.current!)
              setSelectedVideoId(VIDEO_TAG_ID.Screen)
              setShareScreenOnOff(true)
            },
            (err: any) => {
              if (err?.msg === 'NotAllowedError') {
                console.log(`User cancelled screen sharing`)
              }
            }
          )
          return
        }

        screenClient.join(
          token,
          channelName,
          null,
          undefined,
          (uid: string | number) => {
            // Set relevant properties according to the browser.
            // Note that you need to implement isFirefox and isCompatibleChrome.
            // if (isFirefox()) {
            //     streamSpec.mediaSource = 'window';
            // } else if (!isCompatibleChrome()) {
            //     streamSpec.extensionId = 'minllpmhdgpndnkomcoccfekfegnlikg';
            // }
            if (!screenStream.current) {
              screenStream.current = AgoraRTC.createStream({
                streamID: userId,
                audio: false,
                video: false,
                screen: true,
              })
              screenStream.current!.on('stopScreenSharing', () => {
                disableScreenSharing()
              })
            }

            screenStream.current.init(
              () => {
                localScreenStreamUids.current.push(uid)
                screenStream.current!.play(videoTagIdScreenStream)
                screenClient.publish(screenStream.current!)
                setSelectedVideoId(VIDEO_TAG_ID.Screen)
                setShareScreenOnOff(true)
              },
              (err: any) => {
                if (err?.msg === 'NotAllowedError') {
                  console.log(`User cancelled screen sharing`)
                }
              }
            )
          },
          (err: any) => {
            console.log(75, err)
          }
        )
      },
      (err: any) => {
        console.error(55, '[useAgoraVideoRoom]', err)
      }
    )
  }, [setShareScreenOnOff, userId, screenClient, channelName, token, videoTagIdScreenStream, setSelectedVideoId])

  const disableScreenSharing = React.useCallback(() => {
    console.log(82, 'disableScreenSharing')
    if (screenStream.current) {
      screenClient.leave()
      screenStream.current.stop()
      screenStream.current.close()
      screenStream.current = null
    }
    setShareScreenOnOff(false)
    setSelectedVideoId(videoId => {
      if (videoId === VIDEO_TAG_ID.Screen) {
        return VIDEO_TAG_ID.Patient
      }
      return videoId
    })
  }, [screenClient, setShareScreenOnOff, setSelectedVideoId])

  const playLocalStream = React.useCallback(() => {
    localStream.current?.play(videoTagIdLocalStream, { fit: 'contain' }, (errorState: any) => {
      if (errorState) {
        // tslint:disable-next-line
        console.error(`Error playing local stream on tagId = ${videoTagIdLocalStream}`)
        // tslint:disable-next-line
        console.error(errorState)
      }
    })
  }, [videoTagIdLocalStream])

  const handleRemoteStreamChange = React.useCallback(
    (event: IStreamStateChangeEvent) => {
      const isMe = event.uid === userId
      if (isMe) {
        return
      }

      // assuming single remote stream.
      switch (event.type) {
        case 'mute-video':
          setIsPatientVideoOn(false)
          break
        case 'unmute-video':
          setIsPatientVideoOn(true)
          break
        case 'mute-audio':
          setIsPatientMicOn(false)
          break
        case 'unmute-audio':
          setIsPatientMicOn(true)
          break
        case 'stream-updated': {
          const isVideoOn = event.stream.isVideoOn() && event.stream.isPlaying() && !event.stream.isPaused()
          const isAudioOn = event.stream.isAudioOn()
          setIsPatientVideoOn(isVideoOn)
          setIsPatientMicOn(isAudioOn)
        }
      }
    },
    [setIsPatientMicOn, setIsPatientVideoOn, userId]
  )

  const initLocalStream = React.useCallback(
    (streamID: string) => {
      const localStreamInit = AgoraRTC.createStream({
        streamID,
        audio: true,
        video: true,
        screen: false,
      })

      localStreamInit.init(() => {
        localStream.current = localStreamInit
        playLocalStream()

        client.init(
          AppOptions.AGORA_APP_ID,
          () => {
            console.log('[useAgoraVideoRoom] AgoraRTC client initialized')
            client.on('peer-online', (evt: any) => {
              console.log(`[useAgoraVideoRoom] Peer online =>`, { evt })
              setPatientJoined(true)
            })

            client.on('stream-added', (evt: any) => {
              const stream: AgoraRTC.Stream = evt.stream
              const isLocalStream = localScreenStreamUids.current.includes(stream.getId())
              console.log(
                '[useAgoraVideoRoom] New stream added: ' + stream.getId() + `, isLocal? = ${isLocalStream}`,
                evt
              )

              // local stream 을 subscribe 하면 additional charge 발생 가능하다고 함.
              if (!isLocalStream) {
                client.subscribe(stream, undefined, (err: any) => {
                  console.error(`[useAgoraVideoRoom] Subscribing to stream failed`, err)
                })
              }
            })

            client.on('stream-subscribed', (evt: any) => {
              const rStream: AgoraRTC.Stream = evt.stream
              console.log('[useAgoraVideoRoom] New stream subscribed: ' + rStream.getId(), evt)

              setRemoteStream(rStream)
              // setPatientJoined(true)
              rStream.play(videoTagIdRemoteStream, { fit: 'contain' }, (errorState: any) => {
                if (errorState) {
                  // tslint:disable-next-line
                  console.error(`Error playing remote stream on tagId = ${videoTagIdLocalStream}`)
                  // tslint:disable-next-line
                  console.error(errorState)
                }
              })
              // remoteStream.getVideoTrack()
              // remoteStreams.current[remoteStream.getId()] = remoteStream
              // addToTile(remoteStream)

              console.log(151, 'subscribed')
              rStream.on('player-status-change', (evtStatusChange: any) => {
                console.log(270, '[useAgoraVideoRoom]', evtStatusChange)
                // setVideoMuted(evt.streamId, evt.status !== 'play', evt.reason)
              })
            })

            client.on('stream-removed', (evt: any) => {
              console.log('[stream is removed]')
              const stream = evt.stream
              if (stream) {
                const streamId = stream.getId().toString()
                console.log('[useAgoraVideoRoom] Remote stream is removed ' + streamId)
                stream.stop()
                setRemoteStream(null)
                // setPatientJoined(false)
              }
            })

            client.on('exception', (evt: any) => {
              console.log(evt)
            })

            client.on('peer-leave', (evt: any) => {
              console.log(`[useAgoraVideoRoom] Peer leave =>`, { evt })
              // @ts-ignore
              const stream: AgoraRTC.Stream = evt.stream
              const streamId = evt.stream.getId().toString()
              console.log('[useAgoraVideoRoom] Remote stream is removed ' + streamId)
              setPatientJoined(false)
              stream.stop()
              setRemoteStream(null)

              // setRemoteStream(rStream)
              // setPatientJoined(true)
              // setIsPatientVideoOn(false)
              // setIsPatientMicOn(false)
              // stream.stop()
              // console.error('[useAgoraVideoRoom] Unhandled? peer-leave')
            })

            client.on('stream-updated', (evt: any) => {
              console.log(
                180,
                'stream-updated',
                { evt },
                evt.stream.isVideoOn(),
                evt.stream.isAudioOn(),
                evt.stream.isPlaying(),
                evt.stream.isPaused()
              )
              setRemoteStream(evt.stream)
              handleRemoteStreamChange(evt)
              // remoteStreams.current[evt.stream.getId()] = evt.stream
            })

            client.on('mute-video', handleRemoteStreamChange)
            client.on('unmute-video', handleRemoteStreamChange)
            client.on('mute-audio', handleRemoteStreamChange)
            client.on('unmute-audio', handleRemoteStreamChange)

            client.on('stream-published', function (evt: any) {
              console.log('[useAgoraVideoRoom] Publish local stream successfully')
            })

            console.log(`[useAgoraVideoRoom] Joining channel ${channelName} with ${userId}`)
            client.join(
              token,
              channelName,
              userId,
              undefined,
              (uid: number | string) => {
                console.log('[useAgoraVideoRoom] User ' + uid + ' join channel successfully')
                if (localStreamInit) {
                  client.publish(localStreamInit, function (err: string) {
                    console.log('[useAgoraVideoRoom] Publish local stream error: ' + err)
                  })
                }
              },
              (err: string) => {
                console.error(125, '[useAgoraVideoRoom]', err)
                onJoinError(err)
              }
            )
          },
          (err: any) => {
            console.log('[useAgoraVideoRoom] AgoraRTC client init failed', err)
          }
        )
      })
    },
    [
      handleRemoteStreamChange,
      onJoinError,
      playLocalStream,
      videoTagIdLocalStream,
      videoTagIdRemoteStream,
      channelName,
      client,
      setPatientJoined,
      token,
      userId,
    ]
  )

  const deinit = React.useCallback(() => {
    localStream.current?.close()
    screenStream.current?.close()
    client.leave()
  }, [client])

  React.useEffect(() => {
    initLocalStream(userId)
    return () => deinit()
  }, [initLocalStream, userId, deinit])

  const toggleAudioMute = React.useCallback(() => {
    if (localStream.current) {
      if (isMicOn) {
        localStream.current?.muteAudio()
        setMicOnOff(false)
      } else {
        localStream.current?.unmuteAudio()
        setMicOnOff(true)
      }
    }
  }, [isMicOn, setMicOnOff])

  const toggleVideoMute = React.useCallback(() => {
    if (localStream.current) {
      if (isVideoOn) {
        localStream.current?.muteVideo()
        setVideoOnOff(false)
      } else {
        localStream.current?.unmuteVideo()
        setVideoOnOff(true)
      }
    }
  }, [isVideoOn, setVideoOnOff])

  return { enableScreenSharing, disableScreenSharing, toggleAudioMute, toggleVideoMute }
}
