import React from 'react'
import styled from 'styled-components'
import {ODIcons, ODVSpace} from '@mdpp/od-react-belt'
import { GQLPatient, GQLSMOKE_HISTORY_TYPE } from '@mdpp/common/lib/@types/server.schema'
import { EvixCardOneThirds } from '../../../../common/card/EvixCardOneThirds'
import { EvixCardHeader } from '../../../../common/card/EvixCardHeader'
import { EvixCardLabel } from '../../../../common/card/EvixCardLabel'
import { EvixCardRow } from '../../../../common/card/EvixCardRow'
import { EvixCardRowContent } from '../../../../common/card/EvixCardRowContent'
import { EvixCardRowMultilineContents } from '../../../../common/card/EvixCardRowMultilineContents'
import { ReservationBasicInfoBoxStyle } from '../../../../common/reservation/ReservationCommon'

interface IPatientBodyInfoCardProps {
  patient: GQLPatient
  infoStyle: ReservationBasicInfoBoxStyle
  isFinishedMedical?: boolean
  onlyAllergy?:boolean
}

export const PatientBodyInfoCard: React.FC<IPatientBodyInfoCardProps> = props => {
  const { patient, infoStyle, isFinishedMedical, onlyAllergy } = props

  const allergies = (patient.allergy ?? '')
    .split(',')
    .map(v => v.trim())
    .filter(v => !!v)

  const interests = (patient.interests ?? '')
    .split(',')
    .map(v => v.trim())
    .filter(v => !!v)

  const isInsideRoom = infoStyle === ReservationBasicInfoBoxStyle.InsideRoom
  const cardStyle = isInsideRoom ? { border: 0, width: '100%' } : {
      display: 'flex',
      background: '#FFFFFF',
      boxShadow: '0px 0px 5px rgba(23, 24, 24, 0.05), 0px 1px 2px rgba(0, 0, 0, 0.15)',
      borderRadius: 8,
      border: 0,
  }
  const pIcon = isInsideRoom ? null : ODIcons.MaterialPerson

  return (
    <EvixCardOneThirds style={{ flexGrow: 0, flexShrink: 1, ...cardStyle }}>
      {isInsideRoom ?
        <EvixCardHeader icon={pIcon} title="신체정보" isInsideRoom /> :
        <EvixCardHeader icon={pIcon} title="신체정보"  />
      }
      <ODVSpace h={24} />
      <TableListWrapper>
          <EvixCardRow>
              <EvixCardLabel>신장</EvixCardLabel>
              <EvixCardRowContent>{patient.height || '-'} cm</EvixCardRowContent>
          </EvixCardRow>
          <EvixCardRow>
              <EvixCardLabel>체중</EvixCardLabel>
              <EvixCardRowContent>{patient.weight || '-'} kg</EvixCardRowContent>
          </EvixCardRow>
          <EvixCardRow>
              <EvixCardLabel>알레르기</EvixCardLabel>
              <EvixCardRowMultilineContents
                  mainText={allergies.length > 0 ? '있음' : '없음'}
                  subTexts={allergies.map(v => `${v}`)}
                  subTextPrimaryColor
              />
          </EvixCardRow>
          {!onlyAllergy && (
              <EvixCardRow>
                  <EvixCardLabel>관심분야</EvixCardLabel>
                  <Wrapper>
                      {interests.map((i, idx) => (
                          <InterestText key={idx}>{i}</InterestText>
                      ))}
                      {interests.length === 0 && <InterestText key={'none'}>없음</InterestText>}
                  </Wrapper>
              </EvixCardRow>
          )}
      </TableListWrapper>

    </EvixCardOneThirds>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const InterestText = styled.div`
  font-size: 14px;
  line-height: 1.57;
  color: var(--gray800);
`
const TableListWrapper = styled.div`
    gap: 24px;
    display:flex;
    flex-direction: column;
`