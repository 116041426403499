import React from 'react'
import { LogoutPage } from '../login/LogoutPage'
import { SiteUrls } from '../SiteUrls'
import { useAdminAppContext } from './context/AdminAppContext'

interface IAdminLogoutPageProps {}

export const AdminLogoutPage: React.FC<IAdminLogoutPageProps> = props => {
  const { logout } = useAdminAppContext()
  return <LogoutPage logout={logout} urlAfterLogout={SiteUrls.Admin.Login} />
}
