import { produce } from 'immer'
import * as React from 'react'
import { GQLLoginPharmacistInput, GQLPharmacist } from '@mdpp/common/lib/@types/server.schema'
import { AppOptions, I_ADMIN_OPTIONS, LOCAL_STORAGE_KEY_TOKEN } from '../../AppOptions'
import { usePharmacyAPIs } from './usePharmacyAPIs'
import { useCommonAPIs } from '../../common/hooks/useCommonAPIs'
import { WebNotification } from '../../utils/WebNotification'

interface IPharmaAppContextType {
  state: IPharmaAppReducerState
  login: (input: GQLLoginPharmacistInput) => Promise<GQLPharmacist>
  logout: () => Promise<void>
  refreshProfile?: () => Promise<GQLPharmacist>
}

export enum LOGIN_STATE {
  Checking = 'Checking',
  LoggedOut = 'LoggedOut',
  LoggingIn = 'LoggingIn',
  LoggedIn = 'LoggedIn',
  LoggingOut = 'LoggingOut',
}

interface IPharmaAppContextProviderProps {}

interface IPharmaAppReducerState {
  loginState: LOGIN_STATE
  profile?: GQLPharmacist | null
}

enum PharmacyAppActionType {
  SetLoggedIn = 'odDoctorApp/SetLoggedIn',
  SetLoggedOut = 'odDoctorApp/SetLoggedOut',
  SetAppConfig = 'odDoctorApp/SetAppConfig',
  SetNodeClick = 'odDoctorApp/SetNodeClick', // whenever directory view's node is clicked, count++
}

interface IPharmaAppActionSetLoggedIn {
  type: PharmacyAppActionType.SetLoggedIn
  profile?: GQLPharmacist
}

interface IPharmaAppActionSetLoggedOut {
  type: PharmacyAppActionType.SetLoggedOut
}

type PharmaAppReducerAction = IPharmaAppActionSetLoggedIn | IPharmaAppActionSetLoggedOut

interface IPharmaAppReducer extends React.Reducer<IPharmaAppReducerState, PharmaAppReducerAction> {}

function createAppReducer(): IPharmaAppReducer {
  return function (state: IPharmaAppReducerState, action: PharmaAppReducerAction): IPharmaAppReducerState {
    return produce(state, draft => {
      switch (action.type) {
        case PharmacyAppActionType.SetLoggedIn:
          draft.loginState = LOGIN_STATE.LoggedIn
          draft.profile = action.profile
          break
        case PharmacyAppActionType.SetLoggedOut:
          draft.loginState = LOGIN_STATE.LoggedOut
          break
        default:
          return
      }
    })
  }
}

const actionSetLoggedIn = (profile: GQLPharmacist): IPharmaAppActionSetLoggedIn => ({
  type: PharmacyAppActionType.SetLoggedIn,
  profile,
})
const actionSetLoggedOut = (): IPharmaAppActionSetLoggedOut => ({ type: PharmacyAppActionType.SetLoggedOut })

function createInitialAppReducerState(): IPharmaAppReducerState {
  return {
    loginState: LOGIN_STATE.Checking,
    profile: null,
  }
}

function createPharmacyAppContext(appOptions: I_ADMIN_OPTIONS) {
  const Context: React.Context<IPharmaAppContextType> = React.createContext<IPharmaAppContextType>(
    // tslint:disable-next-line
    {} as IPharmaAppContextType
  )

  const PharmacyProvider: React.FC<IPharmaAppContextProviderProps> = props => {
    const { children } = props

    const { loginPharmacist, getPharmacistMyProfile, updatePharmacist, logout: apiLogout } = usePharmacyAPIs()

    const [state, dispatch] = React.useReducer<IPharmaAppReducer>(createAppReducer(), createInitialAppReducerState())
    const setLoggedIn = React.useCallback((profile: GQLPharmacist) => dispatch(actionSetLoggedIn(profile)), [dispatch])

    const checkLogin = React.useCallback(async () => {
      try {
        const profile = await getPharmacistMyProfile()
        setLoggedIn(profile)

        // fcm token
        const webFcmToken = await WebNotification.getToken()
        if (webFcmToken !== profile.webFcmToken)
          await updatePharmacist({ pharmacistId: profile.pharmacistId, webFcmToken })
        WebNotification.onMessageListener()
      } catch (ex) {
        // 로그인 실패, 로그아웃된 상태
        dispatch(actionSetLoggedOut())
      }
    }, [getPharmacistMyProfile, setLoggedIn])

    const login = React.useCallback(
      async (data: GQLLoginPharmacistInput): Promise<GQLPharmacist> => {
        const res = await loginPharmacist(data)
        localStorage.setItem(LOCAL_STORAGE_KEY_TOKEN, res.token)
        setLoggedIn(res.me)
        const webFcmToken = await WebNotification.getToken()
        if (webFcmToken) {
          await updatePharmacist({ pharmacistId: res.me.pharmacistId, webFcmToken })
          WebNotification.onMessageListener()
        }
        return res.me
      },
      [loginPharmacist, setLoggedIn]
    )

    const logout = React.useCallback(async () => {
      const profile = await getPharmacistMyProfile()
      WebNotification.deleteToken()
      await updatePharmacist({ pharmacistId: profile.pharmacistId, webFcmToken: null })
      await apiLogout()
      localStorage.removeItem(LOCAL_STORAGE_KEY_TOKEN)
      const managerEmail = localStorage.getItem('managerEmail')
      const doctorEmail = localStorage.getItem('doctorEmail')
      const adminEmail = localStorage.getItem('adminEmail')
      const pharmacyEmail = localStorage.getItem('pharmacyEmail')
      managerEmail ? localStorage.setItem('managerEmail', managerEmail) : console.log('not remember manager !')
      doctorEmail ? localStorage.setItem('doctorEmail', doctorEmail) : console.log('not remember doctorEmail !')
      adminEmail ? localStorage.setItem('adminEmail', adminEmail) : console.log('not remember adminEmail !')
      pharmacyEmail ? localStorage.setItem('pharmacyEmail', pharmacyEmail) : console.log('not remember pharmacyEmail !')
      dispatch(actionSetLoggedOut())
    }, [apiLogout])

    const refreshProfile = React.useCallback(async () => {
      const profile = await getPharmacistMyProfile()
      setLoggedIn(profile)
      return profile
    }, [getPharmacistMyProfile, setLoggedIn])

    React.useEffect(() => {
      // noinspection JSIgnoredPromiseFromCall
      checkLogin()
    }, [checkLogin])

    const context: IPharmaAppContextType = {
      state,
      login,
      logout,
      refreshProfile,
    }
    return <Context.Provider value={context}>{children}</Context.Provider>
  }

  return { Context, Provider: PharmacyProvider }
}

const { Context: AppContext, Provider } = createPharmacyAppContext(AppOptions)

export const PharmacyAppProvider = Provider
export const PharmacyAppContext = AppContext

export function usePharmacyAppContext(): IPharmaAppContextType {
  return React.useContext(AppContext)
}
