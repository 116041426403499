import React from 'react'
import { ReviewListableTable } from '../../../common/review/list/ReviewListableTable'

interface IAdminDoctorReviewPageProps {
  doctorId: number
}

export const AdminDoctorReviewPage: React.FC<IAdminDoctorReviewPageProps> = props => {
  return <ReviewListableTable dataLoaderOptions={{ doctorId: props.doctorId }} noSearch simpleColumns />
}
