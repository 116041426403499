import React from 'react'
import moment from 'moment-timezone'
import _ from 'lodash'
import arrowUp from '../assets/mydata/ic_action24px_arrow_up.png'
import arrowDown from '../assets/mydata/ic_action24px_arrow_down.png'
import { MEDICAL_DATA_SHARE_ITEM, MEDICATION } from '@mdpp/common/lib/mydata/PublicDataType'
import { OBSERVATION, PRESCRIPTION, PROCEDURE, TEST } from '@mdpp/common/lib/mydata/HospitalDataTypes'

interface MyDetailDataProps {
  info?: MEDICAL_DATA_SHARE_ITEM | null
}

const getMedicineTitle = (medicine: MEDICATION[] | null = []) => {
  if (medicine && medicine.length == 1)
    return (medicine && medicine.length > 0 && `${medicine[0].medicineKr} `) || `제공가능한 기록이 없습니다.`
  else
    return (
      (medicine && medicine.length > 0 && `${medicine[0].medicineKr} 외 ${medicine.length - 1}건`) ||
      `제공가능한 기록이 없습니다.`
    )
}
const getObservationTitle = (medicine: OBSERVATION[] | null = []) => {
  if (medicine && medicine.length === 1) return medicine[0].name
  else
    return (
      (medicine && medicine.length > 0 && `${medicine[0].name} 외 ${medicine.length - 1}건`) ||
      `제공가능한 기록이 없습니다.`
    )
}

const deptMap = new Map()
const initDept = () => {
  deptMap.set(0, '일반의')
  deptMap.set(1, '내과')
  deptMap.set(2, '신경과')
  deptMap.set(3, '정신건강의학과')
  deptMap.set(4, '외과')
  deptMap.set(5, '정형외과')
  deptMap.set(6, '신경외과')
  deptMap.set(7, '흉부외과')
  deptMap.set(8, '성형외과')
  deptMap.set(9, '마취통증의학과')
  deptMap.set(10, '산부인과')
  deptMap.set(11, '소아청소년과')
  deptMap.set(12, '안과')
  deptMap.set(13, '이비인후과')
  deptMap.set(14, '피부과')
  deptMap.set(15, '비뇨의학과')
  deptMap.set(16, '영상의학과')
  deptMap.set(17, '방사선종양학과')
  deptMap.set(18, '병리과')
  deptMap.set(19, '진단검사의학과')
  deptMap.set(20, '결핵과')
  deptMap.set(21, '재활의학과')
  deptMap.set(22, '핵의학과')
  deptMap.set(23, '가정의학과')
  deptMap.set(24, '응급의학과')
  deptMap.set(25, '직업환경의학과')
  deptMap.set(26, '예방의학과')
  deptMap.set(44, '보건기관한방')
  deptMap.set(49, '치과')
  deptMap.set(50, '구강악안면외과')
  deptMap.set(51, '치과보철과')
  deptMap.set(52, '치과교정과')
  deptMap.set(53, '소아치과')
  deptMap.set(54, '치주과')
  deptMap.set(55, '치과보존과')
  deptMap.set(56, '구강내과')
  deptMap.set(57, '영상치의학과')
  deptMap.set(58, '구강병리과')
  deptMap.set(59, '예방치과')
  deptMap.set(60, '치과소계')
  deptMap.set(61, '통합치의학과')
  deptMap.set(80, '한방내과')
  deptMap.set(81, '한방부인과')
}

const getDeptName = (code: string) => {
  let deptName: any
  if (isNaN(Number(code))) {
    deptName = code
  } else {
    const name = deptMap.get(Number(code))
    deptName = name
  }
  return deptName
}

const getMainDisease = (prescriptionData: PRESCRIPTION[]) => {
  let main: any
  prescriptionData.some(items => {
    if (items.condition[0]?.disease.main === '주' || items.condition[0]?.disease.main === 'MAIN') {
      main = items.condition[0]?.disease
    }
  })
  if (main === undefined) {
    prescriptionData.some(items => {
      if (items.condition[0]?.disease.main != '주' && items.condition[0]?.disease.main != 'MAIN') {
        main = items.condition[0]?.disease
      }
    })
  }
  // console.log('main : ',main)
  return main
}

const getPrescriptionTitle = (prescriptionData: PRESCRIPTION[]) => {
  let title = ''
  let count = 0
  prescriptionData.some(items => {
    count = count + items.procedure.length
    //console.log('count : ',count, ', items.procedure.length : ',items.procedure.length )
    if (items.procedure.length > 0) {
      title = items.procedure[0].name
    }
  })
  return count > 1 ? title + ' 외 ' + (count - 1) + '건' : title
}
const getMedicationTitle = (prescriptionData: PRESCRIPTION[]) => {
  let title = ''
  let count = 0
  prescriptionData.some(items => {
    count = count + items.medication.length
    //console.log('count : ',count, ', items.medication.length : ',items.medication.length )
    if (items.medication.length > 0) {
      title = items.medication[0].medicine.name
    }
  })
  return count > 1 ? title + ' 외 ' + (count - 1) + '건' : title
}

export const MyDetailData: React.FC<MyDetailDataProps> = props => {
  const { info } = props

  initDept()
  //console.log('MyDetailData info : ', info)

  let observation //검사
  let condition: any[] = [] //상병
  let medication: any[] = [] //투약
  let procedure: any[] = [] //처치
  const hospitalData = info?.hospitalData

  let mainDisease: string = ''
  let subDisease: string[] = []
  let found = null

  let dateMap = new Map<string, Map<string, any[]>>()

  if (hospitalData) {
    observation = hospitalData.observation
    const prescription = hospitalData.prescription

    prescription.map((item: any) => {
      const conditionList: any[] = item.condition
      const medicationList: any[] = item.medication
      const procedureList: any[] = item.procedure

      conditionList.forEach((c: any) => condition.push(c))
      medicationList.forEach((c: any) => medication.push(c))
      procedureList.forEach((c: any) => procedure.push(c))

      conditionList.map((c: any) => {
        if (c.disease.main === '주') {
          mainDisease = c.disease.name
        }
        if (c.disease.main === '부' && c.disease.main !== 'MAIN') {
          found = subDisease.find(name => {
            if (name === c.disease.name) {
              return true
            }
            return false
          })
          if (!found && c.disease.name !== mainDisease) {
            subDisease.push(c.disease.name)
          }
        }
      })
    })
    //검사
    observation.forEach((m: any) => {
      let d = moment(m.date).format('YYYY.MM.DD')
      let subMap: Map<string, any[]> | undefined = new Map<string, any[]>()
      let mList = []

      if (dateMap.has(d)) {
        subMap = dateMap.get(d)
        if (subMap?.has('observation')) {
          // @ts-ignore
          mList = subMap.get('observation')
          mList.push(m)
        } else {
          mList.push(m)
          subMap?.set('observation', mList)
        }
      } else {
        mList.push(m)
        subMap.set('observation', mList)
      }
      // @ts-ignore
      dateMap.set(moment(m.date).format('YYYY.MM.DD'), subMap)
    })

    //투약
    medication.forEach((m: any) => {
      let d = moment(m.date).format('YYYY.MM.DD')
      let subMap: Map<string, any[]> | undefined = new Map<string, any[]>()
      let mList = []
      let counted: any

      counted = m.meta.count

      if (dateMap.has(d)) {
        subMap = dateMap.get(d)
        if (subMap?.has('medication')) {
          // @ts-ignore
          mList = subMap.get('medication')
          if (counted == 1) {
            mList.push(m.medicine)
          }
          if (counted > 1) {
            for (let i = 0; i < counted; i++) {
              mList.push(m.medicine)
            }
          }
        } else {
          mList.push(m.medicine)
          subMap?.set('medication', mList)
        }
      } else {
        if (counted == 1) {
          mList.push(m.medicine)
          subMap.set('medication', mList)
        }
        if (counted > 1) {
          for (let i = 0; i < counted; i++) {
            mList.push(m.medicine)
            subMap.set('medication', mList)
          }
        }
      }
      // @ts-ignore
      dateMap.set(moment(m.date).format('YYYY.MM.DD'), subMap)
    })
    //처치
    procedure.forEach((m: any) => {
      let d = moment(m.date).format('YYYY.MM.DD')
      let subMap: Map<string, any[]> | undefined = new Map<string, any[]>()
      let mList = []
      let counted: any

      counted = m.meta.count

      if (dateMap.has(d)) {
        subMap = dateMap.get(d)
        if (subMap?.has('procedure')) {
          // @ts-ignore
          mList = subMap.get('procedure')
          if (counted == 1) {
            mList.push(m)
          }
          if (counted > 1) {
            for (let i = 0; i < counted; i++) {
              mList.push(m)
            }
          }
        } else {
          mList.push(m)
          subMap?.set('procedure', mList)
        }
      } else {
        if (counted == 1) {
          mList.push(m)
          subMap.set('procedure', mList)
        }
        if (counted > 1) {
          for (let i = 0; i < counted; i++) {
            mList.push(m)
            subMap.set('procedure', mList)
          }
        }
      }
      // @ts-ignore
      dateMap.set(moment(m.date).format('YYYY.MM.DD'), subMap)
    })
    // console.log('observation : ',observation)
    // console.log('condition : ',condition)
    // console.log('medication : ',medication)
    // console.log('procedure : ',procedure)
  }
  let sortedMap: Map<string, Map<string, any[]>> = new Map(Array.from(dateMap).sort(([a], [b]) => a.localeCompare(b)))
  let dateKeys = Array.from(sortedMap.keys())
  // console.log('sortedMap : ',sortedMap)
  // console.log('dateKeys : ',dateKeys)

  const [expandMedicine, setExpandMedicine] = React.useState<boolean>(true)

  if (!info) {
    return null
  }
  // const mapDate = new Map<string, string>();
  //
  // // info.publicTreatmentAndMedicationData?.some((m:any) => e.foo === 'bar')
  // if(info.publicTreatmentAndMedicationData?.class.includes('입원')){
  //
  // }${moment(selectedCard.publicTreatmentAndMedicationData.startDate).add(selectedCard.publicTreatmentAndMedicationData.period,'days').format('YYYY-MM-DD')}

  return (
    <div className="detail-wrap">
      {/* day  */}
      <div className="date-wrap">
        <div className="date">
          {info.publicTreatmentAndMedicationData
            ? info.publicTreatmentAndMedicationData.class.includes('입원')
              ? moment(info.publicTreatmentAndMedicationData?.startDate).format(`YYYY.MM.DD (dd)`) +
                '~' +
                moment(info.publicTreatmentAndMedicationData?.startDate)
                  .add(info.publicTreatmentAndMedicationData.period, 'days')
                  .format(`YYYY.MM.DD (dd)`)
              : moment(info.publicTreatmentAndMedicationData?.startDate).format(`YYYY.MM.DD (dd)`)
            : info.hospitalData && info.class.includes('입원')
            ? moment(info.hospitalData.visit.start).format(`YYYY.MM.DD (dd)`) +
              '~' +
              moment(info.hospitalData.visit.end).format(`YYYY.MM.DD (dd)`)
            : moment(info.dateTime).format(`YYYY.MM.DD (dd)`)}
        </div>
        {/* 기본 section */}
        {info.hospitalData && (
          <>
            <div className="section">
              <div className="title">진료과</div>
              <div className="content">
                <span>
                  {info.hospitalData
                    ? info.hospitalData.prescription[0]?.condition[0]?.subject.division
                      ? info.hospitalData.prescription[0]?.condition[0]?.subject.division
                      : info.hospitalData.prescription[0]?.condition[0]?.subject.name
                      ? info.hospitalData.prescription[0]?.condition[0]?.subject.name
                      : getDeptName(info.hospitalData.prescription[0]?.condition[0]?.subject.code)
                    : null}

                  {info.hospitalData.prescription?.length === 0 && <span>진료과 정보 없음</span>}
                </span>
              </div>
            </div>
          </>
        )}

        {/* toggle section : toggle 펼쳐진 상태 */}

        {info.hospitalData && info.hospitalData?.prescription.length !== 0 && (
          <div className="section toggle show">
            <div className="title">상병명</div>
            <div className="content">
              <span>{getMainDisease(info.hospitalData?.prescription)?.name}</span>
              <div className="toggle-box">
                {mainDisease?.length > 0 && (
                  <div className="inner">
                    <div className="name">주진단</div>
                    <div className="detail" key={_.uniqueId()}>
                      {mainDisease}
                    </div>
                  </div>
                )}

                {subDisease.length > 0 && (
                  <div className="inner">
                    <div className="name">부진단</div>
                    {subDisease.map((disease: string) => (
                      <div className="detail" key={_.uniqueId()}>
                        {disease}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
            {/*<img src={arrowUp} />*/}
          </div>
        )}

        {(info.hospitalData && info.hospitalData.observation.length > 0 && (
          <div className="section toggle show">
            <div className="title">검사</div>
            <div className="content">
              <span>{getObservationTitle(info.hospitalData.observation)}</span>
              <div className="toggle-box">
                {info.hospitalData?.observation.map((o: OBSERVATION, index) => (
                  <div className="inner" key={_.uniqueId()}>
                    <div className="name">
                      [{moment(o.date).format('YYYY.MM.DD')}] <br />
                      검체명: {o.name}
                    </div>
                    {o.test.map((t: TEST) => (
                      <div className="detail-wrap" key={_.uniqueId()}>
                        <span className="name">{t.name}</span>
                        <div className="other f-row">
                          <span>{t.result}</span>
                          {/*<span>단위</span>*/}
                        </div>
                      </div>
                    ))}
                  </div>
                ))}

                {/*<div className="detail-wrap">*/}
                {/*  <span className="name">검사명</span>*/}
                {/*  <div className="other f-row">*/}
                {/*    <span>결과</span>*/}
                {/*    <span>단위</span>*/}
                {/*  </div>*/}
                {/*</div>*/}

                {/*<div className="inner">*/}
                {/*  <div className="name">검체명 1</div>*/}
                {/*  <div className="detail-wrap">*/}
                {/*    <span className="name">검사명</span>*/}
                {/*    <div className="other f-row">*/}
                {/*      <span>결과</span>*/}
                {/*      <span>단위</span>*/}
                {/*    </div>*/}
                {/*  </div>*/}
                {/*  <div className="detail-wrap">*/}
                {/*    <span className="name">검사명</span>*/}
                {/*    <div className="other f-row">*/}
                {/*      <span>결과</span>*/}
                {/*      <span>단위</span>*/}
                {/*    </div>*/}
                {/*  </div>*/}
                {/*</div>*/}
              </div>
            </div>
            {/*<img src={arrowUp} />*/}
          </div>
        )) ||
          null}

        {/* toggle section   */}
        {info.hospitalData?.prescription &&
          info.hospitalData?.prescription.length > 0 &&
          getPrescriptionTitle(info.hospitalData?.prescription) != '' && (
            <div className="section toggle show" key={_.uniqueId()}>
              <div className="title" key={_.uniqueId()}>
                시술 및 수술
              </div>
              <div className="content" key={_.uniqueId()}>
                {/*<span>{getPrescriptionTitle(info.hospitalData?.prescription)}</span>*/}
                <div className="toggle-box" key={_.uniqueId()}>
                  {dateKeys.map((date: string) =>
                    sortedMap
                      .get(date)
                      ?.get('procedure')
                      ?.map((proc: any, index) => (
                        <div className="inner" key={_.uniqueId()}>
                          {index == 0 ? (
                            <span className="name" key={_.uniqueId()}>
                              [{date}]
                            </span>
                          ) : (
                            ''
                          )}
                          <div className="name pb-0" key={_.uniqueId()}>{`처방: ${proc.name}`}</div>
                        </div>
                      ))
                  )}

                  {/*<div className="inner">*/}
                  {/*    <div className="name pb-0">처방명</div>*/}
                  {/*</div>*/}
                </div>
              </div>
              {/*<img src={arrowUp} />*/}
            </div>
          )}

        {/* toggle section  */}

        <div className={expandMedicine ? 'section toggle show' : 'section toggle hide'}>
          <div className="title">투약</div>
          <div className="content">
            {info.publicTreatmentAndMedicationData &&
              (info.publicTreatmentAndMedicationData.class.includes('조제') ||
                info.publicTreatmentAndMedicationData.class.includes('입원')) && (
                <>
                  <span key={_.uniqueId()}>{getMedicineTitle(info.publicTreatmentAndMedicationData.medication)}</span>
                  <div className="toggle-box" key={_.uniqueId()}>
                    {info.publicTreatmentAndMedicationData.medication?.map((m: MEDICATION) => (
                      <div className="inner f-row f-between" key={_.uniqueId()}>
                        <div className="f-column" key={_.uniqueId()}>
                          <div className="name pb-4" key={_.uniqueId()}>
                            {m.medicineKr}
                          </div>
                          <span className="detail" key={_.uniqueId()}>
                            {m.medicineEfficacy}
                          </span>
                        </div>
                        <div className="other2 f-column" key={_.uniqueId()}>
                          <span key={_.uniqueId()}>{`${m.dosingDays}일`}</span>
                          {/*<span>{`${m.count}회 / 일`}</span>*/}
                        </div>
                      </div>
                    ))}
                  </div>
                </>
              )}
            {info.hospitalData?.prescription &&
              info.hospitalData?.prescription.length > 0 &&
              getMedicationTitle(info.hospitalData?.prescription) != '' && (
                <>
                  {/*<span>{getMedicationTitle(info.hospitalData.prescription)}</span>*/}
                  <div className="toggle-box" key={_.uniqueId()}>
                    {
                      // const mapSort1 = new Map([...myMap.entries()].sort((a, b) => b[1] - a[1]));
                      dateKeys.map((date: string) =>
                        sortedMap
                          .get(date)
                          ?.get('medication')
                          ?.map((m: any, index) => (
                            // <div className="inner" >
                            //     {index==0?<span className="name">[{date}]</span>:''}
                            //     <div className="name pb-0">{ `처방: ${proc.name}`}</div>
                            // </div>
                            <div className="inner f-row f-between" key={_.uniqueId()}>
                              <div className="f-column" key={_.uniqueId()}>
                                {index == 0 ? (
                                  <span className="name" key={_.uniqueId()}>
                                    [{date}]
                                  </span>
                                ) : (
                                  ''
                                )}
                                {/*<span className="name">[{moment(m.date).format('YYYY.MM.DD')}]</span>*/}
                                <div className="name pb-4" key={_.uniqueId()}>
                                  {m.name}
                                </div>
                                {/*<span className="detail">{m.medicineEfficacy}</span>*/}
                              </div>
                              <div className="other2 f-column" key={_.uniqueId()}>
                                <span key={_.uniqueId()}>{`${m.period}일`}</span>
                                <span key={_.uniqueId()}>{`${m.value}x${m.frequency}회 / 일`}</span>
                              </div>
                            </div>
                          ))
                      )
                    }
                  </div>
                </>
              )}

            {info.publicTreatmentAndMedicationData &&
              !info.publicTreatmentAndMedicationData.class.includes('조제') &&
              !info.publicTreatmentAndMedicationData.class.includes('입원') && (
                <span>{`제공가능한 기록이 없습니다.`}</span>
              )}

            {info.hospitalData?.prescription &&
              (info.hospitalData?.prescription.length == 0 ||
                getMedicationTitle(info.hospitalData?.prescription) == '') && (
                <span>{`제공가능한 기록이 없습니다.`}</span>
              )}

            {info.class?.includes('치과외래') && info.publicTreatmentAndMedicationData === undefined && (
              <span>{`제공가능한 기록이 없습니다.`}</span>
            )}

            {info.class?.includes('일반외래') && info.publicTreatmentAndMedicationData === undefined && (
              <span>{`제공가능한 기록이 없습니다.`}</span>
            )}

            {info.class?.includes('조제') && info.publicTreatmentAndMedicationData === undefined && (
              <span>{`제공가능한 기록이 없습니다.`}</span>
            )}

            {info.class?.includes('조제') && info.publicTreatmentAndMedicationData?.medication?.length === 0 && (
              <span>{`제공가능한 기록이 없습니다.`}</span>
            )}
          </div>
          {/*<img src={expandMedicine ? arrowUp : arrowDown} onClick={(e) => {setExpandMedicine(!expandMedicine)}} />*/}
        </div>
      </div>

      {/* day  */}
      {/* <div className="date-wrap"> */}
      {/* <div className="date">2021.09.20 (월)</div> */}
      {/* 기본 section */}
      {/* <div className="section">
          <div className="title">진료과</div>
          <div className="content">
            <span>내분비내과</span>
          </div>
        </div> */}

      {/* toggle section */}
      {/* <div className="section toggle show">
          <div className="title">상병명</div>
          <div className="content">
            <span>상세불명의 당뇨병</span>
            <div className="toggle-box">
              <div className="inner">
                <div className="name pb-0">데이터가 없습니다.</div>
              </div>
            </div>
          </div>
          <img src={arrowUp} />
        </div> */}

      {/* toggle section : 닫힌 상태  */}
      {/* <div className="section toggle hide">
          <div className="title">검사</div>
          <div className="content">
            <span>일반혈액검사 외 2건</span>
          </div>
          <img src={arrowDown} />
        </div> */}

      {/* toggle section : 닫힌 상태  */}
      {/* <div className="section toggle hide">
          <div className="title">시술 등 처방</div>
          <div className="content">
            <span>습윤 드레싱 외 4건</span>
          </div>
          <img src={arrowDown} />
        </div> */}

      {/* toggle section : 닫힌 상태  */}
      {/* <div className="section toggle hide">
          <div className="title">투약</div>
          <div className="content">
            <span>글리백 외 4건</span>
          </div>
          <img src={arrowDown} />
        </div> */}
      {/* </div> */}
    </div>
  )
}
