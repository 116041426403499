import {
  BlockingLoadBox,
  ODButton,
  ODButtonSize,
  ODButtonTheme,
  ODIcons,
  ODListablePaginatedTable2,
  ODListablePagination,
  ODListableSearchBox,
  useCounter,
  useODListableContext,
  useODModalConfirm,
  useWrappedAPI,
} from '@mdpp/od-react-belt'
import moment from 'moment'
import React from 'react'
import styled from 'styled-components/macro'

import { Link } from 'react-router-dom'
import { Card, CardBody } from 'reactstrap'
import { GQLHospitalManager } from '@mdpp/common/lib/@types/server.schema'
import { EvixIconText } from '../../../common/EvixIconText'
import { SiteUrls } from '../../../SiteUrls'
import { COLORS } from '../../../styles/colors'
import { WebUtils } from '../../../utils/webUtils'
import { useAdminAPIs } from '../../context/useAdminAPIs'
import { HospitalManagerPrimaryKey, IHospitalManagerListableOption } from '../HospitalManagerCommon'
import { useHospitalManagerListDataLoader } from './HospitalManagerListDataLoader'
import { EvixCard } from '../../../common/card/EvixCard'

interface IHospitalManagerListableTableProps {
  hospitalId: number
}

export const HospitalManagerListableTable: React.FC<IHospitalManagerListableTableProps> = props => {
  const { hospitalId } = props
  const { Provider, Context } = useODListableContext<GQLHospitalManager, IHospitalManagerListableOption>()
  const [loading, setLoading] = React.useState(false)
  const [token, refresh] = useCounter()
  const dataLoader = useHospitalManagerListDataLoader(hospitalId)
  const showSearch = true

  const searchBoxStyle = { flex: 1}
  const wrapperStyle= { display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 24 }
  
  const { Component: ConfirmModal, confirm, props: confirmProps } = useODModalConfirm({})
  const { resetHospitalManagerPassword, deleteHospitalManager } = useAdminAPIs()
  const handleResetPassword = useWrappedAPI((id: number) => resetHospitalManagerPassword({ id }), loading, setLoading, {
    successMessage: '비밀번호를 변경하고 이메일을 전송하였습니다.',
    onBefore: () =>
      confirm({
        title: '병원관리자 비밀번호 리셋',
        message: '병원관리자의 비밀번호를 임의로 변경하고 이메일로 전송합니다. 계속하시겠습니까?',
        yes: '비밀번호 변경',
        no: '취소',
      }),
  })
  const handleDeleteManager = useWrappedAPI((id: number) => deleteHospitalManager({ id }), loading, setLoading, {
    successMessage: '삭제하였습니다.',
    onSuccess: refresh,
    onBefore: () =>
      confirm({ title: '병원관리자 삭제', message: '병원관리자를 삭제하시겠습니까?', yes: '삭제', no: '취소' }),
  })

  return (
    <Provider
      dataLoader={dataLoader}
      keyExtractor={v => v[HospitalManagerPrimaryKey].toString()}
      pageSize={10}
      refreshToken={token.toString()}
      onDataLoaderError={WebUtils.showError}
      searchOnLoad
    >
      <ConfirmModal {...confirmProps} />
      <BlockingLoadBox show={loading} />
     
          {showSearch && (
            <SearchWrapper >
              <ODListableSearchBox listableContext={Context} placeholder="이름, 이메일로 검색" style={searchBoxStyle} />
              <Link to={SiteUrls.Admin.Hospital.Manager.Create(hospitalId)} style={{ textDecoration: 'none' }}>
                <ODButton
                  theme={ODButtonTheme.Primary}
                  size={ODButtonSize.Small}
                  rounded
                  style={{ minWidth: 135 }}
                >
                  병원관리자 추가
                </ODButton>
              </Link>
            </SearchWrapper>
          )}
          <EvixCard noPadding>
            <ODListablePaginatedTable2
              numColumns={4}
              listableContext={Context}
              renderHeader={() => (
                <tr>
                  <th style={{textAlign: 'center', width: 150}}>이름</th>
                  <th style={{textAlign: 'center'}}>이메일</th>
                  <th style={{textAlign: 'center', width: 180}}>마지막 로그인</th>
                  <th style={{textAlign: 'center', width: 260}}>관리</th>
                </tr> 
              )}
              renderRow={(value: GQLHospitalManager, context) => (
                <tr key={value[HospitalManagerPrimaryKey]}  style={{borderBottom: '1px solid #D1D5DA'}}>
                  <td align='center'>{value.name}</td>
                  <td>{value.email}</td>
                  <td align='center'>{moment(value.lastAccessTime).format('YYYY-MM-DD HH:mm:ss')}</td>
                  <td align='center'>
                    <EvixIconText
                      icon={ODIcons.CorePencil}
                      color={COLORS.GRAY600}
                      fontSize={14}
                      spacing={8}
                      pointer
                      onClick={() => handleResetPassword(value.hospitalManagerId)}
                    >
                      비밀번호 재설정
                    </EvixIconText>
                    <div style={{ display: 'inline-block', width: 20 }} />
                    <EvixIconText
                      icon={ODIcons.CoreTrash}
                      color={COLORS.DANGER}
                      fontSize={14}
                      spacing={8}
                      pointer
                      onClick={() => handleDeleteManager(value.hospitalManagerId)}
                    >
                      삭제
                    </EvixIconText>
                  </td>
                </tr>
              )}
              eventParentContext={{}}
            />
          </EvixCard>
          <ODListablePagination hideIfSinglePage listableContext={Context} wrapperStyle={wrapperStyle} />
       
    </Provider>
  )
}


const SearchWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-bottom: 15px;
  
  > div > form > div {
    > div {
      border-radius: 8px;

      > div {
        border-radius: 8px;
        background-color: white;
        > span {
          border-radius: 8px;
        }
      }

      > input {
        border-radius: 0px 8px 8px 0px;
      }
    }

    @media (max-width: 992px) {
      width: 290px;
    }
  }
`