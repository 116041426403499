import React from 'react'
import { PageContainer, Page, PageHeader, PageTitle } from '../../../common/page/Page'
import { HospitalManagerEdit } from './HospitalManagerEdit'

interface Props {
  hospitalId: number
  idEditing: number | null
}

export const HospitalManagerEditPage: React.FC<Props> = props => {
  return (
    <PageContainer>
      <Page>
        <PageHeader>
          <PageTitle>병원 {'>'} 병원 관리자 추가</PageTitle>
        </PageHeader>
        <HospitalManagerEdit hospitalId={props.hospitalId} idEditing={props.idEditing} />
      </Page>
    </PageContainer>
  )
}
