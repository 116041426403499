import { ODEntityRaw } from '@mdpp/od-react-belt'
import React from 'react'
import { EvixButton, EvixButtonTheme } from '../EvixButton'
import styled from "styled-components";
import {COLORS} from "../../styles/colors";

interface IEvixFormToggleButtonProps {
  name: string
  keyPath: string
  value?: any
}

const selectTheme = (enabled: boolean) =>
  enabled ? EvixButtonTheme.ToggleW142H50Selected : EvixButtonTheme.ToggleW142H50NotSelected

export const EvixFormToggleButton: React.FC<IEvixFormToggleButtonProps> = props => {
  const { name, keyPath, value: expectedValue, children } = props
  return (
    <ODEntityRaw
      name={name}
      keyPath={keyPath}
      render={({ value, formikContext: { setFieldValue } }) => {
        return (
            <EvixButton
                type="button"
                onClick={() => setFieldValue(keyPath, expectedValue === undefined ? !value : expectedValue)}
                theme={expectedValue === undefined ? selectTheme(value) : selectTheme(value === expectedValue)}
            >
                {children}
            </EvixButton>
        )
      }}
    />
  )
}
