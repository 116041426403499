import { ODIcon, ODIcons } from '@mdpp/od-react-belt'
import moment from 'moment'
import React from 'react'
import styled from 'styled-components'
import { GQLTransactionLog } from '@mdpp/common/lib/@types/server.schema'
import { COLORS } from '../../../../styles/colors'

interface ITransactionLogTextProps {
  data: GQLTransactionLog
}

const formatDateTime = (t: any) => moment(t).format('YYYY.MM.DD(ddd) HH:mm')

export const TransactionLogText: React.FC<ITransactionLogTextProps> = props => {
  const { data } = props
  const { createdAt, status } = data

  if (status === 'paid') {
    return (
      <LogWrapper style={{ color: '#7F8386' }}>
        <span>{formatDateTime(createdAt)} 결제 성공</span>
        <IconWrapper>
          <ODIcon icon={ODIcons.MaterialDone} />
        </IconWrapper>
      </LogWrapper>
    )
  }

  return (
    <LogWrapper style={{ color: COLORS.DANGER }}>
      <span>{formatDateTime(createdAt)} 결제 실패</span>
      <IconWrapper>
        <ODIcon icon={ODIcons.MaterialWarning} />
      </IconWrapper>
    </LogWrapper>
  )
}

const LogWrapper = styled.div`
  font-size: 14px;
  line-height: 20px;
  text-align: right;
  margin-top: 0;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
`

const IconWrapper = styled.span`
  transform: scale(0.5);
`
