import React from 'react'
import Select from 'react-select'
import styled from 'styled-components'
import {ODHSpace} from "@mdpp/od-react-belt";
import {DatePicker} from "../../DatePicker";
import moment from "moment/moment";
import {Moment} from "moment";

interface IReservationTimeSelectorProps {
  value: number
  dateRange ?: string[]
  setValue: (value: number) => void
  setDateRange ?: (changedValue: string[]) => void
  placeholder ?: string
}
const SELECT_DATE_RANGE_BY_SELF = 0
const SELECT_DATE_DISABLE_RESERVATION = -1
// const OPTIONS = range(1, 13).map(v => ({ label: `${v}개월`, value: v }))
const OPTIONS = [
  { label: `1개월 간`, value: 1 },
  { label: `3개월 간`, value: 3 },
  { label: `6개월 간`, value: 6 },
  { label: `12개월 간`, value: 12 },
  { label: `예약 받지 않음`, value: SELECT_DATE_DISABLE_RESERVATION }]

enum DATE_RANGE_STATUS {
  MONTH = 'MONTH',
  SELECT = 'SELECT',
}

const isBeforeDay = (a : Moment, b : Moment) => {
  if (!moment.isMoment(a) || !moment.isMoment(b)) return false;
  
  const aYear = a.year();
  const aMonth = a.month();
  
  const bYear = b.year();
  const bMonth = b.month();
  
  const isSameYear = aYear === bYear;
  const isSameMonth = aMonth === bMonth;
  
  if (isSameYear && isSameMonth) return a.date() < b.date();
  if (isSameYear) return aMonth < bMonth;
  return aYear < bYear;
}

const isInclusivelyAfterDay = (a : Moment, b : Moment) => {
  if (!moment.isMoment(a) || !moment.isMoment(b)) return false;
  return !isBeforeDay(a, b);
}

export const ReservationTimeSelector: React.FC<IReservationTimeSelectorProps> = props => {
  const { value, setValue, placeholder, dateRange, setDateRange } = props
  const [dateStatus, setDateStatus] = React.useState(DATE_RANGE_STATUS.MONTH)
  
  return (
    <Wrapper>
      <CustomSelect
        name="reservationTime"
        maxMenuHeight={130}
        options={OPTIONS}
        placeholder={placeholder ?? ''}
        value={OPTIONS.find(v => v.value === value)}
        styles={{
          // @ts-ignore
          placeholder: base => ({
            ...base,
            fontSize: '1em',
            color: '#5243AA',
            fontWeight: 400,
          }),
        }}
        onChange={(e: any) => {
          console.log(22, e.value)
          if (e.value === SELECT_DATE_RANGE_BY_SELF)
            setDateStatus(DATE_RANGE_STATUS.SELECT)
          else
            setDateStatus(DATE_RANGE_STATUS.MONTH)
          setValue(e.value)
        }}
      />
      {dateStatus === DATE_RANGE_STATUS.SELECT &&
      // @ts-ignore
      <DatePicker dateRange={dateRange} onChange={setDateRange} isOutsideRange={(day) => !isInclusivelyAfterDay(day, moment())}/>}
    </Wrapper>
  )
}

const CustomSelect = styled(Select)`
  width: 100%;
`
const Wrapper = styled.div`
  position: relative;
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;

  // React Select Style
  >div:first-child {
    width: 100%;
    min-width: 275px;
    // max-width: 300px;

    & svg {
      width: 24px;
      height: 22px;
      font-size: 24px;
      line-height: 0.92;
      color: var(--gray600);
    }
  }

  .css-1okebmr-indicatorSeparator {
    background-color: transparent;
  }

  .css-1uccc91-singleValue {
    font-size: 14px;
    color: var(--gray600);
  }
  .css-1laao21-a11yText {
    display: none;
  }

  // react-date css
  .DateInput_input {
    width: 130px;
    height: 34px;
    border-radius: 4px;
    border: solid 1px var(--gray300);
    font-size: 14px;
    line-height: 1.43;
    color: var(--gray900);
  }
  .DateRangePickerInput__withBorder {
    border: 0;
  }
  .DateRangePickerInput_arrow.DateRangePickerInput_arrow_1 svg {
    width: 9px;
    height: 16px;
    font-size: 14px;
    letter-spacing: 0.3px;
    color: var(--gray600);
    margin-left: 8px;
    margin-right: 8px;
  }
`
