import {
  ODButton,
  ODButtonSize,
  ODButtonTheme,
  ODIcon,
  ODIcons,
  ODListableContextType,
  ODListablePaginatedTable2,
  ODListablePagination,
  ODListableSearchBox,
  useCounter,
  useODListableContext,
  useODModalConfirm,
  useWrappedAPI,
} from '@mdpp/od-react-belt'
import React, { useContext } from 'react'
import { EvixCard } from '../../../common/card/EvixCard'
import styled from 'styled-components/macro'
import { GQLPharmacyHasHospital } from '@mdpp/common/lib/@types/server.schema'
import { useCommonAPIs } from '../../../common/hooks/useCommonAPIs'
import { WebUtils } from '../../../utils/webUtils'
import { IPharmacyHasHospitalOptions, PharmacyHasHospitalPrimaryKey } from '../AdminPharmacyHasHospitalCommon'
import { usePharmacyHasHospitalModal } from '../usePharmacyHasHospitalModal'
import { usePharmacyHasHospitalListDataLoader } from './PharmacyHasHospitalListDataLoader'


interface IHospitalPharmacyListableTable {
  hospitalId: number
}

export const PharmacyHasHospitalListableTable: React.FC<IHospitalPharmacyListableTable> = props => {
  const { hospitalId } = props
  const { Provider, Context } = useODListableContext<GQLPharmacyHasHospital, IPharmacyHasHospitalOptions>()
  const [loading, setLoading] = React.useState(false)
  const [token, refresh] = useCounter()
  const dataLoader = usePharmacyHasHospitalListDataLoader(hospitalId)
  const searchBoxStyle = { flex: 1}
  const wrapperStyle= { display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 24 }
  
  const { props: pickerProps, Component: PickerComponent, pick } = usePharmacyHasHospitalModal(hospitalId)
  const { Component: ConfirmComponent, props: confirmProps, confirm } = useODModalConfirm({})
  const { createPharmacyHasHospital, removePharmacyHasHospital } = useCommonAPIs()
  const create = useWrappedAPI(
    async (pharmacyIds: number[]) => {
      for (const pId of pharmacyIds) {
        try {
          await createPharmacyHasHospital({ hospitalId, pharmacyId: pId })
        } catch (ex) {
          // silently ignore
        }
      }
    },
    loading,
    setLoading,
    {
      successMessage: '추가하였습니다.',
      onSuccess: refresh,
      deps: [hospitalId, createPharmacyHasHospital],
    }
  )
  const remove = useWrappedAPI(async (phhId: number) => removePharmacyHasHospital({ id: phhId }), loading, setLoading, {
    successMessage: '약국 연동을 해제하였습니다.',
    onSuccess: refresh,
    deps: [hospitalId, removePharmacyHasHospital],
  })

  const handlePharmacyHasHospital = async () => {
    const finalSelection = await pick({
      showSearch: true,
      searchPlaceholder: '약국이름, 주소, 연락처, FAX 로 검색',
    })

    if (!finalSelection) {
      return
    }

    if (finalSelection.length === 0) {
      WebUtils.showError('선택된 약국이 없습니다.')
    }

    return create(finalSelection as number[])
  }

  const handleRemove = async (phhId: number) => {
    if (
      !(await confirm({
        title: '약국 연동 해제',
        message: '해당 약국과 병원과의 연동을 해제하시겠습니까?',
        yes: '해제',
        no: '취소',
      }))
    ) {
      return
    }

    return remove(phhId)
  }

  return (
    <Provider
      dataLoader={dataLoader}
      keyExtractor={v => v[PharmacyHasHospitalPrimaryKey].toString()}
      pageSize={10}
      refreshToken={token.toString()}
      onDataLoaderError={WebUtils.showError}
      searchOnLoad
    >
      <ConfirmComponent {...confirmProps} />
      <PickerComponent {...pickerProps} />
   
       
          <SearchWrapper>
            <ODListableSearchBox
              listableContext={Context}
              placeholder="약국 이름, 주소, 연락처, FAX 로 검색"
              style={searchBoxStyle}
            />
            <ButtonWrapper Context={Context} onClick={() => handlePharmacyHasHospital()} >
              <ODButton
                theme={ODButtonTheme.Primary}
                size={ODButtonSize.Small}
                rounded
                style={{ minWidth: 100 }}
              >
                약국 추가
              </ODButton>
            </ButtonWrapper>
          </SearchWrapper>
          <EvixCard noPadding>
            <ODListablePaginatedTable2
              numColumns={6}
              listableContext={Context}
              renderEmpty={() => <span>추가된 약국이 없습니다.</span>}
              renderHeader={() => (
                <tr>
                  <th align='center'>약국이름</th>
                  <th align='center'>주소</th>
                  <th align='center'>택배여부</th>
                  <th align='center'>연락처</th>
                  <th align='center'>FAX</th>
                  <th align='center'>관리</th>
                </tr>
              )}
              renderRow={(value: GQLPharmacyHasHospital, context) => {
                return (
                  <tr key={value[PharmacyHasHospitalPrimaryKey]}  style={{borderBottom: '1px solid #D1D5DA'}}>
                    <td>{value.pharmacy.name}</td>
                    <td>{value.pharmacy.address}</td>
                    <td align='center'>{value.pharmacy.availableDelivery?'가능':'불가능'}</td>
                    <td align='center'>{value.pharmacy.telephone}</td>
                    <td align='center'>{value.pharmacy.fax}</td>
                    <td align='center'>
                      <IconWrapper onClick={() => handleRemove(value.phhId)}>
                        <ODIcon icon={ODIcons.CoreTrash} />
                      </IconWrapper>
                    </td>
                  </tr>
                )
              }}
              eventParentContext={{}}
            />
          </EvixCard>
          <ODListablePagination hideIfSinglePage={false} listableContext={Context} wrapperStyle={wrapperStyle} />
       
     
    </Provider>
  )
}

interface IButtonWrapper {
  onClick: (context: GQLPharmacyHasHospital[]) => void
  Context: React.Context<ODListableContextType<GQLPharmacyHasHospital, IPharmacyHasHospitalOptions>>
}

const ButtonWrapper: React.FC<IButtonWrapper> = ({ onClick, Context, children }) => {
  const temp = useContext<ODListableContextType<GQLPharmacyHasHospital, IPharmacyHasHospitalOptions>>(Context)
  console.log(138, Context, temp.state.list)
  return <span onClick={() => onClick(temp.state.list)}>{children}</span>
}

const SearchWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-bottom: 15px;

  > div > form > div {
    > div {
      border-radius: 8px;

      > div {
        border-radius: 8px;
        background-color: white;
        > span {
          border-radius: 8px;
        }
      }

      > input {
        border-radius: 0px 8px 8px 0px;
      }
    }

    @media (max-width: 992px) {
      width: 290px;
    }
  }
`

const IconWrapper = styled.span`
  font-size: 14px;
  letter-spacing: 0.3px;
  color: var(--danger);
  cursor: pointer;
`
