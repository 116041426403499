// 스케줄 수정 컴포넌트의 각 셀 상태 컴포넌트
import { SCHEDULE_TYPE } from '@mdpp/common'
import moment from 'moment'
import 'moment/locale/ko'
import React from 'react'
import styled from 'styled-components/macro'
import { GQLReservation, GQLRESERVATION_STATUS } from '@mdpp/common/lib/@types/server.schema'
import { ISCHEDULE_DATA_STATE } from '../ScheduleSettingCommon'
import Available from '../../../assets/available.svg'
import Unavailable from '../../../assets/unavailable.svg'

interface IScheduleStateCell {
  state: ISCHEDULE_DATA_STATE
  onClick: (id: string) => void
  onClickReservationCell: (id: string, reservation: GQLReservation) => void
}

export const ScheduleStateCell: React.FC<IScheduleStateCell> = props => {
  const { state, onClick, onClickReservationCell } = props
  const divId = `state_cell_${state.id}`
  const { date, time, avail, reservation } = state
  const isPast = moment(`${date} ${time}`, 'YYYY-MM-DD HH:mm')
    .add(-30, 'minutes')
    .isSameOrBefore(moment())

  const cannotInteract = state.id <= 0 || isPast || reservation
  let styleState = { color: 'var(--second)', opacity: '1' }
  let icon = ''

  switch (avail) {
    case SCHEDULE_TYPE.AVAILABLE:
      styleState = { color: 'var(--success)', opacity: cannotInteract ? '0.3' : '1' }
      icon = Available
      break
    case SCHEDULE_TYPE.UNAVAILABLE:
      styleState = { color: 'var(--danger)', opacity: cannotInteract ? '0.3' : '1' }
      icon = 'X'
      break
    case SCHEDULE_TYPE.RESERVED: {
      switch (state.reservation?.status) {
        case GQLRESERVATION_STATUS.WAITING:
          icon = '진료대기'
          break
        case GQLRESERVATION_STATUS.RECEIPTED:
          icon = '접수중'
          break
        case GQLRESERVATION_STATUS.READY:
          icon = '진료준비완료'
          break
        case GQLRESERVATION_STATUS.IN_PROGRESS:
          icon = '진료중'
          break
        case GQLRESERVATION_STATUS.PRESCRIPTION:
          icon = '처방중'
          break
        case GQLRESERVATION_STATUS.FINISHED:
          icon = '진료완료'
          break
        case GQLRESERVATION_STATUS.DOCTOR_NO_SHOW:
          icon = '예약부도(의사)'
          break
        case GQLRESERVATION_STATUS.PATIENT_NO_SHOW:
          icon = '예약부도(환자)'
          break
        case GQLRESERVATION_STATUS.DOCTOR_CANCEL:
          icon = '예약취소(의사)'
          break
        case GQLRESERVATION_STATUS.PATIENT_CANCEL:
          icon = '예약취소(환자)'
          break
        case GQLRESERVATION_STATUS.PENDING:
        default:
          icon = '예약'
          break
      }
      break
    }
  }

  const ico = avail === SCHEDULE_TYPE.AVAILABLE ? (<Icon src={Available} />) : avail === SCHEDULE_TYPE.UNAVAILABLE ?  (<Icon src={Unavailable} />) : icon

  return (
    <>
      <ScheduleCell
        id={divId}
        onClick={cannotInteract ? () => onClickReservationCell(divId, reservation!) : () => onClick(divId)}
        style={cannotInteract ? {background: '#F9FAFB'} : {background: 'white'}}
      >
        <div style={styleState}>
          {avail === SCHEDULE_TYPE.RESERVED && <Dot />}
          {ico}
        </div>
      </ScheduleCell>
    </>
  )
}

const ScheduleCell = styled.div`
  border: 1px solid #F5F5F5;
  width: 100%;
  max-width: 200px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: var(--gray800);
  cursor: pointer;

  :hover {
    background-color: var(--gray100);
  }
`
const Dot = styled.span`
  display: inline-block;
  width: 12px;
  height: 12px;
  background-color: var(--second);
  border-radius: 50%;
  margin-right: 8px;
`

const Icon = styled.img`
`