import { BlockingLoadBox, ODFlexContentsContainer } from '@mdpp/od-react-belt'
import React from 'react'
import { Redirect } from 'react-router-dom'
import { WebUtils } from '../utils/webUtils'

interface ILogoutPageProps {
  logout: () => Promise<void>
  urlAfterLogout: string
}

export const LogoutPage: React.FC<ILogoutPageProps> = ({ logout, urlAfterLogout }) => {
  const [redirect, setRedirect] = React.useState(false)

  React.useEffect(() => {
    logout().then(() => setRedirect(true), WebUtils.showError)
  }, [logout])

  if (redirect) {
    return <Redirect to={urlAfterLogout} />
  }

  return (
    <ODFlexContentsContainer>
      <BlockingLoadBox show message="로그아웃 중입니다.." />
    </ODFlexContentsContainer>
  )
}
