import React from 'react'
import { LinkStyle } from '../../common/schedule/ScheduleStatusPopOver'
import { ScheduleEditingPage } from '../../common/schedule/table/ScheduleEditingPage'
import { SiteUrls } from '../../SiteUrls'
import { useODDoctorAppContext } from '../context/ODDoctorAppContext'
import { DoctorProfileDiagnosisContainer } from '../mypage/DoctorProfileDiagnosisContainer'

interface IDoctorScheduleTableProps {}

export const DoctorScheduleTable: React.FC<IDoctorScheduleTableProps> = () => {
  const {
    state: { profile },
  } = useODDoctorAppContext()

  return (
    <>
      <DoctorProfileDiagnosisContainer />
      <ScheduleEditingPage
        isFromDoctorSelf
        doctorId={profile!.doctorId}
        linkToDefaultSetup={SiteUrls.Doctor.Schedule.SetupDefault}
        compactMargin={false}
        linkStyle={LinkStyle.Doctor}
      />
    </>
    
  )
}
