import { ODIcon, ODIcons, ODModal, ODModalSize, ODVSpace } from '@mdpp/od-react-belt'
import React from 'react'
import styled from 'styled-components/macro'
import { GQLInquiryCreationInput } from '@mdpp/common/lib/@types/server.schema'
import {useHistory} from "react-router-dom";
import { Link } from 'react-router-dom'
import {SiteUrls} from "../../../SiteUrls";
import {BP, Button} from "../../RebeccaButton";

interface IInquiryPopoverProps {
  isOpen: boolean
  onClose: () => void
  initialInquirer: string
  initialPhoneNumber: string
  initialEmail: string
  onSubmit: (input: GQLInquiryCreationInput) => void
}

export const InquiryModal: React.FC<IInquiryPopoverProps> = props => {
  const { isOpen, initialEmail, initialInquirer, initialPhoneNumber, onClose, onSubmit } = props

  const [inquirer, setInquirer] = React.useState(initialInquirer)
  const [phoneNumber, setPhoneNumber] = React.useState(initialPhoneNumber)
  const [email, setEmail] = React.useState(initialEmail)
  const [contents, setContents] = React.useState('')
  const history = useHistory()


  const [close, setClose] = React.useState(false)
  const closeModal = () => {
    setClose(false)
  }

  const handleClose = () => {
    history.goBack()
  }


  return (
    <ODModal isOpen={isOpen} toggle={onClose} size={ODModalSize.Normal}>
      <Wrapper
        onSubmit={e => {
          e.preventDefault()
          onSubmit({ name: inquirer, phone: phoneNumber, email, contents })
        }}
      >
        <ODVSpace h={44} />
        <Title>문의하기</Title>
        {/*<CloseButtonBox onClick={onClose}>*/}
        {/*  <ODIcon icon={ODIcons.CoreX} style={CloseIconStyle} />*/}
        {/*</CloseButtonBox>*/}
        <div style={{  position: 'absolute', top: '25px', right: '25px', cursor: 'pointer'}}>
          <Link to={`${history.goBack}`} onClick={handleClose}  style={{textDecoration: 'none'}} >
            {/*닫기*/}
            <ODIcon icon={ODIcons.CoreX} style={CloseIconStyle} />
          </Link>
        </div>
        <Inner>
          <ODVSpace h={48} />
          <InputTitle htmlFor={'name'}>
            문의자명 *
            <ODVSpace h={9} />
            <InputField
              id={'name'}
              value={inquirer}
              onChange={e => {
                setInquirer(e.target.value)
              }}
            />
          </InputTitle>
          <ODVSpace h={26} />
          <InputTitle htmlFor={'phone'}>
            연락 받으실 전화번호
            <ODVSpace h={9} />
            <InputField id={'phone'} value={phoneNumber} onChange={e => setPhoneNumber(e.target.value)} />
          </InputTitle>
          <ODVSpace h={26} />
          <InputTitle htmlFor={'email'}>
            연락 받으실 이메일 *
            <ODVSpace h={9} />
            <InputField id={'email'} value={email} onChange={e => setEmail(e.target.value)} />
          </InputTitle>
          <ODVSpace h={26} />
          <InputTitle htmlFor={'contents'}>
            문의사항 *
            <ODVSpace h={9} />
            <InputTextArea
              onChange={e => setContents(e.target.value)}
              value={contents ?? ''}
              id={'contents'}
              placeholder={'유선 안내가 필요한 경우 전화번호를 남겨주세요.'}
            />
          </InputTitle>
          <ODVSpace h={35} />
        </Inner>
        <SubmitButton type={'submit'} value={'문의하기'} />
      </Wrapper>
    </ODModal>
  )
}

const CloseIconStyle = {
  fontSize: 18,
  color: '#dbdbdb',
}
const Wrapper = styled.form`
  background-color: var(--white);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  position: relative;
`
const Inner = styled.div`
  padding: 0 37px;
`
const Title = styled.h1`
  line-height: 29px;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0.29px;
  text-align: center;
  color: var(--mpPrimary500);
`
const CloseButtonBox = styled.div`
  position: absolute;
  top: 25px;
  right: 25px;
  cursor: pointer;
`
const InputTitle = styled.label`
  line-height: 18px;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0.26px;
  color: var(--gray600);
  margin: 0;
  width: 100%;
`
const InputField = styled.input`
  width: 100%;
  padding: 7px 15px 7px 9px;
  border-radius: 4px;
  border: solid 1px var(--gray300);
  background-color: var(--white);
`
const InputTextArea = styled.textarea`
  width: 100%;
  height: 120px;
  padding: 7px 12px;
  border-radius: 4px;
  border: solid 1px var(--gray300);
  background-color: var(--white);
  resize: none;
`
const SubmitButton = styled.input`
  width: 100%;
  height: 47px;
  padding: 15px 0 13px 3px;
  background-color: var(--mpPrimary500);
  font-size: 15px;
  line-height: 19px;
  color: var(--white);
  letter-spacing: 0.21px;
  text-align: center;
`
