import { GQLPharmacist, GQLPharmacy } from '@mdpp/common/lib/@types/server.schema'

const COMMON_BUSINESS_LICENSE_FILE_FIELDS = ['blfId', 'hashId', 'fileName', 'mimeType', 'encoding', 'deletedAt', 'link']
const COMMON_BANK_BOOK_FILE_FIELDS = ['bbfId', 'hashId', 'fileName', 'mimeType', 'encoding', 'deletedAt', 'link']

export const GQL_LOGOUT = `
mutation {
  logout {
    ok
  }
}
`

export const INQUIRY_SNAPSHOT = `
  createdAt
  updatedAt
  inquiryId
  name
  phone
  email
  contents
  doctorId
  hospitalManagerId
`

export const MEDICAL_SUBJECT_SNAPSHOT = `
  medicalSubjectId
  name
  numDoctors
`

const DIAGNOSIS_HISTORY_SNAPSHOT = `
  diagnosisName
  diagnosedAt
  hospital {
    name
  }
`

const NOTICE_SNAPSHOT = `
  noticeId
  title
  contents
  link
  createdAt
  showOnApp
  showOnDoctor
  showOnHospitalManager
`

export const POPUP_INFO_SNAPSHOT = `
  popupId
  notice {
    ${NOTICE_SNAPSHOT}
  }
  content
  title
  postingStart
  postingEnd
  onetime
  popupImage {
    link
    fileName
  }
  popupImageId
`

const PATIENT_SNAPSHOT = `
  patientId
  name
  birthDate
  fullBirthDate
  gender
  email
  phone
  height
  weight
  diagnosisHistories {
    ${DIAGNOSIS_HISTORY_SNAPSHOT}
  }
  recentDiagnosis {
    ${DIAGNOSIS_HISTORY_SNAPSHOT}
  }
  mostDiagnosis {
    ${DIAGNOSIS_HISTORY_SNAPSHOT}
  }
  smokeHistory
  doesDrink
  smokePeriodType
  smokeAmountType
  drinkDuration
  drinkType
  drinkSize
  drinkSizeType
  drinkFrequency
  allergy
  medicineHistory
  hospitalOperation
  interests
  pastHistory
  createdAt
  leftAt
`

const RESERVATION_SNAPSHOT = `
  reservationId
  status
  reservationTime
  patientId
  patient {
    name
    birthDate
    fullBirthDate
    gender
    phone
    height
    weight
    diagnosisHistories {
      ${DIAGNOSIS_HISTORY_SNAPSHOT}
    }
    recentDiagnosis {
      ${DIAGNOSIS_HISTORY_SNAPSHOT}
    }
    mostDiagnosis {
      ${DIAGNOSIS_HISTORY_SNAPSHOT}
    }
    smokeHistory
    doesDrink
    smokePeriodType
    smokeAmountType
    drinkDuration
    drinkType
    drinkSize
    drinkSizeType
    drinkFrequency
    allergy
    medicineHistory
    hospitalOperation
    interests
    pastHistory
    leftAt
  }
  hospital {
    hospitalId
    name
  }
  doctor {
    doctorId
    name
  }
  medicalSubject {
    name
  }
  symptom
  patientFiles {
    fileName
    link
  }
  prescriptionFiles {
    prescriptionFileId
    fileName
    link
  }
  doctorFactNotes
  diagnosis
  diagnosisNames
  treatment
  paymentTotal
  paymentState
  rate
  ratedAt
  agoraToken
  hasDiagnosis {
    diagName {
      diagNameId
      code
      enDesc
      koDesc
    }
    isFinal
  }
  transactionLogs {
    createdAt
    transactionLogId
    status
  }
  createdAt
  patientEnteredAt
  patientExitedAt
  patientReceiptAt
  proxyReservation
  proxyReservationInfo {
    name
    birthDate
    relationship
    sex
    phone
  }
`

const PRESCRIPTION_FILE_SNAPSHOT = `
  prescriptionFileId
  fileName
  link
`
//
// Hospital
//
export const HOSPITAL_SNAPSHOT = `
  hospitalId
  name
  address
  zipCode
  telephone
  isHidden
  email
  numDoctors
  numAdmins
  numPharmacy
  profileImage {
    link
  }
  businessLicenseFile {
    blfId
    link
    fileName
  }
  bankbookFile {
    bbfId
    link
    fileName
  }
`

export const HOSPITAL_FOR_PHARMACY_CHOOSE_SNAPSHOT = `
  hospitalId
  name
  address
`

export const HOSPITAL_MANAGER_SNAPSHOT = `
  hospitalManagerId
  name
  hospital {
    hospitalId
    name
  }
  email
  createdAt
  lastAccessTime
`

export const GQL_LIST_HOSPITAL = `
query listHospital($data: ListableHospitalInput!) {
  listHospital(data: $data) {
    list {
      ${HOSPITAL_SNAPSHOT}
    }
    totalCount
    page
    pageSize
  }  
}
`

export const GQL_LIST_HOSPITAL_FOR_PHARMACY_CHOOSE = `
query listHospitalForPharmacyChoose($data: ListableHospitalInput!) {
  listHospitalForPharmacyChoose(data: $data) {
    list {
      ${HOSPITAL_FOR_PHARMACY_CHOOSE_SNAPSHOT}
    }
    totalCount
    page
    pageSize
  }
}
`

export const GQL_CREATE_HOSPITAL = `
mutation createHospital($data: HospitalCreationInput!) {
  createHospital(data: $data) {
    ${HOSPITAL_SNAPSHOT}
  }
}
`

export const GQL_UPDATE_HOSPITAL = `
mutation updateHospital($data: HospitalUpdateInput!) {
  updateHospital(data: $data) {
    ${HOSPITAL_SNAPSHOT}
  }
}
`

export const GQL_GET_HOSPITAL = `
query getHospital($data: SingleIDInput!) {
  getHospital(data: $data) {
    ${HOSPITAL_SNAPSHOT}
  }
}`

export const GQL_DELETE_HOSPITAL = `
mutation deleteHospital($data: SingleIDInput!) {
  deleteHospital(data: $data) {
    ok
  }
}
`

export const GQL_GET_HOSPITAL_OF_MANAGER = `
query getHospitalOfManager {
  getHospitalOfManager {
    ${HOSPITAL_SNAPSHOT}
  }
}`

export const DOCTOR_SNAPSHOT = `
	lastAccessTime
	lastPasswordUpdatedTime
  doctorId
  isHidden
  birthDate
  email
  phone
  cellPhone
  name
  introduction
  education
  career
  status
  licenseYear
  licenseName
  hospital {
    hospitalId
    name
  }
  profileImage {
    link
  }
  medicalSubjectsText
  medicalSubjects {
    medicalSubject {
      medicalSubjectId
      name
    }
  }
  profession
  schedule
  lastScheduleToken
  scheduleAvailableDays
  scheduleAvailableStartDay
  scheduleAvailableEndDay
  nextScheduleCreateAt
  reservationAfter
  availableFirstVisit
  smartTreatment
  untactTreatment
  recommendationCode
  statInfo {
    totalJoinPatient
    totalJoinDoctor
    totalSharedMydata
  }
`

export const GQL_CREATE_DOCTOR = `
  mutation createDoctor($data: DoctorCreationInput!) {
    createDoctor(data: $data) {
      ${DOCTOR_SNAPSHOT}
    }
  }
`

export const GQL_READ_DOCTOR = `
  query getDoctor($data: SingleIDInput!) {
    getDoctor(data: $data) {
      ${DOCTOR_SNAPSHOT}
    }
  }
`

export const GQL_UPDATE_DOCTOR = `
  mutation updateDoctor($data: DoctorUpdateInputByManager!) {
    updateDoctor(data: $data) {
      ${DOCTOR_SNAPSHOT}
    }
  }
`

export const GQL_DELETE_DOCTOR = `
  mutation deleteDoctor($data: SingleIDInput!) {
    deleteDoctor(data: $data) {
      ok
    }
  }
`

export const GQL_UPDATE_DOCTOR_SCHEDULE = `
  mutation updateDoctorSchedule($data: DoctorUpdateScheduleInput!) {
    updateDoctorSchedule(data: $data) {
      ${DOCTOR_SNAPSHOT}
    }
  }
`

export const GQL_DELETE_DOCTOR_SCHEDULE = `
  mutation deleteDoctorSchedule($data: SingleIDInput!) {
    deleteDoctorSchedule(data: $data) {
      ok
    }
  }
`

export const GQL_LIST_DOCTOR = `
  query listDoctor($data: ListableDoctorInput!) {
    listDoctor(data: $data) {
      list {
        ${DOCTOR_SNAPSHOT}
        hospital {
          name
        }
        medicalSubjectsText
        isHidden
      }
      totalCount
      page
      pageSize
    }
  }
`

export const GQL_GET_DOCTOR_PROFILE = `
  query getDoctorMyProfile {
    getDoctorMyProfile {
      ${DOCTOR_SNAPSHOT}
    }
  }
`

export const GQL_UPDATE_DOCTOR_PROFILE = `
  mutation updateDoctorProfile($data: DoctorUpdateInput!) {
    updateDoctorProfile(data: $data) {
      ${DOCTOR_SNAPSHOT}
    }
  }
`

export const GQL_LOGIN_DOCTOR = `
mutation loginDoctor($data: DoctorLoginInput!) {
  loginDoctor(data: $data) {
    me {
      ${DOCTOR_SNAPSHOT}
    }
    token
    recovered
  }
}
`

export const GQL_LIST_DOCTOR_RESERVATION = `
query listDoctorReservation($data: ListableDoctorReservationInput!) {
  listDoctorReservation(data: $data) {
    list {
      ${RESERVATION_SNAPSHOT}
    }
    totalCount
    page
    pageSize
  }
}`

export const GQL_GET_RESERVATION = `
query getReservation($data: SingleIDInput!) {
  getReservation(data: $data) {
    ${RESERVATION_SNAPSHOT}
  }
}`

export const GQL_CANCEL_RESERVATION_BY_DOCTOR = `
mutation cancelReservationByDoctor($data: CancelReservationByDoctorInput!) {
  cancelReservationByDoctor(data: $data) {
    ${RESERVATION_SNAPSHOT}
  }
}
`

export const GQL_UPDATE_RESERVATION_BY_DOCTOR = `
mutation updateReservationByDoctor($data: UpdateReservationByDoctorInput!) {
  updateReservationByDoctor(data: $data) {
    ${RESERVATION_SNAPSHOT}
  }
}
`

export const GQL_UPDATE_RESERVATION_BY_MANAGER = `
mutation updateReservationByManager($data: UpdateReservationByDoctorInput!) {
  updateReservationByManager(data: $data) {
    ${RESERVATION_SNAPSHOT}
  }
}
`

export const GQL_LIST_RESERVATION = `
query listReservation($data: ListableReservationInput!) {
  listReservation(data: $data) {
    list {
      ${RESERVATION_SNAPSHOT}
    }
    totalCount
    page
    pageSize
  }
}`

export const GQL_CREATE_PRESCRIPTION_FILE = `
mutation createPrescriptionFile($data: PrescriptionFileCreationInput!) {
  createPrescriptionFile(data: $data) {
    ${PRESCRIPTION_FILE_SNAPSHOT}
  }
}
`

export const GQL_GET_PRESCRIPTION_FILE = `
query getPrescriptionFile($data: SingleIDInput!) {
  getPrescriptionFile(data: $data) {
    ${PRESCRIPTION_FILE_SNAPSHOT}
  }
}
`

export const GQL_DELETE_PRESCRIPTION_FILE = `
mutation deletePrescriptionFile($data: SingleIDInput!) {
  deletePrescriptionFile(data: $data) {
    ok
  }
}
`

export const GQL_ENTER_RESERVATION_ROOM_BY_DOCTOR = `
mutation enterReservationRoomByDoctor($data: SingleIDInput!) {
  enterReservationRoomByDoctor(data: $data) {
    ${RESERVATION_SNAPSHOT}
  }
}
`

export const GQL_SEND_PATIENT_ENTER_REQUEST = `
mutation sendPatientEnterRequest($data: SingleIDInput!) {
  sendPatientEnterRequest(data: $data) {
    ok
  }
}
`

export const GQL_UPSERT_DOCTOR_HAS_MEDICAL_SUBJECT = `
mutation upsertDoctorHasMedicalSubject($data: UpsertDoctorHasMedicalSubjectInput!) {
  upsertDoctorHasMedicalSubject(data: $data) {
    ok
  }
}
`

export const GQL_LIST_PATIENT = `
query listPatient($data: ListablePatientInput!) {
  listPatient(data: $data) {
    list {
      ${PATIENT_SNAPSHOT}
    }
    totalCount
    page
    pageSize
  }
}`

export const GQL_GET_MEDICAL_SUBJECT = `
query getMedicalSubject($data: SingleIDInput!) {
  getMedicalSubject(data: $data) {
    ${MEDICAL_SUBJECT_SNAPSHOT}
  }
}`

export const GQL_UPDATE_MEDICAL_SUBJECT = `
mutation updateMedicalSubject($data: MedicalSubjectUpdateInput!) {
  updateMedicalSubject(data: $data) {
    ${MEDICAL_SUBJECT_SNAPSHOT}
  }
}
`

export const GQL_CREATE_MEDICAL_SUBJECT = `
mutation createMedicalSubject($data: MedicalSubjectCreationInput!) {
  createMedicalSubject(data: $data) {
    ${MEDICAL_SUBJECT_SNAPSHOT}
  }
}
`

export const GQL_DELETE_MEDICAL_SUBJECT = `
mutation deleteMedicalSubject($data: SingleIDInput!) {
  deleteMedicalSubject(data: $data) {
    ok
  }
}
`

export const GQL_LIST_NOTICE = `
query listNotice($data: ListableNoticeInput!) {
  listNotice(data: $data) {
    list {
      ${NOTICE_SNAPSHOT}
    }
    totalCount
    page
    pageSize
  }
}`

export const GQL_GET_NOTICE = `
query getNotice($data: SingleIDInput!) {
  getNotice(data: $data) {
    ${NOTICE_SNAPSHOT}
  }
}`

export const GQL_CREATE_NOTICE = `
mutation createNotice($data: NoticeCreationInput!) {
  createNotice(data: $data) {
    ${NOTICE_SNAPSHOT}
  }
}
`

export const GQL_UPDATE_NOTICE = `
mutation updateNotice($data: NoticeUpdateInput!) {
  updateNotice(data: $data) {
    ${NOTICE_SNAPSHOT}
  }
}
`

export const GQL_DELETE_NOTICE = `
mutation deleteNotice($data: SingleIDInput!) {
  deleteNotice(data: $data) {
    ok
  }
}
`

export const GQL_LIST_MEDICAL_SUBJECTS = `
query listMedicalSubject($data: ListableMedicalSubjectInput!) {
  listMedicalSubject(data: $data) {
    list {
      ${MEDICAL_SUBJECT_SNAPSHOT}
    }
    totalCount
    page
    pageSize
  }
}`

//
// Pharmacy
//
export const PHARMACY_FULL_SNAPSHOT = `
  pharmacyId
  hospitals {
    hospitalId
    name
    address
  }
  name
  address
  addressDetail
  zipCode
  telephone
  fax
  availableDelivery
  availableQuick
  bankName
  accountNumber
  accountHolder
  businessLicenseNumber
  businessLicenseFile {
    blfId
    link
    fileName
  }
  bankbookFile {
    bbfId
    link
    fileName
  }
  businessLicenseFileId
  pharmacist {
    phpId
    pharmacistId
    pharmacist {
      name
      email
      licenseNumber
      birthDate
      cellPhone
      notiMarketing
    }
  }
`

const MEDICATION_DISPENSING_SNAPSHOT = `
  medicationDispensingId
  status
  requestedTime
  sentTime
  reservation {
    ${RESERVATION_SNAPSHOT}
  }
  reservationId
  medicinePrice
  deliveryPrice
  quickPrice
  requestNumber
  faxRequestNumber
  totalPrice
  deliveryMethod
  patientAddressJson
  agreedReplacement
  receiveNum
  pharmacy {
    ${PHARMACY_FULL_SNAPSHOT}
  }
  pharmacyId
  isPromotion
  discount
  rejectReason
  deliveryCallStatus
`

export const GQL_CREATE_PHARMACY = `
mutation createPharmacy($data: PharmacyCreationInput!) {
  createPharmacy(data: $data) {
    ${PHARMACY_FULL_SNAPSHOT}
  }
}
`

export const GQL_GET_PHARMACY = `
query getPharmacy($data: SingleIDInput!) {
  getPharmacy(data: $data) {
    ${PHARMACY_FULL_SNAPSHOT}
  }
}`

export const GQL_DELETE_PHARMACY = `
mutation deletePharmacy($data: SingleIDInput!) {
  deletePharmacy(data: $data) {
    ok
  }
}
`

export const GQL_LIST_PHARMACY = `
query listPharmacy($data: ListablePharmacyInput!) {
  listPharmacy(data: $data) {
    list {
      ${PHARMACY_FULL_SNAPSHOT}
    }
    totalCount
    page
    pageSize
  }
}
`

export const GQL_GET_CHATROOM_HAS_RESERVATION = `
query getChatRoomHasReservation($data: GetChatRoomHasReservationInput!) {
  getChatRoomHasReservation(data: $data) {
    chrId
    chatRoomId
    reservationId
  }
}
`

export const PHARMACY_HAS_HOSPITAL_SNAPSHOT = `
  createdAt
  updatedAt
  phhId
  pharmacy {
    ${PHARMACY_FULL_SNAPSHOT}
  }
  pharmacyId
  hospital {
    ${HOSPITAL_SNAPSHOT}
  }
  hospitalId
`

const COMMON_CHATS_FIELDS: string[] = ['createdAt', 'chatId', 'message', 'chatRoomId', 'sender']
const COMMON_RESERVATION_FIELDS: string[] = [
  'reservationId',
  'startTimeToken',
  'dateToken',
  'patientId',
  'doctorId',
  'status',
  'medicalSubjectId',
  'symptom',
  'reservationTime',
  'cancelledAt',
  'doctorFactNotes',
  'diagnosis',
  'diagnosisNames',
  'treatment',
  'paymentTotal',
  'sendPrescription',
  'pharmSentId',
  'pharmSentAt',
  'pharmDeliveryAddressJson',
  'rate',
  'ratedAt',
  'hospitalId',
  'cancelReason',
  'agoraToken',
  'paymentState',
  'patientEnteredAt',
  'patientExitedAt',
  'patientReceiptAt',
]

const COMMON_WAITING_DOCTOR_FIELDS = [
  'createdAt',
  'updatedAt',
  'waitingDoctorId',
  'licenseName',
  'name',
  'email',
  'cellPhone',
  'birthDate',
  'phone',
  'hospitalName',
  'hospitalAddress',
  'isEmailAuthenticated',
  'isHold',
  'holdReason',
  'hospitalManagerEmail',
  'emailAuthTime',
  `businessLicenseFile { ${COMMON_BUSINESS_LICENSE_FILE_FIELDS} }`,
  `bankbookFile { ${COMMON_BANK_BOOK_FILE_FIELDS} }`,
]

const createFields = (fieldArray: string[]) => fieldArray.join('\n')

export const CHATS_SNAPSHOT = `
  ${createFields(COMMON_CHATS_FIELDS)}
  reservation {
    ${createFields(COMMON_RESERVATION_FIELDS)}
    doctor {
        ${DOCTOR_SNAPSHOT}
    }
    patient {
        ${PATIENT_SNAPSHOT}
    }
  }
`

export const WAITING_DOCTOR_SNAPSHOT = `
  ${createFields(COMMON_WAITING_DOCTOR_FIELDS)}
`

export const GQL_GET_CHATS = `
query getChats($data: ListableChatsInput!) {
  getChats(data: $data) {
    list {
      ${CHATS_SNAPSHOT}
    }
    totalCount
    page
    pageSize
  }
}
`

export const GQL_SEND_CHATS = `
mutation sendMessage($data: ChatsSendInput!) {
  sendMessage(data: $data) {
    ${CHATS_SNAPSHOT}
  }
}
`

export const GQL_LIST_PHARMACY_FOR_HOSPITAL = `
query listPharmacyForHospital($data: ListablePharmacyForHospitalInput!) {
  listPharmacyForHospital(data: $data) {
    list {
      ${PHARMACY_FULL_SNAPSHOT}
    }
    totalCount
    page
    pageSize
  }
}`

//
// PHARMACY_HAS_HOSPITAL
//
const PHARMACY_HAS_HOSPITAL_FULL_SNAPSHOT = `
  phhId
`

export const GQL_CREATE_PHARMACY_HAS_HOSPITAL = `
mutation createPharmacyHasHospital($data: PharmacyHasHospitalCreationInput!) {
  createPharmacyHasHospital(data: $data) {
    ${PHARMACY_HAS_HOSPITAL_FULL_SNAPSHOT}
  }
}
`

export const GQL_UPDATE_PHARMACY_HAS_HOSPITAL = `
mutation updatePharmacyHasHospital($data: PharmacyHasHospitalUpdateInput!) {
  updatePharmacyHasHospital(data: $data) {
    ${PHARMACY_HAS_HOSPITAL_FULL_SNAPSHOT}
  }
}
`

export const GQL_GET_PHARMACY_HAS_HOSPITAL = `
query getPharmacyHasHospital($data: SingleIDInput!) {
  getPharmacyHasHospital(data: $data) {
    ${PHARMACY_HAS_HOSPITAL_FULL_SNAPSHOT}
  }
}`

export const GQL_REMOVE_PHARMACY_HAS_HOSPITAL = `
mutation deletePharmacyHasHospital($data: SingleIDInput!) {
  deletePharmacyHasHospital(data: $data) {
    ok
  }
}
`

export const GQL_LIST_PHARMACY_HAS_HOSPITAL = `
query listPharmacyHasHospital($data: ListablePharmacyHasHospitalInput!) {
  listPharmacyHasHospital(data: $data) {
    list {
      ${PHARMACY_HAS_HOSPITAL_FULL_SNAPSHOT}
    }
    totalCount
    page
    pageSize
  }
}
`

export const GQL_RESET_DOCTOR_PASSWORD = `
mutation resetDoctorPassword($data: SingleIDInput!) {
  resetDoctorPassword(data: $data) {
    ok
  }
}
`

export const GQL_FINISH_RESERVATION = `
mutation finishReservation($data: SingleIDInput!) {
  finishReservation(data: $data) {
    ok
  }
}
`

export const GQL_SEND_PRESCRIPTIONS = `
mutation sendPrescriptions($data: ReservationSendPrescriptionsInput!) {
  sendPrescriptions(data: $data) {
    ${RESERVATION_SNAPSHOT}
  }
}
`

export const GQL_GET_PATIENT = `
query getPatient($data: SingleIDInput!) {
  getPatient(data: $data) {
    ${PATIENT_SNAPSHOT}
  }
}
`

export const APP_VERSION_SNAPSHOT = `
  appVersionId
  build
  version
  isAndroid
  isIOS
  description
  updateType
  createdAt
`

export const GQL_RESET_ACCOUNT_PASSWORD = `
mutation resetAccountPassword($data: ResetAccountPasswordInput!) {
  resetAccountPassword(data: $data) {
    ok
  }
}
`

export const GQL_CHANGE_ACCOUNT_PASSWORD = `
mutation changeAccountPassword($data: ChangeAccountPasswordInput!) {
  changeAccountPassword(data: $data) {
    ok
  }
}
`

export const SCHEDULE_SETTING_SNAPSHOT = `
  scheduleSettingId
  doctorId
  timeToken
  scheduleType
  reservation {
    ${RESERVATION_SNAPSHOT}
    patient {
      name
    }
  }
`

export const GQL_LIST_SCHEDULE_SETTING = `
query listScheduleSetting($data: ListableScheduleSettingInput!) {
  listScheduleSetting(data: $data) {
    list {
      ${SCHEDULE_SETTING_SNAPSHOT}
    }
    totalCount
    page
    pageSize
    doctor {
      schedule
      hospital {
        slotSize
      }
    }
  }
}`

export const GQL_UPDATE_SCHEDULE_SETTING = `
mutation updateScheduleSetting($data: ScheduleSettingUpdateInput!) {
  updateScheduleSetting(data: $data) {
    ${SCHEDULE_SETTING_SNAPSHOT}
  }
}
`

export const GQL_LIST_DIAG_NAME = `
query listDiagName($data: ListableDiagNameInput!) {
  listDiagName(data: $data) {
    list {
      code
      diagNameId
      koDesc
      enDesc
    }
    totalCount
    page
    pageSize
  }
}`

export const GQL_UPDATE_RESERVATION_DIAGNOSIS = `
mutation updateReservationDiagnosis($data: UpdateReservationDiagnosisInput!) {
  updateReservationDiagnosis(data: $data) {
    ${RESERVATION_SNAPSHOT}
  }
}
`

export const GQL_PAY_RESERVATION = `
mutation payReservation($data: ReservationPaymentInput!) {
  payReservation(data: $data) {
    ${RESERVATION_SNAPSHOT}
  }
}
`

export const GQL_START_RESERVATION = `
mutation startReservation($data: SingleIDInput!) {
  startReservation(data: $data) {
    ${RESERVATION_SNAPSHOT}
  }
}
`

export const GQL_PATIENT_RECEIPT = `
mutation setPatientReceipt($data: SingleIDInput!) {
  setPatientReceipt(data: $data) {
    ${RESERVATION_SNAPSHOT}
  }
}
`

export const GQL_DOCTOR_RECEIPT = `
mutation setDoctorReceipt($data: SingleIDInput!) {
  setDoctorReceipt(data: $data) {
    ${RESERVATION_SNAPSHOT}
  }
}
`
export const GQL_SEND_REQUEST_NOTIFICATION_TO_PATIENT = `
mutation sendRequestNotificationToPatient($data: NotificationSendRequestInput!) {
  sendRequestNotificationToPatient(data: $data) {
    ok
  }
}
`
export const GQL_DOCTOR_READY = `
mutation setDoctorReady($data: SingleIDInput!) {
  setDoctorReady(data: $data) {
    ${RESERVATION_SNAPSHOT}
  }
}
`

export const GQL_START_TREATMENT = `
mutation startTreatment($data: SingleIDInput!) {
  startTreatment(data: $data) {
    ${RESERVATION_SNAPSHOT}
  }
}
`

export const GQL_SET_PRESCRIPTION_STATE = `
mutation setPrescriptionState($data: SingleIDInput!) {
  setPrescriptionState(data: $data) {
    ${RESERVATION_SNAPSHOT}
  }
}
`

const TRANSACTION_LOG_SNAPSHOT = `
  transactionLogId
  reservationId
  cardInfoId
  transactionId
  status
  createdAt
  reservation {
    status
    doctor {
      name
    }
    patient {
      name
    }
    medicalSubject {
      name
    }
    reservationTime
    createdAt
    paymentTotal
  }
`

export const GQL_LIST_TRANSACTION_LOG = `
query listTransactionLog($data: ListableTransactionLogInput!) {
  listTransactionLog(data: $data) {
    list {
      ${TRANSACTION_LOG_SNAPSHOT}
    }
    totalCount
    page
    pageSize
  }
}`

const FAQ_SNAPSHOT = `
  faqId
  title
  link
  priority
  createdAt
`

export const GQL_CREATE_FAQ = `
mutation createFaq($data: FaqCreationInput!) {
  createFaq(data: $data) {
    ${FAQ_SNAPSHOT}
  }
}
`

export const GQL_GET_FAQ = `
query getFaq($data: SingleIDInput!) {
  getFaq(data: $data) {
    ${FAQ_SNAPSHOT}
  }
}`

export const GQL_UPDATE_FAQ = `
mutation updateFaq($data: FaqUpdateInput!) {
  updateFaq(data: $data) {
    ${FAQ_SNAPSHOT}
  }
}
`

export const GQL_DELETE_FAQ = `
mutation deleteFaq($data: SingleIDInput!) {
  deleteFaq(data: $data) {
    ok
  }
}
`

export const GQL_LIST_FAQ = `
query listFaq($data: ListableFaqInput!) {
  listFaq(data: $data) {
    list {
      ${FAQ_SNAPSHOT}
    }
    totalCount
    page
    pageSize
  }
}
`

export const GQL_LIST_INQUIRY = `
query listInquiry($data: ListableInquiryInput!) {
  listInquiry(data: $data) {
    list {
      ${INQUIRY_SNAPSHOT}
    }
    totalCount
    page
    pageSize
  }
}
`

export const GQL_GET_INQUIRY = `
query getInquiry($data: SingleIDInput!) {
  getInquiry(data: $data) {
    ${INQUIRY_SNAPSHOT}
  }
}
`

export const GQL_CREATE_INQUIRY = `
mutation createInquiry($data: InquiryCreationInput!) {
  createInquiry(data: $data) {
    ${INQUIRY_SNAPSHOT}
  }
}
`

export const GQL_LIST_PATIENT_MYDATA = `
query listPatientMyData($data: ListableDataShareInput!) {
  listPatientMyData(data: $data) {
    list {
        dataShareId
        createdAt
        hospitalId
        doctorId
        patient {
          ${PATIENT_SNAPSHOT}
        }
        dataShareFile {
            link
        }
    }
    totalCount
    page
    pageSize
  }
  }
`

const COMMON_PATIENT_MEMO_FIELDS: string[] = ['memoId', 'doctorId', 'patientId', 'content']

const COMMON_PATIENT_MEMO_SNAPSHOT = `
  ${createFields(COMMON_PATIENT_MEMO_FIELDS)}
`

export const GQL_GET_PATIENT_MEMO = `
query getPatientMemo($data: PatientMemoSearchInput!) {
  getPatientMemo(data: $data) {
    ${COMMON_PATIENT_MEMO_SNAPSHOT}
  }
}
`

export const GQL_CREATE_PATIENT_MEMO = `
mutation createPatientMemo($data: PatientMemoCreationInput!) {
  createPatientMemo(data: $data) {
    ${COMMON_PATIENT_MEMO_SNAPSHOT}
  }
}
`

export const GQL_UPDATE_PATIENT_MEMO = `
mutation updatePatientMemo($data: PatientMemoUpdateInput!) {
  updatePatientMemo(data: $data) {
    ${COMMON_PATIENT_MEMO_SNAPSHOT}
  }
}
`

export const GQL_DELETE_PATIENT_MEMO = `
mutation deletePatientMemo($data: PatientMemoDeleteInput!) {
  deletePatientMemo(data: $data) {
    ${COMMON_PATIENT_MEMO_SNAPSHOT}
  }
}
`

export const GQL_GET_POPUP_INFO = `
query getPopupInfo($data: PopupInfoSearchInput!) {
  getPopupInfo(data: $data) {
    ${POPUP_INFO_SNAPSHOT}
  }
}
`

export const GQL_CREATE_POPUP_INFO = `
mutation createPopupInfo($data: PopupInfoCreationInput!) {
  createPopupInfo(data: $data) {
    ${POPUP_INFO_SNAPSHOT}
  }
}
`

export const GQL_UPDATE_POPUP_INFO = `
mutation updatePopupInfo($data: PopupInfoUpdateInput!) {
  updatePopupInfo(data: $data) {
    ${POPUP_INFO_SNAPSHOT}
  }
}
`

export const GQL_DELETE_POPUP_INFO = `
mutation deletePopupInfo($data: PopupInfoDeleteInput!) {
  deletePopupInfo(data: $data) {
    ${POPUP_INFO_SNAPSHOT}
  }
}
`

export const GQL_LIST_POPUP_INFO = `
query listPopupInfo($data: PopupInfoListInput!) {
  listPopupInfo(data: $data) {
    list {
      ${POPUP_INFO_SNAPSHOT}
    }
    totalCount
  }
}
`
export const GQL_REJOIN_PATIENT_BY_ADMIN = `
mutation rejoinPatientByAdmin($data: RejoinPatientByAdminInput!) {
  rejoinPatientByAdmin(data: $data) {
    ok
  }
}
`

const COMMON_EVIX_ADMIN_FIELDS = [
  'createdAt',
  'updatedAt',
  'lastLoginToken',
  'lastAccessTime',
  'unread',
  'leftAt',
  'evixAdminId',
  'email',
  'password',
  'name',
  'webFcmToken',
  'level',
]

export const GQL_UPDATE_EVIX_ADMIN_LEVEL = `
mutation updateEvixAdminLevel($data: EvixAdminUpdateLevelInput!) {
  updateEvixAdminLevel(data: $data) {
    ${COMMON_EVIX_ADMIN_FIELDS}
  }
}
`

export const GQL_DOCTOR_FIND_ID = `
query($data: DoctorFindIdInput!) {
  doctorFindId(data: $data) {
    email
  }
 }
`

const COMMON_DOCTOR_FIELDS = [
  'createdAt',
  'updatedAt',
  'lastLoginToken',
  'lastAccessTime',
  'unread',
  'leftAt',
  'doctorId',
  'email',
  'phone',
  'name',
  'introduction',
  'career',
  'licenseYear',
  'licenseName',
  'hospitalId',
  'status',
  'medicalSubjectsText',
  'isHidden',
  'profession',
  'schedule',
]

export const GQL_CREATE_DOCTOR_BY_DOCTOR = `
mutation($data: DoctorCreationByDoctorInput!) {
  createDoctorByDoctor(data: $data) {
    ${COMMON_WAITING_DOCTOR_FIELDS}
  }
}
`

export const GQL_LIST_WAITING_DOCTOR = `
query($data: ListableWaitingDoctorInput!) {
  listWaitingDoctor(data: $data) {
    list {
      ${WAITING_DOCTOR_SNAPSHOT}
    }
    totalCount
    page
    pageSize
  }
 }
`

export const GQL_APPROVED_BY_ADMIN = `
mutation($data: SingleIDInput!) {
  approvedByAdmin(data: $data) {
    ${COMMON_DOCTOR_FIELDS}
  }
}
`

export const GQL_HOLD_BY_ADMIN = `
mutation($data: DoctorApprovalHoldInput!) {
  holdByAdmin(data: $data) {
    ${COMMON_WAITING_DOCTOR_FIELDS}
  }
}
`

export const GQL_SEND_AUTH_MAIL_AGAIN = `
query($data: SingleIDInput!) {
  sendAuthMailAgain(data: $data) {
    ok
  }
}
`

export const GQL_UPDATE_EMAIL_AND_SEND_AUTH_MAIL = `
mutation($data: DoctorUpdateEmailInput!) {
  updateEmailAndSendAuthMail(data: $data) {
    ok
    email
  }
}
`

export const GQL_CHECK_LICENSE_NAME = `
query($data: DoctorLicenseCheckInput!) {
  checkLicenseName(data: $data) {
    ok
  }
}
`

export const GQL_CHECK_NEW_DOCTOR = `
query($data: CheckNewDoctorInput!) {
  checkNewDoctor(data: $data) {
    ok
  }
}
`

export const GQL_DELETE_WAITING_DOCTOR = `
mutation($data: SingleIDInput!) {
  deleteWaitingDcotor(data: $data) {
    ok
  }
}
`

export const GQL_CHECK_EMAIL_AUTH = `
query($data: SingleIDInput!) {
  checkEmailAuth(data: $data) {
    ok
  }
}
`

export const GQL_GET_WAITING_DOCTOR = `
query($data: SingleIDInput!) {
  getWaitingDoctor(data: $data) {
    ${COMMON_WAITING_DOCTOR_FIELDS}
  }
}
`

export const GQL_UPDATE_WAITING_DOCTOR = `
mutation($data: WaitingDoctorUpdateInput!) {
  updateWaitingDoctor(data: $data) {
    ${COMMON_WAITING_DOCTOR_FIELDS}
  }
}
`

export const GQL_GET_RECOMMENDER = `
query($data: RecommenderInput!) {
  getRecommender(data: $data) {
    doctors {
	    ${COMMON_DOCTOR_FIELDS}
	 }
   doctorCount
  }
}
`

export const GQL_DOCTOR_INVITE = `
query($data: DoctorInviteInput!) {
  doctorInvite(data: $data) {
    emails
  }
}
`

export const GQL_DOCTOR_STAT_INFO = `
query getDoctorStatInfo($data: SingleIDInput!) {
  getDoctorStatInfo(data: $data) {
    totalJoinPatient
    totalJoinDoctor
    totalSharedMydata
  }
}
`

export const GQL_CHECK_DOCTOR_IS_HOSPITAL_MANAGER = `
mutation checkDoctorIsHospitalManager($data: SingleIDInput!) {
  checkDoctorIsHospitalManager(data: $data) {
    ok
  }
}
`

const COMMON_WAITING_PHARMACIST_HAS_HOSPITAL_FIELDS = [
  'wphhId',
  `waitingPharmacist {
	  waitingPharmacistId
	  name
	 }`,
  'waitingPharmacistId',
  `hospital { ${HOSPITAL_SNAPSHOT} }`,
  `hospitalId`,
]

const COMMON_WAITING_PHARMACIST_FIELDS = [
  'createdAt',
  'updatedAt',
  'waitingPharmacistId',
  'name',
  'email',
  'licenseNumber',
  'cellPhone',
  'birthDate',
  'pharmacyName',
  'pharmacyAddress',
  'pharmacyAddressDetail',
  'pharmacyZipCode',
  'pharmacyPhoneNumber',
  'pharmacyFaxNumber',
  'businessLicenseNumber',
  `businessLicenseFile { ${COMMON_BUSINESS_LICENSE_FILE_FIELDS} }`,
  'businessLicenseFileId',
  `bankbookFile { ${COMMON_BANK_BOOK_FILE_FIELDS} }`,
  'bankbookFileId',
  'bankName',
  'accountHolder',
  'accountNumber',
  'availableDelivery',
  'availableQuick',
  'notiMarketing',
  'isEmailAuthenticated',
  'emailAuthTime',
  'isHold',
  'holdReason',
  `hospitals { ${COMMON_WAITING_PHARMACIST_HAS_HOSPITAL_FIELDS} }`,
]

const COMMON_PHARMACY_FIELDS = [
  'pharmacyId',
  'name',
  'address',
  'addressDetail',
  'zipCode',
  'telephone',
  'fax',
  'availableDelivery',
  'availableQuick',
  'bankName',
  'accountNumber',
  'accountHolder',
  'businessLicenseNumber',
  'businessLicenseFileId',
]

export const WAITING_PHARMACIST_SNAPSHOT = `
  ${createFields(COMMON_WAITING_PHARMACIST_FIELDS)}
`

export const PHARMACY_SNAPSHOT = `
  ${createFields(COMMON_PHARMACY_FIELDS)}
`

export const PHARMACIST_SNAPSHOT = `
	lastAccessTime
	lastPasswordUpdatedTime
	pharmacistId
  name
  email
  licenseNumber
  cellPhone
  birthDate
  unrecoverable
  webFcmToken
  pharmacyId
  notiMarketing
  pharmacy {
    pharmacyId
    name
    address
    addressDetail
    zipCode
    telephone
    availableDelivery
    availableQuick
    bankName
    accountNumber
    accountHolder
    businessLicenseNumber
    fax
    hospitals{
      name
      address
      hospitalId
    }
    businessLicenseFile {
      blfId
      link
      fileName
    }
    bankbookFile {
      bbfId
      link
      fileName
    }
    businessLicenseFileId
     hospitals {
      hospitalId
      name
    }
  }
`

export const GQL_CHECK_LICENSE_NAME_PHARMACIST = `
query checkLicenseNumberPharmacist($data: CheckLicenseNumberPharmacistInput!) {
  checkLicenseNumberPharmacist(data: $data) {
    ok
  }
}
`

export const GQL_SEND_WAITING_PHARMACIST_MAIL_AGAIN = `
query sendWaitingPharmacistMailAgain($data: SingleIDInput!) {
  sendWaitingPharmacistMailAgain(data: $data) {
    ok
  }
}
`

export const GQL_UPDATE_WAITING_PHARMACIST_EMAIL_AND_SEND_AUTH_MAIL = `
mutation updateWaitingPharmacistEmailAndSendMail($data: UpdateWaitingPharmacistEmailAndSendAuthMailInput!) {
  updateWaitingPharmacistEmailAndSendMail(data: $data) {
    ok
    email
  }
}
`

export const GQL_CREATE_WAITING_PHARMACIST = `
mutation createWaitingPharmacist($data: CreateWaitingPharmacistInput!) {
  createWaitingPharmacist(data: $data) {
    ${WAITING_PHARMACIST_SNAPSHOT}
  }
}
`

export const GQL_LOGIN_PHARMACIST = `
mutation loginPharmacist($data: LoginPharmacistInput!) {
  loginPharmacist(data: $data) {
    me {
      ${PHARMACIST_SNAPSHOT}
    }
    token
    recovered
  }
}
`

export const GQL_GET_PHARMACIST_PROFILE = `
query getPharmacistMyProfile {
  getPharmacistMyProfile {
    ${PHARMACIST_SNAPSHOT}
  }
}
`

export const GQL_UPDATE_PHARMACIST_PROFILE = `
  mutation updatePharmacistProfile($data: UpdatePharmacistInput!) {
    updatePharmacistProfile(data: $data) {
      ${PHARMACIST_SNAPSHOT}
    }
  }
`

export const GQL_PHARMACIST_FIND_ID = `
query pharmacistFindId($data: PharmacistFindIdInput!) {
  pharmacistFindId(data: $data) {
    email
  }
}
`

export const GQL_LIST_WAITING_PHARMACIST = `
query listWaitingPharmacist($data: ListableWaitingPharmacistInput!) {
  listWaitingPharmacist(data: $data) {
    list {
      ${WAITING_PHARMACIST_SNAPSHOT}
    }
    totalCount
    page
    pageSize
  }
 }
`

export const GQL_LIST_PHARMACIST = `
  query listPharmacist($data: ListablePharmacistInput!) {
    listPharmacist(data: $data) {
      list {
        ${PHARMACIST_SNAPSHOT}
        hospital {
          name
        }
        medicalSubjectsText
        isHidden
      }
      totalCount
      page
      pageSize
    }
  }
`

export const GQL_APPROVE_WAITING_PHARMACIST_BY_ADMIN = `
mutation($data: SingleIDInput!) {
  approvedWaitingPharmacistByAdmin(data: $data) {
    pharmacy {
      ${PHARMACY_SNAPSHOT}
    }
    pharmacist {
      ${PHARMACIST_SNAPSHOT}
    }
  }
 }
`

export const GQL_HOLD_WAITING_PHARMACIST_BY_ADMIN = `
mutation($data: HoldWaitingPharmacistByAdminInput!) {
  holdWaitingPharmacistByAdmin(data: $data) {
    ${WAITING_PHARMACIST_SNAPSHOT}
  }
 }
`

export const GQL_GET_WAITING_PHARMACIST = `
query getWaingPharmacist($data: SingleIDInput!) {
  getWaingPharmacist(data: $data) {
    ${WAITING_PHARMACIST_SNAPSHOT}
  }
 }
`

export const GQL_UPDATE_WAITING_PHARMACIST = `
mutation updateWaitingPharmacist($data: UpdateWaitingPharmacistInput!) {
  updateWaitingPharmacist(data: $data) {
    ${WAITING_PHARMACIST_SNAPSHOT}
  }
 }
`

export const GQL_DELETE_WAITING_PHARMACIST = `
mutation deleteWaitingPharmacist($data: SingleIDInput!) {
  deleteWaitingPharmacist(data: $data) {
    ok
  }
}
`

export const GQL_GET_PHARMACIST = `
query($data: SingleIDInput!) {
  getPharmacist(data: $data) {
    ${PHARMACIST_SNAPSHOT}
  }
}
`

export const GQL_UPDATE_PHARMACIST = `
  mutation updatePharmacist($data: UpdatePharmacistInput!) {
    updatePharmacist(data: $data) {
      ${PHARMACIST_SNAPSHOT}
    }
  }
`

export const GQL_UPDATE_PHARMACY = `
mutation updatePharmacy($data: PharmacyUpdateInput!) {
  updatePharmacy(data: $data) {
    ${PHARMACY_FULL_SNAPSHOT}
  }
}
`

export const GQL_LIST_MEDICATION_DISPENSING = `
query listMedicationDispensing($data: ListableWMedicationDispensingInput!) {
  listMedicationDispensing(data: $data) {
    list {
      ${MEDICATION_DISPENSING_SNAPSHOT}
    }
    totalCount
    page
    pageSize
  }
}
`
export const GQL_GET_MEDICATION_DISPENSING = `
query getMedicationDispensing($data: SingleIDInput!) {
  getMedicationDispensing(data: $data) {
    ${MEDICATION_DISPENSING_SNAPSHOT}
  }
}`

export const GQL_REJECT_DISPENSING_REQUEST = `
mutation rejectDispensingRequest($data: RejectDispensingRequestInput!) {
  rejectDispensingRequest(data: $data) {
    ${MEDICATION_DISPENSING_SNAPSHOT}
  }
}`

export const GQL_CHANGE_MEDICATION_DISPENSING = `
mutation chargeDispensingFee($data: ChargeMedicationDispensingInput!) {
  chargeDispensingFee(data: $data) {
    ${MEDICATION_DISPENSING_SNAPSHOT}
  }
}
`

const RESERVATION_EXTENSION = `
	reservationId
  rrn
`

export const GET_RESERVATION_EXTENSION = `
query getReservationExtension($data: SingleIDInput!) {
  getReservationExtension(data: $data) {
    ${RESERVATION_EXTENSION}
  }
}`
