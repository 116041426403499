import { ODHSpace, ODIcon, ODIcons, ODModal, ODModalSize } from '@mdpp/od-react-belt'
import moment from 'moment-timezone'
import React from 'react'
import styled, { css } from 'styled-components'
import { GQLChats } from '@mdpp/common/lib/@types/server.schema'
import { Button, BP } from '../../../../common/RebeccaButton'
import {
  EvixChatMessageType,
  EvixChatMessageWrapper,
  IEvixChatMessageNormal,
} from '../../../../common/chat/EvixChatMessageWrapper'

interface IChatModalProps {
  chats: GQLChats[]
  isOpen: boolean
  onClose: () => void
}

const Wrapper = styled.div`
  bottom: 0;
  background-color: white;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 800px;
  border: 1px solid var(--mpPrimary500);

  ${(p: { finishedMedical?: boolean }) =>
    p.finishedMedical &&
    css`
      position: static;
      flex: 1;
      width: auto;
      border: 0;
      box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.15);
      border-radius: 5px;
    `}

  > :not(:first-child) {
    margin-top: 10px;
  }
`

const ChatScrollArea = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding: 10px 30px 20px 30px;

  > :not(:first-child) {
    margin-top: 30px;
  }
`

const ChatHeader = styled.header`
  display: flex;
  width: 100%;
  height: 66px;
  align-items: center;
  padding: 19px 24px 19px 16px;
  border-bottom: 1px solid var(--gray200);
`

const ChatText = styled.span`
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
  line-height: 1.18;
  letter-spacing: -0.5px;
`

const ChatIconWrapper = styled.div`
  & > i {
    width: 17px;
    height: 17px;
    font-size: 17px;
    color: var(--mpPrimary500);
  }
`

const CloseIconStyle = {
  fontSize: 19,
  color: '#5C5F62',
}

const CloseButtonBox = styled.div`
  position: absolute;
  top: 25px;
  right: 25px;
  cursor: pointer;
`
const ButtonWrapper = styled.div`
  // width: 200px;
  margin-right: 16px;
`

const BottomWrapper = styled.div`
  height: 68px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border-top: 1px solid var(--gray200) !important;
`


export const ChatModal: React.FC<IChatModalProps> = props => {
  const { chats, isOpen, onClose } = props
  const scrollTarget = React.useRef<HTMLDivElement | null>(null)

  const [scrolled, setScrolled] = React.useState(false)
  const scrollToBottom = () => {
    if (!scrolled) {
      const node: HTMLDivElement | null = scrollTarget.current
  
      if (node) {
        node.scrollTop = node.scrollHeight
        setScrolled(true)
      }
    }
  }

  React.useEffect(() => {
    if (isOpen) {
      setScrolled(false)
    }
  }, [isOpen])

  scrollToBottom()

  return (
    <ODModal rounded={true} isOpen={isOpen} toggle={onClose} size={ODModalSize.Normal}>
      <Wrapper>
        <ChatHeader>
          <ChatText>지난 채팅 보기</ChatText>
          <CloseButtonBox onClick={onClose}>
            <ODIcon icon={ODIcons.CoreX} style={CloseIconStyle} />
          </CloseButtonBox>
        </ChatHeader>
        <ChatScrollArea ref={scrollTarget}>
          {chats.map(chat => {
            if (chat.sender === 'DOCTOR') {
              const message: IEvixChatMessageNormal = {
                messageType: EvixChatMessageType.Normal,
                isMine: true,
                name: chat.reservation.doctor.name,
                // name: 'Doctor',
                time: moment(chat.createdAt).format('YYYY.MM.DD HH:mm'),
                message: chat.message,
              }
              return <EvixChatMessageWrapper key={`${chat.chatId}`} message={message} />
            } else {
              const message: IEvixChatMessageNormal = {
                messageType: EvixChatMessageType.Normal,
                isMine: false,
                name: chat.reservation.patient.name ?? 'patient',
                time: moment(chat.createdAt).format('YYYY.MM.DD HH:mm'),
                message: chat.message,
              }
              return <EvixChatMessageWrapper key={`${chat.chatId}`} message={message} />
            }
          })}
        </ChatScrollArea>
        <BottomWrapper>
          <ButtonWrapper>
            <Button size={BP.SIZE.M} type={BP.TYPE.Primary} onClick={onClose} >
              닫기
            </Button>
          </ButtonWrapper>
        </BottomWrapper>
      </Wrapper>
    </ODModal>
  )
}
