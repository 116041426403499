import React from 'react'
import { Modal } from "reactstrap";
import { EvixButton, EvixButtonTheme } from '../../common/EvixButton'
import IconClose from '../../assets/common/icon-close.svg'
import IconWarning from '../../assets/common/warning-red.svg'
import IconWarningBlue from '../../assets/common/warning-blue.svg'
import {Wrapper, Header, Container, Content,  DescriptionContainer, ButtonWrapper, ConfirmTitleWrap, ConfirmTitle, SimpleDescription } from '../../common/style/ModalStyle'

interface IsBtnConfirmModalProps {
  isTitle?: boolean
  title?: string
  titleIcon?: string
  titleIconSize?: string
  isDescriptionIcon: boolean
  description: object
  descriptionIcon: string
  hideCancel?: boolean
  style?: object
  BtnLeft: string
  BtnRight: string
  onLeftBtnPress: () => void
  onRightBtnPress: () => void
  onCancelPress: () => void
}

export const IsBtnConfirmModal: React.FC<IsBtnConfirmModalProps> = props => {
  const { style, description, isDescriptionIcon, descriptionIcon,  title, isTitle, titleIcon, titleIconSize, hideCancel, 
    BtnLeft, BtnRight, onLeftBtnPress, onRightBtnPress, onCancelPress } = props

  return (
    <Modal isOpen={true} toggle={() => {}} style={style}>
      <Wrapper>
        <Header>
          <img src={IconClose} onClick={onCancelPress} alt='close icon'/>
        </Header>

        <Container>
          <Content className={!isTitle ? 'no-title' : undefined }>
            {isTitle && (
              <ConfirmTitleWrap className={titleIconSize === 'L' ? 'icon-large' : undefined}>
                <img src={titleIcon} alt='warning bullet' />
                <ConfirmTitle>{title}</ConfirmTitle>
              </ConfirmTitleWrap>
            )}
            
            <DescriptionContainer>
              {isDescriptionIcon && (
                <img src={descriptionIcon} alt='warning bullet' />
              )}
              
              <SimpleDescription>{description}</SimpleDescription>
            </DescriptionContainer> 
          </Content>

          {/* 버튼 영역 */}
          <ButtonWrapper>
            <EvixButton theme={EvixButtonTheme.H48WhiteInline} style={!hideCancel ? {flex: 1} : {width: '100%'}}
            onClick={onLeftBtnPress} disabled={false}>{BtnLeft}</EvixButton>
            {!hideCancel && (
              <>
              <EvixButton theme={EvixButtonTheme.H48BlackInline} onClick={onRightBtnPress} 
              style={{flex: 1}} disabled={false}>{BtnRight}</EvixButton>
              </>
            )}
          </ButtonWrapper>
        </Container>
      </Wrapper>
    </Modal>
  )
}


