import { ODIcons, ODVSpace } from '@mdpp/od-react-belt'
import React from 'react'
import { NumberParam, useQueryParam, withDefault } from 'use-query-params'
import { EvixTabBar, ITabBarIcon } from '../../../common/EvixTabBar'
import { MainContentWithInset } from '../../../common/layout/MainContentWithInset'
import { AdminDoctorScheduleTablePage } from '../../schedule/AdminDoctorScheduleTablePage'
import { AdminDoctorProfileDiagnosisPage } from './AdminDoctorProfileDiagnosisPage'
import { AdminDoctorProfileHospital } from './AdminDoctorProfileHospital'
import { AdminDoctorProfilePage } from './AdminDoctorProfilePage'
import { AdminDoctorReviewPage } from './AdminDoctorReviewPage'
import styled from 'styled-components/macro'
import {Page, PageContainer, PageHeader, PageTitle} from "../../../common/page/Page";

interface IAdminDoctorDetailPageContainerProps {
  doctorId: number
}

const Items: ITabBarIcon[] = [
  {
    icon: ODIcons.Doctor,
    text: '의사정보',
  },
  { icon: ODIcons.Patient, text: '진료정보' },
  {
    icon: ODIcons.CoreBuilding,
    text: '병원정보',
  },
  {
    icon: ODIcons.CoreSpeech,
    text: '후기',
  },
  {
    icon: ODIcons.CoreCalendarCheck,
    text: '스케줄',
  },
]

export const AdminDoctorDetailPageContainer: React.FC<IAdminDoctorDetailPageContainerProps> = props => {
  const { doctorId } = props
  const [tabRaw, setTab] = useQueryParam('tab', withDefault(NumberParam, 0))
  const tab = Math.min(Math.max(tabRaw, 0), Items.length - 1)

  return (
    <PageContainer>
      <Page>
        <PageHeader>
          <PageTitle>의사</PageTitle>
        </PageHeader>
        <EvixTabBar items={Items} selectedIndex={tab} onClickTab={setTab} />
        <ODVSpace h={18}/>
        <PageContent>
          {tab === 0 && <AdminDoctorProfilePage doctorId={doctorId} />}
          {tab === 1 && <AdminDoctorProfileDiagnosisPage doctorId={doctorId} />}
          {tab === 2 && <AdminDoctorProfileHospital doctorId={doctorId} />}
          {tab === 3 && <AdminDoctorReviewPage doctorId={doctorId} />}
          {tab === 4 && <AdminDoctorScheduleTablePage doctorId={doctorId} />}
        </PageContent>
      </Page>
    </PageContainer>
   
  )
}

export const PageContent = styled.div`
  width: 100%;
  padding-bottom: 48px;
  display: flex;
  flex-direction: column;
  /* gap: 24px; */
`

