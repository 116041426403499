import {
  ODListablePaginatedTable2,
  ODListablePagination,
  ODListableSearchBox,
  useODListableContext,
  ODListableOption,
} from '@mdpp/od-react-belt'
import moment from 'moment-timezone'
import React from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components/macro'
import { phoneNumberSplitWithString } from 'kpnfmt'
import { GQLMydataShare } from '@mdpp/common/lib/@types/server.schema'
import { useSharedDataListDataLoader } from './useSharedDataListDataLoader'
import { WebUtils } from '../../utils/webUtils'
import { SiteUrls } from '../../SiteUrls'
import { Page, PageContainer, PageContent, PageHeader, PageTitle } from '../../common/page/Page'
import IconShareDoc from '../../assets/common/icon-shar-doct.svg'
import IconShareHosp from '../../assets/common/icon-shar-hosp.svg'
import { COLORS } from '../../styles/colors'
import IconWarningBlue from '../../assets/common/warning-blue.svg'

export interface ISharedDataListableOption extends ODListableOption {
  filter: string
}

export interface ISharedDataListableTableProps {
  isDoctor: boolean
}

export const SharedDataListableTable: React.FC<ISharedDataListableTableProps> = props => {
  const { isDoctor } = props
  const { Provider, Context } = useODListableContext<GQLMydataShare, ISharedDataListableOption>()
  const history = useHistory()

  const dataLoader = useSharedDataListDataLoader()

  const toolbarStyle = { display: 'flex', justifyContent: 'space-between' }
  const searchBoxStyle = { width: 230 }

  const wrapperStyle = {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    marginTop: 15,
    borderBottom: 'none',
  }

  const cardStyle = {
    display: 'flex',
    minWidth: 700,
    background: '#FFFFFF',
    boxShadow: '0px 0px 5px rgba(23, 24, 24, 0.05), 0px 1px 2px rgba(0, 0, 0, 0.15)',
    borderRadius: 8,
    border: 0,
  }

  // doctor
  return (
    <Provider
      dataLoader={dataLoader}
      keyExtractor={v => v.dataShareId.toString()}
      pageSize={10}
      onDataLoaderError={WebUtils.showError}
      searchOnLoad
    >
      <PageContainer>
        <Page>
          <PageHeader>
            <PageTitle className="title-description">
              스마트 진료지원
              {/*[MWHLS] 스마트 진료지원 마크업 주석 처리 - 적용시, 아래 코드 활성화해주세요.  */}
              {/* <MessageWrap>
                <IconWarning src={IconWarningBlue} />
                <MessageText>병원에 공유된 기록입니다.</MessageText>
              </MessageWrap> */}
            </PageTitle>
            <div style={toolbarStyle}>
              <ODListableSearchBox
                listableContext={Context}
                placeholder="환자명, 휴대폰번호로 검색"
                style={searchBoxStyle}
              />
            </div>
          </PageHeader>
          <PageContent>
            <TableWrapper style={cardStyle}>
              <ODListablePaginatedTable2
                borderLess={true}
                tableStyle={{ marginBottom: 0, tableLayout: 'auto' }}
                numColumns={4}
                listableContext={Context}
                renderHeader={() => (
                  <tr style={{ background: '#F6F8FA', borderBottom: '1px solid #D1D5DA' }}>
                    <th style={{ padding: '12px 5px', width: 220, textAlign: 'center' }}>공유일시</th>
                    <th style={{ padding: '12px 5px', textAlign: 'center' }}>환자명</th>
                    <th style={{ padding: '12px 5px', minWidth: 60, textAlign: 'center' }}>성별</th>
                    <th style={{ padding: '12px 5px', minWidth: 130, textAlign: 'center' }}>생년월일</th>
                    <th style={{ padding: '12px 5px', minWidth: 130, textAlign: 'center' }}>휴대폰번호</th>
                    {isDoctor === false ? null : (
                      <th style={{ padding: '12px 5px', minWidth: 80, textAlign: 'center' }}>공유대상</th>
                    )}
                  </tr>
                )}
                renderRow={(value: GQLMydataShare, context) => (
                  <StyledTr
                    key={value.dataShareId}
                    onClick={() => {
                      const link = value.dataShareFile!.link.substring(
                        value.dataShareFile!.link.indexOf(`/MydataShareFile/`) + 17
                      )
                      isDoctor
                        ? history.push(SiteUrls.Doctor.MyData.Item(link, value.dataShareId))
                        : history.push(SiteUrls.Manager.MyData.Item(link, value.dataShareId))
                    }}
                  >
                    <td style={{ padding: '8px 5px', textAlign: 'center' }}>
                      <span>{moment(value.createdAt).format('lll')}</span>
                    </td>
                    <td style={{ padding: '8px 5px', textAlign: 'center' }}>
                      <span>{value.patient.name}</span>
                    </td>
                    <td style={{ padding: '8px 5px', textAlign: 'center' }}>
                      <span>{value.patient.gender === 'male' ? '남' : '여'}</span>
                    </td>
                    <td style={{ padding: '8px 5px', textAlign: 'center' }}>
                      <span>{moment(value.patient.fullBirthDate, 'YYYYMMDD').format('ll')}</span>
                    </td>
                    <td style={{ padding: '8px 5px', textAlign: 'center' }}>
                      <span>{value.patient.phone && phoneNumberSplitWithString(value.patient.phone, '-')}</span>
                    </td>
                    {isDoctor === false ? null : (
                      <td style={{ padding: '8px 5px', textAlign: 'center' }}>
                        {value.doctorId ? <IconShare src={IconShareDoc} /> : <IconShare src={IconShareHosp} />}
                      </td>
                    )}
                  </StyledTr>
                )}
                eventParentContext={{}}
              />
            </TableWrapper>
            <ODListablePagination hideIfSinglePage={false} listableContext={Context} wrapperStyle={wrapperStyle} />
          </PageContent>
        </Page>
      </PageContainer>
    </Provider>
  )
}

const IconShare = styled.img`
  width: 30px;
  height: 20px;
  vertical-align: top;
  margin: 0 auto;
`

const StyledTr = styled.tr`
  cursor: pointer;
  height: 44px;
  border-bottom: 1px solid #d1d5da;
  background-color: white;
`

const TableWrapper = styled.div`
  border: 1px solid #d1d5da;
  border-radius: 6px;
  > div {
    border-radius: 6px;
  }
`

const SearchWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  margin-bottom: 24px;
  > div > form > div {
    > div {
      border-radius: 8px;

      > div {
        border-radius: 8px;
        background-color: white;
        > span {
          border-radius: 8px;
        }
      }

      > input {
        border-radius: 0px 8px 8px 0px;
      }
    }

    @media (max-width: 992px) {
      width: 290px;
    }
  }
`
const MessageWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 5px;
`

const IconWarning = styled.img`
  width: 13px;
  height: 13px;
`

const MessageText = styled.div`
  font-size: 13px;
  font-weight: 500;
  font-style: normal;
  line-height: 1.85;
  letter-spacing: normal;
  color: ${COLORS.blue200};
`
