import { useODMutation, useODQuery } from '@mdpp/od-react-belt'
import { useCallback, useEffect, useState } from 'react'
import {
  GQLCancelReservationByDoctorInput,
  GQLDoctor,
  GQLDoctorLoginInput,
  GQLDoctorLoginResponse,
  GQLDoctorUpdateInput,
  GQLNotificationSendRequestInput,
  GQLOkResponse,
  GQLPatientMemo,
  GQLPatientMemoCreationInput,
  GQLPatientMemoDeleteInput,
  GQLPatientMemoSearchInput,
  GQLPatientMemoUpdateInput,
  GQLPrescriptionFile,
  GQLPrescriptionFileCreationInput,
  GQLReservation,
  GQLSingleIDInput,
  GQLUpdateReservationByDoctorInput,
  GQLUpsertDoctorHasMedicalSubjectInput,
  GQLDoctorInviteInput,
  GQLDoctorInviteOutput,
  GQLRecommendStatInfo,
  GQLDoctorStatInfo,
} from '@mdpp/common/lib/@types/server.schema'
import { AppOptions } from '../../AppOptions'
import {
  GQL_CANCEL_RESERVATION_BY_DOCTOR,
  GQL_CREATE_PRESCRIPTION_FILE,
  GQL_CREATE_PATIENT_MEMO,
  GQL_DELETE_PRESCRIPTION_FILE,
  GQL_DELETE_PATIENT_MEMO,
  GQL_ENTER_RESERVATION_ROOM_BY_DOCTOR,
  GQL_GET_DOCTOR_PROFILE,
  GQL_GET_RESERVATION,
  GQL_GET_PATIENT_MEMO,
  GQL_LOGIN_DOCTOR,
  GQL_LOGOUT,
  GQL_UPDATE_DOCTOR_PROFILE,
  GQL_UPDATE_RESERVATION_BY_DOCTOR,
  GQL_UPSERT_DOCTOR_HAS_MEDICAL_SUBJECT,
  GQL_UPDATE_PATIENT_MEMO,
  GQL_SEND_PATIENT_ENTER_REQUEST,
  GQL_DOCTOR_READY,
  GQL_START_TREATMENT,
  GQL_DOCTOR_RECEIPT,
  GQL_SET_PRESCRIPTION_STATE,
  GQL_SEND_REQUEST_NOTIFICATION_TO_PATIENT,
  GQL_GET_RECOMMENDER,
  GQL_DOCTOR_INVITE,
  GQL_DELETE_DOCTOR,
  GQL_CHECK_DOCTOR_IS_HOSPITAL_MANAGER,
  GQL_DOCTOR_STAT_INFO,
  DOCTOR_SNAPSHOT,
} from '../../common/gqls_common'

export function useDoctorAPIs() {
  const simulateDelay = AppOptions.SIMULATE_DELAY
  const getDoctorProfile = useODQuery<void, GQLDoctor>(GQL_GET_DOCTOR_PROFILE, simulateDelay)
  const loginDoctor = useODMutation<GQLDoctorLoginInput, GQLDoctorLoginResponse>(GQL_LOGIN_DOCTOR, simulateDelay)
  const logout = useODMutation<void, GQLOkResponse>(GQL_LOGOUT, simulateDelay)
  const getReservation = useODQuery<GQLSingleIDInput, GQLReservation>(GQL_GET_RESERVATION, simulateDelay)
  const updateReservation = useODMutation<Partial<GQLUpdateReservationByDoctorInput>, GQLReservation>(
    GQL_UPDATE_RESERVATION_BY_DOCTOR,
    simulateDelay
  )
  const updateDoctorProfile = useODMutation<Partial<GQLDoctorUpdateInput>, GQLDoctor>(
    GQL_UPDATE_DOCTOR_PROFILE,
    simulateDelay
  )
  const cancelReservation = useODMutation<GQLCancelReservationByDoctorInput, GQLReservation>(
    GQL_CANCEL_RESERVATION_BY_DOCTOR,
    simulateDelay
  )
  const addPrescriptionFile = useODMutation<GQLPrescriptionFileCreationInput, GQLPrescriptionFile>(
    GQL_CREATE_PRESCRIPTION_FILE,
    simulateDelay
  )
  const deletePrescriptionFile = useODMutation<GQLSingleIDInput, GQLOkResponse>(
    GQL_DELETE_PRESCRIPTION_FILE,
    simulateDelay
  )
  const enterReservationRoom = useODMutation<GQLSingleIDInput, GQLReservation>(
    GQL_ENTER_RESERVATION_ROOM_BY_DOCTOR,
    simulateDelay
  )

  const sendPatientEnterRequest = useODMutation<GQLSingleIDInput, GQLOkResponse>(
    GQL_SEND_PATIENT_ENTER_REQUEST,
    simulateDelay
  )

  const setDoctorReceipt = useODMutation<GQLSingleIDInput, GQLReservation>(GQL_DOCTOR_RECEIPT, simulateDelay)

  const setDoctorReady = useODMutation<GQLSingleIDInput, GQLReservation>(GQL_DOCTOR_READY, simulateDelay)

  const startTreatment = useODMutation<GQLSingleIDInput, GQLReservation>(GQL_START_TREATMENT)

  const setPrescriptionState = useODMutation<GQLSingleIDInput, GQLReservation>(GQL_SET_PRESCRIPTION_STATE)

  const upsertDoctorHasMedicalSubject = useODMutation<Partial<GQLUpsertDoctorHasMedicalSubjectInput>, GQLOkResponse>(
    GQL_UPSERT_DOCTOR_HAS_MEDICAL_SUBJECT,
    simulateDelay
  )

  const createPatientMemo = useODMutation<Partial<GQLPatientMemoCreationInput>, GQLPatientMemo>(
    GQL_CREATE_PATIENT_MEMO,
    simulateDelay
  )

  const getPatientMemo = useODMutation<Partial<GQLPatientMemoSearchInput>, GQLPatientMemo>(
    GQL_GET_PATIENT_MEMO,
    simulateDelay
  )

  const updatePatientMemo = useODMutation<Partial<GQLPatientMemoUpdateInput>, GQLPatientMemo>(
    GQL_UPDATE_PATIENT_MEMO,
    simulateDelay
  )

  const deletePatientMemo = useODMutation<Partial<GQLPatientMemoDeleteInput>, GQLPatientMemo>(
    GQL_DELETE_PATIENT_MEMO,
    simulateDelay
  )
  const sendRequestNotificationToPatient = useODMutation<Partial<GQLNotificationSendRequestInput>, GQLOkResponse>(
    GQL_SEND_REQUEST_NOTIFICATION_TO_PATIENT,
    simulateDelay
  )

  const doctorInvite = useODQuery<GQLDoctorInviteInput, GQLDoctorInviteOutput>(GQL_DOCTOR_INVITE)

  const deleteDoctor = useODMutation<GQLSingleIDInput, GQLOkResponse>(GQL_DELETE_DOCTOR)

  const checkDoctorIsHospitalManager = useODMutation<GQLSingleIDInput, GQLOkResponse>(
    GQL_CHECK_DOCTOR_IS_HOSPITAL_MANAGER
  )

  const getDoctorStatInfo = useODMutation<GQLSingleIDInput, GQLDoctorStatInfo>(GQL_DOCTOR_STAT_INFO)

  const getDoctorMyProfile = useODQuery<void, GQLDoctor>(GQL_GET_DOCTOR_PROFILE)

  return {
    getDoctorProfile,
    loginDoctor,
    logout,
    getReservation,
    cancelReservation,
    updateReservation,
    addPrescriptionFile,
    deletePrescriptionFile,
    enterReservationRoom,
    upsertDoctorHasMedicalSubject,
    updateDoctorProfile,
    createPatientMemo,
    getPatientMemo,
    updatePatientMemo,
    deletePatientMemo,
    sendPatientEnterRequest,
    setDoctorReady,
    startTreatment,
    setDoctorReceipt,
    setPrescriptionState,
    sendRequestNotificationToPatient,
    doctorInvite,
    deleteDoctor,
    checkDoctorIsHospitalManager,
    getDoctorStatInfo,
    getDoctorMyProfile,
  }
}

export interface IUseReservation {
  reservation: GQLReservation | undefined
  refresh: () => void
  setReservation: (
    value: ((prevState: GQLReservation | undefined) => GQLReservation | undefined) | GQLReservation | undefined
  ) => void
  error: Error | undefined
}

export function useReservation(reservationId: number | string): IUseReservation {
  const { getReservation } = useDoctorAPIs()
  const [reservation, setReservation] = useState<GQLReservation | undefined>()
  const [error, setError] = useState<Error | undefined>()

  const refresh = useCallback(() => {
    // setReservation(undefined)
    // setError(undefined)
    getReservation({ id: parseInt(reservationId.toString(), 10) }).then(setReservation, () => null)
  }, [getReservation, setReservation, reservationId])

  useEffect(() => {
    refresh()
  }, [refresh])

  return { reservation, setReservation, error, refresh }
}
