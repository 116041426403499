import React from 'react'
import { LinkStyle } from '../../common/schedule/ScheduleStatusPopOver'
import { ScheduleEditingPage } from '../../common/schedule/table/ScheduleEditingPage'
import { SiteUrls } from '../../SiteUrls'

interface IManagerDoctorScheduleTableProps {
  doctorId: number
  inTab?: boolean
}

export const ManagerDoctorScheduleTablePage: React.FC<IManagerDoctorScheduleTableProps> = props => {
  const { doctorId, inTab = false } = props

  return (
    <ScheduleEditingPage
      inTab={inTab}
      doctorId={doctorId}
      linkToDefaultSetup={SiteUrls.Manager.Doctor.SetupScheduleDefault(doctorId)}
      compactMargin
      isFromDoctorSelf={false}
      linkStyle={LinkStyle.Manager}
    />
  )
}
