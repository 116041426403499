import styled from "styled-components/macro";
import {COLORS} from '../../styles/colors'

export const OverlayBg = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0.6;
  background-color: ${COLORS.BLACK};
`

export const Wrapper = styled.div`
  position: relative;
  border-radius: 8px;
  background-color: ${COLORS.WHITE};
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &.flex-start {
    justify-content: flex-start;
  }
`

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  padding: 20px 20px 0 20px;
  align-items: center;
  justify-content: flex-end;
`

export const Container = styled.div`
  flex: 1;
  justify-content: space-between;
  flex-direction: column;
  display: flex;

  &.pd40 {
    padding: 0 40px 40px 40px;
  }

  &.start {
    justify-content: flex-start;
  }
`
export const Content = styled.div`
  justify-content: space-between;

  &.no-title {
    margin: auto 0;
  }
`

// confirm modal title
export const ConfirmTitleWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content : center;
  padding: 9px 0 10px 0;

  &.icon-large {
    > img {
      margin-top: 0;
    }
  }

  > img {
    width: 19px;
    height: 19px;
    margin-right: 5px;
    margin-top: 3px;
  }

  &.line {
    padding-bottom: 10px;
    border-bottom: 1px solid ${COLORS.GRAYE0};
  }

  &.icon-large {
    flex-direction: column;
    padding: 0 0 10px 0;

    > img {
      width: 48px;
      height: 48px;
      margin-right: 0;
      margin-bottom: 9px;
    }
  }
`

export const ConfirmTitle = styled.div`
  font-size: 18px;
  color: ${COLORS.GRAY42};
  font-weight: bold;

  > .normal {
    font-weight: 500;
  }

  &.center {
    text-align: center;
  }
`

export const TitleWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content : space-between;

  &.line {
    padding-bottom: 10px;
    border-bottom: 1px solid ${COLORS.GRAYE0};
  }

  &.column {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 2px;
  }
`

export const Title = styled.div`
  font-size: 20px;
  color: ${COLORS.GRAY42};
  font-weight: bold;

  &.title-sm {
    font-size: 15px;
    line-height: 28px;
    padding-bottom: 15px;
  }
`
// title 우측 설명
export const TitleDescription = styled.div`
  position: relative;
  font-size: 13px;
  color: ${COLORS.GRAYAB};
  font-weight: 500;
  padding-left: 7px;

  &:before {
    content: '';
    width: 3px;
    height: 3px;
    position: absolute;
    top: 9px;
    left: 0;
    background-color: ${COLORS.red100};
  }
`

export const RequiredWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`

export const Oval = styled.span`
  display: inline-block;
  width: 4px;
  height: 4px;
  border-radius: 4px;
  margin-top: 4px;
  background-color: ${COLORS.red100};
`

export const RequiredText = styled.span`
  font-size: 13px;
  font-weight: 500;
  color: ${COLORS.GRAYAB};
  padding-left: 4px;
  line-height: normal;
`

export const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0;
  text-align: center;

  > img {
    width: 19px;
    height: 19px;
    margin-right: 4px;
  }

  &.top {
    align-items: flex-start;
    margin-top: 8px;
  }
`
export const SimpleDescription = styled.div`
  font-size: 15px;
  color: ${COLORS.GRAY42};
  font-weight: 500;

  &.left {
    text-align: left;
  }
`
export const Box = styled.div`
  height: 103px;
  border-radius: 4px;
  border: solid 1px #dfe0e2;
  background-color: #eeeff1;

  > div, > textarea {
    padding: 15px 20px 20px;
    font-size: 13px;
    color: ${COLORS.GRAY42};
    line-height: 1.69;
    font-weight: 500;
  }

  > textarea {
    width: 100%;
    height: 100%;
    resize: none;
    background-color: transparent;
  }
`


export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 20px;
  padding: 0 40px 40px 40px;

  &.pd0 {
    padding: 0;
  }

  &.pdt40 {
    padding-top: 40px;
  }
`

// Form 영역
export const FormWrap = styled.div `
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;  
  gap: 13px;
`
export const FormBlock  = styled.div `
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`
export const LabelWrap  = styled.div `
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-bottom: 8px;
`

export const Label  = styled.label `
  font-size: 13px;
  font-weight: 500;
  color: ${COLORS.GRAY42};
  margin-bottom: 0;
  padding-right: 3px;
`


export const BtnWrap = styled.div`
  width: 100%;
  border-top: 1px solid ${COLORS.GRAYE0};
  padding-top: 30px;
  text-align: center;
`