import React from 'react'
import { HospitalListableTable } from '../../common/hospital/list/HospitalListableTable'
import {Page, PageContainer, PageHeader, PageTitle} from "../../common/page/Page";

interface IManagerHospitalPageProps {}

export const ManagerHospitalListPage: React.FC<IManagerHospitalPageProps> = props => {
  return (
    <PageContainer>
      <Page>
        <PageHeader>
            <PageTitle>병원관리</PageTitle>
        </PageHeader>
        
        <HospitalListableTable fromEvixAdmin={false}  />
  
      </Page>
    </PageContainer>
    
  )
}

