import React from 'react'
import { HospitalEditPage } from '../../common/hospital/edit/HospitalEditPage'
import { MainContentWithInset } from '../../common/layout/MainContentWithInset'
import { useManagerAppContext } from '../context/ManagerAppContext'
import {Page, PageContainer, PageContent, PageHeader, PageTitle} from "../../common/page/Page";

interface IManagerHospitalFormPageProps {}

export const ManagerHospitalEditPage: React.FC<IManagerHospitalFormPageProps> = props => {
  const {
    state: { profile },
  } = useManagerAppContext()

  if (!profile) {
    return null
  }

  return (
    <HospitalEditPage idEditing={profile!.hospital.hospitalId} fromHospitalManager />
    // <PageContainer>
    //   <Page>
    //     <PageHeader>
    //       <PageTitle>병원관리</PageTitle>
    //     </PageHeader>
    //     <PageContent>

    //     </PageContent>
    //   </Page>
    // </PageContainer>
  )
}
