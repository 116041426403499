import { ODRoute } from '@mdpp/od-react-belt'
import React from 'react'
import { SiteUrls } from '../SiteUrls'
import { ReservationPharmacistDetailPage } from './reservation/detail/ReservationPharmacistDetailPage'
import { PharmacistMyPage } from './mypage/PharmacistMyPage'
import { PharmactistListPage } from './reservation/list/PharmactistListPage'

export const PharmaRoutes: ODRoute[] = [
  {
    path: SiteUrls.Pharmacy.Reservation.Detail(':id'),
    exact: true,
    name: `조제상세`,
    component: ReservationPharmacistDetailPage,
  },
  {
    path: SiteUrls.Pharmacy.Reservation.Main,
    exact: true,
    name: `조제현황`,
    component: PharmactistListPage,
  },
  { path: SiteUrls.Pharmacy.MyPage.Main, exact: true, name: `마이페이지`, component: PharmacistMyPage },
]
