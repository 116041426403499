import { IDoctorScheduleRule, ScheduleUtil } from '@mdpp/common'
import {
  BlockingLoadBox,
  ODHSpace,
  ODIcons,
  ODVSpace,
  SingleQueryWrapper,
  useODModalConfirm,
} from '@mdpp/od-react-belt'
import { padStart } from 'lodash'
import React, { useCallback } from 'react'
import { Card, CardBody as reactstrapCardBody } from 'reactstrap'
import styled from 'styled-components/macro'
import { COLORS } from '../../../styles/colors'
import { WebUtils } from '../../../utils/webUtils'
import { EvixButton, EvixButtonTheme } from '../../EvixButton'
import { useCommonAPIs } from '../../hooks/useCommonAPIs'
import { ReservationTimeSelector } from './ReservationTimeSelector'
import { WeekScheduler } from './WeekScheduler'
import { ScheduleType } from '@remotelock/react-week-scheduler/src/types'
import moment, { now } from 'moment'
import { useHistory } from 'react-router-dom'
import { HolidayWorkingSelector } from './HolidayWorkingSelector'
import { CommonScheduleSelector } from './CommonScheduleSelector'
import { Button, BP } from '../../RebeccaButton'
import { PopupModal } from '../../../common/layout/PopupModal'
import { EvixCard } from '../../card/EvixCard'
import { EvixCardHeader } from '../../card/EvixCardHeader'
import { Page, PageContainer, PageContent, PageHeader, PageTitle } from '../../page/Page'

interface IScheduleSetupPageProps {
  idEditing: number
}

const formatToken = (s: string) => `${s.substr(0, 2)}:${s.substr(2, 2)}`
const deFormatToken = (s: string) =>
  s
    .split(':')
    .map(v => padStart(v, 2, '0'))
    .join('')

// mon-0 tue-1 wed-2 thu-3 fri-4 sat-5 sun-6
const getDayOfDate = (d: Date, dayOfIndex: number) => {
  const offset = dayOfIndex === 6 ? -1 : dayOfIndex
  const day = d.getDay()
  let diff = d.getDate() - day + (day === 0 ? -6 : 1) // adjust when day is sunday
  diff += offset
  return new Date(d.setDate(diff))
}

const convertDateToV1RuleFormat = (schedule: ScheduleType) => {
  const scheduleWithV1Format = new ScheduleUtil()

  schedule.forEach(slot => {
    let day = slot[0].getDay()
    const dayOfIndex = day === 0 ? 6 : day - 1 //adjust
    const startSlot = deFormatToken(`${slot[0].getHours()}`) + deFormatToken(`${slot[0].getMinutes()}`)
    let endSlot = deFormatToken(`${slot[1].getHours()}`) + deFormatToken(`${slot[1].getMinutes()}`)
    // for issue - https://evidnet.atlassian.net/browse/MDPP-304
    if (endSlot === '2359') endSlot = '2400'

    scheduleWithV1Format.rule.week[dayOfIndex].slots.push({ startTime: startSlot, endTime: endSlot })
  })
  return scheduleWithV1Format.rule
}

const convertV1RuleFormatToDate = (rule: IDoctorScheduleRule) => {
  const { week } = rule
  const rangeDate: ScheduleType = []

  ;[0, 1, 2, 3, 4, 5, 6].forEach(v => {
    if (week[v] && week[v].slots) {
      week[v].slots.forEach(slot => {
        // for issue - https://evidnet.atlassian.net/browse/MDPP-304
        if (slot.endTime === '2400') slot.endTime = '2359'
        const now = new Date()
        const date = moment(getDayOfDate(now, v)).format('YYYY-MM-DD')
        const startTime = date + ' ' + slot.startTime.substr(0, 2) + ':' + slot.startTime.substr(2, 4)
        const endTime = date + ' ' + slot.endTime.substr(0, 2) + ':' + slot.endTime.substr(2, 4)
        rangeDate.push([new Date(startTime), new Date(endTime)])
      })
    }
  })
  return rangeDate
}

export const ScheduleSetupPage: React.FC<IScheduleSetupPageProps> = props => {
  const { idEditing: doctorId } = props
  const { getDoctor, updateDoctor, updateDoctorSchedule, deleteDoctorSchedule } = useCommonAPIs()
  const history = useHistory()
  const { Component: ConfirmModal, confirm, props: confirmProps } = useODModalConfirm({})

  const [loading, setLoading] = React.useState(false)
  const [refreshToken, setRefreshToken] = React.useState(0)
  const [, setRule] = React.useState<IDoctorScheduleRule | null>(null)
  const [reservationMonth, setReservationMonth] = React.useState(0)
  const [schedule, setSchedule] = React.useState<ScheduleType>([])
  const [startDate, setStartDate] = React.useState<string | null>(null)
  const [endDate, setEndDate] = React.useState<string | null>(null)
  const [isWorkingHoliday, setIsWorkingHoliday] = React.useState(0)
  const [holidayStartTime, setHolidayStartTime] = React.useState<string | undefined>(undefined)
  const [holidayEndTime, setHolidayEndTime] = React.useState<string | undefined>(undefined)
  const [holidayLunchStartTime, setHolidayLunchStartTime] = React.useState<string | undefined>(undefined)
  const [holidayLunchEndTime, setHolidayLunchEndTime] = React.useState<string | undefined>(undefined)
  const [holidayDinnerStartTime, setHolidayDinnerStartTime] = React.useState<string | undefined>(undefined)
  const [holidayDinnerEndTime, setHolidayDinnerEndTime] = React.useState<string | undefined>(undefined)
  const [reservationAfterTime, setReservationAfterTime] = React.useState(0)
  const [isShowOverlay, setIsShowOverlay] = React.useState(true)
  const [dateRange, setDateRange] = React.useState<string[]>(['', ''])
  const [isEdit, setIsEdit] = React.useState(false)

  const [initMonth, setInitMonth] = React.useState(0)
  const [initAfterTime, setInitAfterTime] = React.useState(0)
  const [initWorkingHoliday, setInitWorkingHoliday] = React.useState(0)
  const [initHolidayStartTime, setInitHolidayStartTime] = React.useState<string | undefined>(undefined)
  const [initHolidayEndTime, setInitHolidayEndTime] = React.useState<string | undefined>(undefined)
  const [initHolidayLunchStartTime, setInitHolidayLunchStartTime] = React.useState<string | undefined>(undefined)
  const [initHolidayLunchEndTime, setInitHolidayLunchEndTime] = React.useState<string | undefined>(undefined)
  const [initHolidayDinnerStartTime, setInitHolidayDinnerStartTime] = React.useState<string | undefined>(undefined)
  const [initHolidayDinnerEndTime, setInitHolidayDinnerEndTime] = React.useState<string | undefined>(undefined)
  const [initSchedule, setInitSchedule] = React.useState<ScheduleType>([])

  const [showCancelModifyPopup, setShowCancelModifyPopup] = React.useState(false)
  const [showDeleteSchedulePopup, setShowDeleteSchedulePopup] = React.useState(false)

  React.useEffect(() => {
    if (reservationMonth !== initMonth) {
      setIsEdit(true)
      return
    }
    if (reservationAfterTime !== initAfterTime) {
      setIsEdit(true)
      return
    }
    if (isWorkingHoliday !== initWorkingHoliday) {
      setIsEdit(true)
      return
    } else if (isWorkingHoliday) {
      if (holidayStartTime !== initHolidayStartTime) {
        setIsEdit(true)
        return
      }
      if (holidayEndTime !== initHolidayEndTime) {
        setIsEdit(true)
        return
      }
      if (holidayLunchStartTime !== initHolidayLunchStartTime) {
        setIsEdit(true)
        return
      }
      if (holidayLunchEndTime !== initHolidayLunchEndTime) {
        setIsEdit(true)
        return
      }
      if (holidayDinnerStartTime !== initHolidayDinnerStartTime) {
        setIsEdit(true)
        return
      }
      if (holidayDinnerEndTime !== initHolidayDinnerEndTime) {
        setIsEdit(true)
        return
      }
    }

    if (schedule != initSchedule) {
      setIsEdit(true)
      return
    }
    setIsEdit(false)
  }, [
    reservationMonth,
    reservationAfterTime,
    isWorkingHoliday,
    holidayStartTime,
    holidayEndTime,
    holidayLunchStartTime,
    holidayLunchEndTime,
    holidayDinnerStartTime,
    holidayDinnerEndTime,
    schedule,
  ])

  const TODAY_RESERVATION_OPTIONS = [
    { label: `당일예약 불가`, value: 0 },
    { label: `당일예약 가능 : 진료 30분 전`, value: 30 },
    { label: `당일예약 가능 : 진료 1시간 전`, value: 60 },
    { label: `당일예약 가능 : 진료 2시간 전`, value: 120 },
  ]

  const setHolidayDataWithRule = useCallback((rule: IDoctorScheduleRule) => {
    if (!rule.holiday) {
      setInitWorkingHoliday(0)
      setIsWorkingHoliday(0)
      return
    }

    const { holiday } = rule
    if (!holiday.slots.length) {
      setInitWorkingHoliday(0)
      setIsWorkingHoliday(0)
      return
    }
    setInitHolidayStartTime(formatToken(holiday.slots[0].startTime))
    setHolidayStartTime(formatToken(holiday.slots[0].startTime))

    let holidayEndTime = holiday.slots[0].endTime
    //for issue - https://evidnet.atlassian.net/browse/MDPP-354
    if (holidayEndTime === '2400') holidayEndTime = '2359'

    switch (holiday.slots.length) {
      case 1: {
        setInitHolidayEndTime(formatToken(holidayEndTime))
        setHolidayEndTime(formatToken(holidayEndTime))
        break
      }
      case 2: {
        setInitHolidayLunchStartTime(formatToken(holiday.slots[0].endTime))
        setInitHolidayLunchEndTime(formatToken(holiday.slots[1].startTime))
        setInitHolidayEndTime(formatToken(holiday.slots[1].endTime))

        setHolidayLunchStartTime(formatToken(holiday.slots[0].endTime))
        setHolidayLunchEndTime(formatToken(holiday.slots[1].startTime))
        setHolidayEndTime(formatToken(holiday.slots[1].endTime))
        break
      }
      case 3: {
        setInitHolidayLunchStartTime(formatToken(holiday.slots[0].endTime))
        setInitHolidayLunchEndTime(formatToken(holiday.slots[1].startTime))
        setInitHolidayDinnerStartTime(formatToken(holiday.slots[1].endTime))
        setInitHolidayDinnerEndTime(formatToken(holiday.slots[2].startTime))
        setInitHolidayEndTime(formatToken(holiday.slots[2].endTime))

        setHolidayLunchStartTime(formatToken(holiday.slots[0].endTime))
        setHolidayLunchEndTime(formatToken(holiday.slots[1].startTime))
        setHolidayDinnerStartTime(formatToken(holiday.slots[1].endTime))
        setHolidayDinnerEndTime(formatToken(holiday.slots[2].startTime))
        setHolidayEndTime(formatToken(holiday.slots[2].endTime))
        break
      }
    }
    setInitWorkingHoliday(1)
    setIsWorkingHoliday(1)
  }, [])

  const handleDelete = async () => {
    try {
      await deleteDoctorSchedule({ id: doctorId })
      setRefreshToken(t => t + 1)
      WebUtils.showSuccess('스케줄을 설정을 삭제하였습니다.')
    } catch (ex) {
      WebUtils.showError(ex)
    } finally {
      setShowDeleteSchedulePopup(false)
    }
  }

  const handleCancel = () => {
    setSchedule(initSchedule)
    setReservationMonth(initMonth)
    setReservationAfterTime(initAfterTime)
    setIsWorkingHoliday(initWorkingHoliday)
    setHolidayStartTime(initHolidayStartTime)
    setHolidayEndTime(initHolidayEndTime)
    setHolidayLunchStartTime(initHolidayLunchStartTime)
    setHolidayLunchEndTime(initHolidayLunchEndTime)
    setHolidayDinnerStartTime(initHolidayDinnerStartTime)
    setHolidayDinnerEndTime(initHolidayDinnerEndTime)
    setShowCancelModifyPopup(false)
  }

  const handleSetReservationMonth = (value: any) => {
    if (value === -1) {
      if (startDate && endDate) {
        setShowDeleteSchedulePopup(true)
        return
      }
    }
    setReservationMonth(value)
    setDateRange(['', ''])
    setIsShowOverlay(false)
  }

  const handleSetDateRange = useCallback((changedValue: string[]) => {
    setReservationMonth(0)
    setDateRange(changedValue)
  }, [])

  const handleSelected = (week: ScheduleType) => {
    setSchedule(week)
  }

  const handleSave = async () => {
    const v1schedule: IDoctorScheduleRule = convertDateToV1RuleFormat(schedule)
    const s = new ScheduleUtil(v1schedule)
    let endTime = holidayEndTime
    let json = ''
    try {
      if (isWorkingHoliday) {
        if (holidayStartTime && endTime) {
          // for issue - https://evidnet.atlassian.net/browse/MDPP-354
          if (endTime === '23:59') endTime = '24:00'

          s.setHolidaySetting(
            deFormatToken(holidayStartTime),
            deFormatToken(endTime),
            holidayLunchStartTime ? deFormatToken(holidayLunchStartTime) : undefined,
            holidayLunchEndTime ? deFormatToken(holidayLunchEndTime) : undefined,
            holidayDinnerStartTime ? deFormatToken(holidayDinnerStartTime) : undefined,
            holidayDinnerEndTime ? deFormatToken(holidayDinnerEndTime) : undefined
          )
        }
      } else {
        s.resetHoliday()
      }
      json = s.toJSON()
    } catch (ex) {
      WebUtils.showError(ex.message)
      return
    }

    setLoading(true)
    try {
      const currentDate = moment()
      const countAvailableDays = moment(currentDate).add(reservationMonth, 'month').diff(moment(currentDate), 'days')
      await updateDoctorSchedule({
        doctorId: doctorId,
        schedule: json,
        scheduleAvailableDays: countAvailableDays,
        scheduleAvailableStartDay: dateRange[0].length ? dateRange[0] : null,
        scheduleAvailableEndDay: dateRange[1].length ? dateRange[1] : null,
        reservationAfter: reservationAfterTime,
      })
      setRefreshToken(t => t + 1)
      WebUtils.showSuccess('스케줄을 업데이트하였습니다.')
    } catch (ex) {
      WebUtils.showError('업데이트를 실패하였습니다. 재시도하세요')
      console.error(ex)
    }

    setInitSchedule(schedule)
    setInitMonth(reservationMonth)
    setInitAfterTime(reservationAfterTime)
    setInitWorkingHoliday(isWorkingHoliday)
    setInitHolidayStartTime(holidayStartTime)
    setInitHolidayEndTime(holidayEndTime)
    setInitHolidayLunchStartTime(holidayLunchStartTime)
    setInitHolidayLunchEndTime(holidayLunchEndTime)
    setInitHolidayDinnerStartTime(holidayDinnerStartTime)
    setInitHolidayDinnerEndTime(holidayDinnerEndTime)
    setIsEdit(false)

    setLoading(false)
  }

  return (
    <>
      <BlockingLoadBox show={loading} />
      <SingleQueryWrapper
        deps={[doctorId, getDoctor, updateDoctor, refreshToken, deleteDoctorSchedule]}
        getter={async () => {
          const r = await getDoctor({ id: doctorId })
          const s = new ScheduleUtil(JSON.parse(r.schedule))
          setRule(s.rule)
          const convertSchedule = convertV1RuleFormatToDate(s.rule)
          setInitSchedule(convertSchedule)
          setSchedule(convertSchedule)
          if (convertSchedule.length) setIsShowOverlay(false)
          // UI는 일요일이 먼저
          if (r.scheduleAvailableStartDay && r.scheduleAvailableEndDay) {
            setStartDate(r.scheduleAvailableStartDay)
            setEndDate(r.scheduleAvailableEndDay)
            setDateRange([r.scheduleAvailableStartDay, r.scheduleAvailableEndDay])
            setInitMonth(0)
            setReservationMonth(0)
          } else if (r.lastScheduleToken) {
            let month
            setStartDate(moment().format('YYYY-MM-DD'))
            setEndDate(moment(r.lastScheduleToken).format('YYYY-MM-DD'))
            month = Math.max(Math.min(Math.floor(r.scheduleAvailableDays / 30), 12), 1)
            setInitMonth(month)
            setReservationMonth(month)
          } else {
            setStartDate(null)
            setEndDate(null)
            setInitMonth(0)
            setReservationMonth(0)
            setIsShowOverlay(true)
          }
          setInitAfterTime(r.reservationAfter ?? 0)
          setReservationAfterTime(r.reservationAfter ?? 0)
          setHolidayDataWithRule(s.rule)
          return r
        }}
      >
        {({ data: doctor }) => {
          return (
            <>
              <ConfirmModal {...confirmProps} />
              {showDeleteSchedulePopup && (
                <PopupModal
                  title={'예약받지 않음'}
                  description={'예약을 받지 않으시겠습니까? 향후 진료가능일정이 모두 사라집니다.'}
                  okTitle={'더 이상 예약 받지 않음'}
                  warning={
                    <div>
                      이미 예약된 진료는 취소되지 않습니다.
                      <br /> 필요 시 <strong>진료 목록 &gt; 진료상세</strong> 에서 개별적으로 취소하세요.
                    </div>
                  }
                  onOkPress={handleDelete}
                  onCancelPress={() => {
                    setShowDeleteSchedulePopup(false)
                  }}
                />
              )}
              {showCancelModifyPopup && (
                <PopupModal
                  title={'변경 내용 취소'}
                  description={'변경 이전으로 돌아가시겠습니까? 저장하지 않은 내용은 삭제됩니다.'}
                  okTitle={'변경취소'}
                  onOkPress={handleCancel}
                  onCancelPress={() => {
                    setShowCancelModifyPopup(false)
                  }}
                />
              )}
              <PageContainer>
                <Page>
                  <PageHeader>
                    <PageTitle>기본 스케줄 설정</PageTitle>
                    <ButtonArea>
                      <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
                        <Button
                          size={BP.SIZE.L}
                          type={BP.TYPE.Primary}
                          onClick={handleSave}
                          disabled={isShowOverlay || !isEdit}
                        >
                          저장
                        </Button>
                        <ODHSpace w={16} />
                        <Button
                          disabled={!isEdit}
                          size={BP.SIZE.L}
                          onClick={() => {
                            setShowCancelModifyPopup(true)
                          }}
                        >
                          변경 취소
                        </Button>
                      </div>
                    </ButtonArea>
                  </PageHeader>
                  <PageContent>
                    <EvixCard style={cardStyle}>
                      <EvixCardHeader title="예약 조건 설정" />
                      <ODVSpace h={24} />
                      <div>
                        <ConfirmModal {...confirmProps} />
                        <SelectBarWrapper>
                          <ScheduleItem>
                            <TitleWrapper>
                              <WeeklyScheduleSetting>예약가능 기간</WeeklyScheduleSetting>
                              <SubText>환자가 예약 할 수 있는 기간</SubText>
                            </TitleWrapper>
                            <ReservationTimeSelector
                              value={isShowOverlay ? -1 : reservationMonth}
                              dateRange={dateRange}
                              setValue={handleSetReservationMonth}
                              setDateRange={handleSetDateRange}
                              placeholder={`예약 기간 추가`}
                            />
                          </ScheduleItem>
                          <ScheduleItem>
                            <WeeklyScheduleSetting>당일예약 설정</WeeklyScheduleSetting>
                            <CommonScheduleSelector
                              value={reservationAfterTime}
                              setValue={setReservationAfterTime}
                              options={TODAY_RESERVATION_OPTIONS}
                            />
                          </ScheduleItem>
                          <ScheduleItem>
                            <WeeklyScheduleSetting>공휴일 시간 설정</WeeklyScheduleSetting>
                            <HolidayWorkingSelector value={isWorkingHoliday} setValue={setIsWorkingHoliday} />
                          </ScheduleItem>
                        </SelectBarWrapper>
                      </div>
                    </EvixCard>
                    {isWorkingHoliday ? (
                      <Card>
                        <ScheduleSettingText>공휴일 진료 직접입력</ScheduleSettingText>
                        <HolidaySettingBody>
                          <InputMaxWrapper>
                            <WeeklyScheduleSetting>시간설정</WeeklyScheduleSetting>
                            <HolidayInputWrapper>
                              <HolidayInputStart
                                type="time"
                                value={holidayStartTime}
                                onChange={(e: any) => setHolidayStartTime(e.target.value)}
                              />
                              <HolidayInputEnd
                                type="time"
                                value={holidayEndTime}
                                onChange={(e: any) => setHolidayEndTime(e.target.value)}
                              />
                            </HolidayInputWrapper>
                          </InputMaxWrapper>

                          <InputMaxWrapper>
                            <WeeklyScheduleSetting>점심시간</WeeklyScheduleSetting>
                            <HolidayInputWrapper>
                              <HolidayInputStart
                                type="time"
                                value={holidayLunchStartTime}
                                onChange={(e: any) => setHolidayLunchStartTime(e.target.value)}
                              />
                              <HolidayInputEnd
                                type="time"
                                value={holidayLunchEndTime}
                                onChange={(e: any) => setHolidayLunchEndTime(e.target.value)}
                              />
                            </HolidayInputWrapper>
                          </InputMaxWrapper>

                          <InputMaxWrapper>
                            <WeeklyScheduleSetting>저녁시간</WeeklyScheduleSetting>
                            <HolidayInputWrapper>
                              <HolidayInputStart
                                type="time"
                                value={holidayDinnerStartTime}
                                onChange={(e: any) => setHolidayDinnerStartTime(e.target.value)}
                              />
                              <HolidayInputEnd
                                type="time"
                                value={holidayDinnerEndTime}
                                onChange={(e: any) => setHolidayDinnerEndTime(e.target.value)}
                              />
                            </HolidayInputWrapper>
                          </InputMaxWrapper>
                        </HolidaySettingBody>
                      </Card>
                    ) : null}

                    {reservationMonth > 0 ? (
                      <EvixCard style={cardStyle}>
                        <CardBody>
                          <EvixCardHeader title="요일별 근무시간 설정" />
                          <ODVSpace h={24} />
                          <SchedulerBody>
                            <WeekSchedulerBody>
                              <WeekScheduler
                                schedule={schedule}
                                setSchedule={handleSelected}
                                isShowOverlay={isShowOverlay}
                              />
                            </WeekSchedulerBody>
                          </SchedulerBody>
                        </CardBody>
                      </EvixCard>
                    ) : null}
                  </PageContent>
                </Page>
              </PageContainer>
            </>
          )
        }}
      </SingleQueryWrapper>
    </>
  )
}

const cardStyle = {
  flexGrow: 2,
  display: 'flex',
  minWidth: 700,
  background: '#FFFFFF',
  boxShadow: '0px 0px 5px rgba(23, 24, 24, 0.05), 0px 1px 2px rgba(0, 0, 0, 0.15)',
  borderRadius: 8,
  border: 0,
}

const SchedulerBody = styled.div`
  padding: 0px 0px 20px 0px;
`

const WeekSchedulerBody = styled.div`
  max-width: 1200px;
  min-width: 740px;
  height: 540px;
`

const HolidaySettingBody = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0px 10px 0px 10px;
`

const CardBody = styled(reactstrapCardBody)`
  padding: 0;

  & input::placeholder {
    color: ${COLORS.GRAY400};
  }
`

const InputMaxWrapper = styled.div`
  max-width: 570px;
  display: flex;
  flex-direction: column;
  padding: 0px 0px 20px 0px;
`

const ScheduleSettingText = styled.p`
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  margin: 20px 0px 24px 20px;
`

const WeeklyScheduleSetting = styled.p`
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #202223;
  margin: 0 0 8px 2px;
`

const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-right: 27px;
  width: 100%;
`

const SubText = styled.span`
  color: #2c6ecb;
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
`

const ScheduleItem = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const SelectBarWrapper = styled.div`
  display: flex;
  width: 100%;
  gap: 24px;
`

const HolidayInputStart = styled.input`
  width: 170px;
  height: 46px;
  background: #ffffff;
  border: 1px solid #c9ccce;
  box-sizing: border-box;
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  padding-left: 5px;
  padding-right: 3px;
  margin-left: 7px;

  &:before {
    content: '시작:';
    margin-right: 0.6em;
    color: #9d9d9d;
  }
`

const HolidayInputEnd = styled(HolidayInputStart)`
  margin-left: 2px;
  margin-right: 7px;
  &:before {
    content: '종료:';
    margin-right: 0.6em;
    color: #9d9d9d;
  }
`

const HolidayInputWrapper = styled.div`
  display: flex;
`

const ButtonArea = styled.div``
