import { ODHSpace, ODVLine, ODVSpace } from '@mdpp/od-react-belt'
import React from 'react'
import { Route, BrowserRouter as Router } from 'react-router-dom'

import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'

import MediPangPangFooterLogo from '../assets/mediPangPang_textType_v1.0_72_32.svg'
import { SiteUrls } from '../SiteUrls'
import { COLORS } from '../styles/colors'
import { TermsPage } from '../login/TermsPage'
import snsBlog from '../assets/footer/icon_b.png'
import snsFacebook from '../assets/footer/icon_f.png'
import snsYoutube from '../assets/footer/icon_y.png'

import '../customStyles.scss'

const FooterComponent = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  right: 60px;
  left: 60px;
  bottom: 28px;
  font-size: 14px;
  color: ${COLORS.GRAY500};
  @media only screen and (max-width: 768px) {
    flex-direction: column;
    right: 5%;
    left: 5%;
  }
`
const LogoBox = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`
const LogoLine = styled.div`
  width: 100%;
  height: 1px;
  background-color: #000;
`

const FooterMain = styled.div`
  display: flex;
  flex-direction: row;
  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }
`

const LeftBox = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  @media only screen and (max-width: 768px) {
    flex-direction: column;
    gap: 30px;
  }
`

const CompanyInfoBox = styled.div`
  display: flex;
  flex-direction: column;
`

const CompanyInfo = styled.small`
  font-size: 14px;
  color: #131415;
  padding-bottom: 8px;
  font-weight: 500;
  /* color: var(--gray600); */

  ${(p: { gray?: boolean }) =>
    p.gray &&
    css`
      font-size: 14px;
      line-height: 18px;
      color: #4f5356;
      padding-bottom: 0;
      font-weight: 400;
    `}
`

const RightBox = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: auto;
  @media only screen and (max-width: 768px) {
    justify-content: flex-start;
    margin-left: 0;
  }
`

const SNS = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  padding-bottom: 10px;

  @media only screen and (max-width: 768px) {
    justify-content: flex-start;
  }
`

const RightLink = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: auto;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
    /* font-size: 10px; */
    margin-top: 20px;
    margin-left: 0;
  }
`

export const FooterForLanding: React.FC = () => {
  // const goDockerHanderTerms = () => {
  // 	document.location.href = '/terms'
  // }
  // const goDockerHanderManager = () => {
  // 	document.location.href = '/manager'
  // }

  return (
    <FooterComponent>
      <LogoBox>
        <img src={MediPangPangFooterLogo} alt="Medi PangPang Footer logo" />
        <ODHSpace w={17} />
        <LogoLine />
      </LogoBox>
      <ODVSpace h={19} />
      <FooterMain>
        <LeftBox>
          <CompanyInfoBox>
            <CompanyInfo>(주)에비드넷</CompanyInfo>
            <CompanyInfo gray>서울특별시 강남구 강남대로 292 뱅뱅빌딩 3층</CompanyInfo>
            <ODVSpace h={5} />
            <CompanyInfo gray>1566-8013</CompanyInfo>
          </CompanyInfoBox>
          <ODHSpace w={48} />
          {/*<CompanyInfoBox>*/}
          {/*	<CompanyInfo>대표 : 조인산</CompanyInfo>*/}
          {/*	<CompanyInfo gray>사업자 등록번호 : 398-81-00866</CompanyInfo>*/}
          {/*</CompanyInfoBox>*/}
        </LeftBox>
        <RightBox>
          <SNS>
            <a href="https://blog.naver.com/medi-pangpang" target="_blank">
              <img src={snsBlog} alt="naver blog" style={{ width: 36, height: 36 }} />
            </a>
            <ODHSpace w={24} />
            <a href="https://www.facebook.com/medipangpang" target="_blank">
              <img src={snsFacebook} alt="facebook" style={{ width: 36, height: 36 }} />
            </a>
            <ODHSpace w={24} />
            <a href="https://www.youtube.com/channel/UCVvQ6N3yY3Tkn25PBnneMvQ" target="_blank">
              <img src={snsYoutube} alt="youtube" style={{ width: 36, height: 36 }} />
            </a>
          </SNS>
          <RightLink>
            <a href={SiteUrls.Terms} className="grayed_link">
              이용약관
            </a>
            <ODHSpace w={16} />
            <ODVLine height={12} color={COLORS.GRAY400} className="footer_line" />
            <ODHSpace w={16} />
            <a href={SiteUrls.Privacy} className="grayed_link">
              개인정보처리방침
            </a>
            <ODHSpace w={16} />
            <ODVLine height={12} color={COLORS.GRAY400} className="footer_line" />
            <ODHSpace w={16} />
            <a href={SiteUrls.PrivacyConsent} className="grayed_link">
              개인정보 수집 및 이용 동의
            </a>
            <div style={{ display: 'none' }}>
              <ODHSpace w={16} />
              <ODVLine height={12} color={COLORS.GRAY400} className="footer_line" />
              <ODHSpace w={16} />
              <a href={SiteUrls.ThirdPartyConsent} className="grayed_link">
                개인정보 수집 및 제3자 제공 동의
              </a>
              <ODHSpace w={16} />
              <ODVLine height={12} color={COLORS.GRAY400} className="footer_line" />
              <ODHSpace w={16} />
              <a href={SiteUrls.UidConsent} className="grayed_link">
                고유식별정보 수집 동의
              </a>
            </div>
          </RightLink>
        </RightBox>
      </FooterMain>
    </FooterComponent>
  )
}
