export const validator = {
  username: (value: string) => {
    const errors: Object[] = []
    const warnings: Object[] = []

    if (!/^[ㄱ-ㅎㅏ-ㅣ가-힣]*$/.test(value)) {
      errors.push('한글만 입력 가능합니다.')
    }
    return errors.length === 0 && warnings.length === 0 ? {} : { errors, warnings }
  },
  email: (value: string) => {
    const errors: Object[] = []
    const warnings: Object[] = []

    if (!/^[A-Za-z0-9_\.\-]+@[A-Za-z0-9\-]+\.[A-Za-z0-9\-]+/.test(value) && value) {
      errors.push('이메일 형식 오류 입니다.')
    }
    return errors.length === 0 && warnings.length === 0 ? {} : { errors, warnings }
  },
  password: (value: string) => {
    const errors: Object[] = []
    const warnings: Object[] = []

    if (value.length < 8 || value.length > 20) {
      errors.push('8자 이상 20자 이하로 입력하세요.')
    }
    if (!/^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]*$/.test(value)) {
      errors.push('영문, 숫자, 특수문자(!@#$%^&*)가 각 1자이상 포함되어야 합니다.')
    }
    if (/(?=.*\s)/.test(value)) {
      errors.push('공백은 사용할 수 없습니다.')
    }
    if (checkSequenceKey(value)) {
      errors.push('연속된 문자를 3자 이상 사용하지 마세요.')
    }
    if (/(\w)\1\1/.test(value)) {
      errors.push('반복된 문자를 3자 이상 사용하지 마세요.')
    }
    return errors.length === 0 && warnings.length === 0 ? { success: '안전한 비밀번호 입니다.' } : { errors, warnings }
  },
  passwordConfirm: (value: string, confirmValue: string) => {
    const errors: Object[] = []
    const warnings: Object[] = []

    if (value !== confirmValue) {
      errors.push('설정하신 비밀번호와 일치하지 않습니다.')
    }
    return errors.length === 0 && warnings.length === 0
      ? { success: confirmValue.length > 0 ? '설정하신 비밀번호와 일치합니다.' : '' }
      : { errors, warnings }
  },
  doctorCode: (value: string) => {
    const errors: Object[] = []
    const warnings: Object[] = []

    if (!/^[0-9]+$/.test(value)) {
      errors.push('의사면허번호 형식 오류 입니다.')
    }
    return errors.length === 0 && warnings.length === 0 ? {} : { errors, warnings }
  },
  birth: (value: string) => {
    const errors: Object[] = []
    const warnings: Object[] = []

    if (!isBirthday(value) || !/^[0-9]+$/.test(value)) {
      errors.push('생년월일 형식 오류입니다.')
    }
    return errors.length === 0 && warnings.length === 0 ? {} : { errors, warnings }
  },
  code: (value: string) => {
    const errors: Object[] = []
    const warnings: Object[] = []

    if (!/^[a-zA-Z]*$/.test(value)) {
      errors.push('영문/대문자만 입력 가능합니다.')
    }
    return errors.length === 0 && warnings.length === 0 ? {} : { errors, warnings }
  },
  account: (value: string) => {
    const errors: Object[] = []
    const warnings: Object[] = []

    if (!/^[0-9]+$/.test(value)) {
      errors.push('숫자만 입력 가능합니다.')
    }
    return errors.length === 0 && warnings.length === 0 ? {} : { errors, warnings }
  },
}

export const continuousPwd = (pwd: string) => {
  for (let i = 0; i < pwd.length - 2; i += 1) {
    if (pwd.charCodeAt(i) === pwd.charCodeAt(i + 1) - 1 && pwd.charCodeAt(i) === pwd.charCodeAt(i + 2) - 2) {
      return true
    }
  }
  return false
}

export const checkSequenceKey = (pwd: string) => {
  const keyboard = ['1234567890', 'qwertyuiop', 'asdfghjkl', 'zxcvbnm']
  const smallPwd = pwd.toLowerCase()

  for (let i = 0; i < smallPwd.length - 2; i++) {
    const sliceValue = smallPwd.substring(i, i + 3)

    if (keyboard.some(code => code.includes(sliceValue))) {
      return true
    }
  }
  return false
}

export const isBirthday = (dateStr: string) => {
  const year = Number(dateStr.substr(0, 4)) // 입력한 값의 0~4자리까지 (연)
  const month = Number(dateStr.substr(4, 2)) // 입력한 값의 4번째 자리부터 2자리 숫자 (월)
  const day = Number(dateStr.substr(6, 2)) // 입력한 값 6번째 자리부터 2자리 숫자 (일)
  const today = new Date() // 날짜 변수 선언
  const yearNow = today.getFullYear() // 올해 연도 가져옴

  if (dateStr.length === 8) {
    if (1900 > year || year >= yearNow) {
      return false
    } else if (month < 1 || month > 12) {
      return false
    } else if (day < 1 || day > 31) {
      return false
    } else if ((month == 4 || month == 6 || month == 9 || month == 11) && day == 31) {
      return false
    } else if (month === 2) {
      const isleap = year % 4 == 0 && (year % 100 != 0 || year % 400 == 0)
      if (day > 29 || (day == 29 && !isleap)) {
        return false
      } else {
        return true
      }
    } else {
      return true
    }
  } else {
    return false
  }
}
