import { ODListableResponseType } from '@mdpp/od-react-belt'
import React from 'react'
import { GQLDoctor, GQLWaitingDoctor } from '@mdpp/common/lib/@types/server.schema'
import { useCommonAPIs } from '../../hooks/useCommonAPIs'
import { IDoctorListableOption } from "../../doctor/DoctorCommon";

export function useWaitingDoctorListDataLoader() {
  const { listWaitingDoctor } = useCommonAPIs()
  return React.useCallback(
    async function dataLoader(
      page: number,
      pageSize: number,
      afterKey: string | null,
      options: IDoctorListableOption
    ): Promise<ODListableResponseType<GQLWaitingDoctor>> {
      const r = await listWaitingDoctor({
        page,
        pageSize,
        ...options,
        emailAuthFlag: true
      })
      return r as ODListableResponseType<GQLWaitingDoctor>
    },
    [listWaitingDoctor]
  )
}
