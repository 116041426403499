import styled from 'styled-components'

export const EvixCardRowContent = styled.div`
  display: flex;
  flex-direction: column;

  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.3px;
  color: #131415;
  width: 100%;
`

export const EvixSpan = styled.span`
  width: 500px;
`
