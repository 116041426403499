import React from 'react'
import { Modal } from "reactstrap";
import { EvixButton, EvixButtonTheme } from '../../../common/EvixButton'
import IconClose from '../../../assets/common/icon-close.svg'
import {Wrapper, Header, Container, Content, ButtonWrapper, Title, Box } from '../../../common/style/ModalStyle'

interface HoldModalProps {
  style?: object
  BtnText?:string
  isEdit? : boolean
  onOKPress: (reason: string) => void
  onCancelPress: () => void
  initValue?: string
}

interface HoldModalState {
  value?: string;
}

export const HoldModal: React.FC<HoldModalProps> = props => {
  const { style, BtnText, isEdit, onOKPress, onCancelPress, initValue } = props
  const value = (state: HoldModalState) => {

  }

  return (
    <Modal isOpen={true} toggle={() => {}} style={style}>
      <Wrapper>
        <Header>
          <img src={IconClose} onClick={onCancelPress} alt='close icon'/>
        </Header>

        <Container className='pd40'>
          <Content>
            <Title className='title-sm'>보류 사유를 작성해주세요.</Title>
            <Box>
            {isEdit ? 
              <>
                <textarea id={'reason'} maxLength={96}>{initValue}</textarea>
              </>
              : 
              <>
                <div>
                  이름과 병원이름이 드라마와 동일합니다.<br/>
                  의사면허번호도 확인이 되지 않습니다.<br/>
                  이름과 병원이름이 드라마와 동일합니다.
                </div>
              </>
            }
            </Box>

          </Content>
          </Container>
          {/* 버튼 영역 */}
          <ButtonWrapper>
            <EvixButton theme={EvixButtonTheme.H48WhiteInline} style={{ width: '100%' }}
                        onClick={() => {
                          const reason: HTMLElement | null = document.getElementById('reason')
                          if (reason && "value" in reason) {
                            onOKPress(reason['value'])
                          }
                        }} disabled={false}>{BtnText}</EvixButton>
          </ButtonWrapper>
        
      </Wrapper>
    </Modal>
  )
}


