import { ODVSpace } from '@mdpp/od-react-belt'
import React from 'react'
import styled from 'styled-components'
import CamOffIcon from '../../../../assets/chat-chat-doctor-male@3x.png'

interface IRoomCameraOffProps {
  text: string
  small: boolean
}

const Wrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: black;
`

const MainImage = styled.div`
  display: flex;
  width: 152px;
  height: 152px;
  align-items: center;
  justify-content: center;
`

const Text = styled.div`
  font-size: 16px;
  font-weight: bold;
  line-height: 1.38;
  text-align: center;
  color: var(--mpPrimary500);
`

export const RoomCameraOff: React.FC<IRoomCameraOffProps> = props => {
  const { text, small } = props
  const width = small ? 50 : 100
  const height = small ? 50 : 100
  return (
    <Wrapper>
      <MainImage>
        <img src={CamOffIcon} width={width} height={height} alt="camera off" />
      </MainImage>
      <ODVSpace h={21} />
      <Text>{text}</Text>
    </Wrapper>
  )
}
