import { createNavItem, ODIcons, ODSiteNav } from '@mdpp/od-react-belt'
import { SiteUrls } from '../SiteUrls'

export const ManagerSiteNavs: ODSiteNav = {
  items: [
    createNavItem(ODIcons.Dashboard, `대시보드`, SiteUrls.Manager.Dashboard.Main),
    createNavItem(ODIcons.CoreBuilding, `병원관리`, SiteUrls.Manager.Hospital.Main),
    createNavItem(ODIcons.Doctor, `의사관리`, SiteUrls.Manager.Doctor.Main),
    createNavItem(ODIcons.Patient, `진료관리`, SiteUrls.Manager.Reservation.Main),
    createNavItem(ODIcons.CoreClipboard, `공지사항`, SiteUrls.Manager.Notice.Main),
    createNavItem(ODIcons.CoreEnvelopeClosed, `문의하기`, SiteUrls.Manager.Inquiry.Main),
    createNavItem(ODIcons.SVGSmart, `스마트 진료지원`, SiteUrls.Manager.MyData.Main),
  ],
}
