export const SiteUrls = {
  ODGallery: {
    Root: '/odcode/gallery',
    Icons: '/odcode/gallery/icons',
  },
  Landing: {
    Root: '/landing',
  },
  // 의사 어드민
  Doctor: {
    Root: '/doctor',
    Login: '/doctor/login',
    Logout: '/doctor/logout',
    ChangePassword: '/doctor/changePassword',
    Dashboard: {
      Main: '/doctor/dashboard',
    },
    Reservation: {
      Main: '/doctor/reservation',
      Detail: (id: number | string) => `/doctor/reservation/${id}`,
      Result: (id: number | string, tab: number) => `/doctor/reservation/${id}?tab=${tab}`,
      Room: (id: number | string) => `/doctor/reservation/${id}/room`,
    },
    Schedule: {
      Main: '/doctor/schedule',
      SetupDefault: '/doctor/schedule/default',
    },
    MyPage: {
      Main: '/doctor/myPage',
    },
    Notice: {
      Main: '/doctor/notice',
      Item: (id: number | string) => `/doctor/notice/${id}`,
    },
    Inquiry: {
      Main: '/doctor/inquiry',
    },
    MyData: {
      Main: '/doctor/mydata',
      Item: (token: string, dsId?: number | string | undefined) => `/doctor/mydata/${dsId}/${token}`,
    },
    SignUp: '/doctor/signup',
    SignUpInfo: '/doctor/signupInfo',
    EmailAuthentication: '/doctor/emailAuthentication',
    ChangeEmail: '/doctor/changeEmail',
    CompleteSignUp: '/doctor/completeSignUp',
    Code: {
      Main: '/doctor/code',
    },
  },
  // 병원매니저 어드민
  Manager: {
    Root: '/manager',
    Login: '/manager/login',
    Logout: '/manager/logout',
    ChangePassword: '/manager/changePassword',
    Dashboard: {
      Main: '/manager/dashboard',
    },
    Hospital: {
      Main: '/manager/hospital',
      Edit: '/manager/hospital/edit',
      Pharmacy: '/manager/hospital/pharmacy',
    },
    Reservation: {
      Main: '/manager/reservation',
      MainFinished: '/manager/reservation?finished=1',
      Detail: (id: string | number) => `/manager/reservation/${id}`,
    },
    Doctor: {
      Main: '/manager/doctor',
      Create: `/manager/doctor/new`,
      Edit: (id: string | number) => `/manager/doctor/${id}/edit`,
      Schedule: (id: string | number) => `/manager/doctor/${id}/schedule`,
      SetupScheduleDefault: (id: string | number) => `/manager/doctor/${id}/schedule/default`,
    },
    Pharmacy: {
      Main: '/manager/pharmacy',
      Create: `/manager/pharmacy/new`,
      Edit: (id: string | number) => `/manager/pharmacy/${id}/edit`,
      Schedule: (id: string | number) => `/manager/pharmacy/${id}/schedule`,
      SetupScheduleDefault: (id: string | number) => `/manager/pharmacy/${id}/schedule/default`,
    },
    Notice: {
      Main: '/manager/notice',
      Item: (id: number | string) => `/manager/notice/${id}`,
    },
    Inquiry: {
      Main: '/manager/inquiry',
    },
    MyData: {
      Main: '/manager/mydata',
      Item: (token: string, dsId?: number | string | undefined) => `/manager/mydata/${dsId}/${token}`,
    },
  },
  // 관리자 어드민
  Admin: {
    Root: '/admin',
    Login: '/admin/login',
    Logout: '/admin/logout',
    Dashboard: {
      Main: '/admin/dashboard',
    },
    Hospital: {
      Main: '/admin/hospital',
      Create: `/admin/hospital/new`,
      Edit: (id: string | number) => `/admin/hospital/${id}/edit`,
      Manager: {
        List: (hospitalId: string | number) => `/admin/hospital/${hospitalId}/manager`,
        Create: (hospitalId: string | number) => `/admin/hospital/${hospitalId}/manager/new`,
        Edit: (hospitalId: string | number, managerId: string | number) =>
          `/admin/hospital/${hospitalId}/manager/${managerId}/edit`,
      },
      Pharmacy: (hospitalId: string | number) => `/admin/hospital/${hospitalId}/pharmacy`,
    },
    Doctor: {
      Main: '/admin/doctor',
      // Create: `/admin/doctor/new`,
      Edit: (id: string | number) => `/admin/doctor/${id}/edit`,
      Schedule: (id: string | number) => `/admin/doctor/${id}/schedule`,
      SetupScheduleDefault: (id: string | number) => `/admin/doctor/${id}/schedule/default`,
    },
    WatingDoctor: {
      Main: '/admin/waitingDoctor',
      Edit: (id: string | number) => `/admin/waitingDoctor/${id}/edit`,
    },
    WatingPharmacy: {
      Main: '/admin/waitingPharmacy',
      Edit: (id: string | number) => `/admin/waitingPharmacy/${id}/edit`,
    },
    Patient: {
      Main: '/admin/patient',
      Detail: (patientId: string | number) => `/admin/patient/${patientId}`,
    },
    Reservation: {
      Main: '/admin/reservation',
      Detail: (reservationId: string | number) => `/admin/reservation/${reservationId}`,
    },
    Review: {
      Main: '/admin/review',
    },
    Pharmacy: {
      Main: '/admin/pharmacy',
      Create: `/admin/pharmacy/new`,
      Edit: (id: string | number) => `/admin/pharmacy/${id}/edit`,
    },
    Contents: {
      Main: '/admin/contents',
    },
    MedicalSubject: {
      Main: '/admin/medicalSubject',
      Create: '/admin/medicalSubject/new',
      Edit: (id: number | string) => `/admin/medicalSubject/${id}/edit`,
    },
    Notice: {
      Main: '/admin/notice',
      Create: '/admin/notice/new',
      Edit: (id: number | string) => `/admin/notice/${id}/edit`,
      Item: (id: number | string) => `/admin/notice/${id}`,
    },
    AppVersion: {
      Main: '/admin/appVersion',
      Create: '/admin/appVersion/new',
      Edit: (id: number | string) => `/admin/appVersion/${id}/edit`,
    },
    Faq: {
      Main: '/admin/faq',
      Create: '/admin/faq/new',
      Edit: (id: number | string) => `/admin/faq/${id}/edit`,
      // Item: (id: number | string) => `/admin/faq/${id}`,
    },
    Inquiry: {
      Main: '/admin/inquiry',
      Item: (id: number | string) => `/admin/inquiry/${id}`,
    },
  },
  // 약국 어드민
  Pharmacy: {
    Root: '/pharmacy',
    Login: '/pharmacy/login',
    Logout: '/pharmacy/logout',
    ChangePassword: '/pharmacy/changePassword',
    SignUp: '/pharmacy/signup',
    SignUpInfo: '/pharmacy/signupInfo',
    EmailAuthentication: '/pharmacy/emailAuthentication',
    ChangeEmail: '/pharmacy/changeEmail',
    CompleteSignUp: '/pharmacy/completeSignUp',
    Reservation: {
      Main: '/pharmacy/reservation',
      Detail: (id: number | string) => `/pharmacy/reservation/${id}`,
      Result: (id: number | string, tab: number) => `/pharmacy/reservation/${id}?tab=${tab}`,
    },
    MyPage: {
      Main: '/pharmacy/mypage',
    },
  },
  Terms: '/terms',
  Privacy: '/privacy',
  PrivacyConsent: '/privacy-consent',
  ThirdPartyConsent: '/third-party-consent',
  UidConsent: '/uid-consent',
  ForgotPassword: {
    Doctor: '/forgotPassword/doctor',
    Manager: '/forgotPassword/manager',
    Admin: '/forgotPassword/admin',
    Pharmacy: '/forgotPassword/pharmacy',
  },
  ForgotID: {
    Doctor: '/forgotID/doctor',
    Manager: '/forgotID/manager',
    Admin: '/forgotID/admin',
    Pharmacy: '/forgotID/pharmacy',
  },
  ProofExpired: '/expired',
  SuccessSignUp: '/succsign',
  SuccessMediSignUp: '/succsignMedi',
  // MyDataWithURL: (url: string) =>
  //     `/files/MydataShareFile/${url}`,
  MyDataTemporaryWithURL: (url: string) => `/files/MydataShareFile/temporary/${url}`,
  ForgotPasswordEmailSent: (email: string) => `/forgotPassword/emailSent/${email}`,
  PasswordResetConfirmed: (email: string, accountType: string) =>
    `/forgotPassword/${accountType}/resetConfirmed/${email}`,
}
