import { ODListableResponseType } from '@mdpp/od-react-belt'
import React from 'react'
import { GQLListableNoticeInput, GQLNotice } from '@mdpp/common/lib/@types/server.schema'
import { useCommonAPIs } from '../../hooks/useCommonAPIs'
import { INoticeListableOption } from '../NoticeCommon'

export function useNoticeListDataLoader(defaultOptions: Partial<GQLListableNoticeInput>) {
  const { listNotice } = useCommonAPIs()
  return React.useCallback(
    async function dataLoader(
      page: number,
      pageSize: number,
      afterKey: string | null,
      options: INoticeListableOption
    ): Promise<ODListableResponseType<GQLNotice>> {
      // @ts-ignore
      const r = await listNotice({
        page,
        pageSize,
        ...options,
        ...defaultOptions,
      })
      return r as ODListableResponseType<GQLNotice>
    },
    [listNotice, defaultOptions]
  )
}
