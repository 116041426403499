import {
  ODCommonMainLayout,
  SingleQueryWrapper,
} from '@mdpp/od-react-belt'
import * as React from 'react'
import { COLORS } from '../styles/colors'
import { SiteUrls } from '../SiteUrls'
import { DoctorRoutes } from './DoctorRoutes'
import { DoctorSiteNavs } from './DoctorSiteNavs'
import { DoctorHeader } from './gnb/DoctorHeader'
import { GQLPopupInfo } from '@mdpp/common/lib/@types/server.schema';
import { useCommonAPIs } from "../common/hooks/useCommonAPIs";
import { NoticePopupModal } from "../common/notice/modal/NoticePopupModal";
import { useEffect } from "react";
import { LocalStorageWithExpiry } from "../utils/LocalStorageWithExpiry";
import moment from "moment/moment";
import { useODDoctorAppContext } from "./context/ODDoctorAppContext";
import { AppOptions } from "../AppOptions";
import ChannelService from 'channel-talk-ts'

export const DoctorMainLayout: React.FC = () => {
  const { listPopupInfo } = useCommonAPIs()
  const [listPopup, setListPopup] = React.useState<GQLPopupInfo[]>([])
  const [showNoticePopup, setShowNoticePopup] = React.useState<boolean>(true)
  const [linkTo, setLinkTo] = React.useState<string>('')
  const {
    state: { profile },
  } = useODDoctorAppContext()
  
  const cancelNoticePopup = React.useCallback(async (onetime : boolean) => {
    if (onetime || listPopup[0].onetime) {
      await LocalStorageWithExpiry.save('notice' + '_doctor'+ profile?.doctorId + listPopup[0]?.notice?.noticeId, '1',
        moment((listPopup[0].postingEnd)).add(10, 'second'))
    }
    setListPopup(listPopup.slice(1))
  }, [listPopup])
  
  useEffect(() => {
    if (listPopup[0]?.notice?.noticeId)
      setLinkTo(AppOptions.WS_SERVER_ADDRESS + '/doctor'
        + '/notice/' + listPopup[0]?.notice?.noticeId )
    
    listPopup && listPopup.length > 0 ?
      setShowNoticePopup(true) : setShowNoticePopup(false)
  }, [listPopup])

  useEffect(() => {
    const CS = new ChannelService({pluginKey: AppOptions.CHANNEL_TALK_DOCTOR_PLUGIN_KEY || 'testkey'})
    CS.boot()

    return () => {
      CS.shutdown()
    }
  }, [])

  return (
    <>
      <ODCommonMainLayout
        renderHeader={() => <DoctorHeader />}
        defaultRoute={SiteUrls.Doctor.Dashboard.Main}
        navs={DoctorSiteNavs}
        routes={DoctorRoutes}
        style={{
          padding: 0,
          marginLeft: 0,
          marginTop: 0,
          marginBottom: 0,
          marginRight: 0,
          // background: COLORS.GRAY100,
          background: '#F4F6F8',
        }}
      />
      <SingleQueryWrapper deps={[listPopupInfo]} getter={async() => {
        if (window.location.href.match('notice'))
          return []
        if (window.location.href.match(/\?tab=/))
          return []
        
        // @ts-ignore
        const listablePopupInfo = await listPopupInfo({showOnDoctor: 1})
        const list = listablePopupInfo.list
        // 이미 확인한 공지인지 체크
        for (let i = list.length - 1; i >= 0; i--) {
          const data = await LocalStorageWithExpiry.load('notice' + '_doctor'+ profile?.doctorId + list[i].notice?.noticeId)
          if (data) {
            list.splice(i, 1)
          }
        }
        setListPopup(list)
        return listablePopupInfo.list
      }}>
        {({ data }) => {
          return (
            <NoticePopupModal isOpen={showNoticePopup}
                              showDetailCallback={ async() => {
                                console.log('show Detail')
                              }}
                              onClose={cancelNoticePopup}
                              popupInfo={listPopup[0]}
                              isManager={false}
                              linkTo={linkTo}/>
          )
        }}
      </SingleQueryWrapper>
    </>
  )
}
