import { createNavItem, ODCommonMainLayout, ODIcons, ODSiteNav, ODSiteNavItem, useODQuery } from '@mdpp/od-react-belt'
import * as React from 'react'
import { SiteUrls } from '../SiteUrls'
import { COLORS } from '../styles/colors'
import { AdminRoutes } from './AdminRoutes'
import { getAdminSiteNavs } from './AdminSiteNavs'
import { AdminHeader } from './gnb/AdminHeader'
import { GQL_LIST_WAITING_DOCTOR } from '../common/gqls_common'
import { GQLListableWaitingDoctorInput, GQLListableWaitingDoctorOutput } from '@mdpp/common/lib/@types/server.schema'
import { useCommonAPIs } from '../common/hooks/useCommonAPIs'
import { useAdminAppContext } from './context/AdminAppContext'

export const AdminMainLayout: React.FC = () => {
  const initNavi = {
    items: [
      createNavItem(ODIcons.Dashboard, `대시보드`, SiteUrls.Admin.Dashboard.Main),
      createNavItem(ODIcons.CoreBuilding, `병원`, SiteUrls.Admin.Hospital.Main),
      createNavItem(ODIcons.SVGDoctor, `의사`, SiteUrls.Admin.Doctor.Main),
      createNavItem(ODIcons.SVGDoctorCheck, `의사 가입승인`, SiteUrls.Admin.WatingDoctor.Main),
      createNavItem(ODIcons.Patient, `환자`, SiteUrls.Admin.Patient.Main),
      createNavItem(ODIcons.Inquiry, `진료`, SiteUrls.Admin.Reservation.Main),
      createNavItem(ODIcons.CoreSpeech, `리뷰`, SiteUrls.Admin.Review.Main),
      createNavItem(ODIcons.CoreFilterPhoto, `약국`, SiteUrls.Admin.Pharmacy.Main),
      createNavItem(ODIcons.SVGDoctorCheck, `약국 가입승인`, SiteUrls.Admin.WatingPharmacy.Main),
      createNavItem(ODIcons.CoreSettingCog, `진료과목 관리`, SiteUrls.Admin.MedicalSubject.Main),
      createNavItem(ODIcons.CoreClipboard, `공지사항`, SiteUrls.Admin.Notice.Main),
      createNavItem(ODIcons.CoreMobile, `앱 버전`, SiteUrls.Admin.AppVersion.Main),
      createNavItem(ODIcons.CoreChatBubble, `자주하는 질문`, SiteUrls.Admin.Faq.Main),
      createNavItem(ODIcons.CoreEnvelopeClosed, `문의 내역`, SiteUrls.Admin.Inquiry.Main),
    ],
  }

  const listApi = useODQuery<Partial<GQLListableWaitingDoctorInput>, GQLListableWaitingDoctorOutput>(
    GQL_LIST_WAITING_DOCTOR
  )

  const { listWaitingDoctor, listWaitingPharmacist } = useCommonAPIs()

  const callGetWaitingDoctorList = async () => {
    const r = await listApi({ page: 1, pageSize: 1000, emailAuthFlag: true })
    const filtered = r.list?.filter(item => {
      return !item.isHold
    })
    return filtered.length
  }

  const callGetWaitingPharmacistList = async () => {
    const r = await listWaitingPharmacist({ page: 1, pageSize: 1000, emailAuthFlag: true })
    const filtered = r.list?.filter(item => {
      return !item.isHold
    })
    return filtered.length
  }

  const [naviList, setNaviList] = React.useState<ODSiteNav>(
    getAdminSiteNavs(callGetWaitingDoctorList, callGetWaitingPharmacistList)
  )
  const [naviRendering, setNaviRendering] = React.useState(false)
  const { sideBarState } = useAdminAppContext()

  const renderInitNaviSideBar = () => {
    return (
      <ODCommonMainLayout
        renderHeader={() => <AdminHeader />}
        defaultRoute={SiteUrls.Admin.Dashboard.Main}
        navs={initNavi}
        routes={AdminRoutes}
        style={{
          padding: 0,
          marginLeft: 0,
          marginTop: 0,
          marginBottom: 0,
          marginRight: 0,
          background: COLORS.GRAY100,
        }}
      />
    )
  }

  const renderCountNaviSideBar = () => {
    return (
      <ODCommonMainLayout
        renderHeader={() => <AdminHeader />}
        defaultRoute={SiteUrls.Admin.Dashboard.Main}
        navs={naviList}
        routes={AdminRoutes}
        style={{
          padding: 0,
          marginLeft: 0,
          marginTop: 0,
          marginBottom: 0,
          marginRight: 0,
          background: COLORS.GRAY100,
        }}
      />
    )
  }

  React.useEffect(() => {
    setNaviRendering(true)
    setTimeout(() => {
      try {
        setNaviList(getAdminSiteNavs(callGetWaitingDoctorList, callGetWaitingPharmacistList))
        setNaviRendering(false)
      } catch (e) {
        setNaviRendering(false)
      }
    }, 100)
  }, [sideBarState])

  return (
    <>
      {naviRendering && renderInitNaviSideBar()}
      {!naviRendering && renderCountNaviSideBar()}
    </>
  )
}
