import { RESET_ACCOUNT_TYPE } from '@mdpp/common'
import { ODIcon, ODIcons, ODVSpace } from '@mdpp/od-react-belt'
import React, { useEffect, useState } from 'react'
import { Redirect } from 'react-router-dom'
import styled from 'styled-components'
import { GQLRESET_ACCOUNT_TYPE } from '@mdpp/common/lib/@types/server.schema'
import { SiteUrls } from '../SiteUrls'
import { WebUtils } from '../utils/webUtils'
import { EvixButton, EvixButtonTheme } from './EvixButton'
import { EvixInputText } from './EvixInputText'
import { useCommonAPIs } from './hooks/useCommonAPIs'
import { usePharmacyAPIs } from '../pharmacy/context/usePharmacyAPIs'
import { useCookies } from 'react-cookie'
import { checkSequenceKey } from '../validator'
import { useDoctorAPIs } from '../doctor/context/useDoctorAPIs'
import { useManagerAPIs } from '../manager/context/useManagerAPIs'
import moment from 'moment-timezone'

interface IChangePasswordContainerProps {
  accountType: RESET_ACCOUNT_TYPE
}

function validatePassword(character: string) {
  return /^(?=.*[A-Za-z])(?=.*\d)(?=.*[$@$!%*#?&])[A-Za-z\d$@$!%*#?&]{8,20}$/.test(character)
}

function repetitionPassword(character: string) {
  return /(\w)\1\1/.test(character)
}

export const ChangePasswordContainer: React.FC<IChangePasswordContainerProps> = props => {
  const { accountType } = props
  const { changeAccountPassword } = useCommonAPIs()
  const [currentPassword, setCurrentPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [checkNewPassword, setCheckNewPassword] = useState('')
  const [nextBtn, setNextBtn] = React.useState(true)
  const [redirect, setRedirect] = useState('')
  const [needUpdate, setNeedUpdate] = useState(false)
  const { getDoctorMyProfile } = useDoctorAPIs()
  const { getManagerProfile } = useManagerAPIs()
  const { getPharmacistMyProfile } = usePharmacyAPIs()
  const COOKIE_KEY = 'hidePasswardPage'
  const [cookies, setCookie] = useCookies([COOKIE_KEY])

  let now = new Date()
  let twoWeeks = new Date()

  useEffect(() => {
    getMyProfile()
  }, [])

  const nextChangePwd = () => {
    setTimeout(() => {
      switch (accountType) {
        case RESET_ACCOUNT_TYPE.DOCTOR:
          setRedirect(SiteUrls.Doctor.Dashboard.Main)
          break
        case RESET_ACCOUNT_TYPE.MANAGER:
          setRedirect(SiteUrls.Manager.Dashboard.Main)
          break
        case RESET_ACCOUNT_TYPE.PHARMACIST:
          setRedirect(SiteUrls.Pharmacy.Reservation.Main)
          break
      }
    }, 100)
  }

  const hidePage = () => {
    twoWeeks.setDate(now.getDate() + 14)
    console.log('twoWeeks === ', twoWeeks)
    setCookie(COOKIE_KEY, 'true', {
      path: '/',
      expires: twoWeeks,
    })
    nextChangePwd()
  }

  const dayDiff = (lastPasswordUpdate: string) => {
    const passwordNeedUpdate = moment().diff(moment(lastPasswordUpdate), 'day') >= 180
    setNeedUpdate(passwordNeedUpdate)
  }

  const getMyProfile = async () => {
    try {
      switch (accountType) {
        case RESET_ACCOUNT_TYPE.DOCTOR:
          const doctor = await getDoctorMyProfile()
          dayDiff(doctor.lastPasswordUpdatedTime)
          break
        case RESET_ACCOUNT_TYPE.MANAGER:
          const manager = await getManagerProfile()
          dayDiff(manager.lastPasswordUpdatedTime)
          break
        case RESET_ACCOUNT_TYPE.PHARMACIST:
          const pharmacist = await getPharmacistMyProfile()
          dayDiff(pharmacist.lastPasswordUpdatedTime)
          break
      }
    } catch (e) {
      console.log(e)
    }
  }

  const handleChangePassword = async () => {
    if (!currentPassword) {
      WebUtils.showError('현재 비밀번호를 입력해주세요.')
      return
    }
    if (!newPassword) {
      WebUtils.showError('새 비밀번호를 입력해주세요.')
      return
    }
    if (!validatePassword(newPassword)) {
      WebUtils.showError('영문, 숫자, 특수문자를 모두 사용하며 8~20자로 구성된 비밀번호를 입력해주세요.')
      return
    }
    if (checkSequenceKey(newPassword) || repetitionPassword(newPassword)) {
      WebUtils.showError('동일한 또는 연속된 숫자 및 문자는 제외하고 비밀번호를 설정해주세요')
      return
    }

    if (newPassword !== checkNewPassword) {
      WebUtils.showError('새 비밀번호와 비밀번호 확인이 일치하지 않습니다.')
      return
    }
    try {
      const t = (() => {
        switch (accountType) {
          case RESET_ACCOUNT_TYPE.ADMIN:
            return GQLRESET_ACCOUNT_TYPE.ADMIN
          case RESET_ACCOUNT_TYPE.MANAGER:
            return GQLRESET_ACCOUNT_TYPE.MANAGER
          case RESET_ACCOUNT_TYPE.DOCTOR:
            return GQLRESET_ACCOUNT_TYPE.DOCTOR
          case RESET_ACCOUNT_TYPE.PHARMACIST:
            return GQLRESET_ACCOUNT_TYPE.PHARMACIST
        }
      })()
      await changeAccountPassword({ currentPassword, newPassword, accountType: t })
      WebUtils.showSuccess('비밀번호가 변경되었습니다.')
      setTimeout(() => {
        switch (accountType) {
          case RESET_ACCOUNT_TYPE.DOCTOR:
            setRedirect(SiteUrls.Doctor.Dashboard.Main)
            break
          case RESET_ACCOUNT_TYPE.MANAGER:
            setRedirect(SiteUrls.Manager.Dashboard.Main)
            break
          case RESET_ACCOUNT_TYPE.ADMIN:
            setRedirect(SiteUrls.Admin.Dashboard.Main)
            break
          case RESET_ACCOUNT_TYPE.PHARMACIST:
            setRedirect(SiteUrls.Pharmacy.Reservation.Main)
            break
        }
      }, 100)
    } catch (error) {
      WebUtils.showError(error)
    }
  }

  useEffect(() => {
    if (!currentPassword || !checkNewPassword) {
      return setNextBtn(true)
    }
    if (!newPassword) {
      return setNextBtn(true)
    }
    setNextBtn(false)
  }, [currentPassword, newPassword, checkNewPassword])

  if (redirect) {
    return <Redirect to={redirect} />
  }

  return (
    <Wrapper>
      <Box>
        <IconWrapper>
          <ODIcon icon={ODIcons.CoreHttps} />
        </IconWrapper>
        <ODVSpace h={17} />
        <TextWrapper>비밀번호 변경</TextWrapper>
        <ODVSpace h={40} />
        <HorizontalLine />
        <ODVSpace h={37} />
        {needUpdate && (
          <>
            <MainMessage>
              6개월 이상 비밀번호를 변경하지 않았습니다.
              <br />
              개인정보 보호를 위해 주기적으로 비밀번호를 변경하세요.
            </MainMessage>
            <ODVSpace h={40} />
          </>
        )}
        <EvixInputText
          type="password"
          placeholder="현재 비밀번호"
          value={currentPassword}
          onChange={setCurrentPassword}
          onEnter={handleChangePassword}
          maxLength={20}
        />
        <ODVSpace h={30} />
        <EvixInputText
          type="password"
          placeholder="새 비밀번호"
          value={newPassword}
          onChange={setNewPassword}
          onEnter={handleChangePassword}
          maxLength={20}
        />
        <div style={{ width: '100%', marginTop: 5, marginLeft: 5 }}>
          <PasswordGuide>8~20자 영문 대 소문자, 숫자, 특수문자(!@#$%^&*)를 모두 사용하세요.</PasswordGuide>
        </div>
        <ODVSpace h={30} />
        <EvixInputText
          type="password"
          placeholder="새 비밀번호 확인"
          value={checkNewPassword}
          onChange={setCheckNewPassword}
          onEnter={handleChangePassword}
          maxLength={20}
        />
        <ODVSpace h={30} />
        <EvixButton
          onClick={handleChangePassword}
          style={Btn}
          theme={EvixButtonTheme.NormalW100H35Rounded}
          disabled={nextBtn}
        >
          변경
        </EvixButton>
        <ODVSpace h={40} />
        {needUpdate && (
          <>
            <HorizontalLine />
            <ODVSpace h={40} />
            <ButtonWrap>
              <EvixButton style={BlueLineBtn} theme={EvixButtonTheme.H48BlueLineInline} onClick={nextChangePwd}>
                다음에 변경하기
              </EvixButton>
              <EvixButton style={BlueLineBtn} theme={EvixButtonTheme.H48BlueLineInline} onClick={hidePage}>
                2주 후에 변경하기
              </EvixButton>
            </ButtonWrap>
          </>
        )}
      </Box>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  height: calc(100vh - 55px);
  width: calc(100vw - 240px);
  align-items: center;
  justify-content: center;
  background-color: var(--white);
`

const Box = styled.div`
  width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const IconWrapper = styled.div`
  font-size: 30px;
  color: var(--mpPrimary500);
`

const TextWrapper = styled.div`
  font-size: 28px;
  font-weight: 300;
  line-height: 1.07;
  color: var(--gray800);
`

const HorizontalLine = styled.div`
  width: 40px;
  height: 1px;
  background-color: var(--mpPrimary500);
`

const PasswordGuide = styled.div`
  font-size: 12px;
  line-height: 1.17;
  color: var(--gray600);
`

const Btn = { height: '50px' }

const MainMessage = styled.div`
  font-size: 15px;
  line-height: 23px;
  color: #7c818b;
  font-weight: 500;
  text-align: center;
`

const ButtonWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
`
const BlueLineBtn = {
  flex: 1,
}
