import { createNavItem, ODIcons, ODSiteNav } from '@mdpp/od-react-belt'
import { SiteUrls } from '../SiteUrls'

export const DoctorSiteNavs: ODSiteNav = {
  items: [
    createNavItem(ODIcons.SVGDashBoard, `대시보드`, SiteUrls.Doctor.Dashboard.Main),
    createNavItem(ODIcons.SVGTreatment, `진료`, SiteUrls.Doctor.Reservation.Main),
    createNavItem(ODIcons.SVGSchedule, `진료 / 스케줄 설정`, SiteUrls.Doctor.Schedule.Main),
    createNavItem(ODIcons.SVGMyPage, `마이페이지`, SiteUrls.Doctor.MyPage.Main),
    createNavItem(ODIcons.SVGNotice, `공지사항`, SiteUrls.Doctor.Notice.Main),
    createNavItem(ODIcons.SVGService, `문의하기`, SiteUrls.Doctor.Inquiry.Main),
    createNavItem(ODIcons.SVGSmart, `스마트 진료지원`, SiteUrls.Doctor.MyData.Main),
    createNavItem(ODIcons.SVGRecom, `나의 추천 코드`, SiteUrls.Doctor.Code.Main),
  ],
}
