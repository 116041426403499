import { ODListableResponseType } from '@mdpp/od-react-belt'
import React from 'react'
import { GQLMydataShare } from '@mdpp/common/lib/@types/server.schema'
import { useCommonAPIs } from "../../common/hooks/useCommonAPIs"
import { ISharedDataListableOption } from "./SharedDataListableTable"

export function useSharedDataListDataLoader() {
  const { listPatientMyData } = useCommonAPIs()
  return React.useCallback(
    async function dataLoader(
      page: number,
      pageSize: number,
      afterKey: string | null,
      options: ISharedDataListableOption
    ): Promise<ODListableResponseType<GQLMydataShare>> {
      const r = await listPatientMyData({
          page,
          pageSize,
          ...options,
      })
      return r as ODListableResponseType<GQLMydataShare>
    },
    [listPatientMyData]
  )
}
