import React from 'react'
import { DoctorListableTable } from '../../common/doctor/list/DoctorListableTable'
import { Page, PageContainer, PageHeader, PageTitle } from '../../common/page/Page'
import { WaitingDoctorListableTable } from '../../common/waitingDoctor/list/WaitingDoctorListableTable'

interface IAdminDoctorPageProps {
  hospitalId: number
}

export const AdminWaitingDoctorListPage: React.FC<IAdminDoctorPageProps> = props => {
  return (
    <PageContainer>
      <Page>
        <PageHeader>
          <PageTitle>의사 가입승인</PageTitle>
        </PageHeader>

        <WaitingDoctorListableTable fromHospitalManager={false} />
      </Page>
    </PageContainer>
  )
}
