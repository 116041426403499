import React from 'react'
import Select from 'react-select'
import styled from 'styled-components'
import { COLORS } from '../styles/colors'

interface IDoctorEvixSelector {
  style?: object
  value?: string
  options?: any
  placeholder?: string
  onChange?: (data: any) => void
  ref?: any
}

const Wrapper = styled.div`
  // React Select Style
  > div > div ~ div {
    display: block !important;
    visibility: visible !important;
    opacity: 1 !important;
    margin: -4px 0 0 0 !important;
    background-color: ${COLORS.GRAYEE} !important;
    box-shadow: none !important;
    border: 1px solid ${COLORS.GRAYDF} !important;
    border-top: none !important;
    border-radius: 0 0 4px 4px !important;
    font-size: 15px;
    font-weight: 500;
  }

  .css-2b097c-container {
    & svg {
      font-size: 22px;
      color: #424242;
    }
  }
  .css-1okebmr-indicatorSeparator {
    background-color: transparent;
  }

  .css-1laao21-a11yText {
    display: none;
  }

  .css-1wa3eu0-placeholder {
    color: #ababab !important;
  }

  .css-1uccc91-singleValue {
    color: #ababab;
    font-size: 13px;
  }
`
// react-select 스타일 수정 .
export const colourStyles = {
  control: (styles: any) => {
    return {
      ...styles,
      height: 44,
      fontSize: 15,
      fontWeight: 500,
      marginTop: 0,
      marginBottom: 0,
      backgroundColor: COLORS.GRAYEE,
      borderRadius: 4,
      borderColor: COLORS.GRAYDF,
      boxShadow: 'none',
      color: COLORS.GRAYAB,
      ':hover': {
        ...styles[':hover'],
        borderColor: COLORS.GRAYDF,
      },
      ':active': {
        ...styles[':active'],
        borderColor: COLORS.GRAYDF,
      },
    }
  },
  option: (styles: any, { data, isDisabled, isFocused, isSelected }: any) => {
    return {
      ...styles,

      backgroundColor: isDisabled
        ? COLORS.GRAYEE
        : isSelected
        ? COLORS.WHITE
        : isFocused
        ? COLORS.WHITE
        : COLORS.GRAYEE,
      color: isDisabled ? COLORS.GRAYAB : isSelected ? COLORS.GRAY42 : isFocused ? COLORS.GRAY42 : COLORS.GRAYAB,
      ':active': {
        ...styles[':active'],
        backgroundColor: !isDisabled && (isSelected ? COLORS.WHITE : COLORS.GRAYEE),
      },
    }
  },
}

export const EvixSelector: React.FC<IDoctorEvixSelector> = props => {
  const { style, value, options, placeholder, onChange, ref } = props

  return (
    <Wrapper style={style} className="select-wrap">
      <Select
        name="EvixSelector"
        options={options}
        ref={ref}
        value={options.find((v: any) => v.value === value)}
        onChange={onChange}
        styles={colourStyles}
        placeholder={placeholder}
      />
    </Wrapper>
  )
}
