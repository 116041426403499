import { AppOptions } from '../AppOptions'

export enum InputField {
  DoctorLoginEmail,
  DoctorLoginPassword,
  ManagerLoginEmail,
  ManagerLoginPassword,
  AdminLoginEmail,
  AdminLoginPassword,
  PharmaLoginEmail,
  PharmaLoginPassword,
}

export const TEST_DATA = {
  [InputField.DoctorLoginEmail]: 'doc1@evidnet.co.kr',
  [InputField.DoctorLoginPassword]: '12345678',
  [InputField.ManagerLoginEmail]: 'manager@evidnet.co.kr',
  [InputField.ManagerLoginPassword]: '12345678',
  [InputField.AdminLoginEmail]: 'evix@evidnet.co.kr',
  [InputField.AdminLoginPassword]: '12345678',
  [InputField.PharmaLoginEmail]: 'pharma@evidnet.co.kr',
  [InputField.PharmaLoginPassword]: '12345678',
}

export function useDefaultData(field: InputField): string {
  if (!AppOptions.FILL_MOCK_DATA) {
    return ''
  }

  return TEST_DATA[field]
}
