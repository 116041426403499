import { ODIcons, ODVSpace } from '@mdpp/od-react-belt'
import React from 'react'
import styled from 'styled-components'
import { TransactionLogListableTable } from '../../common/transactionLog/list/TransactionLogListableTable'
import { useTransactionLogListDataLoader } from '../../common/transactionLog/list/TransactionLogListDataLoader'
import { TitleWithViewAll } from '../../doctor/dashboard/TitleWithViewAll'
import { SiteUrls } from '../../SiteUrls'
import {EvixCardHeader} from "../../common/card/EvixCardHeader";
import {EvixCard, EvixCardWithTableWrapper} from "../../common/card/EvixCard";

interface IManagerRecentTransactionLogContainerProps {}

export const ManagerRecentTransactionLogContainer: React.FC<IManagerRecentTransactionLogContainerProps> = props => {
  const transactionLogDataLoader = useTransactionLogListDataLoader()

  return (
      <EvixCardWithTableWrapper>
          <EvixCardHeader title="최근 청구 내역" moreView link={SiteUrls.Manager.Reservation.MainFinished} />
          <EvixCard noPadding>
              <TransactionLogListableTable dataLoader={transactionLogDataLoader} />
          </EvixCard>
      </EvixCardWithTableWrapper>
  )
}
