import styled from 'styled-components'
import {
  ODDateTimeRenderer,
  ODEnumRenderer,
  ODListablePaginatedTable2,
  ODListablePagination,
  ODStringRenderer,
  useODListableContext,
} from '@mdpp/od-react-belt'
import { ODEnumRendererStyle } from '@mdpp/od-belt'
import React from 'react'
import { useHistory } from 'react-router-dom'
import { GQLReservation } from '@mdpp/common/lib/@types/server.schema'
import { SiteUrls } from '../../../SiteUrls'
import { RESERVATION_STATUS_TEXT, WebUtils } from '../../../utils/webUtils'
import { IReservationListableOption, ReservationPrimaryKey } from '../ReservationCommon'
import { useReservationEventHandler } from '../ReservationEventHandler'
import { useReservationListDataLoader } from './ReservationListDataLoader'
import { BodyContainer } from '../../layout/BodyContainer'

interface IReservationListableOfPatientTableProps {
  patientId?: number
}

export const ReservationListableOfPatientTable: React.FC<IReservationListableOfPatientTableProps> = props => {
  const { patientId } = props
  const { Provider, Context } = useODListableContext<GQLReservation, IReservationListableOption>()
  const dataLoader = useReservationListDataLoader(true, patientId)
  const { onEvent, token } = useReservationEventHandler(Context)
  const history = useHistory()

  const wrapperStyle = {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    marginTop: 15,
  }

  const itemActive = {
    borderColor: '#7F8694',
    backgroundColor: '#7F8694',
    color: 'white',
  }

  const itemDeactive = {
    borderColor: '#E6E8ED',
    backgroundColor: '#E6E8ED',
    color: '#202223',
  }

  const cardStyle = {
    display: 'flex',
    background: '#FFFFFF',
    boxShadow: '0px 0px 5px rgba(23, 24, 24, 0.05), 0px 1px 2px rgba(0, 0, 0, 0.15)',
    borderRadius: 8,
    border: 0,
  }

  return (
    <Provider
      dataLoader={dataLoader}
      keyExtractor={v => v[ReservationPrimaryKey].toString()}
      pageSize={10}
      refreshToken={token.toString()}
      onDataLoaderError={WebUtils.showError}
      searchOnLoad
    >
      <BodyContainer style={{flexDirection: 'column'}}>
        <TableWrapper style={cardStyle}>
          <ODListablePaginatedTable2
            borderLess={true}
            tableStyle={{marginBottom: 0}}
            numColumns={4}
            listableContext={Context}
            renderHeader={() => (
              <tr style={{background: '#F6F8FA', borderBottom: '1px solid #D1D5DA'}}>
                <th style={{paddingLeft: 28, width: 300}}>진료일시</th>
                <th>진료과목</th>
                <th>의료진</th>
              </tr>
            )}
            renderRow={(value: GQLReservation, context) => (
              <StyledTr key={value[ReservationPrimaryKey]} onClick={() =>(history.push(SiteUrls.Doctor.Reservation.Detail(value.reservationId)))}>
                <td style={{paddingLeft: 28, width: 300}}>
                  <ODDateTimeRenderer
                    format="YYYY.MM.DD (ddd) / HH:mm"
                    context={context}
                    value={value.reservationTime}
                    originalValue={value}
                    eventKey="reservationTime"
                  />
                </td>
                <td>
                  <ODStringRenderer
                    context={context}
                    value={value.medicalSubject.name}
                    originalValue={value}
                    eventKey="medicalSubject.name"
                  />
                </td>
                <td>
                  <ODStringRenderer
                    originalValue={value}
                    eventKey="doctor"
                    value={`${value.doctor.name} / ${value.hospital.name}`}
                    context={context}
                  />
                </td>

              </StyledTr>
            )}
            onEvent={onEvent}
            eventParentContext={{}}
          />
        </TableWrapper>
        <ODListablePagination hideIfSinglePage={false} listableContext={Context} wrapperStyle={wrapperStyle} itemActiveStyle={itemActive} itemDeactiveStyle={itemDeactive}/>
      </BodyContainer>
    </Provider>
  )
}


const StyledTr = styled.tr`
  cursor: pointer;
  height: 64px;
  border-bottom: 1px solid #D1D5DA;
  background-color: white;
`

const TableWrapper = styled.div`
  border: 1px solid #D1D5DA;
  border-bottom: none;
  flex: 1;
  border-radius: 5px;
  div {
    border-radius: 5px;
    border-bottom: none;
  }
`