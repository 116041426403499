import React from 'react'
import { Link } from 'react-router-dom'
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Nav } from 'reactstrap'
import styled from 'styled-components'
import { SiteUrls } from '../../SiteUrls'

import SVGLock from '../../assets/lock.svg'
import SVGSlash from '../../assets/slash.svg'
import IconHospital from '../../assets/common/icon-hospital-small.png'
import { usePharmacyAppContext } from '../context/PharmacyAppContext'

interface IPharmacyDropdownProps {}

const CenterBoldMenuItem = styled.div`
  font-size: 14px;
  font-weight: normal;
  line-height: 24px;
  letter-spacing: 0.2px;
  text-align: left;
  color: #202223;
`
const ItemWrapper = styled.div`
  display: flex;
  align-items: center;
`

const Icon = styled.img``

const MenuHeader: React.FC<{ title: string }> = props => {
  return (
    <DropdownItem
      header
      tag="div"
      className="text-center"
      style={{ background: 'white', fontSize: 14, fontWeight: 500 }}
    >
      {props.title}
    </DropdownItem>
  )
}

export const PharmacyDropdown: React.FC<IPharmacyDropdownProps> = props => {
  const [isOpen, setIsOpen] = React.useState(false)
  const {
    state: { profile },
  } = usePharmacyAppContext()

  const name = profile!.name
  const email = profile!.email

  return (
    <Nav className="ml-auto" navbar>
      <Dropdown nav isOpen={isOpen} toggle={() => setIsOpen(!isOpen)} size="sm">
        <DropdownToggle
          caret
          style={{
            backgroundColor: 'white',
            color: 'var(--gray800)',
          }}
        >
          {name}&nbsp;&nbsp;
        </DropdownToggle>
        <DropdownMenu right style={{ boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.2), 0px 2px 10px rgba(0, 0, 0, 0.1)' }}>
          <MenuHeader key="etc" title={email} />

          <Link to={SiteUrls.Pharmacy.ChangePassword}>
            <DropdownItem style={{ borderBottom: 'none' }}>
              <ItemWrapper>
                <Icon src={SVGLock} style={{ marginLeft: 6, marginRight: 18 }} />
                <CenterBoldMenuItem>비밀번호 변경</CenterBoldMenuItem>
              </ItemWrapper>
            </DropdownItem>
          </Link>

          <Link to={SiteUrls.Pharmacy.Logout}>
            <DropdownItem style={{ borderBottom: 'none' }}>
              <ItemWrapper>
                <Icon src={SVGSlash} style={{ marginLeft: 6, marginRight: 18 }} />
                <CenterBoldMenuItem>로그아웃</CenterBoldMenuItem>
              </ItemWrapper>
            </DropdownItem>
          </Link>
        </DropdownMenu>
      </Dropdown>
    </Nav>
  )
}
