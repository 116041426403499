import { SingleQueryWrapper } from '@mdpp/od-react-belt'
import React from 'react'
import { useCommonAPIs } from '../../hooks/useCommonAPIs'
import { InquiryItem } from './InquiryItem'

interface IFaqItemPageProps {
  id: string
}

export const InquiryItemPage: React.FC<IFaqItemPageProps> = props => {
  const { id } = props
  const { getInquiry } = useCommonAPIs()

  return (
    <SingleQueryWrapper deps={[id, getInquiry]} getter={() => getInquiry({ id: parseInt(id, 10) })}>
      {({ data }) => {
        return <InquiryItem inquiry={data} />
      }}
    </SingleQueryWrapper>
  )
}
