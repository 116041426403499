import React, { ReactNode } from 'react'
import { Col, Label } from 'reactstrap'
import styled from 'styled-components'
import {COLORS} from "../../styles/colors";

interface TableListProps  {
    name: string
    label: React.ReactNode
    children?: ReactNode
    labelMDSize?: number
    mandatoryInput?: boolean
    caption?: boolean
    captionLabel? : React.ReactNode
    style?: string
}

export const TableListWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 32px;
`

export const TableListToggleButtonWrapper = styled.div`
  display: flex;
  border-radius: 12px;
  padding: 8px;
  border: 1px solid ${COLORS.GRAY200};
  width: 30%;
`

export const TableList: React.FC<TableListProps> = (props) => {
    const { name, label, children, mandatoryInput, caption, captionLabel, labelMDSize = 3, style } = props
    return (
        <ListContainer>
            <Col md={labelMDSize} style={{padding: 0}}>
                {mandatoryInput ?
                    <LabelWrapper className={style}>
                        <div>
                            <Label htmlFor={`${name}-input`} className="label-table-list">
                                {label}
                            </Label>
                            
                            <RedDot />
                        </div>
                        { caption && <TextAreaHelpText>{captionLabel}</TextAreaHelpText> }
                    </LabelWrapper>
                    :
                    <LabelWrapper>
                        <Label htmlFor={`${name}-input`} className="label-table-list">
                            {label}
                        </Label>
                    { caption && <TextAreaHelpText>{captionLabel}</TextAreaHelpText> }
                    </LabelWrapper>

                }
            </Col>
            <div>
                {children}
            </div>
        </ListContainer>
    )
}

const ListContainer = styled.div``
const LabelWrapper = styled.div`
    display: flex;
    flex-direction: row;
    gap: 6px;
    align-items: center;
    justify-content: space-between;

    &.left {
        justify-content: flex-start;
    }
   
`
const RedDot = styled.div`
    display: inline-block;
    width: 6px;
    height: 6px;
    border-radius: 6px;
    background-color: ${COLORS.red100};
    margin-bottom: 12px;
`
const TextAreaHelpText = styled.div` 
  margin-bottom: 0.5rem;
  font-size: 13px;
  font-weight: 500;
  line-height: 1.17;
  color: #7C818B;
`
