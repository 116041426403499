import { ODWebUtils } from '@mdpp/od-react-belt'
import React from 'react'
import { Redirect } from 'react-router-dom'
import { CenterWithFooterContainer } from '../common/layout/CenterWithFooterContainer'
import { LoginBox, LoginBoxDesignStyle } from '../login/LoginBox'
import { SiteUrls } from '../SiteUrls'
import { useODDoctorAppContext } from './context/ODDoctorAppContext'
import { ConfirmModal } from '../common/layout/ConfirmModal'
import IconWarningBlue from '../assets/common/warning-blue.svg'
import { AppOptions } from '../AppOptions'
import { ErrorCode } from '@mdpp/common'
import { useDoctorAPIs } from './context/useDoctorAPIs'
import { checkMessageWithPrefix } from '../utils/registerServerErrorMessages'
import moment from 'moment-timezone'
import { useCookies } from 'react-cookie'

export const DoctorLoginPage: React.FC = () => {
  const { login } = useODDoctorAppContext()

  const [redirect, setRedirect] = React.useState('')
  const [permissionWait, setPermissionWait] = React.useState(false)
  const [emailCheck, setEmailCheck] = React.useState(false)
  const [showCancelWithdrawalModal, setShowCancelWithdrawalModal] = React.useState(false)

  const { loginDoctor } = useDoctorAPIs()
  const COOKIE_KEY = 'hidePasswardPage'
  const [cookies, setCookie] = useCookies([COOKIE_KEY])

  const goMain = () => {
    setShowCancelWithdrawalModal(false)
    setTimeout(() => {
      setRedirect(SiteUrls.Doctor.Dashboard.Main)
    }, 100)
  }

  if (redirect) {
    return <Redirect to={redirect} />
  }

  const handleLogin = async (email: string, password: string) => {
    const res = await loginDoctor({ email, password })
    const needUpdate = moment().diff(moment(res.me.lastPasswordUpdatedTime), 'day') >= 180

    if (res?.recovered === true) {
      setShowCancelWithdrawalModal(true)
    }
    try {
      await login({ email, password })
      if (res?.recovered === false) {
        if (needUpdate) {
          setTimeout(() => {
            setRedirect(
              cookies.hidePasswardPage === 'true' ? SiteUrls.Doctor.Dashboard.Main : SiteUrls.Doctor.ChangePassword
            )
          }, 100)
        } else {
          setRedirect(SiteUrls.Doctor.Dashboard.Main)
        }
      }
      return true
    } catch (ex) {
      console.log(ex)
      // 로그인 시도 > 이메일 인증안함 > 이메일 인증 하세요 팝업 노출
      if (checkMessageWithPrefix(ex, ErrorCode.EMAIL_NOT_AUTHENTICATED)) {
        setEmailCheck(true)
        setPermissionWait(false)
      }
      // 로그인 시도 > 이메일 인증함/관리자 승인안함 > 승인대기상태 팝업 노출
      if (checkMessageWithPrefix(ex, ErrorCode.ACCOUNT_NOT_APPROVED)) {
        setEmailCheck(false)
        setPermissionWait(true)
      }
      // 로그인 시도 > 이메일 48시간 지남 > 로그인 실패
      if (checkMessageWithPrefix(ex, ErrorCode.SERVER_WRONG_LOGIN_INFO)) {
        ODWebUtils.showError('로그인에 실패하였습니다.')
        setEmailCheck(false)
        setPermissionWait(false)
      }
      return false
    }
  }

  return (
    <CenterWithFooterContainer>
      <LoginBox designStyle={LoginBoxDesignStyle.Doctor} onLogin={handleLogin} />

      {/* 승인 대기 상태 Modal */}
      {permissionWait && (
        <ConfirmModal
          style={{ width: 420, height: 250 }}
          isTitle={true}
          title={'승인 대기 상태'}
          titleIcon={IconWarningBlue}
          isDescriptionIcon={false}
          description={[
            '이메일 인증 완료 후 승인 대기 중입니다.',
            <br />,
            '관리자의 승인이 완료되면 로그인이 가능합니다.',
          ]}
          descriptionIcon={''}
          hideCancel={true}
          onOkPress={() => setPermissionWait(false)}
          onCancelPress={() => setPermissionWait(false)}
        />
      )}

      {/* 이메일 미인증 상태 Modal */}
      {emailCheck && (
        <ConfirmModal
          style={{ width: 420, height: 227 }}
          isTitle={true}
          title={'이메일 미인증 상태'}
          titleIcon={IconWarningBlue}
          isDescriptionIcon={false}
          description={['유효기간 내에 이메일 인증을 완료해주세요.']}
          descriptionIcon={''}
          hideCancel={true}
          onOkPress={() => setEmailCheck(false)}
          onCancelPress={() => setEmailCheck(false)}
        />
      )}

      {/* 탈퇴 철회 확인 Modal */}
      {showCancelWithdrawalModal && (
        <ConfirmModal
          style={{ width: 420, height: 250 }}
          isTitle={true}
          title={'탈퇴가 철회 되었습니다.'}
          titleIcon={IconWarningBlue}
          isDescriptionIcon={false}
          description={['메디팡팡 의사 웹서비스에', <br />, '다시 돌아오신 것을 환영합니다.']}
          descriptionIcon={''}
          hideCancel={true}
          onOkPress={() => goMain()}
          onCancelPress={() => setShowCancelWithdrawalModal(false)}
        />
      )}
    </CenterWithFooterContainer>
  )
}
