import React from 'react'
import {
  CommonReservationListPage,
  ReservationListStyle,
} from '../../../common/reservation/list/CommonReservationListPage'

interface IReservationMainPageProps {}

export const PharmactistListPage: React.FC<IReservationMainPageProps> = props => {
  return <CommonReservationListPage listStyle={ReservationListStyle.Pharmacy} />
}
