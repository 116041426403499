import {ODHSpace, ODIcon, ODIcons, ODVSpace} from '@mdpp/od-react-belt'
import moment from 'moment'
import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { GQLReservation } from '@mdpp/common/lib/@types/server.schema'
import { SiteUrls } from '../../SiteUrls'
import { EvixCardRow } from "../../common/card/EvixCardRow";
import { EvixCardLabel } from "../../common/card/EvixCardLabel";
import { EvixCardRowContent } from "../../common/card/EvixCardRowContent";
import { EvixCardOneThirds } from "../../common/card/EvixCardOneThirds";
import {EvixCardHeader} from "../../common/card/EvixCardHeader";

interface IUpcomingReservationCardProps {
  reservation: GQLReservation | any
}

const cardStyle = {
  minWidth: 300,
  display: 'flex',

  background: '#FFFFFF',
  boxShadow: '0px 0px 5px rgba(23, 24, 24, 0.05), 0px 1px 2px rgba(0, 0, 0, 0.15)',
  borderRadius: 8,
  border: 0,
}

export const UpcomingReservationCard: React.FC<IUpcomingReservationCardProps> = props => {
  const { reservation } = props
  return (
    <EvixCardOneThirds style={cardStyle}>
      {/*<EvixCardTitle>{reservation.patient.name}</EvixCardTitle>*/}
        {reservation.proxyReservation ? (
            <>
            <div className={`enum_tag_proxy`} style={{float:'right'}}>
                <span>대리</span>
            </div>
            <EvixCardHeader title={reservation.proxyReservationInfo?.name}/>
            </>
        ):(
            <EvixCardHeader title={reservation.patient.name}/>
        )}

      <ODVSpace h={24}/>
      <ContentList>
          {/*<EvixCardRow>*/}
          {/*    <EvixCardLabel>환자이름</EvixCardLabel>*/}
          {/*    <EvixCardRowContent>{reservation.patient.name}</EvixCardRowContent>*/}
          {/*</EvixCardRow>*/}
          {reservation.proxyReservation && (
              <EvixCardRow>
              <EvixCardLabel>신청자</EvixCardLabel>
              <EvixCardRowContent>{reservation.patient.name}</EvixCardRowContent>
              </EvixCardRow>
          )}
          <EvixCardRow>
              <EvixCardLabel>진료과목</EvixCardLabel>
              <EvixCardRowContent>{reservation.medicalSubject.name}</EvixCardRowContent>
          </EvixCardRow>
          <EvixCardRow>
              <EvixCardLabel>진료일시</EvixCardLabel>
              <EvixCardRowContent>{moment(reservation.reservationTime).format('YYYY.MM.DD (ddd) / HH:mm')}</EvixCardRowContent>
          </EvixCardRow>
          <EvixCardRow>
              <EvixCardLabel>증상</EvixCardLabel>
              <EvixCardRowContent>{reservation.symptom}</EvixCardRowContent>
          </EvixCardRow>
          <EvixCardRow style={{ flexDirection: 'row-reverse' }}>
              <Link style={{ textDecoration: 'none' }} to={SiteUrls.Doctor.Reservation.Detail(reservation.reservationId)}>
                  <More>
                      상세정보 조회
                      <IconWrapper>
                          <ODIcon icon={ODIcons.MaterialChevronRight} />
                      </IconWrapper>
                  </More>
              </Link>
          </EvixCardRow>
      </ContentList>

    </EvixCardOneThirds>
  )
}

const More = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 5px;
  color: var(--mpPrimary500);
  cursor: pointer;
  font-weight: 500;
`

const IconWrapper = styled.div`
  margin-top: -1px;
  transform: scale(0.8);
`

const ContentList = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
`