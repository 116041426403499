import { ODIcon, ODIcons } from '@mdpp/od-react-belt'
import React from 'react'

interface IEvixButtonTextWithIconProps {
  icon: ODIcons
  text: string
  iconTopOffset?: number
}

export const EvixButtonTextWithIcon: React.FC<IEvixButtonTextWithIconProps &
  React.HTMLAttributes<HTMLDivElement>> = props => {
  const { icon, text, iconTopOffset, ...divProps } = props
  return (
    <div {...divProps}>
      <ODIcon icon={icon} style={{ top: 1 + (iconTopOffset ?? 0), marginRight: 14, position: 'relative' }} />
      {text}
    </div>
  )
}
