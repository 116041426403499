import {
  BlockingLoadBox,
  ODButton,
  ODButtonSize,
  ODButtonTheme,
  ODListablePaginatedTable2,
  ODListablePagination,
  ODListableSearchBox,
  useCounter,
  useModalSelect,
  useODListableContext,
  useWrappedAPI,
} from '@mdpp/od-react-belt'
import React from 'react'
import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components/macro'
import {
  GQLDOCTOR_STATUS,
  GQLPharmacist,
  GQLWaitingDoctor,
  GQLWaitingPharmacist,
} from '@mdpp/common/lib/@types/server.schema'
import { SiteUrls } from '../../../SiteUrls'
import { WebUtils } from '../../../utils/webUtils'
import { useCommonAPIs } from '../../hooks/useCommonAPIs'
import { WaitingPharmacyPrimaryKey, IPharmacistListableOption } from '../WaitingPharmacyCommon'
import { EvixCard } from '../../card/EvixCard'
import { PageContent } from '../../page/Page'
import IconMemo from '../../../assets/common/icon-memo.svg'
import { TooltipWrap } from '../../style/ToolTipStyle'
import ReactTooltip from 'react-tooltip'
import { COLORS } from '../../../styles/colors'
import { IsBtnConfirmModal } from '../../layout/IsBtnConfirmModal'
import { ConfirmModal } from '../../layout/ConfirmModal'
import IconWarningBlue from '../../../assets/common/warning-blue-super.svg'
import IconWarningRed from '../../../assets/common/warning-red-super.svg'
import DeleteIcon from '../../../assets/common/icon-del-small.svg'
import { HoldModal } from '../../doctor/list/HoldModal'
import { useIsOpenModal } from '../../doctor/list/IsOpenModal'

import moment from 'moment'
import { useAdminAppContext } from '../../../admin/context/AdminAppContext'
import { useWaitingPharmacyListDataLoader } from './WaitingPharmacyListDataLoader'

interface IPharmaListableTableProps {
  fromHospitalManager: boolean
}

export const WaitingPharmacyListableTable: React.FC<IPharmaListableTableProps> = props => {
  const { fromHospitalManager } = props
  const { Provider, Context } = useODListableContext<GQLWaitingPharmacist, IPharmacistListableOption>()
  const [loading, setLoading] = React.useState(false)
  const [token, refresh] = useCounter()
  const dataLoader = useWaitingPharmacyListDataLoader()
  const [showApproveModal, setShowApproveModal] = React.useState(false)
  const [showHoldModal, setShowHoldModal] = React.useState(false)
  const [showMemoModal, setShowMemoModal] = React.useState(false)
  const [showMemoTooltip, setShowMemoTooltip] = React.useState(false)
  const [showDeleteInfoModal, setShowDeleteInfoModal] = React.useState(false)
  const [showIsOpenModal, setShowIsOpenModal] = React.useState(false)
  const [selectedWaitingPharmacist, setSelectedWaitingPharmacist] = React.useState<GQLWaitingPharmacist | null>(null)

  const toolbarStyle = { display: 'flex', justifyContent: 'space-between', marginBottom: 15 }
  const searchBoxStyle = { flex: 1 }
  const wrapperStyle = { display: 'flex', alignItems: 'center', justifyContent: 'center' }

  const {
    approveWaitingPharmacistByAdmin,
    holdWaitingPharmacistByAdmin,
    updatePharmacist,
    deleteWaitingPharmacist,
  } = useCommonAPIs()
  const { refreshSidebar } = useAdminAppContext()

  const approveWaitingPharmacist = useWrappedAPI(
    (input: { id: number }) =>
      approveWaitingPharmacistByAdmin({
        id: input.id,
      }),
    loading,
    setLoading,
    {
      successMessage: '약국 가입이 승인되었습니다.',
      onSuccess: refresh,
    }
  )

  const holdWaitingPharmacist = useWrappedAPI(
    (input: { id: number; reason: string }) =>
      holdWaitingPharmacistByAdmin({
        id: input.id,
        reason: input.reason,
      }),
    loading,
    setLoading,
    {
      successMessage: '약국 가입이 보류되었습니다.',
      onSuccess: refresh,
    }
  )

  const deleteWaitPharmacyAdmin = useWrappedAPI(
    (input: { id: number }) =>
      deleteWaitingPharmacist({
        id: input.id,
      }),
    loading,
    setLoading,
    {
      successMessage: '승인 요청 정보가 삭제되었습니다.',
      onSuccess: refresh,
    }
  )

  const updateWaitingPharmacist = useWrappedAPI(
    (input: { id: number; holdReason: string }) =>
      holdWaitingPharmacistByAdmin({
        id: input.id,
        reason: input.holdReason,
      }),
    loading,
    setLoading,
    {
      successMessage: '약국 가입 보류사유가 변경되었습니다.',
      onSuccess: refresh,
    }
  )

  const onClickApprove = (pharmacist: GQLWaitingPharmacist) => {
    setSelectedWaitingPharmacist(pharmacist)
    setShowApproveModal(true)
  }

  const handleApprove = async () => {
    setShowApproveModal(false)
    if (selectedWaitingPharmacist) {
      try {
        const r = await approveWaitingPharmacist({ id: selectedWaitingPharmacist?.waitingPharmacistId })
        refreshSidebar()
      } catch (e) {}
    }
  }

  const onClickHold = async () => {
    setShowApproveModal(false)
    if (!selectedWaitingPharmacist?.isHold) {
      setShowHoldModal(true)
    }
  }

  const handleHold = async (reason: string) => {
    setShowHoldModal(false)
    if (selectedWaitingPharmacist) {
      try {
        const r = await holdWaitingPharmacist({ id: selectedWaitingPharmacist.waitingPharmacistId, reason: reason })
        refreshSidebar()
      } catch (e) {}
    }
  }

  const onClickEditMemo = async (pharmacist: GQLWaitingPharmacist) => {
    setSelectedWaitingPharmacist(pharmacist)
    setShowMemoModal(true)
  }

  const handleEditMemo = async (reason: string) => {
    setShowMemoModal(false)
    if (selectedWaitingPharmacist) {
      try {
        const r = await updateWaitingPharmacist({
          id: selectedWaitingPharmacist.waitingPharmacistId,
          holdReason: reason,
        })
      } catch (e) {}
    }
  }

  const onDelete = async (pharmacist: GQLWaitingPharmacist) => {
    setSelectedWaitingPharmacist(pharmacist)
    setShowDeleteInfoModal(true)
  }

  const handleDeleteWaitingPharmacist = async () => {
    setShowDeleteInfoModal(false)
    if (selectedWaitingPharmacist) {
      try {
        const r = await deleteWaitPharmacyAdmin({ id: selectedWaitingPharmacist?.waitingPharmacistId })
        refreshSidebar()
      } catch (e) {}
    }
  }

  const chooseOptions = [
    {
      title: '거절',
      description: '',
      value: GQLDOCTOR_STATUS.REJECTED,
    },
    fromHospitalManager
      ? null
      : {
          title: '병원관리자 승인 대기',
          description: '',
          value: GQLDOCTOR_STATUS.PENDING,
        },
    {
      title: '약국',
      description: '',
      value: GQLDOCTOR_STATUS.NORMAL,
    },
  ].filter(v => v !== null)

  const { Component: StatusChooseComponent, choose: chooseStatus, props: statusChooseComponentProps } = useModalSelect({
    okTitle: '저장',
    // @ts-ignore
    selects: chooseOptions,
    title: '약국 권한',
  })

  const { Component: HiddenChooseComponent, choose: chooseHidden, props: hiddenChooseComponentProps } = useModalSelect({
    okTitle: '저장',
    selects: [
      {
        title: '공개',
        description: '',
        value: false,
      },
      {
        title: '비공개',
        description: '',
        value: true,
      },
    ],
    title: '공개 여부',
  })

  const { Component: IsOpenModalComponent, choose: chooseOpen, props: isOpenModalComponentProps } = useIsOpenModal({
    style: { width: 420, height: 466 },
  })

  return (
    <>
      {showApproveModal && (
        <IsBtnConfirmModal
          style={{ width: 420, height: 270 }}
          isTitle={true}
          title={'가입을 승인하시겠습니까?'}
          titleIcon={IconWarningBlue}
          titleIconSize={'L'}
          isDescriptionIcon={false}
          description={[(selectedWaitingPharmacist?.name || '') + ' / ' + selectedWaitingPharmacist?.pharmacyName]}
          descriptionIcon={''}
          hideCancel={false}
          BtnLeft={'보류'}
          BtnRight={'승인'}
          onLeftBtnPress={onClickHold}
          onRightBtnPress={handleApprove}
          onCancelPress={() => setShowApproveModal(false)}
        />
      )}

      {/* 메모 버튼 클릭 시 실행되는 팝업  */}
      {showMemoModal && (
        <>
          <HoldModal
            style={{ width: 420, height: 312 }}
            BtnText={'저장'}
            isEdit={true}
            initValue={selectedWaitingPharmacist?.holdReason || ''}
            onOKPress={(reason: string) => handleEditMemo(reason)}
            onCancelPress={() => setShowMemoModal(false)}
          />
        </>
      )}

      {/* 보류 버튼 클릭 시 실행되는 팝업  */}
      {showHoldModal && (
        <>
          <HoldModal
            style={{ width: 420, height: 312 }}
            BtnText={'확인'}
            isEdit={true}
            onOKPress={(reason: string) => handleHold(reason)}
            onCancelPress={() => setShowHoldModal(false)}
          />
        </>
      )}

      {/* 삭제 버튼 클릭 시 실행되는 팝업  */}
      {showDeleteInfoModal && (
        <>
          <ConfirmModal
            style={{ width: 420, height: 270 }}
            isTitle={true}
            title={'회원 정보를 삭제 하시겠습니까?'}
            titleIcon={IconWarningRed}
            titleIconSize={'L'}
            isDescriptionIcon={false}
            description={[selectedWaitingPharmacist?.name + '/' + selectedWaitingPharmacist?.pharmacyName]}
            descriptionIcon={''}
            hideCancel={true}
            onOkPress={handleDeleteWaitingPharmacist}
            onCancelPress={() => setShowDeleteInfoModal(false)}
          />
        </>
      )}

      <Provider
        dataLoader={dataLoader}
        keyExtractor={v => v[WaitingPharmacyPrimaryKey].toString()}
        pageSize={10}
        refreshToken={token.toString()}
        onDataLoaderError={WebUtils.showError}
        searchOnLoad
      >
        <BlockingLoadBox show={loading} />
        <StatusChooseComponent {...statusChooseComponentProps} />
        <HiddenChooseComponent {...hiddenChooseComponentProps} />
        <IsOpenModalComponent {...isOpenModalComponentProps} />

        <SearchWrapper style={toolbarStyle}>
          <ODListableSearchBox listableContext={Context} placeholder="약사이름, 약국으로 검색" style={searchBoxStyle} />
        </SearchWrapper>
        <PageContent>
          <EvixCard noPadding>
            <ODListablePaginatedTable2
              numColumns={fromHospitalManager ? 5 : 6}
              listableContext={Context}
              renderHeader={() => (
                <tr>
                  <th style={{ width: 120, whiteSpace: 'nowrap', textAlign: 'center' }}>약사 이름</th>
                  {!fromHospitalManager && (
                    <th style={{ width: 150, whiteSpace: 'nowrap', textAlign: 'center' }}>약국</th>
                  )}
                  <th style={{ width: 120, whiteSpace: 'nowrap', textAlign: 'center' }}>가입요청 날짜</th>
                  {!fromHospitalManager && (
                    <th style={{ width: 115, whiteSpace: 'nowrap', textAlign: 'center' }}>가입승인</th>
                  )}
                </tr>
              )}
              renderRow={(value: GQLWaitingPharmacist, context) => (
                <tr key={value[WaitingPharmacyPrimaryKey]} style={{ borderBottom: '1px solid #D1D5DA' }}>
                  <td align="center" style={{ whiteSpace: 'nowrap' }}>
                    <Link
                      to={
                        fromHospitalManager
                          ? SiteUrls.Manager.Pharmacy.Edit(value.waitingPharmacistId)
                          : SiteUrls.Admin.WatingPharmacy.Edit(value.waitingPharmacistId)
                      }
                    >
                      <BlueClickableText>{value.name}</BlueClickableText>
                    </Link>
                  </td>
                  {!fromHospitalManager && (
                    <td align="center" style={{ whiteSpace: 'nowrap' }}>
                      {value.pharmacyName}
                    </td>
                  )}
                  {!fromHospitalManager && (
                    <td align="center" style={{ whiteSpace: 'nowrap' }}>
                      {moment(value.emailAuthTime).format('YYYY-MM-DD')}
                    </td>
                  )}

                  {!fromHospitalManager && (
                    <td align="center" style={{ whiteSpace: 'nowrap' }}>
                      {/* 승인하기 버튼 */}
                      {!value.isHold && (
                        <BtnWrap>
                          <Btn onClick={() => onClickApprove(value)}>
                            <span>승인하기</span>
                          </Btn>
                          <IconDelete src={DeleteIcon} onClick={() => onDelete(value)} />
                        </BtnWrap>
                      )}

                      {/* 완료 텍스트*/}
                      {/* <label style={LabelCompleteText}>완료</label> */}

                      {/* 메모 아이콘과 보류 텍스트 */}
                      {value.isHold && (
                        <>
                          <BtnWrap>
                            <LabelHold>
                              <a
                                data-tip
                                data-for="memo"
                                onClick={() => onClickEditMemo(value)}
                                onMouseOver={() => {
                                  setShowMemoTooltip(true)
                                }}
                                onMouseOut={() => {
                                  setShowMemoTooltip(false)
                                }}
                              >
                                <img src={IconMemo} alt="보류 아이콘" />
                              </a>
                              <span onClick={() => onClickApprove(value)}>보류</span>
                            </LabelHold>
                            <IconDelete src={DeleteIcon} onClick={() => onDelete(value)} />
                          </BtnWrap>
                        </>
                      )}
                      {/* 메모 아이콘 마우스 오버 시, 툴팁 */}
                      {showMemoTooltip && (
                        <TooltipWrap>
                          <ReactTooltip
                            id="memo"
                            type="info"
                            place="left"
                            className="custom-color"
                            border
                            backgroundColor="#ffffff"
                            borderColor="#a1a8ae"
                            arrowColor="#ffffff"
                          >
                            <span>{value.holdReason}</span>
                          </ReactTooltip>
                        </TooltipWrap>
                      )}
                    </td>
                  )}
                </tr>
              )}
              eventParentContext={{}}
            />
          </EvixCard>

          <ODListablePagination hideIfSinglePage={false} listableContext={Context} wrapperStyle={wrapperStyle} />
        </PageContent>
      </Provider>
    </>
  )
}

const BlueClickableText = styled.span`
  color: ${COLORS.blue200};
  cursor: pointer;
  font-weight: 500;
  text-decoration: underline;
`
const SearchWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-bottom: 24px;
  > div > form > div {
    > div {
      border-radius: 8px;

      > div {
        border-radius: 8px;
        background-color: white;
        > span {
          border-radius: 8px;
        }
      }

      > input {
        border-radius: 0px 8px 8px 0px;
      }
    }

    @media (max-width: 992px) {
      width: 290px;
    }
  }
`
const BtnWrap = styled.div`
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
`

const IconDelete = styled.img`
  width: 22px;
  height: 22px;
  border-radius: 4px;
  border: solid 1px ${COLORS.GRAY42};
  overflow: hidden;
  cursor: pointer;
`

const Btn = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 22px;
  border-radius: 4px;
  border: solid 1px ${COLORS.GRAY42};
  text-align: center;

  > span {
    font-size: 13px;
    font-weight: 500;
    color: ${COLORS.GRAY42};
    line-height: 1;
  }
`
const LabelCompleteText = {
  fontSize: 13,
  fontWeight: 500,
  color: '#ababab',
  lineHeight: 1,
}

const LabelHold = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 5px;
  box-shadow: none;

  > a {
    width: 17px;
    height: 21px;
    border: none;
    outline: none;

    > img {
      width: 17px;
      height: 21px;
    }
  }

  > span {
    display: inline-block;
    font-size: 13px;
    font-weight: 500;
    color: ${COLORS.GRAY42};
    line-height: 13px;
    text-decoration: underline;
    margin-top: -2px;
  }
`

interface IconBlockProps {
  children: any
  type: string
  onClick: () => {}
}

const IconBlockWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 3px;
`

const IconBlock: React.FC<IconBlockProps> = props => {
  const { children, type, ...rest } = props
  return (
    <Icon type={type} {...rest}>
      {children}
    </Icon>
  )
}

const iconStyles = css<IconBlockProps>`
  ${props =>
    props.type === 'smart' &&
    css`
      border: 1px solid ${COLORS.blue200};

      > span {
        color: ${COLORS.blue200};
      }
    `}

  ${props =>
    props.type === 'non-face' &&
    css`
      border: 1px solid ${COLORS.red100};

      > span {
        color: ${COLORS.red100};
      }
    `}

    ${props =>
    props.type === 'private' &&
    css`
      background-color: #efefef;
      border: 1px solid #c5c5c5;

      > span {
        color: #c5c5c5;
      }
    `}
`

const Icon = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 21px;
  margin: 0 1.5;
  border-radius: 11px;
  cursor: pointer;

  > span {
    font-size: 12px;
    line-height: 1.08;
    font-weight: 500;
  }

  ${iconStyles}
`
