import React from 'react'
import { GQLReservation } from '@mdpp/common/lib/@types/server.schema'
import { ReservationBasicInfoBoxStyle } from '../../../../common/reservation/ReservationCommon'
import { PatientBodyInfoCard } from '../../detail/infoCard/PatientBodyInfoCard'
import { PatientEtcInfoBox } from '../../detail/infoCard/PatientEtcInfoBox'
import { PatientHealthInfoCard } from '../../detail/infoCard/PatientHealthInfoCard'
import { PatientHistoryInfoCard } from '../../detail/infoCard/PatientHistoryInfoCard'
import { PatientInfoCard } from '../../detail/infoCard/PatientInfoCard'
import { PatientMedicationSurgeryHistoryInfoBox } from '../../detail/infoCard/PatientMedicationSurgeryHistoryInfoBox'
import { PatientSmokeDrinkInfoCard } from '../../detail/infoCard/PatientSmokeDrinkInfoCard'
import { RoomSectionDivider } from '../RoomCommon'

interface IRoomInfoSectionProps {
  reservation: GQLReservation
  isFinishedMedical?: boolean
}

export const RoomInfoSection: React.FC<IRoomInfoSectionProps> = props => {
  const { reservation, isFinishedMedical } = props
  return (
    <div>
      {/* <ReservationBasicInfoBox reservation={reservation} infoStyle={ReservationBasicInfoBoxStyle.InsideRoom} /> */}
      <PatientHealthInfoCard
        patient={reservation.patient}
        infoStyle={ReservationBasicInfoBoxStyle.InsideRoom}
        isFinishedMedical
      />
      {!isFinishedMedical && <RoomSectionDivider style={{margin: '0 20px'}}/>}
      <PatientBodyInfoCard
        patient={reservation.patient}
        infoStyle={ReservationBasicInfoBoxStyle.InsideRoom}
        isFinishedMedical
      />
      {/* {!isFinishedMedical && <RoomSectionDivider />}
      <PatientHistoryInfoCard
        patient={reservation.patient}
        infoStyle={ReservationBasicInfoBoxStyle.InsideRoom}
        isFinishedMedical
      />
      {!isFinishedMedical && <RoomSectionDivider />}
      <PatientSmokeDrinkInfoCard
        patient={reservation.patient}
        infoStyle={ReservationBasicInfoBoxStyle.InsideRoom}
        isFinishedMedical
      />
      {!isFinishedMedical && <RoomSectionDivider />}
      <PatientEtcInfoBox
        patient={reservation.patient}
        infoStyle={ReservationBasicInfoBoxStyle.InsideRoom}
        isFinishedMedical
      /> */}
    </div>
  )
}
