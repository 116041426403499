import styled from "styled-components/macro";
import {COLORS} from '../../styles/colors'
import Check from '../../assets/common/icon-check.png'
import CheckOn from '../../assets/common/icon-check-on.png'
import CheckCircle from '../../assets/common/icon-select-off.svg'
import CheckCircleOn from '../../assets/common/icon-select-on.svg'

// 로그인 관련 스타일
export const LoginWrap = styled.div`
  width: 440px;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
`

export const LogoStyle = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: flex-end;
	margin-left: 50px;
	margin-bottom: 30px; 
	gap: 9px;
`
export const Logo = {width: 288, height: 50 }
export const Logo2 = {width: 298, height: 50 }

export const LoginInner = styled.div`
	width: 100%;
  min-height: 394px;
  padding: 37px 50px 50px 50px;
  border-radius: 6px;
  border: solid 1px ${COLORS.GRAYD5};
`
export const LoginTitle = styled.div`
  font-size: 26px;
  font-weight: bold;
  margin-bottom: 10px;
  color: ${COLORS.GRAY42};
`

export const MarketingLine = styled.div`
	font-size: 15px;
	font-weight: 500;
  text-align: left;
  letter-spacing: -.3px;
  color: ${COLORS.GRAY42};
`

export const InfoMessageWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 0 0 36px 0;
`
export const MessageTextWrap = styled.div`
  font-size: 15px;
  font-weight: 500;
`

export const MessageText1 = styled.div`
  font-size: 15px;
  font-weight: 500;
  line-height: 1.33;
  color: ${COLORS.blue200};
`

export const MessageText2 = styled.div`
  font-size: 13px;
  font-weight: 500;
  line-height: 1.54;
  letter-spacing: -0.36px;
  color: ${COLORS.blue200};
`

// message icon + text
export const MessageWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`

export const MessageIcon = styled.img`
  display: inline-block;
  width: 14px;
  height: 14px;
  object-fit: contain;
`


// error Text
export const MessageText3 = styled.div`
  font-size: 15px;
  font-weight: 500;
  line-height: 1.73;
  color: ${COLORS.red100};
`

// 결과가 있을 경우, input style
export const EvixInputTextWrap = styled.div`
  position: relative;

  &.result-input {
    .od-input-wrapper {
      border-color: #f8d8c8 !important;

      .od-input  {
        &.children {
          background-color: #fff2eb !important;
          border-color: #f8d8c8 !important;
          padding-left: 38px;
        }
      }
    }
  }
`

export const InputCheckboxSupportWrapper = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
  font-size: 13px; 
  font-weight: 500;
	color: ${COLORS.GRAY7A};

	> input[type='checkbox'], 
  > input[type='radio']{
		display: none;
	}
  
	> input[type='checkbox'] + label,
  > input[type='radio'] + label  {
		position: relative;
		padding-left: 20px;
		margin-bottom: 0;
	}

	> input[type='checkbox'] + label::after,
  > input[type='radio'] + label::after {
		position: absolute;
		left: 0;
		top: 50%;
    margin-top: -7px;
		width: 17px;
		height: 16px;
		content:'';
		background: url(${Check}) left top no-repeat;
		background-size: 17px 16px;
		
	}

	> input[type='checkbox']:checked + label::after,
  > input[type='radio']:checked + label::after {
		background: url(${CheckOn}) left top no-repeat;
		background-size: 17px 16px;
	}

  &.termText {
    font-size: 15px; 
    color: ${COLORS.GRAY42};
  }
`

export const InputCircleCheckboxSupportWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  > span {
    display: inline-block;
    width: 19px;
		height: 18px;
    background: url(${CheckCircle}) left top no-repeat;
		background-size: 19px 18px;

    &.selected {
      background: url(${CheckCircleOn}) left top no-repeat;
    }
  }
`

// Switch
export const SwitchLabel = styled.label`
  font-size: 15px;
  font-weight: 500;
  color: #424242;
  padding-right: 11px;
  margin-top: -8px;
`

export const SwitchWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 6px;

  .react-toggle {
    .react-toggle-track {
      width: 82px;
      height: 40px;
      background-color: #c8cdd4 !important;

      ~ .react-toggle-thumb {
        width: 34px;
        height: 34px;
        left: 3px;
        top: 3px;
        border: 0 !important;
        box-shadow: none !important;
      }
    }

    &.react-toggle--checked {
      .react-toggle-track {
        background-color: #2f7dff !important;

        ~ .react-toggle-thumb {
          left: 45px;
        }
      }
    }
  }
`

export const ToggleLabel = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;

  > span {
    padding: 3px 3px 0 3px;
    font-size: 12px;
    font-weight: 500;
    color: #909ca7;
  }
`


export const PagingWrap = styled.div`
  flex: 1;

  &.hospital-data-paging {
    .page-item {
      .page-btn {
        border: 1px solid ${COLORS.GRAYE0} !important;
        color: ${COLORS.GRAYAB} !important;
        background-color: ${COLORS.WHITE} !important;

        &.active, &:hover, &:focus {
          border: solid 1px #878a8f !important;
          color: ${COLORS.GRAY42} !important;
          background-color: ${COLORS.WHITE} !important;
        }
      }
    }
  }
`

export const TitleWrap = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
`

export const SubTitle = styled.div`
  font-size: 18px;
  font-weight: 700;
`

export const SubTitleMessage = styled.div`
  display: inline-block;  
  font-size: 13px;
  line-height: 1.17;
  color: #0080ec;
  padding-left: 10px;
  font-weight: 500;
`

export const ColumnWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center; 
`

export const RowWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start; 
`