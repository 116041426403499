import {
  BlockingLoadBox,
  ODButton,
  ODButtonSize,
  ODButtonTheme,
  ODIcon,
  ODIcons,
  ODListablePaginatedTable2,
  ODListablePagination,
  ODListableSearchBox,
  ODVSpace,
  useCounter,
  useODListableContext, 
  useODModalConfirm,
} from '@mdpp/od-react-belt'
import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components/macro'
import { GQLHospital } from '@mdpp/common/lib/@types/server.schema'
import { useAdminAPIs } from '../../../admin/context/useAdminAPIs'
import { SiteUrls } from '../../../SiteUrls'
import { COLORS } from '../../../styles/colors'
import { WebUtils } from '../../../utils/webUtils'
import { HospitalPrimaryKey, IHospitalListableOption } from '../HospitalCommon'
import { useHospitalListDataLoader } from './HospitalListDataLoader'
import { EvixCard } from '../../card/EvixCard'
import { PageContent } from '../../page/Page'

interface IHospitalListableTableProps {
  fromEvixAdmin: boolean
  fromManager?: boolean
}

export const HospitalListableTable: React.FC<IHospitalListableTableProps> = props => {
  const { fromEvixAdmin, fromManager = false } = props
  const { Provider, Context } = useODListableContext<GQLHospital, IHospitalListableOption>()
  const [loading, setLoading] = React.useState(false)
  const [token, refresh] = useCounter()
  const dataLoader = useHospitalListDataLoader(fromEvixAdmin)
  const { updateHospital } = useAdminAPIs()
  const showSearch = fromEvixAdmin

  const { Component: Confirm, props: confirmProps, confirm } = useODModalConfirm({})

  const searchBoxStyle = { flex: 1,}
  const wrapperStyle = { display: 'flex', alignItems: 'center', justifyContent: 'center' }
  const toolbarStyle = { display: 'flex', justifyContent: 'space-between', marginBottom: 15 }
  
  const handleSetHidden = async (v: GQLHospital, setHidden: boolean) => {
    if (loading) {
      return
    }

    if (
      !(await confirm({
        title: setHidden ? '병원 숨기기' : '병원 보이기',
        message: setHidden ? '앱에서 병원을 숨기겠습니까?' : '앱에서 병원을 보이겠습니까?',
        yes: '변경',
        no: '취소',
      }))
    ) {
      return
    }

    try {
      setLoading(true)
      await updateHospital({ id: v.hospitalId, isHidden: setHidden })
      refresh()
    } catch (ex) {
      WebUtils.showError(ex)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Provider
      dataLoader={dataLoader}
      keyExtractor={v => v[HospitalPrimaryKey].toString()}
      pageSize={10}
      refreshToken={token.toString()}
      onDataLoaderError={WebUtils.showError}
      searchOnLoad
    >
      <BlockingLoadBox show={loading} />
      <Confirm {...confirmProps} />

        {showSearch && (
          <SearchWrapper style={toolbarStyle}>
            <ODListableSearchBox listableContext={Context} placeholder="병원명, 주소로 검색" style={searchBoxStyle} />
            <Link to={SiteUrls.Admin.Hospital.Create} style={{ textDecoration: 'none' }}>
              <ODButton theme={ODButtonTheme.Primary} size={ODButtonSize.Small} rounded style={{ minWidth: 100 }}>
                병원 추가
              </ODButton>
            </Link>
          </SearchWrapper>
          )}
          <PageContent>
              {/* table */}
            <EvixCard noPadding>
              <ODListablePaginatedTable2
                numColumns={fromEvixAdmin ? 7 : 5}
                listableContext={Context}
                renderHeader={() => (
                  <tr style={{ borderBottom: '1px solid #D1D5DA', textAlign: 'center' }}>
                    <th>병원명</th>
                    <th>주소</th>
                    <th>우편번호</th>
                    <th>의사</th>
                    {/* [MWH] 병원관리자 컬럼 추가 코드로 우선 주석처리
                    <th>병원 관리자</th> */}
                    {fromEvixAdmin && <th>관리자</th>}
                    <th>약국</th>
                    {fromEvixAdmin && <th>노출여부</th>}
                  </tr>
                )}
                renderRow={(value: GQLHospital, context) => (
                  <StyledTr key={value[HospitalPrimaryKey]} style={{borderBottom: '1px solid #D1D5DA'}}>
                    <td style={{ whiteSpace: 'normal', wordBreak: 'break-all', maxWidth: 250 }}>
                      <Link
                        to={fromEvixAdmin ? SiteUrls.Admin.Hospital.Edit(value.hospitalId) : SiteUrls.Manager.Hospital.Edit}
                      >
                        {value.name}
                      </Link>
                    </td>
                    <td>{value.address}</td>
                    <td align='center'>{value.zipCode}</td>
                    <td align='center'>{value.numDoctors}</td>
                    {/* [MWH] 병원관리자 컬럼 추가 코드로 우선 주석처리
                    <td align='center'>
                      <Link to={}>
                        <span style={{ color: 'black' }}>1</span>{' '}
                        <IconWrapper>
                          <ODIcon icon={ODIcons.CorePencil} />
                        </IconWrapper>
                      </Link>
                    </td>
                    */}
                    {fromEvixAdmin && (
                      <td align='center'>
                        <Link to={SiteUrls.Admin.Hospital.Manager.List(value.hospitalId)}>
                          <span style={{ color: 'black' }}>{value.numAdmins}</span>{' '}
                          <IconWrapper>
                            <ODIcon icon={ODIcons.CorePencil} />
                          </IconWrapper>
                        </Link>
                      </td>
                    )}
                    <td align='center'>
                      <Link
                        to={
                          fromEvixAdmin
                            ? SiteUrls.Admin.Hospital.Pharmacy(value.hospitalId)
                            : SiteUrls.Manager.Hospital.Pharmacy
                        }
                      >
                        <span style={{ color: 'black' }}>{value.numPharmacy}</span>{' '}
                        <IconWrapper>
                          <ODIcon icon={ODIcons.CorePencil} />
                        </IconWrapper>
                      </Link>
                    </td>
                    {fromEvixAdmin && (
                      <td align='center'>
                        {value.isHidden ? (
                          <span
                            onClick={() => handleSetHidden(value, false)}
                            style={{ cursor: 'pointer', color: COLORS.DANGER }}
                          >
                            숨김
                          </span>
                        ) : (
                          <span
                            onClick={() => handleSetHidden(value, true)}
                            style={{ cursor: 'pointer', color: COLORS.mpPrimary500 }}
                          >
                            보임
                          </span>
                        )}
                      </td>
                    )}
                  </StyledTr>
                )}
                eventParentContext={{}}
              />
            </EvixCard>
            {/* table pagination */}
            <ODListablePagination hideIfSinglePage={!fromEvixAdmin} listableContext={Context} wrapperStyle={wrapperStyle} />
          </PageContent>

    </Provider>
  )
}

const IconWrapper = styled.span`
  font-size: 14px;
  letter-spacing: 0.3px;
  color: var(--gray600);
`
const SearchWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  margin-bottom: 24px;
  
  > div > form > div {
    > div {
      border-radius: 8px;

      > div {
        border-radius: 8px;
        background-color: white;
        > span {
          border-radius: 8px;
        }
      }

      > input {
        border-radius: 0px 8px 8px 0px;
      }
    }

    @media (max-width: 992px) {
      width: 290px;
    }
  }
`

const StyledTr = styled.tr`
  cursor: pointer;
`
