import { ODListableResponseType } from '@mdpp/od-react-belt'
import React from 'react'
import { GQLHospital } from '@mdpp/common/lib/@types/server.schema'
import { useCommonAPIs } from '../../hooks/useCommonAPIs'
import { IHospitalListableOption } from '../HospitalCommon'

/**
 * 슈퍼관리자의 경우 목록을, 아닌 경우 병원관리자 자신의 정보만 반환한다.
 */
export function useHospitalListDataLoader(fromEvixAdmin: boolean) {
  const { listHospital, getHospitalOfManager } = useCommonAPIs()
  const single = React.useCallback(
    async function dataLoader(
      page: number,
      pageSize: number,
      afterKey: string | null,
      options: IHospitalListableOption
    ): Promise<ODListableResponseType<GQLHospital>> {
      const r = await getHospitalOfManager()
      return {
        list: [r],
        totalCount: 1,
        page,
        pageSize,
      }
    },
    [getHospitalOfManager]
  )
  const list = React.useCallback(
    async function dataLoader(
      page: number,
      pageSize: number,
      afterKey: string | null,
      options: IHospitalListableOption
    ): Promise<ODListableResponseType<GQLHospital>> {
      const r = await listHospital({
        page,
        pageSize,
        ...options,
      })
      return r as ODListableResponseType<GQLHospital>
    },
    [listHospital]
  )

  return fromEvixAdmin ? list : single
}
