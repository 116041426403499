import React from "react";
import { ODListableContextType } from "@mdpp/od-react-belt";
import { IReservationListableOption } from "../../common/reservation/ReservationCommon";
import { GQLReservation } from "@mdpp/common/lib/@types/server.schema";
import { useSSE } from "react-hooks-sse";
import { ReservationStatusEvent } from "@mdpp/common/lib/sse/common";

interface IDoctorSSEReceiverProps {
  context: React.Context<ODListableContextType<GQLReservation, IReservationListableOption>>
}

export const DoctorSSEReceiver: React.FC<IDoctorSSEReceiverProps> = props => {
  const { refresh }: ODListableContextType<GQLReservation, IReservationListableOption> = React.useContext(props.context)
  useSSE<ReservationStatusEvent>(
    'reservationStateChanged',
    { reservationId: null, status: null },
    {
      parser(input) {
        const event = JSON.parse(input)
        refresh()
        return event
      },
    }
  );
  return (
    <></>
  )
}