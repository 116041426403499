/**
 * ODListablePagination 은 ODListablePaginatedTable 에서 사용하기 위한 컴포넌트이다.
 * 향후 다양한 기능을 추가할 가능성이 있으며 디자인 등의 이유로 교체도 가능하므로 독자 컴포넌트로
 * 만들었다.
 */
import { range } from 'lodash'
import React from 'react'
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap'
import styled from 'styled-components'
import { useGlobalContext } from '@mdpp/od-react-belt/lib/components/global'
import {COLORS} from '../../../styles/colors'
import IconPrev from '../../../assets/common/page-before.svg'
import IconPrevOver from '../../../assets/common/page-before-over.svg'
import IconNext from '../../../assets/common/page-next.svg'
import IconNextOver from '../../../assets/common/page-next-over.svg'

interface HospitalDataPagenationProps {
  currentPage: number
  totalCount: number
  pageSize: number
  onPageSelected: (ind: number) => void
  pageSpan?: number
  hideIfSinglePage?: boolean
  wrapperStyle?: object
  itemActiveStyle?: object
  itemDeactiveStyle?: object
}

export default function HospitalDataPagination(props: HospitalDataPagenationProps) {
  const {
    pageSpan = 3,
    hideIfSinglePage = true,
    wrapperStyle,
    // itemActiveStyle,
    // itemDeactiveStyle,
    totalCount,
    pageSize,
    currentPage,
    onPageSelected
  } = props

  const { convertThemeString } = useGlobalContext()

  let maxPage = Math.ceil(totalCount / pageSize)
  maxPage = maxPage < 1 ? 1 : maxPage

  const isDataMissingOrLoading = totalCount === -1 || currentPage < 1
  if ((hideIfSinglePage && maxPage <= 1) || isDataMissingOrLoading) {
    return null
  }

  if (currentPage > maxPage) {
    onPageSelected(maxPage)
  }

  const paginationItems = range(currentPage - pageSpan, currentPage + pageSpan + 1)
    .filter((i) => (i > 0 && i <= maxPage) || i === 1)
    .map((page) => {
      return (
        <PaginationItem
          key={page}
          active={page === currentPage}
          onClick={() => page !== currentPage && onPageSelected(page)}
        >
          <CustomPaginationLink className={page === currentPage ? 'page-btn active' : 'page-btn'}>{page}</CustomPaginationLink>
        </PaginationItem>
      )
    })

  const prevItems = (() => {
    const hasPrev = currentPage > 1
    return (
      
      <PaginationItem disabled={!hasPrev} onClick={() => hasPrev && onPageSelected(currentPage - 1)}>
        <PagingIcon className={hasPrev ? 'prev' : 'prev disabled'}  />
      </PaginationItem>
    )
  })()

  const nextItems = (() => {
    const hasNext = currentPage < maxPage
    return (
      <PaginationItem disabled={!hasNext} onClick={() => hasNext && onPageSelected(currentPage + 1)}>
        <PagingIcon className={hasNext ? 'next' : 'next disabled'}  />
      </PaginationItem>
    )
  })()

  return (
    <div style={wrapperStyle}>
      <Pagination>
        {prevItems}
        {paginationItems}
        {nextItems}
      </Pagination>
    </div>
  )
}

const CustomPaginationLink = styled(PaginationLink)`
  /* margin: 0 8px 0 0; */

  &.page-btn {
    width: 36px;
    height: 36px;
    /* border: 1px solid ${COLORS.GRAYE0} !important; */
    font-size: 15px;
    /* color: ${COLORS.GRAYAB} !important; */
    /* background-color: ${COLORS.WHITE} !important; */
    line-height: 1;
    font-weight: 500;
    box-shadow: none !important;
    outline: none !important;

    
  }
`

const PagingIcon = styled.span`
  display: inline-block;
  width: 36px;
  height: 36px;
  background-size: 36px 36px;
  /* margin-right: 8px; */
  background-position: left top;

  &.prev {
    background-repeat:  no-repeat;
    background-image: url(${IconPrev});
    
    &:hover, &.active {
      background-image: url(${IconPrevOver});
    }

    &.disabled {
      opacity: 0.3;

      &:hover, &.active {
        background-image: url(${IconPrev});
      }
    }
  }

  &.next {
    background-image: url(${IconNext});

    &:hover, &.active {
      background-image: url(${IconNextOver});
    }

    &.disabled {
      opacity: 0.3;

      &:hover, &.active {
        background-image: url(${IconNextOver});
      }
    }
  }
`