import React from 'react'
import { ODVSpace } from '@mdpp/od-react-belt'
import { useHistory, useLocation } from 'react-router-dom'
import { EvixButton, EvixButtonTheme } from '../../common/EvixButton'

//asset
import {
  Wrap,
  SignUpWrap,
  Header,
  PageTitleWrap,
  ProcessWrap,
  DoctorLogo,
  PageTitle,
  Step,
  StepCircle,
  StepText,
  Container,
  Label,
  Line,
  InnerBox,
} from '../../common/style/SignUpStyle'

import IconEmail from '../../assets/common/icon-email.svg'
import IconWarningRed from '../../assets/common/warning-red-small-2.svg'
import IconDoctor from '../../assets/common/icon-doctor.png'
import IconArrow from '../../assets/common/icon-arrow.svg'
import styled from 'styled-components/macro'
import { COLORS } from '../../styles/colors'
import { useCommonAPIs } from '../../common/hooks/useCommonAPIs'
import { ConfirmModal } from '../../common/layout/ConfirmModal'
import IconWarningBlue from '../../assets/common/warning-blue.svg'
import { ErrorCode } from '@mdpp/common'
import { AppOptions } from '../../AppOptions'
import { checkMessageWithPrefix } from '../../utils/registerServerErrorMessages'

export interface IState {
  join: {
    licenseName?: string
    name?: string
    email?: string
    password?: string
    cellPhone?: string
    birthDate?: string
    phone?: string
    hospitalName?: string
    hospitalAddress?: string
    hospitalManagerEmail?: string
    recommender?: string
    waitingDoctorId?: any
    businessLicenseFile?: any
    bankbookFile?: any
  }
}

export const EmailAuthentication: React.FC = () => {
  const location = useLocation<IState>()
  const [userInfo, setUserInfo] = React.useState(location.state)
  const [showSendEmail, setShowSendEmail] = React.useState(false)
  const [showCertificationCompleteModal, setShowCertificationCompleteModal] = React.useState(false)
  const { sendAuthMailAgain } = useCommonAPIs()

  // 다음 버튼 클릭 시, 이동
  const history = useHistory()

  React.useEffect(() => {
    if (userInfo === undefined) {
      return history.push('/')
    }
    setUserInfo(userInfo)
  }, [userInfo])

  React.useEffect(() => {
    const unBlock = history.block((loc, action) => {
      if (action === 'POP') {
        goHanderJoinInfo()
      }
    })
    return () => unBlock()
  }, [history])

  const mainHome = () => {
    let path = `/landing`
    history.push(path)
  }

  const goHanderJoinInfo = () => {
    document.location.href = '/doctor/signupInfo'
  }

  const handleNext = async (userInfo: IState) => {
    let path = `/doctor/changeEmail`
    setUserInfo(userInfo)
    history.push({ pathname: path, state: userInfo })
  }

  const reSentEmail = async (userInfo: IState) => {
    const userId = userInfo?.join?.waitingDoctorId
    try {
      await sendAuthMailAgain({ id: userId })
      setShowSendEmail(true)
      console.log(userId, ': 메일전송 완료되었습니다.')
    } catch (err) {
      console.log(err)
      // 회원가입 자체가 최종완료 되었을 시에도 같은 팝업
      if (checkMessageWithPrefix(err, ErrorCode.ERROR_ACCOUNT_EMAIL_NOT_FOUND)) {
        setShowCertificationCompleteModal(true)
      }
      // 이메일 인증을 완료했을 시에도 같은 팝업
      if (checkMessageWithPrefix(err, ErrorCode.DOCTOR_ALREADY_AUTHENTICATED)) {
        setShowCertificationCompleteModal(true)
      }
    }
  }
  return (
    <Wrap>
      <SignUpWrap>
        <Header>
          <PageTitleWrap>
            <img src={IconDoctor} style={DoctorLogo} alt="의사 아이콘" />
            <PageTitle>회원가입</PageTitle>
          </PageTitleWrap>
          <ProcessWrap>
            <Step>
              <StepCircle>
                <span>1</span>
              </StepCircle>
              <StepText>약관 동의</StepText>
            </Step>
            <Step>
              <StepCircle>
                <span>2</span>
              </StepCircle>
              <StepText>가입정보 입력</StepText>
            </Step>
            <Step className="active">
              <StepCircle>
                <span>3</span>
              </StepCircle>
              <StepText>이메일 인증</StepText>
            </Step>
            <Step>
              <StepCircle>
                <span>4</span>
              </StepCircle>
              <StepText>가입 승인 요청</StepText>
            </Step>
          </ProcessWrap>
        </Header>

        {/* 메일 전송 확인 Modal */}
        {showSendEmail && (
          <ConfirmModal
            style={{ width: 420, height: 274 }}
            isTitle={true}
            title={'인증 메일 발송'}
            titleIcon={IconWarningBlue}
            isDescriptionIcon={false}
            description={[
              '발송 후 최대 5분의 시간이 걸릴 수 있습니다.',
              <br />,
              '연속으로 인증 메일을 못 받은 경우',
              <br />,
              '이메일 아이디를 변경하거나 관리자에 문의해주세요.',
              <br />,
              '(문의 : 1566-8013)',
            ]}
            descriptionIcon={''}
            hideCancel={true}
            onOkPress={() => setShowSendEmail(false)}
            onCancelPress={() => setShowSendEmail(false)}
          />
        )}

        {/* 인증 완료 안내 Modal */}
        {showCertificationCompleteModal && (
          <ConfirmModal
            style={{ width: 420, height: 274 }}
            isTitle={true}
            title={'이메일 인증 완료'}
            titleIcon={IconWarningBlue}
            isDescriptionIcon={false}
            description={[
              '관리자의 승인 후 로그인이 가능합니다.',
              <br />,
              '승인 완료 시 입력하신 이메일로 안내될 예정입니다.',
              <br />,
              '참고 부탁드립니다. 감사합니다.',
            ]}
            descriptionIcon={''}
            hideCancel={true}
            onOkPress={mainHome}
            onCancelPress={() => setShowCertificationCompleteModal(false)}
          />
        )}

        <Container>
          <InnerBox>
            <img src={IconEmail} style={{ width: 80, height: 50 }} alt="icon email" />
            <ODVSpace h={15} />
            <MainMessage>인증 메일이 전송되었습니다.</MainMessage>
            <ODVSpace h={24} />
            <LabelWrap>
              <Label>
                인증메일이 <span className="text-blue"> {userInfo?.join?.email}</span>(으)로 전송되었습니다. <br />
                받으신 이메일을 열어 <span className="text-blue">[인증 버튼]을 클릭</span>하면 가입이 완료됩니다.
              </Label>
            </LabelWrap>
            <ODVSpace h={115} />
            <Line></Line>
            <WaringWrap>
              <WarningBlock>
                <WarningTextWrap>
                  <img
                    src={IconWarningRed}
                    style={{ width: 14, height: 14, marginTop: 3, marginRight: 5 }}
                    alt="icon warning"
                  />
                  <WarningText>
                    이메일이 확인되지 않나요? <br />
                    <span>인증 메일을 다시 보내주세요.</span>
                  </WarningText>
                </WarningTextWrap>
                <EvixButton theme={EvixButtonTheme.H48White} onClick={() => reSentEmail(userInfo)} disabled={false}>
                  인증 메일 다시 보내기
                </EvixButton>
              </WarningBlock>
              <IconBlock>
                <img src={IconArrow} style={{ width: 16, height: 30 }} alt="icon arrow" />
              </IconBlock>
              <WarningBlock>
                <WarningTextWrap>
                  <img
                    src={IconWarningRed}
                    style={{ width: 14, height: 14, marginTop: 3, marginRight: 5 }}
                    alt="icon warning"
                  />
                  <WarningText>
                    인증 메일을 다시 보냈는데, 확인되지 않나요? <br />
                    <span>새로운 이메일 주소를 입력해주세요.</span>
                  </WarningText>
                </WarningTextWrap>
                <EvixButton theme={EvixButtonTheme.H48White} onClick={() => handleNext(userInfo)} disabled={false}>
                  이메일 아이디 변경
                </EvixButton>
              </WarningBlock>
            </WaringWrap>
          </InnerBox>
        </Container>
      </SignUpWrap>
    </Wrap>
  )
}

const MainMessage = styled.div`
  font-size: 18px;
  font-weight: bold;
  color: ${COLORS.GRAY42};
`

const LabelWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`
const WaringWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding-top: 38px;
`

const WarningBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 270px;
  gap: 18px;
`

const WarningTextWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
`

const WarningText = styled.div`
  font-size: 13px;
  font-weight: bold;

  > span {
    font-weight: 500;
    padding-top: 1px;
  }
`

const IconBlock = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 224px;
  padding-top: 64px;
`
