import React from 'react'
import { DoctorListableTable } from '../../common/doctor/list/DoctorListableTable'
import {Page, PageContainer, PageContent, PageHeader, PageTitle} from "../../common/page/Page";

interface IManagerDoctorPageProps {}

export const ManagerDoctorListPage: React.FC<IManagerDoctorPageProps> = props => {
  return (
    <PageContainer>
      <Page>
          <PageHeader>
              <PageTitle>의사관리</PageTitle>
          </PageHeader>
          <DoctorListableTable fromHospitalManager />
      </Page>
    </PageContainer>
  )
}
