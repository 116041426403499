import { UPDATE_TYPE } from '@mdpp/common'
import { ODEntity, ODEntityInput, ODEntityLabeled, ODIcon, ODIcons, ODVSpace } from '@mdpp/od-react-belt'
import gql from 'graphql-tag'
import React from 'react'
import { Card, CardBody as reactstrapCardBody } from 'reactstrap'
import styled from 'styled-components/macro'
import {
  GQLAppVersion,
  GQLAppVersionCreationInput,
  GQLAppVersionUpdateInput,
  GQLUPDATE_TYPE,
} from '@mdpp/common/lib/@types/server.schema'
import { EvixFormToggleButton } from '../../../common/form/EvixFormToggleButton'
import { SiteUrls } from '../../../SiteUrls'
import { COLORS } from '../../../styles/colors'
import { useODEntityAPI } from '../../../utils/odGQLEntityLoader'
import {
  GQL_CREATE_APP_VERSION,
  GQL_DELETE_APP_VERSION,
  GQL_GET_APP_VERSION,
  GQL_UPDATE_APP_VERSION,
} from '../../context/gqls_evix_admin'
import { AppVersionPrimaryKey, ICTAppVersion } from '../AppVersionCommon'
import { EvixCard } from '../../../common/card/EvixCard'
import { TableList, TableListToggleButtonWrapper } from '../../../common/list/TableList'


interface IAppVersionEditProps {
  idEditing: number | null
}

const DEFAULT_DATA: Partial<ICTAppVersion> = {
  appVersionId: 0,
  description: '',
  isAndroid: false,
  isIOS: false,
  version: '',
  updateType: GQLUPDATE_TYPE.NO,
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap:32px;
  padding: 10px;
  `

const TableListToggleWrap = { width: 450 }

export const AppVersionEdit: React.FC<IAppVersionEditProps> = props => {
  const { idEditing } = props
  const apis = useODEntityAPI<GQLAppVersion, ICTAppVersion, GQLAppVersionCreationInput, GQLAppVersionUpdateInput>({
    createGQL: gql(GQL_CREATE_APP_VERSION),
    readGQL: gql(GQL_GET_APP_VERSION),
    updateGQL: gql(GQL_UPDATE_APP_VERSION),
    deleteGQL: gql(GQL_DELETE_APP_VERSION),
    primaryKeyName: AppVersionPrimaryKey,
  })

  return (
    <EvixCard>
   
        <ODEntity
          resourceId={idEditing}
          api={apis}
          saveButtonName="저장"
          titleCreation="추가"
          createSuccessTitle="성공"
          createSuccessMessage="추가하였습니다."
          updateSuccessTitle="성공"
          updateSuccessMessage="저장하였습니다."
          defaultCreateClientData={DEFAULT_DATA}
          urlAfterCreation={() => SiteUrls.Admin.AppVersion.Main}
          urlAfterUpdate={() => SiteUrls.Admin.AppVersion.Main}
          urlAfterDelete={() => SiteUrls.Admin.AppVersion.Main}
          noCardWrap
          footerProps={{
            buttonStyle: { borderRadius: 9999 },
            deleteButtonStyle: {
              borderColor: COLORS.DANGER,
              color: COLORS.DANGER,
              backgroundColor: COLORS.RED10,
            },
          }}
          deleteButtonName="삭제"
        >
          <Wrapper>
            <TableList name="version" label="버전">
              <ODEntityInput keyPath="version" label="버전" name="version" placeholder="적용 버전" inputType="text" />
            </TableList>

            <TableList name="build" label="빌드번호">
              <ODEntityInput keyPath="build" label="빌드" name="build" placeholder="빌드번호" inputType="number" />
              <TextAreaHelpText>숫자만 가능합니다. 빌드 번호를 의미합니다.</TextAreaHelpText>
            </TableList>

            <TableList name="description" label="주요기능">
              <ODEntityInput
                keyPath="description"
                label="주요기능"
                name="description"
                placeholder=""
                inputType="text"
              />
              <TextAreaHelpText>사용자에게 노출되는 것은 아닙니다.</TextAreaHelpText>
            </TableList>

           
            <TableList name="platform" label="적용 플랫폼">
              <ODEntityLabeled label="적용 플랫폼" name="platform">
                <TableListToggleButtonWrapper>
                  <EvixFormToggleButton name="isAndroid" keyPath="isAndroid">
                    안드로이드
                  </EvixFormToggleButton>
                  <EvixFormToggleButton name="isIOS" keyPath="isIOS">
                    iOS
                  </EvixFormToggleButton>
                </TableListToggleButtonWrapper>
              </ODEntityLabeled>
            </TableList>

            <TableList name="updateType" label="업데이트 종류">
              <ODEntityLabeled label="업데이트 종류" name="updateType">
                <TableListToggleButtonWrapper style={TableListToggleWrap}>
                  <EvixFormToggleButton name="updateType" keyPath="updateType" value={UPDATE_TYPE.NO}>
                    업데이트 알림 없음
                  </EvixFormToggleButton>
                  <EvixFormToggleButton name="updateType" keyPath="updateType" value={UPDATE_TYPE.OPTIONAL}>
                    선택 업데이트
                  </EvixFormToggleButton>
                  <EvixFormToggleButton name="updateType" keyPath="updateType" value={UPDATE_TYPE.REQUIRED}>
                    필수 업데이트
                  </EvixFormToggleButton>
                </TableListToggleButtonWrapper>
              </ODEntityLabeled>
            </TableList>
          </Wrapper>
          <div style={{ marginTop: 30, marginBottom: 30 }}>
            <hr />
          </div>
        </ODEntity>

    </EvixCard>
  )
}

const TextAreaHelpText = styled.div`
  position: relative;
  padding-left: 25px;
  padding-top: 10px;
  font-size: 12px;
  line-height: 1.17;
  color: var(--gray600);

  ::before {
    content: '✳︎';
    position: absolute;
    left: 13px;
  }
`
