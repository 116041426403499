import { ODIcon, ODIcons, ODModal, ODModalSize, ODHSpace, ODHSpread } from '@mdpp/od-react-belt'
import React from 'react'
import styled from 'styled-components/macro'
import { Button, BP } from '../../../common/RebeccaButton'

const REASONS = {
  FIRST: '환자 예약부도',
  SECOND: '진료과 변경을 권장함',
  CUSTOM: 'custom',
}

interface IReservationCancelPopupProps {
  isOpen: boolean
  onClose: () => void
  onSubmit: (reason: string) => void
}

export const ReservationCancelPopup: React.FC<IReservationCancelPopupProps> = props => {
  const { isOpen, onClose, onSubmit } = props

  const [selected, setSelected] = React.useState(REASONS.FIRST)
  const [reason, setReason] = React.useState('')

  const selectReason = (r: string) => {
    setSelected(r)
  }

  const sendReason = (r: string) => {
    if (r === REASONS.CUSTOM) {
      onSubmit(reason)
    } else {
      onSubmit(r)
    }
  }

  return (
    <>
      <ODModal rounded={true} isOpen={isOpen} toggle={onClose} size={ODModalSize.Normal}>
        <ODHSpread style={{ padding: 20, alignItems: 'center', borderBottom: '1px solid #E1E3E5' }}>
          <ModalTitle>예약 취소</ModalTitle>
          <CloseButtonBox onClick={onClose}>
            <ODIcon icon={ODIcons.CoreX} style={CloseIconStyle} />
          </CloseButtonBox>
        </ODHSpread>
        <Wrapper>
          <Inner>
            취소 사유를 입력하세요. 환자에게 전달됩니다.
          </Inner>
            <RadioGroup>
              <RadioRow>
                <RadioInput type="radio" name="cp" onChange={() => (selectReason(REASONS.FIRST))} checked={selected === REASONS.FIRST} />
                <RadioLabel>환자 예약부도</RadioLabel>
              </RadioRow>
              <RadioRow>
                <RadioInput type="radio" name="cp" onChange={() => (selectReason(REASONS.SECOND))}/>
                <RadioLabel>진료과 변경을 권장함</RadioLabel>
              </RadioRow>
              <RadioRow style={{marginBottom: 25}}>
                <RadioInput type="radio" name="cp" onChange={() => (selectReason(REASONS.CUSTOM))}/>
                <ReasonInput type='text' onChange={(e: any) => {
                  setReason(e.target.value)
                }}
                  disabled={selected !== REASONS.CUSTOM}
                  placeholder={'직접입력'}
                  maxLength={200}
                />
              </RadioRow>
            </RadioGroup>
          <Footer style={{borderTop: '1px solid #E1E3E5'}}>
            <FooterGroupRight>
              <Button size={BP.SIZE.M} onClick={(e: any) => {
                e.preventDefault()
                onClose()
              }}
              >취소 안함</Button>
              <ODHSpace w={8} />
              <Button size={BP.SIZE.M} type={BP.TYPE.Primary} onClick={(e: any) => {
                  e.preventDefault()
                  sendReason(selected)
                }}
                disabled={ selected === REASONS.CUSTOM && reason.length === 0}
              > 예약 취소
              </Button>
            </FooterGroupRight>
          </Footer>
        </Wrapper>
      </ODModal>
    </>
  )
}

const RadioGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 139px;
  margin-left: 20px;
`

const RadioRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  height: 41px;
`

const RadioInput = styled.input`
  margin-right: 5px;
  width: 24px;
  height: 24px;
  background: white;
  border: 1.5px solid rgba(115, 129, 143, 0.26);
  box-sizing: border-box;
`

const RadioLabel = styled.span`
  color: #131415;
  font-size: 14px;
  margin-left: 8px;
`

const ReasonInput = styled.input`
  background: #ffffff;
  border: 1px solid #C9CCCE;
  box-sizing: border-box;
  border-radius: 8px;
  width: 425px;
  height: 34px;
  padding-left: 8px;
  margin-left: 2px;

  &: disabled {
    pointer-events: none;
  }
`

const CloseIconStyle = {
  fontSize: 18,
  color: '#dbdbdb',
}
const Wrapper = styled.div`
  background-color: #ffffff;
  position: relative;
`
const Inner = styled.div`
  display: flex;
  justify-content: flex-start;
  padding: 20px;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.3px;
  color: #202223;
`

const ModalTitle = styled.h1`
  font-size: 20px;
  font-weight: 500;
  text-align: left;
  margin: 0;
`

const CloseButtonBox = styled.div`
  right: 25px;
  cursor: pointer;
`

const Footer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
  height: 68px;
`

const FooterGroupRight = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  border-top: 1px solid #E1E3E5;
`
