import { AccountType } from '@mdpp/common'
import { ODHSpace, ODVLine, ODVSpace } from '@mdpp/od-react-belt'
import React from 'react'
import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'

import MediPangPangFooterLogo from '../assets/mediPangPang_textType_v1.0_72_32.svg'
import { SiteUrls } from '../SiteUrls'
import { COLORS } from '../styles/colors'

const FooterComponent = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  right: 60px;
  left: 60px;
  bottom: 28px;
  font-size: 14px;
  color: ${COLORS.GRAY500};
`
const LogoBox = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`
const LogoLine = styled.div`
  width: 100%;
  height: 1px;
  background-color: #e0e7ec;
`

const FooterMain = styled.div`
  display: flex;
`

const LeftBox = styled.div`
  display: flex;
`

const CompanyInfoBox = styled.div`
  display: flex;
  flex-direction: column;
`

const CompanyInfo = styled.small`
  font-size: 12px;
  color: var(--gray600);

  ${(p: { gray?: boolean }) =>
    p.gray &&
    css`
      font-size: 11px;
      line-height: 1.45;
      color: var(--gray400);
    `}
`

const RightBox = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: auto;
`

interface IFooterProps {
  accountType: Omit<AccountType, 'Patient'>
}

export const useTitle = (initialTitle: any) => {
  const [title, setTitle] = React.useState(initialTitle)
  const updateTitle = () => {
    const htmlTitle = document.querySelector('title')
    htmlTitle!.innerText = title
  }
  React.useEffect(updateTitle, [title])
  return setTitle
}

export const Footer: React.FC<IFooterProps> = props => {
  const { accountType } = props
  const isDoctor = accountType === 'Doctor'
  const isManager = accountType === 'HospitalManager'
  const isAdmin = accountType === 'EvixAdmin'
  const isPharmacist = accountType === 'Pharmacist'
  const [isMobile, setIsMobile] = React.useState(false)

  React.useEffect(() => {
    let check = false
    if (
      navigator.userAgent.match(
        /Mobile|iP(hone|od)|BlackBerry|IEMobile|Kindle|NetFront|Silk-Accelerated|(hpw|web)OS|Fennec|Minimo|Opera M(obi|ini)|Blazer|Dolfin|Dolphin|Skyfire|Zune/
      )
    ) {
      if (!navigator.userAgent.match(/iPad/)) {
        // true for mobile device
        setIsMobile(true)
        // console.log('check : ', check);
        alert('본 사이트는 PC 크롬 브라우저에 최적화 되어 있습니다.')
      } else {
        setIsMobile(false)
      }
    } else {
      // false for not mobile device
      setIsMobile(false)
    }
    //console.log('isMobile : ', isMobile);

    return () => console.log(isMobile)
  }, [])

  return (
    <FooterComponent>
      <LogoBox>
        <img src={MediPangPangFooterLogo} alt="Medi PangPang Footer logo" />
        <ODHSpace w={17} />
        <LogoLine />
      </LogoBox>
      <ODVSpace h={19} />
      <FooterMain>
        <LeftBox>
          <CompanyInfoBox>
            <CompanyInfo>주식회사 에비드넷</CompanyInfo>
            <CompanyInfo gray>서울특별시 강남구 강남대로 292 뱅뱅빌딩 3층</CompanyInfo>
            <ODVSpace h={5} />
            <CompanyInfo>1566-8013</CompanyInfo>
          </CompanyInfoBox>
          <ODHSpace w={48} />
          {/*<CompanyInfoBox>*/}
          {/*  <CompanyInfo>대표 : 조인산</CompanyInfo>*/}
          {/*  <CompanyInfo gray>사업자 등록번호 : 398-81-00866</CompanyInfo>*/}
          {/*</CompanyInfoBox>*/}
        </LeftBox>
        <RightBox>
          {!isDoctor && (
            <>
              <Link to={SiteUrls.Doctor.Login} className="grayed_link">
                의사 사이트
              </Link>
              <ODHSpace w={16} />
              <ODVLine height={12} color={COLORS.GRAY400} style={{ marginTop: 5 }} />
              <ODHSpace w={16} />
            </>
          )}
          {!isManager && !isPharmacist && (
            <>
              <Link to={SiteUrls.Manager.Login} className="grayed_link">
                병원 관리자
              </Link>
              <ODHSpace w={16} />
              <ODVLine height={12} color={COLORS.GRAY400} style={{ marginTop: 5 }} />
              <ODHSpace w={16} />
            </>
          )}
          {/*{!isAdmin && (*/}
          {/*  <>*/}
          {/*    <Link to={SiteUrls.Admin.Login} className="grayed_link">*/}
          {/*      메디팡팡 관리자*/}
          {/*    </Link>*/}
          {/*    <ODHSpace w={16} />*/}
          {/*    <ODVLine height={12} color={COLORS.GRAY400} style={{ marginTop: 5 }} />*/}
          {/*    <ODHSpace w={16} />*/}
          {/*  </>*/}
          {/*)}*/}

          {/*{ !isMobile && (*/}
          {/*    <>*/}
          <Link to={SiteUrls.Terms} className="grayed_link">
            이용약관
          </Link>
          <ODHSpace w={16} />
          <ODVLine height={12} color={COLORS.GRAY400} style={{ marginTop: 5 }} />
          <ODHSpace w={16} />
          <Link to={SiteUrls.Privacy} className="grayed_link">
            개인정보처리방침
          </Link>
          <ODHSpace w={16} />
          <ODVLine height={12} color={COLORS.GRAY400} style={{ marginTop: 5 }} />
          <ODHSpace w={16} />
          <Link to={SiteUrls.PrivacyConsent} className="grayed_link">
            개인정보 수집 및 이용 동의
          </Link>
          {/*    </>*/}
          {/*  )*/}
          {/*}*/}
        </RightBox>
      </FooterMain>
    </FooterComponent>
  )
}
