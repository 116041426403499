import { SCHEDULE_TYPE } from '@mdpp/common'
import moment from 'moment'
import { ISCHEDULE_DATA } from '../ScheduleSettingCommon'

/**
 * 서버에 데이터가 없는 경우에 비어있는 데이터들에 대하여 가짜 데이터를 populate 하여 테이블을 보여줄 수 있도록 데이터를 변환한다.
 */
export function fillInScheduleSettingTable(
  schedule: string,
  rr: ISCHEDULE_DATA[],
  startDateToken: string,
  endDateToken: string,
  timeSlots: string[]
) {
  const n = moment(startDateToken, 'YYYY-MM-DD')

  while (n.format('YYYY-MM-DD') <= endDateToken) {
    const nToken = n.format('YYYY-MM-DD')
    const found = rr.find(v => v.date === nToken)
    if (!found) {
      rr.push({
        date: nToken,
        state: timeSlots.map(t => {
          return {
            id: -1,
            avail: SCHEDULE_TYPE.UNAVAILABLE,
            date: nToken,
            reservation: null,
            time: t,
          }
        }),
      })
    } else {
      if (timeSlots.length !== found.state.length) {
        rr.forEach((v, index) => {
          rr[index].state = timeSlots.map(t => {
            const findSlot = v.state.find(v => v.time === t)
            if (findSlot) {
              return { ...findSlot }
            } else {
              return {
                id: -1,
                avail: SCHEDULE_TYPE.UNAVAILABLE,
                date: nToken,
                reservation: null,
                time: t,
              }
            }
          })
        })
      }
    }
    n.add(1, 'day')
  }

  rr.sort((a, b) => (a.date < b.date ? -1 : 1))
}
