const SI_DO_CODE = [
  { value: '', label: '시/도' },
  { value: '110000', label: '서울특별시' },
  { value: '210000', label: '부산광역시' },
  { value: '220000', label: '인천광역시' },
  { value: '230000', label: '대구광역시' },
  { value: '240000', label: '광주광역시' },
  { value: '250000', label: '대전광역시' },
  { value: '260000', label: '울산광역시' },
  { value: '310000', label: '경기도' },
  { value: '320000', label: '강원도' },
  { value: '330000', label: '충청북도' },
  { value: '340000', label: '충청남도' },
  { value: '350000', label: '전라북도' },
  { value: '360000', label: '전라남도' },
  { value: '370000', label: '경상북도' },
  { value: '380000', label: '경상남도' },
  { value: '390000', label: '제주특별자치도' },
  { value: '410000', label: '세종특별자치시' },
]

const SI_GU_GUN_CODE: any = {
  "110000": [
    { value: "", label: "" },
    { value: "110001", label: "강남구" },
    { value: "110002", label: "강동구" },
    { value: "110003", label: "강서구" },
    { value: "110004", label: "관악구" },
    { value: "110005", label: "구로구" },
    { value: "110006", label: "도봉구" },
    { value: "110007", label: "동대문구" },
    { value: "110008", label: "동작구" },
    { value: "110009", label: "마포구" },
    { value: "110010", label: "서대문구" },
    { value: "110011", label: "성동구" },
    { value: "110012", label: "성북구" },
    { value: "110013", label: "영등포구" },
    { value: "110014", label: "용산구" },
    { value: "110015", label: "은평구" },
    { value: "110016", label: "종로구" },
    { value: "110017", label: "중구" },
    { value: "110018", label: "송파구" },
    { value: "110019", label: "중랑구" },
    { value: "110020", label: "양천구" },
    { value: "110021", label: "서초구" },
    { value: "110022", label: "노원구" },
    { value: "110023", label: "광진구" },
    { value: "110024", label: "강북구" },
    { value: "110025", label: "금천구" },
  ]
  ,
  "210000": [
    { value: "", label: "" },
    { value: "210001", label: "부산남구" },
    { value: "210002", label: "부산동구" },
    { value: "210003", label: "부산동래구" },
    { value: "210004", label: "부산진구" },
    { value: "210005", label: "부산북구" },
    { value: "210006", label: "부산서구" },
    { value: "210007", label: "부산영도구" },
    { value: "210008", label: "부산중구" },
    { value: "210009", label: "부산해운대구" },
    { value: "210010", label: "부산사하구" },
    { value: "210011", label: "부산금정구" },
    { value: "210012", label: "부산강서구" },
    { value: "210013", label: "부산연제구" },
    { value: "210014", label: "부산수영구" },
    { value: "210015", label: "부산사상구" },
    { value: "210100", label: "부산기장군" },
  ]
  ,
  "220000": [
    { value: "", label: "" },
    { value: "220001", label: "인천미추홀구" },
    { value: "220002", label: "인천동구" },
    { value: "220003", label: "인천부평구" },
    { value: "220004", label: "인천중구" },
    { value: "220005", label: "인천서구" },
    { value: "220006", label: "인천남동구" },
    { value: "220007", label: "인천연수구" },
    { value: "220008", label: "인천계양구" },
    { value: "220100", label: "인천강화군" },
    { value: "220200", label: "인천옹진군" },
  ],
  "230000": [
    { value: "", label: "" },
    { value: "230001", label: "대구남구" },
    { value: "230002", label: "대구동구" },
    { value: "230003", label: "대구북구" },
    { value: "230004", label: "대구서구" },
    { value: "230005", label: "대구수성구" },
    { value: "230006", label: "대구중구" },
    { value: "230007", label: "대구달서구" },
    { value: "230100", label: "대구달성군" },
  ],
  "240000": [
    { value: "", label: "" },
    { value: "240001", label: "광주동구" },
    { value: "240002", label: "광주북구" },
    { value: "240003", label: "광주서구" },
    { value: "240004", label: "광주광산구" },
    { value: "240005", label: "광주남구" },
  ],
  "250000": [
    { value: "", label: "" },
    { value: "250001", label: "대전유성구" },
    { value: "250002", label: "대전대덕구" },
    { value: "250003", label: "대전서구" },
    { value: "250004", label: "대전동구" },
    { value: "250005", label: "대전중구" },
  ],
  "260000": [
    { value: "", label: "" },
    { value: "260001", label: "울산남구" },
    { value: "260002", label: "울산동구" },
    { value: "260003", label: "울산중구" },
    { value: "260004", label: "울산북구" },
    { value: "260100", label: "울산울주군" },
  ],
  "310000": [
    { value: "", label: "" },
    { value: "310001", label: "가평군" },
    { value: "310002", label: "강화군" },
    { value: "310006", label: "시흥시" },
    { value: "310008", label: "양주군" },
    { value: "310009", label: "양평군" },
    { value: "310010", label: "여주군" },
    { value: "310011", label: "연천군" },
    { value: "310012", label: "옹진군" },
    { value: "310016", label: "평택군" },
    { value: "310017", label: "포천군" },
    { value: "310100", label: "광명시" },
    { value: "310200", label: "동두천시" },
    { value: "310300", label: "부천시" },
    { value: "310301", label: "부천소사구" },
    { value: "310302", label: "부천오정구" },
    { value: "310303", label: "부천원미구" },
    { value: "310401", label: "성남수정구" },
    { value: "310402", label: "성남중원구" },
    { value: "310403", label: "성남분당구" },
    { value: "310500", label: "송탄시" },
    { value: "310600", label: "수원시" },
    { value: "310601", label: "수원권선구" },
    { value: "310602", label: "수원장안구" },
    { value: "310603", label: "수원팔달구" },
    { value: "310604", label: "수원영통구" },
    { value: "310701", label: "안양만안구" },
    { value: "310702", label: "안양동안구" },
    { value: "310800", label: "의정부시" },
    { value: "310900", label: "과천시" },
    { value: "311000", label: "구리시" },
    { value: "311100", label: "안산시" },
    { value: "311101", label: "안산단원구" },
    { value: "311102", label: "안산상록구" },
    { value: "311200", label: "평택시" },
    { value: "311300", label: "하남시" },
    { value: "311400", label: "군포시" },
    { value: "311500", label: "남양주시" },
    { value: "311600", label: "의왕시" },
    { value: "311700", label: "시흥시" },
    { value: "311800", label: "오산시" },
    { value: "311901", label: "고양덕양구" },
    { value: "311902", label: "고양일산서구" },
    { value: "311903", label: "고양일산동구" },
    { value: "312000", label: "용인시" },
    { value: "312001", label: "용인기흥구" },
    { value: "312002", label: "용인수지구" },
    { value: "312003", label: "용인처인구" },
    { value: "312100", label: "이천시" },
    { value: "312200", label: "파주시" },
    { value: "312300", label: "김포시" },
    { value: "312400", label: "안성시" },
    { value: "312500", label: "화성시" },
    { value: "312600", label: "광주시" },
    { value: "312700", label: "양주시" },
    { value: "312800", label: "포천시" },
    { value: "312900", label: "여주시" },
  ],
  "320000": [
    { value: "", label: "" },
    { value: "320001", label: "고성군" },
    { value: "320004", label: "양구군" },
    { value: "320005", label: "양양군" },
    { value: "320006", label: "영월군" },
    { value: "320008", label: "인제군" },
    { value: "320009", label: "정선군" },
    { value: "320010", label: "철원군" },
    { value: "320012", label: "평창군" },
    { value: "320013", label: "홍천군" },
    { value: "320014", label: "화천군" },
    { value: "320015", label: "횡성군" },
    { value: "320100", label: "강릉시" },
    { value: "320200", label: "동해시" },
    { value: "320300", label: "속초시" },
    { value: "320400", label: "원주시" },
    { value: "320500", label: "춘천시" },
    { value: "320600", label: "태백시" },
    { value: "320700", label: "삼척시" },
  ],
  "330000": [
    { value: "", label: "" },
    { value: "330001", label: "괴산군" },
    { value: "330002", label: "단양군" },
    { value: "330003", label: "보은군" },
    { value: "330004", label: "영동군" },
    { value: "330005", label: "옥천군" },
    { value: "330006", label: "음성군" },
    { value: "330007", label: "제천군" },
    { value: "330009", label: "진천군" },
    { value: "330010", label: "청원군" },
    { value: "330011", label: "증평군" },
    { value: "330100", label: "청주시" },
    { value: "330101", label: "청주상당구" },
    { value: "330102", label: "청주흥덕구" },
    { value: "330103", label: "청주청원구" },
    { value: "330104", label: "청주서원구" },
    { value: "330200", label: "충주시" },
    { value: "330300", label: "제천시" },
  ],
  "340000": [
    { value: "", label: "" },
    { value: "340002", label: "금산군" },
    { value: "340004", label: "당진군" },
    { value: "340007", label: "부여군" },
    { value: "340009", label: "서천군" },
    { value: "340011", label: "연기군" },
    { value: "340012", label: "예산군" },
    { value: "340013", label: "천안군" },
    { value: "340014", label: "청양군" },
    { value: "340015", label: "홍성군" },
    { value: "340016", label: "태안군" },
    { value: "340200", label: "천안시" },
    { value: "340201", label: "천안서북구" },
    { value: "340202", label: "천안동남구" },
    { value: "340300", label: "공주시" },
    { value: "340400", label: "보령시" },
    { value: "340500", label: "아산시" },
    { value: "340600", label: "서산시" },
    { value: "340700", label: "논산시" },
    { value: "340800", label: "계룡시" },
    { value: "340900", label: "당진시" },
  ],
  "350000": [
    { value: "", label: "" },
    { value: "350001", label: "고창군" },
    { value: "350004", label: "무주군" },
    { value: "350005", label: "부안군" },
    { value: "350006", label: "순창군" },
    { value: "350008", label: "완주군" },
    { value: "350009", label: "익산군" },
    { value: "350010", label: "임실군" },
    { value: "350011", label: "장수군" },
    { value: "350013", label: "진안군" },
    { value: "350100", label: "군산시" },
    { value: "350200", label: "남원시" },
    { value: "350300", label: "익산시" },
    { value: "350400", label: "전주시" },
    { value: "350401", label: "전주완산구" },
    { value: "350402", label: "전주덕진구" },
    { value: "350500", label: "정읍시" },
    { value: "350600", label: "김제시" },
  ],
  "360000": [
    { value: "", label: "" },
    { value: "360001", label: "강진군" },
    { value: "360002", label: "고흥군" },
    { value: "360003", label: "곡성군" },
    { value: "360006", label: "구례군" },
    { value: "360008", label: "담양군" },
    { value: "360009", label: "무안군" },
    { value: "360010", label: "보성군" },
    { value: "360012", label: "신안군" },
    { value: "360014", label: "영광군" },
    { value: "360015", label: "영암군" },
    { value: "360016", label: "완도군" },
    { value: "360017", label: "장성군" },
    { value: "360018", label: "장흥군" },
    { value: "360019", label: "진도군" },
    { value: "360020", label: "함평군" },
    { value: "360021", label: "해남군" },
    { value: "360022", label: "화순군" },
    { value: "360200", label: "나주시" },
    { value: "360300", label: "목포시" },
    { value: "360400", label: "순천시" },
    { value: "360500", label: "여수시" },
    { value: "360700", label: "광양시" },
  ],
  "370000": [
    { value: "", label: "" },
    { value: "370002", label: "고령군" },
    { value: "370003", label: "군위군" },
    { value: "370005", label: "달성군" },
    { value: "370007", label: "봉화군" },
    { value: "370010", label: "성주군" },
    { value: "370012", label: "영덕군" },
    { value: "370013", label: "영양군" },
    { value: "370014", label: "영일군" },
    { value: "370017", label: "예천군" },
    { value: "370018", label: "울릉군" },
    { value: "370019", label: "울진군" },
    { value: "370021", label: "의성군" },
    { value: "370022", label: "청도군" },
    { value: "370023", label: "청송군" },
    { value: "370024", label: "칠곡군" },
    { value: "370100", label: "경주시" },
    { value: "370200", label: "구미시" },
    { value: "370300", label: "김천시" },
    { value: "370400", label: "안동시" },
    { value: "370500", label: "영주시" },
    { value: "370600", label: "영천시" },
    { value: "370700", label: "포항시" },
    { value: "370701", label: "포항남구" },
    { value: "370702", label: "포항북구" },
    { value: "370800", label: "문경시" },
    { value: "370900", label: "상주시" },
    { value: "371000", label: "경산시" },
  ],
  "380000": [
    { value: "", label: "" },
    { value: "380002", label: "거창군" },
    { value: "380003", label: "고성군" },
    { value: "380004", label: "김해군" },
    { value: "380005", label: "남해군" },
    { value: "380007", label: "사천군" },
    { value: "380008", label: "산청군" },
    { value: "380011", label: "의령군" },
    { value: "380012", label: "창원군" },
    { value: "380014", label: "창녕군" },
    { value: "380016", label: "하동군" },
    { value: "380017", label: "함안군" },
    { value: "380018", label: "함양군" },
    { value: "380019", label: "합천군" },
    { value: "380100", label: "김해시" },
    { value: "380200", label: "마산시" },
    { value: "380300", label: "사천시" },
    { value: "380500", label: "진주시" },
    { value: "380600", label: "진해시" },
    { value: "380700", label: "창원시" },
    { value: "380701", label: "창원마산회원구" },
    { value: "380702", label: "창원마산합포구" },
    { value: "380703", label: "창원진해구" },
    { value: "380704", label: "창원의창구" },
    { value: "380705", label: "창원성산구" },
    { value: "380800", label: "통영시" },
    { value: "380900", label: "밀양시" },
    { value: "381000", label: "거제시" },
    { value: "381100", label: "양산시" },
  ],
  "390000": [
    { value: "", label: "" },
    { value: "390001", label: "남제주군" },
    { value: "390002", label: "북제주군" },
    { value: "390100", label: "서귀포시" },
    { value: "390200", label: "제주시" },
  ],
  "410000": [
    { value: "", label: "" },
    { value: "410000", label: "세종시" },
  ]
}

export default {
  SI_DO_CODE: SI_DO_CODE,
  SI_GU_GUN_CODE: SI_GU_GUN_CODE
}