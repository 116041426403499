import React, { useState } from 'react'
import { Modal } from 'reactstrap'
import { EvixButton, EvixButtonTheme } from '../../common/EvixButton'
import { EvixSelector } from '../../common/EvixSelector'
import { EvixInlineInputText } from '../../common/EvixInlineInputText'
import IconClose from '../../assets/common/icon-close.svg'
import { Wrapper, Header, TitleWrap, Title, TitleDescription } from '../../common/style/ModalStyle'
import { PagingWrap } from '../../common/style/CommonStyle'
import styled from 'styled-components/macro'
import { COLORS } from '../../styles/colors'
import axios from 'axios'
import { HospitalDataListableTable } from '../../common/hospitalData/list/HospitalDataListableTable'
import _ from 'lodash'
import { AppOptions } from '../../AppOptions'
import HospitalDataPagenation from '../../common/hospitalData/list/HospitalDataPagenation'
import SiDoGuGunCode from '../../common/hospitalData/list/SiDoGuGunCode'
import { OptionTypeBase } from 'react-select'
import SP_HOSPITAL_DATA from '../../common/hospitalData/list/SPHospitalData'

interface HospitalSearchModalProps {
  style?: object
  onSelected: (hospitalName: string, hospitalAddress: string, hospitalPostNo: string, hospitalTel: string) => void
  onCancelPress: () => void
  onDirectPress: () => void
}

export const HospitalSearchModal: React.FC<HospitalSearchModalProps> = props => {
  const PAGE_SIZE = 7
  const { style, onSelected, onDirectPress, onCancelPress } = props
  const [list, setList] = React.useState<SP_HOSPITAL_DATA[]>([])
  const [pageNum, setPageNum] = React.useState(1)
  const [totalNum, setTotalNum] = React.useState(0)
  const [selectedHospitalIndex, setSelectedHospitalIndex] = React.useState(-1)
  const [sidoCd, setSidoCd] = React.useState('')
  const [searchKey, setSearchKey] = React.useState('')
  const [loading, setLoading] = React.useState(false)
  const [siGuGun, setSiGuGun] = React.useState('')
  const [siGuGunList, setSiGuGunList] = React.useState<OptionTypeBase[]>([{ value: 'all', label: '시/군/구' }])

  const getHospitalList = async () => {
    try {
      let item
      setLoading(true)
      const result = await axios.get(
        'https://api.dev.medi-pangpang.kr/open-api/B551182/hospInfoService1/getHospBasisList1?ServiceKey=' +
          AppOptions.API_DATA_KEY +
          '&pageNo=' +
          pageNum +
          '&numOfRows=' +
          PAGE_SIZE +
          (sidoCd && sidoCd !== '' ? '&sidoCd=' + sidoCd : '') +
          (searchKey && searchKey !== '' ? '&yadmNm=' + searchKey : '') +
          (siGuGun && siGuGun !== '' && siGuGun !== 'all' ? '&sgguCd=' + siGuGun : '')
      )
      setLoading(false)
      if (!result || result.status !== 200) {
        return
      }

      item = result?.data?.response?.body?.items?.item
      if (item && item.length === undefined) {
        setList([item])
      } else {
        setList(item || [])
      }

      setSelectedHospitalIndex(-1)
      setTotalNum(result?.data?.response?.body?.totalCount || 0)
    } catch (e) {
      console.error('Fail Get Hospital List')
    } finally {
      setLoading(false)
    }
  }
  const updateKeyword = (keyword: string) => {
    setSearchKey(keyword)
  }

  const debounceKey = _.debounce(updateKeyword, 200)

  const setKeyword = (keyword: string) => {
    debounceKey(keyword)
  }

  const delayHandleSearchKeyInput = (searchKey: string) => {
    setKeyword(searchKey)
  }

  React.useEffect(() => {
    getHospitalList()
  }, [pageNum])

  // 검색 영역
  const handleSelect = () => {
    const hospital = list[selectedHospitalIndex]
    onSelected(hospital.yadmNm, hospital.addr, hospital.postNo, hospital.telno)
  }

  const resetSearchInfo = () => {
    setTotalNum(0)
    setPageNum(1)
  }

  return (
    <Modal isOpen={true} toggle={() => {}} style={style}>
      <Wrapper>
        <Header>
          <img src={IconClose} onClick={onCancelPress} alt="close icon" />
        </Header>

        <Container>
          <TitleWrap>
            <Title>병/의원 검색</Title>
            <TitleDescription>건강보험심사평가원에 등록된 기관명 기준입니다.</TitleDescription>
          </TitleWrap>

          {/* 검색 영역 */}
          <SearchWrap>
            <EvixSelector
              style={{ width: 110 }}
              options={SiDoGuGunCode.SI_DO_CODE}
              placeholder={'시/도'}
              onChange={(data: any) => {
                setSidoCd(data.value)
                if (data.value !== '' && data.value.length === 6) {
                  const code = SiDoGuGunCode.SI_GU_GUN_CODE[data.value]
                  setSiGuGunList(code)
                } else {
                  setSiGuGunList([{ value: '', label: '' }])
                }
                setSiGuGun('')
              }}
            />
            <EvixSelector
              style={{ width: 130 }}
              options={siGuGunList}
              placeholder={'시/군/구'}
              onChange={(data: any) => {
                setSiGuGun(data.value)
              }}
              value={siGuGun}
            />
            <EvixInlineInputText
              style={{ flex: 1 }}
              onChange={(data: any) => {
                delayHandleSearchKeyInput(data)
              }}
            />
            <EvixButton
              theme={EvixButtonTheme.H48WhiteInline}
              disabled={false}
              onClick={() => {
                setPageNum(1)
                getHospitalList()
              }}
            >
              검색
            </EvixButton>
          </SearchWrap>

          {/* 검색 결과 리스트 */}
          <TableWrap>
            <HospitalDataListableTable
              list={list}
              loading={loading}
              selectedInd={selectedHospitalIndex}
              onSelect={selectedInd => {
                setSelectedHospitalIndex(selectedInd)
              }}
            />
            {!loading && list.length === 0 && (
              <EmptyContentWrap>
                <p>검색 결과가 없습니다.</p>
                <EvixButton theme={EvixButtonTheme.H48WhiteInline} disabled={false} onClick={onDirectPress}>
                  직접입력
                </EvixButton>
              </EmptyContentWrap>
            )}
          </TableWrap>
          <PagingContainer>
            <PagingWrap className="hospital-data-paging">
              <HospitalDataPagenation
                hideIfSinglePage={true}
                totalCount={totalNum}
                currentPage={pageNum}
                pageSize={PAGE_SIZE}
                onPageSelected={ind => {
                  setPageNum(ind)
                }}
              />
            </PagingWrap>
            <EvixButton
              theme={EvixButtonTheme.H48BlackInline}
              onClick={handleSelect}
              disabled={selectedHospitalIndex === -1}
            >
              선택
            </EvixButton>
          </PagingContainer>
        </Container>
      </Wrapper>
    </Modal>
  )
}

const Container = styled.div`
  padding: 27px 60px 60px 60px;
`

const SearchWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  margin: 16px 0 30px 0;
  padding: 30px;
  border-radius: 4px;
  border: solid 1px ${COLORS.GRAYE0};
`

const TableWrap = styled.div`
  height: 384px;

  > table {
    width: 100%;
  }
`
const PagingContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-top: 30px;
`

const EmptyContentWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100% - 100px);

  position: relative;
  top: -287px;
`
