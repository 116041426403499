import React from 'react'
import { DoctorProfileHospitalCommon } from '../../common/doctor/detail/DoctorProfileHospitalCommon'
import { useODDoctorAppContext } from '../context/ODDoctorAppContext'

interface IDoctorProfileHospitalProps {}

export const DoctorProfileHospital: React.FC<IDoctorProfileHospitalProps> = props => {
  const {
    state: { profile },
  } = useODDoctorAppContext()

  const name = profile?.hospital.name ?? '-'

  return <DoctorProfileHospitalCommon name={name} />
}
