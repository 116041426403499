import React from 'react'
import { Modal } from "reactstrap";
import { ODVSpace} from '@mdpp/od-react-belt'
import { EvixButton, EvixButtonTheme } from '../../common/EvixButton'
import { EvixInlineInputText } from '../../common/EvixInlineInputText' 
import IconClose from '../../assets/common/icon-close.svg'
import { Wrapper, Header, TitleWrap, Title, RequiredWrap, Oval, RequiredText, FormWrap, FormBlock, LabelWrap, Label, BtnWrap  } from '../../common/style/ModalStyle'
import { HospitalNameWrap } from '../../common/style/SignUpStyle'
import styled from "styled-components/macro";

interface DirectInputModalProps {
  style?: object
  onCancelPress: () => void
  onInput: (hospitalName: string, hospitalAddr: string, hospitlaPostNo: string, hospitalTel: string) => void
}

export const DirectInputModal: React.FC<DirectInputModalProps> = props => {
  const { style, onInput, onCancelPress } = props

  const [hospitalName, setHospitalName] = React.useState('')
  const [hospitalAddress1, setHospitalAddress1] = React.useState('')
  const [hospitalAddress2, setHospitalAddress2] = React.useState('')
  const [hospitalTel, setHospitalTel] = React.useState('')
  
  const handleSave = () => {
    onInput(hospitalName, hospitalAddress1, hospitalAddress2, hospitalTel)
  }

  return (
    <Modal isOpen={true} toggle={() => {}} style={style}>
      <Wrapper>
        <Header>
          <img src={IconClose} onClick={onCancelPress} alt='close icon'/>
        </Header>

        <Container>
          <TitleWrap className='line'>
            <Title>병/의원직접 입력</Title>
            <RequiredWrap>
              <Oval></Oval>
              <RequiredText>필수입력 항목입니다.</RequiredText>
            </RequiredWrap>
          </TitleWrap>

          <ODVSpace h={35} />
          <FormWrap>
            <FormBlock>
              <LabelWrap>
                <Label>병/의원 명</Label>
                <Oval></Oval>
              </LabelWrap>
              <EvixInlineInputText style={{width: 610}} value={hospitalName} onChange={value => setHospitalName(value)} disabled={false} maxLength={50}/>
            </FormBlock>

            <FormBlock>
              <LabelWrap>
                <Label>병원 주소</Label>
                <Oval></Oval>
              </LabelWrap>
              <HospitalNameWrap className='w-100'>
                <EvixInlineInputText style={{width: 610}} value={hospitalAddress1} onChange={value => setHospitalAddress1(value)} disabled={false} />
                <EvixInlineInputText style={{width: 80}} value={hospitalAddress2} onChange={value => setHospitalAddress2(value)} disabled={false} />
              </HospitalNameWrap>
            </FormBlock>

            <FormBlock>
              <LabelWrap>
                <Label>병원 연락처</Label>
                <Oval></Oval>
              </LabelWrap>
              <EvixInlineInputText style={{width: 340}} value={hospitalTel} onChange={value => setHospitalTel(value)} disabled={false} />
            </FormBlock>
          </FormWrap>
          <ODVSpace h={40} />

          <BtnWrap>
            <EvixButton theme={EvixButtonTheme.H48BlackInline} onClick={handleSave}
                        disabled={hospitalName === '' || hospitalAddress1 === '' || hospitalAddress2 === '' || hospitalTel === ''}
                        style={{ width: 100 }}>
              입력
            </EvixButton>
          </BtnWrap>
        </Container>
      </Wrapper>
    </Modal>
  )
}

const Container = styled.div `
  padding: 27px 60px 60px 60px;
`
