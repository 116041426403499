import React from 'react'
import { Modal } from 'reactstrap'
import styled from "styled-components/macro";
import IconClose from '../../assets/common/icon-close.svg'
import { Wrapper, Header, Container } from '../../common/style/ModalStyle'
import DaumPostcode, { Address } from 'react-daum-postcode';

interface AddressSearchModalProps {
  style?: object
  onSelected: (address: string, zonecode: string) => void
  onCancelPress: () => void
}

export const AddressSearchModal: React.FC<AddressSearchModalProps> = props => {
  const { style, onSelected, onCancelPress } = props

  const handleComplete = (data: Address) => {
    let fullAddress = data.userSelectedType === 'J' ? data.jibunAddress : data.roadAddress;

    fullAddress += (data.buildingName !== '' ? ` (${data.buildingName})` : '');
    onSelected(fullAddress, data.zonecode)
    onCancelPress()
  }

  return (
    <Modal isOpen={true} toggle={() => {}} style={style}>
      <Wrapper>
        <Header style={{paddingBottom: 10}}>
          <img src={IconClose} onClick={onCancelPress} alt="close icon" />
        </Header>
        <Container>
          <DaumPostcodeWrap>
            <DaumPostcode
              onComplete={handleComplete}
              {...props}
            />
          </DaumPostcodeWrap>
        </Container>
      </Wrapper>
    </Modal>
  )
}

const DaumPostcodeWrap = styled.div`
  overflow: hidden;

   > div {
     width: 100% !important;
     border-color: none !important;
   }
`