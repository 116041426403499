import { BlockingLoadBox, useWrappedAPI } from '@mdpp/od-react-belt'
import React, { useEffect } from 'react'
import {
  GQLPatientMemoCreationInput,
  GQLPatientMemoDeleteInput,
  GQLPatientMemoUpdateInput,
  GQLReservation,
  GQLPatientMemo
} from '@mdpp/common/lib/@types/server.schema'
import { useDoctorAPIs } from '../../doctor/context/useDoctorAPIs'
import { useManagerAPIs } from '../../manager/context/useManagerAPIs'
import { PatientMemoModalPopup } from './PatientMemoModalPopup'

interface IPatientMemoModalProps {
  show: boolean
  setShow: React.Dispatch<React.SetStateAction<boolean>>
  reservation: GQLReservation
  fromManager?: boolean
}

export const PatientMemoModal: React.FC<IPatientMemoModalProps> = props => {
  const { show, setShow, reservation, fromManager = false } = props
  const profile = reservation.doctor

  const [loading, setLoading] = React.useState<boolean>(false)
  const [refresh, setRefresh] = React.useState<boolean>(false)
  const [memo, setMemo] = React.useState<GQLPatientMemo|null>(null)

  const handleClose = () => {
    setShow(false)
  }

  const docApis = useDoctorAPIs()
  const managerApis = useManagerAPIs()

  const apis = fromManager ? managerApis : docApis

  const { createPatientMemo, deletePatientMemo, updatePatientMemo, getPatientMemo } = apis

  useEffect(() => {
    const updateMemo = async () => {
      try {
        const prevMemo = await getPatientMemo({ doctorId: profile.doctorId, patientId: reservation.patientId })
        setMemo(prevMemo)
      } catch(e) {
        setMemo(null)
      }
      setRefresh(false)
    }
    updateMemo()
  }, [profile.doctorId, reservation.patientId, refresh, show])

  const createMemo = useWrappedAPI((input: GQLPatientMemoCreationInput) => createPatientMemo(input), loading, setLoading, {
    successMessage: '메모를 등록하였습니다.',
    onSuccess: () => {
      setShow(false)
    },
  })

  const updateMemo = useWrappedAPI((input: GQLPatientMemoUpdateInput) => updatePatientMemo(input), loading, setLoading, {
    successMessage: '메모를 수정하였습니다.',
    onSuccess: () => {
      setShow(false)
    },
  })

  const deleteMemo = useWrappedAPI((input: GQLPatientMemoDeleteInput) => deletePatientMemo(input), loading, setLoading, {
    successMessage: '메모를 삭제하였습니다.',
    onSuccess: () => {
      setShow(false)
    }
  })

  return (
    <div>
      <BlockingLoadBox show={loading} />
      {!loading && show && (
        <PatientMemoModalPopup
          isOpen={show}
          onClose={() => handleClose()}
          onSubmit={memo ? updateMemo : createMemo}
          onDelete={deleteMemo}
          profile={profile}
          reservation={reservation}
          memo={memo}
          refresh={setRefresh}
        />
      )}
    </div>
  )
}
