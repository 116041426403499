import React from 'react'
import { VIDEO_TAG_ID } from '../RoomCommon'

interface IAppContextType {
  isMicOn: boolean
  isVideoOn: boolean
  isShareScreenOn: boolean
  isFullScreenOn: boolean
  isCurrentCamOff: boolean // 현재 포커스 되어 있는 카메라가 꺼져있는가?
  isPatientJoined: boolean //
  isPatientMicOn: boolean
  isPatientVideoOn: boolean
  selectedVideoId: VIDEO_TAG_ID
  setMicOnOff: (isOn: boolean) => void
  setVideoOnOff: (isOn: boolean) => void
  setShareScreenOnOff: (isOn: boolean) => void
  setFullScreenOnOff: (isOn: boolean) => void
  setPatientJoined: (isJoined: boolean) => void
  setSelectedVideoId: (value: ((prevState: VIDEO_TAG_ID) => VIDEO_TAG_ID) | VIDEO_TAG_ID) => void
  setIsPatientMicOn: (isOn: boolean) => void
  setIsPatientVideoOn: (isOn: boolean) => void
}

// tslint:disable-next-line:interface-over-type-literal
type AppContextProviderProps = {}

function createEvixRoomContext() {
  // tslint:disable-next-line:no-object-literal-type-assertion
  const EvixContext: React.Context<IAppContextType> = React.createContext<IAppContextType>({} as IAppContextType)

  // tslint:disable-next-line:no-shadowed-variable
  const EvixRoomProvider: React.FC<AppContextProviderProps> = props => {
    const { children } = props

    const [selectedVideoId, setSelectedVideoId] = React.useState(VIDEO_TAG_ID.Patient)
    const [isMicOn, setIsMicOn] = React.useState(true)
    const [isVideoOn, setIsVideoOn] = React.useState(true)
    const [isShareScreenOn, setIsShareScreenOn] = React.useState(false)
    const [isFullScreenOn, setIsFullScreenOn] = React.useState(false)
    const [isPatientJoined, setIsPatientJoined] = React.useState(false)
    const [isPatientMicOn, setIsPatientMicOn] = React.useState(true)
    const [isPatientVideoOn, setIsPatientVideoOn] = React.useState(true)

    const setMicOnOff = React.useCallback(
      (toOn: boolean) => {
        setIsMicOn(toOn)
        // TODO: actual configuration
      },
      [setIsMicOn]
    )

    const setVideoOnOff = React.useCallback(
      (toOn: boolean) => {
        setIsVideoOn(toOn)
        // TODO: actual configuration
      },
      [setIsVideoOn]
    )

    const setShareScreenOnOff = React.useCallback(
      (toOn: boolean) => {
        setIsShareScreenOn(toOn)
      },
      [setIsShareScreenOn]
    )

    const setFullScreenOnOff = React.useCallback(
      (toOn: boolean) => {
        setIsFullScreenOn(toOn)
      },
      [setIsFullScreenOn]
    )

    const setPatientJoined = React.useCallback(
      (isJoined: boolean) => {
        setIsPatientJoined(isJoined)
      },
      [setIsPatientJoined]
    )

    const context: IAppContextType = {
      isMicOn,
      isVideoOn,
      isShareScreenOn,
      isFullScreenOn,
      isCurrentCamOff: true,
      isPatientJoined,
      isPatientMicOn,
      isPatientVideoOn,
      setMicOnOff,
      setVideoOnOff,
      setShareScreenOnOff,
      setFullScreenOnOff,
      setPatientJoined,
      selectedVideoId,
      setSelectedVideoId,
      setIsPatientMicOn,
      setIsPatientVideoOn,
    }
    return <EvixContext.Provider value={context}>{children}</EvixContext.Provider>
  }

  return { Context: EvixContext, Provider: EvixRoomProvider }
}

const { Context, Provider } = createEvixRoomContext()

export const EvixRoomProvider = Provider

export function useEvixRoom(): IAppContextType {
  return React.useContext(Context)
}
