import React from 'react'
import styled from 'styled-components'
import { COLORS } from '../styles/colors'

  // 버튼 네임 컨벤션
  // {Type}+{Color}+{Size}
  // ex) {Surface}+{Neutral}+{Small}

export enum EvixButtonTheme {
  NormalW100H44Rounded,
  NormalW100H35Rounded,
  NormalW100H44RoundedLine,
  NormalW100H35RoundedLine,
  ToggleW142H50Selected,
  ToggleW142H50NotSelected,
  PrimaryFullWidth,
  RevertPrimaryFullWidth,
  Primary,
  RevertPrimary,
  H34Selected,

  // 블랙배경 버튼
  H48Black,
  H48BlackInline,
  //화이트배경에 회색라인 버튼
  H48White,
  H48WhiteInline,
  H48WhiteRoundInline,

  H48BlueInline,
  H48BlueLineInline,

  //여기부터 레베카 형식으로 ...
  SurfaceNeutralSmall

}

interface IEvixButtonProps {
  theme: EvixButtonTheme
  onClick?: (e: any) => void
  disabled?: boolean
  type?: 'button' | 'submit' | 'reset' | undefined // button type (ex: submit, button..)
  style?: object
}

interface IWrapperProps {
  border: string | number
  background: string
  textColor: string
  textSize: string
  textWeight: string
  extra?: string
  disabled?: boolean
  borderRadius: number | string
  width?: string
  padding: string
  lineHeight: string
  minWidth: string
  height: string
}

const ButtonWrapper = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border: ${(p: IWrapperProps) => p.border};
  border-radius: ${(p: IWrapperProps) => p.borderRadius};
  background: ${(p: IWrapperProps) => (p.disabled ? 'silver' : p.background)};
  cursor: ${(p: IWrapperProps) => (p.disabled ? 'not-allowed' : 'pointer')};
  ${(p: IWrapperProps) => p.extra || ''}
  ${(p: IWrapperProps) => (p.width ? `width: ${p.width};` : '')}
  padding: ${(p: IWrapperProps) => p.padding};
  min-width: ${(p: IWrapperProps) => p.minWidth};
  height: ${(p: IWrapperProps) => p.height};

  > div {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`

const TextWrapper = styled.div`
  color: ${(p: IWrapperProps) => p.textColor};
  font-size: ${(p: IWrapperProps) => p.textSize};
  line-height: ${(p: IWrapperProps) => p.lineHeight};
  font-weight: ${(p: IWrapperProps) => p.textWeight};
  text-align: center;

`

const propsForTheme = {
  [EvixButtonTheme.NormalW100H44Rounded]: {
    padding: '0',
    textSize: '15px',
    textColor: COLORS.WHITE,
    textWeight: '500',
    lineHeight: '18px',
    borderRadius: '4px',
    border: 0,
    background: COLORS.mpPrimary500,
    minWidth: '',
    height: ''
  },
  [EvixButtonTheme.NormalW100H35Rounded]: {
    padding: '0',
    textSize: '15px',
    textColor: COLORS.WHITE,
    textWeight: '500',
    lineHeight: '18px',
    borderRadius: '4px',
    border: 0,
    background: COLORS.mpPrimary500,
    minWidth: '',
    height: ''
  },
  [EvixButtonTheme.NormalW100H44RoundedLine]: {
    padding: '0',
    textSize: '15px',
    textColor: COLORS.WHITE,
    textWeight: '500',
    lineHeight: '18px',
    borderRadius: '4px',
    border: `1px solid ${COLORS.mpPrimary500}`,
    background: COLORS.mpPrimary500,
    minWidth: '',
    height: ''
  },
  [EvixButtonTheme.NormalW100H35RoundedLine]: {
    padding: '0',
    textSize: '15px',
    textColor: COLORS.WHITE,
    textWeight: '500',
    lineHeight: '18px',
    borderRadius: '4px',
    border: `1px solid ${COLORS.mpPrimary500}`,
    background: COLORS.mpPrimary500,
    minWidth: '',
    height: ''
  },
  [EvixButtonTheme.ToggleW142H50Selected]: {
    padding: '4px 8px',
    width: '50%',
    textSize: '14px',
    textColor: COLORS.WHITE,
    textWeight: '500',
    lineHeight: '18px',
    borderRadius: '6px',
    border: `1px solid ${COLORS.mpPrimary500}`,
    background: COLORS.mpPrimary500,
    minWidth: '',
    height: ''
  },
  [EvixButtonTheme.ToggleW142H50NotSelected]: {
    padding: '4px 8px',
    width: '50%',
    textSize: '14px',
    textColor: COLORS.GRAY600,
    textWeight: '500',
    lineHeight: '18px',
    borderRadius: '6px',
    border: ``,
    background: COLORS.WHITE,
    minWidth: '',
    height: ''
  },
  [EvixButtonTheme.PrimaryFullWidth]: {
    padding: '12px 0px 12px 0px',
    width: '100%',
    textSize: '15px',
    textColor: COLORS.WHITE,
    textWeight: '500',
    lineHeight: '18px',
    borderRadius: '4px',
    border: `1px solid ${COLORS.mpPrimary400}`,
    background: COLORS.mpGradientForButton,
    minWidth: '',
    height: ''
  },
  [EvixButtonTheme.RevertPrimaryFullWidth]: {
    padding: '0',
    width: '100%',
    textSize: '15px',
    textColor: COLORS.mpPrimary500,
    textWeight: '500',
    lineHeight: '18px',
    borderRadius: '4px',
    border: `1px solid ${COLORS.mpNeutral100}`,
    background: COLORS.mpNeutral100,
    minWidth: '',
    height: ''
  },
  [EvixButtonTheme.Primary]: {
    padding: '5px 10px',
    textSize: '14px',
    textColor: COLORS.WHITE,
    textWeight: '500',
    lineHeight: '18px',
    borderRadius: '4px',
    border: `1px solid ${COLORS.mpPrimary400}`,
    background: COLORS.mpGradientForButton,
    minWidth: '',
    height: ''
  },
  [EvixButtonTheme.RevertPrimary]: {
    padding: '5px 10px',
    textSize: '14px',
    textColor: COLORS.mpPrimary500,
    lineHeight: '18px',
    textWeight: '500',
    borderRadius: '4px',
    border: `1px solid ${COLORS.mpNeutral100}`,
    background: COLORS.mpNeutral100,
    minWidth: '',
    height: ''
  },
  [EvixButtonTheme.SurfaceNeutralSmall]: {
    padding: '6px 12px',
    lineHeight: '18px',
    textSize: '14px',
    textColor: COLORS.GRAY900,
    textWeight: '500',
    borderRadius: '4px',
    border: ``,
    background: COLORS.mpNeutral100,
    minWidth: '',
    height: ''
  },
  // yjkim 추가
  [EvixButtonTheme.H34Selected]: {
    padding: '10px 20px',
    textSize: '14px',
    textColor: COLORS.WHITE,
    textWeight: '500',
    lineHeight: '18px',
    borderRadius: '6px',
    border: `1px solid ${COLORS.mpPrimary500}`,
    background: COLORS.mpPrimary500,
    minWidth: '',
    height: ''
  },
  [EvixButtonTheme.H48Black]: {
    padding: '13px',
    textSize: '15px',
    textColor: COLORS.WHITE,
    textWeight: 'bold',
    lineHeight: '18px',
    borderRadius: '4px',
    border: ``,
    background: COLORS.GRAY2D,
    minWidth: '',
    height: ''
  },
  [EvixButtonTheme.H48White] : {
    padding: '13px',
    textSize: '15px',
    textColor: COLORS.GRAY42,
    textWeight: '500',
    lineHeight: '18px',
    borderRadius: '4px',
    border: `1px solid ${COLORS.GRAYAB2}`,
    background: COLORS.WHITE,
    minWidth: '',
    height: ''
  },
  [EvixButtonTheme.H48BlackInline]: {
    padding: '12px',
    textSize: '13px',
    textColor: COLORS.WHITE,
    textWeight: '500',
    lineHeight: '18px',
    borderRadius: '4px',
    border: ``,
    background: COLORS.GRAY2D,
    minWidth: '80px',
    height: ''
  },
  [EvixButtonTheme.H48WhiteInline] : {
    minWidth: '80px',
    padding: '12px',
    textSize: '13px',
    textColor: COLORS.GRAY42,
    textWeight: '500',
    lineHeight: '18px',
    borderRadius: '4px',
    border: `1px solid ${COLORS.GRAYAB2}`,
    background: COLORS.WHITE,
    height: '44px'
  },

  [EvixButtonTheme.H48WhiteRoundInline] : {
    minWidth: '80px',
    padding: '11px 0 12px 0',
    textSize: '12px',
    textColor: COLORS.GRAY42,
    textWeight: '500',
    lineHeight: '12px',
    borderRadius: '18.5px',
    border: `1px solid ${COLORS.GRAYD5}`,
    background: COLORS.WHITE,
    height: '37px'
  },

  [EvixButtonTheme.H48BlueInline] : {
    minWidth: '80px',
    padding: '12px',
    textSize: '13px',
    textColor: COLORS.WHITE,
    textWeight: '500',
    lineHeight: '18px',
    borderRadius: '4px',
    border: `1px solid ${COLORS.blue200}`,
    background: COLORS.blue200,
    height: '44px'
  },

  [EvixButtonTheme.H48BlueLineInline] : {
    minWidth: '80px',
    padding: '12px',
    textSize: '13px',
    textColor: '#007EFF',
    textWeight: '500',
    lineHeight: '18px',
    borderRadius: '4px',
    border: '1px solid #007EFF',
    background: COLORS.WHITE,
    height: '44px'
  },
}

const buttonClassName = {
  [EvixButtonTheme.NormalW100H44Rounded]: 'w-100',
  [EvixButtonTheme.NormalW100H35Rounded]: 'w-100',
  [EvixButtonTheme.NormalW100H44RoundedLine]: 'w-100',
  [EvixButtonTheme.NormalW100H35RoundedLine]: 'w-100',
  [EvixButtonTheme.ToggleW142H50Selected]: '',
  [EvixButtonTheme.ToggleW142H50NotSelected]: '',
  [EvixButtonTheme.PrimaryFullWidth]: '',
  [EvixButtonTheme.RevertPrimaryFullWidth]: '',
  [EvixButtonTheme.Primary]: '',
  [EvixButtonTheme.RevertPrimary]: '',
  [EvixButtonTheme.SurfaceNeutralSmall]: '',
  [EvixButtonTheme.H34Selected]: '',
  [EvixButtonTheme.H48Black]: 'w-100',
  [EvixButtonTheme.H48BlackInline]: '',
  [EvixButtonTheme.H48White]: 'w-100',
  [EvixButtonTheme.H48WhiteInline]: '',
  [EvixButtonTheme.H48WhiteRoundInline]: '',
  [EvixButtonTheme.H48BlueInline]: '',
  [EvixButtonTheme.H48BlueLineInline]: '',
}

const disabledStyleProps = {
  background: COLORS.GRAY200,
  textColor: COLORS.GRAY400,
  border: 'none'
}

export const EvixButton: React.FC<IEvixButtonProps> = props => {
  const { children, theme, onClick, disabled, type, style } = props

  const className = buttonClassName[theme]
  const styleProps = propsForTheme[theme]
  const addProps = disabled ? disabledStyleProps : {}

  return (
    <ButtonWrapper
      type={type}
      {...{ ...styleProps, ...addProps }}
      className={className}
      onClick={!disabled ? onClick : (e: any) => {e.preventDefault()}}
      style={style}
    >
      <TextWrapper {...{ ...styleProps, ...addProps, }}>{children}</TextWrapper>
    </ButtonWrapper>
  )
}
