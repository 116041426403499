import React from 'react'
import { DoctorListableTable } from '../../common/doctor/list/DoctorListableTable'
import {Page, PageContainer, PageHeader, PageTitle} from "../../common/page/Page";

interface IAdminDoctorPageProps {
  hospitalId: number
}

export const AdminDoctorListPage: React.FC<IAdminDoctorPageProps> = props => {
  return (
    <PageContainer>
      <Page>
        <PageHeader>
            <PageTitle>의사</PageTitle>
        </PageHeader>
        
        <DoctorListableTable fromHospitalManager={false} />
      </Page>
    </PageContainer>
  )
}
