import firebase from 'firebase/app';
import 'firebase/messaging';
import { AppOptions } from "../AppOptions";
import { WebUtils } from "./webUtils";
import { ToastOptions } from "react-toastify";

export class WebNotificationCls {
  private isInitialized = false
  public messaging : firebase.messaging.Messaging | undefined = undefined
  
  init() {
    const firebaseConfig = {
      apiKey: AppOptions?.FIREBASE_APIKEY ?? '',
      authDomain: AppOptions?.FIREBASE_AUTH_DOMAIN ?? '',
      projectId: AppOptions?.FIREBASE_PROJECT_ID ?? '',
      storageBucket: AppOptions?.FIREBASE_STORAGE_BUCKET ?? '',
      messagingSenderId: AppOptions?.FIREBASE_MESSAGING_SENDER_ID ??'',
      appId: AppOptions?.FIREBASE_APP_ID ?? '',
      measurementId: AppOptions?.FIREBASE_MEASUREMENT_ID ?? ''
    };
  
    if (firebaseConfig.apiKey && firebase.messaging.isSupported()) {
      console.log('firebase initializeApp')
      // firebase.initializeApp({});
      if (!firebase.apps.length) {
        firebase.initializeApp(firebaseConfig);
      } else {
        firebase.app(); // if already initialized, use that one
      }
  
      this.messaging = firebase.messaging();
    }
  
    this.isInitialized = true
  }
  
  getToken() {
    const vapidKey = AppOptions.FIREBASE_VAPID_KEY;
    if (!this.isInitialized)
      this.init()
    if (this.messaging === undefined)
      return null
    if (vapidKey === null)
      return null
    return this.messaging.getToken({vapidKey})
      .then((currentToken) => {
        if (currentToken) {
          console.log('current token for client: ', currentToken);
        } else {
          console.log('No registration token available. Request permission to generate one.');
        }
        return currentToken
      }).catch((err) => {
        console.error('An error occurred while retrieving token. ', err);
        return null
      });
  }
  
  deleteToken() {
    if (this.messaging)
      this.messaging.deleteToken().catch((err) => {
        console.error('An error occurred while retrieving token. ', err);
      });
  }
  
  onMessageListener() {
    if (this.messaging) {
      const options : ToastOptions = {
        autoClose : 20000
      }
      
      this.messaging.onMessage((payload) => {
        console.log('onMessage: ', payload);
        WebUtils.showWebPush(payload.data.activeText ?? payload.notification.body,
          payload.notification.title,
          payload.data.linkTo ?? undefined,
          payload.data.linkText ?? undefined,
          options
        )
      });
    }
  }
}
export const WebNotification = new WebNotificationCls()
