import {
  BlockingLoadBox,
  ODButton,
  ODButtonSize,
  ODButtonTheme,
  ODIcon,
  ODIcons,
  ODListablePaginatedTable2,
  ODListablePagination,
  ODVSpace,
  useCounter,
  useODListableContext,
} from '@mdpp/od-react-belt'
import React from 'react'
import { Link } from 'react-router-dom'
import { Card, CardBody } from 'reactstrap'
import styled from 'styled-components'
import { GQLAppVersion } from '@mdpp/common/lib/@types/server.schema'
import { SiteUrls } from '../../../SiteUrls'
import { WebUtils } from '../../../utils/webUtils'
import { AppVersionPrimaryKey, IAppVersionListableOption } from '../AppVersionCommon'
import { useAppVersionListDataLoader } from './AppVersionListDataLoader'
import { AppVersionNoticeBox } from './AppVersionNoticeBox'
import { EvixCard } from '../../../common/card/EvixCard'
interface IAppVersionListableTableProps {}

export const AppVersionListableTable: React.FC<IAppVersionListableTableProps> = props => {
  const { Provider, Context } = useODListableContext<GQLAppVersion, IAppVersionListableOption>()
  const [loading] = React.useState(false)
  const [token] = useCounter()
  const dataLoader = useAppVersionListDataLoader()

  const toolbarStyle = { display: 'flex', justifyContent: 'space-between', marginBottom: 15, alignItems: 'center' }
  const wrapperStyle= { display: 'flex', alignItems: 'center', justifyContent: 'center' }
  return (
    <Provider
      dataLoader={dataLoader}
      keyExtractor={v => v[AppVersionPrimaryKey].toString()}
      pageSize={10}
      refreshToken={token.toString()}
      onDataLoaderError={WebUtils.showError}
      searchOnLoad
    >
      <BlockingLoadBox show={loading} />
      
        <EvixCard>
          <div style={toolbarStyle}>
            <InfoWrapper>
              <InfoIconWrapper>
                <ODIcon icon={ODIcons.MaterialError} />
              </InfoIconWrapper>
              <TextAreaHelpText>도움말</TextAreaHelpText>
            </InfoWrapper>
            <Link to={SiteUrls.Admin.AppVersion.Create} style={{ textDecoration: 'none' }}>
              <ODButton
                theme={ODButtonTheme.Primary}
                size={ODButtonSize.Small}
                rounded
                style={{ minWidth: 100 }}
              >
                버전 추가
              </ODButton>
            </Link>
          </div>

          <AppVersionNoticeBox />
        </EvixCard>
        <EvixCard noPadding>
          <ODListablePaginatedTable2
            numColumns={4}
            listableContext={Context}
            renderHeader={() => (
              <tr>
                <th style={{whiteSpace: 'nowrap', textAlign: 'center'}}>버전</th>
                <th style={{whiteSpace: 'nowrap', textAlign: 'center'}}>빌드</th>
                <th style={{whiteSpace: 'nowrap', textAlign: 'center'}}>플랫폼</th>
                <th style={{whiteSpace: 'nowrap', textAlign: 'center'}}>주요 기능</th>
                <th style={{whiteSpace: 'nowrap', textAlign: 'center'}}>종류</th>
                <th style={{whiteSpace: 'nowrap', textAlign: 'center'}}>작성 시각</th>
                <th style={{whiteSpace: 'nowrap', textAlign: 'center'}}>관리</th>
              </tr>
            )}
            renderRow={(value: GQLAppVersion, context) => (
              <tr key={value[AppVersionPrimaryKey]} style={{borderBottom: '1px solid #D1D5DA'}}>
                <td align='center'>
                  <VersionText>{value.version}</VersionText>
                </td>
                <td align='center'>
                  <VersionText>{value.build}</VersionText>
                </td>
                <td>
                  {[value.isAndroid ? '안드로이드' : null, value.isIOS ? 'iOS' : null].filter(v => v).join(',') || '-'}
                </td>
                <td>{value.description}</td>
                <td align='center'>{WebUtils.formatAppVersionUpdateType(value.updateType)}</td>
                <td align='center'>{WebUtils.formatDateTime(value.createdAt)}</td>
                <td align='center'>
                  <Link to={SiteUrls.Admin.AppVersion.Edit(value.appVersionId)}>[편집]</Link>
                </td>
              </tr>
            )}
            eventParentContext={{}}
          />
        </EvixCard>
        <ODListablePagination hideIfSinglePage listableContext={Context} wrapperStyle={wrapperStyle} />
    </Provider>
  )
}

const VersionText = styled.div`
  font-size: 14px;
  font-weight: bold;
  color: var(--mpPrimary500);
`

const InfoWrapper = styled.div`
  display: flex;
`

const InfoIconWrapper = styled.div`
  color: var(--mpPrimary500);
  transform: scale(0.7);
  margin-top: -2px;
  margin-right: 7px;
`

const TextAreaHelpText = styled.div`
  font-size: 15px;
  font-weight: bold;
  letter-spacing: 0.32px;
  color: var(--mpPrimary500);
`
