import { ODIcon, ODIcons } from '@mdpp/od-react-belt'
import React from 'react'
import styled from 'styled-components'

import { GQLReservation } from '@mdpp/common/lib/@types/server.schema'
import { PatientMemoModal } from './PatientMemoModal'
import { ImageButton } from '../RebeccaButton'
import SVGMemo from '../../assets/memo.svg'

interface IPatientMemoCompProps {
  reservation: GQLReservation,
  type?: string,
  message?: string,
  iconOnly?: boolean,
}

export const PatientMemoComp: React.FC<IPatientMemoCompProps> = props => {
  const { reservation, message, iconOnly = false } = props
  const [show, setShow] = React.useState(false)


  if (iconOnly) {
    return (
      <div style={{display: 'flex', justifyContent: 'center'}} onClick={(e:any) => 
        {
          e.stopPropagation()
          setShow(true)
        }}>
      <PatientMemoModal show={show} setShow={setShow} reservation={reservation} />
      {message && message}
      <IconWrapper2 >
        <ODIcon icon={ODIcons.SVGMemo} />
      </IconWrapper2>
    </div>
    )
  }

  return (
    <>
      <PatientMemoModal show={show} setShow={setShow} reservation={reservation} />
      <ImageButton images={{default: SVGMemo}} onClick={(e:any) => 
        {
          e.stopPropagation()
          setShow(true)
        }}>메모</ImageButton>
    </>
  )
}

const IconWrapper2 = styled.span`
  display: flex;
  margin-top: 1px;
  cursor: pointer;
  font-size: 14px;
  line-height: 32px;
`