import React from 'react'
import {
  CommonReservationListPage,
  ReservationListStyle,
} from '../../common/reservation/list/CommonReservationListPage'

interface IReservationMainPageProps {}

export const DoctorReservationListPage: React.FC<IReservationMainPageProps> = props => {
  return <CommonReservationListPage listStyle={ReservationListStyle.Doctor} />
}
