import { ODEntity, ODVSpace } from '@mdpp/od-react-belt'
import gql from 'graphql-tag'
import React from 'react'
import styled from 'styled-components'
import {
  GQLDoctorCreationInput,
  GQLWaitingDoctor,
  GQLWaitingDoctorUpdateInput,
} from '@mdpp/common/lib/@types/server.schema'
import { WaitingDoctorProfileEdit } from '../../../common/waitingDoctor/detail/WaitingDoctorProfileEdit'
import { GQL_GET_WAITING_DOCTOR, GQL_UPDATE_WAITING_DOCTOR } from '../../../common/gqls_common'
import { useODEntityAPI } from '../../../utils/odGQLEntityLoader'
import { EvixCard } from '../../../common/card/EvixCard'
import { WaitingDoctorPrimaryKey, ICTWaitingDoctor } from '../../../common/waitingDoctor/WaitingDoctorCommon'
import { useCommonAPIs } from '../../../common/hooks/useCommonAPIs'
import { validator } from '../../../validator'
import { WebUtils } from '../../../utils/webUtils'

interface IAdminDoctorProfilePageProps {
  doctorId: number
  fromManager?: boolean
  fromAdmin?: boolean
}

const DEFAULT_DOCTOR_DATA: Partial<ICTWaitingDoctor> = {
  waitingDoctorId: 0,
  name: ``,
}

export const AdminWaitingDoctorProfilePage: React.FC<IAdminDoctorProfilePageProps> = props => {
  const { doctorId, fromManager = false, fromAdmin = true } = props
  const apis = useODEntityAPI<GQLWaitingDoctor, ICTWaitingDoctor, GQLDoctorCreationInput, GQLWaitingDoctorUpdateInput>({
    readGQL: gql(GQL_GET_WAITING_DOCTOR),
    updateGQL: gql(GQL_UPDATE_WAITING_DOCTOR),
    primaryKeyName: WaitingDoctorPrimaryKey,
  })

  const { checkLicense } = useCommonAPIs()

  const diffFuction = async (updated: ICTWaitingDoctor, original: ICTWaitingDoctor) => {
    let ret: Partial<ICTWaitingDoctor> = {}
    if (updated.birthDate !== original.birthDate) {
      ret.birthDate = updated.birthDate
      const validationRet = validator.birth(ret?.birthDate || '')
      if (!ret?.birthDate || (validationRet.errors && validationRet.errors.length > 0)) {
        WebUtils.showError(
          validationRet.errors && validationRet.errors.length > 0 ? validationRet.errors[0].toString() : ''
        )
        return null
      }
    }

    if (updated.licenseName !== original.licenseName) {
      ret.licenseName = updated.licenseName

      const validationRet = validator.doctorCode(ret?.licenseName || '')
      if (!ret?.licenseName || (validationRet.errors && validationRet.errors.length > 0)) {
        WebUtils.showError(
          validationRet.errors && validationRet.errors.length > 0 ? validationRet.errors[0].toString() : ''
        )
        return null
      }
      try {
        const r = await checkLicense({ licenseName: ret.licenseName || '' })
        if (r.ok !== 1) {
          // 이미 등록된 면허
          WebUtils.showError('이미 등록된 면허번호입니다.')
          return null
        }
      } catch {
        return null
      }
    }

    Object.keys(original).forEach(key => {
      // @ts-ignore
      if (updated[key] !== original[key]) {
        // @ts-ignore
        ret[key] = updated[key]
      }
    })

    return ret
  }

  return (
    <EvixCard>
      <Wrapper>
        <ODEntity
          resourceId={doctorId}
          api={apis}
          saveButtonName="저장"
          titleCreation="추가"
          createSuccessTitle="성공"
          createSuccessMessage="추가하였습니다."
          titleUpdate="수정"
          updateSuccessTitle="성공"
          updateSuccessMessage="수정하였습니다."
          urlAfterDelete={(item: ICTWaitingDoctor) => `/admin/doctor`}
          deleteSuccessTitle="성공"
          deleteSuccessMessage="삭제하였습니다."
          defaultCreateClientData={DEFAULT_DOCTOR_DATA}
          urlAfterCreation={() => ``}
          urlAfterUpdate={() => null}
          noCardWrap
          diffClientValue={diffFuction}
        >
          <WaitingDoctorProfileEdit fromManager={fromManager} fromAdmin={fromAdmin} doctorId={doctorId} />
          <ODVSpace h={24} />
        </ODEntity>
      </Wrapper>
    </EvixCard>
  )
}

const Wrapper = styled.div`
  padding: 10px;
`
