import { ODEntity, ODEntityInput, ODEntityLabeled } from '@mdpp/od-react-belt'
import gql from 'graphql-tag'
import React from 'react'
import { Card, CardBody } from 'reactstrap'
import styled from 'styled-components/macro'
import { GQLFaq, GQLFaqCreationInput, GQLFaqUpdateInput } from '@mdpp/common/lib/@types/server.schema'
import { SiteUrls } from '../../../SiteUrls'
import { COLORS } from '../../../styles/colors'
import { useODEntityAPI } from '../../../utils/odGQLEntityLoader'
import { GQL_CREATE_FAQ, GQL_DELETE_FAQ, GQL_GET_FAQ, GQL_UPDATE_FAQ } from '../../gqls_common'
import { MainContentWithInset } from '../../layout/MainContentWithInset'
import { FaqPrimaryKey, ICTFaq } from '../FaqCommon'
import {TableList, TableListWrapper} from "../../list/TableList";
import { EvixCard } from '../../card/EvixCard'
import { PageContainer, Page, PageHeader, PageTitle, PageContent } from '../../page/Page'

interface IFaqEditPageProps {
  idEditing: number | null
}

const DEFAULT_DATA: Partial<ICTFaq> = {
  faqId: 0,
  title: ``,
  link: '',
  priority: 10,
}

export const FaqEditPage: React.FC<IFaqEditPageProps> = props => {
  const { idEditing } = props

  const apis = useODEntityAPI<GQLFaq, ICTFaq, GQLFaqCreationInput, GQLFaqUpdateInput>({
    createGQL: gql(GQL_CREATE_FAQ),
    updateGQL: gql(GQL_UPDATE_FAQ),
    readGQL: gql(GQL_GET_FAQ),
    deleteGQL: gql(GQL_DELETE_FAQ),
    primaryKeyName: FaqPrimaryKey,
  })

  return (
    <PageContainer>
        <Page>
          <PageHeader>
            <PageTitle>자주하는 질문 추가</PageTitle>
          </PageHeader>

          <EvixCard>
            <ODEntity
              resourceId={idEditing}
              api={apis}
              saveButtonName="저장"
              titleCreation="추가"
              createSuccessTitle="성공"
              createSuccessMessage="자주하는 질문이 추가되었습니다."
              titleUpdate="수정"
              updateSuccessTitle="성공"
              updateSuccessMessage="수정하였습니다."
              urlAfterDelete={(item: ICTFaq) => SiteUrls.Admin.Faq.Main}
              deleteSuccessTitle="성공"
              deleteSuccessMessage="삭제하였습니다."
              defaultCreateClientData={DEFAULT_DATA}
              urlAfterCreation={() => SiteUrls.Admin.Faq.Main}
              urlAfterUpdate={() => SiteUrls.Admin.Faq.Main}
              noCardWrap
              footerProps={{
                deleteButtonStyle: {
                  borderColor: COLORS.DANGER,
                  color: COLORS.DANGER,
                  backgroundColor: COLORS.RED10,
                },
              }}
              deleteButtonName="삭제"
            >
              <Wrapper>
            <TableList name="title" label="제목">
              <ODEntityInput
                keyPath="title"
                label="제목"
                name="title"
                placeholder="제목을 입력해주세요."
                inputType="text"
              />
              </TableList>

              <TableList name="link" label="URL">
              <ODEntityInput
                keyPath="link"
                label="URL"
                name="link"
                placeholder="링크를 입력해주세요."
                inputType="text"
              />
              </TableList>
              <TableList name="priority" label="우선순위">
              <ODEntityInput keyPath="priority" label="우선순위" name="priority" placeholder="" inputType="number" />
              <TextAreaHelpText>
                  우선순위는 숫자만 입력 가능하며, 숫자가 높은 질문이 상단에 표시됩니다.
                </TextAreaHelpText>
              </TableList>
            
              </Wrapper>
              <div style={{ marginTop: 30, marginBottom: 30 }}>
                <hr />
              </div>
            </ODEntity>
          </EvixCard>
          </Page>
    </PageContainer>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 10px;
  `

const TextAreaHelpText = styled.div`
  position: relative;
  padding-left: 25px;
  padding-top: 10px;
  font-size: 12px;
  line-height: 1.17;
  color: var(--gray600);

  ::before {
    content: '✳︎';
    position: absolute;
    left: 13px;
  }
`
