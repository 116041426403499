import { ODEntityInput, ODImageFileInput, ODVSpace } from '@mdpp/od-react-belt'
import React from 'react'
import styled from 'styled-components'
import { WebUtils } from '../../../utils/webUtils'
import { TableList } from '../../list/TableList'
import { WithdrawalModal } from './WithdrawalModal'
import { WithdrawalManagerModal } from './WithdrawalManagerModal'
import { GQLDoctor } from '@mdpp/common/lib/@types/server.schema'
import { ConfirmModal } from '../../../common/layout/ConfirmModal'
import IconWarningBlue from '../../../assets/common/warning-blue.svg'
import { useDoctorAPIs } from '../../../doctor/context/useDoctorAPIs'
import { useHistory } from 'react-router-dom'

interface IDoctorProfileEditProps {
  fromManager: boolean
  fromAdmin: boolean
  profile?: GQLDoctor | null
  id?: number
}

export const DoctorProfileEdit: React.FC<IDoctorProfileEditProps> = props => {
  const { fromManager, fromAdmin, profile } = props
  const [showWithdrawalModal, setShowWithdrawalModal] = React.useState(false)
  const [showWithdrawalManagerModal, setShowWithdrawalManagerModal] = React.useState(false)

  const { checkDoctorIsHospitalManager, deleteDoctor } = useDoctorAPIs()

  const history = useHistory()

  const doctorResignCheck = async (doctorId: number) => {
    try {
      const r = await checkDoctorIsHospitalManager({ id: doctorId })
      console.log('병원관리자 확인 === ', r.ok)
      if (r.ok === 0) {
        return setShowWithdrawalModal(true)
      }
      setShowWithdrawalManagerModal(true)
    } catch (err) {
      console.log(err)
    }
  }

  const doctorResign = async (doctorId: number) => {
    try {
      const r = await deleteDoctor({ id: doctorId })
      console.log('탈퇴 확인 === ', r.ok)
      if (r.ok === 1) {
        WebUtils.showSuccess('탈퇴 되었습니다.')
        history.push('/landing')
      }
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <Wrapper>
      <TableList name="profileImage" label="프로필 사진" mandatoryInput>
        <ProfileImageRow className="profile-uploader">
          <DoctorAddInfoWrap>
            <ODImageFileInput
              name="profileImage"
              height={92}
              width={92}
              accept="image/png, image/jpeg, image/bmp"
              keyPath="profileImage.link"
              validate={f => {
                if (f.size > 4000000) {
                  // TODO: 프로필 이미지는 1M 하로 변환해서 저장하는 부분 구현 필요
                  WebUtils.showError('이미지 파일 크기는 최대 4Mb 까지 가능합니다.')
                  return false
                }
                return true
              }}
            />
            <DoctorAddInfo>
              <DoctorAddInfoBlock className="block email">
                <div className="label">이메일 아이디</div>
                {fromAdmin ? (
                  <ODEntityInput keyPath="email" label="" placeholder="" name="email" inputType="text" readOnly />
                ) : (
                  <div className="value">{profile?.email}</div>
                )}
              </DoctorAddInfoBlock>

              <DoctorAddInfoBlock className="block">
                <div className="label">생년월일</div>
                {fromAdmin ? (
                  <ODEntityInput keyPath="birthDate" label="" placeholder="" name="birthDate" inputType="text" />
                ) : (
                  <div className="value">{profile?.birthDate}</div>
                )}
              </DoctorAddInfoBlock>
              <DoctorAddInfoBlock className="block">
                <div className="label">의사면허번호</div>
                {fromAdmin ? (
                  <ODEntityInput keyPath="licenseName" label="" placeholder="" name="licenseName" inputType="text" />
                ) : (
                  <div className="value">{profile?.licenseName}</div>
                )}
              </DoctorAddInfoBlock>
            </DoctorAddInfo>
          </DoctorAddInfoWrap>
          <ProfileImageHelpText>
            <ProfileImageDescWrapper>
              프로필 사진을 등록하지 않으면 병원 로고가 표시됩니다.
              <br />
              새로운 사진으로 교체를 원하는 경우 이미지 위에 드래그를 하거나 현재 이미지를 클릭하여 새로운 사진을
              선택해주세요.
            </ProfileImageDescWrapper>
          </ProfileImageHelpText>
        </ProfileImageRow>
      </TableList>

      {!fromManager && (
        <TableList name="name" label="이름(한글)" mandatoryInput>
          <ODEntityInput keyPath="name" label="" name="name" placeholder="이름을 입력해 주세요." inputType="text" />
        </TableList>
      )}
      <TableList name="phone" label="전화번호" mandatoryInput>
        <ODEntityInput
          keyPath="phone"
          label=""
          name="phone"
          placeholder="예약을 위한 고객센터 전화번호를 입력해 주세요."
          inputType="text"
        />
        <TextAreaHelpText>미입력 시 환자에게 병원 대표번호가 제공됩니다.</TextAreaHelpText>
      </TableList>

      <TableList name="cellPhone" label="휴대전화번호">
        <ODEntityInput
          keyPath="cellPhone"
          label=""
          name="cellPhone"
          placeholder="휴대전화번호를 입력해 주세요."
          inputType="text"
        />
        <TextAreaHelpText>입력하신 번호로 진료 예약 관련 알림 문자 메시지를 드립니다.</TextAreaHelpText>
      </TableList>

      <TableList name="introduction" label="소개" mandatoryInput>
        <ODEntityInput
          keyPath="introduction"
          label=""
          name="introduction"
          placeholder="본인을 외부에 소개해 주세요."
          inputType="textarea"
          rows={2}
        />
      </TableList>

      <TableList name="career" label="학력 및 경력" mandatoryInput>
        <ODEntityInput
          keyPath="career"
          label=""
          name="career"
          placeholder="학력 및 경력을 입력해 주세요."
          inputType="textarea"
          rows={4}
        />
        <TextAreaHelpText>항목별로 줄바꿈(Enter)으로 구분해주세요.</TextAreaHelpText>
      </TableList>
      <ODVSpace h={18} />

      {/*{!fromManager && !fromAdmin && (*/}
      {/*  <TextBtnWrap onClick={() => doctorResignCheck(profile!.doctorId)}>회원 탈퇴하기</TextBtnWrap>*/}
      {/*)}*/}

      {!fromManager && !fromAdmin && (
        <TextBtnWrap style={{ cursor: 'default' }}>탈퇴를 원하시면 고객센터(1566-8013)에 문의해주세요.</TextBtnWrap>
      )}

      {/* 병원관리자 확인 Modal */}
      {showWithdrawalModal && (
        <WithdrawalModal
          style={{ width: 510, height: 600 }}
          onOkPress={() => doctorResign(profile!.doctorId)}
          onCancelPress={() => setShowWithdrawalModal(false)}
        />
      )}

      {/* 탈퇴 Modal */}
      {showWithdrawalManagerModal && (
        <WithdrawalManagerModal
          style={{ width: 510, height: 545 }}
          onOkPress={() => doctorResign(profile!.doctorId)}
          onCancelPress={() => setShowWithdrawalManagerModal(false)}
        />
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 32px;
`

const ProfileImageRow = styled.div`
  display: flex;
  flex-direction: column;
`

const ProfileImageHelpText = styled.p`
  font-size: 12px;
  line-height: 1.5;
  color: var(--gray600);
`

const TextAreaHelpText = styled.div`
  position: relative;
  padding-left: 25px;
  padding-top: 10px;
  font-size: 12px;
  line-height: 1.17;
  color: var(--gray600);

  ::before {
    content: '✳︎';
    position: absolute;
    left: 13px;
  }
`
const ProfileImageDescWrapper = styled.div`
  padding-top: 8px;
`

const DoctorAddInfoWrap = styled.div`
  display: flex;
  flex-direction: row;
  gap: 38px;
`
const DoctorAddInfo = styled.p`
  /* flex: 1; */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-radius: 0 !important;
  width: calc(100% - 130px);
  padding: 18px 50px 18px 30px;
  background-color: #f4f6f8;
  gap: 50px;
`

const DoctorAddInfoBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;

  &.email {
    width: calc(100% - 290px);

    input {
      width: 100%;
      border-color: #f4f6f8 !important;
      background-color: #f4f6f8 !important;
      outline: none !important;
      padding-left: 0 !important;
      padding-right: 0 !important;

      &:focus {
        border-color: #f4f6f8 !important;
        outline: none !important;
      }
    }
  }

  .label {
    font-size: 12px;
    font-weight: 500;
    color: #909ca7;
  }

  .label ~ div,
  .label ~ div > div {
    border-radius: 1px !important;
  }

  .value {
    font-size: 15px;
    font-weight: 500;
    color: #424242;
  }

  input {
    width: 120px;
    height: 35px;
    font-size: 15px;
    font-weight: 500;
    color: #424242;
    display: inline-block !important;
    border-radius: 1px !important;
    background-color: #fff !important;
  }
`

const TextBtnWrap = styled.div`
  position: absolute;
  bottom: -28px;
  left: 0;
  text-decoration: underline;
  font-size: 14px;
  font-weight: 500;
  color: #616c77;
  cursor: pointer;
`
