import React from 'react'
// import logo from '../assets/mydata/logo-mdpp.svg'
// import icClose from '../assets/mydata/ic_close.svg'
// import icAlert from '../assets/mydata/ic_alert.svg'

interface Popup2Props {
  isOpen: boolean
  title: string
  message: string
  onSubmit?: () => void
  onClose: () => void
}

export const Popup2: React.FC<Popup2Props> = props => {
  const { isOpen, onClose, message, title } = props

  return (
    <div className={isOpen ? 'popup-bg popup-white' : 'hide'}>
        <div className="pop-wrap">
            <div className="popup-header">
                <label>{title}</label>
              
                {/* <button className="btn-close">
                    <img src={icClose}  />
                </button> */}
            </div>
            <div className="popup-container">
                <div className="popup-inner">
                 {/* 팝업 컨텐츠 영역이 paragraph로 되어있을 경우는 <p></p>를 사용
                 그 이외에는 p 태그 없이 바로 텍스트를 작성해주세요. */}
                    <p style={{wordBreak: 'keep-all'}}>{message}</p>
                </div>
            </div>

            <div className="popup-bottom">
                <button type="button" className="btn primary" onClick={onClose}>
                    확인
                </button>
            </div>
        </div>
      
    </div>
  )
}
