import { ODHSpace } from '@mdpp/od-react-belt'
import React from 'react'
import moment from 'moment-timezone'
import styled from 'styled-components'
import { DateRangeSelector } from './DateRangeSelector'
import { ReservationStatusSelector } from './ReservationStatusSelector'

export interface IReservationToolbarValue {
  status: DoctorReservationStatus
  startDate: string //  YYYY-MM-DD => '' empty text 면 모든 날짜
  endDate: string //  YYYY-MM-DD => '' empty text 면 모든 날짜
}

export enum DoctorReservationStatus {
  ALL = 'ALL', // 모든 진료
  PENDING = 'PENDING', //  진료 대기
  WAITING = 'WAITING', //  진료 예정
  IN_PROGRESS = 'IN_PROGRESS', // 처방중
  FINISHED = 'FINISHED', //  진료 완료
  CANCELED = 'CANCELED', //  취소된 진료
}

interface IReservationToolBar {
  value: IReservationToolbarValue
  onChange: (changedValue: IReservationToolbarValue) => void
}

const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  flex-grow: 1;
  height: 37px;
`

const TreatmentWrapper = styled.div`
  display: inline-flex;
  gap: 8px;

  @media (max-width: 992px) {
  }
`

export const ReservationToolBar: React.FC<IReservationToolBar> = props => {
  const { value, onChange } = props
  // const { status, endDate, startDate } = value
  const [reservationStatus, setReservationStatus] = React.useState(value.status)
  const [dateRange, setDateRange] = React.useState<string[]>(['', ''])

  const onChangeReservationStatus = (changedValue: DoctorReservationStatus) => {
    setReservationStatus(changedValue)
  }

  const onChangeDateRange = (changedValue: string[]) => {
    setDateRange(changedValue)
  }

  React.useEffect(() => {
    onChange({ status: reservationStatus, startDate: dateRange[0], endDate: dateRange[1] })
  }, [onChange, reservationStatus, dateRange])

  return (
    <Wrapper>
      <TreatmentWrapper>
        <ReservationStatusSelector value={reservationStatus} onChange={onChangeReservationStatus} />
        <DateRangeSelector value={dateRange} onChange={onChangeDateRange} />
      </TreatmentWrapper>
    </Wrapper>
  )
}
