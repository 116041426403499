import React from 'react'
import {Link} from 'react-router-dom'
import moment from 'moment-timezone'
import {ODListablePaginatedTable2, ODListablePagination, ODListableSearchBox, useCounter, useODListableContext, useODModalConfirm, useWrappedAPI,} from '@mdpp/od-react-belt'
import {GQLPatient} from '@mdpp/common/lib/@types/server.schema'
import {SiteUrls} from '../../../SiteUrls'
import {WebUtils} from '../../../utils/webUtils'
import {IPatientListableOption, PatientPrimaryKey} from '../PatientCommon'
import {usePatientListDataLoader} from './PatientListDataLoader'
import {BP, Button} from '../../RebeccaButton'
import {useAdminAPIs} from '../../../admin/context/useAdminAPIs'
import styled from 'styled-components'
import {Page, PageContainer, PageContent, PageHeader, PageTitle} from '../../page/Page'
import {EvixCard} from '../../../common/card/EvixCard'

interface IPatientListableTableProps {}

export const PatientListableTable: React.FC<IPatientListableTableProps> = props => {
  const { Provider, Context } = useODListableContext<GQLPatient, IPatientListableOption>()
  const { Component: Confirm, props: confirmProps, confirm } = useODModalConfirm({})
  const [loading, setLoading] = React.useState(false)
  const { rejoinPatientByAdmin } = useAdminAPIs()
  const [token, refresh] = useCounter()

  const dataLoader = usePatientListDataLoader()

  const toolbarStyle = { display: 'flex', justifyContent: 'space-between', marginBottom: 15 }
  const searchBoxStyle = { flex: 1 }
  const wrapperStyle = { display: 'flex', alignItems: 'center', justifyContent: 'center' }
  const resignButtonStyle = { width: 64, height: 24, fontSize: 12, fontWeight: 500, marginLeft: 7, }
  const newDate = moment(new Date()).format('YYYY-MM-DDTHH:mm:ss.sssZ')

  const rejoinMember = useWrappedAPI(
    (patientId: any) => rejoinPatientByAdmin({ patientId: patientId }),
    loading,
    setLoading,
    {
      successMessage: '탈퇴 철회 되었습니다.',
      onSuccess: refresh,
      onBefore: () =>
        confirm({ title: '탈퇴 철회', message: '탈퇴한 계정을 다시 복구하시겠습니까?', yes: '예', no: '아니오' }),
    }
  )

  return (
    <Provider
      dataLoader={dataLoader}
      keyExtractor={v => v[PatientPrimaryKey].toString()}
      pageSize={10}
      refreshToken={token.toString()}
      onDataLoaderError={WebUtils.showError}
      searchOnLoad
    >
      <Confirm {...confirmProps} />
      <PageContainer>
        <Page>
          <PageHeader>
            <PageTitle>환자</PageTitle>
          </PageHeader>
          <SearchWrapper style={toolbarStyle}>
            <ODListableSearchBox
              listableContext={Context}
              placeholder="환자이름, 연락처, 생년월일로 검색"
              style={searchBoxStyle}
            />
          </SearchWrapper>
          <PageContent>
            <EvixCard noPadding>
              <ODListablePaginatedTable2
                numColumns={4}
                listableContext={Context}
                renderHeader={() => (
                  <tr>
                    <th>가입일자</th>
                    <th>탈퇴일자</th>
                    <th>환자이름</th>
                    <th>연락처</th>
                    <th>생년월일</th>
                  </tr>
                )}
                renderRow={(value: GQLPatient, context) => (
                  <tr key={value[PatientPrimaryKey]} style={(value.leftAt !== null)? {backgroundColor: '#fbfbfc', borderBottomWidth: 1, borderBottomColor: '#d1d5d9', color: '#a6a8a9'} : {borderBottomWidth: 1, borderBottomColor: '#d1d5d9'}}>
                    <td>{WebUtils.formatDateTime(moment(value.createdAt))}</td>
                    <td>
                      {value.leftAt ? (
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                          {WebUtils.formatDateTime(moment(value.leftAt))}
                          {moment(newDate).diff(moment(value.leftAt), 'days') < 5 ? (
                            <Button
                              type={BP.TYPE.Primary}
                              onClick={() => rejoinMember(value.patientId)}
                              style={resignButtonStyle}
                            >
                              탈퇴 철회
                            </Button>
                          ) : null}
                        </div>
                      ) : null}
                    </td>
                    <td>
                      <Link to={SiteUrls.Admin.Patient.Detail(value.patientId)}>{value.name}</Link>

                      {moment(newDate).diff(moment(value.leftAt), 'days') > 5 ? (
                        <Chip>
                          <Triangle></Triangle>
                          <ChipText>탈퇴</ChipText>
                        </Chip>
                      ) : null}
                    </td>
                    <td>{value.phone ?? '-'}</td>
                    <td>{value.birthDate ?? '-'}</td>
                  </tr>
                )}
                eventParentContext={{}}
              />
            </EvixCard>
            <ODListablePagination hideIfSinglePage={false} listableContext={Context} wrapperStyle={wrapperStyle} />

          </PageContent>
        </Page>
      </PageContainer>

    </Provider>
  )
}

const Chip = styled.span`
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: auto;
  height: 19px;
  background-color: #525558;
  text-align: center;
  border-radius: 0 2px 2px 2px;
  padding: 0 5px;
  margin-left: 8px;
  vertical-align: middle;
`

const Triangle = styled.span`
  position: absolute;
  top: 6px;
  right: 100%;
  margin-top: -6px;
  width: 0;
  height: 0;
  border-width: 0 5px 8px 0;
  border-color: transparent #525558 transparent transparent;
  border-style: solid;
  border-radius: 1px 0 0 1px;
`
const ChipText = styled.span`
  margin-top: -1px;
  color: #fff;
  font-size: 12px;
`
const SearchWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  margin-bottom: 24px;
  > div > form > div {
    > div {
      border-radius: 8px;

      > div {
        border-radius: 8px;
        background-color: white;
        > span {
          border-radius: 8px;
        }
      }

      > input {
        border-radius: 0px 8px 8px 0px;
      }
    }

    @media (max-width: 992px) {
      width: 290px;
    }
  }
`

