import React, { useEffect, useState } from 'react'
import styled from 'styled-components/macro'
import moment from 'moment-timezone'
import { phoneNumberSplitWithString } from 'kpnfmt'
import { EvixCard } from '../common/card/EvixCard'
import { COLORS } from '../styles/colors'
import { AppOptions } from '../AppOptions'
import { useLocation } from 'react-router' 
import axios from 'axios'
import ReactJson from 'react-json-view'
import { SharedDataModal } from './modal/SharedDataModal'
import { ODModal, ODModalSize, useWrappedAPI } from '@mdpp/od-react-belt'
import { GQLInquiryCreationInput, GQLPatient, GQLHospital } from '@mdpp/common/lib/@types/server.schema'
import { useHistory,Link } from 'react-router-dom'
import { SharedDataErrorModal } from './modal/SharedDataErrorModal'
import { Filter, PERIOD } from './Filter'
import { Card } from './Card'
import { MyDetailData } from './MyDetailData'
import { MyHealthCheckUp } from './MyHealthCheckUp'
import { OutPatient } from './OutPatient'
import { Popup } from './Popup'
import { Popup2 } from './Popup2'
import { useCommonAPIs } from "../common/hooks/useCommonAPIs"


import './css/index.scss'

import arrowBack from '../assets/mydata/ic_action24px_arrow_back__subtle.png'
import arrowNext from '../assets/mydata/ic_action24px_arrow_next__subtle.png'
import {MEDICAL_DATA_SHARE_ITEM} from "@mdpp/common/lib/mydata/PublicDataType"
import {Dropdown, DropdownItem, DropdownMenu, DropdownToggle} from "reactstrap";
import {SiteUrls} from "../SiteUrls";
import SVGLock from "../assets/lock.svg";
import SVGSlash from "../assets/slash.svg";
import {WebUtils} from "../utils/webUtils";
import {result} from "lodash";
import SVGJSON from "../assets/mydata/ic_action24px_json.svg";
import SVGPDF from "../assets/mydata/ic_action24px_pdf.svg";


const CenterBoldMenuItem = styled.div`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.2px;
  text-align: left;
  color: #202223;
`
const ItemWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`

const Icon = styled.img`
  margin-right: 17px;
`

interface ISharedDataViewProps {
  url: string
  temporary: boolean
  authToken?: string
  dsId?: string
}
function useQuery() {
  return new URLSearchParams(useLocation().search)
}

export const SharedDataView: React.FC<ISharedDataViewProps> = props =>  {
  const { url, temporary, dsId } = props

  let query = useQuery()
  const [patientDataJson, setPatientDataJson] = useState<MEDICAL_DATA_SHARE_ITEM[]>([])
  const [cardDataJson, setCardDataJson] = useState<MEDICAL_DATA_SHARE_ITEM[]>([])
  const [showData, setShowData] = useState<boolean>(temporary ? false : true)
  const [tempPerson, setTempPerson] = useState<string>('')

  // filter
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const [status, setStatus] = React.useState<string[]>(['입원', '외래', '조제', '검진'])
  const [category, setCategory] = React.useState<string[]>([])


  const [selectedCard, setSelectedCard] = React.useState<MEDICAL_DATA_SHARE_ITEM|null>(null)

  const link = temporary
    ? `${AppOptions.SERVER_ADDRESS}/files/MydataShareFile/temporary/${url}?${query}`
    : `${AppOptions.SERVER_ADDRESS}/files/MydataShareFile/${url}?${query}`
  const [showAuthPopup, setShowAuthPopup] = React.useState(false)
  const [showErrorPopup, setShowErrorPopup] = React.useState(false)
  const [errorTitle, setErrorTitle] = React.useState('')
  const [errorMessage, setErrorMessage] = React.useState('')
  const [errCode, setErrCode] = React.useState('')
  const history = useHistory()
  const [patient, setPatient] = React.useState<GQLPatient | null>(null)
  const [forDev, setForDev] = React.useState<string | null>(null)

  const { listPatientMyData } = useCommonAPIs()

  const [isOpen, setIsOpen] = React.useState(false)


  useEffect(() => {
    if (showData) { 
      getData()
      const getPatientList = async () => {
        const r = await listPatientMyData({page: null, pageSize: 1000, filter: null})
        const mds = r.list.find(rl => rl.dataShareId.toString() === dsId)
        if (mds) {
          setPatient(mds.patient)
        }
      }
      getPatientList()
    } else {
      setShowAuthPopup(true)
    }
  }, [])

  async function getData(patientName?: string) {
    const token = localStorage.getItem(AppOptions.LOCAL_STORAGE_KEY_TOKEN)
    try {
      let requestLink = link
      if (temporary) {
        requestLink = link
      }

      const result = await axios({
        method: 'get',
        url: requestLink,
        headers: { 'X-Auth-From': 'web', token: token || '' },
        params: {patientName}
      })
      //console.log('result.data: ', result.data)
      if (result.data.type && result.data.type === 'developer') {
        console.debug(JSON.stringify(result.data.data))
        setForDev(JSON.stringify(result.data.data))
      } else {
        setPatientDataJson(result.data)
        setCardDataJson(result.data)
        setShowAuthPopup(false)
        setShowData(true)
        if (result.data.length > 0) {
          setEndDate(result.data[0].dateTime)
          setStartDate(result.data[result.data.length - 1].dateTime)
        }
        if (patientName) {
          setTempPerson(patientName)
        }
      }
    } catch (err: any) {
      console.error(err)
      setShowAuthPopup(false)
      const content = getErrorContent(err?.response?.data)
      setErrorTitle(content.title)
      setErrorMessage(content.message)
      setShowErrorPopup(true)
    }
  }

  const getErrorContent = (errorCode: string) => {
    setErrCode(errorCode)
    switch (errorCode) {
      case 'ERROR_MATCH_USER_DATA_FAILED':
        return {
          title: '사용자 정보가 일치하지 않습니다.',
          message: '건강기록을 공유한 환자명을 확인 후 다시 시도 해 주세요.',
        }
      case 'ERROR_TIME_EXPIRED':
        return {
          title: '이용가능 시간 종료',
          message: `메디팡팡의 스마트 진료지원 서비스를 이용해 주셔서 감사합니다
               해당 건강기록의 이용시간이 모두 종료되었습니다.
               메디팡팡 회원의로 가입하시면 메디팡팡 웹 사이트를 통해
               비대면진료는 물론, 환자가 공유한 건강기록을 제한없이 확인 및 활용할 수 있습니다.`,
        }
      default:
        return {
          title: '데이터 가져오기 실패',
          message: '건강 기록을 가져 올 수 없습니다. 서비스 운영자에게 문의 바랍니다.',
        }
    }
  }

  const onPeriodChanged = (period: string, startDate: string, endDate: string) => {

    const now = moment()
    let end = now
    let start = now.clone()
    let std = start.format('YYYY-MM-DD')
    let endd = end.format('YYYY-MM-DD')
    let periodData = patientDataJson
    //console.log('periodData : ',periodData, ' , status: ',status)
    if (period === PERIOD.ALL) {
      endd = periodData[0]?.dateTime
      std = periodData[periodData.length -1]?.dateTime
      setCardDataJson(periodData.filter(data => !!status.find(status => data.publicTreatmentAndMedicationData?.class.match(status) || data?.class?.match(status))))

    } else if (period === PERIOD.HALF_YEAR) {
      start = now.clone().subtract(6, 'month')
      std = start.format('YYYY-MM-DD')
      periodData = periodData.filter(data => moment(data.dateTime).diff(start) >= 0 && moment(data.dateTime).diff(end) <= 0)
      setCardDataJson(periodData.filter(data => !!status.find(status => data.publicTreatmentAndMedicationData?.class.match(status) || data?.class?.match(status))))
    } else if (period === PERIOD.YEAR) {
      start = now.clone().subtract(1, 'year')
      std = start.format('YYYY-MM-DD')
      periodData = periodData.filter(data => moment(data.dateTime).diff(start) >= 0 && moment(data.dateTime).diff(end) <= 0)
      setCardDataJson(periodData.filter(data => !!status.find(status => data.publicTreatmentAndMedicationData?.class.match(status) || data?.class?.match(status))))
    } else if (period === PERIOD.SELECT) {
      // selected
      periodData = periodData.filter(data => moment(data.dateTime).diff(moment(startDate)) >= 0 && moment(data.dateTime).diff(moment(endDate)) <= 0)
      setCardDataJson(periodData.filter(data => !!status.find(status => data.publicTreatmentAndMedicationData?.class.match(status) || data?.class?.match(status))))
      std = startDate
      endd = endDate
    }
    setStartDate(std)
    setEndDate(endd)
  }

  const onStatusChanged = (statusList: string[]) => {
    let data = patientDataJson.filter(data => moment(data.dateTime).diff(moment(startDate)) >= 0 && moment(data.dateTime).diff(moment(endDate)) <= 0)
    setCardDataJson(data.filter(data => !!statusList.find(status => data.publicTreatmentAndMedicationData?.class.match(status) || data?.class?.match(status))))
    setStatus(statusList)
  }

  const onCategoryChanged = (category: string[]) => {
    //TODO: 공공에선 빠진 기능
  }

  const handleClose = () => {
    history.push('/landing')
  }

  const handleErrorClose = () => {
    setShowErrorPopup(false)
    if (errCode !== 'ERROR_MATCH_USER_DATA_FAILED') {
      if (temporary) {
        handleClose()
      } else {
        history.goBack()
      }
    }
    setShowAuthPopup(true)
  }

  const submit = async (patientName: string) => {
    await getData(patientName)
  }

  const onCardSelect = (info: MEDICAL_DATA_SHARE_ITEM) => {
    setSelectedCard(info);
  }

  const verifyPopup = (
    <Popup isOpen={showAuthPopup} onClose={handleClose} onSubmit={submit} errCode={errCode}/>
  )

  const errorPopup = (
    <Popup2 
        isOpen={showErrorPopup}
        onClose={handleErrorClose}
        onSubmit={handleErrorClose}
        message={errorMessage}
        title={errorTitle}
      />
  )
  // @ts-ignore
  // @ts-ignore
  // @ts-ignore

  const DownloadJson = ({data, fileName, fileType } : { data: any, fileName:any, fileType:any }) => {
    const blob = new Blob([data], { type: fileType })
    const a = document.createElement('a')
    a.download = fileName
    a.href = window.URL.createObjectURL(blob)
    const clickEvt = new MouseEvent('click', {
      view: window,
      bubbles: true,
      cancelable: true,
    })
    a.dispatchEvent(clickEvt)
    a.remove()
  }

  const exportToJson = () => {
    DownloadJson({
      data: JSON.stringify(cardDataJson),
      fileName: 'user.json',
      fileType: 'text/json',
    })
  }



  return (
    <>
    {!!forDev && <div>{forDev}</div>} 
    {!forDev && 
    // <div className="page-container">
      <div className="wrap">
          {/* <Popup2/> */}
        {/* 팝업은 아래 주석 풀고 확인하세요. */}
        {verifyPopup}
        {errorPopup}
        {/* 상단 영역 */}
        {
          !temporary ? 
            <div className="person-wrap">
              <div className="name-wrap">
                <div className="person name">{patient?.name}</div>
                <div className="person info">
                  {`${patient && moment(patient.fullBirthDate, 'YYYYMMDD').format('YYYY.MM.DD') + ' · ' || ''}${patient && (patient.gender === 'male' ? '남성' : '여성') + ' · ' || ''}${patient && patient.phone && phoneNumberSplitWithString(patient.phone, '-') || ''}`}
                </div>
              </div>
              <div className="btn-down-wrap">
                {/* <div style={{position:'fixed', zIndex:10, listStyle: 'none', margin: '3px 0px 0px 0px'}}> */}
                  <Dropdown nav isOpen={isOpen} toggle={() => setIsOpen(!isOpen)} size="sm">
                      <DropdownToggle
                        className="btn-down">
                        {'전체 내려받기'}&nbsp;&nbsp; 
                      </DropdownToggle>
                  
                    <DropdownMenu right
                    className="down-layer">
                      {/* <DropdownItem onClick={exportToJson}>
                        <ItemWrapper>
                          <Icon src={SVGPDF} className='icon' />
                          <div className="export_text">PDF 원본</div>
                        </ItemWrapper>
                      </DropdownItem> */}
                    
                      <DropdownItem onClick={exportToJson}>
                        <ItemWrapper>
                          <Icon src={SVGJSON} className='icon' />
                          <div className="export_text">JSON 원본</div>
                        </ItemWrapper>
                      </DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                {/* </div> */}
              </div>
            </div>
          : 
            tempPerson && 
            <div className="person-wrap-temp">
              <div className="person name">{tempPerson}님의 건강기록</div>
            </div>
        }
        {/* 컨텐츠 영역 */}
        {temporary && !tempPerson ? null : 
          <div className="content-wrap">
            <div className="content-left">
              {/* 필터 영역 */}
              <Filter startDate={startDate} endDate={endDate}
                onPeriodChanged={onPeriodChanged}
                onStatusChanged={onStatusChanged}
                onCategoryChanged={onCategoryChanged}>
              </Filter>
              {/* 카드 영역 */}
              <Card cardInfoList={cardDataJson} onClicked={onCardSelect}></Card>
            </div>
            <div className="content-right">
              {/* 병원명 */}
              <div className="content-title-wrap">
                <div className="content-title">
                  {
                    selectedCard
                        ? selectedCard.publicTreatmentAndMedicationData ? selectedCard.publicTreatmentAndMedicationData?.organization : selectedCard.hospitalData ? selectedCard.hospitalData?.orgName : selectedCard?.orgName
                        : ''
                  }
                </div>
                {/*<div className="content-date">*/}
                {/*  {*/}
                {/*    selectedCard*/}
                {/*        ? selectedCard.publicTreatmentAndMedicationData ? `${moment(selectedCard.publicTreatmentAndMedicationData.startDate).format('YYYY-MM-DD')}`: selectedCard.publicMedicalCheckData ? `${moment(selectedCard.publicMedicalCheckData.date).format('YYYY-MM-DD')}`: ''*/}
                {/*        : ''*/}
                {/*  }*/}
                {/*</div>*/}
                {/*// ? selectedCard.publicTreatmentAndMedicationData ? `${moment(selectedCard.publicTreatmentAndMedicationData.startDate).format('YYYY-MM-DD')}`: selectedCard.hospitalData ?  `${moment(selectedCard.hospitalData.visit.start).format('YYYY-MM-DD')}` : ''*/}
              </div>
              {/* 기간 */}
              {/* <div className="period-wrap">
                <div className="block">
                  <img src={arrowBack} />
                  <div className="period">
                    <span>1주차</span>
                    <span>2021.09.20 ~ 2021.09.27</span>
                  </div>
                  <img src={arrowNext} />
                </div>
              </div> */}

              {/* 상세 정보 */}
              {
                selectedCard && !selectedCard.publicMedicalCheckData &&
                (

                    <MyDetailData info={selectedCard}></MyDetailData>
                )
              }

              {/* MRH_141 : 검진 영역 마크업 */}

              {
                selectedCard && selectedCard.publicMedicalCheckData &&
                (

                    <MyHealthCheckUp info={selectedCard}></MyHealthCheckUp>
                )
              }
              {/* MRH_12 : 외래 영역 마크업 */}
              {/* <OutPatient/> */}
            </div>
          </div>}
      </div>
    // </div>
    }
    </>
  )
}

//////

const MyDateViewItemHeader = styled.div``
const MyDateViewItemTitle = styled.div`
  font-size: 24px;
  font-weight: 600;
  color: ${COLORS.GRAY800};
  padding-bottom: 6px;
`

const MyDateViewItemBody = styled.div`
  font-size: 14px;
  letter-spacing: 0.3px;
  color: ${COLORS.GRAY600};
  padding-top: 24px;
  border-top: 1px solid ${COLORS.GRAY300};
  margin-top: 27px;
`
