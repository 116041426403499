import React, { useEffect, useRef } from 'react'
import { BlockingLoadBox, ODVSpace, ODWebUtils, useODQuery, useWrappedAPI } from '@mdpp/od-react-belt'
import styled from 'styled-components/macro'
import { useHistory } from 'react-router-dom'
import { EvixButton, EvixButtonTheme } from '../../common/EvixButton'
import { EvixSelector } from '../../common/EvixSelector'
import { EvixInlineInputText } from '../../common/EvixInlineInputText'
import { validator } from '../../validator'
import { ErrorCode } from '@mdpp/common'

//asset
import {
  Wrap,
  SignUpWrapNoPadding,
  Header,
  PageTitleWrap,
  ProcessWrap,
  DoctorLogo,
  PageTitle,
  Step,
  StepCircle,
  StepText,
  WarningMessageWrap,
  Container,
  BtnWrap,
  Line,
  Inner,
  TitleWrap,
  Title,
  RequiredWrap,
  Oval,
  RequiredText,
  LabelWrap,
  Label,
  FormWrap,
  FormBlock,
  OvalGray,
  HospitalNameWrap,
  RecomInfoWrap,
  PharmacyNameWrap,
  FileInput,
  FileLabel,
} from '../../common/style/SignUpStyle'
import { EvixInput } from '../../common/EvixInput'
import { ConfirmModal } from '../../common/layout/ConfirmModal'
import { HospitalSearchModal } from './HospitalSearchModal'
import { DirectInputModal } from './DirectInputModal'
import _, { values } from 'lodash'
import { WebUtils } from '../../utils/webUtils'
//아이콘
import IconDoctor from '../../assets/common/icon-doctor.png'
import IconWarningRed from '../../assets/common/warning-red.svg'
import IconWarningBlue from '../../assets/common/warning-blue.svg'
import { useCommonAPIs } from '../../common/hooks/useCommonAPIs'
import { AppOptions } from '../../AppOptions'
import { GQL_CHECK_NEW_DOCTOR, GQL_GET_CHATROOM_HAS_RESERVATION } from '../../common/gqls_common'
import { GQLOkResponse, GQLCheckNewDoctorInput } from '@mdpp/common/lib/@types/server.schema'
import { checkMessageWithPrefix } from '../../utils/registerServerErrorMessages'
import { InfoMessageWrap, MessageIcon } from '../../common/style/CommonStyle'

export const DoctorSignUpInfoPage: React.FC = () => {
  const { checkLicense, createDoctorByDoctor } = useCommonAPIs()
  const [loading, setLoading] = React.useState(false)
  // 가입정보
  const [doctorName, setDoctorName] = React.useState('')
  const [dateOfBirth, setDateOfBirth] = React.useState('')
  const [email, setEmail] = React.useState('')
  const [password, setPassword] = React.useState('')
  const [confirmPassword, setConfirmPassword] = React.useState('')
  const [licenseName, setLicenseName] = React.useState('')
  const [errMsg, setErrMsg] = React.useState('')
  const [errEmailMsg, setErrEmailMsg] = React.useState('')
  const [errCodeMsg, setErrCodeMsg] = React.useState('')
  const [hpNo1, setHpNo1] = React.useState('010')
  const [hpNo2, setHpNo2] = React.useState('')
  const [hpNo3, setHpNo3] = React.useState('')
  const [hospitalName, setHospitalName] = React.useState('')
  const [hospitalAddress1, setHospitalAddress1] = React.useState('')
  const [hospitalAddress2, setHospitalAddress2] = React.useState('')
  const [hospitalTel, setHospitalTel] = React.useState('')
  const [adminEmail, setAdminEmail] = React.useState('')
  const [isFirstDoctor, setIsFirstDoctor] = React.useState(false)
  const [code1, setCode1] = React.useState('')
  const [code2, setCode2] = React.useState('')
  const [code3, setCode3] = React.useState('')
  const [code4, setCode4] = React.useState('')
  const [code5, setCode5] = React.useState('')
  const [code6, setCode6] = React.useState('')
  const [count, setCount] = React.useState(0)
  const [nextBtn, setNextBtn] = React.useState(true)
  const [checkBtn, setCheckBtn] = React.useState(true)

  const inputRef1 = React.useRef<HTMLInputElement>(null)
  const inputRef2 = React.useRef<HTMLInputElement>(null)
  const inputRef3 = React.useRef<HTMLInputElement>(null)
  const inputRef4 = React.useRef<HTMLInputElement>(null)
  const inputRef5 = React.useRef<HTMLInputElement>(null)
  const inputRef6 = React.useRef<HTMLInputElement>(null)

  const [fileName, setFileName] = React.useState('')
  const [fileLink, setFileLink] = React.useState('')
  const [bankBook, setBankBook] = React.useState('')
  const [bankBookFileLink, setBankBookFileLink] = React.useState('')

  const apiCheckNewDoctor = useODQuery<GQLCheckNewDoctorInput, GQLOkResponse>(GQL_CHECK_NEW_DOCTOR)

  const handleFocus = (idx: number, value: string) => {
    const v = value.toUpperCase()
    const codeValue = v.replace(/[^A-Za-z]/gi, '')
    setErrCodeMsg('')
    switch (idx) {
      case 1:
        setCode1(codeValue)
        if (codeValue !== '') {
          return inputRef2.current!.focus()
        }
        break
      case 2:
        setCode2(codeValue)
        if (codeValue !== '') {
          return inputRef3.current!.focus()
        }
        inputRef1.current!.focus()
        break
      case 3:
        setCode3(codeValue)
        if (codeValue !== '') {
          return inputRef4.current!.focus()
        }
        inputRef2.current!.focus()
        break
      case 4:
        setCode4(codeValue)
        if (codeValue !== '') {
          return inputRef5.current!.focus()
        }
        inputRef3.current!.focus()
        break
      case 5:
        setCode5(codeValue)
        if (codeValue !== '') {
          return inputRef6.current!.focus()
        }
        inputRef4.current!.focus()
        break
      case 6:
        setCode6(codeValue)
        if (codeValue === '') {
          return inputRef5.current!.focus()
        }
        break
    }
  }

  const options = [
    { value: '010', label: '010' },
    { value: '011', label: '011' },
    { value: '013', label: '013' },
    { value: '016', label: '016' },
    { value: '017', label: '017' },
    { value: '018', label: '018' },
    { value: '019', label: '019' },
  ]

  // Confirm Modal
  const [showConfirmModal, setShowConfirmModal] = React.useState(false)

  // 병원검색 Modal
  const [showHospitalSearchModal, setShowHospitalSearchModal] = React.useState(false)

  // 병/의원 직접 입력 Modal
  const [showDirectInputModal, setShowDirectInputModal] = React.useState(false)

  // 인증 완료 안내 Modal
  const [showCertificationCompleteModal, setShowCertificationCompleteModal] = React.useState(false)

  // 다음 버튼 클릭 시, 이동
  const history = useHistory()
  const handleNext = async () => {
    const resultHpNo = hpNo1.concat(hpNo2, hpNo3)
    const resultCode = code1.concat(code2, code3, code4, code5, code6)
    const files = fileLink[0]
    const bankBookFiles = bankBookFileLink[0]

    try {
      const join = await createDoctorByDoctor({
        licenseName: licenseName,
        name: doctorName,
        email: email,
        password: password,
        cellPhone: resultHpNo,
        birthDate: dateOfBirth,
        phone: hospitalTel,
        hospitalName: hospitalName,
        hospitalAddress: hospitalAddress1,
        hospitalManagerEmail: adminEmail ? adminEmail : null,
        recommender: resultCode,
        businessLicenseFile: files ? files : null,
        bankbookFile: bankBookFiles ? bankBookFiles : null,
      })
      // console.log('아이디 확인:', join.waitingDoctorId)
      let path = `/doctor/emailAuthentication`
      history.push({ pathname: path, state: { join: join } })
    } catch (err) {
      console.log(err)
      if (checkMessageWithPrefix(err, ErrorCode.EMAIL_ALREADY_EXIST)) {
        setErrEmailMsg('이미 가입한 이메일 아이디입니다.')
      }
      if (checkMessageWithPrefix(err, ErrorCode.RECOMMENDER_NOT_EXIST)) {
        setErrCodeMsg('존재하지 않는 추천코드입니다.')
      }
    }
  }

  const selectValueHandler = (obj: any) => {
    const newObj = _.cloneDeep(obj)
    setHpNo1(newObj.value)
  }

  const handleCheckCode = async (licenseName: string) => {
    setErrMsg('')

    const licenseCode = licenseName.padStart(6, '0')
    setLicenseName(licenseCode)

    try {
      const r = await checkLicense({ licenseName: licenseCode })
      if (r.ok === 1) {
        console.log('사용가능한 면허번호입니다.')
        setCount(1)
        setCheckBtn(true)
      } else {
        setErrMsg('이미 등록된 면허번호입니다.')
        setCount(0)
      }
    } catch (err) {
      console.log(err)
    } finally {
    }
  }

  const resetConfirmPassword = (value: string) => {
    setConfirmPassword(value)
  }

  const onChangePhoneNumber = (value: string, setState: Function) => {
    const onlyNumber = value.replace(/[^0-9]/g, '')
    setState(onlyNumber)
  }

  const CheckDotorNo = (value: string) => {
    if (value.length > 0) {
      setErrMsg('중복확인 버튼을 눌러주세요.')
      setCheckBtn(false)
    } else {
      setCheckBtn(true)
    }
    setLicenseName(value)
  }

  const onDirectPress = () => {
    setShowHospitalSearchModal(false)
    setShowDirectInputModal(true)
  }

  const onHospitalSelected = async (
    hospitalName: string,
    hospitalAddr: string,
    hospitlaPostNo: string,
    hospitalTel: string
  ) => {
    setHospitalName(hospitalName)
    setHospitalTel(hospitalTel)
    setHospitalAddress1(hospitalAddr)
    setHospitalAddress2(hospitlaPostNo)
    setShowHospitalSearchModal(false)
    setShowDirectInputModal(false)
    setFileName('')
    setFileLink('')
    setBankBook('')
    setBankBookFileLink('')

    // first doctor check
    const ret = await apiCheckNewDoctor({ hospitalName: hospitalName, hospitalAddress: hospitalAddr })
    if (ret.ok === 1) {
      // 처음 가입한 의사
      setIsFirstDoctor(true)
      setShowConfirmModal(true)
    } else {
      setIsFirstDoctor(false)
    }
  }

  const fileChangedHandler = async (e: any) => {
    const files = e.target.files
    const fileSize = files[0]?.size

    if (files[0]?.name === null) {
      return console.log('파일없음')
    }
    if (fileSize > 50000000) {
      return WebUtils.showError('첨부파일 사이즈는 50MB 이내로 등록 가능합니다.')
    }
    setFileLink(files)
    setFileName(files[0]?.name)
  }

  const bankBookFileChangedHandler = async (e: any) => {
    const files = e.target.files
    const fileSize = files[0]?.size

    if (files[0]?.name === null) {
      return console.log('파일없음')
    }
    if (fileSize > 50000000) {
      return WebUtils.showError('첨부파일 사이즈는 50MB 이내로 등록 가능합니다.')
    }
    setBankBookFileLink(files)
    setBankBook(files[0]?.name)
  }

  useEffect(() => {
    if (!doctorName || Object.keys(validator.username(doctorName)).length !== 0) {
      return setNextBtn(true)
    }
    if (!licenseName || Object.keys(validator.doctorCode(licenseName)).length !== 0) {
      return setNextBtn(true)
    }
    if (!email || Object.keys(validator.email(email)).length !== 0) {
      return setNextBtn(true)
    }
    if (!dateOfBirth || Object.keys(validator.birth(dateOfBirth)).length > 0) {
      return setNextBtn(true)
    }
    if (!password || validator.password(password)?.errors?.length) {
      return setNextBtn(true)
    }
    if (!confirmPassword || Object.keys(validator.passwordConfirm(confirmPassword, password)).length !== 1) {
      return setNextBtn(true)
    }
    if (!hospitalName || !hospitalAddress1 || !hospitalAddress2) {
      return setNextBtn(true)
    }
    if (count !== 1 || errCodeMsg !== '' || errEmailMsg !== '') {
      return setNextBtn(true)
    }
    if (!hpNo2 || !hpNo3) {
      return setNextBtn(true)
    }
    if (isFirstDoctor === true && (!fileName || !bankBook)) {
      return setNextBtn(true)
    }
    setNextBtn(false)
  }, [
    doctorName,
    licenseName,
    email,
    dateOfBirth,
    password,
    confirmPassword,
    CheckDotorNo,
    hpNo1,
    hpNo2,
    hpNo3,
    fileName,
    bankBook,
  ])

  useEffect(() => {
    setCount(0)
    if (licenseName.length > 0) {
      setCheckBtn(false)
    }
  }, [licenseName])

  useEffect(() => {
    setErrEmailMsg('')
  }, [email])

  useEffect(() => {
    if (password !== confirmPassword) {
      resetConfirmPassword('')
    }
  }, [password])

  return (
    <>
      <BlockingLoadBox show={loading} />
      {/* 컨펌 Modal : 해당 기관의 최초 가입자입니다. */}
      {showConfirmModal && (
        <ConfirmModal
          style={{ width: 420, height: 243 }}
          isTitle={false}
          title={''}
          titleIcon={''}
          isDescriptionIcon={true}
          description={['해당 기관의 최초 가입자입니다.']}
          descriptionIcon={IconWarningBlue}
          hideCancel={true}
          onOkPress={() => setShowConfirmModal(false)}
          onCancelPress={() => setShowConfirmModal(false)}
        />
      )}

      {/* 인증 완료 안내 Modal */}
      {showCertificationCompleteModal && (
        <ConfirmModal
          style={{ width: 420, height: 274 }}
          isTitle={true}
          title={'이메일 인증 완료'}
          titleIcon={IconWarningBlue}
          isDescriptionIcon={false}
          description={[
            '관리자의 승인 후 로그인이 가능합니다.',
            <br />,
            '승인 완료 시 입력하신 이메일로 안내될 예정입니다.',
            <br />,
            '참고 부탁드립니다. 감사합니다.',
          ]}
          descriptionIcon={''}
          hideCancel={true}
          onOkPress={() => setShowCertificationCompleteModal(false)}
          onCancelPress={() => setShowCertificationCompleteModal(false)}
        />
      )}

      {/* 병원 검색 Modal  */}
      {showHospitalSearchModal && (
        <HospitalSearchModal
          style={{ width: 880, height: 764, maxWidth: 880 }}
          onSelected={onHospitalSelected}
          onCancelPress={() => setShowHospitalSearchModal(false)}
          onDirectPress={onDirectPress}
        />
      )}

      {/* 병/의원 직접 입력 Modal  */}
      {showDirectInputModal && (
        <DirectInputModal
          style={{ width: 820, height: 543, maxWidth: 820 }}
          onCancelPress={() => setShowDirectInputModal(false)}
          onInput={onHospitalSelected}
        />
      )}

      <Wrap>
        <SignUpWrapNoPadding>
          <Header style={{ paddingLeft: 90, paddingRight: 80 }}>
            <PageTitleWrap>
              <img src={IconDoctor} style={DoctorLogo} alt="의사 아이콘" />
              <PageTitle>회원가입</PageTitle>
            </PageTitleWrap>
            <ProcessWrap>
              <Step>
                <StepCircle>
                  <span>1</span>
                </StepCircle>
                <StepText>약관 동의</StepText>
              </Step>
              <Step className="active">
                <StepCircle>
                  <span>2</span>
                </StepCircle>
                <StepText>가입정보 입력</StepText>
              </Step>
              <Step>
                <StepCircle>
                  <span>3</span>
                </StepCircle>
                <StepText>이메일 인증</StepText>
              </Step>
              <Step>
                <StepCircle>
                  <span>4</span>
                </StepCircle>
                <StepText>가입 승인 요청</StepText>
              </Step>
            </ProcessWrap>
          </Header>
          <ODVSpace h={18} />
          <Line />
          <ODVSpace h={54} />
          <Container style={{ alignItems: 'center' }}>
            <Inner>
              <TitleWrap>
                <Title style={{ paddingBottom: 0 }}>기본 정보</Title>
                <RequiredWrap>
                  <Oval></Oval>
                  <RequiredText>필수입력 항목입니다.</RequiredText>
                </RequiredWrap>
              </TitleWrap>
              <ODVSpace h={36} />
              {/* 기본정보 */}
              <FormWrap>
                <FormBlock>
                  <LabelWrap>
                    <Label>이름 (한글)</Label>
                    <Oval></Oval>
                  </LabelWrap>
                  <EvixInput
                    style={{ width: '100%' }}
                    value={doctorName}
                    validators={validator.username}
                    onChange={value => setDoctorName(value)}
                    disabled={false}
                  />
                </FormBlock>

                <FormBlock>
                  <LabelWrap>
                    <Label>생년월일</Label>
                    <Oval></Oval>
                  </LabelWrap>
                  <EvixInput
                    style={{ width: '100%' }}
                    placeholder="예) 1970년 1월 1일 → 19700101 입력"
                    maxLength={8}
                    value={dateOfBirth}
                    validators={validator.birth}
                    onChange={value => setDateOfBirth(value)}
                    disabled={false}
                  />
                  <WarningMessageWrap
                    className={Object.keys(validator.birth(dateOfBirth)).length !== 0 ? 'hide' : ''}
                  ></WarningMessageWrap>
                </FormBlock>
              </FormWrap>

              <FormWrap>
                <FormBlock>
                  <LabelWrap>
                    <Label>이메일 주소 (ID)</Label>
                    <Oval></Oval>
                  </LabelWrap>
                  <EvixInput
                    style={{ width: '100%' }}
                    value={email}
                    validators={validator.email}
                    onChange={value => setEmail(value)}
                    disabled={false}
                    maxLength={50}
                  />
                  <WarningMessageWrap className={Object.keys(validator.email(email)).length !== 0 ? 'hide' : ''}>
                    <span className="error" style={{ fontSize: 13, fontWeight: 500, color: '#ff5353' }}>
                      {errEmailMsg}
                    </span>
                  </WarningMessageWrap>
                </FormBlock>
              </FormWrap>

              <FormWrap className="password">
                <FormBlock>
                  <LabelWrap>
                    <Label>비밀번호</Label>
                    <Oval></Oval>
                  </LabelWrap>
                  <EvixInput
                    type="password"
                    style={{ width: '100%' }}
                    placeholder="8~20자 영문 대 소문자, 숫자, 특수문자를 모두 사용."
                    value={password}
                    validators={validator.password}
                    onChange={value => setPassword(value)}
                    disabled={false}
                    maxLength={20}
                    children
                  />
                </FormBlock>

                <FormBlock>
                  <LabelWrap>
                    <Label>비밀번호 확인</Label>
                    <Oval></Oval>
                  </LabelWrap>
                  <EvixInput
                    type="password"
                    style={{ width: '100%' }}
                    value={confirmPassword}
                    onChange={resetConfirmPassword}
                    validators={(value: string) => validator.passwordConfirm(value, password)}
                    disabled={false}
                    maxLength={20}
                    children
                  />
                </FormBlock>
              </FormWrap>

              <FormWrap>
                <FormBlock>
                  <LabelWrap>
                    <Label>의사 면허번호</Label>
                    <Oval></Oval>
                  </LabelWrap>
                  <DoctorNoWrap className="w-100">
                    <EvixInput
                      style={{ flex: 1 }}
                      value={licenseName}
                      onChange={value => CheckDotorNo(value)}
                      validators={validator.doctorCode}
                      disabled={false}
                      maxLength={6}
                    />
                    <EvixButton
                      theme={EvixButtonTheme.H48WhiteInline}
                      onClick={() => handleCheckCode(licenseName)}
                      disabled={checkBtn}
                    >
                      중복확인
                    </EvixButton>
                  </DoctorNoWrap>
                  <WarningMessageWrap
                    className={Object.keys(validator.doctorCode(licenseName)).length !== 0 ? 'hide' : ''}
                  >
                    <span className="error" style={{ fontSize: 13, fontWeight: 500, color: '#ff5353' }}>
                      {errMsg}
                    </span>
                  </WarningMessageWrap>
                </FormBlock>

                <FormBlock>
                  <LabelWrap>
                    <Label>휴대폰 번호</Label>
                    <Oval></Oval>
                  </LabelWrap>
                  <div className="hp-wrap">
                    <EvixSelector
                      style={{ width: 80 }}
                      value={hpNo1}
                      placeholder={'010'}
                      options={options}
                      onChange={e => selectValueHandler(e)}
                    />
                    <EvixInput
                      style={{ width: 80, height: 44 }}
                      value={hpNo2}
                      onChange={value => onChangePhoneNumber(value, setHpNo2)}
                      disabled={false}
                      maxLength={4}
                    />
                    <EvixInput
                      style={{ width: 80, height: 44 }}
                      value={hpNo3}
                      onChange={value => onChangePhoneNumber(value, setHpNo3)}
                      disabled={false}
                      maxLength={4}
                    />
                  </div>
                  <WarningMessageWrap>
                    <ul>
                      <li>
                        <OvalGray></OvalGray> 휴대폰 번호로 진료 예약 관련 알림과 마케팅 정보를 안내합니다.
                      </li>
                      <li>
                        <OvalGray></OvalGray> 휴대폰 번호 입력 시, 마케팅 정보 수신 동의로 간주합니다.
                      </li>
                    </ul>
                  </WarningMessageWrap>
                </FormBlock>
              </FormWrap>
            </Inner>
            <ODVSpace h={47} />
            <Line />
            <ODVSpace h={54} />

            <Inner>
              <TitleWrap>
                <Title style={{ paddingBottom: 0 }}>의료기관 정보</Title>
                <RequiredWrap>
                  <Oval></Oval>
                  <RequiredText>필수입력 항목입니다.</RequiredText>
                </RequiredWrap>
              </TitleWrap>
              <ODVSpace h={36} />

              <FormWrap>
                <FormBlock className="w-100">
                  <LabelWrap>
                    <Label>병/의원 명</Label>
                    <Oval></Oval>
                  </LabelWrap>
                  <HospitalNameWrap className="w-100" onClick={() => setShowHospitalSearchModal(true)}>
                    <EvixInlineInputText
                      style={{ flex: 1 }}
                      value={hospitalName}
                      onChange={value => setHospitalName(value)}
                      disabled={true}
                    />
                    <EvixButton
                      theme={EvixButtonTheme.H48WhiteInline}
                      onClick={() => setShowHospitalSearchModal(true)}
                      disabled={false}
                    >
                      검색
                    </EvixButton>
                  </HospitalNameWrap>
                  <WarningMessageWrap></WarningMessageWrap>
                </FormBlock>
              </FormWrap>

              <FormWrap>
                <FormBlock className="w-100">
                  <LabelWrap>
                    <Label>병원 주소</Label>
                    <Oval></Oval>
                  </LabelWrap>
                  <HospitalNameWrap className="w-100">
                    <EvixInlineInputText style={{ flex: 1 }} value={hospitalAddress1} disabled={true} />
                    <EvixInlineInputText style={{ width: 80 }} value={hospitalAddress2} disabled={true} />
                  </HospitalNameWrap>
                  <WarningMessageWrap></WarningMessageWrap>
                </FormBlock>
              </FormWrap>

              <FormWrap>
                <FormBlock>
                  <LabelWrap>
                    <Label>병원 연락처</Label>
                    <Oval></Oval>
                  </LabelWrap>
                  <EvixInlineInputText style={{ width: '100%' }} value={hospitalTel} disabled={true} />
                  <WarningMessageWrap></WarningMessageWrap>
                </FormBlock>

                {isFirstDoctor && (
                  <FormBlock>
                    <LabelWrap>
                      <Label>병원관리자 메일</Label>
                    </LabelWrap>

                    <EvixInput
                      style={{ width: '100%' }}
                      value={adminEmail}
                      validators={validator.email}
                      onChange={value => setAdminEmail(value)}
                      disabled={false}
                    />
                    <WarningMessageWrap>
                      <ul>
                        <li>1) 병원관리자 Web 이용이 가능한 이메일 아이디입니다.</li>
                        <li>2) 미입력시, 당신의 이메일 아이디가 병원관리자 메일이 됩니다.</li>
                        <li>3) 해당 메일로 병원관리자 로그인 정보가 전송됩니다.</li>
                      </ul>
                    </WarningMessageWrap>
                  </FormBlock>
                )}
              </FormWrap>
            </Inner>

            {/* 사업자 등록증, 통장 사본 노출 */}
            {isFirstDoctor && (
              <Inner>
                <FormWrap>
                  <FormBlock>
                    <LabelWrap>
                      <Label>사업자등록증</Label>
                      <Oval></Oval>
                    </LabelWrap>
                    <PharmacyNameWrap>
                      <FileInput>
                        {fileName ? (
                          fileName
                        ) : (
                          <>
                            <span className="placeholer">PDF, JPG, PNG 파일만 첨부 가능</span>
                          </>
                        )}
                      </FileInput>
                      <FileLabel htmlFor="input-file">
                        {' '}
                        사업자등록증 첨부
                        <input
                          name="businessLicenseFile"
                          type="file"
                          id="input-file"
                          style={{ display: 'none' }}
                          accept=".pdf, image/png, image/jpeg, image/jpg"
                          onChange={e => fileChangedHandler(e)}
                        />
                      </FileLabel>
                    </PharmacyNameWrap>
                    <WarningMessageWrap>
                      <ul>
                        <li className="align-center">
                          <MessageIcon src={IconWarningBlue} alt="warning icon" /> 비대면 진료 정산을 위해 필요합니다.
                        </li>
                      </ul>
                    </WarningMessageWrap>
                  </FormBlock>

                  <FormBlock>
                    <LabelWrap>
                      <Label>통장사본</Label>
                      <Oval></Oval>
                    </LabelWrap>
                    <PharmacyNameWrap>
                      <FileInput>
                        {bankBook ? (
                          bankBook
                        ) : (
                          <>
                            <span className="placeholer">PDF, JPG, PNG 파일만 첨부 가능</span>
                          </>
                        )}
                      </FileInput>
                      <FileLabel htmlFor="input-bank-file">
                        {' '}
                        통장사본 첨부
                        <input
                          name="bankBookFile"
                          type="file"
                          id="input-bank-file"
                          style={{ display: 'none' }}
                          accept=".pdf, image/png, image/jpeg, image/jpg"
                          onChange={e => bankBookFileChangedHandler(e)}
                        />
                      </FileLabel>
                    </PharmacyNameWrap>
                    <WarningMessageWrap>
                      <ul>
                        <li className="align-center">
                          <MessageIcon src={IconWarningBlue} alt="warning icon" />
                          비대면 진료 정산을 위해 필요합니다.
                        </li>
                      </ul>
                    </WarningMessageWrap>
                  </FormBlock>
                </FormWrap>
              </Inner>
            )}
            <ODVSpace h={39} />
            <Line />
            <ODVSpace h={46} />

            <Inner>
              <FormWrap className="recom">
                <FormBlock>
                  <LabelWrap>
                    <Label>추천인 코드 입력</Label>
                  </LabelWrap>
                  <CodeWrap className="recom">
                    <EvixInput
                      style={{ width: 50 }}
                      value={code1}
                      validators={validator.code}
                      onChange={value => handleFocus(1, value)}
                      disabled={false}
                      maxLength={1}
                      ref={inputRef1}
                    />
                    <EvixInput
                      style={{ width: 50 }}
                      value={code2}
                      validators={validator.code}
                      onChange={value => handleFocus(2, value)}
                      disabled={false}
                      maxLength={1}
                      ref={inputRef2}
                    />
                    <EvixInput
                      style={{ width: 50 }}
                      value={code3}
                      validators={validator.code}
                      onChange={value => handleFocus(3, value)}
                      disabled={false}
                      maxLength={1}
                      ref={inputRef3}
                    />
                    <EvixInput
                      style={{ width: 50 }}
                      value={code4}
                      validators={validator.code}
                      onChange={value => handleFocus(4, value)}
                      disabled={false}
                      maxLength={1}
                      ref={inputRef4}
                    />
                    <EvixInput
                      style={{ width: 50 }}
                      value={code5}
                      validators={validator.code}
                      onChange={value => handleFocus(5, value)}
                      disabled={false}
                      maxLength={1}
                      ref={inputRef5}
                    />
                    <EvixInput
                      style={{ width: 50 }}
                      value={code6}
                      validators={validator.code}
                      onChange={value => handleFocus(6, value)}
                      disabled={false}
                      maxLength={1}
                      ref={inputRef6}
                    />
                  </CodeWrap>
                  <WarningMessageWrap>
                    <span className="error">{errCodeMsg}</span>
                  </WarningMessageWrap>
                </FormBlock>
                <RecomInfoWrap>
                  <img src={IconWarningRed} alt="warning icon" />
                  <div>
                    추천인 코드를 입력하지 않으신 경우, <br />
                    재입력이 불가하며 입력 후에도 수정은 불가능합니다.
                  </div>
                </RecomInfoWrap>
              </FormWrap>
            </Inner>
            <ODVSpace h={16} />
            <Line />
            <ODVSpace h={80} />

            <BtnWrap>
              <EvixButton theme={EvixButtonTheme.H48Black} onClick={() => handleNext()} disabled={nextBtn}>
                다음
              </EvixButton>
            </BtnWrap>
          </Container>
        </SignUpWrapNoPadding>
      </Wrap>
    </>
  )
}

const DoctorNoWrap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;

  &.w-100 {
    width: 100%;
  }
`

const CodeWrap = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;

  .od-input {
    font-size: 20px;
    text-align: center;
  }

  > div > div ~ div {
    display: none;
  }
`
