import React from 'react'
import { ODListableContextType } from '@mdpp/od-react-belt'
import { GQLMedicationDispensing } from '@mdpp/common/lib/@types/server.schema'
import { useSSE } from 'react-hooks-sse'
import { ReservationStatusEvent } from '@mdpp/common/lib/sse/common'
import { IMedicationDispensingListableOption } from './reservation/PharmacyCommon'

interface IPharmaSSEReceiverProps {
  context: React.Context<ODListableContextType<GQLMedicationDispensing, IMedicationDispensingListableOption>>
}

export const PharmacySSEReceiver: React.FC<IPharmaSSEReceiverProps> = props => {
  const {
    refresh,
  }: ODListableContextType<GQLMedicationDispensing, IMedicationDispensingListableOption> = React.useContext(
    props.context
  )
  useSSE<ReservationStatusEvent>(
    'medicationDispensingStatusChanged',
    { reservationId: null, status: null },
    {
      parser(input) {
        const event = JSON.parse(input)
        refresh()
        return event
      },
    }
  )
  return <></>
}
