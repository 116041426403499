import { createGlobalStyle } from 'styled-components'
import { COLORS } from './colors'
import { ReactAnimateReplaceStyles } from './react-animate-replace-styles'
import { ReactDatesStyles } from './react-dates-styles'

// https://stackoverflow.com/a/54842791/388351
// @import url('https://fonts.googleapis.com/css?family=Noto+Sans+KR&display=swap&subset=korean');
export const GlobalStyle = createGlobalStyle`
  html,
  body {
    font-family: 'Noto Sans KR', sans-serif;
    scroll-behavior: smooth;
    overflow-x: hidden;
    overflow-y: overlay;
    width: 100%;
    height: 100%;
  }
  #root, .app {
    height: 100%;
  }


  main {
    @media only screen and (max-width: 570px) {
      width: 100%;
      height: 100%;
      margin-left: 240px !important;
    }

    } 
    @media only screen and (min-width: 992px) {
      // margin-left: 0 !important;
      // border-left: 0 !important;
      margin-left: 240px !important;

    } 
  }
  
  //
  // 우리는 기본 react-toastify 를 수정하여 커스텀 디자인을 활용할 것이므로 아래처럼 inset 을 없앤다.
  //
  .Toastify__toast {
    padding: 0;
    margin: 0;
  }

  .Toastify__toast-body {
    padding: 0;
    margin: 0;
    white-space: pre-line;
  }

  //
  // Modal dialog 에서 존재하는 둥근 엣지를 제거하기 위해 사용한다.
  //
  .modal-rect {
    .modal-content {
      border: 0;
      // border-radius: 0;
    }
  }


  table.v-center {
    th, td {
      vertical-align: middle;
    }
  }

  

  //
  // 우측 드롭다운 메뉴의 underline 제거
  //
  .dropdown-menu {
    a {
      text-decoration: none;
    }
  }

  //
  // 기존 CoreUI 에서 dropdown-item 을 Nav 로 묶으면서 발생하는 css 문제 수정
  //
  .app-header {
    a {
      .dropdown-item {
        border-bottom: 1px solid #c8ced3;
      }

      .dropdown-item:first-child {
        border-radius: 0;
      }
      .dropdown-item:last-child {
        border-radius: 0;
      }
    }
  }

  // 디폴트 10rem 에서 좀 더 키운다.
  .dropdown-menu {
    min-width: 14rem;
  }

  // 상단 메뉴에서 배지가 여러개 있을 때에 대한 처리
  .badge:not(:first-child) {
    margin-left: 0.2rem;
  }

  .modal-sm {
    max-width: 300px;
  }

  .modal-tcf {
    max-width: 100%;
    margin-left: 50px;
    margin-right: 50px;
  }

  // 좌측 사이드바 메뉴에 컬러를 강제로 지정하는 경우
  .nav-red {
    a, i {
      color: red !important;
    }
  }

  .nav-primary {
    a, i {
      color: ${COLORS.mpPrimary500} !important;
    }
  }

  // popper 의 기본 max-width 를 늘려준다. (for color-picker)
  .od-popper {
    .popover {
      max-width: 500px;
    }
  }

  // pop-over 의 z-index 를 1로 수정한다.
  .popover {
    z-index: 1;
  }

  // breadcrumb 의 bottom margin 을 제거한다.
  ol.breadcrumb {
    margin-bottom: 0;
  }

  ${ReactAnimateReplaceStyles}

  div.vcenter {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  ${ReactDatesStyles}

  // react_dates_override.css
  .DateRangePicker_picker {
    z-index: 10;
  }

  .sidebar {
    background-color: ${COLORS.SIDEBAR_BG};
    width: 240px !important;
    border-right: 1px solid #E7E8E9;
    // position: relative !important;

    svg {
      height: 24px;
    }

    > button {
      background-color: rgba(0, 0, 0, 0) !important;
    }

    a {
      border-radius: 4px;
      margin-left: 8px;
      margin-right: 8px;
      &:hover {
        text-decoration: none;
      }
    }
  }
  .sidebar-nav {
    width: 240px !important;
  }
  .nav {
    width: 100% !important;
    padding: 12px 0 0 0 !important;
   .nav-link {
      display: flex !important;
      align-items: center;
      gap: 6px;
      font-weight: 500;
      padding: 10px 16px;
      :not(.active):hover {
        background: #F0F2F4 !important;
        color: #4F5356 !important;
      }
      :not(.active):active {
        background: #EBEDF1 !important;
      }
      :hover {
        background: #F0F2F4 !important; 
      }
      :active {
        background: #EBEDF1 !important;
      }
    }
    .nav-link.active {
      .nav-icon {
        fill: #3096f5 !important; // 좌측 사이드바의 아이콘 활성화 컬러

        path { fill: #3096f5 !important;}
      }
    }
    .nav-icon {
      fill: #5C6063; // 좌측 사이드바의 아이콘 디폴트 컬러

      path {fill: #5C6063; }
    }
  }
  .sidebar-minimizer {
    display: none;
  }

  .app-header {
    background-color: ${COLORS.HEADER_BG};
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.1), 0px 2px 10px rgba(0, 0, 0, 0.1);
    border: none;
  }

  a {
    color: inherit;

    :hover {
      color: inherit;
      text-decoration: none;
    }
  }
  
  //버튼
  button:focus {
    outline: 0;
    box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.1) ;
  }

`
