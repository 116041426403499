import { ODListableResponseType, useODQuery } from '@mdpp/od-react-belt'
import React from 'react'
import { GQLListablePharmacy, GQLListablePharmacyInput, GQLPharmacy } from '@mdpp/common/lib/@types/server.schema'
import { GQL_LIST_PHARMACY } from '../../gqls_common'
import { IPharmacyListableOption } from '../PharmacyCommon'

export function usePharmacyListDataLoader() {
  const listApi = useODQuery<Partial<GQLListablePharmacyInput>, GQLListablePharmacy>(GQL_LIST_PHARMACY)

  return React.useCallback(
    async function dataLoader(
      page: number,
      pageSize: number,
      afterKey: string | null,
      options: IPharmacyListableOption
    ): Promise<ODListableResponseType<GQLPharmacy>> {
      const r = await listApi({
        page,
        pageSize,
        ...options,
      })
      return r as ODListableResponseType<GQLPharmacy>
    },
    [listApi]
  )
}
