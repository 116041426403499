import { useODMutation, useODQuery } from '@mdpp/od-react-belt'
import {
  GQLAppVersion,
  GQLAppVersionCreationInput,
  GQLChangeDoctorHospitalInput,
  GQLDoctor,
  GQLEvixAdmin,
  GQLEvixAdminLoginInput,
  GQLEvixAdminLoginResponse,
  GQLHospital,
  GQLHospitalUpdateInput,
  GQLListableAppVersion,
  GQLListableAppVersionInput,
  GQLListableHospitalManager,
  GQLListableHospitalManagerInput,
  GQLListablePharmacyHasHospital,
  GQLListablePharmacyHasHospitalInput,
  GQLOkResponse,
  GQLPopupInfoCreationInput,
  GQLPopupInfoUpdateInput,
  GQLSingleIDInput,
  GQLPopupInfo,
  GQLPopupInfoDeleteInput,
  GQLRejoinPatientByAdminInput,
} from '@mdpp/common/lib/@types/server.schema'
import { AppOptions } from '../../AppOptions'
import {
  GQL_GET_HOSPITAL,
  GQL_LOGOUT,
  GQL_REJOIN_PATIENT_BY_ADMIN,
  GQL_RESET_DOCTOR_PASSWORD,
} from '../../common/gqls_common'
import {
  GQL_CHANGE_DOCTOR_HOSPITAL,
  GQL_CREATE_APP_VERSION,
  GQL_DELETE_HOSPITAL_MANAGER,
  GQL_GET_EVIX_ADMIN_PROFILE,
  GQL_LIST_APP_VERSION,
  GQL_LIST_HOSPITAL_MANAGER,
  GQL_LIST_PHARMACY_HAS_HOSPITAL,
  GQL_LOGIN_EVIX_ADMIN,
  GQL_RESET_HOSPITAL_MANAGER_PASSWORD,
  GQL_UPDATE_HOSPITAL,
  GQL_CREATE_POPUP_INFO,
  GQL_UPDATE_POPUP_INFO,
  GQL_DELETE_POPUP_INFO,
} from './gqls_evix_admin'

export function useAdminAPIs() {
  const simulateDelay = AppOptions.SIMULATE_DELAY

  const loginAdmin = useODMutation<GQLEvixAdminLoginInput, GQLEvixAdminLoginResponse>(
    GQL_LOGIN_EVIX_ADMIN,
    simulateDelay
  )
  const logout = useODMutation<void, GQLOkResponse>(GQL_LOGOUT, simulateDelay)
  const getAdminProfile = useODQuery<void, GQLEvixAdmin>(GQL_GET_EVIX_ADMIN_PROFILE, simulateDelay)

  const getHospital = useODQuery<Partial<GQLSingleIDInput>, GQLHospital>(GQL_GET_HOSPITAL, simulateDelay)
  const updateHospital = useODMutation<Partial<GQLHospitalUpdateInput>, GQLHospital>(GQL_UPDATE_HOSPITAL, simulateDelay)

  const listHospitalManager = useODQuery<GQLListableHospitalManagerInput, GQLListableHospitalManager>(
    GQL_LIST_HOSPITAL_MANAGER,
    simulateDelay
  )
  const resetHospitalManagerPassword = useODMutation<GQLSingleIDInput, GQLOkResponse>(
    GQL_RESET_HOSPITAL_MANAGER_PASSWORD,
    simulateDelay
  )
  const deleteHospitalManager = useODMutation<GQLSingleIDInput, GQLOkResponse>(
    GQL_DELETE_HOSPITAL_MANAGER,
    simulateDelay
  )

  const listPharmacyHasHospital = useODQuery<
    Partial<GQLListablePharmacyHasHospitalInput>,
    GQLListablePharmacyHasHospital
  >(GQL_LIST_PHARMACY_HAS_HOSPITAL, simulateDelay)

  const changeDoctorHospital = useODMutation<GQLChangeDoctorHospitalInput, GQLDoctor>(
    GQL_CHANGE_DOCTOR_HOSPITAL,
    simulateDelay
  )

  const resetDoctorPassword = useODMutation<GQLSingleIDInput, GQLOkResponse>(GQL_RESET_DOCTOR_PASSWORD, simulateDelay)

  const listAppVersion = useODQuery<GQLListableAppVersionInput, GQLListableAppVersion>(
    GQL_LIST_APP_VERSION,
    simulateDelay
  )
  const createAppVersion = useODMutation<GQLAppVersionCreationInput, GQLAppVersion>(
    GQL_CREATE_APP_VERSION,
    simulateDelay
  )

  const createPopupInfo = useODMutation<GQLPopupInfoCreationInput, GQLPopupInfo>(GQL_CREATE_POPUP_INFO, simulateDelay)

  const updatePopupInfo = useODMutation<GQLPopupInfoUpdateInput, GQLPopupInfo>(GQL_UPDATE_POPUP_INFO, simulateDelay)

  const deletePopupInfo = useODMutation<GQLPopupInfoDeleteInput, GQLPopupInfo>(GQL_DELETE_POPUP_INFO, simulateDelay)

  const rejoinPatientByAdmin = useODMutation<GQLRejoinPatientByAdminInput, GQLOkResponse>(
    GQL_REJOIN_PATIENT_BY_ADMIN,
    simulateDelay
  )

  return {
    loginAdmin,
    logout,
    getAdminProfile,
    updateHospital,
    listHospitalManager,
    resetHospitalManagerPassword,
    deleteHospitalManager,
    listPharmacyHasHospital,
    changeDoctorHospital,
    resetDoctorPassword,
    listAppVersion,
    createAppVersion,
    createPopupInfo,
    updatePopupInfo,
    deletePopupInfo,
    rejoinPatientByAdmin,
    getHospital,
  }
}
