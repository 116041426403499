import React from 'react'
import ReactDOM from 'react-dom'
import styled from 'styled-components'
import { ImageButton, BP } from '../common/RebeccaButton'
import SVGClose from '../assets/popup_close.svg'
import { OS, ERROR_TYPE, WebUtils, getDeviceState } from '../utils/webUtils'
import SVGBrowserGuide from '../assets/browser_guide.svg'
import SVGWinGuide from '../assets/win_guide.svg'
import SVGMacGuide from '../assets/mac_guide.svg'

const Popup = styled.div`
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
`

const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`

const Wrapper = styled.div`
  color: white;
  display: flex;
  flex-direction: column;

  font-family: Noto Sans KR;
  font-style: normal;

  width: 938px;
`

const WrapperMac = styled.div`
  color: white;
  display: flex;
  flex-direction: column;

  font-family: Noto Sans KR;
  font-style: normal;

  margin: 255px auto auto auto;
  width: 938px;
`

const Title = styled.span`
  font-weight: bold;
  font-size: 28px;
  line-height: 36px;
  letter-spacing: -0.5px;
`

const TitleDesc = styled.span`
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.3px;
`

const SubTitle = styled.span`
  font-weight: bold;
  font-size: 20px;
  line-height: 36px;
  letter-spacing: -0.5px;
  margin-top: 40px;
`

const Text = styled.span`
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: -0.5px;
  margin-top: 38px;
`

const Image = styled.img`
  margin-top: 38px;
`

const CloseWrapper = styled.div`
  position: absolute;
  top: 86px;
  right: 43px;
`

interface IDevicePopupProps {
  isOpen: boolean
  onClose?: () => void
  state: string,
  os: string,
}

const DevicePopup: React.FC<IDevicePopupProps> = props => {
  const { onClose, isOpen, os, state } = props

  const sysErr = <>
    <WrapperMac>
      <Title>시스템에서 카메라와 마우스가 차단됨</Title>
      <Text>1. 마이크와 카메라를 활성화 하시려면 다음단계를 따르세요.</Text>
      {os === OS.WIN && (<>
        <Image src={SVGWinGuide} />
        <Text>2. 완료 후 브라우저 페이지 새로고침하여 다시 이용해 보세요.</Text>
      </>)}
      {os === OS.MAC && (<>
        <Image src={SVGMacGuide} />
        <Text>2. 완료 후 브라우저 페이지 새로고침하여 다시 이용해 보세요.</Text>
      </>)}
    </WrapperMac>
  </>

  const browserErr = <>
    <WrapperMac>
      <Title>마이크 또는 카메라 차단 됨</Title>
      <TitleDesc>메디팡팡에서 마이크와 카메라에 엑세스 해야 합니다. 다음단계를 따라 주세요.</TitleDesc>
      <Text>1. 브라우저의 검색 주소창에서 차단된 카메라 아이콘을 클릭합니다.</Text>
      <Image src={SVGBrowserGuide} />
      <Text>2. 엑세스를 허용한 후 다음 페이지를 새로고침 합니다.</Text>
    </WrapperMac>
  </>

  const winErr = <>
  <Wrapper>
    <Title>카메라와 마우스가 차단됨</Title>
    <TitleDesc>메디팡팡에서 마이크와 카메라에 엑세스 해야 합니다. 다음단계를 따라 주세요.</TitleDesc>
    <SubTitle>브라우저 설정</SubTitle>
    <Text>1. 브라우저의 검색 주소창에서 차단된 카메라 아이콘을 클릭합니다.</Text>
    <Image src={SVGBrowserGuide} />
    <Text>2. 엑세스를 허용한 후 다음 페이지를 새로고침 합니다.</Text>
    <SubTitle>시스템 설정</SubTitle>
    <Text>1. 마이크와 카메라를 활성화 하시려면 다음단계를 따르세요.</Text>
    <Image src={SVGWinGuide} />
    <Text>2. 완료 후 브라우저 페이지 새로고침하여 다시 이용해 보세요.</Text>
  </Wrapper>
</>

  return (
    <Popup style={!isOpen ? { display: 'none' } : {}}>
      {onClose && (
        <CloseWrapper>
          <ImageButton type={BP.TYPE.InvisibleWithIcon} size={BP.SIZE.S} images={{default: SVGClose} } onClick={onClose}
          style={{backgroundSize: '24px 24px'}}
          ></ImageButton>
        </CloseWrapper>)
      }
      <Content>
        {os !== OS.MAC ? winErr : state === ERROR_TYPE.BROWSER ? browserErr : sysErr}
      </Content>
    </Popup>
  )
}

export const openPopup = () => {
  const os = WebUtils.getOS()
  getDeviceState().then((state: string) => {
    if (state !== ERROR_TYPE.NONE) {
      const rootElem = document.getElementById('root')
      const newElem = document.createElement('div')
      newElem.setAttribute('id', 'medi-popup')
      newElem.classList.add('medi-popup-device')
      rootElem?.appendChild(newElem)
      console.log('Popup Device state: ', os, state)
      ReactDOM.render(<DevicePopup isOpen={true} state={state} os={os} onClose={closePopup}/>, newElem)
    }
  })
}

export const closePopup = () => {
  const rootElem = document.getElementById('root')
  console.log(rootElem?.childNodes)
  const index = rootElem?.childNodes && rootElem.childNodes.length - 1 || -1
  if (index >= 0) {
    rootElem?.removeChild(rootElem.childNodes[index])
  }
}