import { ODListableContextType, useCounter } from '@mdpp/od-react-belt'
import { ODRendererEvent, ODRendererEventType } from '@mdpp/od-belt'
import React from 'react'
import { GQLReservation } from '@mdpp/common/lib/@types/server.schema'
import { IReservationEntityContext, IReservationListableOption } from './ReservationCommon'

export type ReservationEventHandler = (
  ev: ODRendererEvent<GQLReservation>,
  parentContext: IReservationEntityContext
) => void

export function useReservationEventHandler(
  listableContext: React.Context<ODListableContextType<GQLReservation, IReservationListableOption>>
): { onEvent: ReservationEventHandler; token: number } {
  const [token] = useCounter()
  const onEvent = async function(ev: ODRendererEvent<GQLReservation>, context: IReservationEntityContext) {
    try {
      switch (ev.eventType) {
        case ODRendererEventType.ChangeValue: {
          break
        }
      }
    } catch (ex) {
      // tslint:disable-next-line:no-console
      console.error(421, `Failed to handle onEvent : ${ex.message}`)
    }
  }

  return { onEvent, token }
}
