import React from 'react'
import { LogoutPage } from '../login/LogoutPage'
import { SiteUrls } from '../SiteUrls'
import { usePharmacyAppContext } from './context/PharmacyAppContext'

interface IPharmaLogoutPageProps {}

export const PharmacyLogoutPage: React.FC<IPharmaLogoutPageProps> = props => {
  const { logout } = usePharmacyAppContext()
  return <LogoutPage logout={logout} urlAfterLogout={SiteUrls.Pharmacy.Login} />
}
