import React, { ReactNode } from "react";
import { BlockingLoadBox } from "@mdpp/od-react-belt";
import styled from "styled-components/macro";
import { COLORS } from '../../../styles/colors';
import { InputCircleCheckboxSupportWrapper } from '../../../common/style/CommonStyle'
import SP_HOSPITAL_DATA from "./SPHospitalData";

interface IHospitalDataListableTableProps {
  list: SP_HOSPITAL_DATA[]
  loading: boolean
  selectedInd: number
  onSelect: (selectedInd: number) => void
}

const renderHeader = () => {
  return (
    <HeaderTr>
      <th style={{width: 48}}><label>선택</label><span></span></th>
      <th style={{width: 170}}><label>병/의원명</label><span></span></th>
      <th><label>주소</label><span></span></th>
      <th style={{width: 70}}><label>우편번호</label><span></span></th>
      <th style={{width: 170}}><label>연락처</label></th>
    </HeaderTr>
  )
}
const renderRow = (item: SP_HOSPITAL_DATA, index: number, selected: boolean, onSelect: (selectedInd: number) => void) => {
  return (
    <BodyTr key={item.yadmNm + item.addr}>
      <td>
        <InputCircleCheckboxSupportWrapper  onClick={() => {onSelect(index)}}>
          {selected && (
            <span className="selected"></span>
          )}
          {!selected && (
            <span></span>
          )}
        </InputCircleCheckboxSupportWrapper>
      </td>
      <td onClick={() => {onSelect(index)}}>
        {item.yadmNm}
      </td>
      <td onClick={() => {onSelect(index)}}>
        {item.addr}
      </td>
      <td align='center' onClick={() => {onSelect(index)}}>
        {item.postNo}
      </td>
      <td align='center' onClick={() => {onSelect(index)}}>
        {item.telno}
      </td>
    </BodyTr>
  )
}

export const HospitalDataListableTable: React.FC<IHospitalDataListableTableProps> = props => {
  const { list, loading, selectedInd, onSelect } = props
  return (
    <>
      <BlockLoadingWrap>
        <BlockingLoadBox show={loading} />
        <table>
          <thead>{renderHeader()}</thead>
          <tbody>
            {list.length > 0 && list.map((v, index) => renderRow(v, index, index === selectedInd, onSelect))}
          </tbody>
        </table>
      </BlockLoadingWrap>
      
      
    </>
  )
}

const BlockLoadingWrap = styled.div`
  position: relative;

  > div {
    position: absolute !important;
    top: 38px !important;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, .5);
  }

  > table {
    width: 100%;
    height: 361px;
  }
`

const HeaderTr = styled.tr`
  width: 100%;  
  height: 38px;
  padding: 12px 0;
  border-radius: 4px;
  background-color: ${COLORS.GRAYEE};
 
  th {
    position: relative;
    text-align: center;
    padding: 0 5px;
  }

  label {
    font-size: 13px;
    font-weight: 500;
    line-height: 1;
    letter-spacing: normal;
    color: ${COLORS.GRAYAB};
    margin: 0;
    padding: 0;
  }

  span {
    position: absolute;
    top: 13px;
    right: 0;
    width: 1px;
    height: 13px;
    background-color: ${COLORS.GRAYCC};
  }
`

const BodyTr = styled.tr`
  border-bottom: 1px solid ${COLORS.GRAYE0};
  
  > td {
    min-height: 44px;
    padding: 12px 5px 14px 5px;
    font-size: 13px;
    font-weight: 500;
    line-height: 1;
    color: ${COLORS.GRAY42};
  }
`