import { ODIcon, ODIcons } from '@mdpp/od-react-belt'
import React from 'react'
import styled from 'styled-components'

interface IEvixIconTextProps {
  icon: ODIcons
  color: string
  fontSize: number
  spacing?: number
  append?: boolean
  pointer?: boolean
}

export const EvixIconText: React.FC<IEvixIconTextProps & React.HTMLAttributes<HTMLSpanElement>> = props => {
  const { icon, color, fontSize, append = false, spacing = 3, pointer = false, children, ...spanAttr } = props
  return (
    <Wrapper style={{ color, fontSize, cursor: pointer ? 'pointer' : 'normal' }} {...spanAttr}>
      {!append && <ODIcon icon={icon} />}
      {!append && spacing > 0 && <div style={{ display: 'inline-block', width: spacing }} />}
      {children}
      {append && spacing > 0 && <div style={{ display: 'inline-block', width: spacing }} />}
      {append && <ODIcon icon={icon} />}
    </Wrapper>
  )
}

const Wrapper = styled.span`
  letter-spacing: 0.3px;
}
`
