import { createNavItem, ODIcons, ODSiteNav, ODSiteNavItem, useODQuery } from '@mdpp/od-react-belt'
import { SiteUrls } from '../SiteUrls'
import { ODStringRenderable } from '@mdpp/od-belt'
import { PharmacistMyPage } from '../pharmacy/mypage/PharmacistMyPage'

export const getAdminSiteNavs = (
  getWaitDoctorCnt: () => Promise<number>,
  getWaitPharmacistCnt: () => Promise<number>
) => {
  const createWaitDoctorNavi = (icon: ODIcons, name: ODStringRenderable, url: string) => {
    const item: ODSiteNavItem = createNavItem(ODIcons.SVGDoctorCheck, `의사 가입승인`, SiteUrls.Admin.WatingDoctor.Main)
    item.getCount = getWaitDoctorCnt
    return item
  }

  const createWaitPharmaNavi = (icon: ODIcons, name: ODStringRenderable, url: string) => {
    const item: ODSiteNavItem = createNavItem(
      ODIcons.SVGDoctorCheck,
      `약국 가입승인`,
      SiteUrls.Admin.WatingPharmacy.Main
    )
    item.getCount = getWaitPharmacistCnt
    return item
  }

  return {
    items: [
      createNavItem(ODIcons.Dashboard, `대시보드`, SiteUrls.Admin.Dashboard.Main),
      createNavItem(ODIcons.CoreBuilding, `병원`, SiteUrls.Admin.Hospital.Main),
      createNavItem(ODIcons.SVGDoctor, `의사`, SiteUrls.Admin.Doctor.Main),
      createWaitDoctorNavi(ODIcons.SVGDoctorCheck, `의사 가입승인`, SiteUrls.Admin.WatingDoctor.Main),
      createNavItem(ODIcons.Patient, `환자`, SiteUrls.Admin.Patient.Main),
      createNavItem(ODIcons.Inquiry, `진료`, SiteUrls.Admin.Reservation.Main),
      createNavItem(ODIcons.CoreSpeech, `리뷰`, SiteUrls.Admin.Review.Main),
      createNavItem(ODIcons.CoreFilterPhoto, `약국`, SiteUrls.Admin.Pharmacy.Main),
      createWaitPharmaNavi(ODIcons.SVGDoctorCheck, `약국 가입승인`, SiteUrls.Admin.WatingPharmacy.Main),
      createNavItem(ODIcons.CoreSettingCog, `진료과목 관리`, SiteUrls.Admin.MedicalSubject.Main),
      createNavItem(ODIcons.CoreClipboard, `공지사항`, SiteUrls.Admin.Notice.Main),
      createNavItem(ODIcons.CoreMobile, `앱 버전`, SiteUrls.Admin.AppVersion.Main),
      createNavItem(ODIcons.CoreChatBubble, `자주하는 질문`, SiteUrls.Admin.Faq.Main),
      createNavItem(ODIcons.CoreEnvelopeClosed, `문의 내역`, SiteUrls.Admin.Inquiry.Main),
    ],
  }
}
