import { ODWebUtils } from '@mdpp/od-react-belt'
import { isString } from 'lodash'
import { useCallback } from 'react'

type MessageGeneratorFunc<IN_TYPE, OUT_TYPE> = (input: IN_TYPE, output: OUT_TYPE) => string | undefined

interface IMutationAPIOptions<IN_TYPE, OUT_TYPE> {
  onSuccess: string | MessageGeneratorFunc<IN_TYPE, OUT_TYPE>
  onError: string | MessageGeneratorFunc<IN_TYPE, string>
  setLoading: (loading: boolean) => void
}

/**
 * mutation api 를 수행하고, 결과에 따라 자동으로 토스트 메시지를 출력하고 오류를 처리하도록 wrapping 하는 hooks
 */
export function useMutationAPI<IN_TYPE, OUT_TYPE>(
  api: (input: IN_TYPE) => Promise<OUT_TYPE>,
  options: Partial<IMutationAPIOptions<IN_TYPE, OUT_TYPE>> = {}
) {
  return useCallback(
    async (input: IN_TYPE) => {
      options.setLoading?.(true)
      try {
        const ret = await api(input)
        if (isString(options.onSuccess)) {
          ODWebUtils.showSuccess(options.onSuccess)
        } else if (options.onSuccess) {
          const message = options.onSuccess(input, ret)
          if (message) {
            ODWebUtils.showSuccess(message)
          }
        }
        return ret
      } catch (ex) {
        const errMessage = ODWebUtils.parseErrorMessage(ex) ?? ex.message
        if (isString(options.onError)) {
          ODWebUtils.showError(options.onError)
        } else if (options.onError) {
          const message = options.onError(input, errMessage)
          if (message) {
            ODWebUtils.showError(message)
          }
        } else {
          ODWebUtils.showError(errMessage)
        }
      } finally {
        options.setLoading?.(false)
      }
    },
    [api, options]
  )
}
