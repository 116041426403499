import React from 'react'
import { LogoutPage } from '../login/LogoutPage'
import { SiteUrls } from '../SiteUrls'
import { useManagerAppContext } from './context/ManagerAppContext'

interface IManagerLogoutPageProps {}

export const ManagerLogoutPage: React.FC<IManagerLogoutPageProps> = props => {
  const { logout } = useManagerAppContext()
  return <LogoutPage logout={logout} urlAfterLogout={SiteUrls.Manager.Login} />
}
