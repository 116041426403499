import moment from "moment";

interface StorageObj {
  expiry: moment.Moment;
}

export class LocalStorageWithExpiryCls {
  
  save(key: string, data: any, expiry: moment.Moment) {
    let obj;
    const expiryObj = { expiry: expiry };
    obj = data instanceof Object ? { ...data, ...expiryObj } : { value: data, ...expiryObj };
    const dataString = JSON.stringify(obj);
    localStorage.setItem(key, dataString);
    return Promise.resolve();
  }
  
  load(key: string) {
    const dataString = localStorage.getItem(key);
    return new Promise((resolve) => {
      if (dataString) {
        const dataObj: StorageObj = JSON.parse(dataString);
        if (dataObj.expiry && moment(dataObj.expiry).isAfter(moment())) {
          // @ts-ignore
          delete dataObj.expiry;
          resolve(dataObj);
        }
      }
      resolve();
    });
  }
}

export const LocalStorageWithExpiry = new LocalStorageWithExpiryCls()