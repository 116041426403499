import { ODIcon, ODIcons } from '@mdpp/od-react-belt'
import React from 'react'
import styled from 'styled-components'

interface IMedicalSubjectTagProps {
  name: string
}

const Wrapper = styled.div`
  float: left;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 36px;
  border-radius: 5px;
  background-color: var(--gray100);
  padding-left: 14px;
  padding-right: 14px;
  margin-right: 8px;
  margin-bottom: 8px;
`

const Name = styled.div`
  white-space: nowrap;
  margin-left: 10px;
`

export const MedicalSubjectTag: React.FC<IMedicalSubjectTagProps> = props => {
  const { name } = props
  return (
    <Wrapper>
      <ODIcon icon={ODIcons.Patient} />
      <Name>{name}</Name>
    </Wrapper>
  )
}
