import { produce } from 'immer'
import * as React from 'react'
import { GQLHospitalManager, GQLHospitalManagerLoginInput } from '@mdpp/common/lib/@types/server.schema'
import { AppOptions, I_ADMIN_OPTIONS, LOCAL_STORAGE_KEY_TOKEN } from '../../AppOptions'
import { useManagerAPIs } from './useManagerAPIs'

interface IManagerAppContextType {
  state: IManagerAppReducerState
  login: (input: GQLHospitalManagerLoginInput) => Promise<GQLHospitalManager>
  logout: () => Promise<void>
  refreshProfile: () => Promise<GQLHospitalManager>
}

export enum LOGIN_STATE {
  Checking = 'Checking',
  LoggedOut = 'LoggedOut',
  LoggingIn = 'LoggingIn',
  LoggedIn = 'LoggedIn',
  LoggingOut = 'LoggingOut',
}

interface IManagerAppContextProviderProps {}

interface IManagerAppReducerState {
  loginState: LOGIN_STATE
  profile: GQLHospitalManager | null
}

enum ManagerAppActionType {
  SetLoggedIn = 'odDoctorApp/SetLoggedIn',
  SetLoggedOut = 'odDoctorApp/SetLoggedOut',
  SetAppConfig = 'odDoctorApp/SetAppConfig',
  SetNodeClick = 'odDoctorApp/SetNodeClick', // whenever directory view's node is clicked, count++
}

interface IManagerAppActionSetLoggedIn {
  type: ManagerAppActionType.SetLoggedIn
  profile: GQLHospitalManager
}

interface IManagerAppActionSetLoggedOut {
  type: ManagerAppActionType.SetLoggedOut
}

type ManagerAppReducerAction = IManagerAppActionSetLoggedIn | IManagerAppActionSetLoggedOut

interface IManagerAppReducer extends React.Reducer<IManagerAppReducerState, ManagerAppReducerAction> {}

function createAppReducer(): IManagerAppReducer {
  return function (state: IManagerAppReducerState, action: ManagerAppReducerAction): IManagerAppReducerState {
    return produce(state, draft => {
      switch (action.type) {
        case ManagerAppActionType.SetLoggedIn:
          draft.loginState = LOGIN_STATE.LoggedIn
          draft.profile = action.profile
          break
        case ManagerAppActionType.SetLoggedOut:
          draft.loginState = LOGIN_STATE.LoggedOut
          break
        default:
          return
      }
    })
  }
}

const actionSetLoggedIn = (profile: GQLHospitalManager): IManagerAppActionSetLoggedIn => ({
  type: ManagerAppActionType.SetLoggedIn,
  profile,
})
const actionSetLoggedOut = (): IManagerAppActionSetLoggedOut => ({ type: ManagerAppActionType.SetLoggedOut })

function createInitialAppReducerState(): IManagerAppReducerState {
  return {
    loginState: LOGIN_STATE.Checking,
    profile: null,
  }
}

function createManagerAppContext(appOptions: I_ADMIN_OPTIONS) {
  const Context: React.Context<IManagerAppContextType> = React.createContext<IManagerAppContextType>(
    // tslint:disable-next-line
    {} as IManagerAppContextType
  )

  const DoctorProvider: React.FC<IManagerAppContextProviderProps> = props => {
    const { children } = props

    const { loginManager: apiLogin, getManagerProfile: apiGetProfile, logout: apiLogout } = useManagerAPIs()

    const [state, dispatch] = React.useReducer<IManagerAppReducer>(createAppReducer(), createInitialAppReducerState())
    const setLoggedIn = React.useCallback((profile: GQLHospitalManager) => dispatch(actionSetLoggedIn(profile)), [
      dispatch,
    ])

    const checkLogin = React.useCallback(async () => {
      try {
        // const appConfig = await apiGetAppConfig()
        // dispatch(actionSetAppConfig(appConfig))

        const profile = await apiGetProfile()
        setLoggedIn(profile)
      } catch (ex) {
        // 로그인 실패, 로그아웃된 상태
        dispatch(actionSetLoggedOut())
      }
    }, [apiGetProfile, setLoggedIn])

    const login = React.useCallback(
      async (data: GQLHospitalManagerLoginInput): Promise<GQLHospitalManager> => {
        const res = await apiLogin(data)
        localStorage.setItem(LOCAL_STORAGE_KEY_TOKEN, res.token)
        setLoggedIn(res.me)

        return res.me
      },
      [apiLogin, setLoggedIn]
    )

    const logout = React.useCallback(async () => {
      await apiLogout()
      localStorage.removeItem(LOCAL_STORAGE_KEY_TOKEN)
      const managerEmail = localStorage.getItem('managerEmail')
      const doctorEmail = localStorage.getItem('doctorEmail')
      const adminEmail = localStorage.getItem('adminEmail')
      const pharmacyEmail = localStorage.getItem('pharmacyEmail')
      managerEmail ? localStorage.setItem('managerEmail', managerEmail) : console.log('not remember manager !')
      doctorEmail ? localStorage.setItem('doctorEmail', doctorEmail) : console.log('not remember doctorEmail !')
      adminEmail ? localStorage.setItem('adminEmail', adminEmail) : console.log('not remember adminEmail !')
      pharmacyEmail ? localStorage.setItem('pharmacyEmail', pharmacyEmail) : console.log('not remember pharmacyEmail !')
      dispatch(actionSetLoggedOut())
    }, [apiLogout])

    const refreshProfile = React.useCallback(async () => {
      const profile = await apiGetProfile()
      setLoggedIn(profile)
      return profile
    }, [apiGetProfile, setLoggedIn])

    React.useEffect(() => {
      // noinspection JSIgnoredPromiseFromCall
      checkLogin()
    }, [checkLogin])

    const context: IManagerAppContextType = {
      state,
      login,
      logout,
      refreshProfile,
    }
    return <Context.Provider value={context}>{children}</Context.Provider>
  }

  return { Context, Provider: DoctorProvider }
}

const { Context: AppContext, Provider } = createManagerAppContext(AppOptions)

export const ManagerAppProvider = Provider
export const ManagerAppContext = AppContext

export function useManagerAppContext(): IManagerAppContextType {
  return React.useContext(AppContext)
}
