import React from 'react'
import { HospitalListableTable } from '../../common/hospital/list/HospitalListableTable'
import {Page, PageContainer, PageHeader, PageTitle} from "../../common/page/Page";

interface IAdminHospitalPageProps {}

export const AdminHospitalListPage: React.FC<IAdminHospitalPageProps> = props => {
  return (

    <PageContainer>
      <Page>
        <PageHeader>
            <PageTitle>병원</PageTitle>
        </PageHeader>
        
        <HospitalListableTable fromEvixAdmin />
  
      </Page>
    </PageContainer>

  )
}
