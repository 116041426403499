import { ODWebUtils } from '@mdpp/od-react-belt'
import React from 'react'
import { Redirect } from 'react-router-dom'
import { CenterWithFooterContainer } from '../common/layout/CenterWithFooterContainer'
import { LoginBox, LoginBoxDesignStyle } from '../login/LoginBox'
import { SiteUrls } from '../SiteUrls'
import { useManagerAppContext } from './context/ManagerAppContext'
import moment from 'moment-timezone'
import { useCookies } from 'react-cookie'
import { useManagerAPIs } from './context/useManagerAPIs'

export const ManagerLoginPage: React.FC = () => {
  const { login } = useManagerAppContext()

  const [redirect, setRedirect] = React.useState('')

  const { loginManager } = useManagerAPIs()
  const COOKIE_KEY = 'hidePasswardPage'
  const [cookies, setCookie] = useCookies([COOKIE_KEY])

  if (redirect) {
    return <Redirect to={redirect} />
  }

  const handleLogin = async (email: string, password: string) => {
    const res = await loginManager({ email, password })
    const needUpdate = moment().diff(moment(res.me.lastPasswordUpdatedTime), 'day') >= 180

    try {
      await login({ email, password })
      if (res?.me.leftAt === null) {
        if (needUpdate) {
          setTimeout(() => {
            setRedirect(
              cookies.hidePasswardPage === 'true' ? SiteUrls.Manager.Dashboard.Main : SiteUrls.Manager.ChangePassword
            )
          }, 100)
        } else {
          setRedirect(SiteUrls.Manager.Dashboard.Main)
        }
      }
      return true
    } catch (ex) {
      ODWebUtils.showError(ex)
      return false
    }
  }

  return (
    <CenterWithFooterContainer>
      <LoginBox designStyle={LoginBoxDesignStyle.HospitalManager} onLogin={handleLogin} />
    </CenterWithFooterContainer>
  )
}
