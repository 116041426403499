import { ODIcon, ODIcons, ODVSpace } from '@mdpp/od-react-belt'
import React from 'react'
import styled from 'styled-components'

interface IEvixDashboardCardProps {
  mainColor?: string
  icon?: ODIcons
  title?: string
  contents?: string
}

export const EvixDashboardCard: React.FC<IEvixDashboardCardProps> = props => {
  const { mainColor = 'var(--second)', icon = ODIcons.Doctor, title = '총 의사 인원', contents = '100 / 123' } = props
  return (
    <Wrapper>
      <LeftWrap>
        <HeaderIconRow color={mainColor}>
          <IconWrapper color={mainColor}>
            <ODIcon icon={icon} />
          </IconWrapper>
        </HeaderIconRow>
        <TitleRow>{title}</TitleRow>
      </LeftWrap>
      <Contents color={mainColor}>{contents}</Contents>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  height: 170px;
  border-radius: 8px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.15);
  background-color: var(--white);
`

const LeftWrap = styled.div `
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding-top: 40px;
`

const HeaderIconRow = styled.div`
  border-left: 3px solid ${(p: { color: string }) => p.color};
  height: 30px;
`

const IconWrapper = styled.div`
  font-size: 22px;
  line-height: 30px;
  padding-left: 18px;
  color: ${(p: { color: string }) => p.color};
`

const TitleRow = styled.div`
  padding-left: 10px;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.33;
  color: var(--gray900);
  letter-spacing: -.3px;
`

const Contents = styled.div`
  padding-top: 10px;
  padding-left: 59px;
  font-size: 34px;
  font-weight: 600;
  color: ${(p: { color: string }) => p.color};
`
