import { ODHSpace } from '@mdpp/od-react-belt'
import React from 'react'
import Select from 'react-select'
import styled from 'styled-components'
import { PharmacyReservationStatus } from './PharmacyReservationToolBar'

interface IPharmacyReservationStatusSelector {
  value: PharmacyReservationStatus
  onChange: (changedValue: PharmacyReservationStatus) => void
}

const Wrapper = styled.div`
  // React Select Style
  .css-2b097c-container {
    & svg {
      font-size: 24px;
      color: var(--gray600);
    }
  }
  .css-1okebmr-indicatorSeparator {
    background-color: transparent;
  }

  .css-1laao21-a11yText {
    display: none;
  }
`

// react-select 스타일 수정 .
export const colourStyles = {
  control: (styles: any) => {
    return {
      ...styles,
      backgroundColor: 'white',
      width: 200,
      borderRadius: 8,
      border: '1px solid #C9CCCE',
      boxShadow: 'none',
      ':hover': {
        ...styles[':hover'],
        border: '1px solid #9CA0A3',
      },
      ':active': {
        ...styles[':active'],
        border: '1px solid #9CA0A3',
      },
    }
  },
  option: (styles: any, { data, isDisabled, isFocused, isSelected }: any) => {
    return {
      ...styles,
      backgroundColor: isDisabled ? null : isSelected ? 'var(--gray600)' : isFocused ? 'rgba(41,182,202,0.1)' : null,

      ':active': {
        ...styles[':active'],
        backgroundColor: !isDisabled && (isSelected ? 'var(--gray500)' : 'rgba(41,182,202,0.1)'),
      },
    }
  },
}

export const ReservationStatusSelector: React.FC<IPharmacyReservationStatusSelector> = props => {
  const { value, onChange } = props

  const PHARMACY_RESERVATION_STATUS_TEXT = {
    [PharmacyReservationStatus.ALL]: '모든조제',
    [PharmacyReservationStatus.REQUESTED]: '조제신청',
    [PharmacyReservationStatus.ACCEPTED]: '조제승인',
    [PharmacyReservationStatus.REFUSED]: '조제거절',
    [PharmacyReservationStatus.CANCELED]: '조제취소',
    [PharmacyReservationStatus.PAIED]: '결제완료',
    [PharmacyReservationStatus.NOT_PAIED]: '결제실패',
    // [PharmacyReservationStatus.FINISHED]: '발송완료',
    // [PharmacyReservationStatus.FAX_FINISHED]: '처방전 FAX 전송',
  }

  const options = [
    { value: PharmacyReservationStatus.ALL, label: PHARMACY_RESERVATION_STATUS_TEXT.ALL },
    { value: PharmacyReservationStatus.REQUESTED, label: PHARMACY_RESERVATION_STATUS_TEXT.REQUESTED },
    { value: PharmacyReservationStatus.ACCEPTED, label: PHARMACY_RESERVATION_STATUS_TEXT.ACCEPTED },
    { value: PharmacyReservationStatus.REFUSED, label: PHARMACY_RESERVATION_STATUS_TEXT.REFUSED },
    { value: PharmacyReservationStatus.CANCELED, label: PHARMACY_RESERVATION_STATUS_TEXT.CANCELED },
    { value: PharmacyReservationStatus.PAIED, label: PHARMACY_RESERVATION_STATUS_TEXT.PAIED },
    { value: PharmacyReservationStatus.NOT_PAIED, label: PHARMACY_RESERVATION_STATUS_TEXT.NOT_PAIED },
    // { value: PharmacyReservationStatus.FINISHED, label: PHARMACY_RESERVATION_STATUS_TEXT.FINISHED },
    // { value: PharmacyReservationStatus.FAX_FINISHED, label: PHARMACY_RESERVATION_STATUS_TEXT.FAX_FINISHED },
  ]

  return (
    <Wrapper>
      <Select
        name="PharmacyReservationStatusSelector"
        options={options}
        value={options.find(v => v.value === value)}
        onChange={(e: any) => {
          onChange(e.value)
        }}
        styles={colourStyles}
      />
    </Wrapper>
  )
}
