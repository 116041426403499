import React from 'react'
import { Page, PageContainer, PageContent, PageHeader, PageTitle } from '../../common/page/Page'
import { LinkStyle } from '../../common/schedule/ScheduleStatusPopOver'
import { ScheduleEditingPage } from '../../common/schedule/table/ScheduleEditingPage'
import { SiteUrls } from '../../SiteUrls'

interface IAdminDoctorScheduleTableProps {
  doctorId: number
}

const page = { paddingTop: 36 }

export const AdminDoctorScheduleTablePage: React.FC<IAdminDoctorScheduleTableProps> = props => {
  const { doctorId } = props

  return (
    <ScheduleEditingPage
    isFromDoctorSelf={false}
    doctorId={doctorId}
    compactMargin={false}
    linkToDefaultSetup={SiteUrls.Admin.Doctor.SetupScheduleDefault(doctorId)}
    linkStyle={LinkStyle.Admin}
  />
        
    
  )
}
