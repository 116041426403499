import { ODCommonMainLayout } from '@mdpp/od-react-belt'
import * as React from 'react'
import { SiteUrls } from '../SiteUrls'
import { PharmaRoutes } from './PharmaRoutes'
import { PharmacySiteNavs } from './PharmacySiteNavs'
import { PharmacyHeader } from './gnb/PharmacyHeader'
import ChannelService from 'channel-talk-ts'
import { AppOptions } from '../AppOptions'

export const PharmacyMainLayout: React.FC = () => {
  React.useEffect(() => {
    const CS = new ChannelService({ pluginKey: AppOptions.CHANNEL_TALK_PHARMACY_PLUGIN_KEY || 'testkey' })
    CS.boot()

    return () => {
      CS.shutdown()
    }
  }, [])
  return (
    <>
      <ODCommonMainLayout
        renderHeader={() => <PharmacyHeader />}
        defaultRoute={SiteUrls.Pharmacy.Reservation.Main}
        navs={PharmacySiteNavs}
        routes={PharmaRoutes}
        style={{
          padding: 0,
          marginLeft: 0,
          marginTop: 0,
          marginBottom: 0,
          marginRight: 0,
          background: '#F4F6F8',
        }}
      />
    </>
  )
}
