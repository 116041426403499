import {
  ODIcon,
  ODIcons,
  ODListablePaginatedTable2,
  ODListablePagination,
  ODListableSearchBox,
  useODListableContext,
} from '@mdpp/od-react-belt'
import moment from 'moment-timezone'
import React, { useState } from 'react'
import Select from 'react-select'
import { Link } from 'react-router-dom'
import { Card, CardBody } from 'reactstrap'
import styled from 'styled-components/macro'
import { GQLInquiry } from '@mdpp/common/lib/@types/server.schema'
import { SiteUrls } from '../../../SiteUrls'
import { WebUtils } from '../../../utils/webUtils'
import { IInquiryListableOption } from '../InquiryCommon'
import { useInquiryListDataLoader } from './useInquiryListDataLoader'
import './inquiry-listable-table.scss'
import {Page, PageContainer, PageContent, PageHeader, PageTitle} from "../../page/Page";
import { EvixCard } from '../../card/EvixCard'

interface IInquiryListableTableProps {}

const options = [
  { value: 'NAME', label: '문의자 명' },
  { value: 'EMAIL', label: '이메일' },
]

export const InquiryListableTable: React.FC<IInquiryListableTableProps> = props => {
  const { Provider, Context } = useODListableContext<GQLInquiry, IInquiryListableOption>()

  const dataLoader = useInquiryListDataLoader({})

  const searchBoxStyle = { flex: 1,}
  const wrapperStyle = { display: 'flex', alignItems: 'center', justifyContent: 'center' }
  const toolbarStyle = { display: 'flex', justifyContent: 'space-between', marginBottom: 15 }
  

  const [opt, setOpt] = useState(options[0].value)
  return (
    <Provider
      dataLoader={dataLoader}
      keyExtractor={v => {
        return v.inquiryId.toString()
      }}
      pageSize={20}
      onDataLoaderError={WebUtils.showError}
      searchOnLoad
    >
      <PageContainer>
        <Page>
          <PageHeader>
            <PageTitle>문의 내역</PageTitle>
          </PageHeader>
          <SearchWrapper style={toolbarStyle}>
            <OptionSelect
              options={options}
              value={options.find(v => v.value === opt)}
              onChange={(e: any) => setOpt(e.value)}
            />
            <ODListableSearchBox
              listableContext={Context}
              placeholder={opt === 'NAME' ? '문의자 이름으로 검색' : '이메일로 검색'}
              style={searchBoxStyle}
              preFilter={opt}
            />
          </SearchWrapper>
          <PageContent>
            <EvixCard noPadding>
              <ODListablePaginatedTable2
                numColumns={5}
                listableContext={Context}
                renderHeader={() => (
                  <tr>
                    <th style={{textAlign: 'center', width: 180 }}>등록일</th>
                    <th style={{textAlign: 'center', width: 120 }}>계정</th>
                    <th style={{textAlign: 'center', width: 150 }}>문의자명</th>
                    <th style={{textAlign: 'center' }}>이메일</th>
                    <th style={{textAlign: 'center', width: 60 }}>조회</th>
                  </tr>
                )}
                renderRow={(value: GQLInquiry, context) => (
                  <tr key={value.inquiryId} onClick={() => console.log(value)} style={{ borderBottom: '1px solid #D1D5DA', textAlign: 'center' }}>
                    <td>{moment(value.createdAt).format('ll')}</td>
                    <td>{value.doctorId === null ? '병원관리자' : '의사'}</td>
                    <td>{value.name}</td>
                    <td align='left'>{value.email}</td>
                    <td>
                      <Link to={SiteUrls.Admin.Inquiry.Item(value.inquiryId)}>
                        <IconWrapper>
                          <ODIcon icon={ODIcons.CoreMagnifyingGlass} />
                        </IconWrapper>
                      </Link>
                    </td>
                  </tr>
                )}
                eventParentContext={{}}
              />
            </EvixCard>
          </PageContent>
          <ODListablePagination hideIfSinglePage={false} listableContext={Context} wrapperStyle={wrapperStyle} />
        </Page>
      </PageContainer>
    </Provider>
  )
}

const SearchWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  margin-bottom: 24px;
  
  > div > form > div {
    > div {
      border-radius: 8px;

      > div {
        border-radius: 8px;
        background-color: white;
        > span {
          border-radius: 8px;
        }
      }

      > input {
        border-radius: 0px 8px 8px 0px;
      }
    }

    @media (max-width: 992px) {
      width: 290px;
    }
  }
`

const IconWrapper = styled.span`
  font-size: 14px;
  letter-spacing: 0.3px;
  color: var(--gray600);
`

const OptionSelect = styled(Select)`
  width: 200px;
  height: 35px;
`
