import React from 'react'
import { ODListableContextType, useCounter } from '@mdpp/od-react-belt'
import { ODRendererEvent, ODRendererEventType } from '@mdpp/od-belt'
import { GQLMedicationDispensing } from '@mdpp/common/lib/@types/server.schema'
import { IMedicationDispensingEntityContext, IMedicationDispensingListableOption } from './PharmacyCommon'

export type PharmacyReservationEventHandler = (
  ev: ODRendererEvent<GQLMedicationDispensing>,
  parentContext: IMedicationDispensingEntityContext
) => void

export function useReservationEventHandler(
  listableContext: React.Context<ODListableContextType<GQLMedicationDispensing, IMedicationDispensingListableOption>>
): { onEvent: PharmacyReservationEventHandler; token: number } {
  const [token] = useCounter()
  const onEvent = async function (
    ev: ODRendererEvent<GQLMedicationDispensing>,
    context: IMedicationDispensingEntityContext
  ) {
    try {
      switch (ev.eventType) {
        case ODRendererEventType.ChangeValue: {
          break
        }
      }
    } catch (ex) {
      // tslint:disable-next-line:no-console
      console.error(421, `Failed to handle onEvent : ${ex.message}`)
    }
  }

  return { onEvent, token }
}
