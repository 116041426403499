import { ODIcon, ODIcons } from '@mdpp/od-react-belt'
import React from 'react'
import styled from 'styled-components'

export interface IEvixPopOverMenuItem {
  key: string
  icon: ODIcons
  text: string
  color: string
}

interface IEvixPopOverMenuProps {
  show: boolean
  menus: IEvixPopOverMenuItem[]
  style?: React.CSSProperties
  onClickMenu: (key: string) => void
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 166px;
  border-radius: 3px;
  border: solid 1px var(--gray300);
  background-color: var(--white);
`

const ItemWrapper = styled.div`
  width: 100%;
  height: 42px;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 14px;
  padding-left: 14px;
  cursor: pointer;

  :hover {
    background-color: var(--gray100);
  }
`

const TextWrapper = styled.div`
  margin-left: 14px;
`

export const EvixPopOverMenu = React.forwardRef(
  (props: IEvixPopOverMenuProps & React.HTMLAttributes<HTMLDivElement>, ref: React.Ref<HTMLDivElement>) => {
    const { menus, show, style = {}, onClickMenu, ...others } = props
    return (
      <Wrapper style={{ ...{ display: show ? 'flex' : 'none' }, ...style }} ref={ref} {...others}>
        {menus.map(menu => {
          return (
            <ItemWrapper style={{ color: menu.color }} key={menu.key} onClick={() => onClickMenu(menu.key)}>
              <ODIcon icon={menu.icon} style={{ transform: 'scale(0.7)' }} />
              <TextWrapper>{menu.text}</TextWrapper>
            </ItemWrapper>
          )
        })}
      </Wrapper>
    )
  }
)
