import { BlockingLoadBox, useWrappedAPI } from '@mdpp/od-react-belt'
import React, { useEffect } from 'react'
import {
  GQLPopupInfoCreationInput,
  GQLPopupInfoDeleteInput,
  GQLPopupInfoUpdateInput,
  GQLNotice,
} from '@mdpp/common/lib/@types/server.schema'
import { useAdminAPIs } from '../../../admin/context/useAdminAPIs'
import { PopupSettingModalPopup } from './PopupSettingModalPopup'
import { WebPopupSettingModalPopup } from './WebPopupSettingModalPopup'

interface IPopupSettingModalProps {
  show: boolean
  setShow: React.Dispatch<React.SetStateAction<boolean>>
  noticeId: number
  isApp: boolean
}

export const PopupSettingModal: React.FC<IPopupSettingModalProps> = props => {
  const { show, setShow, noticeId, isApp } = props

  const [loading, setLoading] = React.useState<boolean>(false)
  const [refresh, setRefresh] = React.useState<boolean>(false)

  const handleClose = () => {
    setShow(false)
  }

  const { createPopupInfo, updatePopupInfo, deletePopupInfo } = useAdminAPIs()

  const createPopupSetting = useWrappedAPI((input: GQLPopupInfoCreationInput) => createPopupInfo(input), loading, setLoading, {
    successMessage: '팝업을 설정하였습니다.',
    onSuccess: () => {
      setShow(false)
    },
  })

  const updatePopupSetting = useWrappedAPI((input: GQLPopupInfoUpdateInput) => updatePopupInfo(input), loading, setLoading, {
    successMessage: '팝업 설정을 수정하였습니다.',
    onSuccess: () => {
      setShow(false)
    },
  })

  const deletePopupSetting = useWrappedAPI((input: GQLPopupInfoDeleteInput) => deletePopupInfo(input), loading, setLoading, {
    successMessage: '팝업 설정을 삭제하였습니다.',
    onSuccess: () => {
      setShow(false)
    }
  })

  
  return (
    <div>
      <BlockingLoadBox show={loading} />
        {!loading && show  && isApp && (
          <PopupSettingModalPopup
            isOpen={show && isApp}
            onClose={() => handleClose()}
            onCreate={createPopupSetting}
            onUpdate={updatePopupSetting}
            onDelete={deletePopupSetting}
            noticeId={noticeId}
            refresh={setRefresh}
          />
        )}
        {!loading && show  && !isApp && (
          <WebPopupSettingModalPopup
            isOpen={show && !isApp}
            onClose={() => handleClose()}
            onCreate={createPopupSetting}
            onUpdate={updatePopupSetting}
            onDelete={deletePopupSetting}
            noticeId={noticeId}
            refresh={setRefresh}
          />
        )}
    </div>
  )
}
