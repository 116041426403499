import { ODIcon, ODIcons, ODModal, ODModalSize, ODVSpace } from '@mdpp/od-react-belt'
import React from 'react'
import styled from 'styled-components/macro'
import { GQLInquiryCreationInput } from '@mdpp/common/lib/@types/server.schema'

interface IInquiryPopoverProps {
  isOpen: boolean
  onClose: () => void
  initialInquirer: string
  initialPhoneNumber: string
  initialEmail: string
  onSubmit: (input: GQLInquiryCreationInput) => void
}


export const LandingModal: React.FC<IInquiryPopoverProps> = props => {
  const { isOpen, initialEmail, initialInquirer, initialPhoneNumber, onClose, onSubmit } = props

  const [inquirer, setInquirer] = React.useState(initialInquirer)
  const [phoneNumber, setPhoneNumber] = React.useState(initialPhoneNumber)
  const [email, setEmail] = React.useState(initialEmail)
  const [contents, setContents] = React.useState('')

  return (
    <ODModal isOpen={isOpen} toggle={onClose}>
      <Wrapper
        onSubmit={e => {
          e.preventDefault()
          onSubmit({ name: inquirer, phone: phoneNumber, email, contents })
        }}
      >
        <ODVSpace h={20} />
        <Title>원광대학교병원 비대면 진료 안내</Title>
        <CloseButtonBox onClick={onClose}>
          <ODIcon icon={ODIcons.CoreX} style={CloseIconStyle} />
        </CloseButtonBox>
        <Inner>
          <ODVSpace h={8} />
          안녕하세요.
          <ODVSpace h={24} />
          원광대학교병원이 메디팡팡 비대면 진료 서비스 시작합니다.<br/>
          원광대학교병원은 코로나 상황에서 환자분들에게 보다 편안하고 안전한 진료 서비스를 제공해 드리고자 비대면 진료를 시작하게 되었습니다.
          <ODVSpace h={24} />
          원광대학교병원 비대면 진료는 초기 도입에 따른 원활한 운영을 위해 재진 환자를 대상으로 하며 일부 진료과에 한하여 제공됨을 양해 부탁드립니다.
          <ODVSpace h={24} />
          <TagContainer>
            <TagWrapper>
              <TagTitle>비대면 진료 대상 환자</TagTitle>
              <Tags>
                <div style={TagGray}>원광대학교병원 재진환자</div>
              </Tags>
            </TagWrapper>
            <TagWrapper>
              <TagTitle>비대면 진료과</TagTitle>
              <Tags>
                <div style={TagPurple}>관절류마티스내과</div>
                <div style={TagRed}>내분비대사내과</div>
                <div style={TagTeal}>가정의학과</div>
                <div style={TagBlue}>재활의학과</div>
              </Tags>
            </TagWrapper>
          </TagContainer>
          <ODVSpace h={24} />
          세심함을 더한 비대면진료, 메디팡팡이 함께하겠습니다.
          <ODVSpace h={40} />
        </Inner>
        <SubmitButton type={'submit'} value={'오늘 하루 보지않기'} />
      </Wrapper>
    </ODModal>
  )
}

const CloseIconStyle = {
  fontSize: 18,
  color: '#818181',
}
const Wrapper = styled.form`
  background-color: var(--white);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  position: relative;
`
const Inner = styled.div`
  padding: 0 24px;
  font-size: 16px;
  word-break: keep-all;
  @media only screen and (max-width: 570px) {
      padding: 0 24px;     
    }
`
const Title = styled.h1`
  line-height: 29px;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0.29px;
  text-align: left;
  color: black;
  border-bottom: 1px solid #f5f5f5;
  padding-bottom: 24px;
  padding-left: 24px;
  @media only screen and (max-width: 570px) {
      padding-top: 15%;     
    }
`
const CloseButtonBox = styled.div`
  position: absolute;
  top: 25px;
  right: 25px;
  cursor: pointer;
`

const SubmitButton = styled.input`
  width: 100%;
  height: 47px;
  padding: 15px 0 13px 3px;
  background-color: var(--mpPrimary500);
  font-size: 15px;
  line-height: 19px;
  color: var(--white);
  letter-spacing: 0.21px;
  text-align: center;
`

const TagContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  // border-top: 1px solid #f3f3f3 !important;
  // border-bottom: 1px solid #f3f3f3 !important;
  padding: 24px 0;
  @media only screen and (max-width: 570px) {
      flex-direction: column;
   
  }
`
const TagWrapper = styled.div `
  display: flex;
  flex-direction: column;
  gap: 8px;
`

const TagTitle = styled.div`
  font-family: Noto Sans KR;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: -1px;
  text-align: left;
  // border-bottom: 1px solid #f3f3f3 !important;
  // padding-bottom: 8px;
`
const Tags = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 14px;
  gap: 12px;
  @media only screen and (max-width: 570px) {
      flex-direction: column; 
  }
`
const TagGray = {
  display: 'flex',
  padding: 6,
  color: '#1F2121',
  background: '#EBF1F1',
  borderRadius: '8px',
}
const TagPurple = {
  display: 'flex',
  padding: 6,
  color: '#0085FF',
  background: '#EBE8FF',
  borderRadius: '8px',
}
const TagRed = {
  display: 'flex',
  padding: 6,
  color: '#DD3F3F',
  background: '#FFF3F3',
  borderRadius: '8px',
}
const TagTeal = {
  display: 'flex',
  padding: 6,
  color: '#226665',
  background: '#E9FFFE',
  borderRadius: '8px',
}
const TagBlue = {
  display: 'flex',
  padding: 6,
  color: '#0028cb',
  background: '#e9eeff',
  borderRadius: '8px',
}