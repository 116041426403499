import styled from 'styled-components/macro'
import { BlockingLoadBox, ODVSpace, useWrappedAPI } from '@mdpp/od-react-belt'
import React from 'react'
import { EvixButton, EvixButtonTheme } from '../common/EvixButton'
import { EvixInputText } from '../common/EvixInputText'
import { CenterWithFooterContainer } from '../common/layout/CenterWithFooterContainer'

//asset
import {
  LoginWrap,
  LogoStyle,
  Logo,
  Logo2,
  LoginInner,
  MarketingLine,
  LoginTitle,
  MessageTextWrap,
  MessageText3,
  EvixInputTextWrap,
} from '../common/style/CommonStyle'
import LogoDoctor from '../assets/login/bi-mdpp-doctor.png'
import LogoHospital from '../assets/login/bi-mdpp-hospital.png'
import LogoAdmin from '../assets/login/bi-mdpp-admin.png'
import LogoPharmacy from '../assets/login/bi-mdpp-pharma.png'
import WarningRed from '../assets/common/warning-red.svg'
import IconResult from '../assets/common/icon-result.svg'
import { WebUtils } from '../utils/webUtils'
import { useCommonAPIs } from '../common/hooks/useCommonAPIs'
import { Redirect } from 'react-router-dom'
import { SiteUrls } from '../SiteUrls'

export enum ForgotIDRoute {
  Doctor,
  Manager,
  Admin,
  Pharmacy,
}

interface IForgotIDPageProps {
  designStyle: ForgotIDRoute
  fromRoute: ForgotIDRoute
}

export const ForgotIDPage: React.FC<IForgotIDPageProps> = props => {
  const { fromRoute, designStyle } = props
  const { doctorFindId, pharmacistFindId } = useCommonAPIs()
  const [redirect, setRedirect] = React.useState('')
  const [loading, setLoading] = React.useState(false)
  const [userName, setUserName] = React.useState('')
  const [licenseNo, setLicenseNo] = React.useState('')
  const [errShow, setErrShow] = React.useState(false) // 빈값일때 에러 메시지
  const [joinShow, setJoinShow] = React.useState(false) // 의사 회원가입 버튼 유무
  const [findEmailShow, setFindEmailShow] = React.useState(true) // 이메일 아이디 찾기 버튼 유무
  const [email, setEmail] = React.useState('')

  const [pharmacyNo, setPharmacyNo] = React.useState('')

  const handleRequestFindId = async () => {
    setLoading(true)
    try {
      switch (fromRoute) {
        case ForgotIDRoute.Doctor:
          // @ts-ignore
          const r = await doctorFindId({ name: userName, licenseName: licenseNo })
          setEmail(r.email)
          break
        case ForgotIDRoute.Pharmacy:
          // @ts-ignore
          const re = await pharmacistFindId({ name: userName, licenseName: licenseNo })
          setEmail(re.email)
          break
      }
      WebUtils.showSuccess('아이디를 찾았습니다.')
      setTimeout(() => {
        setFindEmailShow(false)
        setJoinShow(false)
      }, 100)
    } catch (ex) {
      if (userName === '' || licenseNo === '') {
        return setErrShow(true)
      }
      if (userName !== '' && licenseNo !== '') {
        setErrShow(false)
        setFindEmailShow(true)
        setJoinShow(true)
      }
    } finally {
      setLoading(false)
    }
  }

  const doctorJoinPage = () => {
    setRedirect(SiteUrls.Doctor.SignUp)
  }

  const doctorLoginPage = () => {
    setRedirect(SiteUrls.Doctor.Login)
  }

  const pharmacyJoinPage = () => {
    setRedirect(SiteUrls.Pharmacy.SignUp)
  }

  const pharmacyLoginPage = () => {
    setRedirect(SiteUrls.Pharmacy.Login)
  }

  if (redirect) {
    return <Redirect to={redirect} />
  }

  return (
    <CenterWithFooterContainer style={{ display: 'flex', flexDirection: 'column' }}>
      <BlockingLoadBox show={loading} />

      <LoginWrap>
        <LogoStyle>
          {/* 의사일 경우 */}
          {designStyle === 0 && <img src={LogoDoctor} style={Logo} alt="MediPangPang Logo" />}

          {/* 병원 관리자일 경우 */}
          {designStyle === 1 && <img src={LogoHospital} style={Logo} alt="MediPangPang Logo" />}

          {/* 관리자일 경우 */}
          {designStyle === 2 && <img src={LogoAdmin} style={Logo2} alt="MediPangPang Logo" />}

          {/* 약사일 경우 */}
          {designStyle === 3 && <img src={LogoPharmacy} style={Logo} alt="MediPangPang Logo" />}
        </LogoStyle>

        {/* 박스 영역 */}
        <LoginInner>
          <LoginTitle>이메일 아이디 찾기</LoginTitle>

          {findEmailShow === true ? (
            <>
              <>
                <MarketingLine>
                  메디팡팡 {designStyle === 0 ? `의사` : `약사`} web 회원가입시 입력했던 <br />
                  <b>[이름], [{designStyle === 0 ? `의사` : `약사`} 면허번호]</b>를 입력해주세요.
                </MarketingLine>
              </>
              <>
                <ODVSpace h={27} />
                <EvixInputText
                  placeholder="이름"
                  value={userName}
                  onChange={value => setUserName(value)}
                  disabled={false}
                />
                <ODVSpace h={16} />

                <EvixInputText
                  placeholder={designStyle === 0 ? '의사 면허번호' : '약사 면허번호'}
                  value={licenseNo}
                  onChange={value => setLicenseNo(value)}
                  disabled={false}
                  maxLength={designStyle === 0 ? 6 : 5}
                />
              </>
              {/* 입력값이 없을 경우 */}
              {errShow && (
                <>
                  <ODVSpace h={8} />
                  <InfoMessageWrap>
                    <img
                      src={WarningRed}
                      style={{ width: 19, height: 19, marginRight: 4, objectFit: 'contain' }}
                      alt="warning icon"
                    />
                    <MessageTextWrap>
                      <MessageText3>
                        이름 또는 {designStyle === 0 ? `의사` : `약사`} 면허번호를 입력해주세요
                      </MessageText3>
                    </MessageTextWrap>
                  </InfoMessageWrap>
                </>
              )}
              {/* 이름/면허번호를 입력 후, 일치하는 정보가 없는 경우 */}
              {!errShow && joinShow && (
                <>
                  <ODVSpace h={8} />
                  <InfoMessageWrap>
                    <img
                      src={WarningRed}
                      style={{ width: 19, height: 19, marginRight: 4, objectFit: 'contain' }}
                      alt="warning icon"
                    />
                    <MessageTextWrap>
                      <MessageText3>조회하신 정보와 일치하는 아이디가 없습니다.</MessageText3>
                    </MessageTextWrap>
                  </InfoMessageWrap>
                </>
              )}

              <>
                <ODVSpace h={60} />
                <EvixButton theme={EvixButtonTheme.H48Black} onClick={handleRequestFindId}>
                  이메일 아이디 찾기
                </EvixButton>
              </>
              {/* 이름/면허번호를 입력 후, 일치하는 정보가 없는 경우 */}
              {joinShow && (
                <>
                  <ODVSpace h={60} />
                  <EvixButton
                    theme={EvixButtonTheme.H48White}
                    onClick={designStyle === 0 ? doctorJoinPage : pharmacyJoinPage}
                  >
                    {designStyle === 0 ? `의사` : `약사`} 회원 가입
                  </EvixButton>
                </>
              )}
            </>
          ) : (
            <>
              <MarketingLine>
                입력하신 정보와 일치하는 <b>[이메일 아이디]</b> 결과입니다.
              </MarketingLine>
              <ODVSpace h={51} />
              <EvixInputTextWrap className="result-input">
                <img
                  src={IconResult}
                  style={{
                    position: 'absolute',
                    left: 12,
                    top: 17,
                    width: 20,
                    height: 14,
                    marginRight: 6,
                    objectFit: 'contain',
                  }}
                  alt="reault icon"
                />
                <EvixInputText children value={email} />
              </EvixInputTextWrap>
              <ODVSpace h={124} />
              <EvixButton
                theme={EvixButtonTheme.H48White}
                onClick={designStyle === 0 ? doctorLoginPage : pharmacyLoginPage}
              >
                로그인 바로가기
              </EvixButton>
            </>
          )}
        </LoginInner>
      </LoginWrap>
    </CenterWithFooterContainer>
  )
}

export const InfoMessageWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`
