import { ODListableResponseType } from '@mdpp/od-react-belt'
import React from 'react'
import { GQLAppVersion } from '@mdpp/common/lib/@types/server.schema'
import { useAdminAPIs } from '../../context/useAdminAPIs'
import { IAppVersionListableOption } from '../AppVersionCommon'

export function useAppVersionListDataLoader() {
  const { listAppVersion } = useAdminAPIs()
  return React.useCallback(
    async function dataLoader(
      page: number,
      pageSize: number,
      afterKey: string | null,
      options: IAppVersionListableOption
    ): Promise<ODListableResponseType<GQLAppVersion>> {
      const r = await listAppVersion({ page, pageSize, ...options })
      return r as ODListableResponseType<GQLAppVersion>
    },
    [listAppVersion]
  )
}
