import { RESET_ACCOUNT_TYPE } from '@mdpp/common'
import { BlockingLoadBox, ODVSpace } from '@mdpp/od-react-belt'
import React from 'react'
import { Redirect } from 'react-router-dom'
import { EvixButton, EvixButtonTheme } from '../common/EvixButton'
import { EvixInputText } from '../common/EvixInputText'
import { useCommonAPIs } from '../common/hooks/useCommonAPIs'
import { CenterWithFooterContainer } from '../common/layout/CenterWithFooterContainer'
import { SiteUrls } from '../SiteUrls'
import { WebUtils } from '../utils/webUtils'
//asset
import {
  LoginWrap,
  LogoStyle,
  Logo,
  Logo2,
  LoginInner,
  MarketingLine,
  LoginTitle,
  InfoMessageWrap,
  EvixInputTextWrap,
} from '../common/style/CommonStyle'
import LogoDoctor from '../assets/login/bi-mdpp-doctor.png'
import LogoHospital from '../assets/login/bi-mdpp-hospital.png'
import LogoAdmin from '../assets/login/bi-mdpp-admin.png'
import LogoPharmacy from '../assets/login/bi-mdpp-pharma.png'
import IconResult from '../assets/common/icon-result.svg'

export enum ForgotPasswordRoute {
  Doctor,
  Manager,
  Admin,
  Pharmacy,
}

interface IForgotPasswordPageProps {
  designStyle: ForgotPasswordRoute
  fromRoute: ForgotPasswordRoute
}

export const ForgotPasswordPage: React.FC<IForgotPasswordPageProps> = props => {
  const { fromRoute, designStyle } = props
  const { resetAccountPassword } = useCommonAPIs()
  const [loading, setLoading] = React.useState(false)
  const [redirect, setRedirect] = React.useState('')
  const [email, setEmail] = React.useState('')
  const [initPassword, setInitPassword] = React.useState(false)

  const handleRequestResetPassword = async () => {
    setLoading(true)
    try {
      switch (fromRoute) {
        case ForgotPasswordRoute.Doctor:
          // @ts-ignore
          await resetAccountPassword({ accountType: RESET_ACCOUNT_TYPE.DOCTOR, email })
          break
        case ForgotPasswordRoute.Manager:
          // @ts-ignore
          await resetAccountPassword({ accountType: RESET_ACCOUNT_TYPE.MANAGER, email })
          break
        case ForgotPasswordRoute.Admin:
          // @ts-ignore
          await resetAccountPassword({ accountType: RESET_ACCOUNT_TYPE.ADMIN, email })
          break
        case ForgotPasswordRoute.Pharmacy:
          // @ts-ignore
          await resetAccountPassword({ accountType: RESET_ACCOUNT_TYPE.PHARMACIST, email })
          break
      }
      WebUtils.showSuccess('입력하신 이메일 주소로 비밀번호 재설정 안내 이메일을 발송하였습니다.')
      setTimeout(() => {
        setInitPassword(true)
      }, 100)
    } catch (ex) {
      WebUtils.showError(ex)
    } finally {
      setLoading(false)
    }
  }

  const LoginPage = () => {
    setLoading(true)
    try {
      switch (fromRoute) {
        case ForgotPasswordRoute.Doctor:
          // @ts-ignore
          setRedirect(SiteUrls.Doctor.Login)
          break
        case ForgotPasswordRoute.Manager:
          // @ts-ignore
          setRedirect(SiteUrls.Manager.Login)
          break
        case ForgotPasswordRoute.Admin:
          // @ts-ignore
          setRedirect(SiteUrls.Admin.Login)
          break
        case ForgotPasswordRoute.Pharmacy:
          // @ts-ignore
          setRedirect(SiteUrls.Pharmacy.Login)
          break
      }
    } catch (ex) {
      console.log(ex)
    } finally {
      setLoading(false)
    }
  }

  if (redirect) {
    return <Redirect to={redirect} />
  }

  return (
    <CenterWithFooterContainer style={{ display: 'flex', flexDirection: 'column' }}>
      <BlockingLoadBox show={loading} />

      <LoginWrap>
        <LogoStyle>
          {/* 의사일 경우 */}
          {designStyle === 0 && <img src={LogoDoctor} style={Logo} alt="MediPangPang Logo" />}

          {/* 병원 관리자일 경우 */}
          {designStyle === 1 && <img src={LogoHospital} style={Logo} alt="MediPangPang Logo" />}

          {/* 관리자일 경우 */}
          {designStyle === 2 && <img src={LogoAdmin} style={Logo2} alt="MediPangPang Logo" />}

          {/* 약사일 경우 */}
          {designStyle === 3 && <img src={LogoPharmacy} style={Logo} alt="MediPangPang Logo" />}
        </LogoStyle>

        {/* 박스 영역 */}
        <LoginInner>
          <LoginTitle>비밀번호 찾기</LoginTitle>
          <MarketingLine>
            이메일 아이디의 주소로,
            <br />
            비밀번호 재설정 안내 메일이 발송됩니다.
          </MarketingLine>
          <ODVSpace h={27} />

          {/* 이메일을 입력하기 전 */}
          {!initPassword && (
            <div>
              <EvixInputText
                placeholder="이메일"
                value={email}
                onChange={setEmail}
                onEnter={handleRequestResetPassword}
              />
              <ODVSpace h={90} />
              <EvixButton theme={EvixButtonTheme.H48Black} onClick={handleRequestResetPassword}>
                보내기
              </EvixButton>
            </div>
          )}

          {/* 이메일을 입력한 후 */}
          {initPassword && (
            <div>
              <EvixInputTextWrap className="result-input">
                <img
                  src={IconResult}
                  style={{
                    position: 'absolute',
                    left: 12,
                    top: 17,
                    width: 20,
                    height: 14,
                    marginRight: 6,
                    objectFit: 'contain',
                  }}
                  alt="reault icon"
                />
                <EvixInputText children value={email} />
              </EvixInputTextWrap>
              <ODVSpace h={14} />
              <InfoMessageWrap></InfoMessageWrap>
              <EvixButton theme={EvixButtonTheme.H48White} onClick={LoginPage}>
                로그인 바로가기
              </EvixButton>
            </div>
          )}
        </LoginInner>
      </LoginWrap>
    </CenterWithFooterContainer>
  )
}
