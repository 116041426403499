import React from 'react'
import { ScheduleSetupPage } from '../../common/schedule/setting/ScheduleSetupPage'

interface IManagerDoctorScheduleSetupPageProps {
  doctorId: number
}

export const ManagerDoctorScheduleSetupPage: React.FC<IManagerDoctorScheduleSetupPageProps> = props => {
  const { doctorId } = props

  return (
    <div>
      <ScheduleSetupPage idEditing={doctorId} />
    </div>
  )
}
