import { ODHSpace, ODIcon, ODIcons, ODVSpace } from '@mdpp/od-react-belt'
import React from 'react'
import Select from 'react-select/async'
import { Popover, PopoverBody, PopoverHeader } from 'reactstrap'
import styled from 'styled-components'
import moment from 'moment/moment'
import {
  GQLDiagName,
  GQLReservation,
  GQLRESERVATION_STATUS,
  GQLUpdateReservationByDoctorInput,
} from '@mdpp/common/lib/@types/server.schema'
import { EvixCardTwoThirds } from '../../../../common/card/EvixCardTwoThirds'
import { EvixCardHeader } from '../../../../common/card/EvixCardHeader'
import { EvixCardLabel } from '../../../../common/card/EvixCardLabel'
import { EvixCardRow } from '../../../../common/card/EvixCardRow'
import { useCommonAPIs } from '../../../../common/hooks/useCommonAPIs'
import { ReservationBasicInfoBoxStyle } from '../../../../common/reservation/ReservationCommon'
import { WebUtils } from '../../../../utils/webUtils'
import { DiagNameWithOption, DiagnosisList } from './DiagnosisList'
import { useReservationHasDiagnosisModal } from './useReservationHasDiagnosisModal'
import { PatientDiagnosisPrescriptionInfoRow } from './PatientDiagnosisPrescriptionInfoRow'
import { EvixButton, EvixButtonTheme } from '../../../../common/EvixButton'
import { EvixButtonTextWithIcon } from '../../../../common/EvixButtonTextWithIcon'
import { IEvixPrescriptionFile } from '../../../../common/EvixPrescriptionFileUpload'
import { EvixCardRowContent } from '../../../../common/card/EvixCardRowContent'
import { Button, BP } from '../../../../common/RebeccaButton'

interface IPatientDiagnosisInfoCardProps {
  reservation: GQLReservation
  infoStyle: ReservationBasicInfoBoxStyle
  onUpdateField: (fieldName: keyof GQLUpdateReservationByDoctorInput, value: string | number) => Promise<void>
  isFinishedMedical?: boolean
  handleFinish: ((selectedDiagNames?: DiagNameWithOption[], treatment?: string, prescriptionFiles?: IEvixPrescriptionFile[], saveOnly?: boolean) => void) | null | undefined
  isRoom: boolean
  reload?: boolean
  style?: React.CSSProperties
}

export const PatientDiagnosisInfoCard: React.FC<IPatientDiagnosisInfoCardProps> = props => {
  const { reservation, infoStyle, handleFinish, isRoom = false, reload = false } = props
  const [popoverOpen, setPopoverOpen] = React.useState(false)
  const { listDiagNames, updateReservationDiagnosis } = useCommonAPIs()
  const [selectedDiagNames, setSelectedDiagNames] = React.useState<DiagNameWithOption[]>(() => {
    return reservation.hasDiagnosis.map(hd => ({
      diagName: hd.diagName,
      isFinal: hd.isFinal,
    }))
  })

  const [refresh, setRefresh] = React.useState(reload)

  const [files, setFiles] = React.useState<IEvixPrescriptionFile[]>(() => {
    return reservation.prescriptionFiles.map(pf => {
      return {
        id: pf.prescriptionFileId.toString(),
        fileName: pf.fileName,
        link: pf.link,
        isDeleting: false,
        isUploading: false,
      }
    })
  })

  React.useEffect(() => {
    const newFiles = reservation.prescriptionFiles.map(pf => {
      return {
        id: pf.prescriptionFileId.toString(),
        fileName: pf.fileName,
        link: pf.link,
        isDeleting: false,
        isUploading: false,
      }
    })

    if (reload !== refresh) {
      setFiles(newFiles)
      setRefresh(reload)
    }
  }, [reservation])

  const [guide, setGuide] = React.useState(reservation.treatment ?? '')
  const { Component: PickerComponent, pick, props: pickerProps } = useReservationHasDiagnosisModal()

  const saveDiagnosis = React.useCallback(
    async (id: number, diagnosis: DiagNameWithOption[]): Promise<boolean> => {
      try {
        await updateReservationDiagnosis({
          id,
          diagnosis: diagnosis.map(dn => ({
            diagNameCode: dn.diagName.code,
            isFinal: dn.isFinal,
          })),
        })
        return true
      } catch (ex) {
        WebUtils.showError(ex)
        return false
      }
    },
    [updateReservationDiagnosis]
  )

  const togglePopover = () => {
    setPopoverOpen(prev => !prev)
  }

  const isInsideRoom = infoStyle === ReservationBasicInfoBoxStyle.InsideRoom
  const cardStyle = isInsideRoom ? { 
    border: 0, 
    width: '100%',
    // boxShadow: '0px 0px 5px rgba(23, 24, 24, 0.05), 0px 1px 2px rgba(0, 0, 0, 0.15)',
    borderRadius: 8,
  } : {
    width: '66%',
    display: 'flex',
    background: '#FFFFFF',
    boxShadow: '0px 0px 5px rgba(23, 24, 24, 0.05), 0px 1px 2px rgba(0, 0, 0, 0.15)',
    borderRadius: 8,
    border: 0,
  }
  const isEditable = () => {
    const lastValidDate = moment(reservation.reservationTime).add(1, 'week')
    const now = moment()
    const isValid = lastValidDate.diff(now) > 0

  return reservation.status === GQLRESERVATION_STATUS.IN_PROGRESS ||
    reservation.status === GQLRESERVATION_STATUS.PRESCRIPTION ||
    (reservation.status === GQLRESERVATION_STATUS.FINISHED && isValid)
  }

  const loadOptions = React.useCallback(
    async (input: string) => {
      const inputValue = input.trim()
      const res = await listDiagNames({ page: 1, pageSize: 10, filter: inputValue })
      return res.list.map(diagName => ({
        value: diagName.code,
        label: `${diagName.code} ${diagName.koDesc}`,
        data: diagName,
      }))
    },
    [listDiagNames]
  )

  const handleDiagNameSelected = async (diagName: GQLDiagName) => {
    if (selectedDiagNames.find(v => v.diagName.code === diagName.code)) {
      WebUtils.showInfo('해당 코드명은 이미 추가되어 있습니다.')
      return
    }

    const finalList = [...selectedDiagNames, { diagName, isFinal: true }]
    setSelectedDiagNames(finalList)
	
	  // 추가후 바로 저장할 경우
	  // const result = await saveDiagnosis(reservation.reservationId, finalList)
	  // if (result) {
	  //   setSelectedDiagNames(finalList)
	  // }
	
	  console.log(135, finalList)
  }

  const handleChangeOrder = async (code: string, isUp: boolean) => {
    const list = [...selectedDiagNames]
    const index = list.findIndex(v => v.diagName.code === code)
    if (index < 0 || (isUp && index === 0) || (!isUp && index === list.length - 1)) {
      return list
    }

    const indexToChange = isUp ? index - 1 : index + 1
    const tempValue = list[indexToChange]
    list[indexToChange] = list[index]
    list[index] = tempValue
    setSelectedDiagNames([...list])
  }

  const toggleDiagnosisFinal = async (code: string) => {
    const finalList = selectedDiagNames.map(item => {
      if (item.diagName.code === code) {
        return { diagName: item.diagName, isFinal: !item.isFinal }
      }
      return item
    })
    setSelectedDiagNames([...finalList])
  }

  const handleRemove = async (code: string) => {
    const newList = selectedDiagNames.filter(v => v.diagName.code !== code)
    setSelectedDiagNames([...newList])
  }

  const handleOpenAddModal = async () => {
    const r = (await pick({
      selectedItems: selectedDiagNames.map(v => v.diagName),
      showSearch: true,
      searchPlaceholder: '진단명 검색',
      returnFullValue: true,
    })) as GQLDiagName[]

    if (!r) {
      return
    }

    const list = [...selectedDiagNames]

    const getIsFinal = (code: string) => list.find(v => v.diagName.code === code)?.isFinal ?? true

    const newList = r.map(diag => {
      return {
        diagName: diag,
        isFinal: getIsFinal(diag.code),
      }
    })
	
	  setSelectedDiagNames(newList)
	  
	  // 추가후 바로 저장할 경우
	  // const result = await saveDiagnosis(reservation.reservationId, newList)
	  // if (result) {
	  //   setSelectedDiagNames(newList)
	  // }
	  
	  console.log(134, r)
  }

  const isFinished = reservation.status === GQLRESERVATION_STATUS.FINISHED

  return (
    <EvixCardTwoThirds fit style={{ ...cardStyle, overflowY: 'auto' }}>
      <PickerComponent {...pickerProps} />
      <EvixCardHeader icon={ODIcons.Patient} title="진료결과" isRoom={isInsideRoom}/>
      {!isInsideRoom ?
          <ODVSpace h={24} /> : <ODVSpace h={0} />
      }
      <TableListWrapper>
        <EvixCardRow style={!isRoom ? {alignItems: 'flex-start'} : {flexDirection: 'column'}}>
          <LabelWrapper style={{marginTop: 8}}>
            <EvixCardLabel style={{minWidth: 0}}>진단명</EvixCardLabel>
            <IconWrapper>
              <div style={{ transform: 'scale(0.7)', marginTop: -2 }}>
                <ODIcon id="popover" icon={ODIcons.MaterialHelpOutline} onClick={() => {}} style={{fill: 'var(--gray700)'}}/>
              </div>
              <Popover trigger="hover" isOpen={popoverOpen} target="popover" toggle={togglePopover} placement="bottom">
                <PopoverHeader>진단명</PopoverHeader>
                <PopoverBody>
                  환자에게는 주진단만 제공됩니다. <br /> 한 개 이상의 진단명을 입력하세요.
                </PopoverBody>
              </Popover>
            </IconWrapper>
          </LabelWrapper>
          <EvixCardRowContent>
            <CardRow>
              <CardRowInner>
                <CustomSelect
                    isDisabled={!isEditable()}
                    cacheOptions
                    placeholder="진단명 검색"
                    loadOptions={loadOptions}
                    defaultOptions
                    onChange={(e: any) => {
                      if (isEditable()) {
                        handleDiagNameSelected(e.data)
                      }
                    }}
                />
                <Button type={BP.TYPE.InvisibleInteractive}  onClick={handleOpenAddModal}
                        disabled={!isEditable()} style={{ border: 'none'}}
                >KCD8 전체보기</Button>
              </CardRowInner>
            </CardRow>
            <ODVSpace h={12}/>
            <DiagnosisList
                diagNames={selectedDiagNames}
                toggleDiagnosisFinal={toggleDiagnosisFinal}
                onChangeOrder={handleChangeOrder}
                onRemove={handleRemove}
                editable={isEditable()}
            />
          </EvixCardRowContent>
        </EvixCardRow>
        <EvixCardRow style={!isRoom ? {alignItems: 'flex-start'} : {flexDirection: 'column'}}>
          <EvixCardLabel>코멘트</EvixCardLabel>
          <GuideBox
              placeholder={isEditable() ? '입력하세요' : ''}
              onChange={(e:any) => (setGuide(e.target.value))}
              disabled={!isEditable()}
              value={guide}
          />
        </EvixCardRow>
        {!isRoom && (<PatientDiagnosisPrescriptionInfoRow
            reservation={reservation}
            files={files}
            setFiles={setFiles}
            infoStyle={ReservationBasicInfoBoxStyle.OutsideRoom}
            disabled={!isEditable()}
        />)}
        {isEditable() && !isRoom && (
            <>
              <ODVSpace h={16} />
              <div style={{ display: 'flex', justifyContent: 'flex-end'}}>
                <Button size={BP.SIZE.M} type={BP.TYPE.Primary}
                        onClick={() => {
                          if (handleFinish) {
                            handleFinish(selectedDiagNames, guide, files, isFinished)
                          }
                        }}
                >{isFinished ? "진료 결과 수정" : "진료 결과 등록"}</Button>
              </div>
            </>
        )}
        {isEditable() && isRoom && (
            <>
              <div style={!isRoom ? { display: 'flex', justifyContent: 'flex-end'} : {display: 'flex', justifyContent: 'center'}}>
                <div style={{ width: '100%', display: 'flex', justifyContent: 'end' }}>
                  <Button size={BP.SIZE.M} type={BP.TYPE.Primary}
                    onClick={() => {
                    console.log('on click handle finish: ', handleFinish)
                    if (handleFinish) {
                      handleFinish(selectedDiagNames, guide, files, true)
                    }}
                  }>
                      진료결과 저장
                  </Button>
                </div>
              </div>
            </>
        )}
      </TableListWrapper>
    </EvixCardTwoThirds>
  )
}

const CardRowInner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 100%;
`

const CardRow = styled.div`
  display: flex;
  flex-direction: 'row';
  flex-grow: 1;
`
const CustomSelect = styled(Select)`
  flex: 1;
  width: 100%;
`

const IconWrapper = styled.span`
  color: var(--mpPrimary500);
  position: relative;

  &:hover {
    & > div {
      opacity: 1;
    }
  }
`

const GuideBox = styled.textarea`
  width: 100%;
  height: 100px;
  border: 1px dotted rgb(171, 171, 171);
  padding: 10px;
  border-radius: 5px;
  margin-top: 0px;
  margin-bottom: 0px;
`
const LabelWrapper = styled.div`
  min-width: 80px;
  display: flex;
  flex-direction: row;
`
const TableListWrapper = styled.div`
    gap: 24px;
    display:flex;
    flex-direction: column;
    
`