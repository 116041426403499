import { ODIcon, ODIcons } from '@mdpp/od-react-belt'
import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { COLORS } from '../../styles/colors'

interface IStepButtonProps {
  numberText: string
  icon: ODIcons
  text: string
  link: string
}

export const StepButton: React.FC<IStepButtonProps> = props => {
  const { numberText, icon, text, link } = props
  return (
    <GrowWrapper>
      <Link to={link} style={{ textDecoration: 'none' }}>
        <Wrapper>
          <Number>{numberText}</Number>
          <Contents>
            <IconWrapper>
              <ODIcon icon={icon} />
              <TextWrapper>{text}</TextWrapper>
            </IconWrapper>
          </Contents>
        </Wrapper>
      </Link>
    </GrowWrapper>
  )
}

const GrowWrapper = styled.div`
  flex-grow: 1;
`
const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  /* border-bottom: 1px solid #E7E8E9; */
  border: 1px solid #E7E8E9;
  border-radius: 5px;
  align-items: flex-start;
  background-color: #fff;
  
  :hover {
    border-color: color:${COLORS.blue200};
    /* box-shadow: 0px 9px 8px 5px rgb(23 24 24 / 2%), 0px 1px 8px rgba(0, 0, 0, 0.05); */
  }
`
const Number = styled.div`
  width: 83px;
  min-width: 83px;
  font-size: 16px;
  font-weight: bold;
  line-height: 2;
  color: var(--gray400);
  display: flex;
  align-items: center;
  padding: 18px 20px 0 30px;
`

const Contents = styled.div`
  flex-grow: 1;
  margin-bottom: 15px;
  display: flex;
  flex-direction: row;
  padding-top: 11px;
`

const IconWrapper = styled.div`
  font-size: 30px;
  color:${COLORS.blue200};
`

const TextWrapper = styled.div`
  font-size: 16px;
  font-weight: 500;
  color:${COLORS.blue200};
`
