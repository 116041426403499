import {
  BlockingLoadBox,
  ODEntity,
  ODEntityInput,
  ODEntityLabeled,
  ODWebUtils,
  useODModalConfirm,
  useWrappedAPI,
} from '@mdpp/od-react-belt'
import gql from 'graphql-tag'
import React from 'react'
import styled from 'styled-components'
import { GQLDoctor, GQLDoctorCreationInput, GQLDoctorUpdateInput } from '@mdpp/common/lib/@types/server.schema'
import { useAdminAPIs } from '../../../admin/context/useAdminAPIs'
import { EvixButton, EvixButtonTheme } from '../../../common/EvixButton'
import { EvixFormToggleButton } from '../../../common/form/EvixFormToggleButton'
import { GQL_READ_DOCTOR, GQL_UPDATE_DOCTOR } from '../../../common/gqls_common'
import { DoctorPrimaryKey, ICTDoctor } from '../../../common/types/DoctorCommon'
import { useODEntityAPI } from '../../../utils/odGQLEntityLoader'
import { EvixCard } from '../../../common/card/EvixCard'
import { TableList, TableListToggleButtonWrapper, TableListWrapper } from '../../../common/list/TableList'

interface IManagerDoctorAccountPageProps {
  doctorId: number
}

export const ManagerDoctorAccountPage: React.FC<IManagerDoctorAccountPageProps> = props => {
  const { doctorId } = props
  const { resetDoctorPassword } = useAdminAPIs()
  const [loading, setLoading] = React.useState(false)

  const apis = useODEntityAPI<GQLDoctor, ICTDoctor, GQLDoctorCreationInput, GQLDoctorUpdateInput>({
    updateGQL: gql(GQL_UPDATE_DOCTOR),
    readGQL: gql(GQL_READ_DOCTOR),
    primaryKeyName: DoctorPrimaryKey,
  })

  const { Component: Confirm, props: confirmProps, confirm } = useODModalConfirm({})
  const resetPassword = useWrappedAPI(
    async (doctorIdIn: number) => {
      return resetDoctorPassword({ id: doctorIdIn })
    },
    loading,
    setLoading,
    {
      onSuccess: () => ODWebUtils.showSuccess('비밀번호가 재설정되었습니다.'),
      deps: [],
    }
  )

  const handleResetPassword = async () => {
    if (
      !(await confirm({
        title: '비밀번호 재설정',
        message: '비밀번호를 재설정하고 메일로 전송하시겠습니까?',
        yes: '재설정',
        no: '취소',
      }))
    ) {
      return false
    }

    return resetPassword(doctorId)
  }

  return (
    <>
      {loading && <BlockingLoadBox show />}
      <Confirm {...confirmProps} />
      <EvixCard>
        <ODEntity
          resourceId={doctorId}
          api={apis}
          saveButtonName="저장"
          titleCreation="추가"
          createSuccessTitle="성공"
          createSuccessMessage="추가하였습니다."
          titleUpdate="수정"
          updateSuccessTitle="성공"
          updateSuccessMessage="수정하였습니다."
          urlAfterDelete={(item: ICTDoctor) => `/admin/doctor`}
          deleteSuccessTitle="성공"
          deleteSuccessMessage="삭제하였습니다."
          defaultCreateClientData={{}}
          urlAfterCreation={() => ``}
          urlAfterUpdate={() => null}
          noCardWrap
        >
          <TableListWrapper>
            <TableList name="name" label="이름">
              <ODEntityInput
                keyPath="name"
                name="name"
                label="이름"
                placeholder="이름을 입력해주세요."
                inputType="text"
              />
            </TableList>
            <TableList name="email" label="이메일">
              <ODEntityInput
                keyPath="email"
                readOnly
                label="이메일"
                name="email"
                placeholder="이메일을 입력해주세요."
                inputType="text"
              />
            </TableList>
            <TableList
              name="resetEmail"
              label="비밀번호"
              caption
              captionLabel="재설정된 비밀번호를 이메일로 전송합니다."
            >
              <ODEntityLabeled name="resetEmail" label="비밀번호">
                <ResetButtonWrapper>
                  <EvixButton type="button" theme={EvixButtonTheme.SurfaceNeutralSmall} onClick={handleResetPassword}>
                    비밀번호 재설정
                  </EvixButton>
                </ResetButtonWrapper>
              </ODEntityLabeled>
            </TableList>
          </TableListWrapper>
          <div style={{ marginTop: 30, marginBottom: 30 }}>
            <hr />
          </div>
        </ODEntity>
      </EvixCard>
    </>
  )
}

const ResetButtonWrapper = styled.div`
  padding-top: 2px;
`
