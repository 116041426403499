import { ODIcons, ODVSpace, useODModalConfirm } from '@mdpp/od-react-belt'
import React, { useState } from 'react'
import styled from 'styled-components'
import {
  GQLReservation,
  GQLRESERVATION_STATUS,
  GQLUpdateReservationByDoctorInput
} from '@mdpp/common/lib/@types/server.schema'
import { EvixCardBase } from '../../../../common/card/EvixCardBase'
import { EvixCardHeader } from '../../../../common/card/EvixCardHeader'
import { EvixClickToInput } from '../../../../common/EvixClickToInput'
import { ReservationBasicInfoBoxStyle } from '../../../../common/reservation/ReservationCommon'
import { PAY_MAX, PAY_MIN, WebUtils } from '../../../../utils/webUtils'
import { TransactionLogText } from './TransactionLogText'
import SVGIllustration from '../../../../assets/illustration.svg'
import SVGIllustrationDisabled from '../../../../assets/illustration_disabled.svg'
import { useCommonAPIs } from '../../../../common/hooks/useCommonAPIs'
import { BP, Button } from '../../../../common/RebeccaButton'
import { PopupModal } from '../../../../common/layout/PopupModal'

interface IPatientDiagnosisFeeInfoCardProps {
  reservation: GQLReservation
  infoStyle: ReservationBasicInfoBoxStyle
  onUpdateField: (fieldName: keyof GQLUpdateReservationByDoctorInput, value: string | number) => Promise<void>
  onPayRequested: (value: number) => void
  payLoading: boolean
}

export const PatientDiagnosisFeeInfoCard: React.FC<IPatientDiagnosisFeeInfoCardProps> = props => {
  const { reservation, onUpdateField, infoStyle, onPayRequested, payLoading } = props
  const isInsideRoom = infoStyle === ReservationBasicInfoBoxStyle.InsideRoom
  const cardStyle = isInsideRoom ? { border: 0 } : {
    display: 'flex',
    background: '#FFFFFF',
    boxShadow: '0px 0px 5px rgba(23, 24, 24, 0.05), 0px 1px 2px rgba(0, 0, 0, 0.15)',
    borderRadius: 8,
    border: 0,
  }
  const { paymentState, transactionLogs } = reservation
  const { finishReservation } = useCommonAPIs()
  const { Component: ConfirmComponent, confirm, props: confirmProps } = useODModalConfirm({
    title: '진료비 청구',
    message: '진료비를 청구하면 환자에게 결제를 시도합니다. 계속하시겠습니까?',
    yes: '청구하기',
    no: '취소',
  })

  const [price, setPrice] = useState(reservation.paymentTotal || undefined)
  const [showPopup, setShowPopup] = useState(false)
  
  const handlePay = async () => {
    if (price && price < 0) {
      WebUtils.showInfo('액수를 입력해주세요.')
      return
    }

    onPayRequested(price as number)
  }

  const handleNotPay = async () => {
    WebUtils.showInfo('청구가능한 금액은 ' + PAY_MIN.toLocaleString('en-US') + ' ~ ' + PAY_MAX.toLocaleString('en-US') + '0원 입니다.(미청구시 0 입력)')
    return
  }
  
  const inActiveCard = () => {
    const subTitleText = reservation.status === GQLRESERVATION_STATUS.FINISHED
      ? '결제가 완료 되었습니다'
      : '현재는 진료비를 청구 할 수 없습니다'
    const illustration = reservation.status === GQLRESERVATION_STATUS.FINISHED
      ? SVGIllustration : SVGIllustrationDisabled
    
    return (
      <EvixCardBase fit style={cardStyle}>
        <EmptyWrapper>
          <ImageWrapper>
            <PaymentImage src={illustration}/>
            <>
              <ODVSpace h={32}/>
              <SubTitleText>{subTitleText}</SubTitleText>
              <ODVSpace h={8}/>
              { reservation.paymentState === 'SUCCESS'
                ? <TitleText>{reservation.paymentTotal?.toLocaleString()}원</TitleText>
                : null
              }
              <ODVSpace h={8}/>
              { reservation.paymentState === 'SUCCESS'
                ? (transactionLogs.map(log => {
                    return <TransactionLogText data={log} key={log.transactionLogId} />
                  }))
                : <InfoCenter></InfoCenter>
              }
            </>
          </ImageWrapper>
        </EmptyWrapper>
      </EvixCardBase>
    )
  }

  const okClick = async () => {
    if (price !== undefined && (price === 0 || price >= PAY_MIN && price <= PAY_MAX)) {
      handlePay()
    } else {
      handleNotPay()
    }
    setShowPopup(false)
  }
  
  const activeCard = () => {
    const isCharged = reservation.paymentState !== "SUCCESS"
      && reservation.status === GQLRESERVATION_STATUS.FINISHED
      && reservation.paymentTotal !== null
    return (
      <EvixCardBase fit style={cardStyle}>
        {
          showPopup &&
          <PopupModal
            title={'진료비 청구'}
            description={`진료비를 청구 하시겠습니까?`}
            onOkPress={okClick}
            onCancelPress={()=>(setShowPopup(false))}
          />
        }
        <ConfirmComponent {...confirmProps} />
        <Wrapper>
          <TopWrapper>
            <EvixCardHeader icon={ODIcons.CoreCreditCard} title="진료비 청구" />
            <ODVSpace h={24} />
            <Info>진료비 입력 후 버튼을 누르면 환자에게 청구됩니다.</Info>
            <Info><u>청구한 이후 금액수정은 불가능</u>하니 반드시 정확하게 입력해주세요.</Info>
            <Info2>청구 후 결제완료까지 시간이 소요될 수 있습니다.</Info2>
            <ODVSpace h={12}/>
          </TopWrapper>
          <MiddleWrapper>
            <PriceInputWrapper>
              <EvixClickToInput
                alignRight
                saveOnEnter
                initialValue={reservation.paymentTotal ?? ''}
                type="number"
                nonText={ reservation.status === GQLRESERVATION_STATUS.PRESCRIPTION ? '금액을 입력해주세요' : '현재는 진료비를 청구할 수 없습니다.'}
                onChangeCallback={async value  => {
                  const v = isNaN(value) ? undefined : value
                  setPrice(v)
                }}
                numberToLocale
                addPostfix="원"
                min={0}
                step={100}
                disabled={ reservation.paymentState === 'SUCCESS' || isCharged || !isValidPaymentState }
              />
            </PriceInputWrapper>
            <ODVSpace h={10} />
            { !isCharged ? (<GuideLabel>{ isValidPaymentState ? '최소 ' + PAY_MIN.toLocaleString('en-US') + ' ~ ' + PAY_MAX.toLocaleString('en-US') + '원 (미청구시 0원)' : ''}</GuideLabel>)
              :(<GuideWaitingLabel>{'결제 요청됨 / 승인 대기중'}</GuideWaitingLabel>)
            }
            {transactionLogs.length > 0 && <ODVSpace h={1} />}
          </MiddleWrapper>
          <BottomWrapper>
            {
              <ButtonWrapper>
                <Button
                    type={BP.TYPE.Primary}
                    size={BP.SIZE.L}
                    onClick={() => (setShowPopup(true))}
                    disabled={
                      price === undefined || payLoading || price && ( price < 0 || price >= 0 && !(price === 0 || (price >= PAY_MIN && price <= PAY_MAX))) || !isValidPaymentState }
                >
                  진료비 청구
                </Button>
              </ButtonWrapper>
            }
          </BottomWrapper>
        </Wrapper>
      </EvixCardBase>
    )
  }

  const isValidPaymentState = (reservation.status === GQLRESERVATION_STATUS.PRESCRIPTION)

  return (
    (isValidPaymentState || reservation.paymentState === 'FAILED') ? activeCard() : inActiveCard()
  )
}

const ButtonWrapper = styled.div`
  float: right;
  margin-top: 24px;
`

const PaymentImage = styled.img`
  width: 100%
  margin-top: 20px;
  display: block;
  margin-left: auto;
  margin-right: auto;
`

const GuideLabel = styled.label`
  color: red;
  font-size: 13px;
  width: 100%;
  text-align: right;
`

const GuideWaitingLabel = styled.label`
  color: #1C564B;
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  width: 100%;
  text-align: right;
`

const ImageWrapper = styled.div`
  width: 100%
  margin-top: 20px;
  align-self: center;
  justify-content: center;
`

const EmptyWrapper = styled.div`
  display: flex;
  flex-direction: row;
  height: 500px;
  align-items: flex-start;
  justify-content: center;
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 500px;
`

const TopWrapper = styled.div`
  width: 100%;
  border-bottom: 1px solid var(--gray200);
  margin-left : 10px
`

const MiddleWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  border-bottom: 1px solid var(--gray200);
  flex-basis: 60%;
`

const PriceInputWrapper = styled.div`
  width: 558px;
  margin-bottom: 5px;
`

const BottomWrapper = styled.div`
  width: 100%;
`

const Info = styled.div`
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.3px;
  color: #131415;
`

const Info2 = styled.div`
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;
  letter-spacing: -0.3px;
  color: #4F5356;
  margin-top: 8px;
`

const InfoCenter = styled.div`
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #6D7175;
`

const TitleText = styled.div`
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 40px;
  text-align: center;
  color: #080809;
`

const SubTitleText = styled.div`
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 28px;
  text-align: center;
  color: var(--gray600);
`
