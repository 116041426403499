import { SingleQueryWrapper } from '@mdpp/od-react-belt'
import React from 'react'
import { useCommonAPIs } from '../../hooks/useCommonAPIs'
import { NoticeItem } from './NoticeItem'
import {Page, PageContainer, PageHeader, PageTitle} from "../../page/Page";

interface INoticeItemPageProps {
  id: string
}

export const NoticeItemPage: React.FC<INoticeItemPageProps> = props => {
  const { id } = props

  const { getNotice } = useCommonAPIs()

  return (
    <SingleQueryWrapper deps={[id, getNotice]} getter={() => getNotice({ id: parseInt(id, 10) })}>
      {({ data }) => {
        return (
            <PageContainer>
              <Page>
                <PageHeader><PageTitle>공지사항</PageTitle></PageHeader>
                <NoticeItem notice={data} />
              </Page>
            </PageContainer>
        )
      }}
    </SingleQueryWrapper>
  )
}
