import { ODListableResponseType } from '@mdpp/od-react-belt'
import React from 'react'
import { GQLHospitalManager } from '@mdpp/common/lib/@types/server.schema'
import { useAdminAPIs } from '../../context/useAdminAPIs'
import { IHospitalManagerListableOption } from '../HospitalManagerCommon'

export function useHospitalManagerListDataLoader(hospitalId?: number) {
  const { listHospitalManager } = useAdminAPIs()
  return React.useCallback(
    async function dataLoader(
      page: number,
      pageSize: number,
      afterKey: string | null,
      options: IHospitalManagerListableOption
    ): Promise<ODListableResponseType<GQLHospitalManager>> {
      const { hospitalId: ignoreThis, ...rest } = options
      const r = await listHospitalManager({
        page,
        pageSize,
        hospitalId: hospitalId ?? null,
        ...rest,
      })
      return r as ODListableResponseType<GQLHospitalManager>
    },
    [listHospitalManager, hospitalId]
  )
}
