const MANAGER_SNAPSHOT = `
  hospitalManagerId
  hospital {
    hospitalId
    name
  }
  email
  name
  lastAccessTime
  leftAt
  lastPasswordUpdatedTime
`

export const GQL_LOGIN_HOSPITAL_MANAGER = `
mutation loginHospitalManager($data: HospitalManagerLoginInput!) {
  loginHospitalManager(data: $data) {
    me {
      ${MANAGER_SNAPSHOT}
    }
    token
  }
}
`

export const GQL_GET_HOSPITAL_MANAGER_PROFILE = `
query getHospitalManagerMyProfile {
  getHospitalManagerMyProfile {
    ${MANAGER_SNAPSHOT}
  }
}`
