import { SingleQueryWrapper } from '@mdpp/od-react-belt'
import React from 'react'
import { useCommonAPIs } from '../../common/hooks/useCommonAPIs'
import { ReservationPatientInfoBox } from '../../doctor/reservation/detail/ReservationPatientInfoBox'
import { PageContainer, Page, PageHeader, PageTitle, PageContent } from '../../common/page/Page'

interface IAdminPatientDetailPageProps {
  patientId: number
}

export const AdminPatientDetailPage: React.FC<IAdminPatientDetailPageProps> = props => {
  const { patientId } = props
  const { getPatient } = useCommonAPIs()

  return (
    <PageContainer>
      <Page>
        <PageHeader>
          <PageTitle>환자</PageTitle>
        </PageHeader>
       
        <SingleQueryWrapper deps={[getPatient, patientId]} getter={() => getPatient({ id: patientId })}>
          {({ data }) => <ReservationPatientInfoBox patient={data} />}
        </SingleQueryWrapper>
     
      </Page>
    </PageContainer>
  )
}
