import { ODIcons, ODVSpace, useODQuery } from '@mdpp/od-react-belt'
import React, { useEffect } from 'react'
import styled from 'styled-components'

import {
  GQLReservation,
  GQLFAMILY_TYPE,
  GQLSingleIDInput,
  GQLReservationExtension,
} from '@mdpp/common/lib/@types/server.schema'
import { GET_RESERVATION_EXTENSION } from '../../../common/gqls_common'
import { EvixCardHeader } from '../../../common/card/EvixCardHeader'
import { EvixCardLabel } from '../../../common/card/EvixCardLabel'
import { EvixCardRow } from '../../../common/card/EvixCardRow'
import { EvixCardRowContent, EvixSpan } from '../../../common/card/EvixCardRowContent'
import { EvixFileLink } from '../../../common/EvixFileLink'
import { ReservationBasicInfoBoxStyle } from '../../../common/reservation/ReservationCommon'
import { WebUtils } from '../../../utils/webUtils'
import { BodyContainer } from '../../../common/layout/BodyContainer'
import { EvixCardBase } from '../../../common/card/EvixCardBase'
import { numSorting } from '../../../utils/webUtils'

interface IReservationBasicInfoBoxProps {
  reservation: GQLReservation
  infoStyle: ReservationBasicInfoBoxStyle
  hidePatient?: boolean
  isFinishedMedical?: boolean
  fromManager?: boolean
  fromAdmin?: boolean
}

export const MAP_FAMILY_TYPE = {
  [GQLFAMILY_TYPE.PARENT]: '부모',
  [GQLFAMILY_TYPE.CHILDREN]: '자녀',
  [GQLFAMILY_TYPE.MARRIAGE_PARTNER]: '배우자',
  [GQLFAMILY_TYPE.SIBLING]: '기타',
}

const NoFile = styled.span`
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.3px;
  color: #9ca0a3;
`
const TableListWrapper = styled.div`
  gap: 30px;
  display: flex;
  flex-direction: column;
`

export const ReservationBasicInfoBox: React.FC<IReservationBasicInfoBoxProps> = props => {
  const {
    reservation,
    infoStyle,
    hidePatient = false,
    isFinishedMedical,
    fromManager = false,
    fromAdmin = false,
  } = props
  const [juMinNum, setJuMinNum] = React.useState<string | null>(null)

  const getReservationExtension = useODQuery<GQLSingleIDInput, GQLReservationExtension>(GET_RESERVATION_EXTENSION)
  const isInsideRoom = infoStyle === ReservationBasicInfoBoxStyle.InsideRoom
  const cardStyle = isInsideRoom
    ? {
        border: 0,
        width: '100%',
      }
    : {
        display: 'flex',
        minWidth: 700,
        background: '#FFFFFF',
        boxShadow: '0px 0px 5px rgba(23, 24, 24, 0.05), 0px 1px 2px rgba(0, 0, 0, 0.15)',
        borderRadius: 8,
        border: 0,
      }

  useEffect(() => {
    if (
      reservation.status !== 'FINISHED' &&
      reservation.status !== 'DOCTOR_CANCEL' &&
      reservation.status !== 'PATIENT_CANCEL' &&
      reservation.status !== 'DOCTOR_NO_SHOW' &&
      reservation.status !== 'PATIENT_NO_SHOW'
    ) {
      getReservationExtensionApi()
    }
  }, [])

  const getReservationExtensionApi = async () => {
    try {
      const r = await getReservationExtension({ id: reservation.reservationId })
      setJuMinNum(r.rrn!)
    } catch (e) {
      console.log(e)
    }
  }

  const patientName = (
    <EvixCardRow>
      <EvixCardLabel>환자이름</EvixCardLabel>
      {!!reservation.proxyReservationInfo && (
        <>
          <EvixSpan>
            {reservation['proxyReservationInfo']['name']} (
            {MAP_FAMILY_TYPE[reservation['proxyReservationInfo']['relationship']]})
          </EvixSpan>
          <EvixCardLabel style={{ minWidth: '100px' }}>대리예약 신청자</EvixCardLabel>
        </>
      )}
      <EvixCardRowContent>
        {hidePatient ? reservation.patient?.name ?? '-' : reservation.patient?.name ?? '-'}
      </EvixCardRowContent>
    </EvixCardRow>
  )

  const doctorName = (
    <EvixCardRow>
      <EvixCardLabel>의사명</EvixCardLabel>
      <EvixCardRowContent>{reservation.doctor.name ?? '-'}</EvixCardRowContent>
    </EvixCardRow>
  )

  const hospitalAndSubject = (
    <EvixCardRow>
      <EvixCardLabel>병원/진료과</EvixCardLabel>
      <EvixCardRowContent>
        {reservation.hospital.name ?? '-'} / {reservation.medicalSubject?.name ?? '-'}
      </EvixCardRowContent>
    </EvixCardRow>
  )

  const reservationTime = (
    <EvixCardRow>
      <EvixCardLabel>진료일시</EvixCardLabel>
      <EvixCardRowContent>{WebUtils.formatReservationTime(reservation.reservationTime)}</EvixCardRowContent>
    </EvixCardRow>
  )

  const reservationCreated = (
    <EvixCardRow>
      <EvixCardLabel>예약등록</EvixCardLabel>
      <EvixCardRowContent>{WebUtils.formatReservationTime(reservation.createdAt)}</EvixCardRowContent>
    </EvixCardRow>
  )

  const birth = (
    <EvixCardRow>
      <EvixCardLabel>생년월일</EvixCardLabel>
      {!!reservation.proxyReservationInfo && (
        <EvixCardRowContent>{reservation.proxyReservationInfo.birthDate}</EvixCardRowContent>
      )}
      {!reservation.proxyReservationInfo && <EvixCardRowContent>{reservation.patient.birthDate}</EvixCardRowContent>}
    </EvixCardRow>
  )

  const phone = (
    <EvixCardRow>
      <EvixCardLabel>연락처</EvixCardLabel>
      {!!reservation.proxyReservationInfo && (
        <EvixCardRowContent>
          {numSorting(reservation.proxyReservationInfo.phone) || numSorting(reservation.patient.phone)}
        </EvixCardRowContent>
      )}
      {!reservation.proxyReservationInfo && (
        <EvixCardRowContent>{numSorting(reservation.patient.phone)}</EvixCardRowContent>
      )}
    </EvixCardRow>
  )

  const gender = (
    <EvixCardRow>
      <EvixCardLabel>성별</EvixCardLabel>
      {!!reservation.proxyReservationInfo && (
        <EvixCardRowContent>
          {reservation.proxyReservationInfo.sex === '1' || reservation.proxyReservationInfo.sex === '3'
            ? '남성'
            : '여성'}
        </EvixCardRowContent>
      )}
      {!reservation.proxyReservationInfo && (
        <EvixCardRowContent>{reservation.patient.gender === 'male' ? '남성' : '여성'}</EvixCardRowContent>
      )}
    </EvixCardRow>
  )

  const subject = (
    <EvixCardRow>
      <EvixCardLabel>진료과목</EvixCardLabel>
      <EvixCardRowContent>{reservation.medicalSubject?.name ?? '-'}</EvixCardRowContent>
    </EvixCardRow>
  )

  const symptom = (
    <EvixCardRow style={{ minHeight: 36 }}>
      <EvixCardLabel>증상</EvixCardLabel>
      <EvixCardRowContent>{reservation.symptom}</EvixCardRowContent>
    </EvixCardRow>
  )

  const attachedFiles = (
    <EvixCardRow>
      <EvixCardLabel>첨부파일</EvixCardLabel>
      <EvixCardRowContent style={{ gap: 4, padding: '16px', borderRadius: 4, backgroundColor: 'rgb(251,251,252)' }}>
        {reservation.patientFiles.length === 0 && <NoFile>첨부파일이 없습니다.</NoFile>}
        {reservation.patientFiles.map((file, index) => {
          return (
            <>
              <EvixFileLink key={index} name={file.fileName} link={file.link} />
              <div className="divider" />
            </>
          )
        })}
      </EvixCardRowContent>
    </EvixCardRow>
  )

  const juMin = (
    <>
      {reservation.status !== 'FINISHED' &&
      reservation.status !== 'DOCTOR_CANCEL' &&
      reservation.status !== 'PATIENT_CANCEL' &&
      reservation.status !== 'DOCTOR_NO_SHOW' &&
      reservation.status !== 'PATIENT_NO_SHOW' ? (
        <EvixCardRow>
          <EvixCardLabel>주민등록번호</EvixCardLabel>
          <EvixCardRowContent>{juMinNum ? window.atob(juMinNum) : '-'}</EvixCardRowContent>
        </EvixCardRow>
      ) : null}
    </>
  )

  return (
    <BodyContainer style={isInsideRoom ? { border: 0, minWidth: 300, margin: 0, width: '100%' } : {}}>
      <EvixCardBase style={{ ...cardStyle }}>
        <EvixCardHeader
          icon={ODIcons.CoreCalendarCheck}
          title={'예약 정보' + (!!reservation?.proxyReservation ? ' (대리 예약)' : '')}
          isRoom={isInsideRoom}
        />
        {!isInsideRoom ? <ODVSpace h={30} /> : <ODVSpace h={0} />}
        {fromAdmin && (
          <TableListWrapper>
            {doctorName}
            {hospitalAndSubject}
            {reservationTime}
            {reservationCreated}
            {patientName}
            {birth}
            {juMin}
            {gender}
            {phone}
          </TableListWrapper>
        )}
        {fromManager && (
          <TableListWrapper>
            {doctorName}
            {subject}
            {reservationTime}
            {reservationCreated}
            {patientName}
            {birth}
            {juMin}
            {gender}
            {phone}
            {symptom}
            {attachedFiles}
          </TableListWrapper>
        )}
        {!fromAdmin && !fromManager && (
          <TableListWrapper>
            {subject}
            {reservationTime}
            {reservationCreated}
            {patientName}
            {juMin}
            {birth}
            {gender}
            {phone}
            {symptom}
            {attachedFiles}
          </TableListWrapper>
        )}
      </EvixCardBase>
    </BodyContainer>
  )
}
