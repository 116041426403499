import { ODHSpace, ODVSpace } from '@mdpp/od-react-belt'
import React, { useEffect } from 'react'
import styled from 'styled-components'
import { TableList } from '../../list/TableList'
import { EvixInput } from '../../EvixInput'
import { validator } from '../../../validator'
import { useCommonAPIs } from '../../hooks/useCommonAPIs'
import { BtnWrap, Oval, OvalGray, WarningMessageWrap } from '../../style/SignUpStyle'
import _ from 'lodash'
import { InputCheckboxSupportWrapper, MessageIcon, SwitchWrap, ToggleLabel } from '../../style/CommonStyle'
import Toggle from 'react-toggle'
import { EvixSelector } from '../../EvixSelector'
import { COLORS } from '../../../styles/colors'
import { WebUtils } from '../../../utils/webUtils'
import { EvixInlineInputText } from '../../EvixInlineInputText'
import { checkMessageWithPrefix } from '../../../utils/registerServerErrorMessages'
import { ErrorCode } from '@mdpp/common'
import { EvixButton, EvixButtonTheme } from '../../EvixButton'
import IconDelete from '../../../assets/common/icon_delete.png'
import IconWarningBlue from '../../../assets/common/warning-blue.svg'
import { AddressSearchModal } from '../../../pharmacy/signup/AddressSearchModal'

interface IPharmaProfileEditProps {
  fromManager: boolean
  fromAdmin: boolean
  waitingPharmacistId: number
}

interface IPharmacist {
  waitingPharmacistId: number
  licenseNumber: string | undefined
  cellPhone: string | undefined
  birthDate: string | undefined
  pharmacyName: string | undefined
  pharmacyAddress: string | undefined
  pharmacyAddressDetail: string | undefined
  pharmacyZipCode: string | undefined
  pharmacyPhoneNumber: string | undefined
  pharmacyFaxNumber: string | undefined
  businessLicenseNumber: string | undefined
  businessLicenseFile: any
  bankbookFile: any
  bankName: string | undefined
  accountHolder: string | undefined
  accountNumber: string | undefined
  availableDelivery: boolean | undefined
  availableQuick: boolean | undefined
  notiMarketing: boolean | undefined
  hospitals: Array<number> | any
  hospitalId?: Array<number> | any
  email?: string
  name?: string
  businessLicenseFileId?: number | null
  createdAt?: null
  updatedAt?: null
  holdReason?: string | null
  emailAuthTime?: null
  isEmailAuthenticated?: boolean
  isHold?: boolean
  __typename?: null
}

export const WaitingPharmacyProfileEdit: React.FC<IPharmaProfileEditProps> = props => {
  const { fromManager, fromAdmin, waitingPharmacistId } = props
  const { getWaingPharmacist, updateWaitingPharmacist, listHospitalForPharmacyChoose } = useCommonAPIs()
  const [origin, setOrigin] = React.useState<IPharmacist | null>()
  const [mdData, setMdData] = React.useState<IPharmacist | null>()
  const [availableDelivery, setAvailableDelivery] = React.useState(false)
  const [availableQuick, setAvailableQuick] = React.useState(false)
  const [fileName, setFileName] = React.useState('')
  const [bankBook, setBankBook] = React.useState('')
  const [file, setFile] = React.useState({})
  const [bankBookFile, setBankBookFile] = React.useState('')
  const [data, setData] = React.useState<any[]>([])
  const [nextBtn, setNextBtn] = React.useState(true)
  const [hospital, setHospital] = React.useState('')
  const [notiMarketing, setNotiMarketing] = React.useState(true)
  const [errBusinessMsg, setBusinessCodeMsg] = React.useState('')
  const [showAddressSearchModal, setShowAddressSearchModal] = React.useState(false) // 주소 검색 Modal
  const [hospitalOptions, setHospitalOptions] = React.useState([
    { value: '연계 병원을 선택해주세요.', label: '연계 병원을 선택해주세요.' },
  ])
  const mapping = {
    hospitalId: 'value',
    name: 'label',
  }
  const uniqData = _.uniqBy(data, 'value')

  useEffect(() => {
    getApiWaingPharmacist()
    getOptions()
  }, [])

  useEffect(() => {
    setMdData(_.cloneDeep(origin))
  }, [origin])

  useEffect(() => {
    const isEqualhospitals = _.isEqual(origin?.hospitals, data)

    if (isEqualhospitals === true) {
      return setNextBtn(true)
    }
    setNextBtn(false)
  }, [data])

  useEffect(() => {
    setBusinessCodeMsg('')
  }, [mdData?.businessLicenseNumber])

  useEffect(() => {
    const isEqualData = _.isEqual(origin, mdData)

    if (isEqualData === true) {
      return setNextBtn(true)
    }
    if (!mdData?.birthDate || Object.keys(validator.birth(mdData.birthDate)).length > 0) {
      return setNextBtn(true)
    }
    if (!mdData?.licenseNumber || Object.keys(validator.account(mdData.licenseNumber)).length > 0) {
      return setNextBtn(true)
    }
    if (!mdData?.cellPhone || Object.keys(validator.account(mdData.cellPhone)).length > 0) {
      return setNextBtn(true)
    }
    if (
      !mdData?.pharmacyName ||
      !mdData?.pharmacyAddress ||
      !mdData?.pharmacyZipCode ||
      !mdData?.pharmacyAddressDetail ||
      !mdData?.accountHolder ||
      !mdData?.bankName
    ) {
      return setNextBtn(true)
    }
    if (!mdData?.pharmacyPhoneNumber || Object.keys(validator.account(mdData.pharmacyPhoneNumber)).length > 0) {
      return setNextBtn(true)
    }
    if (!mdData?.pharmacyFaxNumber || Object.keys(validator.account(mdData.pharmacyFaxNumber)).length > 0) {
      return setNextBtn(true)
    }
    if (!mdData?.businessLicenseNumber || Object.keys(validator.account(mdData.businessLicenseNumber)).length > 0) {
      return setNextBtn(true)
    }
    if (!mdData?.accountNumber || Object.keys(validator.account(mdData.accountNumber)).length > 0) {
      return setNextBtn(true)
    }
    if (!mdData?.bankbookFile || !mdData.businessLicenseFile) {
      return setNextBtn(true)
    }
    setNextBtn(false)
  }, [mdData])

  const reNameKey = (mapping: object, objName: Array<String | object>) => {
    const reNameArr: Array<String | object> = []
    objName.map((obj: any) => {
      const objs = obj.hospital

      if (objs.hasOwnProperty('name')) {
        objs.label = `${objs.name}/${objs.address}`
        delete objs.name
        delete objs.address
      }
      if (objs.hasOwnProperty('hospitalId')) {
        objs.value = objs.hospitalId
        delete objs.hospitalId
      }
      reNameArr.push(obj.hospital)
    })
    return setData(reNameArr)
  }

  const getApiWaingPharmacist = async () => {
    try {
      const r = await getWaingPharmacist({ id: waitingPharmacistId })
      const objName = r!.hospitals
      reNameKey(mapping, objName)
      setOrigin(r)
      setAvailableDelivery(r.availableDelivery)
      setAvailableQuick(r.availableQuick)
    } catch (e) {
      console.log(e)
    }
  }

  const compareArray = (mdDatas: object[], origins: object[], datas: object[]) => {
    const remdDataArrs: Array<number | object> = []
    const redataArrs: Array<number | object> = []

    const mdDataArr = mdDatas.map((v: any) => {
      return v.hospitalId
    })
    const remdDataObj = mdDataArr
    remdDataArrs.push(remdDataObj)

    const dataArr = datas.map((a: any) => {
      return a.value
    })
    const redataObjs = dataArr
    redataArrs.push(redataObjs)

    if (JSON.stringify(remdDataArrs[0]) == JSON.stringify(redataArrs[0])) {
      return true
    }
    return false
  }

  const handleSave = async () => {
    const mdDatas: any = mdData!
    const origins: any = origin!
    const dataValue: Array<number> = uniqData.map(v => {
      const no: number = v?.value
      return no
    })

    const isKeyExists = (obj: any, key: any) => {
      if (obj[key] == undefined) {
        return false
      } else {
        return true
      }
    }

    const postData: any = { waitingPharmacistId: origin?.waitingPharmacistId }
    Object.keys(origins).map((v: string) => {
      if (mdDatas[v]) {
        if (isKeyExists(postData, 'hospitals') === true) {
          delete postData.hospitals
          if (compareArray(mdData?.hospitals, origin?.hospitals, uniqData) === true) {
            delete postData.hospitals
          } else {
            postData['hospitalId'] = dataValue!
          }
        }
        if (mdDatas.availableDelivery !== origins.availableDelivery) {
          postData['availableDelivery'] = availableDelivery
        }
        if (mdDatas.availableQuick !== origins.availableQuick) {
          postData['availableQuick'] = availableQuick
        }
        if (mdDatas.notiMarketing !== origins.notiMarketing) {
          postData['notiMarketing'] = notiMarketing
        }
        if (
          origin?.businessLicenseFile?.blfId !== undefined &&
          mdData?.businessLicenseFile?.blfId === origin?.businessLicenseFile?.blfId
        ) {
          delete postData.businessLicenseFile
        }
        if (origin?.bankbookFile?.bbfId !== undefined && mdData?.bankbookFile?.bbfId === origin?.bankbookFile?.bbfId) {
          delete postData.bankbookFile
        }
        if (postData.licenseNumber) {
          postData['licenseNumber'] = postData.licenseNumber.padStart(5, '0')
        }
        if (origins[v] !== mdDatas[v]) {
          postData[v] = mdDatas[v]
        }
        return postData
      }
    })

    try {
      const result = await updateWaitingPharmacist(postData)
      getApiWaingPharmacist()
      return WebUtils.showSuccess('변경 되었습니다.')
    } catch (err) {
      console.log(err)
      if (checkMessageWithPrefix(err, ErrorCode.NO_UPDATE_FOUND)) {
        WebUtils.showError('변경된 내용이 없습니다.')
      }
      if (checkMessageWithPrefix(err, ErrorCode.BUSINESS_LICENSE_ALREADY_EXIST)) {
        setBusinessCodeMsg('이미 등록된 기관입니다.')
        setNextBtn(true)
      }
      if (checkMessageWithPrefix(err, ErrorCode.LICENSE_ALREADY_EXIST)) {
        WebUtils.showError('이미 등록된 면허번호입니다.')
        setNextBtn(true)
      }
    }
  }

  const handleAddressSelect = (address: string, zonecode: string) => {
    setMdData({
      ...mdData!,
      pharmacyAddress: address,
      pharmacyZipCode: zonecode,
    })
  }

  const fileChangedHandler = async (e: any) => {
    const files = e.target.files
    const fileSize = files[0]?.size
    const fileChangeName = files[0]?.name

    if (files[0]?.name === null) {
      return console.log('파일없음')
    }
    if (fileSize > 50000000) {
      return WebUtils.showError('첨부파일 사이즈는 50MB 이내로 등록 가능합니다.')
    }

    setFileName(fileChangeName ? fileChangeName : mdData?.businessLicenseFile?.name!)
    setFile(files[0])

    fileSaveHandler(files[0])
  }

  const fileBankBookChangedHandler = async (e: any) => {
    const files = e.target.files
    const fileSize = files[0]?.size
    const fileChangeName = files[0]?.name

    if (files[0]?.name === null) {
      return console.log('파일없음')
    }
    if (fileSize > 50000000) {
      return WebUtils.showError('첨부파일 사이즈는 50MB 이내로 등록 가능합니다.')
    }

    setBankBook(fileChangeName ? fileChangeName : mdData?.bankbookFile?.name!)
    setBankBookFile(files[0])

    fileBankBookSaveHandler(files[0])
  }

  const fileSaveHandler = (files: object) => {
    setMdData({
      ...mdData!,
      businessLicenseFile: files ? files : mdData?.businessLicenseFile,
    })
  }

  const fileBankBookSaveHandler = (files: object) => {
    setMdData({
      ...mdData!,
      bankbookFile: files ? files : mdData?.bankbookFile,
    })
  }

  const hospitalList = uniqData.map((v, idx: number) => (
    <SelectedItem key={idx}>
      <span>{v.label}</span>
      <button type="button" onClick={() => handelDeleteHospital(v.label)}>
        <img src={IconDelete} style={{ width: 12, height: 12 }} />
      </button>
    </SelectedItem>
  ))

  const handelDeleteHospital = (label: string) => {
    setData(data?.filter(data => data?.label !== label))
  }

  const onChangeHospital = (value: string) => {
    setHospital(value)
    UpDateDateList(value)
  }

  const getOptions = async () => {
    try {
      const res = await listHospitalForPharmacyChoose({})
      const dataList = res.list
      const options = dataList.map((d: any) => ({
        value: d.hospitalId,
        label: `${d.name}/${d.address}`,
      }))
      setHospitalOptions([{ value: '연계 병원을 선택해주세요.', label: '연계 병원을 선택해주세요.' }, ...options])
    } catch (e) {
      console.log(e)
    }
  }

  const UpDateDateList = (value: any) => {
    let names = _.map(data, 'label')
    const result = names.indexOf(value.label)

    if (data.length > 4) {
      return WebUtils.showError('5개 이상 등록할 수 없습니다.')
    }
    if (value.value === '연계 병원을 선택해주세요.') {
      return
    }
    if (result > -1) {
      return WebUtils.showError('이미 등록한 병원입니다.')
    }
    setData(data.concat(value))
  }

  const HandleToggle = () => {
    setAvailableDelivery(!availableDelivery)
    setAvailableQuick(!availableQuick)
    setMdData({
      ...mdData!,
      availableDelivery: !availableDelivery,
      availableQuick: !availableQuick,
    })
  }

  const HandleMarketingCheck = () => {
    setNotiMarketing(!notiMarketing)
    setMdData({
      ...mdData!,
      notiMarketing: !notiMarketing,
    })
  }

  return (
    <>
      <Wrapper>
        <SubTitle>기본 정보</SubTitle>
        <PharmacyAddInfo>
          <PharmacyAddInfoBlock className="block email">
            <div className="label">이메일 아이디</div>
            <Label>{origin && origin.email}</Label>
          </PharmacyAddInfoBlock>

          <PharmacyAddInfoBlock className="block">
            <div className="label">이름</div>
            <Label>{origin && origin.name}</Label>
          </PharmacyAddInfoBlock>

          <PharmacyAddInfoBlock className="block">
            <div className="label">
              생년월일<Oval style={{ marginLeft: 3, marginBottom: 7 }}></Oval>
            </div>
            <EvixInput
              style={{ width: '100%', diplay: 'inline-block' }}
              maxLength={8}
              value={String(mdData! && mdData?.birthDate)}
              validators={validator.birth}
              onChange={value => {
                setMdData({
                  ...mdData!,
                  birthDate: value,
                })
              }}
              disabled={false}
            />
          </PharmacyAddInfoBlock>

          <PharmacyAddInfoBlock className="block">
            <div className="label">
              약사면허번호<Oval style={{ marginLeft: 3, marginBottom: 7 }}></Oval>
            </div>

            <EvixInput
              style={{ width: '100%', diplay: 'inline-block' }}
              value={mdData! && mdData?.licenseNumber}
              onChange={value => {
                setMdData({
                  ...mdData!,
                  licenseNumber: value,
                })
              }}
              validators={validator.account}
              disabled={false}
              maxLength={5}
            />
            <WarningMessageWrap
              className={Object.keys(validator.account(mdData?.licenseNumber!)).length !== 0 ? 'hide' : ''}
            ></WarningMessageWrap>
          </PharmacyAddInfoBlock>
        </PharmacyAddInfo>

        <TableList name="cellPhone" label="휴대폰 번호" mandatoryInput>
          <ColumnWrap>
            <EvixInput
              style={{ width: 300 }}
              value={mdData! && mdData?.cellPhone}
              onChange={value => {
                setMdData({
                  ...mdData!,
                  cellPhone: value,
                })
              }}
              validators={validator.account}
              disabled={false}
            />
            <WarningMessageWrap>
              <ul>
                <li>
                  <OvalGray></OvalGray> 마케팅 정보 수신 동의여부
                  <InputCheckboxSupportWrapper style={{ marginLeft: 10 }}>
                    <input
                      type="checkbox"
                      id="radio1"
                      onChange={HandleMarketingCheck}
                      checked={mdData! && mdData?.notiMarketing}
                    />
                    <label htmlFor="radio1">동의</label>
                  </InputCheckboxSupportWrapper>
                </li>
              </ul>
            </WarningMessageWrap>
          </ColumnWrap>
          <WarningMessageWrap
            className={Object.keys(validator.account(mdData?.cellPhone!)).length !== 0 ? 'hide' : ''}
          ></WarningMessageWrap>
        </TableList>

        <ODVSpace h={34} />

        <SubTitle>약국 정보</SubTitle>

        <TableList name="name" label="약국 이름" mandatoryInput>
          <EvixInlineInputText
            style={{ width: 300 }}
            value={mdData! && mdData?.pharmacyName}
            onChange={value => {
              setMdData({
                ...mdData!,
                pharmacyName: value,
              })
            }}
            disabled={false}
          />
        </TableList>
        <ODVSpace h={34} />
        <TableList name="pharmacyAddress" label="주소" mandatoryInput>
          <AddressWrap onClick={() => setShowAddressSearchModal(true)}>
            <EvixInlineInputText
              style={{ width: '100%' }}
              value={mdData! && mdData?.pharmacyAddress}
              onChange={value => {
                setMdData({
                  ...mdData!,
                  pharmacyAddress: value,
                })
              }}
              disabled={true}
            />

            <EvixInlineInputText
              style={{ width: 150 }}
              value={mdData! && mdData?.pharmacyZipCode}
              onChange={value => {
                setMdData({
                  ...mdData!,
                  pharmacyZipCode: value,
                })
              }}
              disabled={true}
            />
            <EvixButton
              theme={EvixButtonTheme.H48WhiteInline}
              onClick={() => setShowAddressSearchModal(true)}
              disabled={false}
            >
              검색
            </EvixButton>
          </AddressWrap>
          <ODVSpace h={10} />
          <EvixInlineInputText
            style={{ width: '100%' }}
            value={mdData! && mdData?.pharmacyAddressDetail}
            onChange={value => {
              setMdData({
                ...mdData!,
                pharmacyAddressDetail: value,
              })
            }}
            disabled={false}
          />
          <WarningMessageWrap></WarningMessageWrap>
        </TableList>

        <ColumnWrap>
          <TableList name="pharmacyPhoneNumber" label="연락처" mandatoryInput>
            <EvixInput
              style={{ width: '100%' }}
              validators={validator.account}
              value={mdData! && mdData?.pharmacyPhoneNumber}
              onChange={value => {
                setMdData({
                  ...mdData!,
                  pharmacyPhoneNumber: value,
                })
              }}
              disabled={false}
            />
            <WarningMessageWrap
              className={Object.keys(validator.account(mdData?.pharmacyPhoneNumber!)).length !== 0 ? 'hide' : ''}
            ></WarningMessageWrap>
          </TableList>
          <TableList name="pharmacyFaxNumber" label="FAX" mandatoryInput>
            <EvixInput
              style={{ width: '100%' }}
              validators={validator.account}
              value={mdData! && mdData?.pharmacyFaxNumber}
              onChange={value => {
                setMdData({
                  ...mdData!,
                  pharmacyFaxNumber: value,
                })
              }}
              disabled={false}
            />
            <WarningMessageWrap
              className={Object.keys(validator.account(mdData?.pharmacyFaxNumber!)).length !== 0 ? 'hide' : ''}
            ></WarningMessageWrap>
          </TableList>
        </ColumnWrap>

        <ColumnWrap>
          <TableList name="businessLicenseNumber" label="사업자등록번호" labelMDSize={12} mandatoryInput>
            <EvixInput
              style={{ width: '100%' }}
              value={mdData! && mdData?.businessLicenseNumber}
              onChange={value => {
                setMdData({
                  ...mdData!,
                  businessLicenseNumber: value,
                })
              }}
              validators={validator.account}
              disabled={false}
            />
            <WarningMessageWrap
              className={Object.keys(validator.account(mdData?.businessLicenseNumber!)).length !== 0 ? 'hide' : ''}
            >
              <span className="error" style={{ fontSize: 13, fontWeight: 500, color: '#ff5353' }}>
                {errBusinessMsg}
              </span>
            </WarningMessageWrap>
          </TableList>
          <TableList name="fileName" label="사업자등록증" labelMDSize={12} mandatoryInput>
            <FileWrap>
              <FileInput>
                {mdData && mdData.businessLicenseFile?.fileName === '' ? (
                  <NoFileName>{/* 사업자등록증을 첨부해 주세요 */}PDF, JPG, PNG 파일만 첨부 가능합니다.</NoFileName>
                ) : (
                  <>
                    {mdData && mdData.businessLicenseFile?.fileName ? (
                      <button onClick={() => window.open(mdData.businessLicenseFile?.link)}>
                        {mdData && mdData.businessLicenseFile.fileName}
                      </button>
                    ) : (
                      fileName
                    )}
                  </>
                )}
              </FileInput>
              <FileLabel htmlFor="input-file">
                {' '}
                사업자등록증 첨부
                <input
                  name="businessLicenseFile"
                  type="file"
                  id="input-file"
                  style={{ display: 'none' }}
                  accept=".pdf, image/png, image/jpeg, image/jpg"
                  onChange={e => fileChangedHandler(e)}
                />
              </FileLabel>
            </FileWrap>
            <WarningMessageWrap>
              <ul>
                <li className="align-center">
                  <MessageIcon src={IconWarningBlue} alt="warning icon" />
                  비대면 진료 정산을 위해 필요합니다.
                  {/* <li>
                  <OvalGray></OvalGray> PDF, JPG, PNG 파일만 첨부 가능합니다. */}
                </li>
              </ul>
            </WarningMessageWrap>
          </TableList>
        </ColumnWrap>
        <ColumnWrap>
          <TableList name="bankBook" label="통장사본" labelMDSize={12} mandatoryInput>
            <FileWrap>
              <FileInput>
                {mdData && mdData.bankbookFile === null ? (
                  <NoFileName>PDF, JPG, PNG 파일만 첨부 가능</NoFileName>
                ) : (
                  <>
                    {mdData && mdData.bankbookFile?.fileName ? (
                      <button onClick={() => window.open(mdData?.bankbookFile?.link)}>
                        {mdData && mdData.bankbookFile?.fileName}
                      </button>
                    ) : (
                      bankBook
                    )}
                  </>
                )}
              </FileInput>
              <FileLabel htmlFor="input-bank-file">
                {' '}
                통장사본 첨부
                <input
                  name="bankbookFile"
                  type="file"
                  id="input-bank-file"
                  style={{ display: 'none' }}
                  accept=".pdf, image/png, image/jpeg, image/jpg"
                  onChange={e => fileBankBookChangedHandler(e)}
                />
              </FileLabel>
            </FileWrap>
            <WarningMessageWrap>
              <ul>
                <li className="align-center">
                  <MessageIcon src={IconWarningBlue} alt="warning icon" />
                  조제비 정산을 위해 필요합니다.
                </li>
              </ul>
            </WarningMessageWrap>
          </TableList>
          <div></div>
        </ColumnWrap>
        <ODVSpace h={20} />
        <ColumnWrap>
          <TableList
            name="availableDelivery"
            label="의약품 택배 / 퀵 배송 배송가능 여부"
            labelMDSize={12}
            mandatoryInput
          >
            <AddressWrap>
              <SwitchWrap style={{ display: 'inline-flex' }}>
                <Toggle checked={availableDelivery!} icons={false} onChange={HandleToggle} />
                <ToggleLabel>
                  <span>불가능</span>
                  <span>가능</span>
                </ToggleLabel>
              </SwitchWrap>
              <ODHSpace w={50} />
              {mdData && mdData.availableDelivery === true && (
                <>
                  <InputCheckboxSupportWrapper>
                    <input
                      type="checkbox"
                      id="cb1"
                      checked={availableQuick}
                      disabled={true}
                      // onChange={value => {
                      //   setMdData({
                      //     ...mdData,
                      //     availableQuick: true,
                      //   })
                      // }}
                    />
                    <label htmlFor="cb1">퀵 배송</label>
                  </InputCheckboxSupportWrapper>
                  {/* <TableList name="availableQuick" label="" labelMDSize={12}>
                    <input
                      type="radio"
                      id="radio1"
                      checked={mdData && mdData.availableQuick}
                      onChange={value => {
                        setOrigin({
                          ...mdData,
                          availableQuick: true,
                        })
                      }}
                    />
                    <label htmlFor="radio1" style={{ marginBottom: 0 }}>
                      퀵배송
                    </label>
                    <ODHSpace w={10} />
                    <input
                      type="radio"
                      id="radio2"
                      checked={mdData && !mdData.availableQuick}
                      onChange={value => {
                        setOrigin({
                          ...mdData,
                          availableQuick: false,
                        })
                      }}
                    />
                    <label htmlFor="radio2" style={{ marginBottom: 0 }}>
                      택배 배송
                    </label>
                  </TableList> */}
                </>
              )}
            </AddressWrap>
          </TableList>

          <TableList name="hospital" label="연계 병원" labelMDSize={6}>
            <EvixSelector
              style={{ width: '100%' }}
              value={'연계 병원을 선택해주세요.'}
              placeholder={'연계 병원을 선택해주세요.'}
              options={hospitalOptions}
              onChange={value => onChangeHospital(value)}
            />
            <SelectedItemWrap>{hospitalList}</SelectedItemWrap>
          </TableList>
        </ColumnWrap>
        <ODVSpace h={34} />

        <SubTitle>계좌 정보</SubTitle>
        <ColumnWrap>
          <TableList name="bankName" label="은행명" mandatoryInput>
            <EvixInput
              style={{ width: '100%' }}
              value={mdData! && mdData?.bankName}
              onChange={value => {
                setMdData({
                  ...mdData!,
                  bankName: value,
                })
              }}
              disabled={false}
            />
          </TableList>
          <TableList name="accountHolder" label="예금주" mandatoryInput>
            <EvixInput
              style={{ width: '100%' }}
              value={mdData! && mdData?.accountHolder}
              onChange={value => {
                setMdData({
                  ...mdData!,
                  accountHolder: value,
                })
              }}
              disabled={false}
            />
          </TableList>
        </ColumnWrap>
        <ColumnWrap>
          <TableList name="accountNumber" label="계좌번호" mandatoryInput>
            <EvixInput
              style={{ width: '100%' }}
              value={mdData! && mdData?.accountNumber}
              onChange={value => {
                setMdData({
                  ...mdData!,
                  accountNumber: value,
                })
              }}
              validators={validator.account}
              disabled={false}
            />
            <WarningMessageWrap
              className={Object.keys(validator.account(mdData?.accountNumber!)).length !== 0 ? 'hide' : ''}
            ></WarningMessageWrap>
          </TableList>
          <div></div>
        </ColumnWrap>

        <BtnWrap>
          <EvixButton theme={EvixButtonTheme.H48Black} onClick={() => handleSave()} disabled={nextBtn}>
            저장
          </EvixButton>
        </BtnWrap>
      </Wrapper>

      {showAddressSearchModal && (
        <AddressSearchModal
          style={{ width: 880, height: 764, maxWidth: 880 }}
          onSelected={handleAddressSelect}
          onCancelPress={() => setShowAddressSearchModal(false)}
        />
      )}
    </>
  )
}

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`

const InfoMessageWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-top: 10px;
`

const InfoMessage = styled.div`
  font-size: 13px;
  font-weight: 500;
  line-height: 1.17;
  color: #ababab;
`

const InfoIcon = styled.img`
  width: 14px;
  height: 14px;
  margin-right: 4px;
`

const SubTitle = styled.div`
  font-size: 15px;
  font-weight: 700;
  padding-bottom: 10px;
`

const PharmacyAddInfoBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;

  &.email {
    width: 218px;

    input {
      width: 100%;
      border-color: #f4f6f8 !important;
      background-color: #f4f6f8 !important;
      outline: none !important;
      padding-left: 0 !important;
      padding-right: 0 !important;

      &:focus {
        border-color: #f4f6f8 !important;
        outline: none !important;
      }
    }
  }

  .label {
    font-size: 12px;
    font-weight: 500;
    color: #909ca7;
  }

  .label ~ div,
  .label ~ div > div {
    border-radius: 1px !important;
  }

  .value {
    font-size: 15px;
    font-weight: 500;
    color: #424242;
    white-space: nowrap;
  }

  input {
    width: 120px;
    height: 35px;
    font-size: 15px;
    font-weight: 500;
    color: #424242;
    display: inline-block !important;
    border-radius: 1px !important;
    background-color: #fff !important;
  }

  [class*='EvixInput__InputWrap'] {
    display: inline-block;
    width: auto;
  }
`

const ColumnWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 32px;

  > div {
    width: 50%;
  }
`

const AddressWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
`

const RowWrap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
`

const PharmacyAddInfo = styled.p`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-radius: 0 !important;
  /* width: 680px; */
  padding: 18px 50px 18px 30px;
  background-color: #f4f6f8;
  gap: 50px;
`

const FileLabel = styled.label`
  font-size: 13px;
  font-weight: 500;
  font-style: normal;
  color: ${COLORS.GRAY42};
  margin-bottom: 0;
  padding: 12px;
  height: 44px;
  width: 130px;
  line-height: 18px;
  border-radius: 4px;
  border: 1px solid ${COLORS.GRAYAB2};
  background-color: ${COLORS.WHITE};
  text-align: center;
  cursor: pointer;
`

const FileWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  gap: 10px;

  .od-input-wrapper {
    border: none;

    .od-input {
      &::placeholder {
        color: ${COLORS.GRAYAB};
        font-size: 13px;
      }
    }
  }

  [class*='SignUpStyle__WarningMessageWrap'] {
    display: none;
  }
`

const FileInput = styled.label`
  font-size: 13px;
  font-weight: 500;
  font-style: normal;
  color: ${COLORS.GRAY42};
  margin-bottom: 0;
  padding: 12px;
  height: 44px;
  width: calc(100% - 130px);
  line-height: 18px;
  border-radius: 4px;
  border: 1px solid #dfe0e2;
  background-color: #eeeff1;
  text-align: left;
  cursor: pointer;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  span {
    color: #acb4bc;
  }
`

const NoFileName = styled.div`
  flex: 1;
  font-size: 13px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 0;
  // padding: 0 0.75rem;
  // line-height: 42px;
  // height: 42px;
  // border-radius: 4px;
  color: ${COLORS.GRAYAB};
  // background-color: #f6f7f7 !important;
`

const Label = styled.label`
  font-size: 15px;
  font-weight: 500;
  font-style: normal;
  color: ${COLORS.GRAY42};
  margin-bottom: 0;
  padding: 12px 0;
  height: 44px;
  line-height: 18px;
  border-radius: 4px;
  border: 0;
  background-color: transparent;
  text-align: left;
  cursor: pointer;
`

const SelectedItemWrap = styled.div`
  margin-top: 10px;
`

const SelectedItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0;
  padding: 0 14px;
  height: 44px;
  border-radius: 4px;
  border: 1px solid #dfe0e2;
  background-color: #fff;
  text-align: left;
  cursor: pointer;
  margin-bottom: 5px;

  > span {
    font-size: 13px;
    font-weight: 500;
    font-style: normal;
    color: #7c818b;
    line-height: 18px;
  }

  > button {
  }
`
