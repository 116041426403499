import React from 'react'
import styled from 'styled-components'
import { COLORS } from '../../styles/colors'

interface IEvixCardRowMultilineContentsProps {
  mainText: string
  subTexts: string[]
  subTextPrimaryColor: boolean
}

const MainText = styled.div`
  font-size: 14px;
  color: var(--gray800);
`
const SubText = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 14px;
  line-height: 1.4;
  color: var(--gray400);
`
const listStyle = {
    padding: '4px 8px',
    borderRadius: 4,
    backgroundColor: COLORS.mpNeutral100,
    marginBottom: 4
}

export const EvixCardRowMultilineContents: React.FC<IEvixCardRowMultilineContentsProps> = props => {
  const { mainText, subTexts, subTextPrimaryColor } = props

  return (
    <>
      { mainText === '없음' ? <MainText>{mainText}</MainText> : null }
      <SubText style={{ color: subTextPrimaryColor ? COLORS.GRAY800 : COLORS.GRAY400 }}>
        {subTexts.map((t, i) => {
          return <span style={listStyle} key={i}>{t}</span>
        })}
      </SubText>
    </>
  )
}
