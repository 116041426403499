import { ODHSpace } from '@mdpp/od-react-belt'
import React from 'react'
import Select from 'react-select'
import styled from 'styled-components'
import { DoctorReservationStatus } from './ReservationToolBar'

interface IDoctorReservationStatusSelector {
  value: DoctorReservationStatus
  onChange: (changedValue: DoctorReservationStatus) => void
}

const Wrapper = styled.div`
  // React Select Style
  .css-2b097c-container {
    & svg {
      font-size: 24px;
      color: var(--gray600);
    }
  }
  .css-1okebmr-indicatorSeparator {
    background-color: transparent;
  }

  .css-1laao21-a11yText {
    display: none;
  }
  
`


// react-select 스타일 수정 .
export const colourStyles = {
  control: (styles: any) => {
    return {
      ...styles,
      backgroundColor: 'white',
      width: 200,
      borderRadius: 8,
      border: '1px solid #C9CCCE',
      boxShadow: 'none',
      ':hover': {
        ...styles[':hover'],
        border: '1px solid #9CA0A3',
      },
      ':active': {
        ...styles[':active'],
        border: '1px solid #9CA0A3',

      },
    }
  },
  option: (styles: any, { data, isDisabled, isFocused, isSelected }: any) => {
    return {
      ...styles,
      backgroundColor: isDisabled ? null : isSelected ? 'var(--gray600)' : isFocused ? 'rgba(41,182,202,0.1)' : null,

      ':active': {
        ...styles[':active'],
        backgroundColor: !isDisabled && (isSelected ? 'var(--gray500)' : 'rgba(41,182,202,0.1)'),
      },
    }
  },
}

export const ReservationStatusSelector: React.FC<IDoctorReservationStatusSelector> = props => {
  const { value, onChange } = props

  const DOCTOR_RESERVATION_STATUS_TEXT = {
    [DoctorReservationStatus.ALL]: '모든 진료',
    [DoctorReservationStatus.PENDING]: '진료 예정',
    [DoctorReservationStatus.WAITING]: '진료 대기',
    [DoctorReservationStatus.IN_PROGRESS]: '진료중',
    [DoctorReservationStatus.FINISHED]: '진료 완료',
    [DoctorReservationStatus.CANCELED]: '취소된  진료',
  }

  const options = [
    { value: DoctorReservationStatus.ALL, label: DOCTOR_RESERVATION_STATUS_TEXT.ALL },
    { value: DoctorReservationStatus.PENDING, label: DOCTOR_RESERVATION_STATUS_TEXT.PENDING },
    { value: DoctorReservationStatus.WAITING, label: DOCTOR_RESERVATION_STATUS_TEXT.WAITING },
    { value: DoctorReservationStatus.IN_PROGRESS, label: DOCTOR_RESERVATION_STATUS_TEXT.IN_PROGRESS },
    { value: DoctorReservationStatus.FINISHED, label: DOCTOR_RESERVATION_STATUS_TEXT.FINISHED },
    { value: DoctorReservationStatus.CANCELED, label: DOCTOR_RESERVATION_STATUS_TEXT.CANCELED },
  ]

  return (
    <Wrapper>
      <Select
        name="DoctorreservationStatusSelector"
        options={options}
        value={options.find(v => v.value === value)}
        onChange={(e: any) => {
          onChange(e.value)
        }}
        styles={colourStyles}
      />
    </Wrapper>
  )
}
