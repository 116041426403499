import React, { useEffect } from 'react'
import { Modal } from 'reactstrap'
import { EvixButton, EvixButtonTheme } from '../../common/EvixButton'
import IconClose from '../../assets/common/icon-close.svg'
import { Wrapper, Header, TitleWrap, Title, TitleDescription } from '../../common/style/ModalStyle'
import { WarningMessageWrap } from '../../common/style/SignUpStyle'
import styled from 'styled-components/macro'
import { COLORS } from '../../styles/colors'
import IconDelete from '../../assets/common/icon-email-del.svg'
import { CompleteEmailSendModal } from './CompleteEmailSendModal'
import { EvixInput } from '../../common/EvixInput'
import { validator } from '../../validator'
import { useDoctorAPIs } from '../context/useDoctorAPIs'
import { WebUtils } from '../../utils/webUtils'

interface SendMyCodeModalProps {
  style?: object
  onCancelPress?: () => void
  onOkPress?: () => void
  emails?: Array<string>
}

interface emailInfo {
  id?: number | undefined
  email: string
}

export const SendMyCodeModal: React.FC<SendMyCodeModalProps> = props => {
  const { style, onCancelPress } = props
  const [showCompleteEmailSend, setShowCompleteEmailSend] = React.useState(false)
  const dataId = React.useRef(0)
  const [email, setEmail] = React.useState('')
  const [emailErr, setEmailErr] = React.useState('')
  const [data, setData] = React.useState<emailInfo[]>([])
  const { doctorInvite } = useDoctorAPIs()
  const [failResult, setFailResult] = React.useState<Array<string>>([])

  const onChangeEmail = (value: string) => {
    setEmail(value)
  }

  const offCompleteEmailSend = () => {
    setShowCompleteEmailSend(false)
    setEmail('')
    setData([])
  }

  const objectFilter = (obj: object) => {
    let newArr: string[] = []
    data.map(v => {
      newArr.push(v.email)
    })
    return newArr
  }

  const handleSearch = () => {
    if (email === '' || Object.keys(validator.email(email)).length !== 0) {
      return WebUtils.showError('이메일 형식이 아닙니다.')
    }
    if (data.length >= 10) {
      return WebUtils.showError('최대 10개까지 전송 가능합니다.')
    }
    const emailItem = {
      id: dataId.current,
      email,
    }
    dataId.current += 1
    setData([emailItem, ...data])
    setEmail('')
  }

  const handelDeleteEmail = (id: number | undefined) => {
    setData(data?.filter(data => data.id !== id))
  }

  const handleSend = async () => {
    if (emailList.length <= 0) {
      return setEmailErr('발송 가능한 이메일 주소가 없습니다.')
    }
    try {
      const result = await doctorInvite({ emails: objectFilter(data) })
      setFailResult(result.emails)
      setShowCompleteEmailSend(true)
      setEmailErr('')
    } catch (e) {
      console.log(e)
    }
  }

  const emailList = data.map((v, idx) => (
    <ResultBlock key={idx}>
      <span>{v.email}</span>
      <BtnDelete src={IconDelete} onClick={() => handelDeleteEmail(v.id)} />
    </ResultBlock>
  ))

  return (
    <Modal isOpen={true} toggle={() => {}} style={style}>
      <Wrapper className="flex-start">
        <Header>
          <img src={IconClose} onClick={onCancelPress} alt="close icon" />
        </Header>

        <Container>
          <TitleWrap className="column">
            <Title>내 추천코드 발송</Title>
            <TitleDescription>메일 발송은 한번에 최대 10개까지 가능합니다.</TitleDescription>
          </TitleWrap>

          {/* 추가 영역 */}
          <SearchWrap>
            <EvixInput
              style={{ flex: 1 }}
              value={email}
              validators={validator.email}
              onChange={value => onChangeEmail(value)}
              disabled={false}
              maxLength={50}
            />
            <EvixButton theme={EvixButtonTheme.H48WhiteInline} onClick={handleSearch} disabled={false}>
              추가
            </EvixButton>
          </SearchWrap>
          <WarningMessageWrap
            className={Object.keys(validator.email(email)).length !== 0 ? 'hide' : ''}
          ></WarningMessageWrap>

          {/* 검색 결과 리스트 */}
          <ResultWrap className={emailList.length <= 0 ? 'no-data' : ''}>
            {emailList.length <= 0 ? <NoData>{emailErr}</NoData> : <div>{emailList}</div>}
          </ResultWrap>
          <EvixButton
            style={{ width: '100%', marginTop: 30 }}
            theme={EvixButtonTheme.H48BlackInline}
            onClick={handleSend}
            disabled={false}
          >
            발송
          </EvixButton>
        </Container>
      </Wrapper>
      {showCompleteEmailSend && (
        <CompleteEmailSendModal
          style={{ width: 420, minHeight: 259, maxHeight: 463, maxWidth: 420 }}
          onCancelPress={() => setShowCompleteEmailSend(false)}
          onOkPress={() => offCompleteEmailSend()}
          SuccData={data}
          failData={failResult}
        />
      )}
    </Modal>
  )
}

const Container = styled.div`
  padding: 6px 60px 60px 60px;
`

const SearchWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 11px;
  margin: 14px 0 0 0;
`

const ResultWrap = styled.div`
  padding: 0 20px;
  border-radius: 4px;
  height: 450px;
  border-radius: 4px;
  border: solid 1px ${COLORS.GRAYD8};

  &.no-data {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`

const ResultBlock = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 44px;
  border-bottom: solid 1px ${COLORS.GRAYEB};
  gap: 10px;
`

const NoData = styled.div`
  font-size: 13px;
  font-weight: 500;
  color: ${COLORS.red100};
`

const BtnDelete = styled.img`
  width: 14px;
  height: 14px;
  cursor: pointer;
`
