import * as React from 'react'
import { Modal } from 'reactstrap'
import styled from 'styled-components'
import './modal.scss'

type ODModalProps = {
  isOpen: boolean
  fade?: boolean
  toggle: () => void
  rounded?: boolean
  noBorder?: boolean
  isCentered?: boolean
  width?: number
}

const Wrapper = styled.div`
  background-color: red;
  > div {
    background: rgba(0, 0, 0, 0.6)
  }
`

export const PopupModal: React.FC<ODModalProps> = ({ children, ...props }) => {
  const { isOpen, toggle, fade = true, isCentered = false, width = 1000 } = props
  return (
    <Wrapper>
      <Modal
        fade={fade}
        isOpen={isOpen}
        toggle={toggle}
        className={'modal-fullscreen'}
        centered={isCentered}
        style={{maxWidth: 1000}}
      >
        <div style={{ display: 'flex', flexDirection: 'column', width }}>{children}</div>
      </Modal>
    </Wrapper>
  )
}
