import {
  ODButton,
  ODButtonSize,
  ODButtonTheme,
  ODHSpace,
  ODIcon,
  ODIcons,
  ODVSpace,
  ODListablePaginatedTable2,
  ODListablePagination,
  ODListableSearchBox,
  useODListableContext,
} from '@mdpp/od-react-belt'
import moment from 'moment-timezone'
import React from 'react'
import { Link, useHistory } from 'react-router-dom'
import styled from 'styled-components/macro'
import { GQLNotice, GQLPOPUP_TYPE } from '@mdpp/common/lib/@types/server.schema'
import { SiteUrls } from '../../../SiteUrls'
import { WebUtils } from '../../../utils/webUtils'
import { PopupSettingModal } from '../modal/PopupSettingModal'
import { INoticeListableOption, NoticeFilter } from '../NoticeCommon'
import { useNoticeListDataLoader } from './useNoticeListDataLoader'
import { EvixCard } from '../../card/EvixCard'
import { Page, PageContainer, PageContent, PageHeader, PageTitle } from '../../page/Page'

interface INoticeListableTableProps {
  filter: NoticeFilter
}

const OPTIONS_PER_FILTER = {
  [NoticeFilter.Doctor]: { showOnDoctor: 1 },
  [NoticeFilter.HospitalManager]: { showOnHospitalManager: 1 },
  [NoticeFilter.EvixAdmin]: { showOnApp: 1, showOnDoctor: 1, showOnHospitalManager: 1 },
}

const getDetailUrl = (filter: NoticeFilter, id: number) => {
  switch (filter) {
    case NoticeFilter.Doctor:
      return SiteUrls.Doctor.Notice.Item(id)
    case NoticeFilter.HospitalManager:
      return SiteUrls.Manager.Notice.Item(id)
    case NoticeFilter.EvixAdmin:
      return SiteUrls.Admin.Notice.Item(id)
  }
}

const getExposedScopeText = (v: GQLNotice) =>
  [v.showOnApp && '환자용 앱', v.showOnDoctor && '의사', v.showOnHospitalManager && '병원 관리자']
    .filter(v => v)
    .join(' / ')

export const NoticeListableTable: React.FC<INoticeListableTableProps> = props => {
  const { filter } = props
  const { Provider, Context } = useODListableContext<GQLNotice, INoticeListableOption>()
  const isAdmin = filter === NoticeFilter.EvixAdmin
  const isManager = filter === NoticeFilter.HospitalManager

  const history = useHistory()

  const dataLoader = useNoticeListDataLoader(OPTIONS_PER_FILTER[filter])

  const toolbarStyle = { display: 'flex', justifyContent: 'space-between', alignItems: 'center' }
  const searchBoxStyle = { flexGrow: 6, maxWidth: 600 }
  const linkStyle = { textDecoration: 'none' }
  const addButtonStyle = { minWidth: 120 }

  const [showPopup, setShowPopup] = React.useState(false)
  const [popupType, setPopupType] = React.useState<GQLPOPUP_TYPE>(GQLPOPUP_TYPE.APP)
  const [noticeId, setNoticeId] = React.useState(-1)

  const wrapperStyle = {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    marginTop: 15,
    borderBottom: 'none',
  }

  const PageContent = styled.div`
    width: 100%;
    padding-bottom: 0;
    display: flex;
    flex-direction: column;
  `

  const itemActive = {
    borderColor: '#7F8694',
    backgroundColor: '#7F8694',
    color: 'white',
  }

  const itemDeactive = {
    borderColor: '#E6E8ED',
    backgroundColor: '#E6E8ED',
    color: '#202223',
  }

  const cardStyle = {
    display: 'flex',
    minWidth: 700,
    background: '#FFFFFF',
    boxShadow: '0px 0px 5px rgba(23, 24, 24, 0.05), 0px 1px 2px rgba(0, 0, 0, 0.15)',
    borderRadius: 8,
    border: 0,
  }

  const DirectionRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
  `

  if (isAdmin || isManager) {
    return (
      <Provider
        dataLoader={dataLoader}
        keyExtractor={v => v.noticeId.toString()}
        pageSize={10}
        onDataLoaderError={WebUtils.showError}
        searchOnLoad
      >
        <PopupSettingModal
          show={showPopup}
          setShow={setShowPopup}
          noticeId={noticeId}
          isApp={popupType === GQLPOPUP_TYPE.APP}
        />
        <PageContainer>
          <Page>
            <PageHeader>
              <PageTitle>공지사항</PageTitle>
              {isAdmin && (
                <div style={toolbarStyle}>
                  <ODListableSearchBox
                    listableContext={Context}
                    placeholder="제목, 내용으로 검색"
                    style={searchBoxStyle}
                  />
                  <ODHSpace w={8} />
                  <Link to={SiteUrls.Admin.Notice.Create} style={linkStyle}>
                    <ODButton theme={ODButtonTheme.Primary} size={ODButtonSize.Small} rounded style={addButtonStyle}>
                      공지사항 추가
                    </ODButton>
                  </Link>
                </div>
              )}
            </PageHeader>
            <PageContent>
              <EvixCard noPadding>
                <ODListablePaginatedTable2
                  numColumns={isAdmin ? 4 : 2}
                  listableContext={Context}
                  tableStyle={{ marginBottom: 0 }}
                  renderHeader={() => (
                    <tr style={{ background: '#F6F8FA', borderBottom: '1px solid #D1D5DA' }}>
                      <th style={{ textAlign: 'center', whiteSpace: 'nowrap', width: '200px' }}>등록시각</th>
                      <th style={{ textAlign: 'center', whiteSpace: 'nowrap' }}>제목</th>
                      {isAdmin && (
                        <th style={{ textAlign: 'center', whiteSpace: 'nowrap', width: '220px' }}>노출 위치</th>
                      )}
                      {isAdmin && (
                        <th style={{ textAlign: 'center', whiteSpace: 'nowrap', width: '100px' }}>팝업설정</th>
                      )}
                      {isAdmin && <th style={{ textAlign: 'center', whiteSpace: 'nowrap', width: '70px' }}>수정</th>}
                    </tr>
                  )}
                  renderRow={(value: GQLNotice, context) => (
                    <StyledTr key={value.noticeId}>
                      <td align="center">
                        <span>{moment(value.createdAt).format('ll')}</span>
                      </td>
                      <td>
                        <Link to={getDetailUrl(filter, value.noticeId)}>{value.title}</Link>
                      </td>
                      {isAdmin && (
                        <td>
                          <span>{getExposedScopeText(value)}</span>
                        </td>
                      )}
                      {isAdmin && (
                        <>
                          <td
                            align="center"
                            style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}
                          >
                            <DirectionRow>
                              <PopupSettingButton
                                onClick={(e: any) => {
                                  setNoticeId(value.noticeId)
                                  setShowPopup(true)
                                  setPopupType(GQLPOPUP_TYPE.APP)
                                  e.stopPropagation()
                                }}
                              >
                                앱
                              </PopupSettingButton>
                              <ODHSpace w={5} />
                              <PopupSettingButton
                                onClick={(e: any) => {
                                  setNoticeId(value.noticeId)
                                  setShowPopup(true)
                                  setPopupType(GQLPOPUP_TYPE.WEB)
                                  e.stopPropagation()
                                }}
                              >
                                웹
                              </PopupSettingButton>
                            </DirectionRow>
                          </td>
                        </>
                      )}
                      {isAdmin && (
                        <td align="center">
                          <Link to={SiteUrls.Admin.Notice.Edit(value.noticeId)}>
                            <IconWrapper onClick={(e: any) => e.stopPropagation()}>
                              <ODIcon icon={ODIcons.CorePencil} />
                            </IconWrapper>
                          </Link>
                        </td>
                      )}
                    </StyledTr>
                  )}
                  eventParentContext={{}}
                />
              </EvixCard>
            </PageContent>
            <ODListablePagination hideIfSinglePage={false} listableContext={Context} wrapperStyle={wrapperStyle} />
          </Page>
        </PageContainer>
      </Provider>
    )
  }

  // doctor
  return (
    <Provider
      dataLoader={dataLoader}
      keyExtractor={v => v.noticeId.toString()}
      pageSize={10}
      onDataLoaderError={WebUtils.showError}
      searchOnLoad
    >
      <PopupSettingModal
        show={showPopup}
        setShow={setShowPopup}
        noticeId={noticeId}
        isApp={popupType === GQLPOPUP_TYPE.APP}
      />
      <PageContainer>
        <Page>
          <PageHeader>
            {!isAdmin && !isManager && <PageTitle>공지사항</PageTitle>}
            {isAdmin && (
              <div style={toolbarStyle}>
                <ODListableSearchBox
                  listableContext={Context}
                  placeholder="제목, 내용으로 검색"
                  style={searchBoxStyle}
                />
                <ODHSpace w={8} />
                <Link to={SiteUrls.Admin.Notice.Create} style={linkStyle}>
                  <ODButton theme={ODButtonTheme.Primary} size={ODButtonSize.Small} rounded style={addButtonStyle}>
                    공지사항 추가
                  </ODButton>
                </Link>
              </div>
            )}
          </PageHeader>
          <PageContent>
            <EvixCard noPadding>
              <ODListablePaginatedTable2
                borderLess={true}
                tableStyle={{ marginBottom: 0, tableLayout: 'auto' }}
                numColumns={isAdmin ? 4 : 2}
                listableContext={Context}
                renderHeader={() => (
                  <tr style={{ background: '#F6F8FA', borderBottom: '1px solid #D1D5DA' }}>
                    <th style={{ textAlign: 'center', whiteSpace: 'nowrap' }}>제목</th>
                    <th style={{ textAlign: 'center', whiteSpace: 'nowrap', width: '200px' }}>등록시각</th>
                    {isAdmin && (
                      <th style={{ textAlign: 'center', whiteSpace: 'nowrap', width: '220px' }}>노출 위치</th>
                    )}
                    {isAdmin && <th style={{ textAlign: 'center', whiteSpace: 'nowrap', width: '100px' }}>팝업설정</th>}
                    {isAdmin && <th style={{ textAlign: 'center', whiteSpace: 'nowrap', width: '70px' }}>수정</th>}
                  </tr>
                )}
                renderRow={(value: GQLNotice, context) => (
                  <StyledTr
                    key={value.noticeId}
                    onClick={() => {
                      if (isManager) {
                        history.push(SiteUrls.Manager.Notice.Item(value.noticeId))
                      } else if (isAdmin) {
                        history.push(SiteUrls.Admin.Notice.Item(value.noticeId))
                      } else {
                        history.push(SiteUrls.Doctor.Notice.Item(value.noticeId))
                      }
                    }}
                  >
                    <td align="left">
                      <Link to={getDetailUrl(filter, value.noticeId)} className="listTitle">
                        {value.title}
                      </Link>
                    </td>
                    <td align="center">
                      <span>{moment(value.createdAt).format('ll')}</span>
                    </td>
                    {isAdmin && (
                      <td align="center">
                        <span>{getExposedScopeText(value)}</span>
                      </td>
                    )}
                    {isAdmin && (
                      <>
                        <td align="center" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                          <PopupSettingButton
                            onClick={(e: any) => {
                              setNoticeId(value.noticeId)
                              setShowPopup(true)
                              setPopupType(GQLPOPUP_TYPE.APP)
                              e.stopPropagation()
                            }}
                          >
                            앱
                          </PopupSettingButton>
                          <ODHSpace w={5} />
                          <PopupSettingButton
                            onClick={(e: any) => {
                              setNoticeId(value.noticeId)
                              setShowPopup(true)
                              setPopupType(GQLPOPUP_TYPE.WEB)
                              e.stopPropagation()
                            }}
                          >
                            웹
                          </PopupSettingButton>
                        </td>
                      </>
                    )}
                    {isAdmin && (
                      <td align="center">
                        <Link to={SiteUrls.Admin.Notice.Edit(value.noticeId)}>
                          <IconWrapper onClick={(e: any) => e.stopPropagation()}>
                            <ODIcon icon={ODIcons.CorePencil} />
                          </IconWrapper>
                        </Link>
                      </td>
                    )}
                  </StyledTr>
                )}
                eventParentContext={{}}
              />
            </EvixCard>
            <ODListablePagination hideIfSinglePage={false} listableContext={Context} wrapperStyle={wrapperStyle} />
          </PageContent>
        </Page>
      </PageContainer>
    </Provider>
  )
}

const IconWrapper = styled.span`
  font-size: 14px;
  letter-spacing: 0.3px;
  color: var(--gray600);
`
const PopupSettingButton = styled.button`
  border: 1px solid;
  padding: 0px 5px;
  border-radius: 5px;
  background-color: white;
`

const StyledTr = styled.tr`
  cursor: pointer;
  height: 44px;
  border-bottom: 1px solid #d1d5da;
  background-color: white;
`
