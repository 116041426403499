import { ICTDoctor } from "../../../common/doctor/DoctorCommon";
import { validator } from "../../../validator";
import { WebUtils } from "../../../utils/webUtils";

export const doctorDiffFuction = (updated: ICTDoctor, original: ICTDoctor) => {
  let ret: Partial<ICTDoctor> = {}

  if (updated.birthDate !== original.birthDate) {
    ret.birthDate = updated.birthDate
    const validationRet = validator.birth(ret?.birthDate || '')
    if (!ret?.birthDate || (validationRet.errors && validationRet.errors.length > 0)) {
      WebUtils.showError(validationRet.errors && validationRet.errors.length > 0 ? validationRet.errors[0].toString() : '')
      return null
    }
  }

  if (updated.licenseName !== original.licenseName) {
    ret.licenseName = updated.licenseName

    const validationRet = validator.doctorCode(ret?.licenseName || '')
    if (!ret?.licenseName || (validationRet.errors && validationRet.errors.length > 0)) {
      WebUtils.showError(validationRet.errors && validationRet.errors.length > 0 ? validationRet.errors[0].toString() : '')
      return null
    }
  }

  Object.keys(original).forEach((key) => {
    // @ts-ignore
    if (updated[key] !== original[key]) {
      // @ts-ignore
      ret[key] = updated[key]
    }
  })

  return ret
}
