import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { SiteUrls } from '../SiteUrls'
import { DoctorAuthorized } from './DoctorAuthorized'
import { DoctorSignUpPage } from './signup/DoctorSignUpPage'
import { DoctorSignUpInfoPage } from './signup/DoctorSignUpInfoPage'
import { EmailAuthentication } from './signup/EmailAuthentication'
import { ChangeEmail } from './signup/ChangeEmail'
import { CompleteSignUp } from './signup/CompleteSignUp'
import { DoctorLoginPage } from './DoctorLoginPage'
import { DoctorLogoutPage } from './DoctorLogoutPage'
import { DoctorMainLayout } from './DoctorMainLayout'
import { EvixRoomProvider } from './reservation/room/context/EvixRoomContext'
import { RoomMainLayoutWrapper } from './reservation/room/RoomMainLayoutWrapper'
import { ODCommonMainLayoutWithoutSideBar, ODGlobal } from "@mdpp/od-react-belt";
import {ChangePasswordContainer} from "../common/ChangePasswordContainer";
import {RESET_ACCOUNT_TYPE} from "@mdpp/common";
import {DoctorHeader} from "./gnb/DoctorHeader";
import { SSEProvider } from "react-hooks-sse";
import { AppOptions, LOCAL_STORAGE_KEY_TOKEN } from "../AppOptions";


export const DoctorRoot: React.FC = () => {
  const sseEndpoint = `${AppOptions.SERVER_ADDRESS}/events?token=${localStorage.getItem(LOCAL_STORAGE_KEY_TOKEN)}&clientType=Doctor`
  return (
    <Switch>
      <Route path={SiteUrls.Doctor.SignUp} component={DoctorSignUpPage} />
      <Route path={SiteUrls.Doctor.SignUpInfo} component={DoctorSignUpInfoPage} />
      <Route path={SiteUrls.Doctor.EmailAuthentication} component={EmailAuthentication} />
      <Route path={SiteUrls.Doctor.ChangeEmail} component={ChangeEmail} />
      <Route path={SiteUrls.Doctor.CompleteSignUp} component={CompleteSignUp} />

      <Route path={SiteUrls.Doctor.Login} component={DoctorLoginPage} />
      <Route path={SiteUrls.Doctor.Logout} component={DoctorLogoutPage} />
      <Route
        path={SiteUrls.Doctor.Reservation.Room(':id')}
        exact
        render={props => {
          return (
            <DoctorAuthorized>
              <EvixRoomProvider>
                <RoomMainLayoutWrapper reservationId={parseInt(props.match.params.id, 10)} />
              </EvixRoomProvider>
            </DoctorAuthorized>
          )
        }}
      />
     <Route
        path={SiteUrls.Doctor.ChangePassword}
        render={(props: any) => (
            <DoctorAuthorized>
                <ODCommonMainLayoutWithoutSideBar renderHeader={() => <DoctorHeader />}>
                    <ChangePasswordContainer accountType={RESET_ACCOUNT_TYPE.DOCTOR} />
                </ODCommonMainLayoutWithoutSideBar>
            </DoctorAuthorized>
        )}
     />
      <Route
        path={SiteUrls.Doctor.Root}
        render={() => {
          return (
            <DoctorAuthorized>
              <SSEProvider endpoint={sseEndpoint}>
                  <DoctorMainLayout/>
              </SSEProvider>
            </DoctorAuthorized>
          )
        }}
      />
      <Redirect to={SiteUrls.Doctor.Dashboard.Main} />
    </Switch>
  )
}
