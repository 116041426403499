import React from 'react'
import { HospitalEditPage } from '../../common/hospital/edit/HospitalEditPage'

interface IAdminHospitalFormPageProps {
  idEditing: number | null
}

export const AdminHospitalEditPage: React.FC<IAdminHospitalFormPageProps> = props => {
  return (
    <div>
      <HospitalEditPage idEditing={props.idEditing} />
    </div>
  )
}
