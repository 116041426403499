import {ODEntity, ODEntityInput, ODEntityLabeled, ODIcon, ODIcons, ODVSpace} from '@mdpp/od-react-belt'
import gql from 'graphql-tag'
import React from 'react'
import {Card, CardBody as reactstrapCardBody} from 'reactstrap'
import styled from 'styled-components/macro'
import { GQLDoctor, GQLDoctorCreationInput, GQLDoctorUpdateInput } from '@mdpp/common/lib/@types/server.schema'
import { ICTDoctor } from '../../common/doctor/DoctorCommon'
import { GQL_CREATE_DOCTOR } from '../../common/gqls_common'
import IconWarningBlue from '../../assets/common/warning-blue.svg'
import { DoctorPrimaryKey } from '../../common/types/DoctorCommon'
import { SiteUrls } from '../../SiteUrls'
import { COLORS } from '../../styles/colors'
import { useODEntityAPI } from '../../utils/odGQLEntityLoader'
import {Page, PageContainer, PageContent, PageHeader, PageTitle} from "../../common/page/Page";
import {EvixCard} from "../../common/card/EvixCard";
import {TableList} from "../../common/list/TableList";

interface IManagerDoctorCreatePageProps {
}

const DEFAULT_DATA: Partial<ICTDoctor> = {
	name: '',
	email: '',
	// 아래 필드는 필수여서 넣도록 하되, 비어 있는 값으로 설정된다.
	introduction: '',
	education: '',
	career: '',
	phone: '',
	hospitalId: 0, // 이 값은 hospital manager 에 의해 호출시 서버에서 자동 결정되므로 0으로 보내도 무방합니다.
}

export const ManagerDoctorCreatePage: React.FC<IManagerDoctorCreatePageProps> = props => {
	const apis = useODEntityAPI<GQLDoctor, ICTDoctor, GQLDoctorCreationInput, GQLDoctorUpdateInput>({
		createGQL: gql(GQL_CREATE_DOCTOR),
		primaryKeyName: DoctorPrimaryKey,
	})

  return (
    <PageContainer>
      <Page>
        <PageHeader>
          <PageTitle>의사 관리</PageTitle>
          {/*[MWD_1] 의사 초대 마크업 주석 처리 - 적용시, 위 의사 관리를 삭제 후, 아래 코드 활성화해주세요.  */}
          {/* <PageTitle>의사 초대</PageTitle> */}
        </PageHeader>
        <PageContent>
            <EvixCard>
                <ODEntity
                    resourceId={null}
                    api={apis}
                    saveButtonName="추가"
                    titleCreation="추가"
                    createSuccessTitle="성공"
                    createSuccessMessage="추가하였습니다."
                    titleUpdate="수정"
                    updateSuccessTitle="성공"
                    updateSuccessMessage="수정하였습니다."
                    defaultCreateClientData={DEFAULT_DATA}
                    urlAfterCreation={d => SiteUrls.Manager.Doctor.Edit(d.doctorId)}
                    noCardWrap
                    footerProps={{
                      deleteButtonStyle: {
                        borderColor: COLORS.DANGER,
                        color: COLORS.DANGER,
                        backgroundColor: COLORS.RED10,
                      },
                    }}
                >
                  <TableListWrapper>
                    <TableList name="name" label="이름" >
                      <ODEntityInput
                          keyPath="name"
                          label="이름"
                          name="name"
                          placeholder="이름을 입력해주세요."
                          inputType="text"
                      />
                    </TableList>
                    <TableList name="email" label="이메일" >
                      <ODEntityInput
                          keyPath="email"
                          label="이메일"
                          name="email"
                          placeholder="이메일을 입력해주세요."
                          inputType="text"
                      />

                      {/*[MWD_1] 의사 초대 마크업 주석 처리 - 적용시, 아래 코드 활성화해주세요.  */}
                      {/* <MessageWrap>
                        <IconWarning src={IconWarningBlue} />
                        <MessageText>입력하신 이메일 주소로 초청 메일을 전송합니다.</MessageText>
                      </MessageWrap> */}
                    </TableList>


                    <TableList name="resetEmail" label="비밀번호" >
                      <ResetWrapper>
                        <InfoIconWrapper>
                          <ODIcon icon={ODIcons.MaterialError} />
                        </InfoIconWrapper>
                        <ResetDescription>자동생성 후 이메일로 로그인정보를 전송</ResetDescription>
                      </ResetWrapper>
                    </TableList>
                    <hr />
                  </TableListWrapper>
                </ODEntity>
              <ODVSpace h={30} />
            </EvixCard>
        </PageContent>
      </Page>
    </PageContainer>
  )
}

const MessageWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 5px;
  padding-top: 10px;
`

const IconWarning = styled.img`
  width: 13px;
  height: 13px;
`

const MessageText = styled.div`
  font-size: 13px;
  font-weight: 500;
  font-style: normal;
  line-height: 1.85;
  letter-spacing: normal;
  color: ${COLORS.GRAY700};
`

const ResetWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const ResetDescription = styled.span`
  margin-left: 5px;
  margin-top: 10px;
  font-size: 12px;
  color: var(--gray600);
`

const InfoIconWrapper = styled.div`
  color: var(--mpPrimary500);
  transform: scale(0.7);
  margin-top: 10px;
`


const TableListWrapper = styled.div`
    gap: 24px;
    display:flex;
    flex-direction: column;
`
