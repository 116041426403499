import { ODIcon, ODIcons, ODModal, ODModalSize, ODVSpace } from '@mdpp/od-react-belt'
import React, { useEffect } from 'react'
import { Moment } from 'moment'
import moment from 'moment/moment'
import styled from 'styled-components/macro'
import {
  GQLPOPUP_TYPE,
  GQLPopupInfoCreationInput,
  GQLPopupInfoDeleteInput,
  GQLPopupInfoUpdateInput,
} from '@mdpp/common/lib/@types/server.schema'
import { DatePicker } from '../../../common/DatePicker'
import { useCommonAPIs } from '../../../../src/common/hooks/useCommonAPIs'

interface IPopupSettingModalPopupProps {
  isOpen: boolean
  onClose: () => void
  onCreate: (input: GQLPopupInfoCreationInput) => void
  onUpdate: (input: GQLPopupInfoUpdateInput) => void
  onDelete: (input: GQLPopupInfoDeleteInput) => void
  noticeId: number
  refresh: (input: boolean) => void
}

export const PopupSettingModalPopup: React.FC<IPopupSettingModalPopupProps> = props => {
  const { isOpen, onClose, onCreate, onUpdate, onDelete, noticeId, refresh } = props
  const { getPopupInfo } = useCommonAPIs()
  
  useEffect(() => {
    const updatePopupInfo = async () => {
      try {
        const p = await getPopupInfo({noticeId,  type: GQLPOPUP_TYPE.APP})
        if (p) {
          setPopupId(p.popupId)
          setContent(p.content)
          setTitle(p.title)
          setPostingStart(p.postingStart)
          setPostingEnd(p.postingEnd)
          setOnetime(p.onetime)
          setDateRange([moment(p.postingStart).format('YYYY-MM-DD'), moment(p.postingEnd).format('YYYY-MM-DD')])
          setPExists(true)
        }
      } catch (e) {
        console.log('popupinfo is not exists')
        setPExists(false)
      }
    }

    updatePopupInfo()
  }, [])
  
  const [pExists, setPExists] = React.useState(false)
  const [content, setContent] = React.useState('')
  const [title, setTitle] = React.useState('')
  const [postingStart, setPostingStart] = React.useState(null)
  const [postingEnd, setPostingEnd] = React.useState(null)
  const [onetime, setOnetime] = React.useState(false)

  const initStart = moment().add(1, 'day').format('YYYY-MM-DD')
  const initEnd = moment().add(2, 'week').format('YYYY-MM-DD')
  const initDateRange = [postingStart || initStart, postingEnd || initEnd]
  const [dateRange, setDateRange] = React.useState(initDateRange)
  const [popupId, setPopupId] = React.useState<number | undefined>(undefined)

  const clickSave: React.MouseEventHandler<HTMLButtonElement> = (e: any) => {
    e.preventDefault()
    onCreate({ noticeId: noticeId, content, title, postingStart, postingEnd,
      onetime: onetime, type: GQLPOPUP_TYPE.APP, file : null})
    refresh(true)
  }

  
  const clickUpdate: React.MouseEventHandler<HTMLButtonElement> = (e: any) => {
    e.preventDefault()
    if (popupId)
      onUpdate({ popupId, content, title, postingStart, postingEnd, onetime, file : null })
    refresh(true)
  }

  const clickDelete: React.MouseEventHandler<HTMLButtonElement> = (e: any) => {
    e.preventDefault()
    if (popupId)
      onDelete({ popupId})
    refresh(true)
  }

  const isBeforeDay = (a : Moment, b : Moment) => {
    if (!moment.isMoment(a) || !moment.isMoment(b)) return false;
    
    const aYear = a.year();
    const aMonth = a.month();
    
    const bYear = b.year();
    const bMonth = b.month();
    
    const isSameYear = aYear === bYear;
    const isSameMonth = aMonth === bMonth;
    
    if (isSameYear && isSameMonth) return a.date() < b.date();
    if (isSameYear) return aMonth < bMonth;
    return aYear < bYear;
  }

  const isInclusivelyAfterDay = (a : Moment, b : Moment) => {
    if (!moment.isMoment(a) || !moment.isMoment(b)) return false;
    return !isBeforeDay(a, b);
  }

  const updateDateRange = (ds: any) => {
    if (ds && ds.length > 1) {
      setPostingStart(ds[0])
      setPostingEnd(ds[1])
    }
  }

  return (
    <ODModal isOpen={isOpen} toggle={onClose} size={ODModalSize.Large}>
      <Wrapper>
        <ODVSpace h={44} />
        <Title>팝업 설정</Title>
        <Subtitle>게시기간이 지나면 팝업은 자동 OFF 됩니다.</Subtitle>
        <CloseButtonBox onClick={onClose}>
          <ODIcon icon={ODIcons.CoreX} style={CloseIconStyle} />
        </CloseButtonBox>
        <Inner>
          <ODVSpace h={28} />
          <InputTitle>
            팝업창 문구
            <ODVSpace h={9} />
            <InputField value={content} onChange={e => {
              if (e.target.value.length <= 500) {
                setContent(e.target.value)
              }
            }} style={content.length === 0 ? {borderColor: '#ecaeae', width: 600} : {width: 600}}
            placeholder='팝업창에 안내할 문구를 입력해주세요'
            />
          </InputTitle>
          <ODVSpace h={20} />
          <InputTitle>
            유도버튼 라벨
            <ODVSpace h={9} />
            <InputField value={title} onChange={e => {
              if (e.target.value.length <= 10) {
                setTitle(e.target.value)
              }
            }} style={title.length === 0 ? {borderColor: '#ecaeae', width: 300} : {width: 300}}
              placeholder='버튼명을 입력해주세요'
            />
          </InputTitle>
          <ODVSpace h={20} />
          <InputTitle>
            게시기간
            <ODVSpace h={9} />
            <DateWrapper>
              <DatePicker dateRange={dateRange} onChange={updateDateRange} isOutsideRange={(day) => !isInclusivelyAfterDay(day, moment())}></DatePicker>
            </DateWrapper>
          </InputTitle>

          <ODVSpace h={20} />
          <Checkbox type="checkbox" checked={onetime} onChange={() => (setOnetime(!onetime))}></Checkbox>
          <CheckboxLabel>
            1회만 노출
          </CheckboxLabel>
        </Inner>
        <Footer>
          <SaveButton onClick={ clickDelete } disabled={ !pExists }>삭제</SaveButton>
          <SaveButton onClick={ !pExists ? clickSave : clickUpdate } disabled={ content.length === 0 || title.length === 0 }>{ !pExists ? '설정 생성' : '설정 수정' }</SaveButton>
        </Footer>
      </Wrapper>
    </ODModal>
  )
}

const CheckboxLabel = styled.label`
  line-height: 18px;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.26px;
  color: var(--gray600);
  margin: 0;
  margin-left: 5px;
`

const CloseIconStyle = {
  fontSize: 18,
  color: '#dbdbdb',
}
const Wrapper = styled.form`
  background-color: var(--white);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  position: relative;
  padding-left: 40px;
`
const Inner = styled.div`
  padding-right: 40px;
`
const Title = styled.h1`
  line-height: 29px;
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 0.29px;
`

const Subtitle = styled.h6`
  color: #ababab;
`

const CloseButtonBox = styled.div`
  position: absolute;
  top: 25px;
  right: 25px;
  cursor: pointer;
`
const InputTitle = styled.label`
  line-height: 18px;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.26px;
  color: var(--gray600);
  margin: 0;
  width: 100%;
`
const Footer = styled.div`
  display: flex;
  justify-content: space-evenly;
  padding-bottom: 40px;
`

const SaveButton = styled.button`
  width: 100px;
  height: 40x;
  padding: 15px 0 13px 3px;
  background-color: var(--mpPrimary500);
  font-size: 15px;
  line-height: 13px;
  color: var(--white);
  letter-spacing: 0.21px;
  text-align: center;
  border-radius: 10px;

  &:disabled {
    background-color: lightgray;
  }
`

const InputField = styled.input`
  width: 100%;
  padding: 7px 15px 7px 9px;
  border-radius: 4px;
  border: solid 1px var(--gray300);
  background-color: var(--white);
`

const Checkbox = styled.input`

`

const DateWrapper = styled.div`
  input {
    border: 1px solid #ababab;
    font-size: 16px;
    height: 32px;
    border-radius: 5px;
  }
`
