import React from 'react'
import styled from 'styled-components'
import {ODIcons, ODVSpace} from '@mdpp/od-react-belt'
import { GQLPatient, GQLSMOKE_HISTORY_TYPE } from '@mdpp/common/lib/@types/server.schema'
import { EvixCardHeader } from '../../../../common/card/EvixCardHeader'
import { EvixCardLabel } from '../../../../common/card/EvixCardLabel'
import { EvixCardRow } from '../../../../common/card/EvixCardRow'
import { EvixCardRowContent } from '../../../../common/card/EvixCardRowContent'
import { EvixCardRowMultilineContents } from '../../../../common/card/EvixCardRowMultilineContents'
import { ReservationBasicInfoBoxStyle } from '../../../../common/reservation/ReservationCommon'
import { WebUtils } from '../../../../utils/webUtils'
import { EvixCardRowNo } from '../../../../common/card/EvixCardRowNo'
import { EvixCardTwoThirds } from "../../../../common/card/EvixCardTwoThirds";

interface IPatientHealthInfoCardProps {
  patient: GQLPatient
  infoStyle: ReservationBasicInfoBoxStyle
  isFinishedMedical?: boolean
}

const prependDash = (s: string) => `- ${s}`

export const PatientHealthInfoCard: React.FC<IPatientHealthInfoCardProps> = props => {
  const { patient, infoStyle, isFinishedMedical } = props

  const medicineHistories = (patient.medicineHistory ?? '')
    .split(',')
    .map(v => v.trim())
    .filter(v => !!v)

  const operations = (patient.hospitalOperation ?? '')
    .split(',')
    .map(v => v.trim())
    .filter(v => !!v)

  const histories = (patient.pastHistory ?? '')
    .split(',')
    .map(v => v.trim())
    .filter(v => !!v)

  const doesDrink = patient.doesDrink
  const drinkingMain = patient.doesDrink ? '술을 마심' : '술을 마시지 않음'
  const drinkingSub = patient.doesDrink
    ? [WebUtils.formatDrinkingFrequency(patient), WebUtils.formatDrinkingSize(patient)].map(prependDash)
    : []

  const doesSmoke = patient.smokeHistory === GQLSMOKE_HISTORY_TYPE.SMOKING
  const smokingMain = WebUtils.formatSmokingMainText(patient)
  const smokingSub =
    patient.smokeHistory === GQLSMOKE_HISTORY_TYPE.SMOKING
      ? [WebUtils.formatSmokingDuration(patient), WebUtils.formatSmokingSize(patient)].map(prependDash)
      : []

  const isInsideRoom = infoStyle === ReservationBasicInfoBoxStyle.InsideRoom
  const cardStyle = isInsideRoom ? { border: 0, width: '100%' } : {
      display: 'flex',
      background: '#FFFFFF',
      boxShadow: '0px 0px 5px rgba(23, 24, 24, 0.05), 0px 1px 2px rgba(0, 0, 0, 0.15)',
      borderRadius: 8,
      border: 0,
  }
  const bedIcon = isInsideRoom ? null : ODIcons.CoreBed

  return (
    <EvixCardTwoThirds style={{ flexGrow: 0, flexShrink: 1, ...cardStyle }}>
        {isInsideRoom ?
            <EvixCardHeader icon={bedIcon} title="건강정보" isInsideRoom /> :
            <EvixCardHeader icon={bedIcon} title="건강정보"  />
        }
      <ODVSpace h={30} />
      <TableListWrapper>
          <EvixCardRow>
              <EvixCardLabel>복용약물</EvixCardLabel>
              <Wrapper>
                  {medicineHistories.length > 0 && (
                      <EvixCardRowMultilineContents
                          mainText="있음"
                          subTexts={medicineHistories.map(v => `- ${v}`)}
                          subTextPrimaryColor={true}
                      />
                  )}
                  {medicineHistories.length === 0 && (
                      <EvixCardRowMultilineContents key="none" mainText="없음" subTexts={[]} subTextPrimaryColor={false} />
                  )}
              </Wrapper>
          </EvixCardRow>
          <EvixCardRow>
              <EvixCardLabel>수술 이력</EvixCardLabel>
              <Wrapper>
                  {operations.length > 0 && (
                      <EvixCardRowMultilineContents
                          mainText="있음"
                          subTexts={operations.map(v => `- ${v}`)}
                          subTextPrimaryColor={true}
                      />
                  )}
                  {operations.length === 0 && (
                      <EvixCardRowMultilineContents key="none" mainText="없음" subTexts={[]} subTextPrimaryColor={false} />
                  )}
              </Wrapper>
          </EvixCardRow>
          <EvixCardRow>
              <EvixCardLabel>과거병력</EvixCardLabel>
              <EvixCardRowContent>
                  {histories.length > 0 && (
                      <EvixCardRowMultilineContents
                          mainText="있음"
                          subTexts={histories.map(v => `- ${v}`)}
                          subTextPrimaryColor={true}
                      />
                  )}
                  {histories.length === 0 && (
                      <EvixCardRowMultilineContents key="none" mainText="없음" subTexts={[]} subTextPrimaryColor={false} />
                  )}
              </EvixCardRowContent>
          </EvixCardRow>
          <EvixCardRow>
              <EvixCardLabel>음주</EvixCardLabel>
              {!doesDrink && <EvixCardRowNo>술을 마시지 않음</EvixCardRowNo>}
              {doesDrink && (
                  <EvixCardRowMultilineContents
                      mainText={drinkingMain}
                      subTexts={drinkingSub}
                      subTextPrimaryColor={doesDrink}
                  />
              )}
          </EvixCardRow>
          <EvixCardRow>
              <EvixCardLabel>흡연</EvixCardLabel>
              {!doesSmoke && <EvixCardRowNo>{smokingMain}</EvixCardRowNo>}
              {doesSmoke && (
                  <EvixCardRowMultilineContents mainText={smokingMain} subTexts={smokingSub} subTextPrimaryColor={doesSmoke} />
              )}
          </EvixCardRow>
      </TableListWrapper>
    </EvixCardTwoThirds>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`
const TableListWrapper = styled.div`
    gap: 24px;
    display:flex;
    flex-direction: column;
`
