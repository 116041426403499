import React, { useEffect } from 'react'
import { Card, CardBody } from 'reactstrap'
import { GQLDoctor } from '@mdpp/common/lib/@types/server.schema'
import { DoctorProfileDiagnosisEdit } from '../../../common/doctor/detail/DoctorProfileDiagnosisEdit'
import { useCommonAPIs } from '../../../common/hooks/useCommonAPIs'
import { useDoctorAPIs } from '../../../doctor/context/useDoctorAPIs'
import { useAdminAppContext } from '../../context/AdminAppContext'
import { useManagerAppContext } from '../../../manager/context/ManagerAppContext'
import { EvixCard } from '../../../common/card/EvixCard'

interface IAdminDoctorProfileDiagnosisPageProps {
  doctorId: number
}

export const AdminDoctorProfileDiagnosisPage: React.FC<IAdminDoctorProfileDiagnosisPageProps> = props => {
  const { doctorId } = props
  const { upsertDoctorHasMedicalSubject } = useDoctorAPIs()
  const { getDoctor, updateDoctor } = useCommonAPIs()

  const [profile, setProfile] = React.useState<GQLDoctor>()
  useEffect(() => {
    getDoctor({ id: doctorId }).then(doctor => {
      setProfile(doctor)
    })
  }, [doctorId])

  const updateDoctorData = async ({
    medicalSubjectIds,
    profession,
    availableFirstVisit,
    untactTreatment,
  }: {
    medicalSubjectIds: number[]
    profession: string
    availableFirstVisit: boolean
    untactTreatment: boolean
  }): Promise<void> => {
    await upsertDoctorHasMedicalSubject({ doctorId, medicalSubjectIds })
    if (profile && updateDoctor) {
      const newDoctor = await updateDoctor({ id: doctorId, profession, availableFirstVisit, untactTreatment })
      console.log(newDoctor)
      setProfile(newDoctor)
    }
  }

  return (
    <>
      <EvixCard>
        <DoctorProfileDiagnosisEdit updateData={updateDoctorData} profile={profile} />
      </EvixCard>
    </>
  )
}
