import { ODListableOption } from '@mdpp/od-react-belt'
import { GQLFaq } from '@mdpp/common/lib/@types/server.schema'

export const FaqPrimaryKey = 'faqId'

// modifiable fields only.
export interface ICTFaq extends Pick<GQLFaq, 'faqId' | 'title' | 'link' | 'priority'> {
  // more fields, mapped (if needed)
}

export interface IFaqListableOption extends ODListableOption {
  // filter: string
}
