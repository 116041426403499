import { ODListableResponseType } from '@mdpp/od-react-belt'
import React from 'react'
import { GQLMedicalSubject } from '@mdpp/common/lib/@types/server.schema'
import { useCommonAPIs } from '../../hooks/useCommonAPIs'
import { IMedicalSubjectListableOption } from '../MedicalSubjectCommon'

export function useMedicalSubjectListDataLoader() {
  const { listMedicalSubjects } = useCommonAPIs()
  return React.useCallback(
    async function dataLoader(
      page: number,
      pageSize: number,
      afterKey: string | null,
      options: IMedicalSubjectListableOption
    ): Promise<ODListableResponseType<GQLMedicalSubject>> {
      const r = await listMedicalSubjects({ page, pageSize, ...options })
      return r as ODListableResponseType<GQLMedicalSubject>
    },
    [listMedicalSubjects]
  )
}
