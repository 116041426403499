import { ErrorCode, UPDATE_TYPE } from '@mdpp/common'
import { ODWebUtils } from '@mdpp/od-react-belt'
import { isString } from 'lodash'
import moment from 'moment'
import { ToastOptions } from 'react-toastify'
import {
  GQLDOCTOR_STATUS,
  GQLDRINK_DURATION_TYPE,
  GQLDRINK_SIZE,
  GQLDRINK_TYPE,
  GQLMEDICATION_CATEGORY,
  GQLMEDICATION_DISPENSING_STATUS,
  GQLPatient,
  GQLRESERVATION_STATUS,
  GQLSMOKE_AMOUNT_TYPE,
  GQLSMOKE_HISTORY_TYPE,
  GQLSMOKE_PERIOD_TYPE,
  GQLUPDATE_TYPE,
} from '@mdpp/common/lib/@types/server.schema'
import { AppOptions } from '../AppOptions'

export const RESERVATION_STATUS_TEXT_DETAIL = {
  [GQLRESERVATION_STATUS.PENDING]: '진료예약',
  [GQLRESERVATION_STATUS.WAITING]: '진료대기',
  [GQLRESERVATION_STATUS.RECEIPTED]: '진료접수',
  [GQLRESERVATION_STATUS.READY]: '준비완료',
  [GQLRESERVATION_STATUS.IN_PROGRESS]: '진료중',
  [GQLRESERVATION_STATUS.PRESCRIPTION]: '처방중',
  [GQLRESERVATION_STATUS.FINISHED]: '진료완료',
  [GQLRESERVATION_STATUS.DOCTOR_CANCEL]: '취소 (의사)',
  [GQLRESERVATION_STATUS.PATIENT_CANCEL]: '취소 (환자)',
  [GQLRESERVATION_STATUS.DOCTOR_NO_SHOW]: '부도 (의사)',
  [GQLRESERVATION_STATUS.PATIENT_NO_SHOW]: '부도 (환자)',
}

export const RESERVATION_STATUS_TEXT = {
  [GQLRESERVATION_STATUS.PENDING]: '진료예약',
  [GQLRESERVATION_STATUS.WAITING]: '진료대기',
  [GQLRESERVATION_STATUS.RECEIPTED]: '진료접수',
  [GQLRESERVATION_STATUS.READY]: '준비완료',
  [GQLRESERVATION_STATUS.IN_PROGRESS]: '진료중',
  [GQLRESERVATION_STATUS.PRESCRIPTION]: '처방중',
  [GQLRESERVATION_STATUS.FINISHED]: '진료완료',
  [GQLRESERVATION_STATUS.DOCTOR_CANCEL]: '병원취소',
  [GQLRESERVATION_STATUS.PATIENT_CANCEL]: '환자취소',
  [GQLRESERVATION_STATUS.DOCTOR_NO_SHOW]: '의사부도',
  [GQLRESERVATION_STATUS.PATIENT_NO_SHOW]: '환자부도',
}

export const PHARMACY_RESERVATION_STATUS_TEXT_DETAIL = {
  [GQLMEDICATION_DISPENSING_STATUS.REQUESTED]: '조제신청',
  [GQLMEDICATION_DISPENSING_STATUS.ACCEPTED]: '조제승인',
  [GQLMEDICATION_DISPENSING_STATUS.REFUSED]: '조제거절',
  [GQLMEDICATION_DISPENSING_STATUS.CANCELED]: '조제취소',
  [GQLMEDICATION_DISPENSING_STATUS.PAIED]: '결제완료',
  [GQLMEDICATION_DISPENSING_STATUS.NOT_PAIED]: '결제실패',
  [GQLMEDICATION_DISPENSING_STATUS.FINISHED]: '결제완료',
  [GQLMEDICATION_DISPENSING_STATUS.FAX_FINISHED]: '처방전 FAX 전송',
}

export const PHARMACY_RESERVATION_STATUS_TEXT = {
  [GQLMEDICATION_DISPENSING_STATUS.REQUESTED]: '조제신청',
  [GQLMEDICATION_DISPENSING_STATUS.ACCEPTED]: '조제승인',
  [GQLMEDICATION_DISPENSING_STATUS.REFUSED]: '조제거절',
  [GQLMEDICATION_DISPENSING_STATUS.CANCELED]: '조제취소',
  [GQLMEDICATION_DISPENSING_STATUS.PAIED]: '결제완료',
  [GQLMEDICATION_DISPENSING_STATUS.NOT_PAIED]: '결제실패',
  [GQLMEDICATION_DISPENSING_STATUS.FINISHED]: '결제완료',
  [GQLMEDICATION_DISPENSING_STATUS.FAX_FINISHED]: '처방전 FAX 전송',
}

const DRINK_DURATION_TYPE_TEXT = {
  [GQLDRINK_DURATION_TYPE.PER_WEEK]: '주',
  [GQLDRINK_DURATION_TYPE.PER_MONTH]: '달',
  [GQLDRINK_DURATION_TYPE.PER_YEAR]: '년',
}

const DRINK_TYPE_TEXT = {
  [GQLDRINK_TYPE.BEER]: '맥주',
  [GQLDRINK_TYPE.SOJU]: '소주',
  [GQLDRINK_TYPE.WESTERN]: '양주',
}

const DRINK_SIZE_TYPE_TEXT = {
  [GQLDRINK_SIZE.BOTTLE]: '병',
  [GQLDRINK_SIZE.SOJUJAN]: '잔',
  [GQLDRINK_SIZE.CAN]: '캔',
}

const SMOKING_HISTORY_TEXT = {
  [GQLSMOKE_HISTORY_TYPE.NEVER]: '흡연을 하지 않음.',
  [GQLSMOKE_HISTORY_TYPE.QUIT]: '흡연을 했었지만 끊었음.',
  [GQLSMOKE_HISTORY_TYPE.SMOKING]: '흡연을 하고 있음.',
}

const MEDICATION_CATEGORY_TEXT = {
  [GQLMEDICATION_CATEGORY.GOHYULAP]: '고혈압',
  [GQLMEDICATION_CATEGORY.DANGNYO]: '당뇨',
  [GQLMEDICATION_CATEGORY.PYEGYULHAEK]: '폐결핵',
  [GQLMEDICATION_CATEGORY.GANYEOM]: '간염',
  [GQLMEDICATION_CATEGORY.GOJIHYUL]: '고지혈증',
  [GQLMEDICATION_CATEGORY.NUIJOLJEUNG]: '뇌졸중',
  [GQLMEDICATION_CATEGORY.SIMJANG]: '심장질환',
  [GQLMEDICATION_CATEGORY.ETC]: '기타',
}

const SMOKE_DURATION_TEXT = {
  [GQLSMOKE_PERIOD_TYPE.LESS_THAN_FIVE_YEARS]: '5년 미만',
  [GQLSMOKE_PERIOD_TYPE.FIVE_TO_TEN_YEARS]: '5년 ~ 10년',
  [GQLSMOKE_PERIOD_TYPE.OVER_THAN_TEN_YEARS]: '10년 이상',
}

const SMOKE_AMOUNT_TEXT = {
  [GQLSMOKE_AMOUNT_TYPE.LESS_THAN_HALF_PACK]: '반갑 이하',
  [GQLSMOKE_AMOUNT_TYPE.A_PACK_TO_TWO_PACKS]: '1~2갑',
  [GQLSMOKE_AMOUNT_TYPE.HALF_TO_A_PACK]: '반갑~한갑',
  [GQLSMOKE_AMOUNT_TYPE.OVER_THAN_TWO_PACKS]: '2갑 이상',
}

const DOCTOR_STATUS_TEXT = {
  [GQLDOCTOR_STATUS.NORMAL]: '의사',
  [GQLDOCTOR_STATUS.PENDING]: '병원관리자 승인 대기',
  [GQLDOCTOR_STATUS.REJECTED]: '병원관리자 승인 거절',
  [GQLDOCTOR_STATUS.DELETE_REQUESTED]: '의사 탈퇴요청',
  [GQLDOCTOR_STATUS.DELETED]: '의사 탈퇴 완료',
}

const UPDATE_TYPE_STRING = {
  [GQLUPDATE_TYPE.NO]: '업데이트 알림 없음',
  [GQLUPDATE_TYPE.OPTIONAL]: '선택 업데이트',
  [GQLUPDATE_TYPE.REQUIRED]: '필수 업데이트',
}

export enum OS {
  MAC = 'macintosh',
  WIN = 'windows',
  ETC = 'etc',
}

export const ERROR_TYPE = {
  BROWSER: 'Permission denied',
  SYSTEM: 'Permission denied by system',
  NONE: 'ok',
}

export const PAY_MIN = 100
export const PAY_MAX = 5000000

export const WebUtils = {
  ...ODWebUtils,
  /**
   * 오류의 종류에 따라서 적절하게 오류를 보여준다. 해당 컨테이너/컴포넌트에서 자체 오류 처리가 없는
   * 경우 전역적으로 활용한다.
   */
  showError(error: Error | string, title: string = '', options: Partial<ToastOptions> = {}) {
    try {
      const msg = (isString(error) ? error : WebUtils.parseErrorMessage(error) || '') as string
      const errorCode = parseInt(msg.split(' ')[0].split(AppOptions.SERVER_DEFINED_ERROR_PREFIX)[1], 10)
      if (errorCode === ErrorCode.SERVER_UNAUTHORIZED) {
        ODWebUtils.showError('세션이 만료되었거나 로그아웃되었습니다.')
        setTimeout(() => {
          window.location.href = '/'
        }, 2000)
        return
      }
    } catch (ex) {
      //
    }

    return ODWebUtils.showError(error, title, options)
  },
  formatReservationTime(time: Date) {
    // @ts-ignore
    return moment(time).format('YYYY.MM.DD (ddd) HH:mm')
  },
  formatReservationStatus(status: GQLRESERVATION_STATUS, specific: boolean = false) {
    if (specific) {
      return RESERVATION_STATUS_TEXT_DETAIL[status]
    }
    return RESERVATION_STATUS_TEXT[status]
  },
  formatReservationPhramaStatus(status: GQLMEDICATION_DISPENSING_STATUS, specific: boolean = false) {
    if (specific) {
      return PHARMACY_RESERVATION_STATUS_TEXT_DETAIL[status]
    }
    return PHARMACY_RESERVATION_STATUS_TEXT[status]
  },
  checkIfMaleFromRRN(rrn: string) {
    return parseInt(rrn.split('-')[0] ?? '1', 10) % 2 === 1
  },
  formatDrinkingFrequency(patient: GQLPatient) {
    return `1${DRINK_DURATION_TYPE_TEXT[patient.drinkDuration!]} ${patient.drinkFrequency ?? 0}회`
  },
  formatDrinkingSize(patient: GQLPatient) {
    return `${DRINK_TYPE_TEXT[patient.drinkType!]} ${patient.drinkSize ?? 0}${
      DRINK_SIZE_TYPE_TEXT[patient.drinkSizeType!]
    }`
  },
  formatSmokingMainText(patient: GQLPatient) {
    return patient.smokeHistory ? SMOKING_HISTORY_TEXT[patient.smokeHistory] : SMOKING_HISTORY_TEXT['NEVER']
  },
  formatSmokingDuration(patient: GQLPatient) {
    return patient.smokePeriodType ? SMOKE_DURATION_TEXT[patient.smokePeriodType] : '-'
  },
  formatSmokingSize(patient: GQLPatient) {
    return patient.smokeAmountType ? SMOKE_AMOUNT_TEXT[patient.smokeAmountType] : '-'
  },
  formatMedicationCategory(category: GQLMEDICATION_CATEGORY) {
    return MEDICATION_CATEGORY_TEXT[category]
  },
  formatDoctorStatus(status: GQLDOCTOR_STATUS) {
    return DOCTOR_STATUS_TEXT[status]
  },
  formatDateTime(m: moment.Moment | string) {
    if (isString(m)) {
      return moment(m).format('ll')
    }
    return m.format('ll')
  },
  formatPatientNameForReview(name: string) {
    return `${name.substr(0, 1)}**`
  },
  formatAppVersionUpdateType(updateType: GQLUPDATE_TYPE | UPDATE_TYPE) {
    return UPDATE_TYPE_STRING[updateType] ?? updateType.toString()
  },
  isGivenTimeAvailableForReservation(givenTime: Date) {
    return moment().add(30, 'minutes').isSameOrBefore(moment(givenTime))
  },
  getOS() {
    const agent = navigator.userAgent

    console.log('Check os: ', agent)

    if (agent.toLowerCase().includes('macintosh')) {
      return OS.MAC
    } else if (agent.toLowerCase().includes('windows')) {
      return OS.WIN
    } else {
      return OS.ETC
    }
  },
}

export const getDeviceState = async () => {
  let state = ERROR_TYPE.NONE
  const md = navigator.mediaDevices
  // console.log('MediaDevices: ', md)
  if (md) {
    try {
      const stream = await md.getUserMedia({ audio: true, video: true })
    } catch (e: any) {
      console.log('error: ', e, e.message)
      if (e.message.includes(ERROR_TYPE.SYSTEM)) {
        state = ERROR_TYPE.SYSTEM
      } else {
        state = ERROR_TYPE.BROWSER
      }
    }
  } else {
    console.log('localhost가 아닌 경우 https가 아니면 mediaDevices를 사용할 수 없어 체크 불가')
  }

  return state
}

export const addressParser = (patientAddressJson: string) => {
  if (!patientAddressJson) {
    return
  } else {
    const obj = JSON.parse(patientAddressJson!)
    const allAddress = obj.address.concat(' ', obj.addressDetail, ' ', obj.zipCode)

    return allAddress
  }
}

export const addressMemo = (patientAddressJson: string) => {
  if (!patientAddressJson) {
    return
  } else {
    const obj = JSON.parse(patientAddressJson!)
    return obj.memo
  }
}

export const numSorting = (num: string | null) => {
  let result
  if (num) {
    // * 숫자만 있을 경우 하이픈 추가
    if (num.substr(0, 2) === '02') {
      // * 02(서울)
      if (num.substr(2).length === 7) {
        result = num.substr(0, 2) + '-' + num.substr(2, 3) + '-' + num.substr(5)
      } else if (num.substr(2).length === 8) {
        result = num.substr(0, 2) + '-' + num.substr(2, 4) + '-' + num.substr(6)
      }
      return result
    } else {
      // * 서울 외 지역
      if (num.substr(3).length === 7) {
        result = num.substr(0, 3) + '-' + num.substr(3, 3) + '-' + num.substr(6)
      } else {
        // 인터넷 전화등등의 경우
        result = num.substr(0, 3) + '-' + num.substr(3, 4) + '-' + num.substr(7)
      }
      return result
    }
  }
}

export const familyGender = (gender: string | null) => {
  if (Number(gender) % 2 === 0) {
    return '여'
  }
  return '남'
}
