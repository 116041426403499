import { I_OD_ADMIN_OPTIONS, ODWebUtils } from '@mdpp/od-react-belt'

// tslint:disable-next-line:interface-name
export interface I_ADMIN_OPTIONS extends I_OD_ADMIN_OPTIONS {
  AGORA_APP_ID: string
  SUPPORT_EMAIL: string
  FIREBASE_APIKEY?: string | null
  FIREBASE_AUTH_DOMAIN?: string | null
  FIREBASE_PROJECT_ID?: string | null
  FIREBASE_STORAGE_BUCKET?: string | null
  FIREBASE_MESSAGING_SENDER_ID?: string | null
  FIREBASE_APP_ID?: string | null
  FIREBASE_MEASUREMENT_ID?: string | null
  FIREBASE_VAPID_KEY?: string | null
  API_DATA_KEY?: string | null
  CHANNEL_TALK_DOCTOR_PLUGIN_KEY?: string | null
  CHANNEL_TALK_PHARMACY_PLUGIN_KEY?: string | null
}

const LocalAppOptions: I_ADMIN_OPTIONS = {
  SERVER_ADDRESS: 'http://localhost:8080',
  // SERVER_ADDRESS: 'https://api.dev.medi-pangpang.kr',
  WS_SERVER_ADDRESS: '',
  SHOW_BUILD_INFO: true,
  SIMULATE_DELAY: 0,
  FILL_MOCK_DATA: true,
  LOCAL_STORAGE_KEY_TOKEN: 'token',
  SERVER_DEFINED_ERROR_PREFIX: 'OE-',
  AGORA_APP_ID: '60611a6424bf446587ff206ea2ed2590',
  SUPPORT_EMAIL: 'test.001@evidnet.co.kr',
  API_DATA_KEY: 'F8TY9JmU%2BqZJbvB1OCaHLouHMONT6NNb%2FbbKeqZ9%2FDXcZ2jmbPVc5MFfHDj07Qbt4M7rKG%2FrotddVNjDT6UfrQ%3D%3D',
  CHANNEL_TALK_DOCTOR_PLUGIN_KEY: '6f85ec7b-9bb1-4132-9d4a-fb7bc8af9667',
  CHANNEL_TALK_PHARMACY_PLUGIN_KEY: '0033c4b6-de11-4d06-8da0-2ea46fbdadcf',
}

const DevAppOptions: I_ADMIN_OPTIONS = {
  SERVER_ADDRESS: 'https://api.dev.medi-pangpang.kr',
  WS_SERVER_ADDRESS: '',
  SHOW_BUILD_INFO: true,
  SIMULATE_DELAY: 0,
  FILL_MOCK_DATA: true,
  LOCAL_STORAGE_KEY_TOKEN: 'token',
  SERVER_DEFINED_ERROR_PREFIX: 'OE-',
  AGORA_APP_ID: '60611a6424bf446587ff206ea2ed2590',
  SUPPORT_EMAIL: 'test.002@evidnet.co.kr',
  FIREBASE_APIKEY: 'AIzaSyAS-mrbt2dIIEvcto3Tzh39sCL93ccL9-E',
  FIREBASE_AUTH_DOMAIN: 'medi-pangpang-dev-45766.firebaseapp.com',
  FIREBASE_PROJECT_ID: 'medi-pangpang-dev-45766',
  FIREBASE_STORAGE_BUCKET: 'medi-pangpang-dev-45766.appspot.com',
  FIREBASE_MESSAGING_SENDER_ID: '634615261674',
  FIREBASE_APP_ID: '1:634615261674:web:48c4fd4199d599974a2a93',
  FIREBASE_MEASUREMENT_ID: 'G-8Z777XNBMR',
  FIREBASE_VAPID_KEY: 'BLnGElTLHQUmFHWPE6ASQ73W_oz7NZMuUeyA2A1Mo6m2mPUeoTRRNBM-mElVxYEFntl9aMa4jT-YhAazFsPnmVo',
  API_DATA_KEY: 'F8TY9JmU%2BqZJbvB1OCaHLouHMONT6NNb%2FbbKeqZ9%2FDXcZ2jmbPVc5MFfHDj07Qbt4M7rKG%2FrotddVNjDT6UfrQ%3D%3D',
  CHANNEL_TALK_DOCTOR_PLUGIN_KEY: '6f85ec7b-9bb1-4132-9d4a-fb7bc8af9667',
  CHANNEL_TALK_PHARMACY_PLUGIN_KEY: '0033c4b6-de11-4d06-8da0-2ea46fbdadcf',
}

const QAAppOptions: I_ADMIN_OPTIONS = {
  SERVER_ADDRESS: 'https://evix-api.odcode.com',
  WS_SERVER_ADDRESS: '',
  SHOW_BUILD_INFO: true,
  SIMULATE_DELAY: 0,
  FILL_MOCK_DATA: false,
  LOCAL_STORAGE_KEY_TOKEN: 'token',
  SERVER_DEFINED_ERROR_PREFIX: 'OE-',
  AGORA_APP_ID: '60611a6424bf446587ff206ea2ed2590',
  SUPPORT_EMAIL: 'evix_support@evidnet.co.kr',
  API_DATA_KEY: 'F8TY9JmU%2BqZJbvB1OCaHLouHMONT6NNb%2FbbKeqZ9%2FDXcZ2jmbPVc5MFfHDj07Qbt4M7rKG%2FrotddVNjDT6UfrQ%3D%3D',
}

const StageAppOptions: I_ADMIN_OPTIONS = {
  SERVER_ADDRESS: 'https://api.stage.medi-pangpang.kr',
  WS_SERVER_ADDRESS: '',
  SHOW_BUILD_INFO: true,
  SIMULATE_DELAY: 0,
  FILL_MOCK_DATA: false,
  LOCAL_STORAGE_KEY_TOKEN: 'token',
  SERVER_DEFINED_ERROR_PREFIX: 'OE-',
  AGORA_APP_ID: 'c9b8d269f9c54a0e82bfc5cb9428fe30',
  SUPPORT_EMAIL: 'mdpp_support@evidnet.co.kr',
  FIREBASE_APIKEY: 'AIzaSyAS-mrbt2dIIEvcto3Tzh39sCL93ccL9-E',
  FIREBASE_AUTH_DOMAIN: 'medi-pangpang-dev-45766.firebaseapp.com',
  FIREBASE_PROJECT_ID: 'medi-pangpang-dev-45766',
  FIREBASE_STORAGE_BUCKET: 'medi-pangpang-dev-45766.appspot.com',
  FIREBASE_MESSAGING_SENDER_ID: '634615261674',
  FIREBASE_APP_ID: '1:634615261674:web:317b6c938a259b7f4a2a93',
  FIREBASE_MEASUREMENT_ID: 'G-7CGFMVC973',
  FIREBASE_VAPID_KEY: 'BLnGElTLHQUmFHWPE6ASQ73W_oz7NZMuUeyA2A1Mo6m2mPUeoTRRNBM-mElVxYEFntl9aMa4jT-YhAazFsPnmVo',
  API_DATA_KEY: 'F8TY9JmU%2BqZJbvB1OCaHLouHMONT6NNb%2FbbKeqZ9%2FDXcZ2jmbPVc5MFfHDj07Qbt4M7rKG%2FrotddVNjDT6UfrQ%3D%3D',
  CHANNEL_TALK_DOCTOR_PLUGIN_KEY: '7e8ee84d-1289-4aa9-8dd0-13b146cb39d9',
  CHANNEL_TALK_PHARMACY_PLUGIN_KEY: 'df27aeed-1cb1-448b-9570-2835b98996b6',
}

const ProdAppOptions: I_ADMIN_OPTIONS = {
  SERVER_ADDRESS: 'https://api.medi-pangpang.com',
  WS_SERVER_ADDRESS: '',
  SHOW_BUILD_INFO: true,
  SIMULATE_DELAY: 0,
  FILL_MOCK_DATA: false,
  LOCAL_STORAGE_KEY_TOKEN: 'token',
  SERVER_DEFINED_ERROR_PREFIX: 'OE-',
  AGORA_APP_ID: 'd3445bab32d74d30afc928588ae6eaef',
  SUPPORT_EMAIL: 'mdpp_support@evidnet.co.kr',
  FIREBASE_APIKEY: 'AIzaSyDhpAg4rROZgm52VFwAH7PZ-KN68duqa5E',
  FIREBASE_AUTH_DOMAIN: 'igloo-9eef5.firebaseapp.com',
  FIREBASE_PROJECT_ID: 'igloo-9eef5',
  FIREBASE_STORAGE_BUCKET: 'igloo-9eef5.appspot.com',
  FIREBASE_MESSAGING_SENDER_ID: '951052156726',
  FIREBASE_APP_ID: '1:951052156726:web:3fa93291b421eac9ae3d97',
  FIREBASE_MEASUREMENT_ID: 'G-0C4P8PW9E5',
  FIREBASE_VAPID_KEY: 'BO9HBAS36QYdEiqdweML3JKlb7abxnkw_iZ-Mi6hZs6DTlrdDOMdGqZnjZq83FYLlNtZ4pWUmzwj0FpEIdjSqr4',
  API_DATA_KEY: 'F8TY9JmU%2BqZJbvB1OCaHLouHMONT6NNb%2FbbKeqZ9%2FDXcZ2jmbPVc5MFfHDj07Qbt4M7rKG%2FrotddVNjDT6UfrQ%3D%3D',
  CHANNEL_TALK_DOCTOR_PLUGIN_KEY: '4bbaa695-7a60-46a7-a2c6-677536df6bd0',
  CHANNEL_TALK_PHARMACY_PLUGIN_KEY: '73160a85-470e-4889-a958-f55974e18533',
}

export const LOCAL_STORAGE_KEY_TOKEN = 'token' // 로그인 토큰을 저장할 로컬 스토리지 키

export const AppOptions =
  process.env.REACT_APP_ENV === 'prod'
    ? ProdAppOptions
    : process.env.REACT_APP_ENV === 'stage'
    ? StageAppOptions
    : process.env.REACT_APP_ENV === 'qa'
    ? QAAppOptions
    : process.env.REACT_APP_ENV === 'dev'
    ? DevAppOptions
    : LocalAppOptions

ODWebUtils.setAppOptions(AppOptions)
