import React from 'react'
import { ODVSpace } from '@mdpp/od-react-belt'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components/macro'
import { COLORS } from '../../styles/colors'

//asset
import {
  Wrap,
  SignUpWrap,
  Header,
  PageTitleWrap,
  ProcessWrap,
  PharmacyLogo,
  PageTitle,
  Step,
  StepCircle,
  StepText,
  Container,
  Label,
} from '../../common/style/SignUpStyle'
import IconOK from '../../assets/common/icon-ok.svg'
import IconPharmacy from '../../assets/common/icon-pharmacy.png'

export const CompletePharmacySignUp: React.FC = () => {
  return (
    <Wrap>
      <SignUpWrap>
        <Header>
          <PageTitleWrap>
            <img src={IconPharmacy} style={PharmacyLogo} alt="약국 아이콘" />
            <PageTitle>회원가입</PageTitle>
          </PageTitleWrap>
          <ProcessWrap>
            <Step>
              <StepCircle>
                <span>1</span>
              </StepCircle>
              <StepText>약관 동의</StepText>
            </Step>
            <Step>
              <StepCircle>
                <span>2</span>
              </StepCircle>
              <StepText>가입정보 입력</StepText>
            </Step>
            <Step>
              <StepCircle>
                <span>3</span>
              </StepCircle>
              <StepText>이메일 인증</StepText>
            </Step>
            <Step className="active">
              <StepCircle>
                <span>4</span>
              </StepCircle>
              <StepText>가입 승인 요청</StepText>
            </Step>
          </ProcessWrap>
        </Header>

        <Container>
          <InnerBox>
            <img src={IconOK} style={{ width: 88, height: 80 }} alt="icon email" />
            <ODVSpace h={25} />
            <MainMessage>이메일 인증 및 가입요청이 완료되었습니다.</MainMessage>
            <ODVSpace h={14} />
            <Label style={{ textAlign: 'center' }}>
              <span className="text-blue">관리자의 승인 후 로그인이 가능합니다.</span>
              <br />
              승인 완료 시, 입력하신 이메일로 안내될 예정입니다.
              <br />
              참고 부탁드립니다. 감사합니다.
            </Label>
          </InnerBox>
        </Container>
      </SignUpWrap>
    </Wrap>
  )
}

const MainMessage = styled.div`
  font-size: 18px;
  font-weight: bold;
  color: ${COLORS.GRAY42};
`

const InnerBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 611px;
  border-radius: 6px;
  border: solid 1px ${COLORS.GRAYD5};
`
