import { ODHSpace } from '@mdpp/od-react-belt'
import moment from 'moment-timezone'
import React from 'react'
import { useEffect } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { GQLNOTIFICATION_TYPE, GQLReservation, GQLRESERVATION_STATUS } from '@mdpp/common/lib/@types/server.schema'
import { SiteUrls } from '../../../SiteUrls'
import { ReservationDelayPopup } from '../../../common/reservation/ReservationDelayPopup'
import SVGWaiting from '../../../assets/waiting_icon.svg'
import SVGDelay from '../../../assets/delay_icon.svg'
import Enter from '../../../assets/enter_room.svg'
import SVGPending from '../../../assets/pending_icon.svg'
import { ImageButton, Button, BP } from '../../../common/RebeccaButton'
import { useDoctorAPIs } from "../../context/useDoctorAPIs";
import { ERROR_TYPE, getDeviceState } from '../../../utils/webUtils'
import { openPopup as openDevicePopup } from '../../../common/DevicePopup'


interface IReservationNoticeRightProps {
  fromManager: boolean
  reservation: GQLReservation
  onClickCancel: () => void
  fromAdmin?: boolean
}

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  @media (min-width: 1024px) {
    justify-content: flex-end;
  }
`
// PENDING TEXT
const Text = styled.span`
  font-size: 12px;
  line-height: 1.25;
  color: var(--gray800);

  span {
    color: #131415;
  }

  display: flex;

`

const WaitingText = styled.span`
  font-size: 12px;
  line-height: 1.25;
  color: var(--gray800);

  span {
    color: #131415;
    letter-spacing: -0.5px;
  }
  
  display: flex;
  flex-direction: column;
`

const ReceiptedText = styled.span`
  font-size: 12px;
  line-height: 1.25;
  color: var(--gray800);

  span {
    color: #131415;
  }
  display: flex;

`

const ButtonWrapper = styled.div`
`

const Delay = styled.div`
  font-size: 12px;
  color: #9C4221;
  padding: 0 10px;

  &:hover {
    cursor: pointer;
  }

  &:active {
    color: red;
  }
`
const OutlinePending = styled.div`
  display: flex;
  align-items: center;
  background-color: #F6F6F7;
  border-radius: 8px;
  border: 1px solid #BABFC3;
  padding: 5px 5px 5px 8px;
  flex: none;
`

const OutlineReceipted = styled.div`
  display: flex;
  align-items: center;
  background-color: #FFF5EA;
  border-radius: 8px;
  border: 1px solid #E1B878;
  padding: 5px 5px 5px 8px;
  flex: none;

`

const OutlineWaiting = styled.div`
  display: flex;
  align-items: center;
  background-color: #FFEBEB;
  border-radius: 8px;
  border: 1px solid #E89F9F;
  padding: 5px 5px 5px 8px;
  flex: none;
  
`

const Icon = styled.img`
  margin-right: 5px;
`

export const ReservationNoticeRight: React.FC<IReservationNoticeRightProps> = props => {
  const { reservation, onClickCancel, fromManager, fromAdmin } = props
  const { sendRequestNotificationToPatient } = useDoctorAPIs()
  const [receiptedAt, setReceiptedAt] = React.useState(reservation.patientReceiptAt || '')
  const [showPopup, setShowPopup] = React.useState(false)
  const [deviceState, setDeviceState] = React.useState(ERROR_TYPE.NONE)

  useEffect(() => {
    getDeviceState().then((state: string) => {
      setDeviceState(state)
    })
  }, [])

  useEffect(() => {
    setReceiptedAt(reservation.patientReceiptAt)
  }, [reservation])
  
  const sendDelayMessage = async (delay: string) => {
    console.log('send delay message: ', delay)
    await sendRequestNotificationToPatient({
      reservationId: reservation.reservationId,
      notificationType: GQLNOTIFICATION_TYPE.PATIENT_WAITING_DELAYED,
      data: delay
    })
    setShowPopup(false)
  }

  const openPopup = () => {
    setShowPopup(true)
  }

  const closePopup = () => {
    setShowPopup(false)
  }

  if (reservation.status === GQLRESERVATION_STATUS.PENDING) {
    const message2 = `${moment(reservation.reservationTime).format('YYYY.MM.DD (ddd)')} / ${moment(
      reservation.reservationTime
    ).format('HH:mm')}`

    return (
      <Wrapper>
        <OutlinePending>
          <Icon src={SVGPending} />
          <Text>
            <span>{message2}</span> 진료 예정입니다.
          </Text>
          <ODHSpace w={10} />
          <ButtonWrapper>
            <Button size={BP.SIZE.L} type={BP.TYPE.PrimaryDestructive} onClick={onClickCancel}>
              예약 취소
            </Button>
          </ButtonWrapper>
        </OutlinePending>
      </Wrapper>
    )
  }

  if (
    reservation.status === GQLRESERVATION_STATUS.WAITING
  ) {
    // FIXME: Link to 를 수정 필요. message 추가 필요
    return (
      <OutlineWaiting>
        <Wrapper style={{flex: 'none'}}>
          <Icon src={SVGWaiting} />
          <WaitingText>
            <span>환자가 접수하고 있지 않습니다.</span> 
            <span>환자가 접수 후에 진료 진행이 가능합니다.</span>
            <span>예정된 시간에 환자가 접수하지 않을 시, 예약을 취소할 수 있습니다.</span>
          </WaitingText>
          <ODHSpace w={10} />
          <ButtonWrapper>
            <Button size={BP.SIZE.S} type={BP.TYPE.OutlineDestructive} onClick={onClickCancel}>예약 취소</Button>
          </ButtonWrapper>
        </Wrapper>
      </OutlineWaiting>
    )
  }

  if (
    reservation.status === GQLRESERVATION_STATUS.RECEIPTED
  ) {
    // FIXME: Link to 를 수정 필요. message 추가 필요
    const now = moment()
    const receipted = moment(receiptedAt)
    const diff = now.diff(receipted, 'minute')
    const hour = Math.floor(diff / 60)
    const minute = diff % 60
    return (
      <Wrapper>
        <>
          <ReservationDelayPopup isOpen={showPopup} onClose={closePopup} onSubmit={sendDelayMessage} reservation={reservation} />
          <OutlineReceipted>
            <Icon src={SVGDelay} />
            <ReceiptedText>
              {!isNaN(hour) && (<span>환자 {hour > 0 ? hour + '시간' : ''}{minute}분째 대기중</span>)}
              {!fromAdmin && (<Delay onClick={openPopup}>딜레이 알림 보내기</Delay>)}
            </ReceiptedText>
            <ButtonWrapper>
              <Button size={BP.SIZE.S} type={BP.TYPE.Outline} onClick={onClickCancel}>진료 취소</Button>
            </ButtonWrapper>
            <ODHSpace w={14} />
            {!fromAdmin && !fromManager && deviceState === ERROR_TYPE.NONE ?
              <Link to={SiteUrls.Doctor.Reservation.Room(reservation.reservationId)} className="none-link-underline">
                <ButtonWrapper>
                  <ImageButton size={BP.SIZE.S} type={BP.TYPE.Primary} images={{default: Enter}}>
                    진료실 입장
                  </ImageButton>
                </ButtonWrapper>
              </Link> : !fromAdmin && !fromManager &&
                <ButtonWrapper>
                  <ImageButton size={BP.SIZE.S} type={BP.TYPE.Primary} images={{default: Enter}}
                    onClick={openDevicePopup}
                  >
                    진료실 입장
                  </ImageButton>
                </ButtonWrapper>
            }
          </OutlineReceipted>
        </>
      </Wrapper>
    )
  }

  if (reservation.status === GQLRESERVATION_STATUS.IN_PROGRESS || reservation.status === GQLRESERVATION_STATUS.READY) {
    return (
      <Wrapper>
        {!fromManager && deviceState === ERROR_TYPE.NONE ?
          <Link to={SiteUrls.Doctor.Reservation.Room(reservation.reservationId)}>
            <ButtonWrapper>
              <ImageButton size={BP.SIZE.L} type={BP.TYPE.Primary} images={{default: Enter}}>
                진료실 입장
              </ImageButton>
            </ButtonWrapper>
          </Link> : !fromManager &&
          <ButtonWrapper>
            <ImageButton size={BP.SIZE.L} type={BP.TYPE.Primary} images={{default: Enter}}
              onClick={openDevicePopup}
            >
              진료실 입장
            </ImageButton>
          </ButtonWrapper>
        }
      </Wrapper>
    )
  }

  if (reservation.status === GQLRESERVATION_STATUS.PRESCRIPTION || reservation.status === GQLRESERVATION_STATUS.FINISHED) {
    if (fromManager) {
      return null
    }
  }

  // 취소된 경우 보여주지 않음.
  return null
}
