import React from 'react'
import { ReviewListableTable } from '../../common/review/list/ReviewListableTable'
import {Page, PageContainer, PageContent, PageHeader, PageTitle} from "../../common/page/Page";

export const AdminReviewListPage: React.FC = props => {
  return (
    <PageContainer>
      <Page>
        <PageHeader>
          <PageTitle>리뷰</PageTitle>
        </PageHeader>
        <ReviewListableTable />
      </Page>
    </PageContainer>
  )
}
