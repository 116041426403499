import { ODIcon, ODIcons } from '@mdpp/od-react-belt'
import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

interface ITitleWithViewAllProps {
  icon: ODIcons
  title: string
  link: string
}

export const TitleWithViewAll: React.FC<ITitleWithViewAllProps> = props => {
  const { icon, title, link } = props
  return (
    <Wrapper>
      <Left>
        <IconWrapper>
          <ODIcon icon={icon} />
        </IconWrapper>
        {title}
      </Left>
      <Right>
        <Link to={link} style={{ textDecoration: 'none' }}>
          <ViewAllButton>전체 보기</ViewAllButton>
        </Link>
      </Right>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-left: 5px;
`

const Left = styled.div`
  font-size: 18px;
  line-height: 24px;
  color: var(--black);
  font-weight: 500;
  letter-spacing: -0.5px;
`

const IconWrapper = styled.div`
  font-size: 16px;
  font-weight: 500;
  margin-right: 10px;
  margin-top: 3px;
`

const Right = styled.div`
  padding-right: 5px;
`

const ViewAllButton = styled.div`
  padding: 1px 4px;
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: var(--interactive-default);
  cursor: pointer;
`
