import React from 'react'
import { Card } from 'reactstrap'
import styled from 'styled-components/macro'
import { GQLInquiry } from '@mdpp/common/lib/@types/server.schema'
import { COLORS } from '../../../styles/colors'

interface IFaqItemProps {
  inquiry: GQLInquiry
}

export const InquiryItem: React.FC<IFaqItemProps> = props => {
  const { inquiry } = props

  const { name, email, phone, contents, doctorId } = inquiry

  return (
    <Card style={cardStyle}>
      <Row>
        <RowTitle>계정</RowTitle>
        <RowText>{doctorId === null ? '병원관리자' : '의사'}</RowText>
      </Row>
      <Row>
        <RowTitle>문의자명</RowTitle>
        <RowText>{name}</RowText>
      </Row>
      <Row>
        <RowTitle>전화번호</RowTitle>
        <RowText>{phone}</RowText>
      </Row>
      <Row>
        <RowTitle>이메일</RowTitle>
        <RowText>{email}</RowText>
      </Row>
      <ContentsRow>
        <RowTitle>문의사항</RowTitle>
        <RowText>{contents}</RowText>
      </ContentsRow>
    </Card>
  )
}

const cardStyle = {
  margin: '22px 30px',
  border: 'none',
  borderRadius: 5,
  boxShadow: '0 1px 4px 0 rgba(0, 0, 0, 0.15)',
  padding: '10px 25px 20px',
}
const Row = styled.div`
  display: flex;
  border-bottom: 1px solid ${COLORS.GRAY200};
  height: 40px;
  align-items: center;
`
const RowTitle = styled.div`
  font-size: 12px;
  line-height: 1.67;
  color: var(--gray600);
  width: 110px;
`
const RowText = styled.div`
  font-size: 14px;
  line-height: 1.43;
  color: var(--gray800);
  flex: 1;
`
const ContentsRow = styled.div`
  min-height: 136px;
  display: flex;
  border-bottom: 1px solid ${COLORS.GRAY200};
  padding-top: 10px;
`
