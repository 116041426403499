import React from 'react'
import { PatientListableTable } from '../../common/patient/list/PatientListableTable'

interface IAdminPatientPageProps {
  hospitalId: number
}

export const AdminPatientListPage: React.FC<IAdminPatientPageProps> = props => {
  return (

        <PatientListableTable />

  )
}
