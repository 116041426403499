import { GQLPopupInfo } from '@mdpp/common/lib/@types/server.schema';
import React from "react";
import { ODVSpace } from "@mdpp/od-react-belt";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { PopupModal } from "./PopupModal";
import checkboxDisable from "../../../assets/checkbox-disable.svg"
import checkboxEnable from "../../../assets/checkbox-enable.svg"

interface NoticePopupModalProps {
  isOpen: boolean
  onClose: (onetime : boolean) => void
  showDetailCallback: () => void
  popupInfo: GQLPopupInfo
  isManager: boolean,
  linkTo : string,
}

export const NoticePopupModal: React.FC<NoticePopupModalProps> = props => {
  const {isOpen, onClose, popupInfo, isManager, linkTo} = props
  const [onetime, setOnetime] = React.useState(false)
  return (
    <PopupModal isOpen={isOpen} toggle={() => onClose(onetime)} isCentered={true}>
      <ImageWrapper>
        { popupInfo?.popupImage?.link && popupInfo.notice?.noticeId &&
            <Link to={{pathname: linkTo}} target="_blank" onClick={() => onClose(onetime)}>
              <Img src={popupInfo.popupImage.link}/>
            </Link>
        }
      </ImageWrapper>
      <ODVSpace h={12} />
      <ButtonWrapper>
        <CheckBoxWrapper>
          <img src={onetime ?  checkboxEnable : checkboxDisable} onClick={() => {setOnetime(!onetime)}}/>
          <CheckboxLabel>다시 보지 않기</CheckboxLabel>
        </CheckBoxWrapper>
        <CloseButton onClick={() => {
          onClose(onetime)
          setOnetime(false)
        }}>
          <CheckboxLabel>닫기</CheckboxLabel>
        </CloseButton>
      </ButtonWrapper>
    </PopupModal>
  )
}


const ButtonWrapper = styled.div`
  display : flex;
  justify-content: space-between;
`
const CheckBoxWrapper = styled.div`
  width: 192px;
  height: 59px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: #767676;
  border-radius: 12px;
`

const ImageWrapper = styled.div`
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1), 0px 8px 40px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
`

const CheckboxLabel = styled.label`
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: 500;
  font-size: 15.25px;
  line-height: 100%;
  letter-spacing: -0.3px;
  text-align: center;
  color: #FFFFFF;
  margin: 0px 10px;
`
const Img = styled.img`
  border-radius: 10px;
  object-fit: contain;
`
const CloseButton = styled.div`
  width: 66px;
  height: 59px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: #767676;
  border-radius: 12px;
`