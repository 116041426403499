import { produce } from 'immer'
import * as React from 'react'
import { GQLDoctor, GQLDoctorLoginInput } from '@mdpp/common/lib/@types/server.schema'
import { AppOptions, I_ADMIN_OPTIONS, LOCAL_STORAGE_KEY_TOKEN } from '../../AppOptions'
import { useDoctorAPIs } from './useDoctorAPIs'
import { WebNotification } from '../../utils/WebNotification'

interface IODDoctorAppContextType {
  state: IODDoctorAppReducerState
  login: (input: GQLDoctorLoginInput) => Promise<GQLDoctor>
  logout: () => Promise<void>
  refreshProfile: () => Promise<GQLDoctor>
}

export enum LOGIN_STATE {
  Checking = 'Checking',
  LoggedOut = 'LoggedOut',
  LoggingIn = 'LoggingIn',
  LoggedIn = 'LoggedIn',
  LoggingOut = 'LoggingOut',
}

interface IODDoctorAppContextProviderProps {}

interface IODDoctorAppReducerState {
  loginState: LOGIN_STATE
  profile: GQLDoctor | null
}

enum ODDoctorAppActionType {
  SetLoggedIn = 'odDoctorApp/SetLoggedIn',
  SetLoggedOut = 'odDoctorApp/SetLoggedOut',
  SetAppConfig = 'odDoctorApp/SetAppConfig',
  SetNodeClick = 'odDoctorApp/SetNodeClick', // whenever directory view's node is clicked, count++
}

interface IODDoctorAppActionSetLoggedIn {
  type: ODDoctorAppActionType.SetLoggedIn
  profile: GQLDoctor
}

interface IODDoctorAppActionSetLoggedOut {
  type: ODDoctorAppActionType.SetLoggedOut
}

type ODDoctorAppReducerAction = IODDoctorAppActionSetLoggedIn | IODDoctorAppActionSetLoggedOut

interface IODDoctorAppReducer extends React.Reducer<IODDoctorAppReducerState, ODDoctorAppReducerAction> {}

function createAppReducer(): IODDoctorAppReducer {
  return function (state: IODDoctorAppReducerState, action: ODDoctorAppReducerAction): IODDoctorAppReducerState {
    return produce(state, draft => {
      switch (action.type) {
        case ODDoctorAppActionType.SetLoggedIn:
          draft.loginState = LOGIN_STATE.LoggedIn
          draft.profile = action.profile
          break
        case ODDoctorAppActionType.SetLoggedOut:
          draft.loginState = LOGIN_STATE.LoggedOut
          break
        default:
          return
      }
    })
  }
}

const actionSetLoggedIn = (profile: GQLDoctor): IODDoctorAppActionSetLoggedIn => ({
  type: ODDoctorAppActionType.SetLoggedIn,
  profile,
})
const actionSetLoggedOut = (): IODDoctorAppActionSetLoggedOut => ({ type: ODDoctorAppActionType.SetLoggedOut })

function createInitialAppReducerState(): IODDoctorAppReducerState {
  return {
    loginState: LOGIN_STATE.Checking,
    profile: null,
  }
}

function createODDoctorAppContext(appOptions: I_ADMIN_OPTIONS) {
  const Context: React.Context<IODDoctorAppContextType> = React.createContext<IODDoctorAppContextType>(
    // tslint:disable-next-line
    {} as IODDoctorAppContextType
  )

  const DoctorProvider: React.FC<IODDoctorAppContextProviderProps> = props => {
    const { children } = props

    const { loginDoctor, getDoctorProfile, updateDoctorProfile, logout: apiLogout } = useDoctorAPIs()

    const [state, dispatch] = React.useReducer<IODDoctorAppReducer>(createAppReducer(), createInitialAppReducerState())
    const setLoggedIn = React.useCallback((profile: GQLDoctor) => dispatch(actionSetLoggedIn(profile)), [dispatch])

    const checkLogin = React.useCallback(async () => {
      try {
        const profile = await getDoctorProfile()
        setLoggedIn(profile)

        // fcm token
        const webFcmToken = await WebNotification.getToken()
        if (webFcmToken !== profile.webFcmToken) await updateDoctorProfile({ id: profile.doctorId, webFcmToken })
        WebNotification.onMessageListener()
      } catch (ex) {
        // 로그인 실패, 로그아웃된 상태
        dispatch(actionSetLoggedOut())
      }
    }, [getDoctorProfile, setLoggedIn])

    const login = React.useCallback(
      async (data: GQLDoctorLoginInput): Promise<GQLDoctor> => {
        const res = await loginDoctor(data)
        localStorage.setItem(LOCAL_STORAGE_KEY_TOKEN, res.token)
        setLoggedIn(res.me)
        const webFcmToken = await WebNotification.getToken()
        if (webFcmToken) {
          await updateDoctorProfile({ id: res.me.doctorId, webFcmToken })
          WebNotification.onMessageListener()
        }
        return res.me
      },
      [loginDoctor, setLoggedIn]
    )

    const logout = React.useCallback(async () => {
      const profile = await getDoctorProfile()
      WebNotification.deleteToken()
      await updateDoctorProfile({ id: profile.doctorId, webFcmToken: null })
      await apiLogout()
      localStorage.removeItem(LOCAL_STORAGE_KEY_TOKEN)
      const managerEmail = localStorage.getItem('managerEmail')
      const doctorEmail = localStorage.getItem('doctorEmail')
      const adminEmail = localStorage.getItem('adminEmail')
      const pharmacyEmail = localStorage.getItem('pharmacyEmail')
      managerEmail ? localStorage.setItem('managerEmail', managerEmail) : console.log('not remember manager !')
      doctorEmail ? localStorage.setItem('doctorEmail', doctorEmail) : console.log('not remember doctorEmail !')
      adminEmail ? localStorage.setItem('adminEmail', adminEmail) : console.log('not remember adminEmail !')
      pharmacyEmail ? localStorage.setItem('pharmacyEmail', pharmacyEmail) : console.log('not remember pharmacyEmail !')
      dispatch(actionSetLoggedOut())
    }, [apiLogout])

    const refreshProfile = React.useCallback(async () => {
      const profile = await getDoctorProfile()
      setLoggedIn(profile)
      return profile
    }, [getDoctorProfile, setLoggedIn])

    React.useEffect(() => {
      // noinspection JSIgnoredPromiseFromCall
      checkLogin()
    }, [checkLogin])

    const context: IODDoctorAppContextType = {
      state,
      login,
      logout,
      refreshProfile,
    }
    return <Context.Provider value={context}>{children}</Context.Provider>
  }

  return { Context, Provider: DoctorProvider }
}

const { Context: AppContext, Provider } = createODDoctorAppContext(AppOptions)

export const ODDoctorAppProvider = Provider
export const ODDoctorAppContext = AppContext

export function useODDoctorAppContext(): IODDoctorAppContextType {
  return React.useContext(AppContext)
}
