import { ODHSpace, ODIcon, ODIcons } from '@mdpp/od-react-belt'
import React from 'react'
import styled from 'styled-components/macro'

interface IProfessionTagProps {
  onRemove: () => void
}

export const ProfessionTag: React.FC<IProfessionTagProps> = props => {
  return (
    <TagWrapper onClick={props.onRemove}>
      <ODIcon icon={ODIcons.CoreX} />
      <ODHSpace w={5} />
      {props.children}
    </TagWrapper>
  )
}

const TagWrapper = styled.div`
  cursor: pointer;
  margin-bottom: 10px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  border-radius: 4px;
  background-color: var(--second);
  font-size: 11px;
  font-weight: bold;
  color: var(--white);
  padding-left: 10px;
  padding-right: 10px;
  white-space: nowrap;
`
