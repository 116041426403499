import { ODVSpace } from '@mdpp/od-react-belt'
import React from 'react'
import { Link } from 'react-router-dom'
import { Popover, PopoverBody } from 'reactstrap'
import styled, { css } from 'styled-components'
import { GQLReservation, GQLRESERVATION_STATUS } from '@mdpp/common/lib/@types/server.schema'
import { SiteUrls } from '../../SiteUrls'
import { WebUtils } from '../../utils/webUtils'

export enum LinkStyle {
  Doctor,
  Manager,
  Admin,
}

interface IScheduleStatusPopOver {
  reservation: GQLReservation
  popoverOpen: boolean
  popoverTargetId: string | null
  togglePopover: () => void
  onClickCancel?: () => void
  linkStyle: LinkStyle
}

export const ScheduleStatusPopOver: React.FC<IScheduleStatusPopOver> = props => {
  const { popoverOpen, popoverTargetId, togglePopover, reservation, linkStyle, onClickCancel } = props
  const { reservationId, status, patient, reservationTime } = reservation
  const dateTime = WebUtils.formatReservationTime(reservationTime)

  const reservationLink =
    linkStyle === LinkStyle.Doctor
      ? SiteUrls.Doctor.Reservation.Detail(reservationId)
      : linkStyle === LinkStyle.Manager
      ? SiteUrls.Manager.Reservation.Detail(reservationId)
      : SiteUrls.Admin.Reservation.Detail(reservationId)

  let text
  switch (status) {
    case GQLRESERVATION_STATUS.PENDING:
      text = '진료예정'
      break
    case GQLRESERVATION_STATUS.WAITING:
      text = '진료대기'
      break
    case GQLRESERVATION_STATUS.RECEIPTED:
      text = '진료접수'
      break
    case GQLRESERVATION_STATUS.READY:
      text = '준비완료'
      break
    case GQLRESERVATION_STATUS.IN_PROGRESS:
      text = '진료중'
      break
    case GQLRESERVATION_STATUS.PRESCRIPTION:
      text = '처방중'
      break
    case GQLRESERVATION_STATUS.FINISHED:
      text = '진료완료'
      break
    case GQLRESERVATION_STATUS.DOCTOR_NO_SHOW:
    case GQLRESERVATION_STATUS.DOCTOR_CANCEL:
    case GQLRESERVATION_STATUS.PATIENT_NO_SHOW:
    case GQLRESERVATION_STATUS.PATIENT_CANCEL:
      text = '진료취소'
      break
    default:
      text = ''
      break
  }

  console.log(62, status)

  return (
    <Popover
      isOpen={popoverOpen}
      target={popoverTargetId ?? 'schedule_edit_container'}
      toggle={togglePopover}
      placement="right-start"
    >
      <PopBody>
        <StatusLabel status={status}>{text}</StatusLabel>
        {status === GQLRESERVATION_STATUS.DOCTOR_NO_SHOW && <Text>의사 예약부도</Text>}
        {status === GQLRESERVATION_STATUS.PATIENT_NO_SHOW && <Text>환자 예약부도</Text>}
        <ODVSpace h={20} />
        <PatientName>{patient.name}</PatientName>
        <ODVSpace h={4} />
        <ReservedDate>{dateTime}</ReservedDate>
        <ODVSpace h={20} />
        <Link to={reservationLink}>
          <ReservationButton>예약 상세 조회</ReservationButton>
        </Link>
        <ODVSpace h={7} />
        {(status === GQLRESERVATION_STATUS.WAITING || status === GQLRESERVATION_STATUS.PENDING) && (
          <ReservationCancelButton onClick={onClickCancel}>예약 취소</ReservationCancelButton>
        )}
      </PopBody>
    </Popover>
  )
}

const PopBody = styled(PopoverBody)`
  display: flex;
  flex-direction: column;
  z-index: 10;
  width: 240px;
  padding: 20px;
  border-radius: 5px;
  background-color: var(--white);
`

const StatusLabel = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 16px;
  font-size: 10px;
  font-weight: bold;
  border-radius: 4px;
  background-color: var(--warning);
  color: var(--gray800);

  ${(p: { status: GQLRESERVATION_STATUS }) =>
    p.status === GQLRESERVATION_STATUS.PATIENT_NO_SHOW &&
    css`
      background-color: var(--danger);
      color: var(--white);
    `} ${(p: { status: GQLRESERVATION_STATUS }) =>
  p.status === GQLRESERVATION_STATUS.DOCTOR_NO_SHOW &&
  css`
    background-color: var(--danger);
    color: var(--white);
  `} ${(p: { status: GQLRESERVATION_STATUS }) =>
  p.status === GQLRESERVATION_STATUS.FINISHED &&
  css`
    background-color: var(--success);
    color: var(--white);
  `}
`
const Text = styled.span`
  margin-top: 4px;
  font-size: 14px;
  font-weight: bold;
  color: var(--gray500);
`

const PatientName = styled.span`
  font-size: 20px;
  font-weight: 300;
  line-height: 1.1;
  color: var(--gray800);
`

const ReservedDate = styled.span`
  font-size: 13px;
  font-weight: bold;
  line-height: 1.69;
  color: var(--gray600);
`
const ReservationButton = styled.button`
  width: 200px;
  height: 35px;
  background-color: var(--mpPrimary500);
  border-radius: 30px;
  font-size: 15px;
  color: var(--white);
  outline: none;

  cursor: pointer;
`

const ReservationCancelButton = styled.button`
  width: 200px;
  height: 35px;
  border-radius: 30px;
  border: solid 1px var(--danger);
  background-color: var(--red10);
  font-size: 15px;
  color: var(--danger);
  outline: none;

  cursor: pointer;
`
