import React from 'react'
import { PageContainer, Page, PageHeader, PageTitle, PageContent } from '../../../common/page/Page'
import { AppVersionEdit } from './AppVersionEdit'

interface IAdminMedicalSubjectEditPageProps {
  idEditing: number | null
}

export const AppVersionEditPage: React.FC<IAdminMedicalSubjectEditPageProps> = props => {
  return (
    <PageContainer>
      <Page>
        <PageHeader>
          <PageTitle>앱 버전 추가/편집</PageTitle>
        </PageHeader>
        <PageContent>
        <AppVersionEdit idEditing={props.idEditing} />
        </PageContent>
      </Page>
    </PageContainer>
  )
}
