import React from 'react'
import { MainContentWithInset } from '../../common/layout/MainContentWithInset'
import { MedicalSubjectEditPage } from '../../common/medicalSubject/edit/MedicalSubjectEditPage'

interface IAdminMedicalSubjectEditPageProps {
  idEditing: number | null
}

export const AdminMedicalSubjectEditPage: React.FC<IAdminMedicalSubjectEditPageProps> = props => {
  return (
    <div>
      <MainContentWithInset>
        <MedicalSubjectEditPage idEditing={props.idEditing} />
      </MainContentWithInset>
    </div>
  )
}
