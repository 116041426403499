import { RESET_ACCOUNT_TYPE } from '@mdpp/common'
import { ODCommonMainLayoutWithoutSideBar } from '@mdpp/od-react-belt'
import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { ChangePasswordContainer } from '../common/ChangePasswordContainer'
import { SiteUrls } from '../SiteUrls'
import { ManagerHeader } from './gnb/ManagerHeader'
import { ManagerAuthorized } from './ManagerAuthorized'
import { ManagerLoginPage } from './ManagerLoginPage'
import { ManagerLogoutPage } from './ManagerLogoutPage'
import { ManagerMainLayout } from './ManagerMainLayout'
import { AppOptions, LOCAL_STORAGE_KEY_TOKEN } from "../AppOptions"
import { SSEProvider } from "react-hooks-sse"
import { DoctorAuthorized } from "../doctor/DoctorAuthorized"
const sseEndpoint = `${AppOptions.SERVER_ADDRESS}/events?token=${localStorage.getItem(LOCAL_STORAGE_KEY_TOKEN)}&clientType=HospitalManager`

export const ManagerRoot: React.FC = () => {
  return (
    <Switch>
      <Route path={SiteUrls.Manager.Login} component={ManagerLoginPage} />
      <Route path={SiteUrls.Manager.Logout} component={ManagerLogoutPage} />
      <Route
        path={SiteUrls.Manager.ChangePassword}
        render={(props: any) => (
          <ManagerAuthorized>
            <ODCommonMainLayoutWithoutSideBar renderHeader={() => <ManagerHeader />}>
              <ChangePasswordContainer accountType={RESET_ACCOUNT_TYPE.MANAGER} />
            </ODCommonMainLayoutWithoutSideBar>
          </ManagerAuthorized>
        )}
      />
      <Route
        path={SiteUrls.Manager.Root}
        render={() => {
          return (
            <ManagerAuthorized>
              <SSEProvider endpoint={sseEndpoint}>
                <ManagerMainLayout />
              </SSEProvider>
            </ManagerAuthorized>
          )
        }}
      />
      <Redirect to={SiteUrls.Manager.Dashboard.Main} />
    </Switch>
  )
}
