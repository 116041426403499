import { ErrorCode } from '@mdpp/common'
import { registerServerErrorMessageMap } from '@mdpp/od-react-belt'
import { AppOptions } from '../AppOptions'

interface ErrType {
  message: string
}

export function registerServerErrorMessages() {
  registerServerErrorMessageMap(ErrorCode.SERVER_WRONG_LOGIN_INFO, '로그인에 실패하였습니다.')
  registerServerErrorMessageMap(ErrorCode.PHONE_FORMAT_INVALID, '전화번호가 형식에 맞지 않습니다.')
  registerServerErrorMessageMap(ErrorCode.FAX_FORMAT_INVALID, 'FAX 번호가 형식에 맞지 않습니다.')
  registerServerErrorMessageMap(ErrorCode.NO_UPDATE_FOUND, '변경된 사항이 없어 저장하지 못했습니다.')
  registerServerErrorMessageMap(ErrorCode.ACCOUNT_ALREADY_HAVE_EMAIL, '이미 등록된 이메일 주소입니다.')
  registerServerErrorMessageMap(ErrorCode.EMAIL_FORMAT_INVALID, '올바른 이메일이 아닙니다.')
  registerServerErrorMessageMap(ErrorCode.ERROR_MS_FK_ERROR, '사용중인 진료과목이라 삭제할 수 없습니다.')
  registerServerErrorMessageMap(ErrorCode.ERROR_ACCOUNT_EMAIL_NOT_FOUND, '등록된 이메일이 아닙니다.')
  registerServerErrorMessageMap(ErrorCode.PASSWORD_NOT_MATCH, '현재 비밀번호가 일치하지 않습니다.')
  registerServerErrorMessageMap(
    ErrorCode.ERROR_RESERVATION_STATUS_NOT_JOINABLE,
    '진료실에 입장 가능한 상태가 아닙니다.'
  )
  registerServerErrorMessageMap(ErrorCode.ERROR_RESERVATION_STATUS_NOT_STARTABLE, '진료시작이 가능한 상태가 아닙니다.')
  registerServerErrorMessageMap(
    ErrorCode.ERROR_RESERVATION_STATUS_NOT_CANCELLABLE,
    '진료 취소가 가능한 상태가 아닙니다.'
  )
  registerServerErrorMessageMap(ErrorCode.ERROR_DOCTOR_NAME_IS_NULL, '이름을 입력해주세요.')
  registerServerErrorMessageMap(ErrorCode.ERROR_DOCTOR_EMAIL_IS_NULL, '이메일을 입력해주세요.')
  registerServerErrorMessageMap(
    ErrorCode.ERROR_FAILED_TO_PAY,
    '환자의 결제수단이 유효하지 않아 진료비 결제에 실패하였습니다.'
  )
  registerServerErrorMessageMap(
    ErrorCode.ERROR_PAYMENT_ALREADY_SUCCESSFUL,
    '납부가 완료된 진료비는 수정할 수 없습니다.'
  )
  registerServerErrorMessageMap(
    ErrorCode.ERROR_DELETE_HOSPITAL_BY_EXIST_RESERVATION,
    '진료 예약이 존재하여 병원을 삭제할 수 없습니다.'
  )
  registerServerErrorMessageMap(ErrorCode.ERROR_PHARMACY_NAME_INVALID, '약국 이름은 최소 2글자 이상이여야 합니다.')
  registerServerErrorMessageMap(ErrorCode.ERROR_PHARMACY_ESSENTIAL_FIELD, '필수항목을 입력해주세요')
  registerServerErrorMessageMap(ErrorCode.ERROR_REQUIRED_FIELD_NULL, '필수항목을 입력해주세요.')
  registerServerErrorMessageMap(
    ErrorCode.ERROR_UPDATE_DIAGNOSIS_BY_RESERVATION_FINISH,
    '진행중인 진료만 변경이 가능합니다'
  )
  registerServerErrorMessageMap(ErrorCode.ERROR_UPDATE_DIAGNOSIS_BY_OUT_OF_DATE, '변경 가능한 기간이 아닙니다.')

  registerServerErrorMessageMap(ErrorCode.CELL_PHONE_FORMAT_INVALID, '전화번호가 형식에 맞지 않습니다.')
  registerServerErrorMessageMap(ErrorCode.CELL_PHONE_FORMAT_INVALID, '휴대전화번호가 형식에 맞지 않습니다.')
  registerServerErrorMessageMap(ErrorCode.LICENSE_YEAR_FORMAT_INVALID, '면허취득년도가 형식에 맞지 않습니다.')
}

export const checkMessageWithPrefix = (ex: ErrType, code: ErrorCode) => {
  return ex.message.includes(AppOptions.SERVER_DEFINED_ERROR_PREFIX + code)
}

export default {
  checkMessageWithPrefix,
}
