import React from 'react'
import { ScheduleSetupPage } from '../../common/schedule/setting/ScheduleSetupPage'
import { useODDoctorAppContext } from '../context/ODDoctorAppContext'

interface IDoctorScheduleSetupPageProps {}

export const DoctorScheduleSetupPage: React.FC<IDoctorScheduleSetupPageProps> = props => {
  const {
    state: { profile },
  } = useODDoctorAppContext()

  if (!profile) {
    return null
  }

  return (
    <div>
      <ScheduleSetupPage idEditing={profile.doctorId} />
    </div>
  )
}
