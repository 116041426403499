import React from 'react'
import styled from 'styled-components'
import { ODVSpace } from '@mdpp/od-react-belt'

import { ROOM_TYPE } from './RoomCommon'
import { Button, BP } from '../../../common/RebeccaButton'
interface IRoomInfoBoxProps {
  type: ROOM_TYPE
  onStart?: () => void
  onExit?: () => void
  onRequest?: () => void
}

export const RoomInfoBox: React.FC<IRoomInfoBoxProps> = props => {
  const { type, onStart, onExit, onRequest } = props
  return (
    <>
    {type === ROOM_TYPE.INIT && (<Wrapper>
      <SmallText>진료 준비가 완료되었다면 환자에게 진료실 입장을 요청해주세요</SmallText>
      <ODVSpace h={20} />
      <Button size={BP.SIZE.L} type={BP.TYPE.Primary} onClick={onRequest}>입장 요청하기</Button>
    </Wrapper>)}
    {type === ROOM_TYPE.READY && (<Wrapper>
      <SmallText>환자에게 진료실 입장을 요청했습니다.</SmallText>
      <SmallText>잠시만 기다려주세요!</SmallText>
      <ODVSpace h={20} />
      <Button size={BP.SIZE.L} onClick={onRequest}>입장 재요청하기</Button>
    </Wrapper>)}
    {type === ROOM_TYPE.START_TREATMENT && (<Wrapper>
      <TextWrapper>
        <SmallText2>환자가 입장했습니다.</SmallText2>
        <ODVSpace h={32} />
        <SmallText2>진료가 끝난 후, 진료 마치기 버튼을</SmallText2>
        <SmallText2>눌러서 환자를 내보내주세요</SmallText2>
        <ODVSpace h={38} />
        <Button type={BP.TYPE.Primary} onClick={onStart}>네, 이해했어요</Button>
      </TextWrapper>
    </Wrapper>)}
    {type === ROOM_TYPE.PATIENT_EXIT && (<Wrapper>
      <SmallText>비정상 종료가 발생했습니다.</SmallText>
      <SmallText>잠시만 기다려주세요.</SmallText>
      <ODVSpace h={20} />
    </Wrapper>)}
    </>
  )
}

const Wrapper = styled.div`
  position: absolute;
  // left: calc(50% - 211px);
  top: calc(50% - 151px);
  display: flex;
  padding: 40px 60px 50px 60px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 302px; 
`

const BigText = styled.div`
  font-size: 20px;
  font-weight: 500;
  text-align: center;
  color: var(--mpPrimary500);
  margin-top: 12px;
  margin-bottom: 24px;
`

const SmallText = styled.div`
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  letter-spacing: -0.3px;
  color: #FFFFFF;
`
const SmallText2 = styled.div`
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.5px;
  text-transform: capitalize;
  color: #0085FF;
`

const StartDiagnosisButtonWrapper = styled.div`
  width: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 42px;
  font-size: 15px;
  color: var(--white);

  cursor: pointer;
`


const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 266px;
  height: 180px;
  background: #d0f1ff;
  border-radius: 8px;
  padding: 16px;
`