import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { SiteUrls } from '../SiteUrls'
import { AdminAuthorized } from './AdminAuthorized'
import { AdminLoginPage } from './AdminLoginPage'
import { AdminLogoutPage } from './AdminLogoutPage'
import { AdminMainLayout } from './AdminMainLayout'
import { SSEProvider } from "react-hooks-sse"
import { DoctorAuthorized } from "../doctor/DoctorAuthorized"
import { AppOptions, LOCAL_STORAGE_KEY_TOKEN } from "../AppOptions"
const sseEndpoint = `${AppOptions.SERVER_ADDRESS}/events?token=${localStorage.getItem(LOCAL_STORAGE_KEY_TOKEN)}&clientType=EvixAdmin`
export const AdminRoot: React.FC = () => {
  return (
    <Switch>
      <Route path={SiteUrls.Admin.Login} component={AdminLoginPage} />
      <Route path={SiteUrls.Admin.Logout} component={AdminLogoutPage} />
      <Route
        path={SiteUrls.Admin.Root}
        render={() => {
          return (
            <AdminAuthorized>
              <SSEProvider endpoint={sseEndpoint}>
                <AdminMainLayout />
              </SSEProvider>
            </AdminAuthorized>
          )
        }}
      />
      <Redirect to={SiteUrls.Admin.Dashboard.Main} />
    </Switch>
  )
}
