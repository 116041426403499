import * as changeCase from 'change-case'
import React from 'react'
import { GQLMEDICATION_DISPENSING_STATUS } from '@mdpp/common/lib/@types/server.schema'
import { WebUtils } from '../utils/webUtils'

interface IRReservationPharmactisistStatusBadgeProps {
  status: GQLMEDICATION_DISPENSING_STATUS
}

export const ReservationPharmactisistStatusBadge: React.FC<IRReservationPharmactisistStatusBadgeProps> = props => {
  const { status } = props
  return (
    <div className={`enum_tag_${changeCase.snakeCase(status)}`}>
      <span>{WebUtils.formatReservationPhramaStatus(status)}</span>
    </div>
  )
}
