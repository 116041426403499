import { ODListableResponseType, useODQuery } from '@mdpp/od-react-belt'
import React from 'react'
import {
  GQLListableDoctorReservationInput,
  GQLListableMedicationDispensingOutput,
  GQLListableReservation,
  GQLListableReservationInput,
  GQLListableWMedicationDispensingInput,
  GQLMEDICATION_DISPENSING_STATUS,
  GQLMedicationDispensing,
  GQLReservation,
  GQLRESERVATION_STATUS,
} from '@mdpp/common/lib/@types/server.schema'
import { GQL_LIST_MEDICATION_DISPENSING } from '../../../common/gqls_common'
import { IMedicineDataListableOption } from '../MedicineCommon'
import { IMedicationDispensingListableOption } from '../PharmacyCommon'

export function useReservationPharmacyListDataLoader(
  byPharmacy: boolean,
  pharmacyId?: number,
  statusFilter?: GQLMEDICATION_DISPENSING_STATUS[],
  startDate?: string,
  endDate?: string
) {
  // @ts-ignore
  const listApi = useODQuery<Partial<GQLListableWMedicationDispensingInput>, GQLListableMedicationDispensingOutput>(
    GQL_LIST_MEDICATION_DISPENSING
  )

  const apiByMedicationDispensing = React.useCallback(
    async function dataLoader(
      page: number,
      pageSize: number,
      afterKey: string | null,
      options: IMedicationDispensingListableOption
    ): Promise<ODListableResponseType<GQLMedicationDispensing>> {
      const r = await listApi({
        page,
        pageSize,
        ...options,
        pharmacyId,
        startDate,
        endDate,
        status: statusFilter,
      })
      return r as ODListableResponseType<GQLMedicationDispensing>
    },
    [pharmacyId, statusFilter, listApi, startDate, endDate]
  )

  return apiByMedicationDispensing
}
