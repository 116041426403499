import { ODListableResponseType } from '@mdpp/od-react-belt'
import React from 'react'
import { GQLWaitingPharmacist } from '@mdpp/common/lib/@types/server.schema'
import { useCommonAPIs } from '../../hooks/useCommonAPIs'
import { IPharmacistListableOption } from '../WaitingPharmacyCommon'

export function useWaitingPharmacyListDataLoader() {
  const { listWaitingPharmacist } = useCommonAPIs()
  return React.useCallback(
    async function dataLoader(
      page: number,
      pageSize: number,
      afterKey: string | null,
      options: IPharmacistListableOption
    ): Promise<ODListableResponseType<GQLWaitingPharmacist>> {
      const r = await listWaitingPharmacist({
        page,
        pageSize,
        ...options,
        emailAuthFlag: true,
      })
      return r as ODListableResponseType<GQLWaitingPharmacist>
    },
    [listWaitingPharmacist]
  )
}
