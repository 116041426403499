import React, { useEffect } from 'react'
import { BlockingLoadBox, ODHSpace, ODVSpace } from '@mdpp/od-react-belt'
import styled from 'styled-components/macro'
import { useHistory } from 'react-router-dom'
import { EvixButton, EvixButtonTheme } from '../../common/EvixButton'
import { EvixSelector } from '../../common/EvixSelector'
import { EvixInlineInputText } from '../../common/EvixInlineInputText'
import { validator } from '../../validator'
import { ErrorCode } from '@mdpp/common'
import { AddressSearchModal } from './AddressSearchModal'
//asset
import {
  Wrap,
  SignUpWrapNoPadding,
  Header,
  PageTitleWrap,
  ProcessWrap,
  PharmacyLogo,
  PageTitle,
  Step,
  StepCircle,
  StepText,
  WarningMessageWrap,
  Container,
  BtnWrap,
  Line,
  Inner,
  TitleWrap,
  Title,
  RequiredWrap,
  Oval,
  RequiredText,
  LabelWrap,
  Label,
  FormWrap,
  FormBlock,
  OvalGray,
} from '../../common/style/SignUpStyle'
import { InputCheckboxSupportWrapper, MessageIcon } from '../../common/style/CommonStyle'
import { EvixInput } from '../../common/EvixInput'
import { ConfirmModal } from '../../common/layout/ConfirmModal'
import _ from 'lodash'
//아이콘
import IconPharmacy from '../../assets/common/icon-pharmacy.png'
import IconWarningBlue from '../../assets/common/warning-blue.svg'
import { useCommonAPIs } from '../../common/hooks/useCommonAPIs'
import { checkMessageWithPrefix } from '../../utils/registerServerErrorMessages'
import { SwitchWrap, ToggleLabel } from '../../common/style/CommonStyle'
import { COLORS } from '../../styles/colors'
import Toggle from 'react-toggle'
import 'react-toggle/style.css'
import { WebUtils } from '../../utils/webUtils'
import IconDelete from '../../assets/common/icon_delete.png'
import IconWarningGray from '../../assets/common/icon_warning_small_gray.png'

export interface IEvixPrescriptionFiles {
  id: number
  fileName: string
  link: string
  file?: File
}

export const PharmacySignUpInfoPage: React.FC = () => {
  const { checkLicenseNumberPharmacist, createWaitingPharmacist, listHospitalForPharmacyChoose } = useCommonAPIs()
  const [loading, setLoading] = React.useState(false)
  const [showCertificationCompleteModal, setShowCertificationCompleteModal] = React.useState(false) // 인증 완료 안내 Modal
  const [showAddressSearchModal, setShowAddressSearchModal] = React.useState(false) // 주소검색 Modal
  // 가입정보
  const [name, setName] = React.useState('')
  const [birthDate, setBirthDate] = React.useState('')
  const [email, setEmail] = React.useState('')
  const [password, setPassword] = React.useState('')
  const [confirmPassword, setConfirmPassword] = React.useState('')
  const [licenseNumber, setLicenseNumber] = React.useState('')
  const [errMsg, setErrMsg] = React.useState('')
  const [errEmailMsg, setErrEmailMsg] = React.useState('')
  const [errCodeMsg, setErrCodeMsg] = React.useState('')
  const [errBusinessMsg, setBusinessCodeMsg] = React.useState('')
  const [hpNo1, setHpNo1] = React.useState('010')
  const [hpNo2, setHpNo2] = React.useState('')
  const [hpNo3, setHpNo3] = React.useState('')
  const [pharmacyName, setPharmacyName] = React.useState('')
  const [pharmaAddress1, setPharmaAddress1] = React.useState('')
  const [pharmaAddress2, setPharmaAddress2] = React.useState('')
  const [pharmaAddressDetail, setPharmaAddressDetail] = React.useState('')
  const [pharmacyFaxNumber, setPharmacyFaxNumber] = React.useState('')
  const [pharmacyPhoneNumber, setPharmacyPhoneNumber] = React.useState('')
  const [bankName, setBankName] = React.useState('')
  const [accountHolder, setAccountHolder] = React.useState('')
  const [accountNumber, setAccountNumber] = React.useState('')
  const [count, setCount] = React.useState(0)
  const [nextBtn, setNextBtn] = React.useState(true)
  const [checkBtn, setCheckBtn] = React.useState(true)
  const [businessLicenseNumber, setBusinessLicenseNumber] = React.useState('')
  const [fileName, setFileName] = React.useState('')
  const [fileLink, setFileLink] = React.useState('')
  const [bankBook, setBankBook] = React.useState('')
  const [bankBookFileLink, setBankBookFileLink] = React.useState('')
  const [availableDelivery, setAvailableDelivery] = React.useState(true)
  const [availableQuick, setAvailableQuick] = React.useState(false)
  const [notiMarketing, setNotiMarketing] = React.useState(true)
  const [data, setData] = React.useState<any[]>([])
  const [hospital, setHospital] = React.useState('')
  const [hospitalOptions, setHospitalOptions] = React.useState([
    { value: '연계 병원을 선택해주세요.', label: '연계 병원을 선택해주세요.', isDisabled: true },
  ])
  const options = [
    { value: '010', label: '010' },
    { value: '011', label: '011' },
    { value: '013', label: '013' },
    { value: '016', label: '016' },
    { value: '017', label: '017' },
    { value: '018', label: '018' },
    { value: '019', label: '019' },
  ]

  // 다음 버튼 클릭 시, 이동
  const history = useHistory()

  const handleNext = async () => {
    const cellPhone = hpNo1.concat(hpNo2, hpNo3)
    const files = fileLink[0]
    const bankBookFiles = bankBookFileLink[0]

    const datas = data.map(v => {
      const dataValue = v.value
      return dataValue
    })

    try {
      const join = await createWaitingPharmacist({
        name: name,
        email: email,
        password: password,
        licenseNumber: licenseNumber,
        cellPhone: cellPhone,
        birthDate: birthDate,
        pharmacyName: pharmacyName,
        pharmacyAddress: pharmaAddress1,
        pharmacyAddressDetail: pharmaAddressDetail,
        pharmacyZipCode: pharmaAddress2,
        pharmacyPhoneNumber: pharmacyPhoneNumber,
        pharmacyFaxNumber: pharmacyFaxNumber,
        businessLicenseNumber: businessLicenseNumber,
        businessLicenseFile: files,
        bankbookFile: bankBookFiles,
        bankName: bankName,
        accountHolder: accountHolder,
        accountNumber: accountNumber,
        availableDelivery: availableDelivery,
        availableQuick: availableDelivery === true ? true : false,
        notiMarketing: notiMarketing,
        hospitalId: datas,
      })
      let path = `/pharmacy/emailAuthentication`
      history.push({ pathname: path, state: { join: join } })
    } catch (err) {
      console.log(err)
      if (checkMessageWithPrefix(err, ErrorCode.EMAIL_ALREADY_EXIST)) {
        setErrEmailMsg('이미 가입한 이메일 아이디입니다.')
      }
      if (checkMessageWithPrefix(err, ErrorCode.RECOMMENDER_NOT_EXIST)) {
        setErrCodeMsg('존재하지 않는 추천코드입니다.')
      }
      if (checkMessageWithPrefix(err, ErrorCode.BUSINESS_LICENSE_ALREADY_EXIST)) {
        setBusinessCodeMsg('이미 등록된 기관입니다. 가입 정보 확인은 관리자에게 문의해주세요.')
      }
      // if (checkMessageWithPrefix(err, ErrorCode.LICENSE_ALREADY_EXIST)) {
      //   setBusinessCodeMsg('이미 등록된 면허번호입니다.')
      // }
    }
  }

  const resetConfirmPassword = (value: string) => {
    setConfirmPassword(value)
  }

  const handleCheckCode = async (licenseNumber: string) => {
    setErrMsg('')

    const licenseCode = licenseNumber.padStart(5, '0')
    setLicenseNumber(licenseCode)

    try {
      const r = await checkLicenseNumberPharmacist({ licenseNumber: licenseCode })
      if (r.ok === 0) {
        console.log('사용가능한 면허번호입니다.')
        setCount(1)
        setCheckBtn(true)
      } else {
        setErrMsg('이미 등록된 면허번호입니다.')
        setCount(0)
      }
    } catch (err) {
      console.log(err)
    } finally {
    }
  }

  const CheckPharmaNo = (value: string) => {
    if (value.length > 0) {
      setErrMsg('중복확인 버튼을 눌러주세요.')
      setCheckBtn(false)
    } else {
      setCheckBtn(true)
    }
    setLicenseNumber(value)
  }

  const selectHpValueHandler = (obj: any) => {
    const newObj = _.cloneDeep(obj)
    setHpNo1(newObj.value)
  }

  const onChangePhoneNumber = (value: string, setState: Function) => {
    const onlyNumber = value.replace(/[^0-9]/g, '')
    setState(onlyNumber)
  }

  const handleAddressSelect = (address: string, zonecode: string) => {
    setPharmaAddress1(address)
    setPharmaAddress2(zonecode)
  }

  const fileChangedHandler = async (e: any) => {
    const files = e.target.files
    const fileSize = files[0]?.size

    if (files[0]?.name === null) {
      return console.log('파일없음')
    }
    if (fileSize > 50000000) {
      return WebUtils.showError('첨부파일 사이즈는 50MB 이내로 등록 가능합니다.')
    }
    setFileLink(files)
    setFileName(files[0]?.name)
  }

  const getOptions = async () => {
    try {
      const res = await listHospitalForPharmacyChoose({})
      const dataList = res.list
      const options = dataList.map((d: any) => ({
        value: d.hospitalId,
        label: `${d.name}/${d.address}`,
        isDisabled: false,
      }))
      setHospitalOptions([
        { value: '연계 병원을 선택해주세요.', label: '연계 병원을 선택해주세요.', isDisabled: true },
        ...options,
      ])
    } catch (e) {
      console.log(e)
    }
  }

  const bankBookFileChangedHandler = async (e: any) => {
    const files = e.target.files
    const fileSize = files[0]?.size

    if (files[0]?.name === null) {
      return console.log('파일없음')
    }
    if (fileSize > 50000000) {
      return WebUtils.showError('첨부파일 사이즈는 50MB 이내로 등록 가능합니다.')
    }
    setBankBookFileLink(files)
    setBankBook(files[0]?.name)
  }

  const UpDateDateList = (value: any) => {
    let names = _.map(data, 'label')
    const result = names.indexOf(value.label)

    if (data.length > 4) {
      return WebUtils.showError('5개 이상 등록할 수 없습니다.')
    }
    if (value.value === '연계 병원을 선택해주세요.') {
      return
    }
    if (result > -1) {
      return WebUtils.showError('이미 등록한 병원입니다.')
    }
    setData(data.concat(value))
  }

  const onChangeHospital = (value: string) => {
    setHospital(value)
    UpDateDateList(value)
  }

  const hospitalList = _.uniq(data).map((v, idx: number) => (
    <SelectedItem key={idx}>
      <span>{v.label}</span>
      <button type="button" onClick={() => handelDeleteHospital(v.label)}>
        <img src={IconDelete} style={{ width: 12, height: 12 }} />
      </button>
    </SelectedItem>
  ))

  const handelDeleteHospital = (label: string) => {
    setData(data?.filter(data => data?.label !== label))
  }

  const HandleToggle = () => {
    setAvailableDelivery(!availableDelivery)
  }

  const HandleMarketingCheck = () => {
    setNotiMarketing(!notiMarketing)
  }

  useEffect(() => {
    getOptions()
  }, [])

  useEffect(() => {
    if (!name || Object.keys(validator.username(name)).length !== 0) {
      return setNextBtn(true)
    }
    if (!licenseNumber || Object.keys(validator.account(licenseNumber)).length !== 0) {
      return setNextBtn(true)
    }
    if (count !== 1 || errCodeMsg !== '' || errEmailMsg !== '') {
      return setNextBtn(true)
    }
    if (!email || Object.keys(validator.email(email)).length !== 0) {
      return setNextBtn(true)
    }
    if (!birthDate || Object.keys(validator.birth(birthDate)).length > 0) {
      return setNextBtn(true)
    }
    if (!password || validator.password(password)?.errors?.length) {
      return setNextBtn(true)
    }
    if (!confirmPassword || Object.keys(validator.passwordConfirm(confirmPassword, password)).length !== 1) {
      return setNextBtn(true)
    }
    if (!hpNo2 || !hpNo3 || !fileName || !bankBook) {
      return setNextBtn(true)
    }
    if (!businessLicenseNumber || Object.keys(validator.account(businessLicenseNumber)).length > 0) {
      return setNextBtn(true)
    }
    if (!pharmacyName || !pharmacyPhoneNumber || !pharmaAddress1 || !pharmaAddressDetail || !pharmacyFaxNumber) {
      return setNextBtn(true)
    }
    if (!accountHolder || !bankName || !accountNumber || Object.keys(validator.account(accountNumber)).length !== 0) {
      return setNextBtn(true)
    }
    setNextBtn(false)
  }, [
    name,
    licenseNumber,
    email,
    birthDate,
    password,
    confirmPassword,
    CheckPharmaNo,
    checkBtn,
    pharmacyName,
    pharmaAddress1,
    pharmaAddress2,
    pharmaAddressDetail,
    hpNo1,
    hpNo2,
    hpNo3,
    businessLicenseNumber,
    pharmacyPhoneNumber,
    fileName,
    bankBook,
    accountHolder,
    bankName,
    accountNumber,
    availableDelivery,
    availableQuick,
    notiMarketing,
    pharmacyFaxNumber,
  ])

  useEffect(() => {
    setCount(0)
    if (licenseNumber.length > 0) {
      setCheckBtn(false)
    }
  }, [licenseNumber])

  useEffect(() => {
    setErrEmailMsg('')
  }, [email])

  useEffect(() => {
    setBusinessCodeMsg('')
  }, [businessLicenseNumber])

  useEffect(() => {
    if (password !== confirmPassword) {
      resetConfirmPassword('')
    }
  }, [password])

  return (
    <>
      <BlockingLoadBox show={loading} />

      {/* 인증 완료 안내 Modal */}
      {showCertificationCompleteModal && (
        <ConfirmModal
          style={{ width: 420, height: 274 }}
          isTitle={true}
          title={'이메일 인증 완료'}
          titleIcon={IconWarningBlue}
          isDescriptionIcon={false}
          description={[
            '관리자의 승인 후 로그인이 가능합니다.',
            <br />,
            '승인 완료 시 입력하신 이메일로 안내될 예정입니다.',
            <br />,
            '참고 부탁드립니다. 감사합니다.',
          ]}
          descriptionIcon={''}
          hideCancel={true}
          onOkPress={() => setShowCertificationCompleteModal(false)}
          onCancelPress={() => setShowCertificationCompleteModal(false)}
        />
      )}

      <Wrap>
        <SignUpWrapNoPadding>
          <Header style={{ paddingLeft: 90, paddingRight: 80 }}>
            <PageTitleWrap>
              <img src={IconPharmacy} style={PharmacyLogo} alt="약국 아이콘" />
              <PageTitle>회원가입</PageTitle>
            </PageTitleWrap>
            <ProcessWrap>
              <Step>
                <StepCircle>
                  <span>1</span>
                </StepCircle>
                <StepText>약관 동의</StepText>
              </Step>
              <Step className="active">
                <StepCircle>
                  <span>2</span>
                </StepCircle>
                <StepText>가입정보 입력</StepText>
              </Step>
              <Step>
                <StepCircle>
                  <span>3</span>
                </StepCircle>
                <StepText>이메일 인증</StepText>
              </Step>
              <Step>
                <StepCircle>
                  <span>4</span>
                </StepCircle>
                <StepText>가입 승인 요청</StepText>
              </Step>
            </ProcessWrap>
          </Header>
          <ODVSpace h={18} />
          <Line />
          <ODVSpace h={54} />
          <Container style={{ alignItems: 'center' }}>
            <Inner>
              <TitleWrap>
                <Title style={{ paddingBottom: 0 }}>기본 정보</Title>
                <RequiredWrap>
                  <Oval></Oval>
                  <RequiredText>필수입력 항목입니다.</RequiredText>
                </RequiredWrap>
              </TitleWrap>
              <ODVSpace h={36} />
              {/* 기본정보 */}
              <FormWrap>
                <FormBlock>
                  <LabelWrap>
                    <Label>이름 (한글)</Label>
                    <Oval></Oval>
                  </LabelWrap>
                  <EvixInput
                    style={{ width: '100%' }}
                    value={name}
                    validators={validator.username}
                    onChange={value => setName(value)}
                    disabled={false}
                  />
                </FormBlock>

                <FormBlock>
                  <LabelWrap>
                    <Label>생년월일</Label>
                    <Oval></Oval>
                  </LabelWrap>
                  <EvixInput
                    style={{ width: '100%' }}
                    placeholder="예) 1970년 1월 1일 → 19700101 입력"
                    maxLength={8}
                    value={birthDate}
                    validators={validator.birth}
                    onChange={value => setBirthDate(value)}
                    disabled={false}
                  />
                  <WarningMessageWrap
                    className={Object.keys(validator.birth(birthDate)).length !== 0 ? 'hide' : ''}
                  ></WarningMessageWrap>
                </FormBlock>
              </FormWrap>

              <FormWrap>
                <FormBlock>
                  <LabelWrap>
                    <Label>이메일 주소 (ID)</Label>
                    <Oval></Oval>
                  </LabelWrap>
                  <EvixInput
                    style={{ width: '100%' }}
                    value={email}
                    validators={validator.email}
                    onChange={value => setEmail(value)}
                    disabled={false}
                    maxLength={50}
                  />
                  <WarningMessageWrap className={Object.keys(validator.email(email)).length !== 0 ? 'hide' : ''}>
                    <span className="error" style={{ fontSize: 13, fontWeight: 500, color: '#ff5353' }}>
                      {errEmailMsg}
                    </span>
                  </WarningMessageWrap>
                </FormBlock>
              </FormWrap>

              <FormWrap className="password">
                <FormBlock>
                  <LabelWrap>
                    <Label>비밀번호</Label>
                    <Oval></Oval>
                  </LabelWrap>
                  <EvixInput
                    type="password"
                    style={{ width: '100%' }}
                    placeholder="8~20자 영문 대 소문자, 숫자, 특수문자를 모두 사용."
                    value={password}
                    validators={validator.password}
                    onChange={value => setPassword(value)}
                    disabled={false}
                    maxLength={20}
                    children
                  />
                </FormBlock>

                <FormBlock>
                  <LabelWrap>
                    <Label>비밀번호 확인</Label>
                    <Oval></Oval>
                  </LabelWrap>
                  <EvixInput
                    type="password"
                    style={{ width: '100%' }}
                    value={confirmPassword}
                    onChange={resetConfirmPassword}
                    validators={(value: string) => validator.passwordConfirm(value, password)}
                    disabled={false}
                    maxLength={20}
                    children
                  />
                </FormBlock>
              </FormWrap>

              <FormWrap>
                <FormBlock>
                  <LabelWrap>
                    <Label>약사 면허번호</Label>
                    <Oval></Oval>
                  </LabelWrap>
                  <DoctorNoWrap className="w-100">
                    <EvixInput
                      style={{ flex: 1 }}
                      value={licenseNumber}
                      onChange={value => CheckPharmaNo(value)}
                      validators={validator.account}
                      disabled={false}
                      maxLength={5}
                    />
                    <EvixButton
                      theme={EvixButtonTheme.H48WhiteInline}
                      onClick={() => handleCheckCode(licenseNumber)}
                      disabled={checkBtn}
                    >
                      중복확인
                    </EvixButton>
                  </DoctorNoWrap>
                  <WarningMessageWrap
                    className={Object.keys(validator.account(licenseNumber)).length !== 0 ? 'hide' : ''}
                  >
                    <span className="error" style={{ fontSize: 13, fontWeight: 500, color: '#ff5353' }}>
                      {errMsg}
                    </span>
                  </WarningMessageWrap>
                </FormBlock>

                <FormBlock>
                  <LabelWrap>
                    <Label>휴대폰 번호</Label>
                    <Oval></Oval>
                  </LabelWrap>
                  <div className="hp-wrap">
                    <EvixSelector
                      style={{ width: 80 }}
                      value={hpNo1}
                      placeholder={'010'}
                      options={options}
                      onChange={e => selectHpValueHandler(e)}
                    />
                    <EvixInput
                      style={{ width: 80, height: 44 }}
                      value={hpNo2}
                      onChange={value => onChangePhoneNumber(value, setHpNo2)}
                      disabled={false}
                      maxLength={4}
                    />
                    <EvixInput
                      style={{ width: 80, height: 44 }}
                      value={hpNo3}
                      onChange={value => onChangePhoneNumber(value, setHpNo3)}
                      disabled={false}
                      maxLength={4}
                    />
                  </div>
                  <WarningMessageWrap>
                    <ul>
                      <li>
                        <OvalGray></OvalGray> 휴대폰 번호로 진료 예약 관련 알림과 마케팅 정보를 안내합니다.
                      </li>
                      <li>
                        <OvalGray></OvalGray> 마케팅 정보 수신 동의여부
                        <InputCheckboxSupportWrapper style={{ marginLeft: 10 }}>
                          <input type="checkbox" id="radio1" onChange={HandleMarketingCheck} checked={notiMarketing} />
                          <label htmlFor="radio1">동의</label>
                        </InputCheckboxSupportWrapper>
                      </li>
                    </ul>
                  </WarningMessageWrap>
                </FormBlock>
              </FormWrap>
            </Inner>
            <ODVSpace h={47} />
            <Line />
            <ODVSpace h={54} />

            <Inner>
              <TitleWrap>
                <Title style={{ paddingBottom: 0 }}>약국 정보</Title>
                <RequiredWrap>
                  <Oval></Oval>
                  <RequiredText>필수입력 항목입니다.</RequiredText>
                </RequiredWrap>
              </TitleWrap>
              <ODVSpace h={36} />

              <FormWrap>
                <FormBlock>
                  <LabelWrap>
                    <Label>약국 명</Label>
                    <Oval></Oval>
                  </LabelWrap>
                  <EvixInlineInputText
                    style={{ width: '100%' }}
                    value={pharmacyName}
                    onChange={value => setPharmacyName(value)}
                    disabled={false}
                    maxLength={50}
                  />
                  <WarningMessageWrap></WarningMessageWrap>
                </FormBlock>

                <FormBlock>
                  <LabelWrap>
                    <Label>약국 연락처</Label>
                    <Oval></Oval>
                  </LabelWrap>
                  <EvixInput
                    style={{ width: '100%' }}
                    value={pharmacyPhoneNumber}
                    onChange={value => setPharmacyPhoneNumber(value)}
                    disabled={false}
                    maxLength={173}
                    validators={validator.account}
                  />
                  <WarningMessageWrap
                    className={Object.keys(validator.account(pharmacyPhoneNumber)).length !== 0 ? 'hide' : ''}
                  ></WarningMessageWrap>
                </FormBlock>
              </FormWrap>

              <FormWrap>
                <FormBlock className="w-100">
                  <LabelWrap>
                    <Label>약국 주소</Label>
                    <Oval></Oval>
                  </LabelWrap>
                  <PharmacyNameWrap onClick={() => setShowAddressSearchModal(true)}>
                    <EvixInlineInputText style={{ width: '100%' }} value={pharmaAddress1} disabled={true} />
                    <EvixInlineInputText style={{ width: 100 }} value={pharmaAddress2} disabled={true} />
                    <EvixButton
                      theme={EvixButtonTheme.H48WhiteInline}
                      onClick={() => {
                        setShowAddressSearchModal(true)
                      }}
                      disabled={false}
                    >
                      검색
                    </EvixButton>
                  </PharmacyNameWrap>
                  <ODVSpace h={10} />
                  <EvixInlineInputText
                    style={{ width: '100%' }}
                    placeholder={'상세 주소'}
                    value={pharmaAddressDetail}
                    onChange={value => setPharmaAddressDetail(value)}
                    maxLength={74}
                  />
                  <WarningMessageWrap></WarningMessageWrap>
                </FormBlock>
              </FormWrap>

              <FormWrap>
                <FormBlock>
                  <LabelWrap>
                    <Label>사업자등록번호</Label>
                    <Oval></Oval>
                  </LabelWrap>
                  <EvixInput
                    style={{ width: '100%' }}
                    value={businessLicenseNumber}
                    onChange={value => setBusinessLicenseNumber(value)}
                    disabled={false}
                    validators={validator.account}
                  />
                  <WarningMessageWrap
                    className={Object.keys(validator.account(businessLicenseNumber)).length !== 0 ? 'hide' : ''}
                  >
                    <span className="error" style={{ fontSize: 13, fontWeight: 500, color: '#ff5353' }}>
                      {errBusinessMsg}
                    </span>
                  </WarningMessageWrap>
                </FormBlock>

                <FormBlock>
                  <LabelWrap>
                    <Label>FAX</Label>
                    <Oval></Oval>
                  </LabelWrap>
                  <EvixInput
                    style={{ width: '100%' }}
                    validators={validator.account}
                    value={pharmacyFaxNumber}
                    onChange={value => setPharmacyFaxNumber(value)}
                    disabled={false}
                    maxLength={173}
                  />
                  <WarningMessageWrap
                    className={Object.keys(validator.account(pharmacyFaxNumber)).length !== 0 ? 'hide' : ''}
                  ></WarningMessageWrap>
                </FormBlock>
              </FormWrap>

              <FormWrap>
                <FormBlock>
                  <LabelWrap>
                    <Label>사업자등록증</Label>
                    <Oval></Oval>
                  </LabelWrap>
                  <PharmacyNameWrap>
                    <FileInput>
                      {fileName ? (
                        fileName
                      ) : (
                        <>
                          <span className="placeholer">PDF, JPG, PNG 파일만 첨부 가능합니다.</span>
                        </>
                      )}
                    </FileInput>
                    <FileLabel htmlFor="input-file">
                      {' '}
                      사업자등록증 첨부
                      <input
                        name="businessLicenseFile"
                        type="file"
                        id="input-file"
                        style={{ display: 'none' }}
                        accept=".pdf, image/png, image/jpeg, image/jpg"
                        onChange={e => fileChangedHandler(e)}
                      />
                    </FileLabel>
                  </PharmacyNameWrap>
                  <WarningMessageWrap>
                    <ul>
                      <li className="align-center">
                        <MessageIcon src={IconWarningBlue} alt="warning icon" />
                        비대면 진료 정산을 위해 필요합니다.
                      </li>
                    </ul>
                  </WarningMessageWrap>
                </FormBlock>
                <FormBlock>
                  <LabelWrap>
                    <Label>통장사본</Label>
                    <Oval></Oval>
                  </LabelWrap>
                  <PharmacyNameWrap>
                    <FileInput>
                      {bankBook ? (
                        bankBook
                      ) : (
                        <>
                          <span className="placeholer">PDF, JPG, PNG 파일만 첨부 가능</span>
                        </>
                      )}
                    </FileInput>
                    <FileLabel htmlFor="input-bank-file">
                      {' '}
                      통장사본 첨부
                      <input
                        name="bankBookFile"
                        type="file"
                        id="input-bank-file"
                        style={{ display: 'none' }}
                        accept=".pdf, image/png, image/jpeg, image/jpg"
                        onChange={e => bankBookFileChangedHandler(e)}
                      />
                    </FileLabel>
                  </PharmacyNameWrap>
                  <WarningMessageWrap>
                    <ul>
                      <li className="align-center">
                        <MessageIcon src={IconWarningBlue} alt="warning icon" />
                        조제비 정산을 위해 필요합니다.
                      </li>
                    </ul>
                  </WarningMessageWrap>
                </FormBlock>
              </FormWrap>
              <ODVSpace h={20} />
              <FormWrap>
                <FormBlock>
                  <LabelWrap>
                    <Label>병원 선택 (최대 5곳 선택가능) </Label>
                  </LabelWrap>
                  <EvixSelector
                    style={{ width: '100%' }}
                    value={'연계 병원을 선택해주세요.'}
                    placeholder={'연계 병원을 선택해주세요.'}
                    options={hospitalOptions}
                    onChange={value => onChangeHospital(value)}
                  />
                  <SelectedItemWrap>{hospitalList}</SelectedItemWrap>
                  <WarningMessageWrap></WarningMessageWrap>
                </FormBlock>
              </FormWrap>

              <FormWrap>
                <FormBlock className="w-100">
                  <LabelWrap>
                    <Label>의약품 택배 가능여부</Label>
                    <Oval></Oval>
                  </LabelWrap>
                  <RowWrap>
                    <SwitchWrap style={{ marginTop: 0 }}>
                      <Toggle checked={availableDelivery} icons={false} onChange={HandleToggle} />
                      <ToggleLabel>
                        <span>불가능</span>
                        <span>가능</span>
                      </ToggleLabel>
                    </SwitchWrap>
                    <ODHSpace w={50} />
                    {availableDelivery === true && (
                      <>
                        <InputCheckboxSupportWrapper>
                          <input
                            type="checkbox"
                            id="cb1"
                            onChange={() => {
                              setAvailableQuick(true)
                            }}
                            checked={true}
                          />
                          <label htmlFor="cb1">퀵 배송</label>
                        </InputCheckboxSupportWrapper>
                        {/* <InputCheckboxSupportWrapper>
                      <input type="checkbox" id="cb2" onChange={() => {
                                                        setAvailableQuick(availableQuick)
                                                      }} checked={availableQuick} />
                      <label htmlFor="cb2">택배 배송</label>
                    </InputCheckboxSupportWrapper> */}
                      </>
                    )}
                  </RowWrap>
                  <WarningMessageWrap>
                    <ul>
                      <li>
                        <OvalGray></OvalGray> 배송이 불가능한 경우 입력하신 FAX로 메디팡팡 회원의 처방전을 받으실 수
                        있습니다.
                      </li>
                    </ul>
                  </WarningMessageWrap>
                </FormBlock>
              </FormWrap>

              {/* {availableDelivery === true && (
                <>
                  <input
                    type="radio"
                    id="radio1"
                    onChange={() => {
                      setAvailableQuick(true)
                    }}
                    checked={availableQuick}
                  />
                  <label htmlFor="radio1" style={{ marginBottom: 0 }}>
                    퀵배송
                  </label>
                  <ODHSpace w={10} />
                  <input
                    type="radio"
                    id="radio2"
                    onChange={() => {
                      setAvailableQuick(false)
                    }}
                    checked={!availableQuick}
                  />
                  <label htmlFor="radio2" style={{ marginBottom: 0 }}>
                    택배 배송
                  </label>
                </>
              )} */}
            </Inner>

            <ODVSpace h={39} />
            <Line />
            <ODVSpace h={46} />

            <Inner>
              <TitleWrap>
                <Title style={{ paddingBottom: 0 }}>계좌 정보</Title>
              </TitleWrap>
              <RequiredText style={{ display: 'block', marginTop: 5 }}>
                조제비 정산을 위한 정보입니다. 정확하게 입력 부탁드립니다.{' '}
              </RequiredText>
              <ODVSpace h={36} />
              <FormWrap>
                <FormBlock>
                  <LabelWrap>
                    <Label>은행명</Label>
                    <Oval></Oval>
                  </LabelWrap>
                  <EvixInput
                    style={{ width: '100%' }}
                    value={bankName}
                    onChange={value => setBankName(value)}
                    disabled={false}
                  />
                </FormBlock>
                <FormBlock>
                  <LabelWrap>
                    <Label>예금주</Label>
                    <Oval></Oval>
                  </LabelWrap>
                  <EvixInput
                    style={{ width: '100%' }}
                    value={accountHolder}
                    onChange={value => setAccountHolder(value)}
                    disabled={false}
                  />
                </FormBlock>
              </FormWrap>

              <FormWrap>
                <FormBlock>
                  <LabelWrap>
                    <Label>계좌번호</Label>
                    <Oval></Oval>
                  </LabelWrap>
                  <EvixInput
                    style={{ width: '100%' }}
                    value={accountNumber}
                    validators={validator.account}
                    onChange={value => setAccountNumber(value)}
                    disabled={false}
                  />
                  <WarningMessageWrap
                    className={Object.keys(validator.account(accountNumber)).length !== 0 ? 'hide' : ''}
                  ></WarningMessageWrap>
                </FormBlock>
              </FormWrap>
            </Inner>
            <ODVSpace h={16} />
            <Line />
            <ODVSpace h={80} />

            <BtnWrap>
              <EvixButton theme={EvixButtonTheme.H48Black} onClick={() => handleNext()} disabled={nextBtn}>
                다음
              </EvixButton>
            </BtnWrap>
          </Container>
        </SignUpWrapNoPadding>
      </Wrap>
      {showAddressSearchModal && (
        <AddressSearchModal
          // style={{ width: 880, height: 764, maxWidth: 880 }}
          onSelected={handleAddressSelect}
          onCancelPress={() => setShowAddressSearchModal(false)}
        />
      )}
    </>
  )
}

const DoctorNoWrap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;

  &.w-100 {
    width: 100%;
  }
`

const FileLabel = styled.label`
  font-size: 13px;
  font-weight: 500;
  font-style: normal;
  color: ${COLORS.GRAY42};
  margin-bottom: 0;
  padding: 12px 5px;
  height: 44px;
  width: 120px;
  line-height: 18px;
  border-radius: 4px;
  border: 1px solid ${COLORS.GRAYAB2};
  background-color: ${COLORS.WHITE};
  text-align: center;
  cursor: pointer;
`

const FileInput = styled.label`
  font-size: 13px;
  font-weight: 500;
  font-style: normal;
  color: ${COLORS.GRAY42};
  margin-bottom: 0;
  padding: 12px;
  height: 44px;
  width: calc(100% - 130px);
  line-height: 18px;
  border-radius: 4px;
  border: 1px solid #dfe0e2;
  background-color: #eeeff1;
  text-align: left;
  cursor: pointer;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  span {
    color: #acb4bc;
  }
`

export const PharmacyNameWrap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
  width: 100%;
`

export const RowWrap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
`
const SelectedItemWrap = styled.div`
  width: 100%;
  margin-top: 10px;
`

const SelectedItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0;
  padding: 0 14px;
  height: 44px;
  border-radius: 4px;
  border: 1px solid #dfe0e2;
  background-color: #fff;
  text-align: left;
  cursor: pointer;
  margin-bottom: 5px;

  > span {
    font-size: 13px;
    font-weight: 500;
    font-style: normal;
    color: #7c818b;
    line-height: 18px;
  }

  > button {
  }
`
