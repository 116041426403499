import { ODRoute } from '@mdpp/od-react-belt'
import React from 'react'
import { FaqEditPage } from '../common/faq/edit/FaqEditPage'
import { FaqListableTable } from '../common/faq/list/FaqListableTable'
import { InquiryListableTable } from '../common/inquiry/list/InquiryListableTable'
import { NotImplementedPage } from '../common/layout/NotImplementedPage'
import { NoticeItemPage } from '../common/notice/list/NoticeItemPage'
import { NoticeListableTable } from '../common/notice/list/NoticeListableTable'
import { NoticeFilter } from '../common/notice/NoticeCommon'
import { ReservationDetailPage } from '../doctor/reservation/detail/ReservationDetailPage'
import { SiteUrls } from '../SiteUrls'
import { AppVersionEditPage } from './appVersion/edit/AppVersionEditPage'
import { AdminAppVersionListPage } from './appVersion/list/AdminAppVersionListPage'
import { AdminDashboardContainer } from './dashboard/AdminDashboardContainer'
import { AdminDoctorListPage } from './doctor/AdminDoctorListPage'
import { AdminDoctorDetailPageContainer } from './doctor/detail/AdminDoctorDetailPage'
import { AdminHospitalListPage } from './hospital/AdminHopsitalListPage'
import { AdminHospitalEditPage } from './hospital/AdminHospitalEditPage'
import { AdminHospitalManagerListPage } from './hospitalManager/AdminHopsitalManagerListPage'
import { HospitalManagerEditPage } from './hospitalManager/edit/HospitalManagerEditPage'
import { AdminPharmacyHasHospitalListPage } from './hospitalParmacy/AdminPharmacyHasHospitalListPage'
import { AdminMedicalSubjectEditPage } from './medicalSubject/AdminMedicalSubjectEditPage'
import { AdminMedicalSubjectListPage } from './medicalSubject/AdminMedicalSubjectListPage'
import { AdminNoticeEditPage } from './notice/AdminNoticeEditPage'
import { AdminPatientDetailPage } from './patient/AdminPatientDetailPage'
import { AdminPatientListPage } from './patient/AdminPatientListPage'
import { AdminPharmacyEditPage } from './pharmacy/AdminPharmacyEditPage'
import { AdminPharmacyListPage } from './pharmacy/AdminPharmacyListPage'
import { AdminReservationListPage } from './reservation/AdminReservationListPage'
import { AdminReviewListPage } from './review/AdminReviewListPage'
import { AdminDoctorScheduleSetupPage } from './schedule/AdminDoctorScheduleSetupPage'
import { AdminDoctorScheduleTablePage } from './schedule/AdminDoctorScheduleTablePage'
import { InquiryItemPage } from '../common/inquiry/detail/InquiryItemPage'
import { AdminWaitingDoctorListPage } from './waitingDoctor/AdminWaitingDoctorListPage'
import { AdminWaitingDoctorDetailPageContainer } from './waitingDoctor/detail/AdminWaitingDoctorDetailPage'
import { AdminWaitingPharmacyListPage } from './watingPharma/AdminWaitingPharmacyListPage'
import { AdminWaitingPharmaDetailPageContainer } from './watingPharma/detail/AdminWaitingPharmaDetailPage'

// https://github.com/ReactTraining/react-router/tree/master/packages/react-router-config
export const AdminRoutes: ODRoute[] = [
  { path: SiteUrls.Admin.Dashboard.Main, exact: true, name: `대시보드`, component: AdminDashboardContainer },
  { path: SiteUrls.Admin.Hospital.Main, exact: true, name: `병원관리`, component: AdminHospitalListPage },
  {
    path: SiteUrls.Admin.Hospital.Create,
    exact: true,
    name: `병원 추가`,
    render: (props: any) => <AdminHospitalEditPage idEditing={null} />,
  },
  {
    path: SiteUrls.Admin.Hospital.Edit(':id'),
    exact: true,
    name: `병원 수정`,
    render: (props: any) => <AdminHospitalEditPage idEditing={parseInt(props.match.params.id, 10)} />,
  },
  {
    path: SiteUrls.Admin.Hospital.Pharmacy(':id'),
    exact: true,
    name: `약국 관리`,
    render: (props: any) => <AdminPharmacyHasHospitalListPage hospitalId={parseInt(props.match.params.id, 10)} />,
  },
  {
    path: SiteUrls.Admin.Hospital.Manager.List(':id'),
    exact: true,
    name: `관리자`,
    render: (props: any) => <AdminHospitalManagerListPage hospitalId={parseInt(props.match.params.id, 10)} />,
  },
  {
    path: SiteUrls.Admin.Hospital.Manager.Create(':hospitalId'),
    exact: true,
    name: `추가`,
    render: (props: any) => (
      <HospitalManagerEditPage hospitalId={parseInt(props.match.params.hospitalId, 10)} idEditing={null} />
    ),
  },
  {
    path: SiteUrls.Admin.Hospital.Manager.Edit(':hospitalId', ':id'),
    exact: true,
    name: `수정`,
    render: (props: any) => (
      <HospitalManagerEditPage
        hospitalId={parseInt(props.match.params.hospitalId, 10)}
        idEditing={parseInt(props.match.params.id, 10)}
      />
    ),
  },
  { path: SiteUrls.Admin.Doctor.Main, exact: true, name: `의사관리`, component: AdminDoctorListPage },
  {
    path: SiteUrls.Admin.Doctor.Edit(':id'),
    exact: true,
    name: `의사관리`,
    render: (props: any) => <AdminDoctorDetailPageContainer doctorId={parseInt(props.match.params.id, 10)} />,
  },
  {
    path: SiteUrls.Admin.Doctor.SetupScheduleDefault(':id'),
    exact: true,
    name: `설정`,
    render: (props: any) => <AdminDoctorScheduleSetupPage doctorId={parseInt(props.match.params.id, 10)} />,
  },
  {
    path: SiteUrls.Admin.Doctor.Schedule(':id'),
    exact: true,
    name: `스케줄`,
    render: (props: any) => <AdminDoctorScheduleTablePage doctorId={parseInt(props.match.params.id, 10)} />,
  },
  { path: SiteUrls.Admin.WatingDoctor.Main, exact: true, name: `대기의사관리`, component: AdminWaitingDoctorListPage },
  {
    path: SiteUrls.Admin.WatingDoctor.Edit(':id'),
    exact: true,
    name: `대기의사관리`,
    render: (props: any) => <AdminWaitingDoctorDetailPageContainer doctorId={parseInt(props.match.params.id, 10)} />,
  },
  { path: SiteUrls.Admin.Patient.Main, exact: true, name: `환자`, component: AdminPatientListPage },
  {
    path: SiteUrls.Admin.Patient.Detail(':id'),
    exact: true,
    name: `상세`,
    render: (props: any) => <AdminPatientDetailPage patientId={parseInt(props.match.params.id, 10)} />,
  },
  { path: SiteUrls.Admin.Reservation.Main, exact: true, name: `진료`, component: AdminReservationListPage },
  {
    path: SiteUrls.Admin.Reservation.Detail(':id'),
    exact: true,
    name: `상세`,
    render: () => <ReservationDetailPage fromAdmin />,
  },
  { path: SiteUrls.Admin.Review.Main, exact: true, name: `리뷰`, component: AdminReviewListPage },
  { path: SiteUrls.Admin.Pharmacy.Main, exact: true, name: `약국`, component: AdminPharmacyListPage },
  {
    path: SiteUrls.Admin.Pharmacy.Create,
    exact: true,
    name: `약국 추가`,
    render: (props: any) => <AdminPharmacyEditPage idEditing={null} />,
  },
  {
    path: SiteUrls.Admin.WatingPharmacy.Main,
    exact: true,
    name: `대기약국관리`,
    component: AdminWaitingPharmacyListPage,
  },
  {
    path: SiteUrls.Admin.WatingPharmacy.Edit(':id'),
    exact: true,
    name: `대기약국관리`,
    render: (props: any) => (
      <AdminWaitingPharmaDetailPageContainer waitingPharmacistId={parseInt(props.match.params.id, 10)} />
    ),
  },
  {
    path: SiteUrls.Admin.Pharmacy.Edit(':id'),
    exact: true,
    name: `약국 수정`,
    render: (props: any) => <AdminPharmacyEditPage idEditing={parseInt(props.match.params.id, 10)} />,
  },
  {
    path: SiteUrls.Admin.MedicalSubject.Create,
    exact: true,
    name: `진료과목 추가`,
    render: (props: any) => <AdminMedicalSubjectEditPage idEditing={null} />,
  },
  {
    path: SiteUrls.Admin.MedicalSubject.Edit(':id'),
    exact: true,
    name: `진료과목 수정`,
    render: (props: any) => <AdminMedicalSubjectEditPage idEditing={parseInt(props.match.params.id, 10)} />,
  },
  {
    path: SiteUrls.Admin.MedicalSubject.Main,
    exact: true,
    name: `진료과목 관리`,
    component: AdminMedicalSubjectListPage,
  },
  { path: SiteUrls.Admin.AppVersion.Main, exact: true, name: `앱 버전`, component: AdminAppVersionListPage },
  { path: SiteUrls.Admin.AppVersion.Create, exact: true, name: `앱 버전 추가`, component: AppVersionEditPage },
  {
    path: SiteUrls.Admin.AppVersion.Edit(':id'),
    exact: true,
    name: `수정`,
    render: (props: any) => <AppVersionEditPage idEditing={parseInt(props.match.params.id, 10)} />,
  },
  {
    path: SiteUrls.Admin.Notice.Main,
    exact: true,
    name: `공지사항`,
    render: (props: any) => <NoticeListableTable filter={NoticeFilter.EvixAdmin} />,
  },
  {
    path: SiteUrls.Admin.Notice.Create,
    exact: true,
    name: `공지사항 추가`,
    render: (props: any) => <AdminNoticeEditPage idEditing={null} />,
  },
  {
    path: SiteUrls.Admin.Notice.Edit(':id'),
    exact: true,
    name: `공지사항 수정`,
    render: (props: any) => <AdminNoticeEditPage idEditing={parseInt(props.match.params.id, 10)} />,
  },
  {
    path: SiteUrls.Admin.Notice.Item(':id'),
    exact: true,
    name: `공지사항`,
    render: (props: any) => <NoticeItemPage id={props.match.params.id} />,
  },
  {
    path: SiteUrls.Admin.Faq.Main,
    exact: true,
    name: `자주하는 질문`,
    render: (props: any) => <FaqListableTable />,
  },
  {
    path: SiteUrls.Admin.Faq.Create,
    exact: true,
    name: `추가`,
    render: (props: any) => <FaqEditPage idEditing={null} />,
  },
  {
    path: SiteUrls.Admin.Faq.Edit(':id'),
    exact: true,
    name: `수정`,
    render: (props: any) => <FaqEditPage idEditing={parseInt(props.match.params.id, 10)} />,
  },
  {
    path: SiteUrls.Admin.Inquiry.Main,
    exact: true,
    name: `문의 내역`,
    render: (props: any) => <InquiryListableTable />,
  },
  {
    path: SiteUrls.Admin.Inquiry.Item(':id'),
    exact: true,
    name: `세부 내용`,
    render: (props: any) => <InquiryItemPage id={props.match.params.id} />,
  },
]
