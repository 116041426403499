import { ODHSpace, ODIcon, ODIcons, ODVSpace, useODQuery } from '@mdpp/od-react-belt'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import styled, { css } from 'styled-components'
import {
  GQLChatRoom,
  GQLGetChatRoomHasReservationInput,
  GQLReservation,
  GQLUpdateReservationByDoctorInput,
} from '@mdpp/common/lib/@types/server.schema'
import { EvixButtonTextWithIcon } from '../../../common/EvixButtonTextWithIcon'
import { GQL_GET_CHATROOM_HAS_RESERVATION } from '../../../common/gqls_common'
import { RoomChatSection } from '../room/section/RoomChatSection'
import { RoomDiagnosisSection } from '../room/section/RoomDiagnosisSection'
import { RoomInfoSection } from '../room/section/RoomInfoSection'

interface IFinishedMedicalPage {
  reservation: GQLReservation // 토큰이 이미 발급되어 입장이 가능한 reservation 정보
  onUpdateField: (fieldName: keyof GQLUpdateReservationByDoctorInput, value: string | number) => Promise<void>
}

export const FinishedMedicalPage: React.FC<IFinishedMedicalPage> = props => {
  const { reservation, onUpdateField } = props
  const history = useHistory()
  const apiGetChatRoomId = useODQuery<GQLGetChatRoomHasReservationInput, GQLChatRoom>(GQL_GET_CHATROOM_HAS_RESERVATION)
  const [chatRoomId, setChatRoomId] = useState<number | null>(null)

  const onExit = () => {
    history.goBack()
  }

  const { reservationId } = reservation
  const updateChatRoomId = React.useCallback(
    async (reservationId: number) => {
      try {
        const result = await apiGetChatRoomId({ reservationId })
        setChatRoomId(result.chatRoomId)
      } catch (ex) {
        // 채팅방이 없을 수 있음.
      }
    },
    [apiGetChatRoomId]
  )

  React.useEffect(() => {
    // noinspection JSIgnoredPromiseFromCall
    updateChatRoomId(reservationId)
  }, [updateChatRoomId, reservationId])

  return (
    <Wrapper>
      <Header>
        <HeaderText>종료된 진료실</HeaderText>
      </Header>
      <Main>
        <InfoSection>
          <InfoSectionHeader>
            <ODHSpace w={112} />
            <HeaderTextBox>
              <ExitButtonWrapper onClick={onExit}>
                <EvixButtonTextWithIcon text="나가기" icon={ODIcons.CoreAccountLogout} iconTopOffset={1} />
              </ExitButtonWrapper>
              <ODIcon icon={ODIcons.MaterialNotificationsActive} />
              <ODHSpace w={8} />
              진단
            </HeaderTextBox>
          </InfoSectionHeader>
          <Info left={true}>
            <FinishedMedicalButtonWrapper>
              <EvixButtonTextWithIcon text="종료된 진료입니다" icon={ODIcons.MaterialInfo} iconTopOffset={-3} />
            </FinishedMedicalButtonWrapper>
            <ODVSpace h={14} />
            <RoomDiagnosisSection reservation={reservation} onUpdateField={onUpdateField} isFinishedMedical />
          </Info>
        </InfoSection>
        <InfoSection center>
          <InfoSectionHeader center>
            <ODHSpace w={15} />
            <ODIcon icon={ODIcons.MaterialInfoOutline} />
            <ODHSpace w={8} />
            환자 정보
          </InfoSectionHeader>
          <Info center>
            <RoomInfoSection reservation={reservation} isFinishedMedical />
          </Info>
        </InfoSection>
        <InfoSection>
          <InfoSectionHeader>
            <ODHSpace w={15} />
            <ODIcon icon={ODIcons.CoreChatBubble} />
            <ODHSpace w={8} />
            채팅
          </InfoSectionHeader>
          <Info right>
            <RoomChatSection reservation={reservation} chatRoomId={chatRoomId} finishedMedical />
          </Info>
        </InfoSection>
      </Main>
      <ODVSpace h={26} />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100vw;
  min-height: 100vh;
  background-color: var(--gray100);
`

const Header = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 80px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.15);
  background-color: var(--white);
`

const HeaderText = styled.h1`
  font-size: 24px;
  font-weight: 300;
  color: #2e343a;
  text-align: center;
  margin: 0;
`
const ExitButtonWrapper = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  height: 80px;
  left: 0;
  top: -87.5px;
  font-size: 13px;
  color: var(--danger);

  cursor: pointer;

  & i {
    width: 16px;
    height: 16px;
    font-size: 16px;
    letter-spacing: 0.23px;
  }
`

const Main = styled.main`
  display: flex;
  flex: 1;
  background-color: var(--gray100);
`
const FinishedMedicalButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 2;
  height: 42px;
  border-radius: 5px;
  background-color: var(--gray200);
  font-size: 15px;
  color: var(--gray500);

  & svg {
    width: 17px;
    height: 17px;
    font-size: 17px;
    color: var(--gray500);
  }
`
const InfoSection = styled.div`
  display: flex;
  flex-direction: column;
  flex: 2;

  ${(p: { center?: boolean }) =>
    p.center &&
    css`
      max-width: 380px;
      flex: 0;
    `}
`

const HeaderTextBox = styled.div`
  position: relative;
  display: flex;
  width: 380px;
  margin-left: auto;
  margin-right: 24px;
`

const InfoSectionHeader = styled.div`
  display: flex;
  align-items: center;
  height: 34px;
  background-color: var(--gray400);

  font-size: 13px;
  font-weight: bold;
  color: var(--white);

  ${(p: { center?: boolean }) =>
    p.center &&
    css`
      border-left: 1px solid var(--gray300);
      border-right: 1px solid var(--gray300);
    `}
  & svg,
  & > i {
    width: 16px;
    height: 16px;
    font-size: 16px;
    color: var(--white);
  }
`
const Info = styled.div`
  width: 380px;
  padding-top: 20px;

  ${(p: { left?: boolean; right?: boolean; center?: boolean }) =>
    p.left &&
    css`
      margin-left: auto;
      margin-right: 24px;
    `}
  ${(p: { left?: boolean; right?: boolean; center?: boolean }) =>
    p.right &&
    css`
      margin-right: auto;
      margin-left: 15px;
    `}
  ${(p: { left?: boolean; right?: boolean; center?: boolean }) =>
    p.center &&
    css`
      padding-right: 14px;
      padding-left: 14px;
      border-left: 1px solid var(--gray200);
      border-right: 1px solid var(--gray200);
    `}
`
