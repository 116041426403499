import React from 'react'
import { PharmacyHasHospitalListableTable } from '../../admin/hospitalParmacy/list/PharmacyHasHospitalListableTable'
import { PageContainer, Page, PageHeader, PageTitle } from '../../common/page/Page'

import { useManagerAppContext } from '../context/ManagerAppContext'

interface IManagerHospitalPharmacyList {}

export const ManagerPharmacyHasHospitalListPage: React.FC<IManagerHospitalPharmacyList> = props => {
  const { state } = useManagerAppContext()

  return (

    <PageContainer>
      <Page>
        <PageHeader>
          <PageTitle>병원관리 {'>'} 약국</PageTitle>
        </PageHeader>
        
        <PharmacyHasHospitalListableTable hospitalId={state.profile!.hospital.hospitalId} />
      </Page>
    </PageContainer>
  )
}
