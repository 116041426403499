import styled from 'styled-components'
import moment from 'moment-timezone'
import {
  ODDateTimeRenderer,
  ODEnumRenderer,
  ODListablePaginatedTable2,
  ODListablePagination,
  ODListableResponseType,
  ODListableSearchBox,
  ODStringRenderer,
  useODListableContext,
} from '@mdpp/od-react-belt'
import { ODEnumRendererStyle } from '@mdpp/od-belt'
import React, { CSSProperties } from 'react'
import { useHistory } from 'react-router-dom'
import { GQLMedicationDispensing } from '@mdpp/common/lib/@types/server.schema'
import {
  ERROR_TYPE,
  familyGender,
  getDeviceState,
  numSorting,
  PHARMACY_RESERVATION_STATUS_TEXT,
  WebUtils,
} from '../../../utils/webUtils'
import { useReservationEventHandler } from '../PharmaReservationEventHandler'
import { PharmacyReservationToolBar } from '../PharmacyReservationToolBar'
import { EvixCard } from '../../../common/card/EvixCard'
import { PharmacySSEReceiver } from '../../PharmacySSEReceiver'
import { IMedicationDispensingListableOption, MedicationDispensingKey } from '../PharmacyCommon'
import { GQLPAYMENT_STATE, GQLReservation, GQLRESERVATION_STATUS } from '@mdpp/common/lib/@types/server.schema'
import { SiteUrls } from '../../../SiteUrls'
import { DoctorSSEReceiver } from '../../../doctor/reservation/DoctorSSEReceiver'
import { ReservationToolBar } from '../../../doctor/reservation/ReservationToolBar'
import { addressParser } from '../../../utils/webUtils'

type ReservationListableDataLoader = (
  page: number,
  pageSize: number,
  afterKey: string | null,
  options: IMedicationDispensingListableOption
) => Promise<ODListableResponseType<GQLMedicationDispensing>>

interface IReservationListableOfPharmacyTableProps {
  medicationDispensingId?: number
  showSearch?: boolean
  dataLoader: ReservationListableDataLoader
  linkToDetail: (id: string | number) => string
  fromHospitalManager?: boolean
  fromAdmin?: boolean
  fromHospitalManagerDashboard?: boolean
  fromPharmacy?: boolean
  toolbox?: boolean
  value?: any
  onChange?: any
}

interface IReservationColumnInfo {
  headerName: string
  render: (value: GQLMedicationDispensing, context: any) => React.ReactNode
  thStyle?: CSSProperties
}

enum RT {
  Status,
  CreatedAt,
  PatientName,
  PatientGender,
  PatientBirthDate,
  Phone,
  Address,
  Hospitals,
  Memo,
  Message,
}

const COLUMNS_PER_STYLE = {
  Pharmacist: [
    RT.Status,
    RT.CreatedAt,
    RT.PatientName,
    RT.PatientGender,
    RT.PatientBirthDate,
    RT.Phone,
    RT.Address,
    RT.Hospitals,
  ],
}

export const ReservationListableOfPharmacyTable: React.FC<IReservationListableOfPharmacyTableProps> = props => {
  const { dataLoader, showSearch, linkToDetail, toolbox, value, onChange, medicationDispensingId } = props
  const { Provider, Context } = useODListableContext<GQLMedicationDispensing, IMedicationDispensingListableOption>()
  const { onEvent, token } = useReservationEventHandler(Context)
  const history = useHistory()
  const toolbarStyle = { display: 'flex', justifyContent: 'space-between', marginBottom: 15 }

  const wrapperStyle = {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    marginTop: 15,
  }

  const itemActive = {
    borderColor: '#7F8694',
    backgroundColor: '#7F8694',
    color: 'white',
  }

  const itemDeactive = {
    borderColor: '#E6E8ED',
    backgroundColor: '#E6E8ED',
    color: '#202223',
  }

  const cardStyle = {
    display: 'flex',
    background: '#FFFFFF',
    boxShadow: '0px 0px 5px rgba(23, 24, 24, 0.05), 0px 1px 2px rgba(0, 0, 0, 0.15)',
    borderRadius: 8,
    border: 0,
  }

  const searchBoxStyle = {
    width: '100%',
  }

  const getAge = (birthDate: string, sex: string) => {
    // 현재 날짜
    const now = moment()
    const dates = new Date()
    const nowMonth = dates.getMonth()
    const nowDay = dates.getDate()
    // 생일날짜 format
    const birth = moment(birthDate, 'YYMMDD')
    const y = birth.year().toString().substr(2, 3)
    const m = Number(birthDate.slice(2, 4))
    const d = Number(birthDate.slice(4, 6))

    if (sex === '1' || sex === '2') {
      const oldBirth = `19${y}`
      const oldBase = now.year() - Number(oldBirth)

      if (nowMonth - m > 0) {
        // 생일지남
        const nextBase = oldBase + 1
        return nextBase
      }
      if (nowMonth - m === 0) {
        // 생일과 같은달
        return nowDay - d >= 0 ? oldBase + 1 : oldBase
      }
      return oldBase
    }

    if (sex === '3' || sex === '4') {
      const y = birth.year().toString().substr(2, 3)
      const newBirth = `20${y}`
      const newBase = now.year() - Number(newBirth)
      if (nowMonth - m > 0) {
        // 생일지남
        const nextBase = newBase + 1
        return nextBase
      }
      if (nowMonth - m === 0) {
        // 생일과 같은달
        return nowDay - d >= 0 ? newBase + 1 : newBase
      }
      return newBase
    }
  }

  const getNewAge = (birthDate: string) => {
    const now = moment()
    const birth = moment(birthDate, 'YYYY-MM-DD')
    const base = now.year() - birth.year()
    const diffMonth = moment(now.month()).diff(moment(birth.month()), 'month')

    if (diffMonth > 0) {
      // 생일 지남
      const newBase = base + 1
      return newBase
    }

    if (diffMonth === 0) {
      // 생일과 같은달
      return now.date() - birth.date() >= 0 ? base + 1 : base
    }
    return base
  }

  const COLUMN_INFO: { [key: number]: IReservationColumnInfo } = {
    [RT.Status]: {
      headerName: '상태',
      render: (value, context) => (
        <td key={value.medicationDispensingId + '_status'} align="center" style={{ opacity: 1 }}>
          <ODEnumRenderer
            enumRendererType={ODEnumRendererStyle.Tag}
            textMap={PHARMACY_RESERVATION_STATUS_TEXT}
            context={context}
            value={value.status}
            originalValue={value}
            eventKey="status"
          />
        </td>
      ),
      thStyle: {
        textAlign: 'center',
        width: 100,
        minWidth: 100,
        whiteSpace: 'nowrap',
      },
    },
    [RT.CreatedAt]: {
      headerName: '조제 신청 일시',
      render: (value, context) => (
        <td key={value.medicationDispensingId + '_requested_time '} align="center">
          <ODDateTimeRenderer
            format="YYYY.MM.DD (ddd) / HH:mm"
            context={context}
            value={value.requestedTime}
            originalValue={value}
            eventKey="requestedTime"
          />
        </td>
      ),
      thStyle: {
        width: 170,
        minWidth: 170,
        whiteSpace: 'nowrap',
        textAlign: 'center',
      },
    },
    [RT.PatientName]: {
      headerName: '환자명',
      render: (value, context) => (
        <td key={value.medicationDispensingId + '_patient_name'} align="center">
          <ODStringRenderer
            context={context}
            value={
              value.reservation?.proxyReservationInfo
                ? value?.reservation?.proxyReservationInfo.name
                : value?.reservation?.patient.name
            }
            originalValue={value}
            eventKey="patient.name"
          />
        </td>
      ),
      thStyle: {
        whiteSpace: 'nowrap',
        textAlign: 'center',
      },
    },
    [RT.PatientGender]: {
      headerName: '성별',
      render: (value, context) => (
        <td key={value.medicationDispensingId + '_patient_gender'} align="center">
          <ODStringRenderer
            context={context}
            value={
              value.reservation?.proxyReservationInfo
                ? familyGender(value.reservation.proxyReservationInfo.sex)
                : value?.reservation?.patient.gender === 'male'
                ? '남'
                : '여'
            }
            originalValue={value}
            eventKey="patient.gender"
          />
        </td>
      ),
      thStyle: {
        whiteSpace: 'nowrap',
        textAlign: 'center',
      },
    },
    [RT.PatientBirthDate]: {
      headerName: '생년월일(연령)',
      render: (value, context) => {
        const age = !!value.reservation?.proxyReservationInfo
          ? getAge(value.reservation?.proxyReservationInfo.birthDate, value.reservation?.proxyReservationInfo.sex)
          : getNewAge(value.reservation!.patient.fullBirthDate)
        return (
          <td key={value.medicationDispensingId + '_patient_birthDate'} align="center">
            <ODStringRenderer
              context={context}
              value={
                `${
                  value.reservation?.proxyReservationInfo
                    ? value.reservation?.proxyReservationInfo.birthDate
                    : value.reservation!.patient.birthDate
                }(${age})` ?? ''
              }
              originalValue={value}
              eventKey="patient.birthDate"
            />
          </td>
        )
      },
      thStyle: {
        whiteSpace: 'nowrap',
        textAlign: 'center',
      },
    },
    [RT.Phone]: {
      headerName: '연락처',
      render: (value, context) => (
        <td key={value.medicationDispensingId + '_patient_phone'} align="center">
          <ODStringRenderer
            context={context}
            value={
              value.reservation?.proxyReservationInfo?.phone
                ? numSorting(value.reservation?.proxyReservationInfo.phone)
                : numSorting(value.reservation!.patient.phone) ?? ''
            }
            originalValue={value}
            eventKey="patient.phone"
          />
        </td>
      ),
      thStyle: {
        whiteSpace: 'nowrap',
        textAlign: 'center',
      },
    },
    [RT.Address]: {
      headerName: '주소',
      render: (value, context) => (
        <td key={value.medicationDispensingId + '_patientAddress_Json'} align="center">
          <ODStringRenderer
            context={context}
            value={addressParser(value?.patientAddressJson!)}
            originalValue={value}
            eventKey="patientAddressJson"
          />
        </td>
      ),
      thStyle: {
        whiteSpace: 'nowrap',
        textAlign: 'center',
      },
    },
    [RT.Hospitals]: {
      headerName: '진료병원',
      render: (value, context) => (
        <td key={value.medicationDispensingId + '_hospital_name'} align="center">
          <ODStringRenderer
            context={context}
            value={value.reservation?.hospital.name}
            originalValue={value}
            eventKey="hospital.name"
          />
        </td>
      ),
      thStyle: {
        whiteSpace: 'nowrap',
        textAlign: 'center',
      },
    },
  }

  const columns = COLUMNS_PER_STYLE.Pharmacist

  const table = (
    <>
      <ODListablePaginatedTable2
        borderLess={true}
        tableStyle={{ marginBottom: 0 }}
        numColumns={columns.length}
        renderEmpty={() => <span>조제 정보가 없습니다.</span>}
        listableContext={Context}
        renderHeader={() => (
          <tr style={{ background: '#F6F8FA', borderBottom: '1px solid #D1D5DA' }}>
            {columns
              .map(c => {
                return COLUMN_INFO[c]!
              })
              .map((c, index) => (
                <th key={index}>{c?.headerName}</th>
              ))}
          </tr>
        )}
        renderRow={(value: GQLMedicationDispensing, context) => {
          return (
            <StyledTr
              key={value[MedicationDispensingKey]}
              onClick={() => {
                history.push(linkToDetail(value.medicationDispensingId))
              }}
            >
              {columns.map(c => COLUMN_INFO[c]!).map((c, index) => c.render(value, context))}
            </StyledTr>
          )
          return (
            <tr
              key={value[MedicationDispensingKey]}
              onClick={() => {
                history.push(linkToDetail(value.medicationDispensingId))
              }}
              style={{ borderBottomWidth: 1, borderBottomColor: '#d1d5d9' }}
            >
              {columns.map(c => COLUMN_INFO[c]!).map((c, index) => c.render(value, context))}
            </tr>
          )
        }}
        onEvent={onEvent}
        eventParentContext={{}}
      />
      <PharmacySSEReceiver context={Context} />
    </>
  )

  return (
    <Provider
      dataLoader={dataLoader}
      keyExtractor={v => v[MedicationDispensingKey].toString()}
      pageSize={10}
      refreshToken={token.toString()}
      onDataLoaderError={WebUtils.showError}
      searchOnLoad
    >
      <TableCardWrapper>
        {showSearch && (
          <SearchWrapper style={toolbarStyle}>
            <ODListableSearchBox
              listableContext={Context}
              placeholder="환자이름, 진료병원으로 검색"
              style={searchBoxStyle}
            />
          </SearchWrapper>
        )}
        {toolbox && (
          <SearchWrapper>
            <ODListableSearchBox
              listableContext={Context}
              placeholder="환자이름, 진료병원으로 검색"
              style={searchBoxStyle}
            />
            <PharmacyReservationToolBar value={value} onChange={onChange} />
          </SearchWrapper>
        )}
        <EvixCard noPadding>
          {table}
          <ODListablePagination
            hideIfSinglePage={false}
            listableContext={Context}
            wrapperStyle={wrapperStyle}
            itemActiveStyle={itemActive}
            itemDeactiveStyle={itemDeactive}
          />
        </EvixCard>
      </TableCardWrapper>
    </Provider>
  )
}

const StyledTr = styled.tr`
  cursor: pointer;
  height: 64px;
  border-bottom: 1px solid #d1d5da;
  background-color: white;
`

const SearchWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  margin-bottom: 12px;

  > div > form > div {
    > div {
      border-radius: 8px;

      > div {
        border-radius: 8px;
        background-color: white;
        > span {
          border-radius: 8px;
        }
      }

      > input {
        border-radius: 0px 8px 8px 0px;
      }
    }

    /* @media(max-width: 992px) {
      width: 290px;
    } */
  }
`

const TableCardWrapper = styled.div`
  //   @media(max-width: 992px) {
  //     width: 290px;
  //   }
`
