import React, { useMemo } from 'react'
import { ODVSpace } from '@mdpp/od-react-belt'
import { useHistory } from 'react-router-dom'
import { EvixButton, EvixButtonTheme } from '../../common/EvixButton'

//asset
import {
  Wrap,
  SignUpWrap,
  Header,
  PageTitleWrap,
  ProcessWrap,
  DoctorLogo,
  PageTitle,
  Step,
  StepCircle,
  StepText,
  Container,
  Title,
  TermWrap,
  BtnWrap,
} from '../../common/style/SignUpStyle'
import { InputCheckboxSupportWrapper } from '../../common/style/CommonStyle'
import IconDoctor from '../../assets/common/icon-doctor.png'
import styled from 'styled-components'

export const DoctorSignUpPage: React.FC = () => {
  const [rememberCheck1, setRememberCheck1] = React.useState(false)
  const [rememberCheck2, setRememberCheck2] = React.useState(false)

  // 다음 버튼 클릭 시, 이동
  const history = useHistory()
  const handleNext = () => {
    let path = `/doctor/signupInfo`
    history.push(path)
  }

  // 메디팡팡 이용약관에 동의합니다. (필수)
  const handleCheck1 = () => {
    setRememberCheck1(!rememberCheck1)
  }

  // 개인정보 수집 및 이용 동의 (필수)
  const handleCheck2 = () => {
    setRememberCheck2(!rememberCheck2)
  }

  const handleAgree = (src: string) => {
    return <Frame src={src} />
  }

  const Frame = styled.iframe`
    width: 100%;
    height: 100%;
  `

  const agreeTerms = React.useMemo(
    () =>
      handleAgree(
        'https://medi-pangpang-cs.s3.ap-northeast-2.amazonaws.com/notice/%EC%9D%B4%EC%9A%A9%EC%95%BD%EA%B4%80/common/index.html'
      ),
    []
  )

  const agreeInfo = React.useMemo(
    () =>
      handleAgree(
        'https://medi-pangpang-cs.s3.ap-northeast-2.amazonaws.com/notice/%EA%B0%9C%EC%9D%B8%EC%A0%95%EB%B3%B4%EC%88%98%EC%A7%91%EB%B0%8F%EC%9D%B4%EC%9A%A9%EB%8F%99%EC%9D%98/common/index.html'
      ),
    []
  )

  return (
    <Wrap>
      <SignUpWrap>
        <Header>
          <PageTitleWrap>
            <img src={IconDoctor} style={DoctorLogo} alt="의사 아이콘" />
            <PageTitle>회원가입</PageTitle>
          </PageTitleWrap>
          <ProcessWrap>
            <Step className="active">
              <StepCircle>
                <span>1</span>
              </StepCircle>
              <StepText>약관 동의</StepText>
            </Step>
            <Step>
              <StepCircle>
                <span>2</span>
              </StepCircle>
              <StepText>가입정보 입력</StepText>
            </Step>
            <Step>
              <StepCircle>
                <span>3</span>
              </StepCircle>
              <StepText>이메일 인증</StepText>
            </Step>
            <Step>
              <StepCircle>
                <span>4</span>
              </StepCircle>
              <StepText>가입 승인 요청</StepText>
            </Step>
          </ProcessWrap>
        </Header>

        <Container>
          <Title>이용약관</Title>
          <InputCheckboxSupportWrapper className="termText">
            <input type="checkbox" id="cb1" onChange={handleCheck1} checked={rememberCheck1} />
            <label htmlFor="cb1">메디팡팡 이용약관에 동의합니다. (필수) </label>
          </InputCheckboxSupportWrapper>
          <ODVSpace h={20} />
          <TermWrap>{agreeTerms}</TermWrap>
          <ODVSpace h={54} />

          <Title>개인정보 수집 및 이용 동의</Title>
          <InputCheckboxSupportWrapper className="termText">
            <input type="checkbox" id="cb2" onChange={handleCheck2} checked={rememberCheck2} />
            <label htmlFor="cb2">개인정보 수집 및 이용 동의 (필수)</label>
          </InputCheckboxSupportWrapper>
          <ODVSpace h={20} />
          <TermWrap>{agreeInfo}</TermWrap>
          <ODVSpace h={60} />

          <BtnWrap>
            <EvixButton
              theme={EvixButtonTheme.H48Black}
              onClick={handleNext}
              disabled={!(rememberCheck1 && rememberCheck2)}
            >
              다음
            </EvixButton>
          </BtnWrap>
        </Container>
      </SignUpWrap>
    </Wrap>
  )
}
