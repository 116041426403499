import {
  APP_VERSION_SNAPSHOT,
  DOCTOR_SNAPSHOT,
  HOSPITAL_MANAGER_SNAPSHOT,
  HOSPITAL_SNAPSHOT,
  PHARMACY_HAS_HOSPITAL_SNAPSHOT,
  POPUP_INFO_SNAPSHOT,
} from '../../common/gqls_common'

const ADMIN_SNAPSHOT = `
  evixAdminId
  name
  email
`

export const GQL_LOGIN_EVIX_ADMIN = `
mutation loginEvixAdmin($data: EvixAdminLoginInput!) {
  loginEvixAdmin(data: $data) {
    me {
      ${ADMIN_SNAPSHOT}
    }
    token
  }
}
`

export const GQL_GET_EVIX_ADMIN_PROFILE = `
query getEvixAdminMyProfile {
  getEvixAdminMyProfile {
    ${ADMIN_SNAPSHOT}
  }
}`

export const GQL_UPDATE_HOSPITAL = `
mutation updateHospital($data: HospitalUpdateInput!) {
  updateHospital(data: $data) {
    ${HOSPITAL_SNAPSHOT}
  }
}
`

export const GQL_RESET_HOSPITAL_MANAGER_PASSWORD = `
mutation resetHospitalManagerPassword($data: SingleIDInput!) {
  resetHospitalManagerPassword(data: $data) {
    ok
  }
}
`

export const GQL_DELETE_HOSPITAL_MANAGER = `
mutation deleteHospitalManager($data: SingleIDInput!) {
  deleteHospitalManager(data: $data) {
    ok
  }
}
`
export const GQL_LIST_PHARMACY_HAS_HOSPITAL = `
query listPharmacyHasHospital($data: ListablePharmacyHasHospitalInput!) {
  listPharmacyHasHospital(data: $data) {
    list {
      ${PHARMACY_HAS_HOSPITAL_SNAPSHOT}
      }
    totalCount
    page
    pageSize
    }
  }
`

export const GQL_CHANGE_DOCTOR_HOSPITAL = `
mutation changeDoctorHospital($data: ChangeDoctorHospitalInput!) {
  changeDoctorHospital(data: $data) {
    ${DOCTOR_SNAPSHOT}
  }
}
`

export const GQL_LIST_APP_VERSION = `
query listAppVersion($data: ListableAppVersionInput!) {
  listAppVersion(data: $data) {
    list {
      ${APP_VERSION_SNAPSHOT}
    }
    totalCount
    page
    pageSize
  }
}`

export const GQL_CREATE_APP_VERSION = `
mutation createAppVersion($data: AppVersionCreationInput!) {
  createAppVersion(data: $data) {
    ${APP_VERSION_SNAPSHOT}
  }
}
`

export const GQL_GET_APP_VERSION = `
query getAppVersion($data: SingleIDInput!) {
  getAppVersion(data: $data) {
    ${APP_VERSION_SNAPSHOT}
  }
}
`

export const GQL_UPDATE_APP_VERSION = `
mutation updateAppVersion($data: AppVersionUpdateInput!) {
  updateAppVersion(data: $data) {
    ${APP_VERSION_SNAPSHOT}
  }
}
`

export const GQL_DELETE_APP_VERSION = `
mutation deleteAppVersion($data: SingleIDInput!) {
  deleteAppVersion(data: $data) {
    ok
  }
}
`

export const GQL_CREATE_POPUP_INFO = `
mutation createPopupInfo($data: PopupInfoCreationInput!) {
  createPopupInfo(data: $data) {
    ${POPUP_INFO_SNAPSHOT}
  }
}
`

export const GQL_UPDATE_POPUP_INFO = `
mutation updatePopupInfo($data: PopupInfoUpdateInput!) {
  updatePopupInfo(data: $data) {
    ${POPUP_INFO_SNAPSHOT}
  }
}
`

export const GQL_DELETE_POPUP_INFO = `
mutation deletePopupInfo($data: PopupInfoDeleteInput!) {
  deletePopupInfo(data: $data) {
    ok
  }
}
`



//
// Hospital Manager
//
export const HOSPITAL_MANAGER_FULL_SNAPSHOT = `
  hospitalManagerId
  name
  email
  createdAt
  hospitalId
`

export const GQL_CREATE_HOSPITAL_MANAGER = `
mutation createHospitalManager($data: HospitalManagerCreationInput!) {
  createHospitalManager(data: $data) {
    ${HOSPITAL_MANAGER_FULL_SNAPSHOT}
  }
}
`

export const GQL_UPDATE_HOSPITAL_MANAGER = `
mutation updateHospitalManager($data: HospitalManagerUpdateInput!) {
  updateHospitalManager(data: $data) {
    ${HOSPITAL_MANAGER_FULL_SNAPSHOT}
  }
}
`

export const GQL_GET_HOSPITAL_MANAGER = `
query getHospitalManager($data: SingleIDInput!) {
  getHospitalManager(data: $data) {
    ${HOSPITAL_MANAGER_FULL_SNAPSHOT}
  }
}`

export const GQL_REMOVE_HOSPITAL_MANAGER = `
mutation removeHospitalManager($data: SingleIDInput!) {
  removeHospitalManager(data: $data) {
    ok
  }
}
`

export const GQL_LIST_HOSPITAL_MANAGER = `
query listHospitalManager($data: ListableHospitalManagerInput!) {
  listHospitalManager(data: $data) {
    list {
      ${HOSPITAL_MANAGER_SNAPSHOT}
    }
    totalCount
    page
    pageSize
  }
}`
