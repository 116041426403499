import { ODListableResponseType } from '@mdpp/od-react-belt'
import React from 'react'
import { GQLDoctor } from '@mdpp/common/lib/@types/server.schema'
import { useCommonAPIs } from '../../hooks/useCommonAPIs'
import { IDoctorListableOption } from '../DoctorCommon'

export function useDoctorListDataLoader() {
  const { listDoctor } = useCommonAPIs()
  return React.useCallback(
    async function dataLoader(
      page: number,
      pageSize: number,
      afterKey: string | null,
      options: IDoctorListableOption
    ): Promise<ODListableResponseType<GQLDoctor>> {
      const r = await listDoctor({
        page,
        pageSize,
        ...options,
      })
      return r as ODListableResponseType<GQLDoctor>
    },
    [listDoctor]
  )
}
