import { SingleQueryWrapper, useODQuery2 } from '@mdpp/od-react-belt'
import React from 'react'
import styled from 'styled-components'
import { RecentNoticeList } from '../../common/notice/list/RecentNoticeList'
import { SiteUrls } from '../../SiteUrls'
import { GuideBanner } from './GuideBanner'
import { UpcomingReservation } from './UpcomingReservation'
import {Page, PageContainer, PageContent, PageHeader, PageTitle} from "../../common/page/Page";

interface IDoctorDashboardContainerProps {}

const GQL_DOCTOR_DASHBOARD = `
query {
  listDoctorReservation(data: {page: 1, pageSize: 3, status: [PENDING,WAITING,RECEIPTED,READY,IN_PROGRESS,PRESCRIPTION], oldestFirst: true }) {
    list {
      reservationId
      patient {
        name
      }
      symptom
      proxyReservation
      proxyReservationInfo {
        name
      }
      medicalSubject {
        name
      }
      reservationTime
    }
  }
  listNotice(data: {page:1, pageSize: 10, showOnDoctor: 1}) {
    list {
      noticeId
      title
      createdAt
    }
  }
}
`

export const DoctorDashboardContainer: React.FC<IDoctorDashboardContainerProps> = props => {
  const { api } = useODQuery2<any, any>(GQL_DOCTOR_DASHBOARD, { skip: true })

  return (
    <Wrapper>
      <SingleQueryWrapper deps={[]} getter={() => api({})}>
        {({ data }) => {
          const {
            listDoctorReservation: { list: reservationList },
            listNotice: { list: noticeList },
          } = data
          return (
            <>
              <PageContainer>
                  <Page>
                      <GuideBanner />
                      <UpcomingReservation reservations={reservationList} />
                      <Hr />
                      <PageContent>
                        <RecentNoticeList
                            notices={noticeList}
                            viewItemLink={SiteUrls.Doctor.Notice.Item}
                            viewMoreLink={SiteUrls.Doctor.Notice.Main}
                        />
                      </PageContent>
                  </Page>
              </PageContainer>

            </>
          )
        }}
      </SingleQueryWrapper>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 48px;
`

const Hr = styled.div`
  width: 100%;
  height: 24px;
`




