import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { SiteUrls } from '../SiteUrls'
import { Landing } from './Landing'
// import { DoctorAuthorized } from './DoctorAuthorized'
// import { DoctorLoginPage } from './DoctorLoginPage'
// import { DoctorLogoutPage } from './DoctorLogoutPage'
// import { DoctorMainLayout } from './DoctorMainLayout'
// import { EvixRoomProvider } from './reservation/room/context/EvixRoomContext'
// import { RoomMainLayoutWrapper } from './reservation/room/RoomMainLayoutWrapper'

export const LandingRoot: React.FC = () => {
    return (
        <Switch>
            <Route path={SiteUrls.Landing.Root} component={Landing} title={"매디팡팡"}/>
            <Route
                path={SiteUrls.Landing.Root}
                render={() => {
                    return (
                        <Landing/>
                    )
                }}
            />
            <Redirect to={SiteUrls.Landing.Root} />
        </Switch>
    )
}
