import React, { useEffect } from 'react'
import { Modal } from 'reactstrap'
import { EvixButton, EvixButtonTheme } from '../../../common/EvixButton'
import IconClose from '../../../assets/common/icon-close.svg'
import IconWarningRed from '../../../assets/common/warning-red-super.svg'
import styled from 'styled-components/macro'
import { COLORS } from '../../../styles/colors'

import {
  Wrapper,
  Header,
  Container,
  Content,
  DescriptionContainer,
  ButtonWrapper,
  ConfirmTitleWrap,
  ConfirmTitle,
  SimpleDescription,
} from '../../../common/style/ModalStyle'
import { InputCheckboxSupportWrapper } from '../../../common/style/CommonStyle'
import { ODVSpace } from '@mdpp/od-react-belt'
import { EvixInput } from '../../../common/EvixInput'
import { validator } from '../../../validator'
import { WarningMessageWrap } from '../../../common/style/SignUpStyle'
import { WebUtils } from '../../../utils/webUtils'

interface RefuseDispenseModalProps {
  style?: object
  onOkPress: (reason: string) => void
  onCancelPress: () => void
}

export const RefuseDispenseModal: React.FC<RefuseDispenseModalProps> = props => {
  const { style, onOkPress, onCancelPress } = props
  const [selected, setSelected] = React.useState('')
  const [etc, setEtc] = React.useState('')
  const [errMsg, setErrMsg] = React.useState('')

  const radioSelect = (e: any) => {
    setSelected(e.target.value)
  }

  const handleComplete = () => {
    if (selected === '') {
      return WebUtils.showError('조제거절 사유를 선택해주세요.')
    }
    if (selected === '3' && etc === '') {
      setErrMsg('조제거절 사유를 입력해주세요.')
      return
    }
    onOkPress(selected !== '3' ? selected : etc)
  }

  useEffect(() => {
    setErrMsg('')
  }, [selected, etc])

  return (
    <Modal isOpen={true} toggle={() => {}} style={style}>
      <Wrapper>
        <Header>
          <img src={IconClose} onClick={onCancelPress} alt="close icon" />
        </Header>
        <Container>
          <Content>
            <ConfirmTitleWrap className={'icon-large'} style={{ paddingBottom: 3 }}>
              <img src={IconWarningRed} alt="warning bullet" />
              <ConfirmTitle>해당 처방전의 조제를 거절하시겠습니까?</ConfirmTitle>
            </ConfirmTitleWrap>

            <DescriptionContainer>사유를 선택해주세요.</DescriptionContainer>
            <SelectedWrap>
              <InputCheckboxSupportWrapper>
                <input
                  type="radio"
                  id="radio1"
                  value={'해당 의약품 조제 불가능'}
                  onChange={value => radioSelect(value)}
                  checked={selected === '해당 의약품 조제 불가능'}
                />
                <label htmlFor="radio1">해당 의약품 조제 불가능</label>
              </InputCheckboxSupportWrapper>
              <ODVSpace h={10} />
              <InputCheckboxSupportWrapper>
                <input
                  type="radio"
                  id="radio2"
                  value={'해당 지역 배송 불가능'}
                  onChange={value => radioSelect(value)}
                  checked={selected === '해당 지역 배송 불가능'}
                />
                <label htmlFor="radio2">해당 지역 배송 불가능</label>
              </InputCheckboxSupportWrapper>
              <ODVSpace h={10} />
              <InputCheckboxSupportWrapper>
                <input
                  type="radio"
                  id="radio3"
                  value={'3'}
                  onChange={value => radioSelect(value)}
                  checked={selected === '3'}
                />
                <label htmlFor="radio3">기타 사유</label>
              </InputCheckboxSupportWrapper>
              <ODVSpace h={10} />
              {selected === '3' && (
                <>
                  <EvixInput
                    style={{ width: '100%' }}
                    placeholder={'예시) 영업시간이 아닙니다.'}
                    value={etc}
                    onChange={value => setEtc(value)}
                    disabled={false}
                    maxLength={20}
                  />
                  <span
                    className="error"
                    style={{ fontSize: 13, fontWeight: 500, color: '#ff5353', position: 'absolute', top: '322px' }}
                  >
                    {errMsg}
                  </span>
                </>
              )}
            </SelectedWrap>
          </Content>
          {/* 버튼 영역 */}
          <ButtonWrapper style={{ marginTop: 26 }}>
            <EvixButton
              theme={EvixButtonTheme.H48WhiteInline}
              style={{ flex: 1 }}
              onClick={onCancelPress}
              disabled={false}
            >
              {'취소'}
            </EvixButton>
            <EvixButton
              theme={EvixButtonTheme.H48BlackInline}
              style={{ flex: 1 }}
              onClick={handleComplete}
              disabled={false}
            >
              {'확인'}
            </EvixButton>
          </ButtonWrapper>
        </Container>
      </Wrapper>
    </Modal>
  )
}

const SelectedWrap = styled.div`
  padding: 40px 40px 0 40px;
  display: flex;
  flex-direction: column;
`
