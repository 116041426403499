import { IODListableEventContext, ODListableOption } from '@mdpp/od-react-belt'
import { GQLMedicalSubject } from '@mdpp/common/lib/@types/server.schema'

export const MedicalSubjectPrimaryKey = 'medicalSubjectId'

// modifiable fields only.
export interface ICTMedicalSubject extends Pick<GQLMedicalSubject, 'medicalSubjectId' | 'name'> {
  // more fields, mapped (if needed)
}

export interface IMedicalSubjectListableOption extends ODListableOption {
  filter: string
}

export interface IMedicalSubjectEntityContext extends IODListableEventContext<GQLMedicalSubject> {
  //
}
