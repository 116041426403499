import { isString } from 'lodash'
import React from 'react'
import styled from 'styled-components'
import { WebUtils } from '../utils/webUtils'

interface IEvixErrorBoxProps {
  message: string | Error
}

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const EvixErrorBox: React.FC<IEvixErrorBoxProps> = props => {
  const { message } = props
  return <Wrapper>{isString(message) ? message : WebUtils.parseErrorMessage(message)}</Wrapper>
}
