import React from 'react'
import { Modal } from 'reactstrap'
import { EvixButton, EvixButtonTheme } from '../../common/EvixButton'
import IconClose from '../../assets/common/icon-close.svg'
import IconWarningBlue from '../../assets/common/warning-blue-super.svg'
import IconWarningRed from '../../assets/common/warning-red-small-2.svg'
import styled from 'styled-components/macro'
import { COLORS } from '../../styles/colors'

import {
  Wrapper,
  Header,
  Container,
  Content,
  DescriptionContainer,
  ButtonWrapper,
  ConfirmTitleWrap,
  ConfirmTitle,
  SimpleDescription,
} from '../../common/style/ModalStyle'

interface CompleteEmailSendModalProps {
  style?: object
  onOkPress: () => void
  onCancelPress: () => void
  failData?: Array<string>
  SuccData?: Array<object> | undefined
}

export const CompleteEmailSendModal: React.FC<CompleteEmailSendModalProps> = props => {
  const { style, onOkPress, onCancelPress, failData, SuccData } = props
  const emailFailList = failData && failData.map((v: any, idx: number) => <div key={idx}>{v}</div>)

  return (
    <Modal isOpen={true} toggle={() => {}} style={style}>
      <Wrapper>
        <Header>
          <img src={IconClose} onClick={onCancelPress} alt="close icon" />
        </Header>
        <Container>
          <Content>
            <ConfirmTitleWrap className={'icon-large'} style={{ paddingBottom: 3 }}>
              <img src={IconWarningBlue} alt="warning bullet" />
              <ConfirmTitle>이메일이 발송되었습니다.</ConfirmTitle>
            </ConfirmTitleWrap>

            <DescriptionContainer>
              <SimpleDescription>
                성공 <span>{SuccData ? SuccData!.length - failData!.length : '0'}</span>/ 실패{' '}
                <span>{failData!.length}</span>
              </SimpleDescription>
            </DescriptionContainer>
          </Content>
          {/* 버튼 영역 */}
          <ButtonWrapper style={{ marginTop: 26 }}>
            <EvixButton
              theme={EvixButtonTheme.H48WhiteInline}
              style={{ width: '100%' }}
              onClick={onOkPress}
              disabled={false}
            >
              {'확인'}
            </EvixButton>
          </ButtonWrapper>
          {failData!.length > 0 && (
            <FailWrap>
              <div>
                <TitleWrap>
                  <Icon src={IconWarningRed} />
                  <span>발송 실패한 이메일 주소</span>
                </TitleWrap>
                <EmailList>{emailFailList}</EmailList>
              </div>
            </FailWrap>
          )}
        </Container>
      </Wrapper>
    </Modal>
  )
}

const FailWrap = styled.div`
  border-radius: 4px;
  border: solid 1px #e0e1e4;
  width: calc(100% - 80px);
  height: 164px;

  margin: 0 40px 40px 40px;
  padding: 20px 0 20px 20px;

  > div {
    overflow-y: scroll;
    height: 100%;
  }
`

const TitleWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;

  > span {
    font-size: 13px;
    font-weight: bold;
    color: ${COLORS.GRAY42};
  }
`

const Icon = styled.img`
  width: 13px;
  height: 13px;
`

const EmailList = styled.div`
  font-size: 13px;
  font-weight: 500;
  color: ${COLORS.GRAY42};
  padding-left: 20px;
  margin-top: 5px;

  > div {
    line-height: 1.85;
  }
`
