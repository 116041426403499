import { ODIcon, ODIcons } from '@mdpp/od-react-belt'
import React from 'react'
import styled from 'styled-components'
import { SiteUrls } from '../../SiteUrls'
import { StepButton } from './StepButton'
import { COLORS } from '../../styles/colors'
import IconWarningBlue from '../../assets/common/warning-blue.svg'

interface IGuideBannerProps {}

export const GuideBanner: React.FC<IGuideBannerProps> = props => {
  const [showWelcome, setShowWelcome] = React.useState(() => {
    return !localStorage.getItem('HIDE_WELCOME')
  })

  const handleHide = () => {
    localStorage.setItem('HIDE_WELCOME', '1')
    setShowWelcome(false)
  }

  return (
    <Wrapper>
      {showWelcome && (
        <TopSection>
          <TitleSection>
            <TitleBigText>
              <span style={{ fontWeight: 'bold' }}>메디팡팡</span>에 오신 것을 환영합니다!
            </TitleBigText>
            <TitleSubText>선생님은 메디팡팡을 통해 환자의 건강기록을 공유받을 수 있는 <b>스마트진료</b>와 화상으로 진료가 가능한 <b>비대면 진료</b>를 이용하실 수 있습니다.</TitleSubText>
          
            <BoxWrap>
              <BoxTitle><span>진료 / 스케줄 설정</span>에서 비대면 진료 진행 여부에 대해 업데이트 해주세요.</BoxTitle>
            </BoxWrap>
          </TitleSection>

          
          <CloseSection>
            <NoMoreText onClick={handleHide}>다시 보지 않기</NoMoreText>
            <CloseButtonWrapper>
              <ODIcon icon={ODIcons.CoreX} onClick={handleHide}/>
            </CloseButtonWrapper>
          </CloseSection>
        </TopSection>
      )} 

      {showWelcome ? null : (<Hr/>) }
     
      <StepSection>
        <StepButton
          numberText="01."
          icon={ODIcons.CoreContact}
          text="내 정보 확인하기"
          link={SiteUrls.Doctor.MyPage.Main}
        />
        <StepButton
          numberText="02."
          icon={ODIcons.CoreCalendar}
          text="스케줄 등록하기"
          link={SiteUrls.Doctor.Schedule.Main}
        />
        <StepButton
          numberText="03."
          icon={ODIcons.Inquiry}
          text="예약된 진료 확인하기"
          link={SiteUrls.Doctor.Reservation.Main}
        />
      </StepSection>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const TopSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding: 48px 0;
  
`

const StepSection = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  /* border-top: solid 1px var(--gray200); */
  gap: 20px;
`

const Hr = styled.div`
  width: 100%;
  height: 30px;
`
const TitleSection = styled.div``

const TitleBigText = styled.div`
  font-size: 24px;
  font-weight: 400;
  color: #2e343a;
  margin-bottom: 5px;
`

const TitleSubText = styled.div`
  padding-top: 30px;
  font-size: 15px;
  font-weight: 500;
  line-height: 24px;
  color: ${COLORS.GRAY42};

  > b {
    font-weight: 900;
  }
`

const BoxWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 788px;
  padding: 0 18px;
  margin-top: 18px;
  height: 85px;
  border: solid 1px ${COLORS.GRAYD8};
`

const BoxTitle = styled.div`
  font-size: 15px;
  font-weight: 500;
  line-height: 24px;
  color: ${COLORS.GRAY42};

  > span {
    color: ${COLORS.blue200};
  }
 `

 const BoxMessage = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  > .info-message {
    font-size: 13px;
    font-weight: 500;
    line-height: 24px;
    color: ${COLORS.GRAY42};
  }
 `


const CloseSection = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`

const NoMoreText = styled.div`
  font-size: 14px;
  color: var(--gray400);
  margin-right: 10px;
  margin-top: -2px;
  white-space: nowrap;
`

const CloseButtonWrapper = styled.div`
  font-size: 18px;
  color: var(--gray600);
`
