import React from 'react'
import styled from 'styled-components'
import IconX from '../../../assets/common/icon_x.png'
import IconV from '../../../assets/common/icon_v.png'
import IconInfo from '../../../assets/common/warning-red-small-2.svg'
import IconInfoBlue from '../../../assets/common/icon_warning_small_blue.png'
import ArrowDash from '../../../assets/common/arrow-dash.png'
import { GQLMedicationDispensing } from '@mdpp/common/lib/@types/server.schema'

interface IPaymentResultProps {
  reservation: GQLMedicationDispensing
  reload?: boolean
}

export const PaymentResult: React.FC<IPaymentResultProps> = props => {
  const { reservation, reload } = props

  return (
    <>
      <Wrapper>
        <TitleWrap>
          {reservation?.status === 'PAIED' || reservation?.status === 'FINISHED' ? (
            <>
              <TitleIcon src={IconV} />
              <Title>결제 완료</Title>
            </>
          ) : (
            <>
              <TitleIcon src={IconX} />
              <Title>결제 실패</Title>
            </>
          )}
        </TitleWrap>
        <BlockWrap>
          <Block>
            <span>조제비</span>
            <span className="price">{reservation?.medicinePrice ? reservation?.medicinePrice : 0}원</span>
          </Block>
          <Block>
            <span>퀵 배송비</span>
            <div>
              <span className="price">{!!reservation.isPromotion ? '0' : reservation?.quickPrice}원</span>
            </div>
          </Block>
          {/*<Block>*/}
          {/*  <span>택배 배송비</span>*/}
          {/*  <div>*/}
          {/*    <span className="under-price">*/}
          {/*      <img src={ArrowDash} />*/}
          {/*      16,500원*/}
          {/*    </span>*/}
          {/*    <span className="price">0원</span>*/}
          {/*  </div>*/}
          {/*</Block>*/}
        </BlockWrap>
        <MessageWrap>
          {reservation?.status === 'PAIED' || reservation?.status === 'FINISHED' ? (
            <>
              <MessageIcon src={reservation!.deliveryCallStatus !== 'FAILED' ? IconInfoBlue : IconInfo} />
              <Message>
                {reservation!.deliveryCallStatus !== 'FAILED' ? (
                  <>퀵 서비스가 자동 신청되었습니다. 조제약을 포장해주시면 퀵 서비스 기사님이 수거하실 예정입니다.</>
                ) : (
                  <>퀵 서비스 자동 신청에 실패했습니다. 메디팡팡 고객센터로 문의해주세요. (문의 : 1566-8013)</>
                )}
              </Message>
            </>
          ) : (
            <>
              <MessageIcon src={IconInfo} />
              <Message>결제 카드의 오류, 잔액부족 등이 발생하였습니다.</Message>
            </>
          )}
        </MessageWrap>
      </Wrapper>
    </>
  )
}

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 100px auto;
  gap: 25px;
  align-items: center;
`

const TitleWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const TitleIcon = styled.img`
  width: 40px;
  height: 40px;
`

const Title = styled.div`
  font-size: 18px;
  font-weight: 500;
  padding-top: 10px;
`

const BlockWrap = styled.div`
  width: 320px;
`

const Block = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  padding: 0 13px;
  height: 50px;
  background: #f6f7f7;
  border-radius: 4px;

  &:last-child {
    margin-bottom: 0;
  }

  span {
    display: inline-block;
    font-size: 15px;
    font-weight: 500;
    color: #424242;

    &.price {
      color: #007eff;
    }

    &.under-price {
      position: relative;
      width: 76px;
      color: #afb3ba;
      margin-right: 10px;
      padding-left: 3px;

      > img {
        position: absolute;
        top: 8px;
        left: 0;
        width: 78px;
        height: auto;
        z-index: 1;
      }
    }
  }
`

const MessageWrap = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  margin-top: -4px;
`

const MessageIcon = styled.img`
  width: 14px;
  height: 14px;
  margin-right: 5px;
  margin-top: 4px;
`

const Message = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: #7c818b;

  > span {
    font-weight: 700;
    color: #424242;

    &.blue {
      color: #007eff;
    }
    &.red {
      color: #ff5353;
    }
  }
`
