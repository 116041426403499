import React from 'react'
import styled from 'styled-components'

import { ImageButton, BP } from '../RebeccaButton'
import SVGMessage from '../../assets/room/send_message.svg'
import SVGMessageDisabled from '../../assets/room/send_message_disabled.svg'

interface IEvixChatInputBoxProps {
  onSendMessage: (message: string) => void
  onAttachFile: (file: File) => void
}

const Wrapper = styled.div`
  width: 100%;
  height: 75px;
  // min-height: 75px;
  padding-left: 16px;
  padding-right: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  border-top: 1px solid #E4E5E7;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
`

const InputWrapper = styled.div`
  flex-grow: 1;
  background: #EBEDEF;
  border-radius: 80px;
  display: flex;
  align-items: center;
  height: 43px;
  padding-right: 10px;
`

const SendButtonWrapper = styled.div`
  margin-left: 14px;
  width: 34px;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 1000px;
  background-color: var(--mpPrimary500);
  color: var(--white);
`

export const EvixChatInputBox: React.FC<IEvixChatInputBoxProps> = props => {
  const { onSendMessage } = props
  const [text, setText] = React.useState('')

  const imgs = {
    default: SVGMessage,
    disabled: SVGMessageDisabled,
  }

  return (
    <Wrapper>
      <InputWrapper>
        <input
          className="od-input"
          placeholder="메시지 보내기"
          style={{ width: '100%' }}
          value={text}
          onChange={e => setText(e.target.value)}
          onKeyPress={e => {
            if (e.key === 'Enter' && text.trim().length > 0) {
              onSendMessage(text)
              setText('')
            }
          }}
        />

        <ImageButton type={BP.TYPE.InvisibleWithIcon} images={imgs} disabled={text.trim().length === 0} onClick={
          () => {
            if (text.trim().length === 0) {
              return
            }
            onSendMessage(text)
            setText('')
          }
        }></ImageButton>
      </InputWrapper>
    </Wrapper>
  )
}
