import { ODVSpace, ODWebUtils, ODIcon, ODIcons } from '@mdpp/od-react-belt'
import React, { useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { AppOptions } from '../AppOptions'
import { EvixButton, EvixButtonTheme } from '../common/EvixButton'
import { EvixInputText } from '../common/EvixInputText'
import { SiteUrls } from '../SiteUrls'
import { COLORS } from '../styles/colors'
import { InputField, useDefaultData } from '../utils/useDefaultData'
import Package from '../../package.json'

//asset
import {
  LoginWrap,
  LogoStyle,
  Logo,
  Logo2,
  LoginInner,
  MarketingLine,
  InputCheckboxSupportWrapper,
} from '../common/style/CommonStyle'
import LogoDoctor from '../assets/login/bi-mdpp-doctor.png'
import LogoHospital from '../assets/login/bi-mdpp-hospital.png'
import LogoAdmin from '../assets/login/bi-mdpp-admin.png'
import LogoPharmacy from '../assets/login/bi-mdpp-pharma.png'
import Check from '../assets/common/icon-check.png'
import CheckOn from '../assets/common/icon-check-on.png'
import { ErrorCode } from '@mdpp/common'
import { checkMessageWithPrefix } from '../utils/registerServerErrorMessages'
import { ConfirmModal } from '../common/layout/ConfirmModal'
import IconWarningBlue from '../assets/common/warning-blue.svg'

export enum LoginBoxDesignStyle {
  Doctor,
  HospitalManager,
  Admin,
  Pharmacy,
}

interface ILoginBoxProps {
  designStyle: LoginBoxDesignStyle
  onLogin: (email: string, pw: string) => Promise<boolean>
}

const MarketingLineText = {
  [LoginBoxDesignStyle.Doctor]: '의사 계정을 입력하세요.',
  [LoginBoxDesignStyle.HospitalManager]: '병원관리자 계정을 입력하세요.',
  [LoginBoxDesignStyle.Admin]: `메디팡팡 관리자 사이트 (v${Package.version})`,
  [LoginBoxDesignStyle.Pharmacy]: '약사 계정을 입력하세요.',
}

const EmailInputField = {
  [LoginBoxDesignStyle.Doctor]: InputField.DoctorLoginEmail,
  [LoginBoxDesignStyle.HospitalManager]: InputField.ManagerLoginEmail,
  [LoginBoxDesignStyle.Admin]: InputField.AdminLoginEmail,
  [LoginBoxDesignStyle.Pharmacy]: InputField.PharmaLoginEmail,
}

const PasswordInputField = {
  [LoginBoxDesignStyle.Doctor]: InputField.DoctorLoginPassword,
  [LoginBoxDesignStyle.HospitalManager]: InputField.ManagerLoginPassword,
  [LoginBoxDesignStyle.Admin]: InputField.AdminLoginPassword,
  [LoginBoxDesignStyle.Pharmacy]: InputField.PharmaLoginPassword,
}

export const LoginBox: React.FC<ILoginBoxProps> = props => {
  const { onLogin, designStyle } = props
  const [loggingIn, setLoggingIn] = React.useState(false)
  const [email, setEmail] = React.useState(useDefaultData(EmailInputField[designStyle]))
  const [emails, setEmails] = React.useState(useDefaultData(EmailInputField[designStyle]))
  const [pw, setPw] = React.useState(useDefaultData(PasswordInputField[designStyle]))
  const showFindID = true
  const showFindPassword = true
  const showSupport = designStyle === LoginBoxDesignStyle.HospitalManager
  const showSignInDoctor = designStyle === LoginBoxDesignStyle.Doctor
  const showSingInPharmacy = designStyle === LoginBoxDesignStyle.Pharmacy
  const [rememberEmail, setRememberEmail] = React.useState(false)
  const [errors, setErrors] = React.useState({ name: ['empty'] })
  const [permissionWait, setPermissionWait] = React.useState(false)
  const [emailCheck, setEmailCheck] = React.useState(false)

  const handleLogin = async () => {
    const regEmail = /^([0-9a-zA-Z_\.-]+)@([0-9a-zA-Z_-]+)(\.[0-9a-zA-Z_-]+){1,2}$/

    if (loggingIn) {
      return
    }

    if (email === '' || pw === '') {
      return ODWebUtils.showError('이메일 또는 비밀번호를 입력해주세요.')
    }
    if (email !== '' && regEmail.test(email)) {
      console.log('형식에 맞는 이메일입니다.')
    } else {
      return ODWebUtils.showError('이메일 양식을 확인해주세요.')
    }

    try {
      if (rememberEmail) {
        await saveEmail(email)
      }
      setLoggingIn(true)
      const successful = await onLogin(email, pw)
      if (!successful) {
        setLoggingIn(false)
      }
    } catch (ex) {
      console.log(ex)
      setLoggingIn(false)
      if (checkMessageWithPrefix(ex, ErrorCode.SERVER_WRONG_LOGIN_INFO)) {
        return ODWebUtils.showError('로그인에 실패하였습니다.')
      }
      // 로그인 시도 > 이메일 인증안함 > 이메일 인증 하세요 팝업 노출
      if (checkMessageWithPrefix(ex, ErrorCode.EMAIL_NOT_AUTHENTICATED)) {
        setEmailCheck(true)
        setPermissionWait(false)
      }
      // 로그인 시도 > 이메일 인증함/관리자 승인안함 > 승인대기상태 팝업 노출
      if (checkMessageWithPrefix(ex, ErrorCode.ACCOUNT_NOT_APPROVED)) {
        setEmailCheck(false)
        setPermissionWait(true)
      }
      // 로그인 시도 > 이메일 48시간 지남 > 로그인 실패
      if (checkMessageWithPrefix(ex, ErrorCode.SERVER_WRONG_LOGIN_INFO)) {
        setEmailCheck(false)
        setPermissionWait(false)
      }
    }
  }

  // 의사 회원 가입
  const history = useHistory()
  const handleSignUp = () => {
    let path = `/doctor/signup`
    history.push(path)
  }

  // 약국 회원 가입
  // const handleSignUpPharmacy = () => {
  //   let path = `/pharmacy/signup`
  //   history.push(path)
  // }

  // 아이디 찾기
  const forgotIDLink = (() => {
    switch (designStyle) {
      case LoginBoxDesignStyle.Doctor:
        return SiteUrls.ForgotID.Doctor
      case LoginBoxDesignStyle.HospitalManager:
        return SiteUrls.ForgotID.Manager
      case LoginBoxDesignStyle.Admin:
        return SiteUrls.ForgotID.Admin
      case LoginBoxDesignStyle.Pharmacy:
        return SiteUrls.ForgotID.Pharmacy
    }
  })()

  // 패스워드 찾기
  const forgotPasswordLink = (() => {
    switch (designStyle) {
      case LoginBoxDesignStyle.Doctor:
        return SiteUrls.ForgotPassword.Doctor
      case LoginBoxDesignStyle.HospitalManager:
        return SiteUrls.ForgotPassword.Manager
      case LoginBoxDesignStyle.Admin:
        return SiteUrls.ForgotPassword.Admin
      case LoginBoxDesignStyle.Pharmacy:
        return SiteUrls.ForgotPassword.Pharmacy
    }
  })()

  const saveEmail = async (email: string) => {
    switch (designStyle) {
      case LoginBoxDesignStyle.Doctor:
        localStorage.setItem('doctorEmail', email)
        return
      case LoginBoxDesignStyle.HospitalManager:
        localStorage.setItem('managerEmail', email)
        return
      case LoginBoxDesignStyle.Admin:
        localStorage.setItem('adminEmail', email)
        return
      case LoginBoxDesignStyle.Pharmacy:
        localStorage.setItem('pharmacyEmail', email)
        return
    }
  }
  const removeEmail = async (email: string) => {
    switch (designStyle) {
      case LoginBoxDesignStyle.Doctor:
        localStorage.removeItem('doctorEmail')
        return
      case LoginBoxDesignStyle.HospitalManager:
        localStorage.removeItem('managerEmail')
        return
      case LoginBoxDesignStyle.Admin:
        localStorage.removeItem('adminEmail')
        return
      case LoginBoxDesignStyle.Pharmacy:
        localStorage.removeItem('pharmacyEmail')
        return
    }
  }

  const getRememberEmail = () => {
    switch (designStyle) {
      case LoginBoxDesignStyle.Doctor:
        return localStorage.getItem('doctorEmail')
      case LoginBoxDesignStyle.HospitalManager:
        return localStorage.getItem('managerEmail')
      case LoginBoxDesignStyle.Admin:
        return localStorage.getItem('adminEmail')
      case LoginBoxDesignStyle.Pharmacy:
        return localStorage.getItem('pharmacyEmail')
    }
  }

  const handleCheck = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = e.target.checked
    setRememberEmail(isChecked)
    isChecked ? await saveEmail(email) : await removeEmail(email)
  }

  useEffect(() => {
    document.title =
      designStyle === 0
        ? '메디팡팡:의료진'
        : designStyle === 1
        ? '메디팡팡:병원관리자'
        : designStyle === 2
        ? '메디팡팡:Admin'
        : '메디팡팡:약국'
    const storageRememberEmail = getRememberEmail()
    if (storageRememberEmail) {
      setRememberEmail(true)
      // @ts-ignore
      setEmail(storageRememberEmail)
    }
  }, [])

  return (
    <LoginWrap>
      <LogoStyle>
        {/* 의사일 경우 */}
        {designStyle === 0 && <img src={LogoDoctor} style={Logo} alt="MediPangPang Logo" />}

        {/* 병원 관리자일 경우 */}
        {designStyle === 1 && <img src={LogoHospital} style={Logo} alt="MediPangPang Logo" />}

        {/* 관리자일 경우 */}
        {designStyle === 2 && <img src={LogoAdmin} style={Logo2} alt="MediPangPang Logo" />}

        {/* 약사일 경우 */}
        {designStyle === 3 && <img src={LogoPharmacy} style={Logo} alt="MediPangPang Logo" />}
      </LogoStyle>

      {/* 로그인 박스 영역 */}
      <LoginInner>
        <MarketingLine>{MarketingLineText[designStyle]}</MarketingLine>
        <ODVSpace h={17} />

        <EvixInputText
          placeholder="email"
          value={email}
          onChange={setEmail}
          disabled={loggingIn}
          onEnter={handleLogin}
          title={'이메일 아이디 입력'}
        />
        <ODVSpace h={16} />
        <EvixInputText
          placeholder="password"
          type="password"
          value={pw}
          onChange={setPw}
          disabled={loggingIn}
          onEnter={handleLogin}
          title={'비밀번호 입력'}
        />
        <ODVSpace h={12} />

        <InputCheckboxSupportWrapper>
          <input type="checkbox" id="cb1" onChange={handleCheck} checked={rememberEmail} />
          <label htmlFor="cb1" style={{ marginBottom: 0 }}>
            이메일 아이디 기억하기
          </label>
        </InputCheckboxSupportWrapper>
        <ODVSpace h={30} />

        <EvixButton theme={EvixButtonTheme.H48Black} onClick={handleLogin} disabled={loggingIn}>
          로그인
        </EvixButton>
        <ODVSpace h={10} />

        {/* 아이디 패스워드 찾기 */}
        <SearchIDPwdWrap>
          <Link to={forgotIDLink}>
            {showFindID && designStyle === 0 && <FindText>아이디 찾기</FindText>}
            {showFindID && designStyle === 3 && <FindText>아이디 찾기</FindText>}
          </Link>

          <PasswordWrap>
            {showFindPassword && (
              <Link to={forgotPasswordLink}>
                <FindText>비밀번호 찾기</FindText>
              </Link>
            )}

            {showSupport && (
              <EmailSupportWrapper>
                <ODIcon icon={ODIcons.CoreEnvelopeClosed} style={{ marginTop: -2, marginRight: 5 }} />
                <a
                  href={`mailto:${AppOptions.SUPPORT_EMAIL}?subject=[메디팡팡 병원관리 문의] : &body=[성명]:           %0D%0A [소속]:               %0D%0A [이메일]:            %0D%0A [연락처]:           %0D%0A [문의내용]:             %0D%0A %0D%0A %0D%0A *빠른 시간안에 답변 드리겠습니다.%0D%0A 메디팡팡 (주)에비드넷 서울특별시 강남구 강남대로 292 뱅뱅빌딩 3층 1566-8013`}
                >
                  <span style={{ color: `${COLORS.GRAY7A}` }}>문의</span>
                </a>
              </EmailSupportWrapper>
            )}
          </PasswordWrap>
        </SearchIDPwdWrap>

        {/* 의사 회원 가입 */}
        {showSignInDoctor && (
          <>
            <ODVSpace h={68} />
            <EvixButton theme={EvixButtonTheme.H48White} onClick={handleSignUp}>
              의사 회원 가입
            </EvixButton>
          </>
        )}

        {/* 약국 회원 가입 */}
        {/* {showSingInPharmacy && (
          <>
            <ODVSpace h={68} />
            <EvixButton theme={EvixButtonTheme.H48White} onClick={handleSignUpPharmacy}>
              약국 회원 가입
            </EvixButton>
          </>
        )} */}
      </LoginInner>

      {/* 승인 대기 상태 Modal */}
      {permissionWait && (
        <ConfirmModal
          style={{ width: 420, height: 250 }}
          isTitle={true}
          title={'승인 대기 상태'}
          titleIcon={IconWarningBlue}
          isDescriptionIcon={false}
          description={[
            '이메일 인증 완료 후 승인 대기 중입니다.',
            <br />,
            '관리자의 승인이 완료되면 로그인이 가능합니다.',
          ]}
          descriptionIcon={''}
          hideCancel={true}
          onOkPress={() => setPermissionWait(false)}
          onCancelPress={() => setPermissionWait(false)}
        />
      )}

      {/* 이메일 미인증 상태 Modal */}
      {emailCheck && (
        <ConfirmModal
          style={{ width: 420, height: 227 }}
          isTitle={true}
          title={'이메일 미인증 상태'}
          titleIcon={IconWarningBlue}
          isDescriptionIcon={false}
          description={['유효기간 내에 이메일 인증을 완료해주세요.']}
          descriptionIcon={''}
          hideCancel={true}
          onOkPress={() => setEmailCheck(false)}
          onCancelPress={() => setEmailCheck(false)}
        />
      )}
    </LoginWrap>
  )
}

const SearchIDPwdWrap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

const FindText = styled.span`
  font-size: 13px;
  font-weight: 500;
  color: ${COLORS.GRAY7A};

  &:hover {
    color: ${COLORS.BLACK};
  }
`

const PasswordWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`

const EmailSupportWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  font-weight: 500;
  color: ${COLORS.GRAY7A};
  margin-left: 20px;
`
