import { ODIcon, ODIcons, ODModal, ODModalSize, ODVSpace, ODHSpace, ODHSpread,
  useODModalConfirm } from '@mdpp/od-react-belt'
import React, { useEffect } from 'react'
import moment from 'moment/moment'
import styled from 'styled-components/macro'
import {
  GQLPatientMemoCreationInput,
  GQLPatientMemoUpdateInput,
  GQLPatientMemoDeleteInput,
  GQLReservation, GQLDoctor,
  GQLPatientMemo,
  GQLPatient,
} from '@mdpp/common/lib/@types/server.schema'

import { Button, BP } from '../../common/RebeccaButton'
import { PopupModal } from '../../common/layout/PopupModal'

interface IPatientMemoModalPopupProps {
  isOpen: boolean
  onClose: () => void
  profile?: GQLDoctor | null
  onSubmit: (input: GQLPatientMemoCreationInput | GQLPatientMemoUpdateInput) => void
  onDelete: (input: GQLPatientMemoDeleteInput) => void
  reservation: GQLReservation
  memo?: GQLPatientMemo | null
  refresh: (input: boolean) => void
}

export const PatientMemoModalPopup: React.FC<IPatientMemoModalPopupProps> = props => {
  const { isOpen, onClose, onSubmit, onDelete, reservation, profile, memo, refresh } = props

  const [patient, setPatient] = React.useState<GQLPatient|null>(reservation.patient)
  const [showPopup, setShowPopup] = React.useState(false)

  const { Component: DelConfirmComponent, confirm: confirmDelete, props: confirmDeleteProps } = useODModalConfirm({
    title: '메모',
    message: '정말 삭제하시겠습니까?\n삭제한 내용은 복구할 수 없습니다.',
    yes: '삭제',
    no: '취소',
  })

  useEffect(() => {
    setPatient(reservation.patient)
  }, [reservation.patientId])

  const getBirthDate = () => {
    if (patient) {
      const birth = moment(patient.fullBirthDate, 'YYYYMMDD').format('YYYY.MM.DD')
      return birth
    }
    return null
  }

  const getPatientInfo = () => {
    if (patient) {
      const birthDate = getBirthDate()
      const gender = patient.gender === 'male' ? '남성' : '여성'
      const phone = patient.phone

      return `${birthDate} • ${gender} • ${phone}`
   }
   return ''
  }

  const [content, setContent] = React.useState('')
  useEffect(() => {
    setContent(memo?.content || '')
  }, [memo])

  const onSave: React.MouseEventHandler<HTMLButtonElement> = (e: any) => {
    e.preventDefault()
    if (profile) {
      onSubmit({ doctorId: profile.doctorId, patientId: reservation.patientId, content })
      refresh(true)
    }
  }

  const onDel = () => {
    if (profile) {
      onDelete({ doctorId: profile.doctorId, patientId: reservation.patientId })
      refresh(true)
    }
  }

  return (
    <>
      <DelConfirmComponent {...confirmDeleteProps} />
      {showPopup && (
        <PopupModal
          title={'메모'}
          description={'정말 삭제하시겠습니까? 삭제한 내용은 복구할 수 없습니다.'}
          onOkPress={onDel}
          onCancelPress={() =>(setShowPopup(false))}
        />
      )}
      { !confirmDeleteProps.isOpen &&
        <ODModal rounded={true} isOpen={isOpen} toggle={onClose} size={ODModalSize.Normal}>
          <ODHSpread style={{ padding: 20, alignItems: 'center' }}>
            <ModalTitle>메모</ModalTitle>
            <CloseButtonBox onClick={onClose}>
              <ODIcon icon={ODIcons.CoreX} style={CloseIconStyle} />
            </CloseButtonBox>
          </ODHSpread>
          <Wrapper>
            <Separator />
            <Inner>
              <Subtitle>{patient?.name}</Subtitle>
              <SubContent>{getPatientInfo()}</SubContent>
              <ODVSpace h={33} />
              <CountTextInput htmlFor={'contents'}>
                <InputTextArea
                  onChange={e => {
                    if (e.target.value.length <= 1000) {
                      setContent(e.target.value)}
                    }}
                  value={content ?? ''}
                  id={'content'}
                  placeholder={'메모할 내용을 적어주세요.'}
                />
                <TextNum>글자 수 ({content.length}/1000)</TextNum>
              </CountTextInput>
              <ODVSpace h={15} />
            </Inner>
            <Separator />
            <Footer>
              <FooterGroupLeft>
                <Button size={BP.SIZE.M} type={BP.TYPE.PrimaryDestructive} onClick={() => (setShowPopup(true))} disabled={!memo}>삭제</Button>
              </FooterGroupLeft>
              <FooterGroupRight>
                <Button onClick={onClose} size={BP.SIZE.M}>취소</Button>
                <ODHSpace w={8} />
                <Button
                  size={BP.SIZE.M}
                  type={BP.TYPE.Primary}
                  onClick={onSave}
                  disabled={content.length === 0 || memo?.content === content}>
                    {!!memo ? '수정' : '저장'}
                </Button>
              </FooterGroupRight>
            </Footer>
          </Wrapper>
        </ODModal>
      }
    </>
  )
}

const CloseIconStyle = {
  fontSize: 19,
  color: '#5C5F62',
}
const Wrapper = styled.div`
  background-color: var(--white);
  position: relative;
`
const Inner = styled.div`
  padding: 20px;
`
const Separator = styled.div`
  height: 1px;
  background-color: var(--gray200);
`

const ModalTitle = styled.span`
  font-size: 18px;
  font-weight: 500;
  font-style: normal;
  text-align: left;
  margin: 0;
`

const Subtitle = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
`
const SubContent = styled.span`
  font-size: 12px;
  font-style: normal;
  font-weight: normal;
  font-color: #7F8386;
`

const CloseButtonBox = styled.div`
  right: 25px;
  cursor: pointer;
`
const CountTextInput = styled.label`
  line-height: 18px;
  font-size: 14px;
  letter-spacing: 0.26px;
  color: var(--gray900);
  margin: 0;
  width: 100%;
`

const InputTextArea = styled.textarea`
  width: 100%;
  height: 320px;
  padding: 7px 12px;
  border-radius: 4px;
  border: solid 1px var(--gray300);
  background-color: var(--white);
  resize: none;
`

const Footer = styled.div`
  display: flex;
  justify-content: space-evenly;
  padding: 20px;
`

const FooterGroupLeft = styled.div`
  margin-right: auto;
`
const FooterGroupRight = styled.div`
  display: flex;
  justify-content: space-between;
`

const MemoButton = styled.button`
  width: 100px;
  height: 40x;
  padding: 15px 0 13px 3px;
  background-color: var(--mpPrimary500);
  font-size: 15px;
  line-height: 13px;
  color: var(--white);
  letter-spacing: 0.21px;
  text-align: center;
  border-radius: 10px;

  &:disabled {
    background-color: lightgray;
  }
`

const TextNum = styled.span`
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  color: #6D7175;
`
