import { ODHSpace, ODIcon, ODIcons } from '@mdpp/od-react-belt'
import React from 'react'
import styled from 'styled-components'
import {Link} from "react-router-dom";

interface IEvixCardHeaderProps {
  icon?: ODIcons | null
  title: string
  isRoom?: boolean
  isInsideRoom?: boolean
  moreView?: boolean
}
type LinkProps = {
  link?: string | '#'
}
const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`
const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const Text = styled.div`
  font-size: 18px;
  line-height: 24px;
  color: var(--black);
  font-weight: 500;
  letter-spacing: -0.5px;
`
const RoomSectionHeader = styled.div`
  font-size: 15px;
  line-height: 24px;
  color: var(--gray800);
  font-weight: 500;
  letter-spacing: -0.5px;
`
const ViewAllButton = styled.div`
  padding: 1px 4px;
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 18px;
  color: var(--interactive-default);
  cursor: pointer;
`

export const EvixCardHeader: React.FC<IEvixCardHeaderProps & React.HTMLAttributes<HTMLDivElement> & LinkProps> = props => {
  const { icon = null, title, isRoom = false, isInsideRoom = false, moreView = false, link = null, ...others } = props
  return (
    <HeaderWrapper>
      {!isRoom && (
        <Wrapper {...others}>
          {icon && (
            <>
              <ODIcon icon={icon} style={{fontSize: 20}} />
              <ODHSpace w={6} />
            </>
          )}
          {isInsideRoom ? <RoomSectionHeader>{title}</RoomSectionHeader> : <Text>{title}</Text> }
        </Wrapper>
      )}
      {moreView && (
          <Link to={link || '#'} style={{ textDecoration: 'none' }}>
            <ViewAllButton>전체 보기</ViewAllButton>
          </Link>
      )

      }
    </HeaderWrapper>
  )
}
