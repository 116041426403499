import { ODListableOption, ODListableResponseType, ODModalSize, usePickerTableModal } from '@mdpp/od-react-belt'
import React from 'react'
import { GQLDiagName } from '@mdpp/common/lib/@types/server.schema'
import { useCommonAPIs } from '../../../../common/hooks/useCommonAPIs'

const THeads = ['코드', '한글 진단명', '영문 진단명']

interface IDiagNameListableOptions extends ODListableOption {
  filter: string
}

export function useReservationHasDiagnosisModal() {
  const { listDiagNames } = useCommonAPIs()

  const dataLoader = React.useCallback(
    async function dataLoader(
      page: number,
      pageSize: number,
      afterKey: string | null,
      options: IDiagNameListableOptions
    ): Promise<ODListableResponseType<GQLDiagName>> {
      const r = await listDiagNames({
        page,
        pageSize,
        ...options,
      })
      return r as ODListableResponseType<GQLDiagName>
    },
    [listDiagNames]
  )

  return usePickerTableModal<GQLDiagName, IDiagNameListableOptions>({
    size: ODModalSize.XLarge,
    rounded: false,
    pickDefaultOptions: {
      confirmButtonTitle: '추가',
      title: '진단명 추가',
    },
    dataLoader,
    keyExtractor: v => v.diagNameId,
    numColumns: THeads.length,
    renderTH: (index: number) => THeads[index],
    renderTD: (index: number, value) => {
      switch (index) {
        case 0:
          return value.code
        case 1:
          return value.koDesc
        case 2:
          return value.enDesc
        default:
          return '-'
      }
    },
  })
}
