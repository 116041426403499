import React, { useEffect } from 'react'
import styled from 'styled-components'
import { TableList } from '../../../common/list/TableList'
import { EvixButton, EvixButtonTheme } from '../../../common/EvixButton'
import { WarningMessageWrap } from '../../../common/style/SignUpStyle'
import { EvixInput } from '../../../common/EvixInput'
import { GQLMedicationDispensing } from '@mdpp/common/lib/@types/server.schema'
import { usePharmacyAPIs } from '../../context/usePharmacyAPIs'
import { InputCheckboxSupportWrapper, TitleWrap, SubTitle, SubTitleMessage } from '../../../common/style/CommonStyle'
import { IsBtnConfirmModal } from '../../../common/layout/IsBtnConfirmModal'
import IconWarningBlue from '../../../assets/common/warning-blue-super.svg'
import IconX from '../../../assets/common/icon_x.png'
import { COLORS } from '../../../styles/colors'
import { PaymentResult } from './PaymentResult'
import { validator } from '../../../validator'
import { addressMemo, addressParser } from '../../../utils/webUtils'

interface IPaymentProps {
  id?: number
  reservation: GQLMedicationDispensing
  reload?: boolean
  handlePay: ((medicinePay: number) => void) | null | undefined
}

export const Payment: React.FC<IPaymentProps> = props => {
  const { reservation, handlePay, reload } = props
  const [selectedQuick, setSelectedQuick] = React.useState(false)
  const [selectedDelivery, setSelectedDelivery] = React.useState(false)
  const [nextBtn, setNextBtn] = React.useState(true)
  const { chargeDispensingFee } = usePharmacyAPIs()
  const [refresh, setRefresh] = React.useState(reload)
  const [medicinePay, setMedicinePay] = React.useState('')

  React.useEffect(() => {
    if (reload !== refresh) {
      setRefresh(reload)
    }
  }, [reservation])

  useEffect(() => {
    const medicineNumber = Number(medicinePay)

    if (!medicinePay || Object.keys(validator.account(medicinePay)).length > 0) {
      return setNextBtn(true)
    }
    if (
      medicineNumber < 0 ||
      (medicineNumber >= 0 && !(medicineNumber === 0 || (medicineNumber >= 100 && medicineNumber <= 5000000)))
    ) {
      return setNextBtn(true)
    }
    setNextBtn(false)
  }, [medicinePay])

  const handleSelectedQuick = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = e.target.checked
    setSelectedQuick(isChecked)
    // isChecked ? await saveEmail(email) : await removeEmail(email)
  }

  const SelectedDelivery = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = e.target.checked
    setSelectedDelivery(isChecked)
    // isChecked ? await saveEmail(email) : await removeEmail(email)
  }

  return (
    <>
      {/* 비용 청구 - 조제 취소일 경우  */}
      {reservation && reservation?.status === 'CANCELED' && (
        <Overlay>
          <ColumnCenterWrap>
            <TitleIcon src={IconX} />
            <Title>조제 취소</Title>
          </ColumnCenterWrap>
          <Message className="white">해당 처방 건의 조제가 취소되었습니다.</Message>
        </Overlay>
      )}

      {/* 비용 청구 - 조제 신청 / 조제 승인 / 조제 취소일 경우  */}
      <Wrapper>
        {((reservation && reservation?.status === 'REQUESTED') ||
          reservation?.status === 'ACCEPTED' ||
          reservation?.status === 'CANCELED') && (
          <>
            <TitleWrap>
              <SubTitle>비용 청구</SubTitle>
              {(reservation?.status === 'REQUESTED' || 'ACCEPTED') && (
                <SubTitleMessage>비용청구를 완료하시면 [조제승인] 상태가 됩니다.</SubTitleMessage>
              )}
            </TitleWrap>
            <TableList name="price" label="조제비" labelMDSize={12}>
              <EvixInput
                style={{ flex: 1 }}
                value={reservation?.medicinePrice ? String(reservation?.medicinePrice) : medicinePay}
                onChange={value => setMedicinePay(value)}
                validators={validator.account}
                placeholder="최소 100 ~ 최대 5,000,000원 (미청구시 0원)"
                disabled={reservation?.medicinePrice ? true : false}
                maxLength={7}
              />
              <WarningMessageWrap className={Object.keys(validator.account(medicinePay)).length !== 0 ? 'hide' : ''} />
              <span
                className="error"
                style={{
                  textAlign: 'right',
                  color: '#ff5353',
                  fontSize: '13px',
                  fontWeight: 500,
                  position: 'absolute',
                  top: '147px',
                  right: '12px',
                }}
              >
                최소 100 ~ 최대 5,000,000원 (미청구시 0원)
              </span>
            </TableList>

            <TableList name="deliveryPrice" label="배송비" labelMDSize={5}>
              <Block>
                <TableList name="deliveryPrice" label="환자 주소(배송지)" labelMDSize={5}>
                  <div>{addressParser(reservation?.patientAddressJson!)}</div>
                  <TextAreaHelpText>
                    (배송 메모 :{' '}
                    {reservation.patientAddressJson ? addressMemo(reservation.patientAddressJson!) : '없음'})
                  </TextAreaHelpText>
                </TableList>
              </Block>
            </TableList>

            <ColumnWrap>
              <InputCheckboxSupportWrapper>
                <input type="checkbox" id="cb1" onChange={handleSelectedQuick} checked={true} />
                <label htmlFor="cb1" style={{ marginBottom: 0 }}>
                  퀵 서비스
                </label>
              </InputCheckboxSupportWrapper>
              <div className="price-wrap">
                <EvixInput
                  value={reservation?.quickPrice !== null ? String(reservation?.quickPrice) : ''}
                  disabled={true}
                  maxLength={5}
                />
                <WarningMessageWrap style={{ textAlign: 'right' }}>
                  <span
                    className="error"
                    style={{
                      textAlign: 'right',
                      color: '#ff5353',
                      fontSize: '13px',
                      fontWeight: 500,
                      position: 'relative',
                      top: '-29px',
                      float: 'right',
                    }}
                  >
                    퀵 배송비는 자동 입력됩니다.
                  </span>
                </WarningMessageWrap>
              </div>
            </ColumnWrap>
            {/*<ColumnWrap>*/}
            {/*  <InputCheckboxSupportWrapper>*/}
            {/*    <input type="checkbox" id="cb2" onChange={SelectedDelivery} checked={selectedDelivery} />*/}
            {/*    <label htmlFor="cb2" style={{ marginBottom: 0 }}>*/}
            {/*      택배*/}
            {/*    </label>*/}
            {/*  </InputCheckboxSupportWrapper>*/}
            {/*  <div className="price-wrap">*/}
            {/*    <EvixInput*/}
            {/*      value={'3,000'}*/}
            {/*      // onChange={value => CheckPharmaNo(value)}*/}
            {/*      // validators={validator.numberCode}*/}
            {/*      disabled={!selectedDelivery}*/}
            {/*      maxLength={5}*/}
            {/*    />*/}
            {/*    <WarningMessageWrap style={{ textAlign: 'right' }}>*/}
            {/*      <span*/}
            {/*        className="error"*/}
            {/*        style={{*/}
            {/*          textAlign: 'right',*/}
            {/*          color: '#ff5353',*/}
            {/*          fontSize: '13px',*/}
            {/*          fontWeight: 500,*/}
            {/*          position: 'relative',*/}
            {/*          top: '-29px',*/}
            {/*          float: 'right',*/}
            {/*        }}*/}
            {/*      >*/}
            {/*        최소 0 ~ 최대 N 원*/}
            {/*      </span>*/}
            {/*    </WarningMessageWrap>*/}
            {/*  </div>*/}
            {/*</ColumnWrap>*/}
            {
              <ButtonWrap>
                {reservation?.status !== 'ACCEPTED' ? (
                  <EvixButton
                    theme={EvixButtonTheme.H48BlueInline}
                    onClick={() => {
                      if (handlePay) {
                        handlePay(Number(medicinePay))
                      }
                    }}
                    disabled={nextBtn}
                  >
                    조제비 청구
                  </EvixButton>
                ) : (
                  <EvixButton theme={EvixButtonTheme.H48BlueInline} onClick={() => null} disabled={true}>
                    결제 요청 중
                  </EvixButton>
                )}
              </ButtonWrap>
            }
          </>
        )}

        {/* 비용 청구 - 조제 거절일 경우  */}
        {reservation && reservation?.status === 'REFUSED' && (
          <>
            <TitleWrap>
              <SubTitle>비용 청구</SubTitle>
            </TitleWrap>
            <ColumnCenterWrap className="pd-100">
              <TitleIcon src={IconX} />
              <Message className="red">현재는 비용을 청구할 수 없습니다.</Message>
            </ColumnCenterWrap>
          </>
        )}

        {/* 비용 청구 - 결제 완료 / 결제 실패일 경우  */}
        {((reservation && reservation?.status === 'PAIED') ||
          reservation?.status === 'NOT_PAIED' ||
          reservation?.status === 'FINISHED') && <PaymentResult reservation={reservation!} />}
      </Wrapper>
    </>
  )
}

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 10px;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 10px;
  gap: 32px;
`

const TextAreaHelpText = styled.div`
  display: inline-block;
  font-size: 12px;
  line-height: 1.17;
  font-weight: 500;
  color: var(--gray600);
`
const ColumnWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 32px;

  > div:first-child {
    width: 100px;
    padding-top: 12px;
  }

  .price-wrap {
    width: calc(100% - 100px);
  }
`

const ButtonWrap = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
`
const Block = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-radius: 0 !important;
  /* width: 680px; */
  padding: 18px 50px 18px 30px;
  background-color: #f4f6f8;
  gap: 50px;
`
const ColumnCenterWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &.pd-100 {
    padding: 100px 0 150px;
  }
`

const TitleIcon = styled.img`
  width: 40px;
  height: 40px;
`

const Title = styled.div`
  font-size: 18px;
  font-weight: 500;
  padding-top: 10px;
  color: #fff;
`

const Message = styled.div`
  font-size: 15px;
  font-weight: 500;
  padding-top: 20px;

  &.white {
    color: #fff;
  }

  &.red {
    color: ${COLORS.red100};
  }
`
