import * as changeCase from 'change-case'
import React from 'react'
import { GQLRESERVATION_STATUS } from '@mdpp/common/lib/@types/server.schema'
import { WebUtils } from '../utils/webUtils'

interface IReservationStatusBadgeProps {
  status: GQLRESERVATION_STATUS
}

export const ReservationStatusBadge: React.FC<IReservationStatusBadgeProps> = props => {
  const { status } = props
  return (
    <div className={`enum_tag_${changeCase.snakeCase(status)}`}>
      <span>{WebUtils.formatReservationStatus(status)}</span>
    </div>
  )
}
