import { IODListableEventContext, ODListableOption } from '@mdpp/od-react-belt'
import { GQLHospitalManager } from '@mdpp/common/lib/@types/server.schema'

export const HospitalManagerPrimaryKey = 'hospitalManagerId'

// modifiable fields only.
export interface ICTHospitalManager
  extends Pick<GQLHospitalManager, 'hospitalManagerId' | 'name' | 'email' | 'hospitalId'> {
  // more fields, mapped (if needed)
}

export interface IHospitalManagerListableOption extends ODListableOption {
  filter: string
  hospitalId: number
}

export interface IHospitalManagerEntityContext extends IODListableEventContext<GQLHospitalManager> {
  //
}
