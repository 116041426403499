import React from 'react'
import { Page, PageContainer, PageHeader, PageTitle } from '../../common/page/Page'
import { WaitingPharmacyListableTable } from '../../common/waitingPharmacy/list/WaitingPharmacyListableTable'

interface IAdminPharmacyPageProps {
  hospitalId: number
}

export const AdminWaitingPharmacyListPage: React.FC<IAdminPharmacyPageProps> = props => {
  return (
    <PageContainer>
      <Page>
        <PageHeader>
          <PageTitle>약국 가입승인</PageTitle>
        </PageHeader>

        <WaitingPharmacyListableTable fromHospitalManager={false} />
      </Page>
    </PageContainer>
  )
}
