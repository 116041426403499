import '@coreui/coreui/scss/coreui.scss'
import { ODGalleryRoot, ODGlobal, ODLoading, ODModalProvider } from '@mdpp/od-react-belt'
import { ODGlobalDef, ODGlobalTheme } from '@mdpp/od-belt'
import { ConnectedRouter, connectRouter, routerMiddleware } from 'connected-react-router'
import { createBrowserHistory } from 'history'
import React from 'react'
import { ApolloProvider } from 'react-apollo-hooks'
import { Provider } from 'react-redux'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { Slide, toast, ToastContainer } from 'react-toastify'
import { RecoilRoot } from 'recoil'
import { applyMiddleware, combineReducers, compose, createStore } from 'redux'
import { QueryParamProvider } from 'use-query-params'
import { AppOptions } from './AppOptions'
import { AppRoot } from './AppRoot'
import { SiteUrls } from './SiteUrls'
import { GlobalStyle } from './styles/global-styles'
import './styles/odStyle.scss'
import { createApolloClient } from './utils/apolloClientUtils'
import { registerServerErrorMessages } from './utils/registerServerErrorMessages'
import 'react-dates/lib/css/_datepicker.css'
import { COLORS } from './styles/colors'

const { client } = createApolloClient(AppOptions.SERVER_ADDRESS, AppOptions.WS_SERVER_ADDRESS)
const history = createBrowserHistory()

// @ts-ignore
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const store = createStore(
  combineReducers({
    router: connectRouter(history),
  }),
  {},
  composeEnhancers(
    applyMiddleware(
      routerMiddleware(history) // for dispatching history actions
      // ... other middlewares ...
    )
  )
)

toast.configure()

const containerStyle = {
  zIndex: 1999,
}

const globalThemeDef: ODGlobalDef = {
  sidebar: {
    bgColor: '#F9FAFB',
    iconColor: '#5C6063',
    textColor: '#4F5356',
    activeBGColor: '#EBEDF1',
    activeIconColor: '#3096f5',
    activeTextColor: '#1a7fe4',
    hoverBGColor: '#F0F2F4',
    hoverIconColor: '{{primary}}',
    hoverTextColor: '#1a7fe4',
    // marginTop: '5px',
  },
  appHeader: { topBorderSize: '0', topBorderColor: '{{primary}}' },
  logo: { logoString: '메디팡팡', textColor: '{{primary}}' },
  css: { linkColor: '#29b6ca', linkHoverColor: '#20a8d8' },
  serviceName: 'Evix',
  theme: ODGlobalTheme.CoreUILike,
  basePalette: {},
  palette: { primary: COLORS.mpPrimary500, secondary: COLORS.mpSecondary400 },
}

registerServerErrorMessages()

const App: React.FC = () => {
  return (
    <ODGlobal def={globalThemeDef}>
      <Provider store={store}>
        <RecoilRoot>
          <ConnectedRouter history={history}>
            <ODModalProvider>
              <ApolloProvider client={client}>
                <GlobalStyle />
                <ToastContainer position="bottom-center" autoClose={5000} style={containerStyle} transition={Slide} />
                <Router>
                  <QueryParamProvider ReactRouterRoute={Route}>
                    <React.Suspense fallback={ODLoading}>
                      <Switch>
                        <Route path={SiteUrls.ODGallery.Root} component={ODGalleryRoot} />
                        <Route path="" component={AppRoot} className="app-route" />
                      </Switch>
                    </React.Suspense>
                  </QueryParamProvider>
                </Router>
              </ApolloProvider>
            </ODModalProvider>
          </ConnectedRouter>
        </RecoilRoot>
      </Provider>
    </ODGlobal>
  )
}

export default App
