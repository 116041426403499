import React from 'react'
import { LinkStyle } from '../ScheduleStatusPopOver'
import { ScheduleEditing } from './ScheduleEditing'


interface IScheduleEditingPageProps {
  doctorId: number
  linkToDefaultSetup: string
  compactMargin: boolean
  isFromDoctorSelf: boolean
  linkStyle: LinkStyle
  inTab?: boolean
}

export const ScheduleEditingPage: React.FC<IScheduleEditingPageProps> = props => {
  const { doctorId, linkToDefaultSetup, compactMargin, isFromDoctorSelf, linkStyle, inTab = false } = props
  
  return (
      <>
        {/* {isFromDoctorSelf && <ScheduleSettingBox link={linkToDefaultSetup} />} */}
        <ScheduleEditing
          doctorId={doctorId}
          linkToSetting={linkToDefaultSetup}
          compactMargin={compactMargin}
          linkStyle={linkStyle}
          fromDoctor={isFromDoctorSelf}
          inTab={inTab}
        />
      </>
  )
}
