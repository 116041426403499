import moment from 'moment-timezone'
import {
  ODDateTimeRenderer,
  ODEnumRenderer,
  ODListablePaginatedTable2,
  ODListablePagination,
  ODListableResponseType,
  ODListableSearchBox,
  ODStringRenderer,
  useODListableContext,
  ODVSpace,
  ODHSpace,
} from '@mdpp/od-react-belt'
import { ODEnumRendererStyle, ODRendererEventType } from '@mdpp/od-belt'
import React, { CSSProperties } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { Card, CardBody } from 'reactstrap'
import styled from 'styled-components'
import { GQLPAYMENT_STATE, GQLReservation, GQLRESERVATION_STATUS } from '@mdpp/common/lib/@types/server.schema'
import { COLORS } from '../../../styles/colors'
import { RESERVATION_STATUS_TEXT, WebUtils, getDeviceState, OS, ERROR_TYPE } from '../../../utils/webUtils'
import { IReservationListableOption, ReservationPrimaryKey } from '../ReservationCommon'
import { useReservationEventHandler } from '../ReservationEventHandler'
import { SiteUrls } from '../../../SiteUrls'
import { PatientMemoComp } from '../../memo/PatientMemoComp'
import { Button, BP } from '../../RebeccaButton'
import { openPopup, closePopup } from '../../../common/DevicePopup'
import { ReservationToolBar } from '../../../doctor/reservation/ReservationToolBar'
import { EvixCard } from '../../card/EvixCard'
import { DoctorSSEReceiver } from '../../../doctor/reservation/DoctorSSEReceiver'

type ReservationListableDataLoader = (
  page: number,
  pageSize: number,
  afterKey: string | null,
  options: IReservationListableOption
) => Promise<ODListableResponseType<GQLReservation>>

interface IReservationListableTableProps {
  showSearch?: boolean
  dataLoader: ReservationListableDataLoader
  linkToDetail: (id: string | number) => string
  fromHospitalManager?: boolean
  fromAdmin?: boolean
  fromHospitalManagerDashboard?: boolean
  fromPharmacy?: boolean
  toolbox?: boolean
  value?: any
  onChange?: any
}

interface IReservationColumnInfo {
  headerName: string
  render: (value: GQLReservation, context: any) => React.ReactNode
  thStyle?: CSSProperties
}

enum RT {
  Status,
  CreatedAt,
  ReservationTime,
  PatientNameHidden,
  PatientName,
  DoctorName,
  MedicalSubject,
  Memo,
  Message,
  PayRequestedAt,
  PayStatus,
  PaymentAmount,
  Symptom,
  PatientGender,
  PatientBirthDate,
  Room,
  HospitalName,
}

const COLUMNS_PER_STYLE = {
  Admin: [
    RT.Status,
    RT.ReservationTime,
    RT.CreatedAt,
    RT.HospitalName,
    RT.DoctorName,
    RT.MedicalSubject,
    RT.PatientNameHidden,
    RT.PayStatus,
    RT.PayRequestedAt,
    RT.PaymentAmount,
  ],
  Doctor: [
    RT.Status,
    RT.ReservationTime,
    RT.PatientName,
    RT.PatientGender,
    RT.PatientBirthDate,
    RT.Memo,
    RT.MedicalSubject,
    RT.Symptom,
    RT.Room,
  ],
  Manager: [
    RT.Status,
    RT.ReservationTime,
    RT.CreatedAt,
    RT.DoctorName,
    RT.MedicalSubject,
    RT.PatientNameHidden,
    RT.PayStatus,
    RT.PayRequestedAt,
    RT.PaymentAmount,
    RT.Memo,
  ],
  ManagerDashboard: [
    RT.Status,
    RT.CreatedAt,
    RT.ReservationTime,
    RT.PatientNameHidden,
    RT.DoctorName,
    RT.MedicalSubject,
  ],
}

export const ReservationListableTable: React.FC<IReservationListableTableProps> = props => {
  const {
    dataLoader,
    showSearch,
    linkToDetail,
    fromHospitalManager = false,
    fromAdmin = false,
    fromPharmacy = false,
    fromHospitalManagerDashboard = false,
    toolbox,
    value,
    onChange,
  } = props
  const { Provider, Context } = useODListableContext<GQLReservation, IReservationListableOption>()
  const { onEvent, token } = useReservationEventHandler(Context)

  const [deviceState, setDeviceState] = React.useState(ERROR_TYPE.NONE)

  React.useEffect(() => {
    if (isDoctor) {
      getDeviceState().then((state: string) => {
        setDeviceState(state)
      })
    }
  }, [])

  const history = useHistory()
  const toolbarStyle = { display: 'flex', justifyContent: 'space-between', marginBottom: 15 }
  const searchBoxStyle = {
    width: '100%',
  }

  const isDoctor = !fromAdmin && !fromHospitalManager && !fromHospitalManagerDashboard

  const getAge = (birthDate: string, sex: string) => {
    // 현재 날짜
    const now = moment()
    const dates = new Date()
    const nowMonth = dates.getMonth()
    const nowDay = dates.getDate()
    // 생일날짜 format
    const birth = moment(birthDate, 'YYMMDD')
    const y = birth.year().toString().substr(2, 3)
    const m = Number(birthDate.slice(2, 4))
    const d = Number(birthDate.slice(4, 6))

    if (sex === '1' || sex === '2') {
      const oldBirth = `19${y}`
      const oldBase = now.year() - Number(oldBirth)

      if (nowMonth - m > 0) {
        // 생일지남
        const nextBase = oldBase + 1
        return nextBase
      }
      if (nowMonth - m === 0) {
        // 생일과 같은달
        return nowDay - d >= 0 ? oldBase + 1 : oldBase
      }
      return oldBase
    }

    if (sex === '3' || sex === '4') {
      const y = birth.year().toString().substr(2, 3)
      const newBirth = `20${y}`
      const newBase = now.year() - Number(newBirth)
      if (nowMonth - m > 0) {
        // 생일지남
        const nextBase = newBase + 1
        return nextBase
      }
      if (nowMonth - m === 0) {
        // 생일과 같은달
        return nowDay - d >= 0 ? newBase + 1 : newBase
      }
      return newBase
    }
  }

  const getNewAge = (birthDate: string) => {
    const now = moment()
    const birth = moment(birthDate, 'YYYY-MM-DD')
    const base = now.year() - birth.year()
    const diffMonth = moment(now.month()).diff(moment(birth.month()), 'month')

    if (diffMonth > 0) {
      // 생일 지남
      const newBase = base + 1
      return newBase
    }

    if (diffMonth === 0) {
      // 생일과 같은달
      return now.date() - birth.date() >= 0 ? base + 1 : base
    }
    return base
  }

  const COLUMN_INFO: { [key: number]: IReservationColumnInfo } = {
    [RT.Status]: {
      headerName: '상태',
      render: (value, context) => (
        <td
          key={value.reservationId + '_status'}
          align="center"
          style={value.patient.leftAt !== null ? { opacity: 0.55 } : { opacity: 1 }}
        >
          <ODEnumRenderer
            enumRendererType={ODEnumRendererStyle.Tag}
            textMap={RESERVATION_STATUS_TEXT}
            context={context}
            value={value.status}
            originalValue={value}
            eventKey="status"
          />
        </td>
      ),
      thStyle: {
        textAlign: 'center',
        width: 100,
        minWidth: 100,
        whiteSpace: 'nowrap',
      },
    },
    [RT.CreatedAt]: {
      headerName: '예약등록일시',
      render: (value, context) => (
        <td key={value.reservationId + '_created_at'} align="center">
          <ODDateTimeRenderer
            format="YYYY.MM.DD (ddd) / HH:mm"
            context={context}
            value={value.createdAt}
            originalValue={value}
            eventKey="createdAt"
          />
        </td>
      ),
      thStyle: {
        width: 170,
        minWidth: 170,
        whiteSpace: 'nowrap',
        textAlign: 'center',
      },
    },
    [RT.ReservationTime]: {
      headerName: '진료일시',
      render: (value, context) => (
        <td key={value.reservationId + '_reservation_time'} align="center">
          <ODDateTimeRenderer
            format="YYYY.MM.DD (ddd) / HH:mm"
            context={context}
            value={value.reservationTime}
            originalValue={value}
            eventKey="reservationTime"
          />
        </td>
      ),
      thStyle: {
        width: 170,
        minWidth: 170,
        whiteSpace: 'nowrap',
        textAlign: 'center',
      },
    },
    [RT.PatientNameHidden]: {
      // for admin
      headerName: '환자명',
      render: (value, context) => (
        <td key={value.reservationId + '_patient_name_hidden'} align="center">
          {!!value.proxyReservationInfo ? (
            <>
              <div className={`enum_tag_proxy`}>
                <span>대리</span>
              </div>
              <ODStringRenderer
                context={context}
                value={value.proxyReservationInfo.name}
                originalValue={value}
                eventKey="patient.name"
              />
            </>
          ) : (
            <>
              <div className={`enum_tag_proxy_empty`}>
                <span>대리</span>
              </div>
              <ODStringRenderer
                context={context}
                value={value.patient.name}
                originalValue={value}
                eventKey="patient.name"
              />
            </>
          )}

          {value.patient.leftAt !== null && (
            <Chip>
              <Triangle></Triangle>
              <ChipText>탈퇴</ChipText>
            </Chip>
          )}
        </td>
      ),
      thStyle: {
        whiteSpace: 'nowrap',
        textAlign: 'center',
      },
    },
    [RT.PatientName]: {
      headerName: '환자명',
      render: (value, context) => {
        return (
          <td key={value.reservationId + '_patient_name'} align="center">
            {!!value.proxyReservationInfo ? (
              <>
                <div className={`enum_tag_proxy`}>
                  <span>대리</span>
                </div>
                <ODStringRenderer
                  context={context}
                  value={value.proxyReservationInfo.name}
                  originalValue={value}
                  eventKey="patient.name"
                />
              </>
            ) : (
              <>
                <div className={`enum_tag_proxy_empty`}>
                  <span>대리</span>
                </div>
                &nbsp;
                <ODStringRenderer
                  context={context}
                  value={value.patient.name}
                  originalValue={value}
                  eventKey="patient.name"
                />
              </>
            )}

            {value.patient.leftAt !== null && (
              <Chip>
                <Triangle></Triangle>
                <ChipText>탈퇴</ChipText>
              </Chip>
            )}
          </td>
        )
      },
      thStyle: {
        whiteSpace: 'nowrap',
        textAlign: 'center',
      },
    },
    [RT.PatientGender]: {
      headerName: '성별',
      render: (value, context) => (
        <td key={value.reservationId + '_patient_gender'} align="center">
          {!!value.proxyReservationInfo ? (
            <ODStringRenderer
              context={context}
              value={value.proxyReservationInfo.sex === '1' || value.proxyReservationInfo.sex === '3' ? '남' : '여'}
              originalValue={value}
              eventKey="patient.gender"
            />
          ) : (
            <ODStringRenderer
              context={context}
              value={value.patient.gender === 'male' ? '남' : '여'}
              originalValue={value}
              eventKey="patient.gender"
            />
          )}
        </td>
      ),
      thStyle: {
        whiteSpace: 'nowrap',
        textAlign: 'center',
      },
    },
    [RT.PatientBirthDate]: {
      headerName: '생년월일(연령)',
      render: (value, context) => {
        const age = !!value.proxyReservationInfo
          ? getAge(value.proxyReservationInfo.birthDate, value.proxyReservationInfo.sex)
          : getNewAge(value.patient.fullBirthDate)
        return (
          <td key={value.reservationId + '_patient_birthDate'} align="center">
            {!!value.proxyReservationInfo ? (
              <ODStringRenderer
                context={context}
                value={`${value.proxyReservationInfo.birthDate}(${age})` ?? ''}
                originalValue={value}
                eventKey="patient.birthDate"
              />
            ) : (
              <ODStringRenderer
                context={context}
                value={`${value.patient.birthDate}(${age})` ?? ''}
                originalValue={value}
                eventKey="patient.birthDate"
              />
            )}
          </td>
        )
      },
      thStyle: {
        whiteSpace: 'nowrap',
        textAlign: 'center',
      },
    },
    [RT.DoctorName]: {
      headerName: '의사명',
      render: (value, context) => (
        <td key={value.reservationId + '_doctor_name'} align="center">
          <ODStringRenderer
            context={context}
            value={value.doctor.name ?? '-'}
            originalValue={value}
            eventKey="doctor.name"
          />
        </td>
      ),
      thStyle: {
        whiteSpace: 'nowrap',
        textAlign: 'center',
      },
    },
    [RT.MedicalSubject]: {
      headerName: '진료과',
      render: (value, context) => (
        <td key={value.reservationId + '_ms'} align="center">
          <ODStringRenderer
            context={context}
            value={value.medicalSubject.name}
            originalValue={value}
            eventKey="medicalSubject.name"
          />
        </td>
      ),
      thStyle: {
        whiteSpace: 'nowrap',
        textAlign: 'center',
      },
    },
    [RT.PayRequestedAt]: {
      headerName: '결제일시',
      render: (value, context) => (
        <td key={value.reservationId + '_payRequestedAt'} align="center">
          <ODDateTimeRenderer
            format="YYYY.MM.DD (ddd) / HH:mm"
            context={context}
            value={value.transactionLogs[0]?.createdAt}
            originalValue={value}
            eventKey="payRequestedAt"
          />
        </td>
      ),
      thStyle: {
        whiteSpace: 'nowrap',
        textAlign: 'center',
      },
    },
    [RT.PayStatus]: {
      headerName: '결제상태',
      render: (value, context) => {
        switch (value.paymentState) {
          case GQLPAYMENT_STATE.SUCCESS:
            return (
              <td key={value.reservationId + '_paymentState'} style={{ color: COLORS.SECOND }} align="center">
                결제 성공
              </td>
            )
          case GQLPAYMENT_STATE.FAILED:
            return (
              <td key={value.reservationId + '_paymentState'} style={{ color: COLORS.DANGER }} align="center">
                결제 실패
              </td>
            )
          default:
            return (
              <td key={value.reservationId + '_paymentState'} align="center">
                청구 전
              </td>
            )
        }
      },
      thStyle: {
        whiteSpace: 'nowrap',
        textAlign: 'center',
      },
    },
    [RT.PaymentAmount]: {
      headerName: '총 결제금액',
      render: (value, context) => (
        <td key={value.reservationId + '_paymentTotal'} align="center">
          {' '}
          {value.paymentTotal ? value.paymentTotal.toLocaleString() : '-'}
        </td>
      ),
      thStyle: {
        width: 92,
        minWidth: 92,
        whiteSpace: 'nowrap',
        textAlign: 'right',
      },
    },
    [RT.Symptom]: {
      headerName: '증상',
      render: (value, context) => (
        <td style={{ maxWidth: 500 }} key={value.reservationId + '_symptom'} align="left">
          <SymptomContent>{value.symptom}</SymptomContent>
        </td>
      ),
      thStyle: {
        maxWidth: 500,
        whiteSpace: 'nowrap',
        textAlign: 'center',
      },
    },
    [RT.Message]: {
      headerName: '메시지',
      render: (value, context) => <td key={value.reservationId + '_message'} align="left"></td>,
      thStyle: { textAlign: 'center', maxWidth: 500, whiteSpace: 'nowrap' },
    },
    [RT.Memo]: {
      headerName: '메모',
      render: (value, context) => (
        <td
          key={value.reservationId + '_memo'}
          style={{ maxWidth: 200, paddingLeft: '17', marginLeft: '0' }}
          align="center"
        >
          <PatientMemoComp reservation={value} iconOnly={true} />
        </td>
      ),
      thStyle: { textAlign: 'center', whiteSpace: 'nowrap' },
    },
    [RT.Room]: {
      headerName: '',
      render: (value, context) => {
        if (value.status === GQLRESERVATION_STATUS.RECEIPTED) {
          const now = moment()
          const receipted = moment(value.patientReceiptAt)
          const diff = now.diff(receipted, 'minute')
          return (
            <td style={{ maxWidth: 500 }} key={value.reservationId + 'room'} align="center">
              <>
                {deviceState === ERROR_TYPE.NONE ? (
                  <Link to={SiteUrls.Doctor.Reservation.Room(value.reservationId)}>
                    <Button
                      type={BP.TYPE.Primary}
                      onClick={(e: any) => {
                        e.stopPropagation()
                      }}
                    >
                      진료실 입장
                    </Button>
                    {!isNaN(diff) && (
                      <WaitingText
                        onClick={(e: any) => {
                          e.stopPropagation()
                        }}
                      >
                        대기 {diff}분 경과
                      </WaitingText>
                    )}
                  </Link>
                ) : (
                  <Button
                    type={BP.TYPE.Primary}
                    onClick={(e: any) => {
                      e.stopPropagation()
                      openPopup()
                    }}
                  >
                    진료실 입장
                  </Button>
                )}
              </>
            </td>
          )
        } else if (value.status === GQLRESERVATION_STATUS.IN_PROGRESS || value.status === GQLRESERVATION_STATUS.READY) {
          return (
            <td style={{ maxWidth: 500 }} key={value.reservationId + 'room'} align="center">
              <>
                {deviceState === ERROR_TYPE.NONE ? (
                  <Link to={SiteUrls.Doctor.Reservation.Room(value.reservationId)}>
                    <Button
                      type={BP.TYPE.Primary}
                      onClick={(e: any) => {
                        e.stopPropagation()
                      }}
                    >
                      진료실 입장
                    </Button>
                  </Link>
                ) : (
                  <Button
                    type={BP.TYPE.Primary}
                    onClick={(e: any) => {
                      e.stopPropagation()
                      openPopup()
                    }}
                  >
                    진료실 입장
                  </Button>
                )}
              </>
            </td>
          )
        } else {
          return <td key={'empty'}></td>
        }
      },
      thStyle: { maxWidth: 500, whiteSpace: 'nowrap' },
    },
    [RT.HospitalName]: {
      headerName: '병원명',
      render: (value, context) => (
        <td key={value.hospitalId + '_hospital'} align="center">
          {value.hospital.name}
        </td>
      ),
      thStyle: { width: 140, minWidth: 140, whiteSpace: 'nowrap', textAlign: 'center' },
    },
  }

  const columns = fromAdmin
    ? COLUMNS_PER_STYLE.Admin
    : fromHospitalManager
    ? COLUMNS_PER_STYLE.Manager
    : fromHospitalManagerDashboard
    ? COLUMNS_PER_STYLE.ManagerDashboard
    : COLUMNS_PER_STYLE.Doctor

  const table = (
    <>
      <ODListablePaginatedTable2
        borderLess={isDoctor && true}
        tableStyle={{ marginBottom: 0 }}
        numColumns={columns.length}
        renderEmpty={() => <span>진료 정보가 없습니다.</span>}
        listableContext={Context}
        renderHeader={() => (
          <tr style={isDoctor ? { background: '#F6F8FA', borderBottom: '1px solid #D1D5DA' } : {}}>
            {columns
              .map(c => {
                return COLUMN_INFO[c]!
              })
              .map((c, index) => (
                <th key={index} style={c.thStyle}>
                  {c.headerName}
                </th>
              ))}
          </tr>
        )}
        renderRow={(value: GQLReservation, context) => {
          if (isDoctor) {
            console.log('is doctor')
            return (
              <StyledTr
                key={value[ReservationPrimaryKey]}
                onClick={() => {
                  history.push(linkToDetail(value.reservationId))
                }}
                style={
                  value.status === GQLRESERVATION_STATUS.RECEIPTED ||
                  value.status === GQLRESERVATION_STATUS.IN_PROGRESS ||
                  value.status === GQLRESERVATION_STATUS.READY ||
                  value.patient.leftAt !== null
                    ? {
                        backgroundColor: value.patient.leftAt !== null ? '#fbfbfc' : '#EAF5FF',
                        color: value.patient.leftAt !== null ? '#a6a8a9' : '#000',
                      }
                    : {}
                }
              >
                {columns.map(c => COLUMN_INFO[c]!).map((c, index) => c.render(value, context))}
              </StyledTr>
            )
          }
          return (
            <tr
              key={value[ReservationPrimaryKey]}
              onClick={() => {
                history.push(linkToDetail(value.reservationId))
              }}
              style={
                value.patient.leftAt !== null
                  ? { backgroundColor: '#fbfbfc', borderBottomWidth: 1, borderBottomColor: '#d1d5d9', color: '#a6a8a9' }
                  : { borderBottomWidth: 1, borderBottomColor: '#d1d5d9' }
              }
            >
              {columns.map(c => COLUMN_INFO[c]!).map((c, index) => c.render(value, context))}
            </tr>
          )
        }}
        onEvent={onEvent}
        eventParentContext={{}}
      />
      <DoctorSSEReceiver context={Context} />
    </>
  )

  if (fromHospitalManagerDashboard) {
    return (
      <Provider
        dataLoader={dataLoader}
        keyExtractor={v => v[ReservationPrimaryKey].toString()}
        pageSize={5}
        refreshToken={token.toString()}
        onDataLoaderError={WebUtils.showError}
        searchOnLoad
      >
        {table}
      </Provider>
    )
  }
  const wrapperStyle = {
    position: 'absolute',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    marginTop: 15,
  }

  const itemActive = {
    borderColor: '#7F8694',
    backgroundColor: '#7F8694',
    color: 'white',
  }

  const itemDeactive = {
    borderColor: '#E6E8ED',
    backgroundColor: '#E6E8ED',
    color: '#202223',
  }

  if (fromAdmin || fromHospitalManager) {
    return (
      <Provider
        dataLoader={dataLoader}
        keyExtractor={v => v[ReservationPrimaryKey].toString()}
        pageSize={10}
        refreshToken={token.toString()}
        onDataLoaderError={WebUtils.showError}
        searchOnLoad
      >
        <TableCardWrapper>
          {showSearch && (
            <OldSearchWrapper>
              <ODListableSearchBox
                listableContext={Context}
                placeholder="환자이름, 증상으로 검색"
                style={searchBoxStyle}
              />
            </OldSearchWrapper>
          )}
          {toolbox && (
            <SearchWrapper>
              <ODListableSearchBox
                listableContext={Context}
                placeholder="환자이름, 증상으로 검색"
                style={searchBoxStyle}
              />
              <ReservationToolBar value={value} onChange={onChange} />
            </SearchWrapper>
          )}
          <EvixCard noPadding>
            {table}
            <ODVSpace h={16} />
            <ODListablePagination
              hideIfSinglePage={false}
              listableContext={Context}
              wrapperStyle={wrapperStyle}
              itemActiveStyle={itemActive}
              itemDeactiveStyle={itemDeactive}
            />
          </EvixCard>
        </TableCardWrapper>
      </Provider>
    )
  }

  return (
    <Provider
      dataLoader={dataLoader}
      keyExtractor={v => v[ReservationPrimaryKey].toString()}
      pageSize={10}
      refreshToken={token.toString()}
      onDataLoaderError={WebUtils.showError}
      searchOnLoad
    >
      <TableCardWrapper>
        {showSearch && (
          <SearchWrapper style={toolbarStyle}>
            <ODListableSearchBox
              listableContext={Context}
              placeholder="환자이름, 증상으로 검색"
              style={searchBoxStyle}
            />
          </SearchWrapper>
        )}
        {toolbox && (
          <SearchWrapper>
            <ODListableSearchBox
              listableContext={Context}
              placeholder="환자이름, 증상으로 검색"
              style={searchBoxStyle}
            />
            <ReservationToolBar value={value} onChange={onChange} />
          </SearchWrapper>
        )}
        <EvixCard noPadding>
          {table}
          <ODListablePagination
            hideIfSinglePage={false}
            listableContext={Context}
            wrapperStyle={wrapperStyle}
            itemActiveStyle={itemActive}
            itemDeactiveStyle={itemDeactive}
          />
        </EvixCard>
      </TableCardWrapper>
    </Provider>
  )
}

const WaitingText = styled.span`
  font-size: 13px;
  color: #c24141;
  text-align: center;
  white-space: nowrap;
`
const OldSearchWrapper = styled.div`
  > div > form > div {
    width: 435px;

    > div {
      border-radius: 8px;

      > div {
        border-radius: 8px;
        background-color: white;
        > span {
          border-radius: 8px;
        }
      }

      > input {
        border-radius: 0px 8px 8px 0px;
      }
    }

    /* @media(max-width: 992px) {
      width: 290px;
    } */
  }
`

const SearchWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  margin-bottom: 12px;

  > div > form > div {
    > div {
      border-radius: 8px;

      > div {
        border-radius: 8px;
        background-color: white;
        > span {
          border-radius: 8px;
        }
      }

      > input {
        border-radius: 0px 8px 8px 0px;
      }
    }

    /* @media(max-width: 992px) {
      width: 290px;
    } */
  }
`
const TableCardWrapper = styled.div`
  //   @media(max-width: 992px) {
  //     width: 290px;
  //   }
`

const SymptomContent = styled.span`
  width: 200px;
  display: inline-block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`

const StyledTr = styled.tr`
  cursor: pointer;
  height: 64px;
  border-bottom: 1px solid #d1d5da;
`

const Chip = styled.span`
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: auto;
  height: 19px;
  background-color: #525558;
  text-align: center;
  border-radius: 0 2px 2px 2px;
  padding: 0 5px;
  margin-left: 8px;
  vertical-align: middle;
`

const Triangle = styled.span`
  position: absolute;
  top: 6px;
  right: 100%;
  margin-top: -6px;
  width: 0;
  height: 0;
  border-width: 0 5px 8px 0;
  border-color: transparent #525558 transparent transparent;
  border-style: solid;
  border-radius: 1px 0 0 1px;
`
const ChipText = styled.span`
  margin-top: -1px;
  color: #fff;
  font-size: 12px;
`
