import { BlockingLoadBox, useWrappedAPI } from '@mdpp/od-react-belt'
import React from 'react'
import { useHistory } from 'react-router-dom'
import { GQLInquiryCreationInput } from '@mdpp/common/lib/@types/server.schema'
import { useCommonAPIs } from '../../common/hooks/useCommonAPIs'
import { InquiryModal } from '../../common/inquiry/modal/InquiryModal'
import { useODDoctorAppContext } from '../context/ODDoctorAppContext'

export const DoctorInquiryModal: React.FC = () => {
  const {
    state: { profile },
  } = useODDoctorAppContext()

  const [loading, setLoading] = React.useState(false)
  const history = useHistory()

  const handleClose = () => {
    history.goBack()
  }

  const { createInquiry } = useCommonAPIs()
  const submitInquiry = useWrappedAPI((input: GQLInquiryCreationInput) => createInquiry(input), loading, setLoading, {
    successMessage: '문의를 등록하였습니다.',
    onSuccess: () => {
      history.goBack()
    },
  })

  return (
    <div>
      <BlockingLoadBox show={loading} />
      {!loading && (
        <InquiryModal
          isOpen
          onClose={() => handleClose()}
          onSubmit={submitInquiry}
          initialEmail={profile?.email ?? ''}
          initialInquirer={profile?.name ?? ''}
          initialPhoneNumber={profile?.phone ?? ''}
        />
      )}
    </div>
  )
}
