import { ODHSpace, ODVSpace } from '@mdpp/od-react-belt'
import React, { useEffect } from 'react'
import styled from 'styled-components'
import { COLORS } from '../../../styles/colors'
import { TableList } from '../../list/TableList'
import { PageContainer, Page, PageTitle, PageHeader, PageContent } from '../../page/Page'
import { InputCheckboxSupportWrapper, SwitchWrap, ToggleLabel, MessageIcon } from '../../../common/style/CommonStyle'
import { EvixCard } from '../../card/EvixCard'
import Toggle from 'react-toggle'
import 'react-toggle/style.css'
import { EvixSelector } from '../../EvixSelector'
import { EvixInput } from '../../EvixInput'
import { WebUtils } from '../../../utils/webUtils'
import { useCommonAPIs } from '../../hooks/useCommonAPIs'
import _ from 'lodash'
import { validator } from '../../../validator'
import { BtnWrap, OvalGray, WarningMessageWrap } from '../../style/SignUpStyle'
import { EvixInlineInputText } from '../../EvixInlineInputText'
import { usePharmacyAPIs } from '../../../pharmacy/context/usePharmacyAPIs'
import { checkMessageWithPrefix } from '../../../utils/registerServerErrorMessages'
import { ErrorCode } from '@mdpp/common'
import { EvixButton, EvixButtonTheme } from '../../EvixButton'
import IconDelete from '../../../assets/common/icon_delete.png'
import IconWarningBlue from '../../../assets/common/warning-blue.svg'
import { AddressSearchModal } from '../../../pharmacy/signup/AddressSearchModal'

interface IPharmacyEditPageProps {
  idEditing: number | null
}

interface IPharmacy {
  hospitalId?: Array<number> | any
  name?: string
  address?: string
  addressDetail?: string
  zipCode?: string
  label?: string
  birthDate?: string
  telephone?: string
  fax?: string
  email?: string
  businessLicenseNumber?: string | null
  businessLicenseFile: any
  bankbookFile: any
  notiMarketing?: boolean
  pharmacistId?: number
  pharmacy?: any
  pharmacyId?: number | null
  unrecoverable?: boolean
  __typename?: null
  webFcmToken?: string | null
  pharmacist?: any
  hospitals: Array<number> | any
  businessLicenseFileId?: number | null
  bankbookFileId?: number | null
  availableDelivery?: boolean
  availableQuick?: boolean
  accountNumber?: string | null
  bankName?: string | null
  accountHolder?: string | null
}

export const PharmacyEditPage: React.FC<IPharmacyEditPageProps> = props => {
  const { idEditing } = props
  const { getPharmacy, updatePharmacy, updatePharmacist } = usePharmacyAPIs()
  const { listHospitalForPharmacyChoose } = useCommonAPIs()
  const [origin, setOrigin] = React.useState<IPharmacy | null>()
  const [mdData, setMdData] = React.useState<IPharmacy | null>()
  const [availableDelivery, setAvailableDelivery] = React.useState(false)
  const [availableQuick, setAvailableQuick] = React.useState(false)
  const [fileName, setFileName] = React.useState('')
  const [data, setData] = React.useState<any[]>([])
  const [hospital, setHospital] = React.useState('')
  const [nextBtn, setNextBtn] = React.useState(true)
  const [file, setFile] = React.useState({})
  const [bankBook, setBankBook] = React.useState('')
  const [bankBookFile, setBankBookFile] = React.useState('')
  const [showAddressSearchModal, setShowAddressSearchModal] = React.useState(false) // 주소 검색 Modal
  const [errBusinessMsg, setBusinessCodeMsg] = React.useState('')
  const [notiMarketing, setNotiMarketing] = React.useState(true)
  const [phone, setPhone] = React.useState('')
  const [hospitalOptions, setHospitalOptions] = React.useState([
    { value: '연계 병원을 선택해주세요.', label: '연계 병원을 선택해주세요.' },
  ])
  const mapping = {
    hospitalId: 'value',
    name: 'label',
  }
  const uniqData = _.uniqBy(data, 'value')

  useEffect(() => {
    getApiPharmacist()
    getOptions()
  }, [])

  useEffect(() => {
    setMdData(_.cloneDeep(origin))
  }, [origin])

  useEffect(() => {
    const isEqualhospitals = _.isEqual(origin?.hospitals, data)

    if (isEqualhospitals === true) {
      return setNextBtn(true)
    }
    setNextBtn(false)
  }, [data])

  useEffect(() => {
    setBusinessCodeMsg('')
  }, [mdData?.businessLicenseNumber])

  useEffect(() => {
    const isEqualData = _.isEqual(origin, mdData)

    if (isEqualData === true) {
      return setNextBtn(true)
    }
    if (!phone || Object.keys(validator.account(phone)).length > 0) {
      return setNextBtn(true)
    }
    if (
      !mdData!.name ||
      !mdData!.address ||
      !mdData!.zipCode ||
      !mdData!.addressDetail ||
      !mdData!.accountHolder ||
      !mdData!.bankName
    ) {
      return setNextBtn(true)
    }
    if (!mdData?.telephone || Object.keys(validator.account(mdData.telephone)).length > 0) {
      return setNextBtn(true)
    }
    if (!mdData?.fax || Object.keys(validator.account(mdData.fax)).length > 0) {
      return setNextBtn(true)
    }
    if (!mdData?.businessLicenseNumber || Object.keys(validator.account(mdData.businessLicenseNumber)).length > 0) {
      return setNextBtn(true)
    }
    if (!mdData?.accountNumber || Object.keys(validator.account(mdData.accountNumber)).length > 0) {
      return setNextBtn(true)
    }
    if (!mdData?.bankbookFile || !mdData.businessLicenseFile) {
      return setNextBtn(true)
    }
    setNextBtn(false)
  }, [mdData, phone])

  const reNameKey = (mapping: object, objName: Array<String | object>) => {
    const reNameArr: Array<String | object> = []
    objName.map((obj: any) => {
      if (obj.hasOwnProperty('name')) {
        obj.label = `${obj.name}/${obj.address}`
        delete obj.name
        delete obj.address
      }
      if (obj.hasOwnProperty('hospitalId')) {
        obj.value = obj.hospitalId
        delete obj.hospitalId
      }
      reNameArr.push(obj)
    })
    return setData(reNameArr)
  }

  const getApiPharmacist = async () => {
    try {
      const r = await getPharmacy({ id: idEditing! })
      const objName = r!.hospitals
      reNameKey(mapping, objName)
      setOrigin(r)
      setNotiMarketing(r!.pharmacist!.pharmacist?.notiMarketing!)
      setPhone(r!.pharmacist!.pharmacist?.cellPhone)
      setAvailableDelivery(r!.availableDelivery!)
      setAvailableQuick(r!!.availableQuick!)
    } catch (e) {
      console.log(e)
    }
  }

  const pharmacistEditSave = async () => {
    const origins: any = origin!.pharmacist.pharmacist
    const mdDatas: any = mdData!.pharmacist.pharmacist

    const postDataPharmacist: any = { pharmacistId: origin?.pharmacist.pharmacistId }
    Object.keys(origins).map((v: string) => {
      if (mdDatas[v]) {
        if (mdDatas.notiMarketing !== origins.notiMarketing) {
          postDataPharmacist['notiMarketing'] = notiMarketing
        }
        if (origins[v] !== mdDatas[v]) {
          postDataPharmacist[v] = mdDatas[v]
        }
        return postDataPharmacist
      }
    })

    try {
      const result = await updatePharmacist(postDataPharmacist)
      getApiPharmacist()
      return WebUtils.showSuccess('기본 정보가 변경되었습니다.')
    } catch (e) {
      console.log(e)
      if (checkMessageWithPrefix(e, ErrorCode.NO_UPDATE_FOUND)) {
        console.log('기본 정보 변경된 내용이 없습니다.')
      }
    }
  }

  const compareArray = (mdDatas: object, origins: object, datas: object) => {
    if (JSON.stringify(mdDatas) == JSON.stringify(datas)) {
      return true
    }
    if (JSON.stringify(origins) == JSON.stringify(datas)) {
      return true
    }
    return false
  }

  const PharmacyEditSave = async () => {
    const mdDatas: any = mdData!
    const origins: any = origin!
    const dataValue: Array<number> = uniqData.map(v => {
      const no: number = v?.value
      return no
    })

    const isKeyExists = (obj: any, key: any) => {
      if (obj[key] == undefined) {
        return false
      } else {
        return true
      }
    }

    const postData: any = { id: idEditing! }
    Object.keys(origins).map((v: string) => {
      if (mdDatas[v]) {
        if (isKeyExists(postData, 'hospitals') === true) {
          delete postData.hospitals
          if (compareArray(mdData?.hospitals, origin?.hospitals, uniqData) === true) {
            delete postData.hospitals
          } else {
            postData['hospitalId'] = dataValue!
          }
        }
        if (mdDatas.availableDelivery !== origins.availableDelivery) {
          postData['availableDelivery'] = availableDelivery
        }
        if (mdDatas.availableQuick !== origins.availableQuick) {
          postData['availableQuick'] = availableQuick
        }
        if (
          origin?.businessLicenseFile?.blfId !== undefined &&
          mdData?.businessLicenseFile?.blfId === origin?.businessLicenseFile?.blfId
        ) {
          delete postData.businessLicenseFile
        }
        if (origin?.bankbookFile?.bbfId !== undefined && mdData?.bankbookFile?.bbfId === origin?.bankbookFile?.bbfId) {
          delete postData.bankbookFile
        }
        if (origins[v] !== mdDatas[v]) {
          postData[v] = mdDatas[v]
        }
        delete postData.pharmacist
        return postData
      }
    })

    try {
      const r = await updatePharmacy(postData)
      getApiPharmacist()
      return WebUtils.showSuccess('약국 정보가 변경 되었습니다.')
    } catch (e) {
      console.log(e)
      if (checkMessageWithPrefix(e, ErrorCode.NO_UPDATE_FOUND)) {
        console.log('약국 정보 변경된 내용이 없습니다.')
      }
      if (checkMessageWithPrefix(e, ErrorCode.BUSINESS_LICENSE_ALREADY_EXIST)) {
        setBusinessCodeMsg('이미 등록된 기관입니다. 가입 정보 확인은 관리자에게 문의해주세요.')
        setNextBtn(true)
      }
    }
  }

  const handleEditSave = async () => {
    await pharmacistEditSave()
    await PharmacyEditSave()
  }

  const handleAddressSelect = (address: string, zonecode: string) => {
    setMdData({
      ...mdData!,
      address: address,
      zipCode: zonecode,
    })
  }

  const fileChangedHandler = async (e: any) => {
    const files = e.target.files
    const fileSize = files[0]?.size
    const fileChangeName = files[0]?.name

    if (files[0]?.name === null) {
      return console.log('파일없음')
    }
    if (fileSize > 50000000) {
      return WebUtils.showError('첨부파일 사이즈는 50MB 이내로 등록 가능합니다.')
    }

    setFileName(fileChangeName ? fileChangeName : mdData?.businessLicenseFile?.name!)
    setFile(files[0])

    fileSaveHandler(files[0])
  }

  const fileBankBookChangedHandler = async (e: any) => {
    const files = e.target.files
    const fileSize = files[0]?.size
    const fileChangeName = files[0]?.name

    if (files[0]?.name === null) {
      return console.log('파일없음')
    }
    if (fileSize > 50000000) {
      return WebUtils.showError('첨부파일 사이즈는 50MB 이내로 등록 가능합니다.')
    }

    setBankBook(fileChangeName ? fileChangeName : mdData?.bankbookFile?.name!)
    setBankBookFile(files[0])

    fileBankBookSaveHandler(files[0])
  }

  const fileSaveHandler = (files: object) => {
    setMdData({
      ...mdData!,
      businessLicenseFile: files ? files : mdData?.businessLicenseFile,
    })
  }

  const fileBankBookSaveHandler = (files: object) => {
    setMdData({
      ...mdData!,
      bankbookFile: files ? files : mdData?.bankbookFile,
    })
  }

  const hospitalList = uniqData.map((v, idx: number) => (
    <>
      <SelectedItem key={idx}>
        <span>{v.label}</span>
        <button type="button" onClick={() => handelDeleteHospital(v.label)}>
          <img src={IconDelete} style={{ width: 12, height: 12 }} />
        </button>
      </SelectedItem>
    </>
  ))

  const handelDeleteHospital = (label: string) => {
    setData(data?.filter(data => data?.label !== label))
  }

  const onChangeHospital = (value: any) => {
    setHospital(value)
    UpDateDateList(value)
  }

  const getOptions = async () => {
    try {
      const res = await listHospitalForPharmacyChoose({})
      const dataList = res.list
      const options = dataList.map((d: any) => ({
        value: d.hospitalId,
        label: `${d.name}/${d.address}`,
      }))
      setHospitalOptions([{ value: '연계 병원을 선택해주세요.', label: '연계 병원을 선택해주세요.' }, ...options])
    } catch (e) {
      console.log(e)
    }
  }

  const UpDateDateList = (value: any) => {
    let names = _.map(data, 'label')
    const result = names.indexOf(value.label)

    if (data.length > 4) {
      return WebUtils.showError('5개 이상 등록할 수 없습니다.')
    }
    if (value.value === '연계 병원을 선택해주세요.') {
      return
    }
    if (result > -1) {
      return WebUtils.showError('이미 등록한 병원입니다.')
    }
    setData(data.concat(value))
  }

  const HandleMarketingCheck = () => {
    setNotiMarketing(!notiMarketing)
    setMdData({
      ...mdData!,
      pharmacist: {
        ...mdData?.pharmacist,
        pharmacist: {
          email: mdData?.pharmacist.pharmacist.email,
          name: mdData?.pharmacist.pharmacist.name,
          birthDate: mdData?.pharmacist.pharmacist.birthDate,
          licenseNumber: mdData?.pharmacist.pharmacist.licenseNumber,
          cellPhone: mdData?.pharmacist.pharmacist.cellPhone,
          notiMarketing: !notiMarketing,
        },
      },
    })
  }

  const HandlePhone = (value: string) => {
    setPhone(value)
    setMdData({
      ...mdData!,
      pharmacist: {
        ...mdData?.pharmacist,
        pharmacist: {
          email: mdData?.pharmacist.pharmacist.email,
          name: mdData?.pharmacist.pharmacist.name,
          birthDate: mdData?.pharmacist.pharmacist.birthDate,
          licenseNumber: mdData?.pharmacist.pharmacist.licenseNumber,
          notiMarketing: mdData?.pharmacist.pharmacist.notiMarketing,
          cellPhone: value,
        },
      },
    })
  }

  const HandleToggle = () => {
    setAvailableDelivery(!availableDelivery)
    setAvailableQuick(!availableQuick)
    setMdData({
      ...mdData!,
      availableDelivery: !availableDelivery,
      availableQuick: !availableQuick,
    })
  }

  return (
    <PageContainer>
      <Page>
        <PageHeader>
          <PageTitle>약국</PageTitle>
        </PageHeader>

        <PageContent>
          <EvixCard>
            <Wrapper>
              <SubTitle>기본 정보</SubTitle>

              <PharmacyAddInfo>
                <PharmacyAddInfoBlock className="block email">
                  <div className="label">이메일 아이디</div>
                  <Label>{origin && origin.pharmacist?.pharmacist.email}</Label>
                </PharmacyAddInfoBlock>

                <PharmacyAddInfoBlock className="block">
                  <div className="label">이름</div>
                  <Label>{origin && origin.pharmacist?.pharmacist.name}</Label>
                </PharmacyAddInfoBlock>

                <PharmacyAddInfoBlock className="block">
                  <div className="label">생년월일</div>
                  <Label>{origin && origin.pharmacist?.pharmacist.birthDate}</Label>
                </PharmacyAddInfoBlock>

                <PharmacyAddInfoBlock className="block">
                  <div className="label">약사면허번호</div>
                  <Label>{origin && origin.pharmacist?.pharmacist.licenseNumber}</Label>
                </PharmacyAddInfoBlock>
              </PharmacyAddInfo>

              <TableList name="cellPhone" label="휴대폰 번호" mandatoryInput>
                <ColumnWrap>
                  <EvixInput
                    style={{ width: 300 }}
                    value={phone}
                    onChange={HandlePhone}
                    validators={validator.account}
                    disabled={false}
                  />
                  <WarningMessageWrap>
                    <ul>
                      <li>
                        <OvalGray></OvalGray> 마케팅 정보 수신 동의여부
                        <InputCheckboxSupportWrapper style={{ marginLeft: 10 }}>
                          <input type="checkbox" id="radio1" onChange={HandleMarketingCheck} checked={notiMarketing} />
                          <label htmlFor="radio1">동의</label>
                        </InputCheckboxSupportWrapper>
                      </li>
                    </ul>
                  </WarningMessageWrap>
                </ColumnWrap>
                <WarningMessageWrap
                  className={Object.keys(validator.account(phone)).length !== 0 ? 'hide' : ''}
                ></WarningMessageWrap>
              </TableList>

              <ODVSpace h={34} />

              <SubTitle>약국 정보</SubTitle>
              <ColumnWrap>
                <TableList name="name" label="약국 이름" mandatoryInput>
                  <EvixInlineInputText
                    style={{ width: 300 }}
                    value={mdData! && mdData?.name}
                    onChange={value => {
                      setMdData({
                        ...mdData!,
                        name: value,
                      })
                    }}
                    disabled={false}
                  />
                  <WarningMessageWrap></WarningMessageWrap>
                </TableList>
                <div></div>
              </ColumnWrap>

              <TableList name="address" label="주소" mandatoryInput>
                <AddressWrap onClick={() => setShowAddressSearchModal(true)}>
                  <EvixInlineInputText
                    style={{ width: '100%' }}
                    value={mdData! && mdData?.address}
                    onChange={value => {
                      setMdData({
                        ...mdData!,
                        address: value,
                      })
                    }}
                    disabled={true}
                  />

                  <EvixInlineInputText
                    style={{ width: 150 }}
                    value={mdData! && mdData?.zipCode}
                    onChange={value => {
                      setMdData({
                        ...mdData!,
                        zipCode: value,
                      })
                    }}
                    disabled={true}
                  />
                  <EvixButton
                    theme={EvixButtonTheme.H48WhiteInline}
                    onClick={() => setShowAddressSearchModal(true)}
                    disabled={false}
                  >
                    검색
                  </EvixButton>
                </AddressWrap>
                <ODVSpace h={10} />
                <EvixInlineInputText
                  style={{ width: '100%' }}
                  value={mdData! && mdData?.addressDetail}
                  onChange={value => {
                    setMdData({
                      ...mdData!,
                      addressDetail: value,
                    })
                  }}
                  disabled={false}
                />
                <WarningMessageWrap></WarningMessageWrap>
              </TableList>

              <ColumnWrap>
                <TableList name="telephone" label="연락처" mandatoryInput>
                  <EvixInput
                    style={{ width: '100%' }}
                    validators={validator.account}
                    value={mdData! && mdData?.telephone}
                    onChange={value => {
                      setMdData({
                        ...mdData!,
                        telephone: value,
                      })
                    }}
                    disabled={false}
                  />
                  <WarningMessageWrap
                    className={Object.keys(validator.account(mdData?.telephone!)).length !== 0 ? 'hide' : ''}
                  ></WarningMessageWrap>
                </TableList>
                <TableList name="fax" label="FAX" mandatoryInput>
                  <EvixInput
                    style={{ width: '100%' }}
                    validators={validator.account}
                    value={mdData! && mdData?.fax}
                    onChange={value => {
                      setMdData({
                        ...mdData!,
                        fax: value,
                      })
                    }}
                    disabled={false}
                  />
                  <WarningMessageWrap
                    className={Object.keys(validator.account(mdData?.fax!)).length !== 0 ? 'hide' : ''}
                  ></WarningMessageWrap>
                </TableList>
              </ColumnWrap>
              <ColumnWrap>
                <TableList name="businessLicenseNumber" label="사업자등록번호" labelMDSize={12} mandatoryInput>
                  <EvixInput
                    style={{ width: '100%' }}
                    value={mdData! && mdData!.businessLicenseNumber!}
                    onChange={value => {
                      setMdData({
                        ...mdData!,
                        businessLicenseNumber: value,
                      })
                    }}
                    validators={validator.account}
                    disabled={false}
                  />
                  <WarningMessageWrap
                    className={
                      Object.keys(validator.account(mdData?.businessLicenseNumber!)).length !== 0 ? 'hide' : ''
                    }
                  >
                    <span className="error" style={{ fontSize: 13, fontWeight: 500, color: '#ff5353' }}>
                      {errBusinessMsg}
                    </span>
                  </WarningMessageWrap>
                </TableList>
                <TableList name="fileName" label="사업자등록증" labelMDSize={12} mandatoryInput>
                  <FileWrap>
                    <FileInput>
                      {mdData && mdData.businessLicenseFile?.fileName === '' ? (
                        <NoFileName>
                          {/* 사업자등록증을 첨부해 주세요 */}
                          PDF, JPG, PNG 파일만 첨부 가능합니다.
                        </NoFileName>
                      ) : (
                        <>
                          <button onClick={() => window.open(mdData!.businessLicenseFile?.link)}>
                            {mdData && mdData.businessLicenseFile?.fileName
                              ? mdData.businessLicenseFile?.fileName
                              : fileName
                              ? fileName
                              : origin?.businessLicenseFile?.fileName}
                          </button>
                        </>
                      )}
                    </FileInput>
                    <FileLabel htmlFor="input-file">
                      {' '}
                      사업자등록증 첨부
                      <input
                        name="businessLicenseFile"
                        type="file"
                        id="input-file"
                        style={{ display: 'none' }}
                        accept=".pdf, image/png, image/jpeg, image/jpg"
                        onChange={e => fileChangedHandler(e)}
                      />
                    </FileLabel>
                  </FileWrap>
                  <WarningMessageWrap>
                    <ul>
                      {/* <li>
                        <OvalGray></OvalGray> PDF, JPG, PNG 파일만 첨부 가능합니다. */}
                      <li className="align-center">
                        <MessageIcon src={IconWarningBlue} alt="warning icon" />
                        비대면 진료 정산을 위해 필요합니다.
                      </li>
                    </ul>
                  </WarningMessageWrap>
                </TableList>
              </ColumnWrap>

              <ColumnWrap>
                <TableList name="bankBook" label="통장사본" labelMDSize={12} mandatoryInput>
                  <FileWrap>
                    <FileInput>
                      {mdData && mdData.bankbookFile === null ? (
                        <NoFileName>
                          {/* 사업자등록증을 첨부해 주세요 */}
                          PDF, JPG, PNG 파일만 첨부 가능합니다.
                        </NoFileName>
                      ) : (
                        <>
                          <button onClick={() => window.open(mdData!.bankbookFile?.link)}>
                            {mdData && mdData.bankbookFile?.fileName
                              ? mdData.bankbookFile?.fileName
                              : bankBook
                              ? bankBook
                              : origin?.bankbookFile?.fileName}
                          </button>
                        </>
                      )}
                    </FileInput>
                    <FileLabel htmlFor="input-bank-file">
                      {' '}
                      통장사본 첨부
                      <input
                        name="bankBookFile"
                        type="file"
                        id="input-bank-file"
                        style={{ display: 'none' }}
                        accept=".pdf, image/png, image/jpeg, image/jpg"
                        onChange={e => fileBankBookChangedHandler(e)}
                      />
                    </FileLabel>
                  </FileWrap>
                  <WarningMessageWrap>
                    <ul>
                      {/* <li>
                        <OvalGray></OvalGray> PDF, JPG, PNG 파일만 첨부 가능합니다. */}
                      <li className="align-center">
                        <MessageIcon src={IconWarningBlue} alt="warning icon" />
                        조제비 정산을 위해 필요합니다.
                      </li>
                    </ul>
                  </WarningMessageWrap>
                </TableList>
                <div></div>
              </ColumnWrap>
              <ODVSpace h={20} />
              <ColumnWrap>
                <TableList
                  name="availableDelivery"
                  label="의약품 택배 / 퀵 배송 배송가능 여부"
                  labelMDSize={12}
                  mandatoryInput
                >
                  <AddressWrap>
                    <SwitchWrap style={{ display: 'inline-flex' }}>
                      <Toggle checked={availableDelivery} icons={false} onChange={HandleToggle} />
                      <ToggleLabel>
                        <span>불가능</span>
                        <span>가능</span>
                      </ToggleLabel>
                    </SwitchWrap>
                    <ODHSpace w={50} />
                    {availableDelivery === true && (
                      <InputCheckboxSupportWrapper>
                        <input
                          type="checkbox"
                          id="cb1"
                          checked={availableQuick}
                          disabled={true}
                          // onChange={value => {
                          //   setMdData({
                          //     ...origin,
                          //     availableQuick: true,
                          //   })
                          // }}
                        />
                        <label htmlFor="cb1">퀵 배송</label>
                      </InputCheckboxSupportWrapper>
                      // <TableList name="availableQuick" label="" labelMDSize={12}>
                      //   <input
                      //     type="radio"
                      //     id="radio1"
                      //     checked={mdData && mdData.availableQuick}
                      //     onChange={value => {
                      //       setOrigin({
                      //         ...mdData,
                      //         availableQuick: true,
                      //       })
                      //     }}
                      //   />
                      //   <label htmlFor="radio1" style={{ marginBottom: 0 }}>
                      //     퀵배송
                      //   </label>
                      //   <ODHSpace w={10} />
                      //   <input
                      //     type="radio"
                      //     id="radio2"
                      //     checked={mdData && !mdData.availableQuick}
                      //     onChange={value => {
                      //       setOrigin({
                      //         ...mdData,
                      //         availableQuick: false,
                      //       })
                      //     }}
                      //   />
                      //   <label htmlFor="radio2" style={{ marginBottom: 0 }}>
                      //     택배 배송
                      //   </label>
                      // </TableList>
                    )}
                  </AddressWrap>
                </TableList>
              </ColumnWrap>
              <ODVSpace h={34} />
              <ColumnWrap>
                <TableList name="hospital" label="연계 병원" labelMDSize={12} captionLabel="최대 5곳 선택 가능" caption>
                  <EvixSelector
                    style={{ width: '100%' }}
                    value={'연계 병원을 선택해주세요.'}
                    placeholder={'연계 병원을 선택해주세요.'}
                    options={hospitalOptions}
                    onChange={value => onChangeHospital(value)}
                  />
                  <SelectedItemWrap>{hospitalList}</SelectedItemWrap>
                </TableList>
                <div></div>
              </ColumnWrap>

              <ODVSpace h={34} />

              <SubTitle>계좌 정보</SubTitle>
              <ColumnWrap>
                <TableList name="bankName" label="은행명" mandatoryInput>
                  <EvixInput
                    style={{ width: '100%' }}
                    value={mdData! && mdData!.bankName!}
                    onChange={value => {
                      setMdData({
                        ...mdData!,
                        bankName: value,
                      })
                    }}
                    disabled={false}
                  />
                </TableList>
                <TableList name="accountHolder" label="예금주" mandatoryInput>
                  <EvixInput
                    style={{ width: '100%' }}
                    value={mdData! && mdData!.accountHolder!}
                    onChange={value => {
                      setMdData({
                        ...mdData!,
                        accountHolder: value,
                      })
                    }}
                    disabled={false}
                  />
                </TableList>
              </ColumnWrap>
              <ColumnWrap>
                <TableList name="accountNumber" label="계좌번호" mandatoryInput>
                  <EvixInput
                    style={{ width: '100%' }}
                    value={mdData! && mdData!.accountNumber!}
                    onChange={value => {
                      setMdData({
                        ...mdData!,
                        accountNumber: value,
                      })
                    }}
                    validators={validator.account}
                    disabled={false}
                  />
                  <WarningMessageWrap
                    className={Object.keys(validator.account(mdData?.accountNumber!)).length !== 0 ? 'hide' : ''}
                  ></WarningMessageWrap>
                </TableList>
                <div></div>
              </ColumnWrap>
              <BtnWrap>
                <EvixButton theme={EvixButtonTheme.H48Black} onClick={() => handleEditSave()} disabled={nextBtn}>
                  저장
                </EvixButton>
              </BtnWrap>
            </Wrapper>
          </EvixCard>
        </PageContent>
      </Page>

      {showAddressSearchModal && (
        <AddressSearchModal
          style={{ width: 880, height: 764, maxWidth: 880 }}
          onSelected={handleAddressSelect}
          onCancelPress={() => setShowAddressSearchModal(false)}
        />
      )}
    </PageContainer>
  )
}

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`

const InfoMessageWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-top: 10px;
`

const InfoMessage = styled.div`
  font-size: 13px;
  font-weight: 500;
  line-height: 1.17;
  color: #ababab;
`

const InfoIcon = styled.img`
  width: 14px;
  height: 14px;
  margin-right: 4px;
`

const SubTitle = styled.div`
  font-size: 15px;
  font-weight: 700;
  padding-bottom: 10px;
`

const PharmacyAddInfoBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;

  &.email {
    width: 218px;

    input {
      width: 100%;
      border-color: #f4f6f8 !important;
      background-color: #f4f6f8 !important;
      outline: none !important;
      padding-left: 0 !important;
      padding-right: 0 !important;

      &:focus {
        border-color: #f4f6f8 !important;
        outline: none !important;
      }
    }
  }

  .label {
    font-size: 12px;
    font-weight: 500;
    color: #909ca7;
  }

  .label ~ div,
  .label ~ div > div {
    border-radius: 1px !important;
  }

  .value {
    font-size: 15px;
    font-weight: 500;
    color: #424242;
    white-space: nowrap;
  }

  input {
    width: 120px;
    height: 35px;
    font-size: 15px;
    font-weight: 500;
    color: #424242;
    display: inline-block !important;
    border-radius: 1px !important;
    background-color: #fff !important;
  }
`

const ColumnWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 32px;

  > div {
    width: 50%;
  }
`

const AddressWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
`

const RowWrap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
`

const PharmacyAddInfo = styled.p`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-radius: 0 !important;
  /* width: 680px; */
  padding: 18px 50px 18px 30px;
  background-color: #f4f6f8;
  gap: 50px;
`

const FileLabel = styled.label`
  font-size: 13px;
  font-weight: 500;
  font-style: normal;
  color: ${COLORS.GRAY42};
  margin-bottom: 0;
  padding: 12px;
  height: 44px;
  width: 130px;
  line-height: 18px;
  border-radius: 4px;
  border: 1px solid ${COLORS.GRAYAB2};
  background-color: ${COLORS.WHITE};
  text-align: center;
  cursor: pointer;
`

const FileWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  gap: 10px;

  .od-input-wrapper {
    border: none;

    .od-input {
      &::placeholder {
        color: ${COLORS.GRAYAB};
        font-size: 13px;
      }
    }
  }

  [class*='SignUpStyle__WarningMessageWrap'] {
    display: none;
  }
`

const FileInput = styled.label`
  font-size: 13px;
  font-weight: 500;
  font-style: normal;
  color: ${COLORS.GRAY42};
  margin-bottom: 0;
  padding: 12px;
  height: 44px;
  width: calc(100% - 130px);
  line-height: 18px;
  border-radius: 4px;
  border: 1px solid #dfe0e2;
  background-color: #eeeff1;
  text-align: left;
  cursor: pointer;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  span {
    color: #acb4bc;
  }
`

const NoFileName = styled.div`
  flex: 1;
  font-size: 13px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 0;
  color: ${COLORS.GRAYAB};
`

const Label = styled.label`
  font-size: 15px;
  font-weight: 500;
  font-style: normal;
  color: ${COLORS.GRAY42};
  margin-bottom: 0;
  padding: 12px 0;
  height: 44px;
  line-height: 18px;
  border-radius: 4px;
  border: 0;
  background-color: transparent;
  text-align: left;
  cursor: pointer;
`

const SelectedItemWrap = styled.div`
  width: 100%;
  margin-top: 10px;
`

const SelectedItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0;
  padding: 0 14px;
  height: 44px;
  border-radius: 4px;
  border: 1px solid #dfe0e2;
  background-color: #fff;
  text-align: left;
  cursor: pointer;
  margin-bottom: 5px;

  > span {
    font-size: 13px;
    font-weight: 500;
    font-style: normal;
    color: #7c818b;
    line-height: 18px;
  }

  > button {
  }
`
