import _ from 'lodash'

export const COLORS = {
  GRAY100: '#f0f3f5',
  GRAY200: '#EEEEEE',
  GRAY300: '#c8ced3',
  GRAY400: '#acb4bc',
  GRAYAB: '#ababab',
  GRAYAB2: '#abaeb3',
  GRAY42: '#424242',
  GRAY500: '#8f9ba6',
  GRAY600: '#939191',
  GRAY700: '#5c6873',
  GRAY7A: '#7a7a7a',
  GRAY800: '#2f353a',
  GRAY900: '#232222',
  GRAY2D: '#2d3441',
  GRAYD5: '#d5d5d5',
  GRAYD8: '#d8d8d8',
  GRAYDF: '#dfe0e2',
  GRAYC9: '#c9d0d4',
  GRAYCB: '#cbcbcb',
  GRAYCC: '#cccdce',
  GRAYE0: '#e0e1e4',
  GRAYEE: '#eeeff1',
  GRAYEB: '#ebebeb',
  BLACK: '#000000',
  WHITE: '#ffffff',
  ACCENT: '#b5944b',
  Primary: '#29b6ca',
  PRIMARY: '#29b6ca',
  PRIMARY10: '#e9f7f9',
  PRIMARY30: '#bee9ef',
  RED10: '#fee9e9',
  RED30: '#fcd2d2',
  DimmedText: '#393939',
  DefaultIconColor: '',
  // SIDEBAR_BG: '#000000',
  SIDEBAR_BG: '#F9FAFB',
  HEADER_BG: '#ffffff',
  SECOND: '#20a8d8',
  DANGER: '#fff5353',

  ////////// Medi PangPang Own Colours

  // 블루로 메인 컬러 변경 후,
  blue100: '#2f7dff',
  blue200: '#1a7fe4',
  blueD5: '#d5e7fa',

  // 레드
  red100: '#ff5353',
  redFE: '#ffe0e0',

  //Primary
  mpPrimary500: '#0085FF',  //'#6456FF',
  mpPrimary400: '#817EFF',
  mpPrimary300: '#8A88FF',
  mpPrimary200: '#B8B7FF',
  mpPrimary100: '#D4D3FF',

  //Secondary
  mpSecondary500: '#2B5EFE',
  mpSecondary400: '#1a7fe4',
  mpSecondary300: '#96AFFF',
  mpSecondary200: '#BCCCFF',
  mpSecondary100: '#D5DFFF',

  //Neutral
  mpNeutral700: '#A5A9B0',
  mpNeutral600: '#B3B7C0',
  mpNeutral500: '#BFC3CB',
  mpNeutral400: '#D8DCE2',
  mpNeutral300: '#E6E9EF',
  mpNeutral200: '#EDEFF4',
  mpNeutral100: '#F1F4FB',

  //Gradient
  mpGradientForButton: 'linear-gradient(0deg, rgba(100,86,255,1) 0%, rgba(129,126,255,1) 100%)',
  mpGradientForSymbol: 'linear-gradient(0deg, rgba(138,136,255,1) 0%, rgba(184,183,255,1) 100%)',
  mpGradientForBackground: 'linear-gradient(0deg, rgba(214,225,255,1) 0%, rgba(122,100,255,1) 100%)',
}

export const alpha = (color: string, alphaFloat: number) =>
  `${color}${_.padStart(Math.round(alphaFloat * 255).toString(16), 2, '0')}`
