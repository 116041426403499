import { BlockingLoadBox, ODEntity } from '@mdpp/od-react-belt'
import gql from 'graphql-tag'
import React from 'react'
import { CardBody } from 'reactstrap'
import { GQLDoctor, GQLDoctorCreationInput, GQLDoctorUpdateInput } from '@mdpp/common/lib/@types/server.schema'
import { DoctorProfileEdit } from '../../common/doctor/detail/DoctorProfileEdit'
import { GQL_GET_DOCTOR_PROFILE, GQL_UPDATE_DOCTOR_PROFILE } from '../../common/gqls_common'
import { DoctorPrimaryKey, ICTDoctor } from '../../common/types/DoctorCommon'
import { useODEntityAPI } from '../../utils/odGQLEntityLoader'
import { useODDoctorAppContext } from '../context/ODDoctorAppContext'
import { EvixCard } from '../../common/card/EvixCard'
import styled from 'styled-components'

interface IDoctorProfilePageProps {
  fromManager: boolean
  fromAdmin: boolean
  profile?: GQLDoctor | null
}

const DEFAULT_DOCTOR_DATA: Partial<ICTDoctor> = {
  doctorId: 0,
  name: ``,
}

export const DoctorProfilePage: React.FC<IDoctorProfilePageProps> = props => {
  const { fromManager, fromAdmin } = props
  const {
    state: { profile },
  } = useODDoctorAppContext()

  const apis = useODEntityAPI<GQLDoctor, ICTDoctor, GQLDoctorCreationInput, GQLDoctorUpdateInput>({
    updateGQL: gql(GQL_UPDATE_DOCTOR_PROFILE),
    readGQL: gql(GQL_GET_DOCTOR_PROFILE),
    primaryKeyName: DoctorPrimaryKey,
  })

  if (!profile) {
    return <BlockingLoadBox show />
  }

  return (
    <EvixCard>
      <Wrapper>
        <ODEntity
          resourceId={profile.doctorId}
          api={apis}
          saveButtonName="저장"
          titleCreation="추가"
          createSuccessTitle="성공"
          createSuccessMessage="추가하였습니다."
          titleUpdate="수정"
          updateSuccessTitle="성공"
          updateSuccessMessage="수정하였습니다."
          urlAfterDelete={(item: ICTDoctor) => `/admin/doctor`}
          deleteSuccessTitle="성공"
          deleteSuccessMessage="삭제하였습니다."
          defaultCreateClientData={DEFAULT_DOCTOR_DATA}
          urlAfterCreation={() => ``}
          urlAfterUpdate={() => null}
          noCardWrap
          renderLoading={() => <></>}
        >
          <DoctorProfileEdit fromManager={fromManager} fromAdmin={fromAdmin} profile={profile} />
        </ODEntity>
      </Wrapper>
    </EvixCard>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 10px;
`
