import { BlockingLoadBox, ODFlexContentsContainer } from '@mdpp/od-react-belt'
import React from 'react'
import { Redirect } from 'react-router-dom'
import { SiteUrls } from '../../SiteUrls'
import { LOGIN_STATE, usePharmacyAppContext } from '../context/PharmacyAppContext'

interface IPharmaAuthorizedProps {}

export const PharmacyAuthorized: React.FC<IPharmaAuthorizedProps> = props => {
  const {
    state: { loginState },
  } = usePharmacyAppContext()

  switch (loginState) {
    case LOGIN_STATE.Checking:
    case LOGIN_STATE.LoggingIn:
      return (
        <ODFlexContentsContainer>
          <BlockingLoadBox show message="로그인 정보 확인 중입니다.." />
        </ODFlexContentsContainer>
      )
    case LOGIN_STATE.LoggedIn:
      return <>{props.children}</>
    case LOGIN_STATE.LoggedOut:
      return <Redirect to={SiteUrls.Pharmacy.Login} />
    case LOGIN_STATE.LoggingOut:
      return (
        <ODFlexContentsContainer>
          <BlockingLoadBox show message="로그아웃 중입니다.." />
        </ODFlexContentsContainer>
      )
  }
}
