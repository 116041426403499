import { ODHSpace, ODIcon, ODIcons, ODVSpace } from '@mdpp/od-react-belt'
import React from 'react'
import styled from 'styled-components'
import { GQLDiagName } from '@mdpp/common/lib/@types/server.schema'
import deleteLine from '../../../../assets/icDeleteLine.png'

export interface DiagNameWithOption {
  diagName: GQLDiagName
  isFinal: boolean
}

interface IDiagnosisListProps {
  diagNames: DiagNameWithOption[]
  toggleDiagnosisFinal?: (code: string) => void
  onRemove?: (code: string) => void
  onChangeOrder?: (code: string, isUp: boolean) => void
  editable?: boolean
}

export const DiagnosisList: React.FC<IDiagnosisListProps> = props => {
  const { diagNames, toggleDiagnosisFinal, onRemove, onChangeOrder, editable = true } = props
  return (
    <>
      <DiagnosisListBox>
        <DiagnosisType>주진단</DiagnosisType>
        <ODVSpace h={8} />
        {!diagNames[0] && <NoContentText>{editable ? '진단명을 추가하세요.' : '-'}</NoContentText>}
        {diagNames[0] && (
          <DiagnosisBox>

            <Diagnosis
              final={diagNames[0].isFinal}
            >
              <DiagnosisNameBlock style={{ flex: 1 }}>
                {editable && <ODHSpace w={8} />}
                <DiagnosisCode>
                  {diagNames[0].diagName.code}
                </DiagnosisCode>
                {' ' + diagNames[0].diagName.koDesc}
              </DiagnosisNameBlock>
              {editable &&
              <>
                <CloseButton onClick={() => onRemove?.(diagNames[0].diagName.code)}>
                  <DeleteImage src={deleteLine} />
                </CloseButton>
              </>
              }
            </Diagnosis>
            {editable && (
              <>
                <ODHSpace w={26} />
                <ODIcon
                  icon={ODIcons.CoreArrowCircleBottom}
                  onClick={() => onChangeOrder?.(diagNames[0].diagName.code, false)}
                />
                <ODHSpace w={26} />
              </>
            )}
          </DiagnosisBox>
        )}
        <ODVSpace h={8} />
      </DiagnosisListBox>
      <ODVSpace h={16} />
      <DiagnosisListBox>
        <DiagnosisType>부진단</DiagnosisType>
        <ODVSpace h={8} />
        {!diagNames[1] && <NoContentText>{editable ? '진단명을 추가하세요.' : '-'}</NoContentText>}
        {diagNames.length > 1 &&
          diagNames
            .filter((item, index) => index !== 0)
            .map(item => {
              return (
                <DiagnosisBox key={item.diagName.code}>
                  <Diagnosis final={item.isFinal}>
                    <DiagnosisNameBlock style={{ flex: 1 }}>
                      {editable && <ODHSpace w={8} />}
                      <DiagnosisCode>
                        {item.diagName.code}
                      </DiagnosisCode>
                      {' ' + item.diagName.koDesc}
                    </DiagnosisNameBlock>
                    {editable &&
                    <>
                      <CloseButton onClick={() => onRemove?.(item.diagName.code)}>
                        <DeleteImage src={deleteLine} />
                      </CloseButton>
                    </>
                    }
                  </Diagnosis>
                  {editable &&
                    <>
                      <ODHSpace w={26} />
                      <ODIcon
                        icon={ODIcons.CoreArrowCircleBottom}
                        onClick={() => onChangeOrder?.(item.diagName.code, false)}
                      />
                      <ODHSpace w={6} />
                      <ODIcon
                        icon={ODIcons.CoreArrowCircleTop}
                        onClick={() => onChangeOrder?.(item.diagName.code, true)}
                      />
                    </>
                  }
                </DiagnosisBox>
              )
            })}
        <ODVSpace h={10} />
      </DiagnosisListBox>
    </>
  )
}

const DiagnosisListBox = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid var(--gray200);
`
const DiagnosisType = styled.span`
  font-size: 13px;
  color: var(--gray600);
`
const NoContentText = styled.p`
  font-size: 14px;
  line-height: 1.43;
  color: var(--gray400);
  margin: 0;
`
const DiagnosisBox = styled.div`
  display: flex;
  align-items: center;

  & > i {
    width: 20px;
    height: 20px;
    font-size: 20px;
    color: var(--gray400);
  }

  & + & {
    margin-top: 10px;
  }
`

const Diagnosis = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 24px;
  border-radius: 4px !important;
  background-color: ${(p: { final: boolean }) => (p.final ? '#baead3' : 'var(--green)')};
  padding: 8px 12px;
  font-size: 14px;
  font-weight: 400;
  flex: 1;
  word-break: break-all;
  justify-content: space-between;

  & i {
    height: 10px;
    font-size: 10px;
  }
`
const CloseButton = styled.button`
    border-radius: 61px;
    width: 24px;
    color: #9e9ea7;
    text-align: center;
    font-weight: 900;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    
`
const DeleteImage = styled.img`
    width: 10px;
    height: 10px;
`

const DiagnosisNameBlock = styled.div`
    flex: 1;
`
const DiagnosisCode = styled.span`
    font-weight: 500;
`