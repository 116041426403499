import {
  ODButton,
  ODButtonSize,
  ODButtonTheme,
  ODListableOption,
  ODListablePaginatedTable2,
  ODListablePagination,
  ODListableSearchBox,
  useODListableContext,
} from '@mdpp/od-react-belt'
import React from 'react'
import { Link } from 'react-router-dom'
import { GQLPharmacy } from '@mdpp/common/lib/@types/server.schema'
import { SiteUrls } from '../../../SiteUrls'
import { WebUtils } from '../../../utils/webUtils'
import { IPharmacyListableOption, PharmacyPrimaryKey } from '../PharmacyCommon'
import { usePharmacyListDataLoader } from './PharmacyListDataLoader'
import styled from 'styled-components/macro'
import { EvixCard } from '../../card/EvixCard'

interface IPharmacyListableTableProps {}

export const PharmacyListableTable: React.FC<IPharmacyListableTableProps> = props => {
  const { Provider, Context } = useODListableContext<GQLPharmacy, IPharmacyListableOption>()
  const dataLoader = usePharmacyListDataLoader()

  const toolbarStyle = { display: 'flex', justifyContent: 'space-between', marginBottom: 15 }
  const searchBoxStyle = { flex: 1 }
  const wrapperStyle = { display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 24 }

  return (
    <Provider
      dataLoader={dataLoader}
      keyExtractor={v => v.pharmacyId.toString()}
      pageSize={10}
      // refreshToken={token.toString()}
      onDataLoaderError={WebUtils.showError}
      searchOnLoad
    >
      <SearchWrapper style={toolbarStyle}>
        <ODListableSearchBox
          listableContext={Context}
          placeholder="약국 이름, 주소, 연락처, FAX, 병원으로 검색"
          style={searchBoxStyle}
        />
        {/*<Link to={SiteUrls.Admin.Pharmacy.Create} style={{ textDecoration: 'none' }}>*/}
        {/*  <ODButton theme={ODButtonTheme.Primary} size={ODButtonSize.Small} rounded style={{ minWidth: 100 }}>*/}
        {/*    약국 추가*/}
        {/*  </ODButton>*/}
        {/*</Link>*/}
      </SearchWrapper>

      <EvixCard noPadding>
        <ODListablePaginatedTable2
          numColumns={6}
          listableContext={Context}
          renderHeader={() => (
            <tr>
              <th style={{ width: 200, whiteSpace: 'nowrap', textAlign: 'center' }}>약국이름</th>
              <th style={{ whiteSpace: 'nowrap', textAlign: 'center' }}>주소</th>
              <th style={{ width: 70, whiteSpace: 'nowrap', textAlign: 'center' }}>택배여부</th>
              <th style={{ width: 120, whiteSpace: 'nowrap', textAlign: 'center' }}>연락처</th>
              <th style={{ width: 120, whiteSpace: 'nowrap', textAlign: 'center' }}>FAX</th>
              <th style={{ width: 200, whiteSpace: 'nowrap', textAlign: 'center' }}>병원</th>
            </tr>
          )}
          renderRow={(value: GQLPharmacy, context) => (
            <tr key={value[PharmacyPrimaryKey]} style={{ borderBottom: '1px solid #D1D5DA' }}>
              <td align="left">
                <Link to={SiteUrls.Admin.Pharmacy.Edit(value.pharmacyId)}>{value.name}</Link>
              </td>
              <td>{value.address}</td>
              <td align="center" style={{ whiteSpace: 'nowrap' }}>
                {value.availableDelivery ? '가능' : '불가능'}
              </td>
              <td align="center" style={{ whiteSpace: 'nowrap' }}>
                {value.telephone}
              </td>
              <td align="center" style={{ whiteSpace: 'nowrap' }}>
                {value.fax}
              </td>
              <td align="left">{value.hospitals.map(h => h.name).join(',')}</td>
            </tr>
          )}
          eventParentContext={{}}
        />
      </EvixCard>

      <ODListablePagination hideIfSinglePage={false} listableContext={Context} wrapperStyle={wrapperStyle} />
    </Provider>
  )
}

const SearchWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-bottom: 24px;
  > div > form > div {
    > div {
      border-radius: 8px;

      > div {
        border-radius: 8px;
        background-color: white;
        > span {
          border-radius: 8px;
        }
      }

      > input {
        border-radius: 0px 8px 8px 0px;
      }
    }

    @media (max-width: 992px) {
      width: 290px;
    }
  }
`
