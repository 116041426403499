import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { AdminRoot } from './admin/AdminRoot'
import { AdminAppProvider } from './admin/context/AdminAppContext'
import { ODDoctorAppProvider } from './doctor/context/ODDoctorAppContext'
import { DoctorRoot } from './doctor/DoctorRoot'
import { LandingRoot } from './landing/LandingRoot'
import { ForgotPasswordPage, ForgotPasswordRoute } from './login/ForgotPasswordPage'
import { ForgotIDPage, ForgotIDRoute } from './login/ForgotIDPage'
// import { ForgotPasswordEmailSentPage } from './login/FormatPasswordEmailSentPage'
import { PasswordResetConfirmedPage } from './login/PasswordResetConfirmedPage'
import { PrivacyPage } from './login/PrivacyPage'
import { PrivacyConsentPage } from './login/PrivacyConsentPage'
import { TermsPage } from './login/TermsPage'
import { ManagerAppProvider } from './manager/context/ManagerAppContext'
import { ManagerRoot } from './manager/ManagerRoot'
import { SiteUrls } from './SiteUrls'
import { SharedDataView } from './mydata/SharedDataView'
import { CompleteSignUp } from './doctor/signup/CompleteSignUp'
import { CompletePharmacySignUp } from './pharmacy/signup/CompletePharmacySignUp'
import { ProofExpired } from './login/ProofExpired'
import { PharmacyAppProvider } from './pharmacy/context/PharmacyAppContext'
import { PharmacyRoot } from './pharmacy/PharmacyRoot'
import { ThirdPartyConsentPage } from './login/ThirdPartyConsentPage'
import { UidConsentPage } from './login/UidConsentPage'

export const AppRoot: React.FC = () => {
  return (
    <Switch>
      <Route path={SiteUrls.Terms} component={TermsPage} />
      <Route path={SiteUrls.Privacy} component={PrivacyPage} />
      <Route path={SiteUrls.ThirdPartyConsent} component={ThirdPartyConsentPage} />
      <Route path={SiteUrls.UidConsent} component={UidConsentPage} />
      <Route path={SiteUrls.SuccessSignUp} component={CompleteSignUp} />
      <Route path={SiteUrls.SuccessMediSignUp} component={CompletePharmacySignUp} />
      <Route path={SiteUrls.ProofExpired} component={ProofExpired} />
      <Route path={SiteUrls.PrivacyConsent} component={PrivacyConsentPage} />
      {/*<Route*/}
      {/*  path={SiteUrls.ForgotPasswordEmailSent(':email')}*/}
      {/*  render={(props: any) => <ForgotPasswordEmailSentPage email={props.match.params.email ?? ''} />}*/}
      {/*/>*/}
      <Route
        path={SiteUrls.PasswordResetConfirmed(':email', ':accountType')}
        render={(props: any) => (
          <PasswordResetConfirmedPage
            email={props.match.params.email ?? ''}
            accountType={props.match.params.accountType}
          />
        )}
      />
      <Route
        path={SiteUrls.MyDataTemporaryWithURL(':url')}
        render={(props: any) => <SharedDataView url={props.match.params.url} temporary={true} />}
      />
      {/* <Route
          path={SiteUrls.MyDataWithURL(':url')}
          render={(props: any) => <SharedDataView url={props.match.params.url} temporary={false}/>}
      /> */}
      <Route
        path={SiteUrls.ForgotPassword.Doctor}
        render={(props: any) => (
          <ForgotPasswordPage fromRoute={ForgotPasswordRoute.Doctor} designStyle={ForgotPasswordRoute.Doctor} />
        )}
      />
      <Route
        path={SiteUrls.ForgotPassword.Admin}
        render={(props: any) => (
          <ForgotPasswordPage fromRoute={ForgotPasswordRoute.Admin} designStyle={ForgotPasswordRoute.Admin} />
        )}
      />
      <Route
        path={SiteUrls.ForgotPassword.Manager}
        render={(props: any) => (
          <ForgotPasswordPage fromRoute={ForgotPasswordRoute.Manager} designStyle={ForgotPasswordRoute.Manager} />
        )}
      />
      <Route
        path={SiteUrls.ForgotPassword.Pharmacy}
        render={(props: any) => (
          <ForgotPasswordPage fromRoute={ForgotPasswordRoute.Pharmacy} designStyle={ForgotPasswordRoute.Pharmacy} />
        )}
      />
      {/* 아이디찾기 */}
      <Route
        path={SiteUrls.ForgotID.Doctor}
        render={(props: any) => <ForgotIDPage fromRoute={ForgotIDRoute.Doctor} designStyle={ForgotIDRoute.Doctor} />}
      />
      <Route
        path={SiteUrls.ForgotID.Admin}
        render={(props: any) => <ForgotIDPage fromRoute={ForgotIDRoute.Admin} designStyle={ForgotIDRoute.Admin} />}
      />
      <Route
        path={SiteUrls.ForgotID.Manager}
        render={(props: any) => <ForgotIDPage fromRoute={ForgotIDRoute.Manager} designStyle={ForgotIDRoute.Manager} />}
      />
      <Route
        path={SiteUrls.ForgotID.Pharmacy}
        render={(props: any) => (
          <ForgotIDPage fromRoute={ForgotIDRoute.Pharmacy} designStyle={ForgotIDRoute.Pharmacy} />
        )}
      />
      <Route
        path={SiteUrls.Landing.Root}
        render={() => {
          return (
            <ODDoctorAppProvider>
              <LandingRoot />
            </ODDoctorAppProvider>
          )
        }}
      />
      <Route
        path={SiteUrls.Doctor.Root}
        render={() => {
          return (
            <ODDoctorAppProvider>
              <DoctorRoot />
            </ODDoctorAppProvider>
          )
        }}
      />
      <Route
        path={SiteUrls.Manager.Root}
        render={() => {
          return (
            <ManagerAppProvider>
              <ManagerRoot />
            </ManagerAppProvider>
          )
        }}
      />
      <Route
        path={SiteUrls.Admin.Root}
        render={() => {
          return (
            <AdminAppProvider>
              <AdminRoot />
            </AdminAppProvider>
          )
        }}
      />
      <Route
        path={SiteUrls.Pharmacy.Root}
        render={() => {
          return (
            <PharmacyAppProvider>
              <PharmacyRoot />
            </PharmacyAppProvider>
          )
        }}
      />
      {/*<Redirect to={SiteUrls.Doctor.Root} />*/}
      <Redirect to={SiteUrls.Landing.Root} />
    </Switch>
  )
}
