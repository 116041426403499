import React from 'react'
import { DoctorProfileDiagnosisPage } from './DoctorProfileDiagnosisPage'
import { Page, PageContainer, PageContent, PageHeader, PageTitle, TitleDescription } from '../../common/page/Page'
import { Link } from 'react-router-dom'
import { ImageButton, Button, BP } from '../../common/RebeccaButton'
import SVGScheduleBlack from '../../../assets/schedule_black.svg'

import styled from 'styled-components'
import { RowWrap, ColumnWrap } from '../../common/style/CommonStyle'
import { ODHSpace, ODIcon, ODVSpace, ODIcons } from '@mdpp/od-react-belt'
import { EvixButton, EvixButtonTheme } from '../../common/EvixButton'

interface IDoctorProfileDiagnosisContainerProps {}

export const DoctorProfileDiagnosisContainer: React.FC<IDoctorProfileDiagnosisContainerProps> = props => {
  const goToGuide = () => {
    window.open('https://bit.ly/3aBjvgp', '_blank')
  }

  const Test1To1 = () => {
    window.open('https://forms.gle/RXAGGoY9wEd5Azuw8', '_blank')
  }

  return (
    <>
      <PageContainer>
        <Page>
          <PageHeader style={{ alignItems: 'flex-end' }}>
            <ColumnWrap>
              <PageTitle>진료 설정</PageTitle>
              <ODVSpace h={20} />
              <TitleDescription className="line">
                <span>
                  비대면 진료 진행 전에 <b>진료과목 / 전문분야 / 초진 가능 여부</b>를 설정 후 저장하시고,
                  <br />
                  <b>스케줄을 설정</b>해주세요.
                </span>
              </TitleDescription>
            </ColumnWrap>
            <RowWrap>
              {/*<Link to={goToGuide} style={{ textDecoration: 'none', backgroundColor: '#fff' }}>*/}
              <EvixButton theme={EvixButtonTheme.H48BlueLineInline} style={TopBtn} onClick={goToGuide} disabled={false}>
                <ODIcon icon={ODIcons.MaterialHelpOutline} style={{ marginRight: 3 }} />
                비대면 진료 가이드
              </EvixButton>
              {/*</Link>*/}
              <ODHSpace w={10} />
              {/*<Link to={Test1To1} style={{ textDecoration: 'none', backgroundColor: '#fff' }}>*/}
              <EvixButton theme={EvixButtonTheme.H48WhiteInline} style={TopBtn} onClick={Test1To1} disabled={false}>
                <ODIcon icon={ODIcons.SVGMemo} style={{ marginRight: 3 }} />
                비대면 진료 테스트 신청
              </EvixButton>
              {/*</Link>*/}
            </RowWrap>
          </PageHeader>

          <PageContent>
            <DoctorProfileDiagnosisPage />
          </PageContent>
        </Page>
      </PageContainer>
    </>
  )
}

const TopBtn = {
  height: 44,
  fontSize: 13,
  fontWeight: 500,
  paddingTop: 0,
  paddingBottom: 0,
  display: 'flex',
  alignItems: 'center',
}
