import { useODMutation, useODQuery } from '@mdpp/od-react-belt'
import {
  GQLHospitalManager,
  GQLHospitalManagerLoginInput,
  GQLHospitalManagerLoginResponse,
  GQLOkResponse,
  GQLPatientMemo,
  GQLPatientMemoCreationInput,
  GQLPatientMemoDeleteInput,
  GQLPatientMemoSearchInput,
  GQLPatientMemoUpdateInput,
} from '@mdpp/common/lib/@types/server.schema'
import { AppOptions } from '../../AppOptions'
import {
  GQL_LOGOUT,
  GQL_CREATE_PATIENT_MEMO,
  GQL_DELETE_PATIENT_MEMO,
  GQL_GET_PATIENT_MEMO,
  GQL_UPDATE_PATIENT_MEMO,
 } from '../../common/gqls_common'
import { GQL_GET_HOSPITAL_MANAGER_PROFILE, GQL_LOGIN_HOSPITAL_MANAGER } from './gqls_hospital_manager'

export function useManagerAPIs() {
  const simulateDelay = AppOptions.SIMULATE_DELAY

  const loginManager = useODMutation<GQLHospitalManagerLoginInput, GQLHospitalManagerLoginResponse>(
    GQL_LOGIN_HOSPITAL_MANAGER,
    simulateDelay
  )
  const logout = useODMutation<void, GQLOkResponse>(GQL_LOGOUT, simulateDelay)
  const getManagerProfile = useODQuery<void, GQLHospitalManager>(GQL_GET_HOSPITAL_MANAGER_PROFILE, simulateDelay)

  const createPatientMemo = useODMutation<Partial<GQLPatientMemoCreationInput>, GQLPatientMemo>(
    GQL_CREATE_PATIENT_MEMO,
    simulateDelay
  )

  const getPatientMemo = useODMutation<Partial<GQLPatientMemoSearchInput>, GQLPatientMemo>(
    GQL_GET_PATIENT_MEMO,
    simulateDelay
  )

  const updatePatientMemo = useODMutation<Partial<GQLPatientMemoUpdateInput>, GQLPatientMemo>(
    GQL_UPDATE_PATIENT_MEMO,
    simulateDelay
  )

  const deletePatientMemo = useODMutation<Partial<GQLPatientMemoDeleteInput>, GQLPatientMemo>(
    GQL_DELETE_PATIENT_MEMO,
    simulateDelay
  )

  return {
    loginManager,
    logout,
    getManagerProfile,
    createPatientMemo,
    getPatientMemo,
    updatePatientMemo,
    deletePatientMemo,
  }
}
