import React from 'react'
import { Link } from 'react-router-dom'
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Nav } from 'reactstrap'
import styled from 'styled-components'
import { SiteUrls } from '../../SiteUrls'
import { useAdminAppContext } from '../context/AdminAppContext'

interface IAdminDropdownProps {}

const CenterBoldMenuItem = styled.div`
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.2px;
  text-align: center;
  color: #2f353a;
`

const MenuHeader: React.FC<{ title: string }> = props => {
  return (
    <DropdownItem header tag="div" className="text-center">
      <strong>{props.title}</strong>
    </DropdownItem>
  )
}

export const AdminDropdown: React.FC<IAdminDropdownProps> = props => {
  const [isOpen, setIsOpen] = React.useState(false)
  const {
    state: { profile },
  } = useAdminAppContext()

  const name = profile?.email

  return (
    <Nav className="ml-auto" navbar>
      <Dropdown nav isOpen={isOpen} toggle={() => setIsOpen(!isOpen)} size="sm">
        <DropdownToggle
          caret
          style={{
            backgroundColor: 'white',
            color: 'black',
          }}
        >
          {name}&nbsp;&nbsp;
        </DropdownToggle>
        <DropdownMenu right>
          <MenuHeader key="etc" title="Admin" />
          <Link to={SiteUrls.Admin.Logout}>
            <DropdownItem>
              <CenterBoldMenuItem>로그아웃</CenterBoldMenuItem>
            </DropdownItem>
          </Link>
        </DropdownMenu>
      </Dropdown>
    </Nav>
  )
}
