import { ODListableResponseType } from '@mdpp/od-react-belt'
import React from 'react'
import { GQLFaq, GQLListableFaqInput } from '@mdpp/common/lib/@types/server.schema'
import { useCommonAPIs } from '../../hooks/useCommonAPIs'
import { IFaqListableOption } from '../FaqCommon'

export function useFaqListDataLoader(defaultOptions: Partial<GQLListableFaqInput>) {
  const { listFaq } = useCommonAPIs()
  return React.useCallback(
    async function dataLoader(
      page: number,
      pageSize: number,
      afterKey: string | null,
      options: IFaqListableOption
    ): Promise<ODListableResponseType<GQLFaq>> {
      // @ts-ignore
      const r = await listFaq({
        page,
        pageSize,
        ...options,
        ...defaultOptions,
      })
      return r as ODListableResponseType<GQLFaq>
    },
    [listFaq, defaultOptions]
  )
}
