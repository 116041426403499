import React from 'react'
import { PageContainer, Page, PageHeader, PageTitle } from '../../common/page/Page'
import { HospitalManagerListableTable } from './list/HospitalManagerListableTable'

interface IAdminHospitalManagerPageProps {
  hospitalId: number
}

export const AdminHospitalManagerListPage: React.FC<IAdminHospitalManagerPageProps> = props => {
  return (
    <PageContainer>
      <Page>
        <PageHeader>
          <PageTitle>병원 {'>'} 병원 관리자</PageTitle>
        </PageHeader>
        <HospitalManagerListableTable hospitalId={props.hospitalId} />
      </Page>
    </PageContainer>
  )
}
