import { ODIcons, ODVSpace } from '@mdpp/od-react-belt'
import React from 'react'
import { NumberParam, useQueryParam, withDefault } from 'use-query-params'
import { EvixTabBar, ITabBarIcon } from '../../../common/EvixTabBar'
import { AdminWaitingDoctorProfilePage } from './AdminWaitingDoctorProfilePage'
import styled from 'styled-components/macro'
import {Page, PageContainer, PageHeader, PageTitle} from "../../../common/page/Page";

interface IAdminDoctorDetailPageContainerProps {
  doctorId: number
}

const Items: ITabBarIcon[] = [
  {
    icon: ODIcons.Doctor,
    text: '의사정보',
  }
]

export const AdminWaitingDoctorDetailPageContainer: React.FC<IAdminDoctorDetailPageContainerProps> = props => {
  const { doctorId } = props
  const [tabRaw, setTab] = useQueryParam('tab', withDefault(NumberParam, 0))
  const tab = Math.min(Math.max(tabRaw, 0), Items.length - 1)

  return (
    <PageContainer>
      <Page>
        <PageHeader>
          <PageTitle>가입승인 대기 의사</PageTitle>
        </PageHeader>
        <EvixTabBar items={Items} selectedIndex={tab} onClickTab={setTab} />
        <ODVSpace h={18}/>
        <PageContent>
          {tab === 0 && <AdminWaitingDoctorProfilePage doctorId={doctorId} />}
        </PageContent>
      </Page>
    </PageContainer>
   
  )
}

export const PageContent = styled.div`
  width: 100%;
  padding-bottom: 48px;
  display: flex;
  flex-direction: column;
  /* gap: 24px; */
`

