import classNames from 'classnames'
import React, { HTMLAttributes, useState } from 'react'
import { InputType } from 'reactstrap/lib/Input'
import styled from 'styled-components/macro'
import { WarningMessageWrap } from './style/SignUpStyle'
import { COLORS } from '../styles/colors'

interface IEvixInputTextProps {
  id?: string
  type?: InputType
  error?: boolean
  success?: boolean
  disabled?: boolean
  placeholder?: string
  value?: string
  onChangeRaw?: (e: React.FormEvent<HTMLInputElement>) => void
  onChange?: (data: any) => void
  onEnter?: () => void
  guideText?: string
  inputProps?: HTMLAttributes<HTMLInputElement>
  title?: string
  maxLength?: number
}

export const EvixInputText: React.FC<IEvixInputTextProps> = props => {
  const {
    id,
    placeholder,
    value,
    onChangeRaw,
    onChange,
    onEnter,
    guideText,
    type,
    title,
    inputProps = {},
    maxLength,
    ...cnProps
  } = props
  const cn = classNames('od-input', cnProps)
  const cnWrapper = classNames('od-input-wrapper', {
    success: cnProps.success,
    error: cnProps.error,
    disabled: cnProps.disabled,
  })

  const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
    onChangeRaw?.(e)
    // @ts-ignore
    onChange?.(e.target.value)
  }

  const handleKeydown = (e: any) => {
    if (e.key === 'Enter') {
      onEnter?.()
      e.preventDefault()
    }
  }

  return (
    <div className="w-100">
      <InputWrap className={cnWrapper}>
        <input
          type={type}
          style={value ? undefined : placeholderCss}
          id={id}
          disabled={cnProps.disabled}
          className={cn}
          placeholder={placeholder}
          value={value}
          onChange={handleChange}
          onKeyPress={handleKeydown}
          {...inputProps}
          title={title}
          maxLength={maxLength}
        />
      </InputWrap>
    </div>
  )
}

const InputWrap = styled.div`
  .od-input {
    height: 48px;
    font-size: 15px;
    font-weight: 500;
    background-color: '#eeeff1';
    border-radius: 4px;
  }
`

const placeholderCss = { fontSize: 15, color: '#ababab !important' }
