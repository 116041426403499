import { ODIcon, ODIcons, ODVSpace } from '@mdpp/od-react-belt'
import React from 'react'
import styled from 'styled-components'
import { COLORS } from '../styles/colors'

export interface ITabBarIcon {
  icon?: ODIcons
  text: string
}

interface IEvixTabBarProps {
  items: ITabBarIcon[]
  selectedIndex: number
  onClickTab: (index: number) => void
  isRoom?: boolean
}

interface ITabBarWrapperProps {
  selected: boolean
}

const ItemWrapper = styled.div`
  width: 71px;
  padding: 0px 10px 0px 10px;
`

const TabBarWrapper = styled.div`
  cursor: pointer;
  width: 58px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 25px;
  padding-bottom: 7px;
  color: ${(p: ITabBarWrapperProps) => (p.selected ? '#3096f5' : '#131415')};
  border-bottom: ${(p: ITabBarWrapperProps) => (p.selected ? `2px solid #3096f5` : 0)};
`

const TabBarText = styled.span`
  font-size: 15px;
  line-height: 1.33;
`

const EvixTabBarItem: React.FC<ITabBarIcon & { selected: boolean; onClick: () => void }> = props => {
  return (
    <ItemWrapper>
      <TabBarWrapper selected={props.selected} onClick={props.onClick}>
        {props.icon && (
          <>
            <ODIcon icon={props.icon} />
            <ODVSpace h={7} />
          </>
        )}
        <TabBarText>{props.text}</TabBarText>
      </TabBarWrapper>
    </ItemWrapper>
  )
}

const Wrapper = styled.div`
  padding-left: 30px;
  padding-right: 0;
  // background-color: ${COLORS.WHITE};
  background-color: #F9FAFB;
  height: 84px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);

  > :not(:first-child) {
    margin-left: ${(p: { room: boolean }) => (p.room ? 0 : '0px')};
  }
`

export const RoomTabBar: React.FC<IEvixTabBarProps> = props => {
  const { items, selectedIndex, onClickTab, isRoom } = props

  const isRoomWrapperStyle = isRoom ? { height: 28, boxShadow: 'none', marginLeft: 0, paddingLeft: 0, background: 'white' } : {}
  return (
    <Wrapper className="w-100 flex flex-row" style={isRoomWrapperStyle} room={!!isRoom}>
      {items.map((item, index) => {
        return (
          <EvixTabBarItem key={index} selected={index === selectedIndex} {...item} onClick={() => onClickTab(index)} />
        )
      })}
    </Wrapper>
  )
}
