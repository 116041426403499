import { ODIcons, ODModalSize, useODModalConfirm, useODModalContext } from '@mdpp/od-react-belt'
import React from 'react'
import gql from 'graphql-tag'
import styled from 'styled-components'
import { NumberParam, useQueryParam, withDefault } from 'use-query-params'
import { GQLDoctor, GQLMedicationDispensing } from '@mdpp/common/lib/@types/server.schema'
import { EvixTabBar, ITabBarIcon } from '../../../common/EvixTabBar'
import { ReservationNoticeBox } from './ReservationNoticeBox'
import { Page, PageContainer, PageContent, PageHeader, PageTitle } from '../../../common/page/Page'
import { EvixCard } from '../../../common/card/EvixCard'
import { PrescriptionConfirm } from './PrescriptionConfirm'
import { Payment } from './Payment'
import { DeliveryInfo } from './DeliveryInfo'
import { usePharmacyAPIs, usePharmacistReservation } from '../../context/usePharmacyAPIs'
import { Redirect, useParams } from 'react-router-dom'
import { useSSE } from 'react-hooks-sse'
import { ReservationStatusEvent } from '@mdpp/common/lib/sse/common'
import { WebUtils } from '../../../utils/webUtils'
import { DiagNameWithOption } from '../../../doctor/reservation/detail/infoCard/DiagnosisList'
import { IEvixPrescriptionFile } from '../../../common/EvixPrescriptionFileUpload'
import { RefuseDispenseModal } from './RefuseDispenseModal'
import { IsBtnConfirmModal } from '../../../common/layout/IsBtnConfirmModal'
import IconWarningBlue from '../../../assets/common/warning-blue.svg'

interface IReservationDetailPageProps {}

enum TabType {
  PrescriptionConfirm,
  Payment,
  DeliveryInfo,
}

type ReservationTabBarIcon = ITabBarIcon & { type: TabType }

const Items: ReservationTabBarIcon[] = [
  {
    icon: ODIcons.SVGReservationResult,
    text: '처방전 확인',
    type: TabType.PrescriptionConfirm,
  },
  { icon: ODIcons.CoreCreditCard, text: '비용 청구', type: TabType.Payment },
  // {
  //   icon: ODIcons.MaterialHelpOutline,
  //   text: '복약지도/배송 정보',
  //   type: TabType.DeliveryInfo,
  // },
]

export const ReservationPharmacistDetailPage: React.FC<IReservationDetailPageProps> = props => {
  const items = Items
  const { id } = useParams<{ id: string }>()
  const [tabRaw, setTab] = useQueryParam('tab', withDefault(NumberParam, 0))
  const tab = Math.min(Math.max(tabRaw, 0), items.length - 1)
  const [loading, setLoading] = React.useState(false)
  const { reservation, setReservation, error, refresh } = usePharmacistReservation(id)
  const apis = usePharmacyAPIs()
  const { confirm, confirmInput } = useODModalContext()
  const { Component: ConfirmFinishComponent, confirm: confirmFinish, props: confirmFinishProps } = useODModalConfirm({
    size: ODModalSize.Normal,
  })
  const [redirect, setRedirect] = React.useState('')
  const tabType = items[tab].type ?? TabType.PrescriptionConfirm
  const isFinished = reservation?.status !== 'ACCEPTED'
  // 조제 거절
  const [showRefuseDespenseModal, setShowRefuseDespenseModal] = React.useState(false)
  const [status, setStatus] = React.useState('')
  const [reason, setReason] = React.useState('')
  // 조제비 청구
  const [showPayModal, setShowPayModal] = React.useState(false)
  const [medicinePay, setMedicinePay] = React.useState(0)
  const [deliveryPrice, setDeliveryPrice] = React.useState(0)

  const reservationChanged = useSSE<ReservationStatusEvent>(
    'medicationDispensingStatusChanged',
    { reservationId: null, status: null },
    {
      parser(input) {
        const event = JSON.parse(input)
        console.log('medicationDispensingStatusChanged')
        console.log(event)
        if (event.id === reservation?.reservationId) refresh()
        return event
      },
    }
  )

  React.useEffect(() => {
    const TabHandler = setTimeout(() => {
      refresh()
    }, 500)
    return () => {
      clearInterval(TabHandler)
    }
  }, [tab])

  const handleCancel = async () => {
    if (!reservation) {
      return
    }
  }

  const handleFinish = async () => {
    setTab(1)
  }

  const handleNotFinish = async () => {
    setShowRefuseDespenseModal(true)
  }

  const RefuseDispenseOk = async (reason: string) => {
    setReason(reason)

    try {
      const res = await apis.rejectDispensingRequest({
        medicationDispensingId: reservation!.medicationDispensingId!,
        reason: reason,
      })
      setStatus(res.status)
      setShowRefuseDespenseModal(false)
      refresh()
    } catch (e) {
      console.log(e)
    }
  }

  const handlePayOk = async (medicinePay: number) => {
    if (medicinePay !== 0) {
      if (medicinePay < 100) {
        return WebUtils.showError('100원 이상 입력해주세요.')
      }
    }
    setShowPayModal(true)
    setMedicinePay(medicinePay)
  }

  const ChargeDispensingFee = async (medicinePay: number, deliveryPrice: number) => {
    try {
      const res = await apis.chargeDispensingFee({
        medicationDispensingId: reservation!.medicationDispensingId,
        medicinePrice: medicinePay,
        deliveryPrice: deliveryPrice ? deliveryPrice : null,
      })
      setShowPayModal(false)
      refresh()
    } catch (e) {
      console.log(e)
    }
  }

  // const handleDeliveryComment = () => {
  //   console.log('코멘트 전송 API')
  //   refresh()
  // }

  if (redirect) {
    return <Redirect to={redirect} />
  }

  return (
    <ReservationPageContainer style={{ flexDirection: 'column' }}>
      <Page>
        <div style={{ borderBottom: '1px solid var(--gray200)', marginBottom: 8 }}>
          <ReservationNoticeBox reservation={reservation!} onClickCancel={handleCancel} />
        </div>

        <EvixTabBarWapper>
          <EvixTabBar items={items} selectedIndex={tab} onClickTab={setTab} />
        </EvixTabBarWapper>
      </Page>
      <Page>
        <EvixCard>
          {tabType === TabType.PrescriptionConfirm && (
            <PrescriptionConfirm
              reservation={reservation!}
              handleFinish={handleFinish}
              handleNotFinish={handleNotFinish}
              isFinishedMedical={isFinished}
            />
          )}

          {tabType === TabType.Payment && <Payment reservation={reservation!} handlePay={handlePayOk} />}

          {/*{tabType === TabType.DeliveryInfo && (*/}
          {/*  <DeliveryInfo reservation={reservation!} handleComment={handleDeliveryComment} />*/}
          {/*)}*/}
        </EvixCard>
      </Page>

      {showRefuseDespenseModal && (
        <RefuseDispenseModal
          style={{ width: 420, minHeight: 300, maxHeight: 500, maxWidth: 420 }}
          onOkPress={RefuseDispenseOk}
          onCancelPress={() => setShowRefuseDespenseModal(false)}
        />
      )}

      {showPayModal && (
        <IsBtnConfirmModal
          style={{ width: 420, height: 330 }}
          isTitle={true}
          title={'비용을 확인해주세요.'}
          titleIcon={IconWarningBlue}
          titleIconSize={'L'}
          isDescriptionIcon={false}
          description={[
            '조제비 - ' + `${medicinePay}원`,
            <br />,
            '배송비 - ' + `퀵 ${!!reservation?.quickPrice ? reservation?.quickPrice : 0}원`,
            <br />,
            <br />,
            '환자에게 청구하시겠습니까?',
          ]}
          descriptionIcon={''}
          hideCancel={false}
          BtnLeft={'취소'}
          BtnRight={'확인'}
          onLeftBtnPress={() => setShowPayModal(false)}
          onRightBtnPress={() => ChargeDispensingFee(medicinePay, deliveryPrice)}
          onCancelPress={() => setShowPayModal(false)}
        />
      )}
    </ReservationPageContainer>
  )
}

const EvixTabBarWapper = styled.div`
  padding-bottom: 18px;
`

const ReservationPageContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
`

const ReservationDiagnosisInfoBoxPage = styled.div`
  max-width: 1280px;
  width: 100%;
  height: calc(100% - 200px);
  padding: 0 50px;
  display: flex;
  flex-direction: column;
  justify-items: center;

  @media (max-width: 992px) {
    padding: 0 20px;
    min-width: 650px;
  }
`
