import { ODEntity, ODEntityInput, ODIcon, ODIcons, ODImageFileInput, ODVSpace } from '@mdpp/od-react-belt'
import gql from 'graphql-tag'
import React, { useEffect } from 'react'
import styled from 'styled-components/macro'
import { GQLHospital, GQLHospitalCreationInput, GQLHospitalUpdateInput } from '@mdpp/common/lib/@types/server.schema'
import { SiteUrls } from '../../../SiteUrls'
import { COLORS } from '../../../styles/colors'
import { useODEntityAPI } from '../../../utils/odGQLEntityLoader'
import { WebUtils } from '../../../utils/webUtils'
import { GQL_CREATE_HOSPITAL, GQL_DELETE_HOSPITAL, GQL_GET_HOSPITAL, GQL_UPDATE_HOSPITAL } from '../../gqls_common'
import { HospitalPrimaryKey, ICTHospital } from '../HospitalCommon'
import { EvixCard } from '../../card/EvixCard'
import { TableList, TableListWrapper } from '../../list/TableList'
import { PageContainer, Page, PageHeader, PageTitle, PageContent } from '../../page/Page'
import IconWarningBlue from '../../../assets/common/warning-blue.svg'
import { EvixInlineInputText } from '../../EvixInlineInputText'
import { useAdminAPIs } from '../../../admin/context/useAdminAPIs'
import { saveAs } from 'file-saver'
import { FileInput, FileLabel, PharmacyNameWrap, WarningMessageWrap } from '../../style/SignUpStyle'
import { MessageIcon } from '../../style/CommonStyle'

interface IHospitalEditPageProps {
  idEditing: number | null
  fromHospitalManager?: boolean
}

const DEFAULT_DATA: Partial<ICTHospital> = {
  hospitalId: 0,
  email: '',
  telephone: '',
  address: '',
  name: '',
  zipCode: '',
  isHidden: false,
  // @ts-ignore
  profileImage: '',
  businessLicenseFile: '',
}

export interface IEvixPrescriptionFiles {
  id: number
  fileName: string
  link: string
  file?: File
}

export const HospitalEditPage: React.FC<IHospitalEditPageProps> = props => {
  const { idEditing, fromHospitalManager = false } = props
  const headerText = idEditing ? '병원관리' : '병원 추가'
  const [fileName, setFileName] = React.useState('')
  const [fileLink, setFileLink] = React.useState('')
  const [bankBook, setBankBook] = React.useState('')
  const [bankBookFileLink, setBankBookFileLink] = React.useState('')
  const [hospitalName, setHospitalName] = React.useState('')
  const { getHospital, updateHospital } = useAdminAPIs()

  const apis = useODEntityAPI<GQLHospital, ICTHospital, GQLHospitalCreationInput, GQLHospitalUpdateInput>({
    createGQL: gql(GQL_CREATE_HOSPITAL),
    updateGQL: gql(GQL_UPDATE_HOSPITAL),
    readGQL: gql(GQL_GET_HOSPITAL),
    deleteGQL: fromHospitalManager ? undefined : gql(GQL_DELETE_HOSPITAL),
    primaryKeyName: HospitalPrimaryKey,
  })

  useEffect(() => {
    getHospitalData()
  }, [idEditing])

  const getHospitalData = async () => {
    try {
      const r = await getHospital({ id: idEditing! })
      setHospitalName(r.name)
      setFileName(r.businessLicenseFile?.fileName!)
      setFileLink(r.businessLicenseFile?.link!)
      setBankBook(r.bankbookFile?.fileName!)
      setBankBookFileLink(r.bankbookFile!.link)
    } catch (e) {
      console.log(e)
    }
  }

  const handleFileAdd = async (files: any, bankFiles: any) => {
    const postData: any = { id: idEditing! }
    if (files !== null) {
      postData!['businessLicenseFile'] = files[0]
    }
    if (bankFiles !== null) {
      postData!['bankbookFile'] = bankFiles[0]
    }

    try {
      const fileUploaded = await updateHospital(postData)
      setFileName(fileUploaded!.businessLicenseFile!.fileName)
      setFileLink(fileUploaded!.businessLicenseFile!.link)
      setBankBook(fileUploaded!.bankbookFile?.fileName!)
      setBankBookFileLink(fileUploaded!.bankbookFile!.link)
      WebUtils.showSuccess('파일이 변경되었습니다.')
    } catch (e) {
      console.log(e)
    }
  }

  const fileChangedHandler = async (e: any) => {
    const files = e.target.files
    const fileSize = files[0]?.size
    const fileChangeName = files[0]?.name

    if (files[0]?.name === null) {
      return console.log('파일없음')
    }
    if (fileSize > 50000000) {
      return WebUtils.showError('첨부파일 사이즈는 50MB 이내로 등록 가능합니다.')
    }
    setFileName(fileChangeName ? fileChangeName : fileName)
    setFileLink(files[0] ? files[0] : fileLink)
    await handleFileAdd(files, null)
  }

  const bankBookFileChangedHandler = async (e: any) => {
    const bankFiles = e.target.files
    const fileSize = bankFiles[0]?.size
    const fileChangeName = bankFiles[0]?.name

    if (bankFiles[0]?.name === null) {
      return console.log('파일없음')
    }
    if (fileSize > 50000000) {
      return WebUtils.showError('첨부파일 사이즈는 50MB 이내로 등록 가능합니다.')
    }
    setBankBook(fileChangeName ? fileChangeName : bankBook)
    setBankBookFileLink(bankFiles[0] ? bankFiles[0] : bankBookFileLink)
    await handleFileAdd(null, bankFiles)
  }

  const onDownloadBtn = (fileLink: string, hospitalName: string) => {
    saveAs(fileLink, `${hospitalName}_파일`)
    return WebUtils.showSuccess('파일이 다운로드 되었습니다.')
  }

  return (
    <PageContainer>
      <Page>
        <PageHeader>
          <PageTitle>{headerText}</PageTitle>
        </PageHeader>
        <PageContent>
          <EvixCard>
            <ODEntity
              resourceId={idEditing}
              api={apis}
              saveButtonName="저장"
              titleCreation="추가"
              createSuccessTitle="성공"
              createSuccessMessage="추가하였습니다."
              titleUpdate="수정"
              updateSuccessTitle="성공"
              updateSuccessMessage="수정하였습니다."
              urlAfterDelete={(item: ICTHospital) => SiteUrls.Admin.Hospital.Main}
              deleteSuccessTitle="성공"
              deleteSuccessMessage="삭제하였습니다."
              defaultCreateClientData={DEFAULT_DATA}
              urlAfterCreation={() => SiteUrls.Admin.Hospital.Main}
              urlAfterUpdate={() =>
                fromHospitalManager ? SiteUrls.Manager.Hospital.Main : SiteUrls.Admin.Hospital.Main
              }
              noCardWrap
              footerProps={{
                deleteButtonStyle: {
                  borderColor: COLORS.DANGER,
                  color: COLORS.DANGER,
                  backgroundColor: COLORS.RED10,
                },
              }}
              deleteButtonName={!fromHospitalManager ? '삭제' : undefined}
            >
              <TableListWrapper>
                <TableList name="profileImage" label="병원 로고">
                  <ProfileImageRow>
                    <ODImageFileInput
                      name="profileImage"
                      height={92}
                      width={92}
                      accept="image/png, image/jpeg, image/bmp"
                      keyPath="profileImage.link"
                      validate={f => {
                        if (f.size > 4000000) {
                          // TODO: 프로필 이미지는 1M 하로 변환해서 저장하는 부분 구현 필요
                          WebUtils.showError('이미지 파일 크기는 최대 4Mb 까지 가능합니다.')
                          return false
                        }
                        return true
                      }}
                      style={enabled =>
                        enabled
                          ? {
                              border: 'none',
                            }
                          : {
                              borderRadius: 4,
                              backgroundColor: 'var(--gray100)',
                              border: '1px solid var(--gray300)',
                            }
                      }
                      renderNoneImage={() => (
                        <NoneImageBackground>
                          <ODIcon icon={ODIcons.CoreSatelite} />
                        </NoneImageBackground>
                      )}
                    />
                    <InfoWrapper>
                      <InfoIconWrapper>
                        <ODIcon icon={ODIcons.MaterialError} />
                      </InfoIconWrapper>
                      <ProfileImageHelpText>
                        <ProfileImageDescWrapper>
                          새로운 사진으로 교체를 원하는 경우 이미지 위에 드래그를 하거나
                        </ProfileImageDescWrapper>
                        <ProfileImageDescWrapper>
                          현재 이미지를 클릭하여 새로운 사진을 선택해주세요.
                        </ProfileImageDescWrapper>
                      </ProfileImageHelpText>
                    </InfoWrapper>
                  </ProfileImageRow>
                </TableList>

                <TableList name="name" label="병원명">
                  <ODEntityInput
                    keyPath="name"
                    label="병원명"
                    name="name"
                    placeholder="병원명을 입력해 주세요."
                    inputType="text"
                  />
                </TableList>
                <TableList name="email" label="문의 이메일">
                  <ODEntityInput
                    keyPath="email"
                    label="문의 이메일"
                    name="email"
                    placeholder="이메일을 입력해 주세요."
                    inputType="email"
                  />
                </TableList>
                <TableList name="address" label="병원주소">
                  <ODEntityInput
                    keyPath="address"
                    label="병원주소"
                    name="address"
                    placeholder="병원주소를 입력해 주세요."
                    inputType="text"
                  />
                </TableList>
                <TableList name="zipCode" label="우편번호">
                  <ODEntityInput
                    keyPath="zipCode"
                    label="우편번호"
                    name="zipCode"
                    placeholder="우편번호를 입력해 주세요."
                    inputType="text"
                  />
                </TableList>
                <TableList name="telephone" label="연락처">
                  <ODEntityInput
                    keyPath="telephone"
                    label="연락처"
                    name="telephone"
                    placeholder="연락처를 입력해주세요."
                    inputType="tel"
                  />
                </TableList>

                <TableList name="certi" label="사업자등록증">
                  <PharmacyNameWrap>
                    <FileInput onClick={() => onDownloadBtn(fileLink, hospitalName)}>
                      {fileName ? (
                        fileName
                      ) : (
                        <>
                          <span className="placeholer">PDF, JPG, PNG 파일만 첨부 가능</span>
                        </>
                      )}
                    </FileInput>
                    <FileLabel htmlFor="input-file">
                      {' '}
                      사업자등록증 첨부
                      <input
                        name="businessLicenseFile"
                        type="file"
                        id="input-file"
                        style={{ display: 'none' }}
                        spellCheck={false}
                        accept=".pdf, image/png, image/jpeg, image/jpg"
                        onChange={e => fileChangedHandler(e)}
                      />
                    </FileLabel>
                  </PharmacyNameWrap>
                  <WarningMessageWrap>
                    <ul>
                      <li className="align-center">
                        <MessageIcon src={IconWarningBlue} alt="warning icon" /> 비대면 진료 정산을 위해 필요합니다.
                      </li>
                    </ul>
                  </WarningMessageWrap>

                  {/* <CertiWrap>
                    {fileName === undefined ? (
                      <NoFileName>사업자등록증을 첨부해 주세요</NoFileName>
                    ) : (
                      <EvixInlineInputText
                        style={{ flex: 1 }}
                        value={fileName}
                        readOnly
                        onClick={() => onDownloadBtn(fileLink, hospitalName)}
                      />
                    )}
                    
                  </CertiWrap> */}
                </TableList>

                <TableList name="bankBook" label="통장사본">
                  <PharmacyNameWrap>
                    <FileInput onClick={() => onDownloadBtn(bankBookFileLink, hospitalName)}>
                      {bankBook ? (
                        bankBook
                      ) : (
                        <>
                          <span className="placeholer">PDF, JPG, PNG 파일만 첨부 가능</span>
                        </>
                      )}
                    </FileInput>
                    <FileLabel htmlFor="input-bank-file">
                      {' '}
                      통장사본 첨부
                      <input
                        name="bankBookFile"
                        type="file"
                        id="input-bank-file"
                        style={{ display: 'none' }}
                        spellCheck={false}
                        accept=".pdf, image/png, image/jpeg, image/jpg"
                        onChange={e => bankBookFileChangedHandler(e)}
                      />
                    </FileLabel>
                  </PharmacyNameWrap>
                  <WarningMessageWrap>
                    <ul>
                      <li className="align-center">
                        <MessageIcon src={IconWarningBlue} alt="warning icon" /> 비대면 진료 정산을 위해 필요합니다.
                      </li>
                    </ul>
                  </WarningMessageWrap>
                </TableList>

                {/*<SectionHeader>진료 관리</SectionHeader>*/}
                {/*<ODEntityInput*/}
                {/*  keyPath="autoCancelAfterMin"*/}
                {/*  label="예약 부도 처리"*/}
                {/*  name="telephone"*/}
                {/*  placeholder="예약 부도 처리 단위"*/}
                {/*  inputType="tel"*/}
                {/*/>*/}
              </TableListWrapper>
              <ODVSpace h={24} />
            </ODEntity>
          </EvixCard>
        </PageContent>
      </Page>
    </PageContainer>
  )
}

const ProfileImageRow = styled.div`
  padding-top: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
`

const InfoWrapper = styled.div`
  display: flex;
  margin-left: 38px;
`

const ProfileImageHelpText = styled.div`
  font-size: 12px;
  line-height: 1.5;
  color: var(--mpPrimary500);
`

const InfoIconWrapper = styled.div`
  color: var(--mpPrimary500);
  transform: scale(0.7);
  margin-top: -5px;
`

const ProfileImageDescWrapper = styled.div`
  margin-left: 5px;
`

const NoneImageBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  color: var(--gray600);
`
