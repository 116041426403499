// @ts-ignore
import { AppSidebarToggler } from '@coreui/react'
import { ODHSpace, ODIcon, ODIcons } from '@mdpp/od-react-belt'
import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import LogoDoctor from '../../assets/common/bi-mdpp-doctor-small.png'
import { COLORS } from '../../styles/colors'
import { SiteUrls } from '../../SiteUrls'
import { useODDoctorAppContext } from '../context/ODDoctorAppContext'
import { DoctorDropdown } from './DoctorDropdown'
import SVGMac from '../../assets/mac_pc.svg'
import SVGWin from '../../assets/win_pc.svg'
import SVGMicOff from '../../assets/mic_off.svg'
import SVGMicOn from '../../assets/mic_on.svg'
import SVGChrome from '../../assets/chrome.svg'
import { WebUtils, getDeviceState, ERROR_TYPE, OS } from '../../utils/webUtils'
import { openPopup, closePopup } from '../../common/DevicePopup'

interface IEvixHeaderProps {}

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding: 0 20px;

  @media only screen and (max-width: 360px) {
    padding: 0 10px;
  }
`
const HospitalWrap = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
  margin-left: 10px;
`

const HospitalIcon = styled.div`
  font-size: 15px;
  color: ${COLORS.GRAY500};
`

const HospitalName = styled.span`
  font-size: 12px;
  font-weight: bold;
  color: ${COLORS.GRAY500};
  margin-top: 1px;
  max-height: 40px;
  overflow: hidden;
`

const IconWrapper = styled.div`
  display: flex;
  border: 1px solid #C9CCCE;
  border-radius: 80px;
  width: 68px;
  height: 36px;
  cursor: pointer;
`

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
`

const DeviceWrapper = styled.div`

`

const RoundIcon = styled.img`
  width: 24px;
  height: 24px;
  margin: 6px;
  margin-right: 0px;
`


export const DoctorHeader: React.FC<IEvixHeaderProps> = props => {
  const {
    state: { profile },
  } = useODDoctorAppContext()

  const [deviceState, setDeviceState] = React.useState(ERROR_TYPE.NONE)
  const [os, setOS] = React.useState(OS.WIN)

  const hospitalName = profile?.hospital?.name ?? '-'

  React.useEffect(() => {
    const current = WebUtils.getOS()
    setOS(current)
    console.log('Header os: ', os)
  }, [])

  React.useEffect(() => {
    getDeviceState().then((state: string) => {
      setDeviceState(state)
      console.log('Header Device state: ', state)
    })
  }, [profile])

  return (
    <>
      <Wrapper>
        <div style={{display: 'flex'}}>
          <AppSidebarToggler className="d-lg-none" display="md" mobile /> 
          <TitleWrapper>
            <Link to={SiteUrls.Doctor.Root} style={{ textDecoration: 'none', display: 'inline-block'  }}>
              <img src={LogoDoctor} className='logo-doctor'  alt="Medi PangPang Logo" />
            </Link>
            {/* <HospitalName>{hospitalName}</HospitalName> */}

            <HospitalWrap>
              <HospitalIcon>
                <ODIcon icon={ODIcons.CoreHospital} />
              </HospitalIcon>
              <HospitalName>{hospitalName}</HospitalName>
            </HospitalWrap>
          </TitleWrapper>
        </div>


        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          {/* <DeviceWrapper onClick={() => (setShowPopup(true))}> */}
          <DeviceWrapper onClick={() => (openPopup())}>
            {os === OS.WIN && deviceState !== ERROR_TYPE.NONE ?
              <IconWrapper>
                <RoundIcon src={SVGWin}></RoundIcon>
                <RoundIcon src={SVGMicOff}></RoundIcon>
              </IconWrapper>
            : os === OS.MAC &&
            deviceState === ERROR_TYPE.BROWSER ? (
              <IconWrapper>
                <RoundIcon src={SVGChrome}></RoundIcon>
                <RoundIcon src={SVGMicOff}></RoundIcon>
              </IconWrapper>
            ) : deviceState === ERROR_TYPE.SYSTEM ? (
              <IconWrapper>
                <RoundIcon src={SVGMac}></RoundIcon>
                <RoundIcon src={SVGMicOff}></RoundIcon>
              </IconWrapper>
            ) : null }
          </DeviceWrapper>
          <ODHSpace w={11} />
          <DoctorDropdown />
        </div>
      </Wrapper>
    </>
  )
}
