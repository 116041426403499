import { ODIcons, ODVSpace } from '@mdpp/od-react-belt'
import React from 'react'
import { NumberParam, useQueryParam, withDefault } from 'use-query-params'
import { EvixTabBar, ITabBarIcon } from '../../../common/EvixTabBar'
import { ManagerDoctorScheduleTablePage } from '../../schedule/ManagerDoctorScheduleTablePage'
import { ManagerDoctorAccountPage } from './ManagerDoctorAccountPage'
import { ManagerDoctorProfileDiagnosisPage } from './ManagerDoctorProfileDiagnosisPage'
import { ManagerDoctorProfilePage } from './ManagerDoctorProfilePage'
import {Page, PageContainer, PageContent, PageHeader, PageTitle} from "../../../common/page/Page";

interface IManagerDoctorDetailPageContainerProps {
  doctorId: number
  inTab?: boolean
}

const Items: ITabBarIcon[] = [
  { icon: ODIcons.CoreContact, text: '기본정보' },
  { icon: ODIcons.Doctor, text: '의사정보' },
  { icon: ODIcons.Patient, text: '진료정보' },
  { icon: ODIcons.CoreCalendarCheck, text: '스케줄' },
]

export const ManagerDoctorDetailPageContainer: React.FC<IManagerDoctorDetailPageContainerProps> = props => {
  const { doctorId } = props
  const [tabRaw, setTab] = useQueryParam('tab', withDefault(NumberParam, 0))
  const tab = Math.min(Math.max(tabRaw, 0), Items.length - 1)

  return (
      <PageContainer>
          <Page>
              <PageHeader>
                  <PageTitle>의사관리</PageTitle>
              </PageHeader>
              <PageContent>
                  <EvixTabBar items={Items} selectedIndex={tab} onClickTab={setTab} />
                  {tab === 0 && <ManagerDoctorAccountPage doctorId={doctorId} />}
                  {tab === 1 && <ManagerDoctorProfilePage doctorId={doctorId} />}
                  {tab === 2 && <ManagerDoctorProfileDiagnosisPage doctorId={doctorId} />}
                  {tab === 3 && <ManagerDoctorScheduleTablePage doctorId={doctorId} inTab />}
              </PageContent>
          </Page>
      </PageContainer>
  )
}
