import { ODListableResponseType } from '@mdpp/od-react-belt'
import React from 'react'
import { GQLPatient } from '@mdpp/common/lib/@types/server.schema'
import { useCommonAPIs } from '../../hooks/useCommonAPIs'
import { IPatientListableOption } from '../PatientCommon'

export function usePatientListDataLoader() {
  const { listPatient } = useCommonAPIs()
  return React.useCallback(
    async function dataLoader(
      page: number,
      pageSize: number,
      afterKey: string | null,
      options: IPatientListableOption
    ): Promise<ODListableResponseType<GQLPatient>> {
      const r = await listPatient({
        page,
        pageSize,
        ...options,
      })
      return r as ODListableResponseType<GQLPatient>
    },
    [listPatient]
  )
}
