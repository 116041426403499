import React from 'react'
import { Modal } from 'reactstrap'
import { EvixButton, EvixButtonTheme } from '../../EvixButton'
import styled, { css } from 'styled-components'
import IconClose from '../../../assets/common/icon-close.svg'
import {
  Wrapper,
  Header,
  Container,
  Content,
  DescriptionContainer,
  SimpleDescription,
  ButtonWrapper,
  ConfirmTitleWrap,
  ConfirmTitle,
} from '../../style/ModalStyle'
import { COLORS } from '../../../styles/colors'
import IconWarningRed from '../../../assets/common/warning-red-super.svg'

interface WithdrawalManagerModalProps {
  style?: object
  onOkPress: () => void
  onCancelPress: () => void
  onWebPress?: () => void
}

export const WithdrawalManagerModal: React.FC<WithdrawalManagerModalProps> = props => {
  const { style, onOkPress, onCancelPress } = props

  const goDockerHanderManager = () => {
    document.location.href = '/manager'
  }

  return (
    <Modal isOpen={true} toggle={() => {}} style={style}>
      <Wrapper>
        <Header>
          <img src={IconClose} onClick={onCancelPress} alt="close icon" />
        </Header>

        <Container className="start">
          <Content>
            <ConfirmTitleWrap className="icon-large">
              <img src={IconWarningRed} alt="warning bullet" />
              <ConfirmTitle>병원관리자 계정을 보유하고 있습니다.</ConfirmTitle>
            </ConfirmTitleWrap>

            <DescriptionContainer className="top">
              <Badge color="red">
                <span>주의</span>
              </Badge>
              <SimpleDescription className="left">
                의사 계정을 탈퇴하셔도 병원관리자 계정은 남아있습니다.
                <br />
                병원관리자 탈퇴를 원하실 경우, 메디팡팡 관리자에게 <br />
                문의해주세요. (문의 : 1566-8013)
              </SimpleDescription>
            </DescriptionContainer>
          </Content>

          {/* 버튼 영역 */}
          <ButtonWrapper className="pdt40">
            <EvixButton theme={EvixButtonTheme.H48WhiteInline} style={{ flex: 1 }} onClick={onOkPress} disabled={false}>
              {'탈퇴진행하기'}
            </EvixButton>
            <EvixButton
              theme={EvixButtonTheme.H48BlackInline}
              style={{ flex: 1 }}
              onClick={onCancelPress}
              disabled={false}
            >
              {'취소'}
            </EvixButton>
          </ButtonWrapper>

          {/* 공지 영역 */}
          <InfoWrap>
            <span>
              <Badge color="blue">
                <span>안내</span>
              </Badge>
            </span>
            <ul>
              <li>
                병원관리자 추가(다른 사람에게 이관)을 원하시면,
                <br />
                병원관리자 웹서비스에서 로그인 후 변경해주세요.
              </li>
            </ul>

            <EvixButton
              theme={EvixButtonTheme.H48WhiteRoundInline}
              style={{ width: 140, marginTop: 13 }}
              onClick={() => goDockerHanderManager()}
              disabled={false}
            >
              {'병원관리자 바로가기'}
            </EvixButton>
          </InfoWrap>
        </Container>
      </Wrapper>
    </Modal>
  )
}

const InfoWrap = styled.div`
  display: flex;
  flex-direction: column;
  border: solid 1px ${COLORS.GRAYE0};
  border-radius: 4px;
  padding: 20px;
  margin: 0 40px;

  > ul {
    margin: 16px 0 0 0;

    li {
      position: relative;
      font-size: 14px;
      font-weight: 500;
      line-height: 1.54;
      color: ${COLORS.GRAY42};
    }
  }
`

interface BadgeProps {
  children: any
  color: string
}

const Badge: React.FC<BadgeProps> = props => {
  const { children, color, ...rest } = props
  return (
    <BadgeSpan color={color} {...rest}>
      {children}
    </BadgeSpan>
  )
}

const BadgeStyles = css<BadgeProps>`
  ${props =>
    props.color === 'red' &&
    css`
      background-color: ${COLORS.redFE};

      > span {
        color: ${COLORS.red100};
      }
    `}

  ${props =>
    props.color === 'blue' &&
    css`
      background-color: ${COLORS.blueD5};

      > span {
        color: ${COLORS.blue200};
      }
    `}
`

const BadgeSpan = styled.span`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  margin: 2px 6px 0 0;

  > span {
    padding: 3px 8px 5px 8px;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.08;
  }

  ${BadgeStyles}
`
