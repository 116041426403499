// @ts-ignore
import { AppSidebarToggler } from '@coreui/react'
import { ODHSpace, ODIcon, ODIcons } from '@mdpp/od-react-belt'
import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import LogoPharmacy from '../../assets/login/bi-mdpp-pharma.png'
import { COLORS } from '../../styles/colors'
import { SiteUrls } from '../../SiteUrls'
import { PharmacyDropdown } from './PharmacyDropdown'
import { usePharmacyAppContext } from '../context/PharmacyAppContext'
interface IPharmacyHeaderProps {}

export const PharmacyHeader: React.FC<IPharmacyHeaderProps> = props => {
  const {
    state: { profile },
  } = usePharmacyAppContext()

  const Pharmacytitle = profile!.name ?? '-'

  return (
    <>
      <Wrapper>
        <div style={{ display: 'flex' }}>
          <AppSidebarToggler className="d-lg-none" display="md" mobile />
          <TitleWrapper>
            <Link to={SiteUrls.Pharmacy.Root} style={{ textDecoration: 'none', display: 'inline-block' }}>
              <img src={LogoPharmacy} className="logo-doctor" alt="Medi PangPang Logo" />
            </Link>
          </TitleWrapper>
        </div>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <PharmacyDropdown />
        </div>
      </Wrapper>
    </>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding: 0 20px;

  @media only screen and (max-width: 360px) {
    padding: 0 10px;
  }
`
const PharmacyWrap = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
  margin-left: 10px;
`

const PharmacyIcon = styled.div`
  font-size: 15px;
  color: ${COLORS.GRAY500};
`

const PharmacyName = styled.span`
  font-size: 12px;
  font-weight: bold;
  color: ${COLORS.GRAY500};
  margin-top: 1px;
  max-height: 40px;
  overflow: hidden;
`

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
`
