import {
  BlockingLoadBox,
  ODButton,
  ODButtonSize,
  ODButtonTheme,
  ODListableContextType,
  ODListablePaginatedTable2,
  ODListablePagination,
  ODListableSearchBox,
  ODVSpace,
  useCounter,
  useModalSelect,
  useODListableContext,
  useWrappedAPI,
} from '@mdpp/od-react-belt'
import React from 'react'
import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components/macro'
import { GQLDoctor, GQLDOCTOR_STATUS } from '@mdpp/common/lib/@types/server.schema'
import { SiteUrls } from '../../../SiteUrls'
import { WebUtils } from '../../../utils/webUtils'
import { useCommonAPIs } from '../../hooks/useCommonAPIs'
import { DoctorPrimaryKey, IDoctorListableOption } from '../DoctorCommon'
import { useDoctorListDataLoader } from './DoctorListDataLoader'
import { EvixCard } from '../../card/EvixCard'
import { PageContent } from '../../page/Page'
import IconMemo from '../../../assets/common/icon-memo.svg'
import { TooltipWrap } from '../../../common/style/ToolTipStyle'
import ReactTooltip from 'react-tooltip'
import { COLORS } from '../../../styles/colors'
import { IsBtnConfirmModal } from '../../layout/IsBtnConfirmModal'
import { ConfirmModal } from '../../layout/ConfirmModal'
import IconWarningBlue from '../../../assets/common/warning-blue-super.svg'
import IconWarningRed from '../../../assets/common/warning-red-super.svg'
import DeleteIcon from '../../../assets/common/icon-del-small.svg'
import IconDownload from '../../../assets/common/icon-down.svg'
import { HoldModal } from './HoldModal'
import { OPEN_STATUS, useIsOpenModal } from './IsOpenModal'

interface IDoctorListableTableProps {
  fromHospitalManager: boolean
}

export const DoctorListableTable: React.FC<IDoctorListableTableProps> = props => {
  const { fromHospitalManager } = props
  const { Provider, Context } = useODListableContext<GQLDoctor, IDoctorListableOption>()
  const [loading, setLoading] = React.useState(false)
  const [token, refresh] = useCounter()
  const dataLoader = useDoctorListDataLoader()
  const { updateDoctor } = useCommonAPIs()
  const [showApproveModal, setShowApproveModal] = React.useState(false)
  const [showHoldModal, setShowHoldModal] = React.useState(false)
  const [showMemoModal, setShowMemoModal] = React.useState(false)
  const [showDeleteInfoModal, setShowDeleteInfoModal] = React.useState(false)
  const [showIsOpenModal, setShowIsOpenModal] = React.useState(false)

  const toolbarStyle = { display: 'flex', justifyContent: 'space-between', marginBottom: 15 }
  const searchBoxStyle = { flex: 1 }
  const wrapperStyle = { display: 'flex', alignItems: 'center', justifyContent: 'center' }

  const handleOpenModal = async () => {
    setShowIsOpenModal(true)
  }

  const chooseOptions = [
    {
      title: '거절',
      description: '',
      value: GQLDOCTOR_STATUS.REJECTED,
    },
    fromHospitalManager
      ? null
      : {
          title: '병원관리자 승인 대기',
          description: '',
          value: GQLDOCTOR_STATUS.PENDING,
        },
    {
      title: '의사',
      description: '',
      value: GQLDOCTOR_STATUS.NORMAL,
    },
  ].filter(v => v !== null)

  const { Component: StatusChooseComponent, choose: chooseStatus, props: statusChooseComponentProps } = useModalSelect({
    okTitle: '저장',
    // @ts-ignore
    selects: chooseOptions,
    title: '의사 권한',
  })

  const { Component: HiddenChooseComponent, choose: chooseHidden, props: hiddenChooseComponentProps } = useModalSelect({
    okTitle: '저장',
    selects: [
      {
        title: '공개',
        description: '',
        value: false,
      },
      {
        title: '비공개',
        description: '',
        value: true,
      },
    ],
    title: '공개 여부',
  })

  const { Component: IsOpenModalComponent, choose: chooseOpen, props: isOpenModalComponentProps } = useIsOpenModal({
    style: { width: 420, height: 466 },
  })

  const updateDoctorStatus = useWrappedAPI(
    (input: { id: number; status: GQLDOCTOR_STATUS }) =>
      updateDoctor({
        id: input.id,
        status: input.status,
      }),
    loading,
    setLoading,
    {
      successMessage: '의사의 권한을 변경하였습니다.',
      onSuccess: refresh,
    }
  )

  const updateDoctorOpenStatus = useWrappedAPI(
    (input: { id: number; smartTreatment: boolean; untactTreatment: boolean }) =>
      updateDoctor({
        id: input.id,
        smartTreatment: input.smartTreatment,
        untactTreatment: input.untactTreatment,
      }),
    loading,
    setLoading,
    {
      successMessage: '의사의 공개여부를 변경하였습니다.',
      onSuccess: refresh,
    }
  )

  const updateDoctorHidden = useWrappedAPI(
    (input: { id: number; isHidden: boolean }) =>
      updateDoctor({
        id: input.id,
        isHidden: input.isHidden,
      }),
    loading,
    setLoading,
    {
      successMessage: '의사의 공개 상태를 변경하였습니다.',
      onSuccess: refresh,
    }
  )

  const handleChangeDoctorStatus = async (v: GQLDoctor) => {
    const status = await chooseStatus(v.status)
    if (status !== null && status !== v.status) {
      await updateDoctorStatus({ id: v.doctorId, status })
    }
  }

  const handleChangeDoctorHidden = async (v: GQLDoctor) => {
    const isHidden = await chooseHidden(v.isHidden)
    if (isHidden !== null && isHidden !== v.isHidden) {
      await updateDoctorHidden({ id: v.doctorId, isHidden })
    }
  }

  const handleIsOpenModal = async (v: GQLDoctor) => {
    const openStatus: OPEN_STATUS | null = await chooseOpen(
      v.smartTreatment,
      v.untactTreatment,
      v.hospital.name,
      v.name
    )
    if (openStatus && openStatus.smartTreatment !== null && openStatus.untactTreatment !== null) {
      await updateDoctorOpenStatus({
        id: v.doctorId,
        smartTreatment: openStatus.smartTreatment,
        untactTreatment: openStatus.untactTreatment,
      })
    }
  }

  return (
    <>
      {/* 스마트/비대면 공개 여부 모달 */}

      {showApproveModal && (
        <IsBtnConfirmModal
          style={{ width: 420, height: 270 }}
          isTitle={true}
          title={'가입을 승인하시겠습니까?'}
          titleIcon={IconWarningBlue}
          titleIconSize={'L'}
          isDescriptionIcon={false}
          description={['김사부 / 서울돌담약국']}
          descriptionIcon={''}
          hideCancel={false}
          BtnLeft={'보류'}
          BtnRight={'승인'}
          onLeftBtnPress={() => setShowHoldModal(true)}
          onRightBtnPress={() => setShowApproveModal(false)}
          onCancelPress={() => setShowApproveModal(false)}
        />
      )}

      {/* 메모 버튼 클릭 시 실행되는 팝업  */}
      {showMemoModal && (
        <>
          <HoldModal
            style={{ width: 420, height: 312 }}
            BtnText={'저장'}
            isEdit={false}
            onOKPress={() => setShowMemoModal(false)}
            onCancelPress={() => setShowMemoModal(false)}
          />
        </>
      )}

      {/* 보류 버튼 클릭 시 실행되는 팝업  */}
      {showHoldModal && (
        <>
          <HoldModal
            style={{ width: 420, height: 312 }}
            BtnText={'확인'}
            isEdit={true}
            onOKPress={() => setShowHoldModal(false)}
            onCancelPress={() => setShowHoldModal(false)}
          />
        </>
      )}

      {/* 삭제 버튼 클릭 시 실행되는 팝업  */}
      {showDeleteInfoModal && (
        <>
          <ConfirmModal
            style={{ width: 420, height: 270 }}
            isTitle={true}
            title={'회원 정보를 삭제 하시겠습니까?'}
            titleIcon={IconWarningRed}
            titleIconSize={'L'}
            isDescriptionIcon={false}
            description={['김사부 / 서울돌담병원']}
            descriptionIcon={''}
            hideCancel={true}
            onOkPress={() => setShowDeleteInfoModal(false)}
            onCancelPress={() => setShowDeleteInfoModal(false)}
          />
        </>
      )}

      <Provider
        dataLoader={dataLoader}
        keyExtractor={v => v[DoctorPrimaryKey].toString()}
        pageSize={10}
        refreshToken={token.toString()}
        onDataLoaderError={WebUtils.showError}
        searchOnLoad
      >
        <BlockingLoadBox show={loading} />
        <StatusChooseComponent {...statusChooseComponentProps} />
        <HiddenChooseComponent {...hiddenChooseComponentProps} />
        <IsOpenModalComponent {...isOpenModalComponentProps} />

        <SearchWrapper style={toolbarStyle}>
          <ODListableSearchBox listableContext={Context} placeholder="의사이름, 병원으로 검색" style={searchBoxStyle} />
          {fromHospitalManager && (
            <Link to={SiteUrls.Manager.Doctor.Create} style={{ textDecoration: 'none' }}>
              <ODButton theme={ODButtonTheme.Primary} size={ODButtonSize.Small} rounded style={{ minWidth: 135 }}>
                의사 추가
                {/*[MWD] 의사 관리 마크업 주석 처리  */}
                {/* 의사 초대 */}
              </ODButton>
            </Link>
          )}

          {!fromHospitalManager && <Download context={Context} />}
        </SearchWrapper>
        <PageContent>
          <EvixCard noPadding>
            <ODListablePaginatedTable2
              numColumns={fromHospitalManager ? 5 : 6}
              listableContext={Context}
              renderHeader={() => (
                <tr>
                  {!fromHospitalManager && (
                    <th style={{ width: 100, whiteSpace: 'nowrap', textAlign: 'center' }}>추천코드</th>
                  )}
                  <th style={{ width: 120, whiteSpace: 'nowrap', textAlign: 'center' }}>의사이름</th>
                  {!fromHospitalManager && (
                    <th style={{ width: 150, whiteSpace: 'nowrap', textAlign: 'center' }}>병원</th>
                  )}
                  <th style={{ textAlign: 'center', whiteSpace: 'nowrap' }}>진료과목</th>
                  <th style={{ width: 70, whiteSpace: 'nowrap', textAlign: 'center' }}>스케줄</th>
                  <th style={{ width: 70, whiteSpace: 'nowrap', textAlign: 'center' }}>권한</th>
                  <th style={{ width: 100, whiteSpace: 'nowrap', textAlign: 'center' }}>공개여부</th>
                  {!fromHospitalManager && (
                    <th style={{ width: 65, whiteSpace: 'nowrap', textAlign: 'center' }}>의사가입</th>
                  )}
                  {!fromHospitalManager && (
                    <th style={{ width: 65, whiteSpace: 'nowrap', textAlign: 'center' }}>환자가입</th>
                  )}
                  {!fromHospitalManager && (
                    <th style={{ width: 75, whiteSpace: 'nowrap', textAlign: 'center' }}>스마트진료</th>
                  )}
                </tr>
              )}
              renderRow={(value: GQLDoctor, context) => (
                <tr key={value[DoctorPrimaryKey]} style={{ borderBottom: '1px solid #D1D5DA' }}>
                  {!fromHospitalManager && (
                    <td align="center" style={{ whiteSpace: 'nowrap' }}>
                      {value.recommendationCode}
                    </td>
                  )}
                  <td align="left" style={{ whiteSpace: 'normal', wordBreak: 'break-all' }}>
                    <Link
                      to={
                        fromHospitalManager
                          ? SiteUrls.Manager.Doctor.Edit(value.doctorId)
                          : SiteUrls.Admin.Doctor.Edit(value.doctorId)
                      }
                    >
                      <BlueClickableText>{value.name}</BlueClickableText>
                    </Link>
                  </td>
                  {!fromHospitalManager && (
                    <td align="left" style={{ whiteSpace: 'normal', wordBreak: 'break-all' }}>
                      {value.hospital.name}
                    </td>
                  )}
                  <td>{value.medicalSubjects.map(v => v.medicalSubject.name).join(',')}</td>
                  <td align="center" style={{ whiteSpace: 'nowrap' }}>
                    <Link
                      to={
                        fromHospitalManager
                          ? SiteUrls.Manager.Doctor.Schedule(value.doctorId)
                          : SiteUrls.Admin.Doctor.Schedule(value.doctorId)
                      }
                    >
                      <BlueClickableText>보기</BlueClickableText>
                    </Link>
                  </td>
                  <td align="center" style={{ whiteSpace: 'nowrap' }}>
                    <BlueClickableText onClick={() => handleChangeDoctorStatus(value)}>
                      {WebUtils.formatDoctorStatus(value.status)}
                    </BlueClickableText>
                  </td>
                  <td align="center" style={{ whiteSpace: 'nowrap' }}>
                    {/* <BlueClickableText onClick={() => handleChangeDoctorHidden(value)}>
                      {value.isHidden ? '비공개' : '공개'}
                    </BlueClickableText> */}
                    <IconBlockWrap>
                      <IconBlock
                        type={value.smartTreatment ? 'smart' : 'private'}
                        onClick={() => handleIsOpenModal(value)}
                      >
                        <span>스마트</span>
                      </IconBlock>
                      <IconBlock
                        type={value.untactTreatment ? 'non-face' : 'private'}
                        onClick={() => handleIsOpenModal(value)}
                      >
                        <span>비대면</span>
                      </IconBlock>
                    </IconBlockWrap>
                  </td>
                  {!fromHospitalManager && (
                    <td align="center" style={{ whiteSpace: 'nowrap' }}>
                      {value?.statInfo?.totalJoinDoctor}
                    </td>
                  )}
                  {!fromHospitalManager && (
                    <td align="center" style={{ whiteSpace: 'nowrap', color: '#1a7fe4' }}>
                      {value?.statInfo?.totalJoinPatient}
                    </td>
                  )}
                  {!fromHospitalManager && (
                    <td align="center" style={{ whiteSpace: 'nowrap', color: '#ff5353' }}>
                      {value?.statInfo?.totalSharedMydata}
                    </td>
                  )}
                </tr>
              )}
              eventParentContext={{}}
            />
          </EvixCard>

          <ODListablePagination hideIfSinglePage={false} listableContext={Context} wrapperStyle={wrapperStyle} />
        </PageContent>
      </Provider>
    </>
  )
}

const BlueClickableText = styled.span`
  color: ${COLORS.blue200};
  cursor: pointer;
  font-weight: 500;
  text-decoration: underline;
`
const SearchWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-bottom: 24px;
  > div > form > div {
    > div {
      border-radius: 8px;

      > div {
        border-radius: 8px;
        background-color: white;
        > span {
          border-radius: 8px;
        }
      }

      > input {
        border-radius: 0px 8px 8px 0px;
      }
    }

    @media (max-width: 992px) {
      width: 290px;
    }
  }
`
const BtnWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
`

const IconDelete = styled.img`
  width: 22px;
  height: 22px;
  border-radius: 4px;
  border: solid 1px ${COLORS.GRAY42};
  overflow: hidden;
  cursor: pointer;
`

const Btn = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 22px;
  border-radius: 4px;
  border: solid 1px ${COLORS.GRAY42};
  text-align: center;

  > span {
    font-size: 13px;
    font-weight: 500;
    color: ${COLORS.GRAY42};
    line-height: 1;
  }
`
const LabelCompleteText = {
  fontSize: 13,
  fontWeight: 500,
  color: '#ababab',
  lineHeight: 1,
}

const LabelHold = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 5px;
  box-shadow: none;

  > a {
    width: 17px;
    height: 21px;
    border: none;
    outline: none;

    > img {
      width: 17px;
      height: 21px;
    }
  }

  > span {
    display: inline-block;
    font-size: 13px;
    font-weight: 500;
    color: ${COLORS.GRAY42};
    line-height: 13px;
    text-decoration: underline;
    margin-top: -2px;
  }
`

interface IconBlockProps {
  children: any
  type: string
  onClick: () => {}
}

const IconBlockWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 3px;
`

const IconBlock: React.FC<IconBlockProps> = props => {
  const { children, type, ...rest } = props
  return (
    <Icon type={type} {...rest}>
      {children}
    </Icon>
  )
}

interface IDownloadProps<T, O> {
  context: React.Context<ODListableContextType<T, O>>
}

const Download: React.FC<IDownloadProps<GQLDoctor, IDoctorListableOption>> = props => {
  const { state, keyExtractor }: ODListableContextType<GQLDoctor, IDoctorListableOption> = React.useContext(
    props.context
  )
  const { list, pages, pageSize, loadOption } = state
  const { listDoctor } = useCommonAPIs()
  return (
    <DownLoadBtn
      onClick={async () => {
        const r = await listDoctor({
          page: 1,
          pageSize: pages * pageSize,
          filter: loadOption?.filter || '',
        })
        const outList = r.list
        const printList: any[] = []
        outList.map((item: GQLDoctor) => {
          const printItem = {
            추천코드: item.recommendationCode,
            의사이름: item.name,
            병원: item.hospital.name,
            의사가입수: item?.statInfo?.totalJoinDoctor || '0',
            환자가입수: item?.statInfo?.totalJoinPatient || '0',
            스마트진료수: item?.statInfo?.totalSharedMydata || '0',
          }
          printList.push(printItem)
        })
        const xlsx = require('xlsx')
        const ws = xlsx.utils.json_to_sheet(printList)
        const wb = xlsx.utils.book_new()
        xlsx.utils.book_append_sheet(wb, ws, 'Sheet1')
        xlsx.writeFile(wb, 'medipangpang_doctors' + (loadOption?.filter ? '_' + loadOption.filter : '') + '.xlsx')
      }}
    >
      <img src={IconDownload} alt="다운로드" />
      <span>데이터 다운로드</span>
    </DownLoadBtn>
  )
}

const iconStyles = css<IconBlockProps>`
  ${props =>
    props.type === 'smart' &&
    css`
      border: 1px solid ${COLORS.blue200};

      > span {
        color: ${COLORS.blue200};
      }
    `}

  ${props =>
    props.type === 'non-face' &&
    css`
      border: 1px solid ${COLORS.red100};

      > span {
        color: ${COLORS.red100};
      }
    `}

    ${props =>
    props.type === 'private' &&
    css`
      background-color: #efefef;
      border: 1px solid #c5c5c5;

      > span {
        color: #c5c5c5;
      }
    `}
`

const Icon = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 21px;
  margin: 0 1.5;
  border-radius: 11px;
  cursor: pointer;

  > span {
    font-size: 12px;
    line-height: 1.08;
    font-weight: 500;
  }

  ${iconStyles}
`

const DownLoadBtn = styled.button`
  height: 37px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap: 7px;
  padding: 0 14px;
  border-radius: 4px;
  border: solid 1px ${COLORS.GRAYAB2};
  background-color: ${COLORS.WHITE};

  > img {
    width: 14px;
    height: 14px;
  }

  span {
    font-size: 13px;
    font-weight: 500;
    line-height: 1;
    color: ${COLORS.GRAY42};
  }
`
