import React from 'react'
import {
  GQLReservation,
  GQLRESERVATION_STATUS,
  GQLUpdateReservationByDoctorInput,
} from '@mdpp/common/lib/@types/server.schema'
import { ReservationBasicInfoBoxStyle } from '../../../../common/reservation/ReservationCommon'
import { PatientDiagnosisInfoCard } from '../../detail/infoCard/PatientDiagnosisInfoCard'
import { useCommonAPIs } from '../../../../common/hooks/useCommonAPIs'
import { useDoctorAPIs } from '../../../context/useDoctorAPIs'
import { IEvixPrescriptionFile } from '../../../../common/EvixPrescriptionFileUpload'
import { IFileObject } from '../../../../common/hooks/useFileMutationAPI'
import { WebUtils } from '../../../../utils/webUtils'
import { DiagNameWithOption } from '../../detail/infoCard/DiagnosisList'

interface IRoomDiagnosisSectionProps {
  reservation: GQLReservation
  onUpdateField: (fieldName: keyof GQLUpdateReservationByDoctorInput, value: string | number) => Promise<void>
  isFinishedMedical?: boolean
}

export const RoomDiagnosisSection: React.FC<IRoomDiagnosisSectionProps> = props => {
  const { reservation, onUpdateField, isFinishedMedical } = props
  const { updateReservationDiagnosis } = useCommonAPIs()
  const apis = useDoctorAPIs()

    const handleUpdateField = async (fieldName: keyof GQLUpdateReservationByDoctorInput, value: string | number) => {
      try {
        await apis.updateReservation({id: reservation.reservationId, [fieldName]: value})
      } catch (e) {
        console.error(e)
      }
    }

    const updateFiles = async (f: IEvixPrescriptionFile[]): Promise<Array<IFileObject | null>> => {
      const results: Array<IFileObject | null> = []
      if (!reservation) {
        return results
      }

      for (const file of f) {
        if (file.isUploading && file.file) {
          try {
            const fileUploaded = await apis.addPrescriptionFile({
              reservationId: reservation.reservationId,
              file: file.file,
            })
            const obj: IFileObject = {
              id: fileUploaded.prescriptionFileId.toString(),
              fileName: fileUploaded.fileName,
              isDeleting: false,
              isUploading: false,
              link: fileUploaded.link,
            }
            results.push(obj)
          } catch (ex) {
            WebUtils.showError(ex)
            results.push(null)
          }
        } else if (file.isDeleting) {
          try {
            await apis.deletePrescriptionFile({id: parseInt(file.id, 10)})
          } catch (ex) {
            WebUtils.showError(ex)
          }
        }
      }
      return results
    }

    const handleFinish = async (selectedDiagNames: DiagNameWithOption[] = [], treatment: string = '', prescriptionFiles: IEvixPrescriptionFile[] = [], saveOnly: boolean = false) => {
      if (!reservation) {
        return
      }

      try {
        await updateReservationDiagnosis({
          id: reservation.reservationId,
          diagnosis: selectedDiagNames.map(dn => ({
            diagNameCode: dn.diagName.code,
            isFinal: dn.isFinal,
          })),
        })
        await handleUpdateField('treatment', treatment)
        await updateFiles(prescriptionFiles)
        WebUtils.showSuccess('진료결과가 저장되었습니다.')
      } catch (e) {
        console.error(e)
        WebUtils.showError('진료결과 저장에 실패했습니다.')
      }
    }

  return (
    <div>
      <PatientDiagnosisInfoCard
        reservation={reservation}
        onUpdateField={onUpdateField}
        infoStyle={ReservationBasicInfoBoxStyle.InsideRoom}
        isFinishedMedical={isFinishedMedical}
        handleFinish={handleFinish}
        isRoom={true}
      />
    </div>
  )
}
