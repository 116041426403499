import React from 'react'
import styled from 'styled-components'
import { GQLReservation } from '@mdpp/common/lib/@types/server.schema'
import { ReservationNoticeLeft } from './ReservationNoticeLeft'
import { ReservationNoticeRight } from './ReservationNoticeRight'

interface IReservationNoticeBoxProps {
  fromManager: boolean
  reservation: GQLReservation
  onClickCancel: () => void
  onClickPatientNoShow: () => void
  onClickFinish: () => void
}

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
  padding: 36px 0;
  gap: 10px;

  @media (min-width: 1024px) {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
`

export const ReservationNoticeBox: React.FC<IReservationNoticeBoxProps> = props => {
  const { reservation, onClickCancel, fromManager } = props

  return (
    <Wrapper>
      <ReservationNoticeLeft reservation={reservation} fromManager={fromManager} />
      <ReservationNoticeRight reservation={reservation} fromManager={fromManager} onClickCancel={onClickCancel} />
    </Wrapper>
  )
}
