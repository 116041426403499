import { ODHSpace, ODIcons, ODVSpace } from '@mdpp/od-react-belt'
import React from 'react'
import { NumberParam, useQueryParam, withDefault } from 'use-query-params'
import { EvixTabBar, ITabBarIcon } from '../../common/EvixTabBar'
import { MainContentWithInset } from '../../common/layout/MainContentWithInset'
import { DoctorProfileDiagnosisPage } from './DoctorProfileDiagnosisPage'
import { DoctorProfileHospital } from './DoctorProfileHospital'
import { DoctorProfilePage } from './DoctorProfilePage'
import { BodyContainer } from '../../common/layout/BodyContainer'
import { Page, PageContainer, PageContent, PageHeader, PageTitle } from '../../common/page/Page'

import styled from 'styled-components'

interface IDoctorMyPageContainerProps {}

const Items: ITabBarIcon[] = [
  {
    icon: ODIcons.Doctor,
    text: '의사정보',
  },
  // { icon: ODIcons.Patient, text: '진료정보' },
  {
    icon: ODIcons.CoreBuilding,
    text: '병원정보',
  },
]

export const DoctorMyPageContainer: React.FC<IDoctorMyPageContainerProps> = props => {
  const [tabRaw, setTab] = useQueryParam('tab', withDefault(NumberParam, 0))
  const tab = Math.min(Math.max(tabRaw, 0), Items.length - 1)

  return (
    <>
      <PageContainer>
        <Page>
          <PageHeader>
            <PageTitle>마이페이지</PageTitle>
          </PageHeader>
          <EvixTabBar items={Items} selectedIndex={tab} onClickTab={setTab} />
          <ODVSpace h={10} />
          <CommentWrap>
            <RedDot />
            <Comment>환자에게 공개되는 정보입니다.</Comment>
          </CommentWrap>
          <ODVSpace h={8} />
          <PageContent>
            {tab === 0 && <DoctorProfilePage fromManager={false} fromAdmin={false} />}
            {tab === 1 && <DoctorProfileHospital />}
          </PageContent>
        </Page>
      </PageContainer>
    </>
  )
}

const CommentWrap = styled.div`
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`
const RedDot = styled.div`
  width: 8px;
  height: 8px;
  background: var(--redActive);
  border-radius: 80px;
`

const Comment = styled.div`
  padding-left: 10px;
  font-size: 13px;
  color: var(--gray600);
`
