import styled from 'styled-components'

export const EvixCardLabel = styled.div`
  min-width: 80px;
  max-width: 100px;
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.3px;
  color: #86919d;
`
