import { IODEntityAPI, useODMutation, useODQuery } from '@mdpp/od-react-belt'
import { DocumentNode } from 'graphql'
import gql from 'graphql-tag'
import React from 'react'
import { GQLEmptyInput, GQLOkResponse, GQLSingleIDInput } from '@mdpp/common/lib/@types/server.schema'

const gqlQueryPlaceholder = gql`
  query placeholderQuery {
    youShouldNotSeeThisInAction
  }
`

const gqlMutationPlaceholder = gql`
  mutation placeholderMutation {
    youShouldNotSeeThisInAction
  }
`

export interface IODEntityAPIOptions<
  SERVER_TYPE extends object,
  CLIENT_TYPE extends object,
  CREATION_INPUT_TYPE,
  UPDATE_INPUT_TYPE
> {
  createGQL?: DocumentNode
  readGQL?: DocumentNode
  readLatestGQL?: DocumentNode
  updateGQL?: DocumentNode
  deleteGQL?: DocumentNode
  primaryKeyName: string // keyof CLIENT_TYPE
  mapServerDataToClientData?: (serverLoadedData: SERVER_TYPE) => CLIENT_TYPE
  mapClientDataToServerCreate?: (creationData: Partial<CLIENT_TYPE>) => CREATION_INPUT_TYPE
  mapClientDataToServerUpdate?: (
    updatedPartial: Partial<CLIENT_TYPE>,
    originalClientData: CLIENT_TYPE
  ) => UPDATE_INPUT_TYPE
}

export function useODEntityAPI<
  SERVER_TYPE extends object,
  CLIENT_TYPE extends object,
  CREATION_INPUT_TYPE,
  UPDATE_INPUT_TYPE
>(
  options: IODEntityAPIOptions<SERVER_TYPE, CLIENT_TYPE, CREATION_INPUT_TYPE, UPDATE_INPUT_TYPE>
): IODEntityAPI<CLIENT_TYPE> {
  const {
    mapServerDataToClientData,
    mapClientDataToServerUpdate,
    mapClientDataToServerCreate,
    primaryKeyName: pk,
  } = options
  const primaryKeyName = pk as keyof CLIENT_TYPE
  const createAPI = useODMutation<CREATION_INPUT_TYPE, SERVER_TYPE>(options.createGQL || gqlMutationPlaceholder)
  const readAPI = useODQuery<GQLSingleIDInput, SERVER_TYPE>(options.readGQL || gqlQueryPlaceholder)
  const readLatestAPI = useODQuery<GQLEmptyInput, SERVER_TYPE>(options.readLatestGQL || gqlQueryPlaceholder)
  const updateAPI = useODMutation<UPDATE_INPUT_TYPE, SERVER_TYPE>(options.updateGQL || gqlMutationPlaceholder)
  const deleteAPI = useODMutation<GQLSingleIDInput, GQLOkResponse>(options.deleteGQL || gqlMutationPlaceholder)

  const createEntityAPI = React.useCallback(() => {
    const api: IODEntityAPI<CLIENT_TYPE> = {
      create: options.createGQL
        ? async (clientValue: Partial<CLIENT_TYPE>): Promise<CLIENT_TYPE> => {
            const { [primaryKeyName]: idToIgnore, ...values } = clientValue

            const createInput: CREATION_INPUT_TYPE = mapClientDataToServerCreate
              ? mapClientDataToServerCreate(values as Partial<CLIENT_TYPE>)
              : (values as CREATION_INPUT_TYPE)

            const serverData = await createAPI(createInput)
            if (mapServerDataToClientData) {
              return mapServerDataToClientData(serverData)
            }

            // default mapServerDataToClientData
            return (serverData as unknown) as CLIENT_TYPE
          }
        : undefined,
      load: options.readGQL
        ? async (id: number): Promise<CLIENT_TYPE> => {
            const serverData = await readAPI({ id })
            if (mapServerDataToClientData) {
              return mapServerDataToClientData(serverData)
            }

            return (serverData as unknown) as CLIENT_TYPE
          }
        : undefined,
      loadLatest: options.readLatestGQL
        ? async (): Promise<CLIENT_TYPE> => {
            const serverData = await readLatestAPI({ ignoreMe: null })
            if (mapServerDataToClientData) {
              return mapServerDataToClientData(serverData)
            }

            return (serverData as unknown) as CLIENT_TYPE
          }
        : undefined,
      update: options.updateGQL
        ? async (changedClientValue, originalClientValue): Promise<CLIENT_TYPE> => {
            if (!options.readLatestGQL) {
              const id = originalClientValue[primaryKeyName]
              const updateInput: UPDATE_INPUT_TYPE = mapClientDataToServerUpdate
                ? mapClientDataToServerUpdate(changedClientValue, originalClientValue)
                : (changedClientValue as UPDATE_INPUT_TYPE)

              const serverData = await updateAPI({ id, ...updateInput })
              if (mapServerDataToClientData) {
                return mapServerDataToClientData(serverData)
              }

              return (serverData as unknown) as CLIENT_TYPE
            } else {
              // singleton style
              const updateInput: UPDATE_INPUT_TYPE = mapClientDataToServerUpdate
                ? mapClientDataToServerUpdate(changedClientValue, originalClientValue)
                : (changedClientValue as UPDATE_INPUT_TYPE)

              const serverData = await updateAPI(updateInput)
              if (mapServerDataToClientData) {
                return mapServerDataToClientData(serverData)
              }

              return (serverData as unknown) as CLIENT_TYPE
            }
          }
        : undefined,
      delete: options.deleteGQL
        ? async (loadedClientValue: CLIENT_TYPE) => {
            const id = (loadedClientValue[primaryKeyName] as unknown) as number
            await deleteAPI({ id })
          }
        : undefined,
    }
    return api
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    primaryKeyName,
    createAPI,
    readAPI,
    updateAPI,
    deleteAPI,
    mapClientDataToServerCreate,
    mapServerDataToClientData,
    mapClientDataToServerUpdate,
  ])

  const [apis] = React.useState(() => {
    return createEntityAPI()
  })

  return apis
}
