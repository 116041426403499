import React from 'react'
import { Card, CardBody } from 'reactstrap'
import styled from "styled-components";

interface IEvixCardProps {
  style?: React.CSSProperties
  fit?: boolean // 카드의 디폴트 flexGrow = 2 인데, 이를 0 으로 함.
  outline? : boolean
  noPadding?: boolean
}

const cardStyle = {
  display: 'flex',
  minWidth: 700,
  background: '#FFFFFF',
  boxShadow: '0px 0px 5px rgba(23, 24, 24, 0.05), 0px 1px 2px rgba(0, 0, 0, 0.15)',
  borderRadius: 8,
  border: 0,
}
export const EvixCardWithTableWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-top: 8px;
`
export const EvixCard: React.FC<IEvixCardProps> = props => {
  return (
    <Card style={{flexGrow: props.fit ? 0 : 2, ...(props.style || cardStyle) }}>
      <CardBody style={{padding: props.noPadding? 0 : 20}}>{props.children}</CardBody>
    </Card>
  )
}
