import React from 'react'
import { MainContentWithInset } from '../../common/layout/MainContentWithInset'
import { NoticeEditPage } from '../../common/notice/edit/NoticeEditPage'
import {Page, PageContainer, PageContent, PageHeader, PageTitle} from "../../common/page/Page";

interface IAdminNoticeEditPageProps {
  idEditing: number | null
}

export const AdminNoticeEditPage: React.FC<IAdminNoticeEditPageProps> = props => {
  return (
    <PageContainer>
        <Page>
            <PageHeader>
                <PageTitle>공지사항</PageTitle>
            </PageHeader>
            <PageContent>
                <NoticeEditPage idEditing={props.idEditing} />
            </PageContent>
        </Page>
    </PageContainer>
  )
}
