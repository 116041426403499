import { BlockingLoadBox } from '@mdpp/od-react-belt'
import React from 'react'
import { CardBody } from 'reactstrap'
import { DoctorProfileDiagnosisEdit } from '../../common/doctor/detail/DoctorProfileDiagnosisEdit'
import { useODDoctorAppContext } from '../context/ODDoctorAppContext'
import { useDoctorAPIs } from '../context/useDoctorAPIs'
import { EvixCard } from '../../common/card/EvixCard'

interface IDoctorProfileDiagnosisPageProps {}

export const DoctorProfileDiagnosisPage: React.FC<IDoctorProfileDiagnosisPageProps> = props => {
  const {
    state: { profile: initProfile },
    refreshProfile,
  } = useODDoctorAppContext()

  const [profile, setProfile] = React.useState(initProfile)

  const { upsertDoctorHasMedicalSubject, updateDoctorProfile, getDoctorProfile } = useDoctorAPIs()

  if (!profile) {
    return <BlockingLoadBox show />
  }

  const updateDoctorData = async ({
    medicalSubjectIds,
    profession,
    availableFirstVisit,
    untactTreatment,
  }: {
    medicalSubjectIds: number[]
    profession: string
    availableFirstVisit: boolean
    untactTreatment: boolean
  }): Promise<void> => {
    await upsertDoctorHasMedicalSubject({ medicalSubjectIds })
    if (profile || availableFirstVisit) {
      const newProfile = await updateDoctorProfile({
        id: profile.doctorId,
        profession,
        availableFirstVisit,
        untactTreatment,
      })
      setProfile(newProfile) // 현재 페이지를 업데이트 한다.
      await refreshProfile() // context를 refresh 한다.
    }
  }
  const cardStyle = {
    display: 'flex',
    minWidth: 700,
    background: '#FFFFFF',
    boxShadow: '0px 0px 5px rgba(23, 24, 24, 0.05), 0px 1px 2px rgba(0, 0, 0, 0.15)',
    borderRadius: 8,
    border: 0,
  }
  return (
    <EvixCard style={cardStyle} noPadding>
      <DoctorProfileDiagnosisEdit profile={profile} updateData={updateDoctorData} />
    </EvixCard>
  )
}
