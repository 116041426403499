import React from 'react'
import { ScheduleSetupPage } from '../../common/schedule/setting/ScheduleSetupPage'
import {EvixCard} from "../../common/card/EvixCard";

interface IAdminDoctorScheduleSetupPageProps {
  doctorId: number
}

export const AdminDoctorScheduleSetupPage: React.FC<IAdminDoctorScheduleSetupPageProps> = props => {
  const { doctorId } = props

  return (
  
      <ScheduleSetupPage idEditing={doctorId} />

  )
}
