import { ODListableResponseType, ODModalSize, useODQuery, usePickerTableModal } from '@mdpp/od-react-belt'
import React from 'react'
import { GQLListablePharmacy, GQLListablePharmacyForHospitalInput, GQLPharmacy } from '@mdpp/common/lib/@types/server.schema'
import { GQL_LIST_PHARMACY_FOR_HOSPITAL } from '../../common/gqls_common'
import { IPharmacyListableOption } from '../../common/pharmacy/PharmacyCommon'

const THeads = ['약국이름', '주소', '택배여부', '연락처', 'FAX']

export function usePharmacyHasHospitalModal(hospitalId: number) {
  const listApi = useODQuery<Partial<GQLListablePharmacyForHospitalInput>, GQLListablePharmacy>(
    GQL_LIST_PHARMACY_FOR_HOSPITAL
  )

  const dataLoader = React.useCallback(
    async function dataLoader(
      page: number,
      pageSize: number,
      afterKey: string | null,
      options: IPharmacyListableOption
    ): Promise<ODListableResponseType<GQLPharmacy>> {
      const r = await listApi({
        page,
        pageSize,
        hospitalId,
        ...options,
      })
      return r as ODListableResponseType<GQLPharmacy>
    },
    [listApi, hospitalId]
  )

  return usePickerTableModal<GQLPharmacy, IPharmacyListableOption>({
    size: ODModalSize.XLarge,
    rounded: false,
    pickDefaultOptions: {
      confirmButtonTitle: '저장',
      title: '약국 추가',
    },
    dataLoader,
    keyExtractor: v => v.pharmacyId,
    numColumns: THeads.length,
    renderTH: (index: number) => THeads[index],
    renderTD: (index: number, value) => {
      switch (index) {
        case 0:
          return value.name
        case 1:
          return value.address ?? '-'
        case 2:
          return value.availableDelivery?'가능':'불가능'
        case 3:
          return value.telephone ?? '-'
        case 4:
          return value.fax ?? '-'
        default:
          return '-'
      }
    },
  })
}
