import { ODRoute } from '@mdpp/od-react-belt'
import React from 'react'
import { NoticeItemPage } from '../common/notice/list/NoticeItemPage'
import { NoticeListableTable } from '../common/notice/list/NoticeListableTable'
import { NoticeFilter } from '../common/notice/NoticeCommon'
import { ReservationDetailPage } from '../doctor/reservation/detail/ReservationDetailPage'
import { SiteUrls } from '../SiteUrls'
import { ManagerDashboardContainer } from './dashboard/ManagerDashboardContainer'
import { ManagerDoctorDetailPageContainer } from './doctor/detail/ManagerDoctorDetailPage'
import { ManagerDoctorCreatePage } from './doctor/ManagerDoctorCreatePage'
import { ManagerDoctorListPage } from './doctor/ManagerDoctorListPage'
import { ManagerHospitalListPage } from './hospital/ManagerHopsitalListPage'
import { ManagerHospitalEditPage } from './hospital/ManagerHospitalEditPage'
import { ManagerPharmacyHasHospitalListPage } from './hospitalPharmacy/ManagerPharmacyHasHospitalListPage'
import { ManagerInquiryModal } from './inquiry/ManagerInquiryModal'
import { ManagerReservationListPage } from './reservation/ManagerReservationListPage'
import { ManagerDoctorScheduleSetupPage } from './schedule/ManagerDoctorScheduleSetupPage'
import { ManagerDoctorScheduleTablePage } from './schedule/ManagerDoctorScheduleTablePage'
import { ManagerSharedDataTable } from '../mydata/list/ManagerSharedDataTable'
import { SharedDataView } from '../mydata/SharedDataView'

// https://github.com/ReactTraining/react-router/tree/master/packages/react-router-config
export const ManagerRoutes: ODRoute[] = [
  { path: SiteUrls.Manager.Dashboard.Main, exact: true, name: `대시보드`, component: ManagerDashboardContainer },
  {
    path: SiteUrls.Manager.Hospital.Pharmacy,
    exact: true,
    name: `약국 관리`,
    render: (props: any) => <ManagerPharmacyHasHospitalListPage />,
  },
  { path: SiteUrls.Manager.Hospital.Main, exact: true, name: `병원관리`, component: ManagerHospitalListPage },
  { path: SiteUrls.Manager.Hospital.Edit, exact: true, name: `수정`, component: ManagerHospitalEditPage },
  { path: SiteUrls.Manager.Doctor.Main, exact: true, name: `의사관리`, component: ManagerDoctorListPage },
  {
    path: SiteUrls.Manager.Doctor.Create,
    exact: true,
    name: `추가`,
    render: (props: any) => <ManagerDoctorCreatePage />,
  },
  {
    path: SiteUrls.Manager.Doctor.Edit(':id'),
    exact: true,
    name: `수정`,
    render: (props: any) => <ManagerDoctorDetailPageContainer doctorId={parseInt(props.match.params.id, 10)} />,
  },
  {
    path: SiteUrls.Manager.Doctor.SetupScheduleDefault(':id'),
    exact: true,
    name: `설정`,
    render: (props: any) => <ManagerDoctorScheduleSetupPage doctorId={parseInt(props.match.params.id, 10)} />,
  },
  {
    path: SiteUrls.Manager.Doctor.Schedule(':id'),
    exact: true,
    name: `스케줄`,
    render: (props: any) => <ManagerDoctorScheduleTablePage doctorId={parseInt(props.match.params.id, 10)} />,
  },
  { path: SiteUrls.Manager.Reservation.Main, exact: true, name: `진료`, component: ManagerReservationListPage },
  {
    path: SiteUrls.Manager.Reservation.Detail(':id'),
    exact: false,
    name: `진료상세`,
    render: () => <ReservationDetailPage fromHospitalManager />,
  },
  {
    path: SiteUrls.Manager.Notice.Main,
    exact: true,
    name: `공지사항`,
    render: (props: any) => <NoticeListableTable filter={NoticeFilter.HospitalManager} />,
  },
  {
    path: SiteUrls.Manager.Notice.Item(':id'),
    exact: true,
    name: `상세보기`,
    render: (props: any) => <NoticeItemPage id={props.match.params.id} />,
  },
  { path: SiteUrls.Manager.Inquiry.Main, exact: true, name: `문의하기`, component: ManagerInquiryModal },
  { path: SiteUrls.Manager.MyData.Main, exact: true, name: `스마트 진료지원`, component: ManagerSharedDataTable },
  {
    path: SiteUrls.Manager.MyData.Item(':url', ':dsId'),
    exact: true,
    name: `상세보기`,
    render: (props: any) => <SharedDataView url={props.match.params.url} temporary={false} dsId={props.match.params.dsId}/>
  }
]
