import { ODIcon, ODIcons } from '@mdpp/od-react-belt'
import React from 'react'
import { usePopper } from 'react-popper'
import styled from 'styled-components'
import { EvixPopOverMenu, IEvixPopOverMenuItem } from '../../../../common/EvixPopOverMenu'
import { COLORS } from '../../../../styles/colors'
import { useEvixRoom } from '../context/EvixRoomContext'

interface IRoomVideoBottomBarProps {
  onScreenSharingOptionChanged: (shareScreen: boolean) => void
  onFullscreenClicked: (enter: boolean) => void
  toggleAudioMute: () => void
  toggleVideoMute: () => void
  onClickFinish: () => void
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  height: 78px;
  background: #383D46;
  border-radius: 16px;
  width: 416px;
  margin: 20px auto 0px auto;
  color: color: rgba(255, 255, 255, 0.5);
`

const ItemWithIcon = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 62px;
  height: 62px;
  font-size: 20px;
  cursor: pointer;

  &: hover {
    background: rgba(255, 255, 255, 0.1);
    border-radius: 8px;
  }
`

const IconWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  // border-radius: 9999px;
  color: white;
  opacity: 0.5;

  :hover {
    // background-color: var(--gray300);
    // opacity: 0.1;
  }
`

const Space = styled.div`
  width: 30px;
`

const ItemText = styled.span`
  font-size: 11px;
  color: white;
  opacity: 0.5;
  text-align: center;
`

const OnBadge = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 0;
  top: 0;
  width: 13px;
  height: 13px;
  border-radius: 17px;
  background-color: var(--danger);
  font-size: 6px;
  font-weight: bold;
  line-height: 1;
  color: var(--white);
`

const ShareMenus: IEvixPopOverMenuItem[] = [
  { icon: ODIcons.MaterialShareScreen, text: '화면공유', key: 'ShareScreen', color: COLORS.GRAY800 },
]

const ShareMenusOff: IEvixPopOverMenuItem[] = [
  { icon: ODIcons.MaterialStopScreenShare, text: '화면공유 중지', key: 'StopShareScreen', color: 'red' },
]

export const RoomVideoBottomBar: React.FC<IRoomVideoBottomBarProps> = props => {
  const { onScreenSharingOptionChanged, toggleAudioMute, toggleVideoMute, onClickFinish, onFullscreenClicked } = props
  const { isMicOn, isFullScreenOn, isShareScreenOn, isVideoOn } = useEvixRoom()

  const [referenceElement, setReferenceElement] = React.useState<any>(null)
  const [popperElement, setPopperElement] = React.useState<any>(null)
  const [showSharePopOver, setShowSharePopOver] = React.useState(false)
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'top-start',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [15, -10],
        },
      },
    ],
  })

  const handleSharePopOverMenu = (key: string) => {
    if (key === 'ShareScreen') {
      onScreenSharingOptionChanged(true)
      // setShareScreenOnOff(true)
    } else {
      onScreenSharingOptionChanged(false)
      // setShareScreenOnOff(false)
    }
    setShowSharePopOver(false)
  }

  return (
    <Wrapper>
      <ItemWithIcon onClick={toggleAudioMute}>
        <IconWrapper>
          <ODIcon icon={isMicOn ? ODIcons.MaterialMic : ODIcons.MaterialMicOff} />
        </IconWrapper>
        <ItemText style={{ marginTop: 5 }}>마이크</ItemText>
      </ItemWithIcon>
      <ItemWithIcon onClick={toggleVideoMute}>
        <IconWrapper>
          <ODIcon icon={isVideoOn ? ODIcons.MaterialVideoCam : ODIcons.MaterialVideoCamOff} />
        </IconWrapper>
        <ItemText style={{ marginTop: 5 }}>카메라</ItemText>
      </ItemWithIcon>
      <ItemWithIcon onClick={() => setShowSharePopOver(!showSharePopOver)} ref={setReferenceElement}>
        <IconWrapper style={showSharePopOver ? { backgroundColor: COLORS.GRAY300 } : {}}>
          <ODIcon icon={ODIcons.MaterialShareScreen} />
          {isShareScreenOn && <OnBadge>ON</OnBadge>}
        </IconWrapper>
        <ItemText style={{ marginTop: 5 }}>화면공유</ItemText>
      </ItemWithIcon>
      <ItemWithIcon onClick={() => onFullscreenClicked(!isFullScreenOn)}>
        <IconWrapper>
          <ODIcon icon={isFullScreenOn ? ODIcons.MaterialFullscreenExit : ODIcons.MaterialFullscreen} />
        </IconWrapper>
        <ItemText style={{ marginTop: 5 }}>{isFullScreenOn ? '전체화면 종료' : '전체화면'}</ItemText>
      </ItemWithIcon>
      {/* <Space></Space> */}
      {/* <ItemWithIcon onClick={() => onClickFinish()}>
        <IconWrapper>
          <ODIcon icon={ODIcons.MaterialExitToApp} />
        </IconWrapper>
        <ItemText style={{ marginTop: 5 }}>나가기</ItemText>
      </ItemWithIcon> */}
      <EvixPopOverMenu
        ref={setPopperElement}
        style={styles.popper}
        {...attributes.popper}
        menus={isShareScreenOn ? ShareMenusOff : ShareMenus}
        onClickMenu={handleSharePopOverMenu}
        show={showSharePopOver}
      />
    </Wrapper>
  )
}
