import { ODHSpace } from '@mdpp/od-react-belt'
import React from 'react'
import styled from 'styled-components'
import { TableList } from '../../../common/list/TableList'
import { EvixButton, EvixButtonTheme } from '../../../common/EvixButton'
import { RefuseDispenseModal } from './RefuseDispenseModal'
import {
  GQLMEDICATION_DISPENSING_STATUS,
  GQLMedicationDispensing,
  GQLRESERVATION_STATUS,
} from '@mdpp/common/lib/@types/server.schema'
import { ReservationBasicInfoBoxStyle } from '../../../common/reservation/ReservationCommon'
import { WebUtils } from '../../../utils/webUtils'
import { usePharmacyAPIs } from '../../context/usePharmacyAPIs'
import { RESET_ACCOUNT_TYPE } from '@mdpp/common'
import { SiteUrls } from '../../../SiteUrls'
import { TitleWrap, SubTitle } from '../../../common/style/CommonStyle'
import { addressParser } from '../../../utils/webUtils'
import { saveAs } from 'file-saver'

interface IPrescriptionConfirmProps {
  reservation: GQLMedicationDispensing
  infoStyle?: ReservationBasicInfoBoxStyle
  isFinishedMedical?: boolean
  id?: number
  handleFinish?: ((isAccepted: any) => void) | null | undefined
  handleNotFinish?: ((status: boolean) => void) | null | undefined
  reload?: boolean
}

export const PrescriptionConfirm: React.FC<IPrescriptionConfirmProps> = props => {
  const { reservation, infoStyle, isFinishedMedical, handleFinish, handleNotFinish, reload } = props
  const [refresh, setRefresh] = React.useState(reload)
  const isAccepted = reservation?.status === GQLMEDICATION_DISPENSING_STATUS.ACCEPTED

  React.useEffect(() => {
    if (reload !== refresh) {
      setRefresh(reload)
    }
  }, [reservation])

  const onDownloadMediFile = (fileLink: string, patientName: string) => {
    saveAs(fileLink, `${patientName}님_처방전`)
    return WebUtils.showSuccess('처방전이 다운로드 되었습니다.')
  }

  return (
    <>
      <Wrapper>
        <TitleWrap>
          <SubTitle>처방전 확인</SubTitle>
        </TitleWrap>
        <ColumnWrap>
          <TableList name="patientName" label="환자명" labelMDSize={5}>
            <ValueText>
              {reservation?.reservation?.proxyReservationInfo
                ? reservation?.reservation?.proxyReservationInfo.name!
                : reservation?.reservation?.patient.name}
              {reservation?.agreedReplacement === true ? (
                <TextAreaHelpText>(동일 성분 대체 조제에 동의합니다.)</TextAreaHelpText>
              ) : (
                <TextAreaHelpText></TextAreaHelpText>
              )}
            </ValueText>
          </TableList>

          <TableList name="address" label="주소" labelMDSize={5}>
            <ValueText>{addressParser(reservation?.patientAddressJson!)}</ValueText>
          </TableList>
        </ColumnWrap>

        <ColumnWrap>
          <TableList name="date" label="진료일시" labelMDSize={5}>
            <ValueText>{WebUtils.formatReservationTime(reservation?.reservation?.reservationTime)}</ValueText>
          </TableList>

          <TableList name="hospital" label="진료받은 병원" labelMDSize={5}>
            <ValueText>{reservation?.reservation?.hospital.name}</ValueText>
          </TableList>
        </ColumnWrap>

        <TableList name="prescription" label="처방전" labelMDSize={5}>
          <PrescriptionWrap>
            {' '}
            <iframe
              width={'100%'}
              height={'100%'}
              src={
                reservation?.reservation?.prescriptionFiles[0]?.link
                  ? `${reservation?.reservation?.prescriptionFiles[0]?.link}#toolbar=0`
                  : ''
              }
            />
          </PrescriptionWrap>
        </TableList>

        {reservation && (
          <ButtonWrap>
            {reservation?.reservation?.prescriptionFiles[0]?.link ? (
              <EvixButton
                disabled={false}
                theme={EvixButtonTheme.H48WhiteInline}
                onClick={() =>
                  onDownloadMediFile(
                    reservation?.reservation?.prescriptionFiles[0].link!,
                    reservation?.reservation?.proxyReservationInfo
                      ? reservation.reservation?.proxyReservationInfo.name!
                      : reservation?.reservation?.patient.name!
                  )
                }
              >
                처방전 다운로드
              </EvixButton>
            ) : (
              <div />
            )}
            <div>
              <EvixButton
                theme={EvixButtonTheme.H48BlueInline}
                onClick={() => {
                  if (handleFinish) {
                    handleFinish(isAccepted)
                  }
                }}
                disabled={reservation?.status !== 'REQUESTED' ? true : false}
              >
                조제 승인
              </EvixButton>
              <ODHSpace w={10} />
              <EvixButton
                theme={EvixButtonTheme.H48WhiteInline}
                onClick={() => {
                  if (handleNotFinish) {
                    handleNotFinish(true)
                  }
                }}
                disabled={reservation?.status !== 'REQUESTED' ? true : false}
              >
                조제 거절
              </EvixButton>
            </div>
          </ButtonWrap>
        )}
      </Wrapper>
    </>
  )
}

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 10px;
  gap: 32px;
`

const TextAreaHelpText = styled.div`
  display: inline-block;
  font-size: 12px;
  line-height: 1.17;
  color: var(--gray600);
  padding-left: 5px;
`
const ColumnWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 32px;

  > div {
    width: 50%;
  }
`

const ValueText = styled.div`
  font-size: 15px;
  color: #424242;
  white-space: nowrap;
`

const PrescriptionWrap = styled.div`
  border: 1px solid #ddd;
  width: 100%;
  height: 500px;
  overflow: auto;
  padding: 10px;
  background-color: #f4f6f8;
`

const ButtonWrap = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;

  > div {
    display: inline-flex;
    flex-direction: row;
    justify-content: flex-end;
  }
`
