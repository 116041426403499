import { ODListableOption } from '@mdpp/od-react-belt'
import { GQLNotice } from '@mdpp/common/lib/@types/server.schema'

export const NoticePrimaryKey = 'noticeId'

// modifiable fields only.
export interface ICTNotice
  extends Pick<
    GQLNotice,
    'noticeId' | 'title' | 'contents' | 'showOnApp' | 'showOnDoctor' | 'showOnHospitalManager' | 'link'
  > {
  // more fields, mapped (if needed)
}

export interface INoticeListableOption extends ODListableOption {
  // filter: string
}

export enum NoticeFilter {
  Doctor,
  HospitalManager,
  EvixAdmin,
}
