import { IODListableEventContext, ODListableOption } from '@mdpp/od-react-belt'
import { GQLAppVersion } from '@mdpp/common/lib/@types/server.schema'

export const AppVersionPrimaryKey = 'appVersionId'

// modifiable fields only.
export interface ICTAppVersion
  extends Pick<
    GQLAppVersion,
    'appVersionId' | 'isAndroid' | 'isIOS' | 'description' | 'updateType' | 'build' | 'version'
  > {
  // more fields, mapped (if needed)
}

export interface IAppVersionListableOption extends ODListableOption {
  filter?: string | null
}

export interface IAppVersionEntityContext extends IODListableEventContext<GQLAppVersion> {
  //
}
