import { ODIcon, ODIcons, ODModal, ODModalSize, ODHSpace, ODHSpread } from '@mdpp/od-react-belt'
import React from 'react'
import styled from 'styled-components/macro'
import { GQLReservation } from '@mdpp/common/lib/@types/server.schema'
import { Button, BP } from '../RebeccaButton'

const DELAY_TYPE = {
  SHORT: '5분 미만',
  MIDDLE: '5분 ~15분',
  LONG: '15분 이상'
}

interface IReservationDelayPopupProps {
  isOpen: boolean
  onClose: () => void
  onSubmit: (delay: string) => void
  reservation: GQLReservation
}

export const ReservationDelayPopup: React.FC<IReservationDelayPopupProps> = props => {
  const { isOpen, onClose, onSubmit, reservation } = props

  const [delay, setDelay] = React.useState(DELAY_TYPE.SHORT)

  const selectDuration = (dur: string) => {
    setDelay(dur)
  }

  return (
    <>
      <ODModal rounded={true} isOpen={isOpen} toggle={onClose} size={ODModalSize.Normal}>
        <ODHSpread style={{ padding: 20, alignItems: 'center', borderBottom: '1px solid #E1E3E5' }}>
          <ModalTitle>딜레이 알림</ModalTitle>
          <CloseButtonBox onClick={onClose}>
            <ODIcon icon={ODIcons.CoreX} style={CloseIconStyle} />
          </CloseButtonBox>
        </ODHSpread>
        <Wrapper>
          {/* <Separator /> */}
          <Inner>
            접수 후 대기중인 환자에게 예상 대기시간을 알려주세요
          </Inner>
            <RadioGroup>
              <RadioRow>
                <RadioInput type="radio" name="delay" onChange={() => (selectDuration(DELAY_TYPE.SHORT))} checked={delay === DELAY_TYPE.SHORT} />
                <RadioLabel>5분 미만</RadioLabel>
              </RadioRow>
              <RadioRow>
                <RadioInput type="radio" name="delay" onChange={() => (selectDuration(DELAY_TYPE.MIDDLE))}/>
                <RadioLabel>5분 ~15분</RadioLabel>
              </RadioRow>
              <RadioRow>
                <RadioInput type="radio" name="delay" onChange={() => (selectDuration(DELAY_TYPE.LONG))}/>
                <RadioLabel>15분 이상</RadioLabel>
              </RadioRow>
            </RadioGroup>
          <Footer style={{borderTop: '1px solid #E1E3E5'}}>
            <FooterGroupRight>
              <Button size={BP.SIZE.M} onClick={(e: any) => {
                e.preventDefault()
                onClose()
              }}
                style={{width: 68, height: 36}}
              >취소</Button>
              <ODHSpace w={8} />
              <Button size={BP.SIZE.M} type={BP.TYPE.Primary} onClick={(e: any) => {
                  e.preventDefault()
                  onSubmit(delay)}
                }
                disabled={delay.length === 0}
              > 보내기
              </Button>
            </FooterGroupRight>
          </Footer>
        </Wrapper>
      </ODModal>
    </>
  )
}

const RadioGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 139px;
  margin-left: 20px;
`

const RadioRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  height: 41px;
`

const RadioInput = styled.input`
  margin-right: 5px;
  width: 24px;
  height: 24px;
  background: white;
  border: 1.5px solid rgba(115, 129, 143, 0.26);
  box-sizing: border-box;
`

const RadioLabel = styled.span`
  color: #131415;
  font-size: 14px;
  margin-left: 8px;
`

const CloseIconStyle = {
  fontSize: 18,
  color: '#dbdbdb',
}
const Wrapper = styled.div`
  background-color: #ffffff;
  position: relative;
`
const Inner = styled.div`
  display: flex;
  justify-content: flex-start;
  padding: 20px;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.3px;
  color: #202223;
`

const ModalTitle = styled.h1`
  font-size: 20px;
  font-weight: 500;
  text-align: left;
  margin: 0;
`

const CloseButtonBox = styled.div`
  right: 25px;
  cursor: pointer;
`

const Footer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
`

const FooterGroupRight = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  border-top: 1px solid #E1E3E5;
`
