import React from 'react'
import styled from 'styled-components'
import { useODQuery, ODHSpace } from '@mdpp/od-react-belt'

import { GQLReservation, GQLUpdateReservationByDoctorInput, GQLGetChatRoomHasReservationInput, GQLChatRoom } from '@mdpp/common/lib/@types/server.schema'
import { GQL_GET_CHATROOM_HAS_RESERVATION } from '../../../common/gqls_common'
import { ReservationBasicInfoBoxStyle } from '../../../common/reservation/ReservationCommon'
import { PatientDiagnosisInfoCard } from './infoCard/PatientDiagnosisInfoCard'
import { EvixCardOneThirds } from '../../../common/card/EvixCardOneThirds'
import { ReservationChatCard } from './infoCard/ReservationChatCard'

interface IReservationDiagnosisInfoBoxProps {
  reservation: GQLReservation
  fromManager: boolean
  onUpdateField: (fieldName: keyof GQLUpdateReservationByDoctorInput, value: string | number) => Promise<void>
  handleFinish: (() => void) | null | undefined
  reload: boolean
}

const cardRight = {
  display: 'flex',
  background: '#FFFFFF',
  boxShadow: '0px 0px 5px rgba(23, 24, 24, 0.05), 0px 1px 2px rgba(0, 0, 0, 0.15)',
  borderRadius: 8,
  border: 0,
}

export const ReservationDiagnosisInfoBox: React.FC<IReservationDiagnosisInfoBoxProps> = props => {
  const { reservation, fromManager = false, onUpdateField, handleFinish, reload } = props
  const isFinished = reservation.status !== 'IN_PROGRESS'

  const apiGetChatRoomId = useODQuery<GQLGetChatRoomHasReservationInput, GQLChatRoom>(GQL_GET_CHATROOM_HAS_RESERVATION)

  const [chatRoomId, setChatRoomId] = React.useState<number|null>(null)
  React.useEffect(() => {
    if (!fromManager) {
      (async () => {
        try {
          const result = await apiGetChatRoomId({ reservationId: reservation.reservationId })
          setChatRoomId(result.chatRoomId)
        } catch (e) {
          console.log('no chatroom')
        }
      })()
    }
  }, [])

  return (
    <BodyContainer>
      <PatientDiagnosisInfoCard
        reservation={reservation}
        // infoStyle={ReservationBasicInfoBoxStyle.OutsideRoom}
        infoStyle={ReservationBasicInfoBoxStyle.InsideRoom}
        onUpdateField={onUpdateField}
        isFinishedMedical={isFinished}
        handleFinish={handleFinish}
        isRoom={false}
        reload={reload}
      />
      
      {!fromManager && (
        <>
          <ODHSpace w={24} />
          <EvixCardOneThirds
          style={cardRight}
          >
            <ReservationChatCard reservation={reservation} chatRoomId={chatRoomId} />
          </EvixCardOneThirds>
        </>
      )}
    </BodyContainer>
  )
}

const BodyContainer = styled.div`
  width: 100%;
  height: calc(100% - 36px);
  flex-direction: row;
  display: flex;
  justify-content: center;

  > div.card:last-child {
    padding: 0 !important;

    > .card-body {
      padding-right: 10px;
    }
  }
`
