import { ODIcon, ODIcons } from '@mdpp/od-react-belt'
import React from 'react'
import styled from 'styled-components'

import { COLORS } from '../styles/colors'

interface IEvixFileLinkProps {
  name: string
  link: string
  isDelete?: boolean
}

const Wrapper = styled.div`
  padding: 0 8px;
`

const Text = styled.span`
  font-size: 14px;
  color: #0085FF;
}
`

export const EvixFileLink: React.FC<IEvixFileLinkProps & React.HTMLAttributes<HTMLDivElement>> = props => {
  const { name, link, isDelete, ...others } = props
  return (
    <Wrapper {...others}>
      {link && !isDelete && (
        <a href={link} target="_blank" rel="noopener noreferrer">
          {/*<ODIcon icon={ODIcons.CoreFile} style={{ position: 'relative', top: 1 }} />*/}
        <Text>{name}</Text>
        </a>
      )}
      {link && isDelete && (
        <a href={link} target="_blank" rel="noopener noreferrer">
          {/*<ODIcon icon={ODIcons.CoreFile} style={{ position: 'relative', top: 1 }} />*/}
          <Text style={{ textDecorationLine: 'line-through' }}>{name}</Text>
        </a>
      )}
      {!link && (
        <>
          {/*<ODIcon icon={ODIcons.CoreFile} style={{ position: 'relative', top: 1, color: COLORS.GRAY400 }} />*/}
          <Text style={{ color: COLORS.GRAY400 }}>{name}</Text>
        </>
      )}
    </Wrapper>
  )
}
