import React from 'react'
import { GQLReservation, GQLUpdateReservationByDoctorInput } from '@mdpp/common/lib/@types/server.schema'
import { ReservationBasicInfoBoxStyle } from '../../../common/reservation/ReservationCommon'
import { PatientDiagnosisFeeInfoCard } from './infoCard/PatientDiagnosisFeeInfoCard'
import { BodyContainer } from "../../../common/layout/BodyContainer";

interface IReservationPaymentBoxProps {
  reservation: GQLReservation
  payLoading: boolean
  onUpdateField: (fieldName: keyof GQLUpdateReservationByDoctorInput, value: string | number) => Promise<void>
  onPayRequested: (value: number) => void
}

export const ReservationPaymentBox: React.FC<IReservationPaymentBoxProps> = props => {
  const { reservation, onUpdateField, onPayRequested, payLoading } = props
  return (
    <BodyContainer>
      <PatientDiagnosisFeeInfoCard
        reservation={reservation}
        onUpdateField={onUpdateField}
        payLoading={payLoading}
        infoStyle={ReservationBasicInfoBoxStyle.OutsideRoom}
        onPayRequested={onPayRequested}
      />
    </BodyContainer>
  )
}
