import React from 'react'
import styled from 'styled-components'


// TODO; 정의된 색상 값을 Rebecca token으로 바꿔치기 한다.
export enum SIZE {
  S,
  M,
  L,
}

export enum TYPE {
  Basic,
  Primary,
  PrimaryDestructive,
  Outline,
  OutlineDestructive,
  InvisibleInteractive,
  InvisibleDestructive,
  InvisibleWithIcon,
}

export const BP = {
  SIZE,
  TYPE,
}

interface IButtonProp {
  size: SIZE,
  type: TYPE,
  textOnly?: boolean,
}

const width = {
  [SIZE.S]: '60px',
  [SIZE.M]: '68px',
  [SIZE.L]: '84px',
}

const height = {
  [SIZE.S]: '30px',
  [SIZE.M]: '36px',
  [SIZE.L]: '42px',
}

const padding = {
  [SIZE.S]: '6px 12px',
  [SIZE.M]: '9px 18px',
  [SIZE.L]: '12px 24px',
}

function withDefault<T extends { defaultProps?: Partial<TDefaults> }, TDefaults>(o: T, defaultProps: TDefaults): T & { defaultProps: TDefaults } {
  o.defaultProps = defaultProps;
  return o as any;
}

const DefaultButton = withDefault(styled.button<IButtonProp>`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 14px;
  border-radius: 4px;
  border: 1px solid #C9CCCE;
  white-space: nowrap;

  height: ${(h: { size: SIZE }) => (height[h.size])};
  padding: ${(p: { size: SIZE }) => (padding[p.size])};
`,
  { size: SIZE.S, type: TYPE.Basic },
)

export const Button = styled(DefaultButton)<IButtonProp>`
height: ${(h: { size: SIZE }) => (h.size === SIZE.M ? '36px' : h.size === SIZE.L ? '42px' : '30px')};
padding: ${(p: { size: SIZE }) => (p.size === SIZE.M ? '9px 18px' : p.size === SIZE.L ? '12px 24px' : '6px 12px')} ;
${
  (t: { type: TYPE }) => {
    switch(t.type) {
      case TYPE.Primary:
        return `
          background-color: #007eff;
          color: #ffffff;
          box-shadow: inset 0 -1px 0px 0 rgb(52 37 144 / 80%), 0 1px 0px 0 rgb(52 37 144 / 47%);
          border: 0;
          
          &:hover {
            background-color: #0085FF;
            color: #ffffff;
          }
        
          &:focus {
            // border: 2px solid #0085FF;
            background-color: #0085FF;
            color: #ffffff;
            outline: none;
          }
        
          &:active {
            background-color: #0085FF;
            color: #ffffff;
          }
        
          &:disabled {
            background-color: #E7E8E9;
            color: #9CA0A3;
            box-shadow: inset 0 -1px 0px 0 var(--gray300);
          }
        `
      case TYPE.PrimaryDestructive:
        return `
          background-color: #ffffff;
          color: #AD2424;
        
          &:hover {
            background-color: #AD2424;
            color: #ffffff;
          }
        
          &:focus {
            background-color: #C93131;
            color: #ffffff;
            outline: none;
          }
        
          &:active {
            background-color: #7C1111;
            color: #ffffff;
          }
        
          &:disabled {
            background-color: #E7E8E9;
            color: #9CA0A3;
          }
        `
      case TYPE.Outline:
        return `
          border: 1px solid #E7E8E9;
          background-color: rgba(0, 0, 0, 0);
          color: #131415;
        
          &:hover {
            background-color: #F6F7F8;
          }
        
          &:focus {
            border: 1px solid #E7E8E9;
            outline: none;
          }
        
          &:active {
            background-color: #F6F7F8;
          }
        
          &:disabled {
            border: 1px solid #F5F5F5;
            color: #9CA0A3;
          }
        `
      case TYPE.OutlineDestructive:
        return`
          font-size: 14px;
          border: 1px solid #DD3F3F;
          border-radius: 4px;
        
          background-color: rgba(0, 0, 0, 0);
          color: #AD2424;
        
          &:hover {
            background-color: #FFE5E5;
          }
        
          &:focus {
            outline: none;
          }
        
          &:active {
            background-color: #FFC2C2;
            color: #ffffff;
          }
        
          &:disabled {
            border: 1px solid #F5F5F5;
            color: #9CA0A3;
          }
        `
      case TYPE.InvisibleInteractive:
        return`
          color: #0085FF;
          background: rgba(0, 0, 0, 0);
          padding: 0 !important;
          border: 0;
          font-weight: 500;
          
          &:hover {
            color: #0072DB;
          }

          &:focus {
            border: 2px solid #0085FF;
            outline: none;
          }

          &:active {
            background-color: #F6F7F8;
            color: #005EB5;
          }

          &:disabled {
            color: var(--gray400);
          }
        `
      case TYPE.InvisibleWithIcon:
        return`
            background-color: rgba(0, 0, 0, 0);
            border: none;
            padding: 0px;

          &:hover {
            background-color: rgba(255, 255, 255, 0.05);
          }

          &:focus {
            background-color: rgba(255, 255, 255, 0.1);
            outline: none;
          }

          &:active {

          }

          &:disabled {

          }
        `
      default: // Basic
        return `
        border: 1px solid #C9CCCE;
        box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.05);
      
        background-color: #FFFFFF;
        color: #131415;
      
        &:hover {
          background-color: #F6F7F8;
        }
      
        &:focus {
          border: 2px solid #0085FF;
          outline: none;
        }
      
        &:active {
          background-color: #F6F7F8;
        }
      
        &:disabled {
          background-color: #F9FAFB;
          color: #9CA0A3;
        }
      `
    }
  }
}
`
interface IImgProps {
  default: string,
  hover?: string,
  focus?: string,
  active?: string,
  disabled?: string,
}

export const ImageButton = styled(Button)`
  background-image: url(${(p: { images: IImgProps }) => (p.images.default)});
  background-size: 18px 18px;
  background-repeat: no-repeat;
  background-position-x: 12px;
  background-position-y: center;
  padding-left: 34px;
  min-width: 14px;
  min-height: 14px;

  &:hover {
    outline: none;
    background-image: url(${(p: { images: IImgProps }) => (p.images.hover && p.images.hover || p.images.default)});
  }

  &:focus {
    background-image: url(${(p: { images: IImgProps }) => (p.images.focus && p.images.focus || p.images.default)});
  }

  &:active {
    background-image: url(${(p: { images: IImgProps }) => (p.images.active && p.images.active || p.images.default)});
  }

  &:disabled {
    background-image: url(${(p: { images: IImgProps }) => (p.images.disabled && p.images.disabled || p.images.default)});
  }
`
