import { ODRoute } from '@mdpp/od-react-belt'
import React from 'react'
import { NoticeItemPage } from '../common/notice/list/NoticeItemPage'
import { NoticeListableTable } from '../common/notice/list/NoticeListableTable'
import { NoticeFilter } from '../common/notice/NoticeCommon'
import { SiteUrls } from '../SiteUrls'
import { DoctorDashboardContainer } from './dashboard/DoctorDashboardContainer'
import { DoctorInquiryModal } from './inquiry/DoctorInquiryModal'
import { DoctorMyPageContainer } from './mypage/DoctorMyPageContainer'
import { ReservationDetailPage } from './reservation/detail/ReservationDetailPage'
import { DoctorReservationListPage } from './reservation/DoctorReservationListPage'
import { DoctorScheduleSetupPage } from './schedule/DoctorScheduleSetupPage'
import { DoctorScheduleTable } from './schedule/DoctorScheduleTablePage'
import { DoctorSharedDataTable } from '../mydata/list/DoctorSharedDataTable'
import { SharedDataView } from '../mydata/SharedDataView'
import { MyRecomCode } from './code/MyRecomCode'

// https://github.com/ReactTraining/react-router/tree/master/packages/react-router-config
export const DoctorRoutes: ODRoute[] = [
  { path: SiteUrls.Doctor.Dashboard.Main, exact: true, name: `대시보드`, component: DoctorDashboardContainer },
  { path: SiteUrls.Doctor.Reservation.Detail(':id'), exact: true, name: `진료상세`, component: ReservationDetailPage },
  { path: SiteUrls.Doctor.Reservation.Main, exact: true, name: `진료`, component: DoctorReservationListPage },
  { path: SiteUrls.Doctor.Schedule.Main, exact: true, name: `진료 / 스케줄 설정`, component: DoctorScheduleTable },
  { path: SiteUrls.Doctor.Schedule.SetupDefault, exact: true, name: `스케줄설정`, component: DoctorScheduleSetupPage },
  { path: SiteUrls.Doctor.MyPage.Main, exact: true, name: `마이페이지`, component: DoctorMyPageContainer },
  { path: SiteUrls.Doctor.Code.Main, exact: true, name: `나의 추천 코드`, component: MyRecomCode },
  {
    path: SiteUrls.Doctor.Notice.Main,
    exact: true,
    name: `공지사항`,
    render: (props: any) => <NoticeListableTable filter={NoticeFilter.Doctor} />,
  },
  {
    path: SiteUrls.Doctor.Notice.Item(':id'),
    exact: true,
    name: `상세보기`,
    render: (props: any) => <NoticeItemPage id={props.match.params.id} />,
  },
  { path: SiteUrls.Doctor.Inquiry.Main, exact: true, name: `문의하기`, component: DoctorInquiryModal },
  { path: SiteUrls.Doctor.MyData.Main, exact: true, name: `스마트 진료지원`, component: DoctorSharedDataTable },
  {
    path: SiteUrls.Doctor.MyData.Item(':url', ':dsId'),
    exact: true,
    name: `상세보기`,
    render: (props: any) => <SharedDataView url={props.match.params.url} temporary={false} dsId={props.match.params.dsId}/>
  }
]
