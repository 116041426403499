import { useODModalConfirm, useODQuery } from '@mdpp/od-react-belt'
import React, { useState } from 'react'
import { FullScreen, useFullScreenHandle } from 'react-full-screen'
import { Redirect } from 'react-router-dom'
import styled from 'styled-components'
import { NumberParam, useQueryParam, withDefault } from 'use-query-params'
import {
  GQLChatRoom,
  GQLGetChatRoomHasReservationInput,
  GQLReservation,
  GQLRESERVATION_STATUS,
  GQLUpdateReservationByDoctorInput,
} from '@mdpp/common/lib/@types/server.schema'
import { IEvixPillboxTabBarItem } from '../../../common/EvixPillBoxTabBar'
import { ITabBarIcon } from '../../../common/EvixTabBar'
import { RoomTabBar } from '../../../common/RoomTabBar'
import { GQL_GET_CHATROOM_HAS_RESERVATION } from '../../../common/gqls_common'
import { SiteUrls } from '../../../SiteUrls'
import { useODDoctorAppContext } from '../../context/ODDoctorAppContext'
import { useEvixRoom } from './context/EvixRoomContext'
import { useAgoraVideoRoom } from './hooks/useAgoraVideoRoom'
import { RoomSectionDivider, ROOM_TYPE, VIDEO_TAG_ID } from './RoomCommon'
import { RoomChatSection } from './section/RoomChatSection'
import { RoomDiagnosisSection } from './section/RoomDiagnosisSection'
import { RoomInfoSection } from './section/RoomInfoSection'
import { RoomCameraOff } from './video/RoomCameraOff'
import { RoomVideoBottomBar } from './video/RoomVideoBottomBar'
import { RoomInfoBox } from './RoomInfoBox'
import { RoomReservationSection } from './section/RoomReservationSection'
import { Button, BP } from '../../../common/RebeccaButton'
import { RoomExitPopup } from './RoomExitPopup'
import { AppOptions } from '../../../AppOptions'
import ChannelService from 'channel-talk-ts'

interface IRoomMainLayoutProps {
  reservation: GQLReservation // 토큰이 이미 발급되어 입장이 가능한 reservation 정보
  onUpdateField: (fieldName: keyof GQLUpdateReservationByDoctorInput, value: string | number) => Promise<void>
  onStartReservation: () => void
  onRequestPatientEnter: (reservation: GQLReservation) => void
  onDoctorOut: () => void
  onExitRoom: () => void
  refresh: () => void
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background: #202124;
  // opacity: 0.9;
`

const ScreenPanel = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  background: #202124;
  // opacity: 0.9;
  margin: 20px 0px 20px 0px;
`

const ScreenBottomPanel = styled.div`
  flex-grow: 0;
  height: 93px;
  width: 100%;
  background: #202124;
  // opacity: 0.9;
`

const InfoPanel = styled.div`
  display: flex;
  flex-direction: column;
  width: 392px;
  min-width: 392px;
  height: calc(100% - 40px);
  flex-grow: 0;
  background: white;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1), 0px 8px 40px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  overflow-x: hidden;
  margin: 20px 20px 20px 0px;
  // padding-top: 21px;
`

const InfoTitle = styled.div`
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: -0.5px;
  color: #202223;
`

const InfoPanelHeader = styled.div`
  display: flex;
  align-items: center;
  padding: 21px 16px 21px;
  height: 66px;
`

const InfoPanelBody = styled.div`
  height: calc(100% - 134px);
  overflow: auto;
`

const InfoPanelFooter = styled.div`
  height: 68px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  /* padding: 16px; */
  border-top: 1px solid #e4e5e7;
`

const VideosWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  background: #202124;
  // opacity: 0.9;
  flex-grow: 1;
`

const VideoPanel = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #202124;
  // opacity: 0.9;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.36;

  > div {
    background-color: #202124 !important;
    // opacity: 0.9;
    border-radius: 10px;
  }
`

const StartDiagnosisButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  /* height: 42px; */
  font-size: 16px;
  /* color: var(--white); */
`

const PatientState = styled.div`
  color: blue;
  font-size: 14px;
`

const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`

const Items: IEvixPillboxTabBarItem[] = [{ text: '정보' }, { text: '진료' }, { text: '채팅' }]
const TabBarItems: ITabBarIcon[] = [{ text: '예약상세' }, { text: '기초문진' }, { text: '진료결과' }]

export const RoomMainLayout: React.FC<IRoomMainLayoutProps> = props => {
  const apiGetChatRoomId = useODQuery<GQLGetChatRoomHasReservationInput, GQLChatRoom>(GQL_GET_CHATROOM_HAS_RESERVATION)
  const [chatRoomId, setChatRoomId] = useState<number | null>(null)
  const {
    reservation: { reservationId },
    onStartReservation,
    onRequestPatientEnter,
    onDoctorOut,
    onExitRoom,
    refresh,
  } = props
  const [forceUserId] = useQueryParam('forceUserId', withDefault(NumberParam, null))
  const handle = useFullScreenHandle()
  const [showPopup, setShowPopup] = React.useState(false)

  const { reservation, onUpdateField } = props
  const {
    isShareScreenOn,
    isVideoOn,
    setSelectedVideoId,
    selectedVideoId,
    isPatientJoined,
    isFullScreenOn,
    setFullScreenOnOff,
    isPatientVideoOn,
  } = useEvixRoom()

  React.useEffect(() => {
    console.log('patient: ', isPatientJoined)
  }, [isPatientJoined])

  React.useEffect(() => {
    const CS = new ChannelService({pluginKey: AppOptions.CHANNEL_TALK_DOCTOR_PLUGIN_KEY || 'testkey'})
    CS.boot()

    return () => {
      CS.shutdown()
    }
  }, [])

  const {
    state: { profile },
  } = useODDoctorAppContext()
  const userId = forceUserId ?? profile?.doctorId ?? '0'

  const { Component: ConfirmBox, props: confirmProps, confirm } = useODModalConfirm({})
  const [redirect, setRedirect] = useState('')
  const handleJoinError = React.useCallback(
    async (err: string) => {
      console.log(err)
      if (err === 'DYNAMIC_KEY_EXPIRED') {
        await confirm({
          title: '진료실 입장이 불가능합니다.',
          message: '진료실에 입장할 권한이 없거나, 현재 진료가 가능한 상태가 아닙니다.',
          yes: '확인',
        })
        setRedirect(SiteUrls.Doctor.Reservation.Detail(reservationId))
      } else if (err.toString() === 'NotAllowedError: Permission denied') {
        await confirm({
          title: '장치가 켜져있지 않습니다.',
          message: '카메라 또는 오디오 장치가 꺼져 있습니다.',
          yes: '확인',
        })
        setRedirect(SiteUrls.Doctor.Reservation.Detail(reservationId))
      } else if (err.toString() === 'NotAllowedError: Permission denied by system') {
        await confirm({
          title: '장치가 켜져있지 않습니다.',
          message: '카메라 또는 오디오 장치가 시스템에서 꺼져 있습니다.',
          yes: '확인',
        })
        setRedirect(SiteUrls.Doctor.Reservation.Detail(reservationId))
      }
    },
    [confirm, setRedirect, reservationId]
  )

  const { enableScreenSharing, disableScreenSharing, toggleAudioMute, toggleVideoMute } = useAgoraVideoRoom(
    userId.toString(),
    reservation.reservationId.toString(),
    reservation.agoraToken!,
    VIDEO_TAG_ID.Doctor,
    VIDEO_TAG_ID.Patient,
    VIDEO_TAG_ID.Screen,
    handleJoinError
  )

  const [tabRaw, setTab] = useQueryParam('tab', withDefault(NumberParam, 0))
  const tab = Math.min(Math.max(tabRaw, 0), Items.length - 1)

  const styleFocused = { width: '100%', height: '100%', left: 0, top: 0, zIndex: 0 }
  const styledThumbnail1 = {
    // backgroundColor: 'black',
    backgroundColor: '#202124',
    // opacity: 0.9,
    width: 149,
    height: 112,
    left: 43,
    top: 35,
    zIndex: 1,
    border: 'solid 1px #979797',
    borderRadius: 8,
  }
  const styledThumbnail2 = {
    // backgroundColor: 'blue',
    backgroundColor: '#202124',
    // opacity: 0.9,
    width: 149,
    height: 112,
    left: 43,
    top: 147,
    zIndex: 1,
    border: 'solid 1px #979797',
    borderRadius: 8,
  }

  const styleHidden = { display: 'none' }

  const styles = {
    [VIDEO_TAG_ID.Doctor]: {},
    [VIDEO_TAG_ID.Patient]: {},
    [VIDEO_TAG_ID.Screen]: {},
  }

  switch (selectedVideoId) {
    case VIDEO_TAG_ID.Doctor: {
      styles[VIDEO_TAG_ID.Doctor] = styleFocused
      styles[VIDEO_TAG_ID.Patient] = isPatientJoined ? styledThumbnail1 : styleHidden
      styles[VIDEO_TAG_ID.Screen] = isShareScreenOn
        ? isPatientJoined
          ? styledThumbnail2
          : styledThumbnail1
        : styleHidden
      break
    }
    case VIDEO_TAG_ID.Patient: {
      styles[VIDEO_TAG_ID.Doctor] = !isPatientJoined ? styledThumbnail1 : styledThumbnail1
      styles[VIDEO_TAG_ID.Patient] = isPatientJoined ? styleFocused : styleHidden
      styles[VIDEO_TAG_ID.Screen] = isShareScreenOn ? styledThumbnail2 : styleHidden
      break
    }
    case VIDEO_TAG_ID.Screen: {
      styles[VIDEO_TAG_ID.Doctor] = styledThumbnail1
      styles[VIDEO_TAG_ID.Patient] = isPatientJoined ? styledThumbnail2 : styleHidden
      styles[VIDEO_TAG_ID.Screen] = styleFocused
      break
    }
  }

  const isPatientSelected = selectedVideoId === VIDEO_TAG_ID.Patient
  const isDoctorSelected = selectedVideoId === VIDEO_TAG_ID.Doctor

  const updateChatRoomId = React.useCallback(
    async (reservationId: number) => {
      const result = await apiGetChatRoomId({ reservationId })
      setChatRoomId(result.chatRoomId)
    },
    [apiGetChatRoomId]
  )

  React.useEffect(() => {
    // noinspection JSIgnoredPromiseFromCall
    updateChatRoomId(reservationId)
  }, [updateChatRoomId, reservationId])

  React.useEffect(() => {
    refresh()
  }, [tab])

  const shouldShowRequestPatientEnterPopup = reservation.status === GQLRESERVATION_STATUS.RECEIPTED
  const shouldShowResendPatientEnterRequestPopup =
    reservation.status === GQLRESERVATION_STATUS.READY && !isPatientJoined
  const shouldShowStartTreatmentPopup = reservation.status === GQLRESERVATION_STATUS.READY && isPatientJoined
  const shouldShowWaitPatientPopup = reservation.status === GQLRESERVATION_STATUS.IN_PROGRESS && !isPatientJoined

  const initState = reservation.status === GQLRESERVATION_STATUS.RECEIPTED && !isPatientJoined
  const isDoctorReady = reservation.status === GQLRESERVATION_STATUS.READY && !isPatientJoined
  const isPatientReady = reservation.status === GQLRESERVATION_STATUS.READY && isPatientJoined
  const inProgress = reservation.status === GQLRESERVATION_STATUS.IN_PROGRESS && isPatientJoined
  const isPatientOut = reservation.status === GQLRESERVATION_STATUS.IN_PROGRESS && !isPatientJoined

  const popupMessage = () => {
    let message: any
    if (isDoctorReady) {
      message = (
        <>
          진료실을 나가시겠습니까?
          <br />
          환자의 진료실 입장은 취소 됩니다.
        </>
      )
    } else if (isPatientOut) {
      message = (
        <>
          환자가 비정상 종료되었지만, 진료를 종료하시겠습니까?
          <br />
          진료에 대한 결과 입력 및 처방전 등록은 진료실을 나가서 수행하실 수 있습니다.
        </>
      )
    } else if (initState) {
      message = (
        <>
          진료실을 나가시겠습니까?
          <br />
          진료실 입장하기를 눌러 재입장 하실수 있습니다.
        </>
      )
    } else {
      // inProgress
      message = (
        <>
          진료실을 나가시겠습니까?
          <br />
          진료에 대한 결과 입력 및 처방전 등록은 진료실을 나가서 수행하실 수 있습니다.
        </>
      )
    }

    return message
  }

  const handleFinish = async () => {
    if (isFullScreenOn) {
      handle.exit()
      setFullScreenOnOff(false)
    }
    disableScreenSharing()

    // 진료실 나가는 조건 체크
    if (isDoctorReady) {
      // 1. 환자 대기중 환자 미입장으로 퇴장 - 상태가 RECEIPTED로 변환
      onDoctorOut()
      setRedirect(SiteUrls.Doctor.Reservation.Detail(reservationId))
    } else if (initState) {
      setRedirect(SiteUrls.Doctor.Reservation.Detail(reservationId))
    } else {
      // 2. 환자 진료 종료 후 정상 종료 - 상태가 PRESCRIPTION으로 변환, 환자 퇴장 이벤트 전달,
      // 3. 환자 비정상 종료 중 퇴장 - 상태가 IN_PROGRESS 유지?? 환자가 동의하에 진료 종료라면? -> PRESCRIPTION
      onExitRoom()
      setRedirect(SiteUrls.Doctor.Reservation.Result(reservationId, 2))
    }

    setShowPopup(false)
  }

  const handleFullscreen = (enter: boolean) => {
    if (enter) {
      handle.enter()
      setFullScreenOnOff(true)
    } else {
      handle.exit()
      setFullScreenOnOff(false)
    }
  }

  if (redirect) {
    return <Redirect to={redirect} />
  }

  return (
    <>
      <RoomExitPopup isOpen={showPopup} onClose={() => setShowPopup(false)} onSubmit={handleFinish}>
        {popupMessage()}
      </RoomExitPopup>
      <FullScreen handle={handle}>
        <Wrapper>
          <ConfirmBox {...confirmProps} />

          <ScreenPanel>
            <VideosWrapper>
              <VideoPanel
                id={VIDEO_TAG_ID.Doctor}
                style={{ ...styles[VIDEO_TAG_ID.Doctor] }}
                onClick={() => setSelectedVideoId(VIDEO_TAG_ID.Doctor)}
              >
                {!isVideoOn && (
                  <RoomCameraOff text={isDoctorSelected ? '카메라를 껐습니다.' : ''} small={!isDoctorSelected} />
                )}
              </VideoPanel>
              <VideoPanel
                id={VIDEO_TAG_ID.Patient}
                style={{ ...styles[VIDEO_TAG_ID.Patient] }}
                onClick={() => setSelectedVideoId(VIDEO_TAG_ID.Patient)}
              >
                {!isPatientVideoOn && (
                  <RoomCameraOff
                    text={isPatientSelected ? '상대방이 카메라를 껐습니다.' : ''}
                    small={!isPatientSelected}
                  />
                )}
              </VideoPanel>
              <VideoPanel
                id={VIDEO_TAG_ID.Screen}
                style={styles[VIDEO_TAG_ID.Screen]}
                onClick={() => setSelectedVideoId(VIDEO_TAG_ID.Screen)}
              />
              {shouldShowRequestPatientEnterPopup && (
                <RoomInfoBox type={ROOM_TYPE.INIT} onRequest={() => onRequestPatientEnter(reservation)} />
              )}
              {shouldShowResendPatientEnterRequestPopup && (
                <RoomInfoBox type={ROOM_TYPE.READY} onRequest={() => onRequestPatientEnter(reservation)} />
              )}
              {shouldShowStartTreatmentPopup && (
                <RoomInfoBox type={ROOM_TYPE.START_TREATMENT} onStart={onStartReservation} />
              )}
              {shouldShowWaitPatientPopup && <RoomInfoBox type={ROOM_TYPE.PATIENT_EXIT} />}
            </VideosWrapper>
            <ScreenBottomPanel>
              <RoomVideoBottomBar
                toggleAudioMute={toggleAudioMute}
                toggleVideoMute={toggleVideoMute}
                onClickFinish={handleFinish}
                onFullscreenClicked={handleFullscreen}
                onScreenSharingOptionChanged={enable => (enable ? enableScreenSharing() : disableScreenSharing())}
              />
            </ScreenBottomPanel>
          </ScreenPanel>

          <RoomChatSection reservation={reservation} chatRoomId={chatRoomId} />

          <InfoPanel>
            <InfoPanelHeader>
              <TitleWrapper>
                <InfoTitle>진료 정보</InfoTitle>
              </TitleWrapper>
            </InfoPanelHeader>
            <InfoPanelBody>
              <RoomTabBar items={TabBarItems} selectedIndex={tab} onClickTab={setTab} isRoom />
              <div style={{ position: 'relative' }}>
                <RoomSectionDivider />
                {tab === 0 && <RoomReservationSection reservation={reservation} />}
                {tab === 1 && <RoomInfoSection reservation={reservation} />}
                {tab === 2 && <RoomDiagnosisSection reservation={reservation} onUpdateField={onUpdateField} />}
              </div>
            </InfoPanelBody>
            <InfoPanelFooter>
              {(initState || isPatientReady || isPatientOut || isDoctorReady) && (
                <StartDiagnosisButtonWrapper onClick={() => setShowPopup(true)}>
                  <Button
                    type={BP.TYPE.Primary}
                    style={{
                      width: '100%',
                      height: '100%',
                      borderTopLeftRadius: 0,
                      borderTopRightRadius: 0,
                      fontSize: 16,
                    }}
                  >
                    진료실 나가기
                  </Button>
                </StartDiagnosisButtonWrapper>
                // <StartDiagnosisButtonWrapper onClick={()=>(setShowPopup(true))}>
                //   <ImageButton images={{default: SVGDoor}} style={{width: '100%', height: '100%'}}>
                //     진료실 나가기
                //   </ImageButton>
                // </StartDiagnosisButtonWrapper>
              )}
              {inProgress && (
                <StartDiagnosisButtonWrapper onClick={() => setShowPopup(true)}>
                  <Button
                    type={BP.TYPE.Primary}
                    style={{
                      width: '100%',
                      height: '100%',
                      borderTopLeftRadius: 0,
                      borderTopRightRadius: 0,
                      fontSize: 16,
                    }}
                  >
                    진료 종료하기
                  </Button>
                </StartDiagnosisButtonWrapper>
              )}
            </InfoPanelFooter>
          </InfoPanel>
        </Wrapper>
      </FullScreen>
    </>
  )
}
