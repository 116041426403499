import { ODListableResponseType, useODQuery } from '@mdpp/od-react-belt'
import React from 'react'
import { GQLListableReservation, GQLListableReservationInput, GQLReservation } from '@mdpp/common/lib/@types/server.schema'
import { GQL_LIST_RESERVATION } from '../../gqls_common'
import { IReservationListableOption } from '../../reservation/ReservationCommon'

export function useReservationReviewListDataLoader(fixedOptions: Partial<GQLListableReservationInput> = {}) {
  const listApi = useODQuery<Partial<GQLListableReservationInput>, GQLListableReservation>(GQL_LIST_RESERVATION)

  return React.useCallback(
    async function dataLoader(
      page: number,
      pageSize: number,
      afterKey: string | null,
      options: IReservationListableOption
    ): Promise<ODListableResponseType<GQLReservation>> {
      const r = await listApi({
        page,
        pageSize,
        onlyHasReview: true,
        ...fixedOptions,
        ...options,
      })
      return r as ODListableResponseType<GQLReservation>
    },
    [fixedOptions, listApi]
  )
}
