import React, { useEffect, useRef } from 'react'
import { EvixButton, EvixButtonTheme } from '../../common/EvixButton'
import { Page, PageContainer, PageContent, PageHeader, PageTitle, TitleDescription } from '../../common/page/Page'
import { EvixCard } from '../../common/card/EvixCard'
import styled, { css } from 'styled-components'
import { COLORS } from '../../styles/colors'
import IconWarningBlue from '../../assets/common/warning-blue.svg'
import { SendMyCodeModal } from './SendMyCodeModal'
import { useODDoctorAppContext } from '../context/ODDoctorAppContext'
import { GQLDoctor } from '@mdpp/common/lib/@types/server.schema'
import { ODWebUtils } from '@mdpp/od-react-belt'
import { useDoctorAPIs } from '../context/useDoctorAPIs'

interface IMyRecomCodeProps {
  type: string
  profile?: GQLDoctor | null
  recommenderId?: number
  totalJoinDoctor?: number | undefined
  totalJoinPatient?: number | undefined
  totalSharedMydata?: number | undefined
}

export const MyRecomCode: React.FC<IMyRecomCodeProps> = props => {
  const { type } = props
  const [showSendMyCodeModal, setShowSendMyCodeModal] = React.useState(false)
  const [totalJoinDoctor, setTotalJoinDoctor] = React.useState(0)
  const [totalJoinPatient, setTtotalJoinPatient] = React.useState(0)
  const [totalSharedMydata, setTotalSharedMydata] = React.useState(0)
  const {
    state: { profile },
  } = useODDoctorAppContext()
  const { getDoctorStatInfo } = useDoctorAPIs()

  React.useEffect(() => {
    doctorStatInfos(profile!.doctorId)
  }, [])

  const doctorCode = profile?.recommendationCode!

  const doctorStatInfos = async (id: number) => {
    const r = await getDoctorStatInfo({ id: id })
    console.log(r)
    try {
      setTotalJoinDoctor(r?.totalJoinDoctor)
      setTtotalJoinPatient(r?.totalJoinPatient)
      setTotalSharedMydata(r?.totalSharedMydata)
    } catch (err) {
      console.log(err)
    }
  }

  const handleSendCode = async () => {
    setShowSendMyCodeModal(true)
  }

  const copyByClipBoardAPI = () => {
    navigator.clipboard
      .writeText(doctorCode)
      .then(() => {
        ODWebUtils.showSuccess(`${doctorCode} 복사 하였습니다.`)
      })
      .catch(err => {
        console.log(err)
      })
  }

  const doctorCodeInput = (doctorCode || '').split('').map((txt: string, idx: number) => {
    return <span key={idx}>{txt}</span>
  })

  return (
    <PageContainer>
      <Page>
        <PageHeader className="title-description">
          <PageTitle>나의 추천 코드</PageTitle>
          <TitleDescription>
            <img src={IconWarningBlue} alt="info icon" />
            <span>나의 추천 코드를 환자 또는 의사에게 공유해주세요.</span>
          </TitleDescription>
        </PageHeader>
        <PageContent>
          <EvixCard noPadding={true}>
            {/* 추천인 코드 영역 */}
            <RecomCodeWrap>
              <Code>{doctorCodeInput}</Code>
              {/* 추천인 코드 복사 및 코드 발송 영역 */}
              <BtnWrap>
                <EvixButton
                  style={{ flex: 1 }}
                  theme={EvixButtonTheme.H48WhiteInline}
                  disabled={false}
                  onClick={() => copyByClipBoardAPI()}
                >
                  복사하기
                </EvixButton>
                <EvixButton
                  style={{ flex: 1 }}
                  theme={EvixButtonTheme.H48WhiteInline}
                  disabled={false}
                  onClick={handleSendCode}
                >
                  의사에게 추천코드 발송
                </EvixButton>
              </BtnWrap>
            </RecomCodeWrap>
            <Line />

            {/* 가입자 수 영역 */}
            <CountWrap>
              <CountBlock type="doctor">
                <label>의사 가입 (명)</label>
                <span>{totalJoinDoctor}</span>
              </CountBlock>
              <CountBlock type="patient">
                <label>환자 가입 (명)</label>
                <span>{totalJoinPatient}</span>
              </CountBlock>
              <CountBlock type="smart">
                <label>스마트진료 (명)</label>
                <span>{totalSharedMydata}</span>
              </CountBlock>
            </CountWrap>
          </EvixCard>
        </PageContent>
      </Page>

      {showSendMyCodeModal && (
        <SendMyCodeModal
          style={{ width: 590, height: 758, maxWidth: 590 }}
          onCancelPress={() => setShowSendMyCodeModal(false)}
        />
      )}
    </PageContainer>
  )
}

interface CountBlockProps {
  children: any
  type: string
}

const CountBlock: React.FC<CountBlockProps> = props => {
  const { children, type, ...rest } = props
  return (
    <Count type={type} {...rest}>
      {children}
    </Count>
  )
}

const Line = styled.div`
  display: block;
  width: 100%;
  height: 1px;
  background-color: #d8d8d8;
`

const RecomCodeWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 90px 0 70px 0;
  gap: 30px;
  width: 440px;
  margin: 0 auto;
`

const Code = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;

  > span {
    display: flex;
    width: 65px;
    height: 65px;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    background-color: #e8f2fc;
    font-size: 30px;
    line-height: normal;
    color: ${COLORS.GRAY42};
    font-weight: 500;
  }
`

const BtnWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  width: 100%;
`

const CountWrap = styled.div`
  padding: 50px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 48px;
`

const countStyles = css<CountBlockProps>`
  ${props =>
    props.type === 'doctor' &&
    css`
      background-color: #f6f7f7;

      > span {
        color: ${COLORS.GRAY42};
      }
    `}

  ${props =>
    props.type === 'patient' &&
    css`
      background-color: #e8f2fc;

      > span {
        color: ${COLORS.blue200};
      }
    `}

    ${props =>
    props.type === 'smart' &&
    css`
      background-color: #ffeded;

      > span {
        color: #ff5353;
      }
    `}
`

const Count = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 115px;
  height: 90px;
  padding: 9px 9px 12px 9px;
  gap: 20px;

  > label {
    font-size: 13px;
    font-weight: 500;
    color: ${COLORS.GRAY42};
    line-height: normal;
    margin-bottom: 0;
  }

  > span {
    font-size: 30px;
    font-weight: 500;
    line-height: 1;
  }

  ${countStyles}
`
