import React from 'react'
import { MainContentWithInset } from '../../common/layout/MainContentWithInset'
import { PharmacyListableTable } from '../../common/pharmacy/list/PharmacyListableTable'
import {Page, PageContainer, PageContent, PageHeader, PageTitle} from "../../common/page/Page";

export const AdminPharmacyListPage: React.FC = props => {
  return (
    <PageContainer>
      <Page>
        <PageHeader>
          <PageTitle>약국</PageTitle>
        </PageHeader>
        <PharmacyListableTable />
      </Page>
    </PageContainer>
  )
}
