import { useODMutation, useODQuery } from '@mdpp/od-react-belt'
import {
  GQLLoginPharmacistInput,
  GQLLoginPharmacistOutput,
  GQLOkResponse,
  GQLPharmacist,
  GQLUpdatePharmacistInput,
  GQLSingleIDInput,
  GQLPharmacyUpdateInput,
  GQLPharmacy,
  GQLMedicationDispensing,
  GQLRejectDispensingRequestInput,
  GQLChargeMedicationDispensingInput,
} from '@mdpp/common/lib/@types/server.schema'
import { AppOptions } from '../../AppOptions'
import {
  GQL_CHANGE_MEDICATION_DISPENSING,
  GQL_GET_MEDICATION_DISPENSING,
  GQL_GET_PHARMACIST,
  GQL_GET_PHARMACIST_PROFILE,
  GQL_GET_PHARMACY,
  GQL_LOGIN_PHARMACIST,
  GQL_LOGOUT,
  GQL_REJECT_DISPENSING_REQUEST,
  GQL_UPDATE_PHARMACIST,
  GQL_UPDATE_PHARMACIST_PROFILE,
  GQL_UPDATE_PHARMACY,
} from '../../common/gqls_common'
import { useCallback, useEffect, useState } from 'react'

export function usePharmacyAPIs() {
  const simulateDelay = AppOptions.SIMULATE_DELAY
  const loginPharmacist = useODMutation<GQLLoginPharmacistInput, GQLLoginPharmacistOutput>(
    GQL_LOGIN_PHARMACIST,
    simulateDelay
  )
  const logout = useODMutation<void, GQLOkResponse>(GQL_LOGOUT, simulateDelay)
  const getPharmacistMyProfile = useODQuery<void, GQLPharmacist>(GQL_GET_PHARMACIST_PROFILE, simulateDelay)
  const updatePharmacistProfile = useODMutation<Partial<GQLUpdatePharmacistInput>, GQLPharmacist>(
    GQL_UPDATE_PHARMACIST_PROFILE,
    simulateDelay
  )

  const getPharmacist = useODQuery<GQLSingleIDInput, GQLPharmacist>(GQL_GET_PHARMACIST, simulateDelay)
  const updatePharmacist = useODMutation<Partial<GQLUpdatePharmacistInput>, GQLPharmacist>(
    GQL_UPDATE_PHARMACIST,
    simulateDelay
  )

  const getPharmacy = useODQuery<GQLSingleIDInput, GQLPharmacy>(GQL_GET_PHARMACY, simulateDelay)
  const updatePharmacy = useODMutation<Partial<GQLPharmacyUpdateInput>, GQLPharmacy>(GQL_UPDATE_PHARMACY, simulateDelay)

  const getMedicationDispensing = useODQuery<GQLSingleIDInput, GQLMedicationDispensing>(
    GQL_GET_MEDICATION_DISPENSING,
    simulateDelay
  )
  const rejectDispensingRequest = useODMutation<GQLRejectDispensingRequestInput, GQLMedicationDispensing>(
    GQL_REJECT_DISPENSING_REQUEST,
    simulateDelay
  )

  const chargeDispensingFee = useODMutation<GQLChargeMedicationDispensingInput, GQLMedicationDispensing>(
    GQL_CHANGE_MEDICATION_DISPENSING,
    simulateDelay
  )

  return {
    loginPharmacist,
    logout,
    getPharmacistMyProfile,
    updatePharmacistProfile,
    getPharmacy,
    updatePharmacy,
    getPharmacist,
    updatePharmacist,
    getMedicationDispensing,
    rejectDispensingRequest,
    chargeDispensingFee,
  }
}

export interface IUseReservation {
  reservation: GQLMedicationDispensing | undefined
  refresh: () => void
  setReservation: (
    value:
      | ((prevState: GQLMedicationDispensing | undefined) => GQLMedicationDispensing | undefined)
      | GQLMedicationDispensing
      | undefined
  ) => void
  error: Error | undefined
}

export function usePharmacistReservation(medicationDispensingId: number | string): IUseReservation {
  const { getMedicationDispensing } = usePharmacyAPIs()
  const [reservation, setReservation] = useState<GQLMedicationDispensing | undefined>()
  const [error, setError] = useState<Error | undefined>()

  const refresh = useCallback(() => {
    // setReservation(undefined)
    // setError(undefined)
    getMedicationDispensing({ id: parseInt(medicationDispensingId.toString(), 10) }).then(setReservation, () => null)
  }, [getMedicationDispensing, setReservation, medicationDispensingId])

  useEffect(() => {
    refresh()
  }, [refresh])

  return { reservation, setReservation, error, refresh }
}
