import React from 'react'
import styled from 'styled-components/macro'
import { GQLReservation } from '@mdpp/common/lib/@types/server.schema'
import { IEvixPrescriptionFile } from '../../../common/EvixPrescriptionFileUpload'
import { DiagNameWithOption, DiagnosisList } from './infoCard/DiagnosisList'
import { EvixCardRow } from "../../../common/card/EvixCardRow";
import { EvixCardLabel } from "../../../common/card/EvixCardLabel";
import { EvixCardRowContent } from "../../../common/card/EvixCardRowContent";
import { ODVSpace } from '@mdpp/od-react-belt'

interface IReservationFinishDetailProps {
  reservation: GQLReservation
  diagNames?: DiagNameWithOption[]
  treatment?: string,
  prescriptionFiles?: IEvixPrescriptionFile[]
}

export const ReservationFinishDetail: React.FC<IReservationFinishDetailProps> = props => {
  const { reservation, diagNames = [], treatment = '', prescriptionFiles = [] } = props
  const {
    patient: { name },
  } = reservation

  return (
    <Wrapper>
      <EvixCardRow>
        <EvixCardLabel>환자이름</EvixCardLabel>
        <EvixCardRowContent>{name ?? '-'}</EvixCardRowContent>
      </EvixCardRow>
      <ODVSpace h={24}/>
      <EvixCardRow style={{alignItems: 'flex-start'}}>
        <EvixCardLabel>진단명</EvixCardLabel>
        <Contents>
          <DiagnosisList diagNames={diagNames} editable={false} />
        </Contents>
      </EvixCardRow>
      <ODVSpace h={24}/>
      <EvixCardRow style={{alignItems: 'flex-start'}}>
        <EvixCardLabel>치료안내</EvixCardLabel>
        <EvixCardRowContent>{treatment ?? '-'}</EvixCardRowContent>
      </EvixCardRow>
      <ODVSpace h={24}/>
      <EvixCardRow style={{alignItems: 'flex-start'}}>
        <EvixCardLabel>처방전</EvixCardLabel>
        <EvixCardRowContent>{prescriptionFiles.length === 0 ? '첨부' : (
          prescriptionFiles.map(file => {
              return (
                <>
                  {file.fileName}
                  <br />
                </>
              )
            }
          )
        )}
        </EvixCardRowContent>
      </EvixCardRow>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  padding: 0 0 0 0;
  background-color: var(--white);
  display: flex;
  flex-direction: column;
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid var(--gray200) !important;
  padding-bottom: 10px;
  margin: 2px 25px 10px 25px;
`

const Label = styled.div`
  width: 130px;
  min-width: 130px;
  flex-grow: 0;
  font-size: 12px;
  line-height: 1.67;
  color: var(--gray600);
  text-align: left;
`

const Contents = styled.div`
  flex-grow: 1;
  font-size: 14px;
  line-height: 1.43;
  color: var(--gray800);
  text-align: left;
  
`

const GuideText = styled.div`
  text-align: center;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  background-color: var(--white);
  padding-top: 26px;
`
