import { ODIcons, ODVSpace } from '@mdpp/od-react-belt'
import React from 'react'
import styled from 'styled-components'
import { GQLRESERVATION_STATUS } from '@mdpp/common/lib/@types/server.schema'
import { ReservationListableTable } from '../../common/reservation/list/ReservationListableTable'
import { useReservationListDataLoader } from '../../common/reservation/list/ReservationListDataLoader'
import { TitleWithViewAll } from '../../doctor/dashboard/TitleWithViewAll'
import { SiteUrls } from '../../SiteUrls'
import {EvixCardHeader} from "../../common/card/EvixCardHeader";
import {CardLink} from "reactstrap";
import {EvixCard, EvixCardWithTableWrapper} from "../../common/card/EvixCard";

interface IManagerRecentReservationContainerProps {}

export const ManagerRecentReservationContainer: React.FC<IManagerRecentReservationContainerProps> = props => {
  const dataLoader = useReservationListDataLoader(false, undefined, [
    GQLRESERVATION_STATUS.WAITING,
    GQLRESERVATION_STATUS.PENDING,
  ])

  return (
    <EvixCardWithTableWrapper>
        <EvixCardHeader title="최근 진료예약 내역" moreView link={SiteUrls.Manager.Reservation.Main} />
        <EvixCard noPadding>
            <ReservationListableTable
                dataLoader={dataLoader}
                linkToDetail={SiteUrls.Manager.Reservation.Detail}
                showSearch={false}
                fromAdmin={false}
                fromHospitalManager={false}
                fromHospitalManagerDashboard={true}
            />
        </EvixCard>
    </EvixCardWithTableWrapper>
  )
}
