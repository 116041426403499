import React from 'react'
import { GQLPatient } from '@mdpp/common/lib/@types/server.schema'
import { ReservationBasicInfoBoxStyle } from '../../../common/reservation/ReservationCommon'
import { PatientHealthInfoCard } from './infoCard/PatientHealthInfoCard'
import { PatientBodyInfoCard } from './infoCard/PatientBodyInfoCard'
import { BodyContainer } from "../../../common/layout/BodyContainer"
import { ODHSpace } from "@mdpp/od-react-belt";

interface IReservationPatientInfoBoxProps {
  patient: GQLPatient
  hidePatient?: boolean
}

export const ReservationPatientInfoBox: React.FC<IReservationPatientInfoBoxProps> = props => {
  const { patient, hidePatient = false } = props
  return (
    <BodyContainer>
      {/* <PatientInfoCard
          patient={patient}
          infoStyle={ReservationBasicInfoBoxStyle.OutsideRoom}
          hidePatient={hidePatient}
        />
        <PatientHistoryInfoCard patient={patient} infoStyle={ReservationBasicInfoBoxStyle.OutsideRoom} />
        <PatientSmokeDrinkInfoCard patient={patient} infoStyle={ReservationBasicInfoBoxStyle.OutsideRoom} /> */}
      <PatientHealthInfoCard patient={patient} infoStyle={ReservationBasicInfoBoxStyle.OutsideRoom} />

      <ODHSpace w={16} />

      {/* <PatientMedicationSurgeryHistoryInfoBox
          patient={patient}
          infoStyle={ReservationBasicInfoBoxStyle.OutsideRoom}
        />
        <PatientEtcInfoBox patient={patient} /> */}
      <PatientBodyInfoCard patient={patient} infoStyle={ReservationBasicInfoBoxStyle.OutsideRoom} />
    </BodyContainer>
  )
}
