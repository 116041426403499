import React from 'react'
import { ConfirmModal } from '../common/layout/ConfirmModal'
import IconWarningRed from '../assets/common/warning-red.svg'
import { useHistory } from 'react-router-dom'

interface ProofExpiredProps {}

export const ProofExpired: React.FC<ProofExpiredProps> = props => {
  const [showWaitApprovalModal, setShowWaitApprovalModal] = React.useState(true)

  const history = useHistory()

  const mainHome = () => {
    let path = `/landing`
    history.push(path)
  }

  return (
    <div>
      {/* 인증 만료 안내 Modal */}
      <ConfirmModal
        style={{ width: 420, height: 250 }}
        isTitle={true}
        title={'이메일 인증 유효 시간 만료'}
        titleIcon={IconWarningRed}
        isDescriptionIcon={false}
        description={[
          '가입 메일 인증 유효기간이 만료되었습니다.',
          <br />,
          '다시 회원가입 절차 및 이메일 인증을 진행해주세요.',
        ]}
        descriptionIcon={''}
        hideCancel={true}
        onOkPress={mainHome}
        onCancelPress={() => setShowWaitApprovalModal(false)}
      />
    </div>
  )
}
