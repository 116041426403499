import moment from 'moment-timezone'
import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { ODHSpace } from '@mdpp/od-react-belt'
import { GQLMedicationDispensing, GQLReservation } from '@mdpp/common/lib/@types/server.schema'
import { PatientMemoComp } from '../../../common/memo/PatientMemoComp'
import { ReservationStatusBadge } from '../../../common/ReservationStatusBadge'
import { ImageButton, BP } from '../../../common/RebeccaButton'
import BackIcon from '../../../assets/back_arrow.svg'
import { SiteUrls } from '../../../SiteUrls'
import { ODIcon } from '@mdpp/od-react-belt'
import { ReservationPharmactisistStatusBadge } from '../../../common/ReservationPharmactisistStatusBadge'
import { familyGender } from '../../../utils/webUtils'

interface IReservationNoticeLeftProps {
  reservation: GQLMedicationDispensing
}

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 10px;
`

const NameTitle = styled.div`
  font-size: 18px;
  font-weight: 600;
  font-style: normal;
  display: flex;
  margin-right: auto;
`

const InfoArea = styled.div`
  font-size: 14px;
  color: #7f8386;
  display: flex;
  align-items: center;
  white-space: nowrap;
`

const BadgeArea = styled.div`
  display: flex;
  align-items: center;
  margin-left: 16px;
  font-weight: normal;
`
const MetaBox = styled.div`
  display: flex;
  flex-direction: column;
`

export const ReservationNoticeLeft: React.FC<IReservationNoticeLeftProps> = props => {
  const { reservation } = props
  const birthDate = moment(reservation?.reservation?.patient.fullBirthDate, 'YYYYMMDD').format('YYYY.MM.DD')
  const linkUrl = SiteUrls.Pharmacy.Reservation.Main

  return (
    <Wrapper>
      <Link to={linkUrl}>
        <img className="single-icon-btn-new" src={BackIcon} alt={'이전으로 가기'} />
        {/*<ImageButton images={{default: BackIcon}} type={BP.TYPE.InvisibleWithIcon} className="single-icon-btn"/>*/}
      </Link>
      <MetaBox>
        <NameTitle>
          {reservation?.reservation?.proxyReservationInfo
            ? reservation?.reservation?.proxyReservationInfo.name!
            : reservation?.reservation?.patient.name}
          <BadgeArea>{reservation && <ReservationPharmactisistStatusBadge status={reservation.status} />}</BadgeArea>
        </NameTitle>
        <InfoArea>
          {reservation?.reservation?.proxyReservationInfo ? (
            <>
              {reservation.reservation.proxyReservationInfo?.birthDate} ·{' '}
              {familyGender(reservation.reservation.proxyReservationInfo?.sex) + '성'} ·{' '}
              {reservation?.reservation?.proxyReservationInfo.phone
                ? reservation?.reservation?.proxyReservationInfo.phone
                : reservation?.reservation?.patient.phone}
            </>
          ) : (
            <>
              {birthDate} · {reservation?.reservation?.patient.gender === 'male' ? '남성' : '여성'} ·
              {reservation?.reservation?.patient.phone}
            </>
          )}
          <ODHSpace w={13} />
          {/*<PatientMemoComp reservation={reservation} />*/}
        </InfoArea>
      </MetaBox>
    </Wrapper>
  )
}
