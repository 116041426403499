import styled from "styled-components/macro";
import {COLORS} from '../../styles/colors'

// 회원가입 관련 스타일
export const Wrap = styled.div `
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  min-width: 1184px;
`

export const SignUpWrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 1184px;
	display: flex;
	flex-direction: column;
	padding: 80px 80px 80px 90px;
  margin: 100px 0;
  border-radius: 8px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.08);
  border: solid 1px #dadddf;
  background-color: ${COLORS.WHITE};
`

// 좌우측 패딩 제외
export const SignUpWrapNoPadding = styled.div`
  display: flex;
  flex-direction: column;
  width: 1184px;
	display: flex;
	flex-direction: column;
	padding: 70px 0 80px 0;
  margin: 100px 0;
  border-radius: 8px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.08);
  border: solid 1px #dadddf;
  background-color: ${COLORS.WHITE};
`

export const Header = styled.div `
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  padding-bottom: 46px;
`

export const PageTitleWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 9px;
`

export const DoctorLogo = {width: 87, height: 43}
export const PharmacyLogo = {width: 87, height: 43}

export const PageTitle = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 26px;
  font-weight: bold;
  color: ${COLORS.GRAY42};
`

export const ProcessWrap = styled.div`
  display: flex;
  flex-direction: row;
`

export const Line = styled.div `
  width: 100%;
  height: 1px;
  /* margin: 0 170px 0 -90px; */
  background-color: ${COLORS.GRAYD8};
`

//step
export const Step = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  width: 155px;
  z-index: 1;

  &:first-child {
    &:before {
      left: 78px;
    }
  }

  &:last-child {
    &:before {
      right: 78px;
    }
  }

  &:before {
    position: absolute;
    top: 22px;
    left: 0;
    right: 0;
    height: 2px;
    background-color: ${COLORS.GRAYC9};
    z-index: -1;
    content: '';
  }

  &.active {
    > div {
      &:first-child {
        
        background-color: ${COLORS.blue200};
        border: solid 2px ${COLORS.blue200};

        > span {
          color: ${COLORS.WHITE};
        }
      }
    }

    > span {
      color: ${COLORS.BLACK};
      font-weight: bold;
    }
  }
`

export const StepCircle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  border-radius: 50px;
  border: solid 2px ${COLORS.GRAYC9};
  background-color: ${COLORS.WHITE};

  > span {
    font-size: 22px;
    font-weight: 500;
    color:  ${COLORS.GRAYAB};
  }
`

export const StepText = styled.span`
  padding-top: 9px;
  font-size: 13px;
  font-weight: 500;
  color:  ${COLORS.GRAYAB};
`
export const Container = styled.div `
  display: flex;
  flex-direction: column;
  color: ${COLORS.BLACK};
`

// container 
export const Inner = styled.div`
  width: 700px;
`

export const InnerBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 394px;
  padding: 139px 0 50px 0;
  border-radius: 6px;
  border: solid 1px ${COLORS.GRAYD5};
`

export const InnerBox2 = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 394px;
  padding: 139px 0 50px 0;
  border-radius: 6px;
  border: solid 1px ${COLORS.GRAYD5};
`


export const TitleWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`

export const Title = styled.h2`
  font-size: 20px;
  font-weight: bold;
  color: ${COLORS.GRAY42};
  margin: 0;
  padding-bottom: 15px;
`

// Term
export const TermWrap = styled.div`
  height: 340px;
  padding: 0;
  border-radius: 4px;
  border: solid 1px ${COLORS.GRAYCB};
  overflow-y: hidden;
`

export const BtnWrap = styled.div`
  width: 340px;
  margin: 0 auto;
`


export const RequiredWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`

export const Oval = styled.span`
  display: inline-block;
  width: 6px;
  height: 6px;
  border-radius: 6px;
  background-color: ${COLORS.red100};
`

export const OvalGray = styled.span`
  display: inline-block;
  width: 2px;
  height: 2px;
  border-radius: 2px;
  background-color: ${COLORS.GRAYAB};
  margin-top: 8px
`

export const RequiredText = styled.span`
  font-size: 13px;
  font-weight: 500;
  color: ${COLORS.GRAYAB};
  padding-left: 3px;

  &.gray7c { color: #7C818B}
`
export const LabelWrap  = styled.div `
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-bottom: 9px;
`

export const Label  = styled.label `
  font-size: 15px;
  font-weight: 500;
  line-height: normal;
  color: ${COLORS.GRAY42};
  margin-bottom: 0;
  margin-right: 3px;

  .text-blue {
    color: ${COLORS.blue200};
  }

  ~ span { margin-top: 3px; }
`

export const FormWrap = styled.div `
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;  
  gap: 20px;

  &.recom {
    justify-content: flex-start;
    gap: 12px;
  }

  &.password {
    .od-input {
      padding-top: 0;
      font-size: 35px;
    }
  }
`

export const RecomInfoWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 5px;
  padding-top: 36px;

  > img {
    width: 14px;
    height: 14px;
    margin-top: 4px;
  }

  > div {
    font-size: 13px;
    font-weight: 500;
    font-style: normal;
    line-height: 1.54;
    letter-spacing: normal;
    color: ${COLORS.GRAYAB};
  }
`

export const WarningMessageWrap = styled.div `
  width: 100%;
  padding: 5px 0 10px 0;
  min-height: 34px;
  
  &.hide {
    display: none;
  }
  
  > span {
    display: block;
    line-height: 19px;
    font-size: 13px;
    font-weight: 500;

    /* &:last-child {
      padding: 0 0 10px 0;
    } */

    &.error {
      color: ${COLORS.red100}
    }

    ~ ul {
        margin-top: 5px;
      }
  }

  ul, li {
    padding: 0;
    margin: 0;
  }  
  
  li {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    font-size: 13px;
    font-weight: 500;
    gap: 4px;
    color: ${COLORS.GRAYAB};
    letter-spacing: -.3px;
    line-height: 1.54;

    &:before {
      position: absolute;

    }

    &.align-center {
      align-items: center;
    }
  }
`

export const FormBlock  = styled.div `
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 340px;
  /* gap: 9px; */

  &.w-100 {
    width: 100%;
  }

  &.warning-hide {
    > div:nth-child(2) {
      .od-input-wrapper + div {
        display: none;
      }
    }
  }

  .hp-wrap {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 10px;
  }

`

export const HospitalNameWrap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;

  &.w-100 { 
    width: 100%; 
  }
` 

// 사업자등록증 
export const PharmacyNameWrap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
  width: 100%;
`

export const FileLabel = styled.label`
  font-size: 13px;
  font-weight: 500;
  font-style: normal;
  color: ${COLORS.GRAY42};
  margin-bottom: 0;
  padding: 12px 5px;
  height: 44px;
  width: 120px;
  line-height: 18px;
  border-radius: 4px;
  border: 1px solid ${COLORS.GRAYAB2};
  background-color: ${COLORS.WHITE};
  text-align: center;
  cursor: pointer;
`

export const FileInput = styled.label`
  font-size: 13px;
  font-weight: 500;
  font-style: normal;
  color: ${COLORS.GRAY42};
  margin-bottom: 0;
  padding: 12px;
  height: 44px;
  width: calc(100% - 130px);
  line-height: 18px;
  border-radius: 4px;
  border: 1px solid #dfe0e2;
  background-color: #eeeff1;
  text-align: left;
  cursor: pointer;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  span {
    color: #acb4bc;
  }
`
