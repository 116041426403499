import React from 'react'
import { LogoutPage } from '../login/LogoutPage'
import { SiteUrls } from '../SiteUrls'
import { useODDoctorAppContext } from './context/ODDoctorAppContext'

interface IDoctorLogoutPageProps {}

export const DoctorLogoutPage: React.FC<IDoctorLogoutPageProps> = props => {
  const { logout } = useODDoctorAppContext()
  return <LogoutPage logout={logout} urlAfterLogout={SiteUrls.Doctor.Login} />
}
