import React from 'react'
import { Card, CardBody } from 'reactstrap'

interface IEvixCardProps {
  style?: React.CSSProperties
  fit?: boolean // 카드의 디폴트 flexGrow = 2 인데, 이를 0 으로 함.
}

export const EvixCardTwoThirds: React.FC<IEvixCardProps> = props => {
  return (
    <Card style={{ width: '66%', padding: 10, ...(props.style || {}) }}>
      <CardBody>{props.children}</CardBody>
    </Card>
  )
}