import { ODHSpace } from '@mdpp/od-react-belt'
import React from 'react'
import moment from 'moment-timezone'
import styled from 'styled-components'
import { DateRangeSelector } from './DateRangeSelector'
import { ReservationStatusSelector } from './ReservationStatusSelector'

export interface IPharmacyReservationToolbarValue {
  status: PharmacyReservationStatus
  startDate: string //  YYYY-MM-DD => '' empty text 면 모든 날짜
  endDate: string //  YYYY-MM-DD => '' empty text 면 모든 날짜
}

export enum PharmacyReservationStatus {
  ALL = 'ALL', // 모든조제
  REQUESTED = 'REQUESTED', // 조제신청
  ACCEPTED = 'ACCEPTED', // 조제승인
  REFUSED = 'REFUSED', // 조제거절
  CANCELED = 'CANCELED', // 조제취소
  PAIED = 'PAIED', //  결제완료
  NOT_PAIED = 'NOT_PAIED', // 결제실패
  FINISHED = 'FINISHED', // 결제완료
  FAX_FINISHED = 'FAX_FINISHED', // FAX 발송 완료
}

interface IReservationToolBar {
  value: IPharmacyReservationToolbarValue
  onChange: (changedValue: IPharmacyReservationToolbarValue) => void
}

const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  flex-grow: 1;
  height: 37px;
`

const TreatmentWrapper = styled.div`
  display: inline-flex;
  gap: 8px;

  @media (max-width: 992px) {
  }
`

export const PharmacyReservationToolBar: React.FC<IReservationToolBar> = props => {
  const { value, onChange } = props
  // const { status, endDate, startDate } = value
  const [reservationStatus, setReservationStatus] = React.useState(value.status)
  const [dateRange, setDateRange] = React.useState<string[]>(['', ''])

  const onChangeReservationStatus = (changedValue: PharmacyReservationStatus) => {
    setReservationStatus(changedValue)
  }

  const onChangeDateRange = (changedValue: string[]) => {
    setDateRange(changedValue)
  }

  React.useEffect(() => {
    onChange({ status: reservationStatus, startDate: dateRange[0], endDate: dateRange[1] })
  }, [onChange, reservationStatus, dateRange])

  return (
    <Wrapper>
      <TreatmentWrapper>
        <ReservationStatusSelector value={reservationStatus} onChange={onChangeReservationStatus} />
        <DateRangeSelector value={dateRange} onChange={onChangeDateRange} />
      </TreatmentWrapper>
    </Wrapper>
  )
}
