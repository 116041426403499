import { ODListableOption, ODListableResponseType, ODModalSize, usePickerTableModal } from '@mdpp/od-react-belt'
import React from 'react'
import { GQLMedicalSubject } from '@mdpp/common/lib/@types/server.schema'
import { useCommonAPIs } from '../../common/hooks/useCommonAPIs'
import './scss/table.scss'

export interface IMedicalSubjectListableOption extends ODListableOption {
  filter: string
}

const THeads = ['진료과목']

export function useMedicalSubjectPickerModal() {
  const { listMedicalSubjects } = useCommonAPIs()

  const dataLoader = React.useCallback(
    async function dataLoader(
      page: number,
      pageSize: number,
      afterKey: string | null,
      options: IMedicalSubjectListableOption
    ): Promise<ODListableResponseType<GQLMedicalSubject>> {
      const r = await listMedicalSubjects({ page, pageSize, ...options })
      console.log(r)
      return r as ODListableResponseType<GQLMedicalSubject>
    },
    [listMedicalSubjects]
  )

  return usePickerTableModal<GQLMedicalSubject, IMedicalSubjectListableOption>({
    size: ODModalSize.Normal,
    rounded: false,
    pickDefaultOptions: {
      confirmButtonTitle: '확인',
      title: '진료과목 선택',
    },
    dataLoader,
    keyExtractor: v => v.medicalSubjectId,
    numColumns: 1,
    renderTH: (index: number) => THeads[index],
    renderTD: (index: number, value: GQLMedicalSubject) => value.name,

  })
}
