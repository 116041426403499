import { ODIcon, ODIcons, ODModal, ODModalSize, ODVSpace, useODModalContext } from '@mdpp/od-react-belt'
import React, { useEffect } from 'react'
import { Moment } from 'moment'
import moment from 'moment/moment'
import styled from 'styled-components/macro'
import {
  GQLPOPUP_TYPE,
  GQLPopupInfoCreationInput,
  GQLPopupInfoDeleteInput,
  GQLPopupInfoUpdateInput,
} from '@mdpp/common/lib/@types/server.schema'
import { DatePicker } from '../../../common/DatePicker'
import { useCommonAPIs } from '../../../../src/common/hooks/useCommonAPIs'
import { EvixPrescriptionFileUpload, IEvixPrescriptionFile } from "../../EvixPrescriptionFileUpload";
import uuid from "uuid";
import { WebUtils } from "../../../utils/webUtils";
import { IFileObject, IFileServer } from "../../hooks/useFileMutationAPI";

interface IPopupSettingModalPopupProps {
  isOpen: boolean
  onClose: () => void
  onCreate: (input: GQLPopupInfoCreationInput) => void
  onUpdate: (input: GQLPopupInfoUpdateInput) => void
  onDelete: (input: GQLPopupInfoDeleteInput) => void
  noticeId: number
  refresh: (input: boolean) => void
}

export const WebPopupSettingModalPopup: React.FC<IPopupSettingModalPopupProps> = props => {
  const { isOpen, onClose, onCreate, onUpdate, onDelete, noticeId, refresh } = props
  const { getPopupInfo } = useCommonAPIs()
  
  useEffect(() => {
    const updateWebPopupInfo = async () => {
      try {
        const p = await getPopupInfo({noticeId,  type: GQLPOPUP_TYPE.WEB})
        if (p) {
          const obj: IFileObject[] = [{
            id: p.popupImageId?.toString() ?? '',
            fileName: p.popupImage?.fileName ?? '',
            isDeleting: false,
            isUploading: false,
            link: p.popupImage?.link ?? '',
          }]
          setPopupId(p.popupId)
          setContent(p.content)
          setFiles(obj)
          setPostingStart(p.postingStart)
          setPostingEnd(p.postingEnd)
          setOnetime(p.onetime)
          setDateRange([moment(p.postingStart).format('YYYY-MM-DD'),
            moment(p.postingEnd).format('YYYY-MM-DD')])
          setPExists(true)
        }
      } catch (e) {
        console.log('popupinfo is not exists')
        setPExists(false)
      }
    }
  
    updateWebPopupInfo()
  }, [])
  
  
  
  const [pExists, setPExists] = React.useState(false)
  const [content, setContent] = React.useState('')
  const [title, setTitle] = React.useState('')
  const [postingStart, setPostingStart] = React.useState(null)
  const [postingEnd, setPostingEnd] = React.useState(null)
  const [onetime, setOnetime] = React.useState(false)
  
  const initStart = moment().add(1, 'day').format('YYYY-MM-DD')
  const initEnd = moment().add(2, 'week').format('YYYY-MM-DD')
  const initDateRange = [postingStart || initStart, postingEnd || initEnd]
  const [dateRange, setDateRange] = React.useState(initDateRange)
  const { confirm } = useODModalContext()
  const [files, setFiles] = React.useState<IEvixPrescriptionFile[]>([])
  const [uploadFile, setUploadFile] = React.useState<File | null>(null)
  const [popupId, setPopupId] = React.useState<number | undefined>(undefined)
  
  const [fs] = React.useState(() => {
    const fileServer: IFileServer = {
      async addFiles(f: File[]): Promise<Array<IFileObject | null>> {
        const results: Array<IFileObject | null> = []
        for (const file of f) {
          try {
            setUploadFile(file)
            const obj: IFileObject = {
              id: file.name,
              fileName: file.name,
              isDeleting: false,
              isUploading: false,
              link: file.name,
            }
            results.push(obj)
            setPExists(true)
          } catch (ex) {
            WebUtils.showError(ex)
            results.push(null)
          }
        }
        return results
      },
      async deleteFile(f: IFileObject): Promise<boolean> {
        try {
          // await deletePrescriptionFile({ id: parseInt(f.id, 10) })
          setPExists(false)
          return true
        } catch (ex) {
          WebUtils.showError(ex)
          return false
        }
      },
    }
    return fileServer
  })
  
  const clickSave: React.MouseEventHandler<HTMLButtonElement> = (e: any) => {
    e.preventDefault()
    
    if (!content) {
      onCreate({
        noticeId: noticeId, content, title, postingStart, postingEnd, onetime: onetime,
        type: GQLPOPUP_TYPE.WEB, file: uploadFile ?? null
      })
    } else {
      if (popupId)
        onUpdate({ popupId, content, title, postingStart, postingEnd, onetime, file: uploadFile ?? null})
    }
    refresh(true)
  }
  
  const clickDelete: React.MouseEventHandler<HTMLButtonElement> = (e: any) => {
    e.preventDefault()
    setFiles([])
    if (popupId)
      onDelete({ popupId })
    refresh(true)
  }

  const isBeforeDay = (a : Moment, b : Moment) => {
    if (!moment.isMoment(a) || !moment.isMoment(b)) return false;
    
    const aYear = a.year();
    const aMonth = a.month();
    
    const bYear = b.year();
    const bMonth = b.month();
    
    const isSameYear = aYear === bYear;
    const isSameMonth = aMonth === bMonth;
    
    if (isSameYear && isSameMonth) return a.date() < b.date();
    if (isSameYear) return aMonth < bMonth;
    return aYear < bYear;
  }

  const isInclusivelyAfterDay = (a : Moment, b : Moment) => {
    if (!moment.isMoment(a) || !moment.isMoment(b)) return false;
    return !isBeforeDay(a, b);
  }

  const updateDateRange = (ds: any) => {
    if (ds && ds.length > 1) {
      setPostingStart(ds[0])
      setPostingEnd(ds[1])
    }
  }
  const changeFileHavingId = React.useCallback((fileId: string, cb: (f: IEvixPrescriptionFile) => void) => {
    setFiles(fileList => {
      const f = fileList.find(item => item.id === fileId)
      if (f) {
        cb(f)
      }
      return [...fileList]
    })
  }, [])
  
  const handleFileDelete = async (file: IEvixPrescriptionFile) => {
    if (
      !(await confirm({
        title: '삭제하시겠습니까?',
        message: (
          <>
            {file.fileName}
            <br />
            처방전을 삭제하시겠습니까?`
          </>
        ),
        yes: '삭제',
        no: '취소',
      }))
    ) {
      return
    }
    
    try {
      changeFileHavingId(file.id, f => (f.isDeleting = true))
      await fs.deleteFile(file)
      
      setFiles(fileList => fileList.filter(v => v.id !== file.id))
      WebUtils.showSuccess('삭제되었습니다.')
    } catch (ex) {
      WebUtils.showError(ex)
      changeFileHavingId(file.id, f => (f.isDeleting = false))
    }
  }
  
  const handleFileAdd = async (filesToAdd: File[]) => {
    try {
      const fakeList: IEvixPrescriptionFile[] = filesToAdd.map(f => ({
        id: uuid.v4(),
        fileName: f.name,
        link: '',
        isDeleting: false,
        isUploading: true,
      }))
      setFiles(fakeList)
      const fileUploadedList = await fs.addFiles(filesToAdd)
      
      setFiles(fileList => {
        fakeList.forEach((fakeFile, fileIndex) => {
          const index = fileList.findIndex(f => f.id === fakeFile.id)
          if (index >= 0) {
            // @ts-ignore
            fileList[index] = fileUploadedList[fileIndex] // 임시로 null 을 허용하고 뒤에서 filter out 해버린다.
          }
        })
        return [...fileList].filter(v => !!v)
      })
      //WebUtils.showSuccess('업로드되었습니다.')
    } catch (ex) {
      WebUtils.showError(ex)
    }
  }

  return (
    <ODModal isOpen={isOpen} toggle={onClose} size={ODModalSize.Large}>
      <Wrapper>
        <ODVSpace h={44} />
        <Title>팝업 설정</Title>
        <Subtitle>게시기간이 지나면 팝업은 자동 OFF 됩니다.</Subtitle>
        <CloseButtonBox onClick={onClose}>
          <ODIcon icon={ODIcons.CoreX} style={CloseIconStyle} />
        </CloseButtonBox>
        <Inner>
          <ODVSpace h={28} />
          <InputTitle>
            이미지 파일 업로드
          </InputTitle>
          <ODVSpace h={10} />
            <EvixPrescriptionFileUpload
              files={files}
              onClickDeleteFile={handleFileDelete}
              onAddFiles={handleFileAdd}
              disabled={false}
              acceptFileType={'image/jpeg, image/png'}
            />
          <ODVSpace h={20} />
          <InputTitle>
            게시기간
            <ODVSpace h={9} />
            <DateWrapper>
              <DatePicker dateRange={dateRange} onChange={updateDateRange} isOutsideRange={(day) => !isInclusivelyAfterDay(day, moment())}></DatePicker>
            </DateWrapper>
          </InputTitle>

          <ODVSpace h={40} />
        </Inner>
        <Footer>
          <SaveButton onClick={ clickDelete } disabled={ !pExists }>삭제</SaveButton>
          <SaveButton onClick={ clickSave } disabled={ !pExists }>설정</SaveButton>
        </Footer>
      </Wrapper>
    </ODModal>
  )
}

const CheckboxLabel = styled.label`
  line-height: 18px;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.26px;
  color: var(--gray600);
  margin: 0;
  margin-left: 5px;
`

const CloseIconStyle = {
  fontSize: 18,
  color: '#dbdbdb',
}
const Wrapper = styled.form`
  background-color: var(--white);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  position: relative;
  padding-left: 40px;
`
const Inner = styled.div`
  padding-right: 40px;
`
const Title = styled.h1`
  line-height: 29px;
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 0.29px;
`

const Subtitle = styled.h6`
  color: #ababab;
`

const CloseButtonBox = styled.div`
  position: absolute;
  top: 25px;
  right: 25px;
  cursor: pointer;
`
const InputTitle = styled.label`
  line-height: 18px;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.26px;
  color: var(--gray600);
  margin: 0;
  width: 100%;
`
const Footer = styled.div`
  display: flex;
  justify-content: space-evenly;
  padding-bottom: 40px;
`

const SaveButton = styled.button`
  width: 100px;
  height: 40x;
  padding: 15px 0 13px 3px;
  background-color: var(--mpPrimary500);
  font-size: 15px;
  line-height: 13px;
  color: var(--white);
  letter-spacing: 0.21px;
  text-align: center;
  border-radius: 10px;

  &:disabled {
    background-color: lightgray;
  }
`

const InputField = styled.input`
  width: 100%;
  padding: 7px 15px 7px 9px;
  border-radius: 4px;
  border: solid 1px var(--gray300);
  background-color: var(--white);
`

const Checkbox = styled.input`

`

const DateWrapper = styled.div`
  input {
    border: 1px solid #ababab;
    font-size: 16px;
    height: 32px;
    border-radius: 5px;
  }
`