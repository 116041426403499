import { STAT_SUM_BY_TYPE as BY_DATE } from '@mdpp/common'
import { ODIcons, ODVSpace, SingleQueryWrapper, useODQuery2 } from '@mdpp/od-react-belt'
import React from 'react'
import { Col, Row } from 'reactstrap'
import styled from 'styled-components'
import {
  GQLReservationChartDataInput,
  GQLReservationChartDataResponse,
  GQLSTAT_SUM_BY_TYPE,
} from '@mdpp/common/lib/@types/server.schema'
import { EvixDashboardCard } from '../../common/card/EvixDashboardCard'
import { RecentNoticeList } from '../../common/notice/list/RecentNoticeList'
import { SiteUrls } from '../../SiteUrls'
import { WebUtils } from '../../utils/webUtils'
import { ManagerRecentReservationChart } from './ManagerRecentReservationChart'
import { ManagerRecentReservationContainer } from './ManagerRecentReservationContainer'
import { ManagerRecentTransactionLogContainer } from './ManagerRecentTransactionLogContainer'
import {Page, PageContainer, PageContent, PageHeader} from "../../common/page/Page";

interface IManagerDashboardContainerProps {}

const GQL_DOCTOR_DASHBOARD = `
query {
  listNotice(data: {page:1, pageSize: 10, showOnHospitalManager: 1}) {
    list {
      noticeId
      title
      createdAt
    }
  }
  getHospitalOfManager {
    numDoctors
    numVisibleDoctors
    numTotalReservations
    numFinishedReservations
    numCancelledReservations
  }
}
`

export const GQL_GET_RESERVATION_CHART_DATA = `
query getReservationChartData($data: ReservationChartDataInput!) {
  getReservationChartData(data: $data) {
    chartData {
      label
      numReservation
      numFinished
      numNoShow
      numCancelled
    }
  }
}
`

export const ManagerDashboardContainer: React.FC<IManagerDashboardContainerProps> = props => {
  const { api } = useODQuery2<any, any>(GQL_DOCTOR_DASHBOARD, { skip: true })
  const { api: getChartData } = useODQuery2<GQLReservationChartDataInput, GQLReservationChartDataResponse>(
    GQL_GET_RESERVATION_CHART_DATA,
    { pickFirstKey: true, wrapVariablesWithData: true, skip: true }
  )

  const [byDate, setByDate] = React.useState<BY_DATE>(BY_DATE.DAY)
  const [chartData, setChartData] = React.useState<GQLReservationChartDataResponse>({ chartData: [] })

  React.useEffect(() => {
    getChartData({
      hospitalId: null,
      chartType: (byDate as unknown) as GQLSTAT_SUM_BY_TYPE,
      n: 10,
    }).then(setChartData, WebUtils.showError)
  }, [getChartData, byDate])

  return (
    <PageContainer>
      <SingleQueryWrapper deps={[]} getter={() => api({})}>
        {({ data }) => {
          const {
            listNotice: { list: noticeList },
            getHospitalOfManager: {
              numDoctors,
              numVisibleDoctors,
              numTotalReservations,
              numFinishedReservations,
              numCancelledReservations,
            },
          } = data
          return (
            <Page>
              <PageHeader>
                <EvixDashboardCard
                    title="총 의사 인원"
                    mainColor="var(--second)"
                    contents={`${numVisibleDoctors.toLocaleString()} / ${numDoctors.toLocaleString()}`}
                />
                <EvixDashboardCard
                    title="진료 예약 누적 건수"
                    icon={ODIcons.CoreClipboard}
                    mainColor="#f2ac39"
                    contents={`${numTotalReservations.toLocaleString()}`}
                />
                <EvixDashboardCard
                    title="진료 완료 누적 건수"
                    icon={ODIcons.Inquiry}
                    mainColor="var(--mpPrimary500)"
                    contents={`${numFinishedReservations.toLocaleString()}`}
                />
                <EvixDashboardCard
                    title="예약 부도/취소 누적 건수"
                    icon={ODIcons.CoreXCircle}
                    mainColor="#d41512"
                    contents={`${numCancelledReservations.toLocaleString()}`}
                />
              </PageHeader>
              <PageContent>
                <ManagerRecentReservationChart value={{ byDate: byDate, data: chartData }} onChange={setByDate} />
                <ManagerRecentReservationContainer />
                <ManagerRecentTransactionLogContainer />
                <hr/>
                <RecentNoticeList
                    notices={noticeList}
                    viewItemLink={SiteUrls.Manager.Notice.Item}
                    viewMoreLink={SiteUrls.Manager.Notice.Main}
                />
              </PageContent>
            </Page>
          )
        }}
      </SingleQueryWrapper>
    </PageContainer>
  )
}
