import React from 'react'
import styled, { css } from 'styled-components'

import TypeFaceLogo_SingleLine from '../assets/mediPangPang_textType_v1.1_103_9.svg'

import { ODVSpace } from '@mdpp/od-react-belt'
import { COLORS } from '../styles/colors'
import { useTitle } from '../common/Footer'

const isMobile = /iPhone|Android/.test(navigator.userAgent)

const Wrapper = styled.div`
  max-width: 100%;
  min-height: 100%;
  padding: 83px 192px 74px;
  background-color: var(--white);

  @media screen and (max-width: 600px) {
    padding: 17px 20px 20px;
  }
`

const Header = styled.header`
  width: 100%;
  border-bottom: 1px solid ${COLORS.mpPrimary400};
  margin-bottom: 43.3px;

  @media screen and (max-width: 600px) {
    display: none;
    text-align: center;
    border-bottom: 0;

    & img {
      display: none;
    }
  }
`

const HeaderText = styled.h1`
  font-size: 40px;
  font-weight: bold;
  line-height: 1;
  color: #343b42;
  margin: 0;
`

const ContentWrapper = styled.div`
  flex: 1;
`

const Frame = styled.iframe`
  width: 100%;
  height: ${isMobile ? '95vh' : '68vh'};
  border: ${isMobile ? 0 : '1px solid var(--gray300)'};
`

type Props = {
  privacy: boolean
}

const Title = () => {
  useTitle('개인정보 수집 및 이용 동의 | 메디팡팡')
  return <div />
}

export const PrivacyConsentPage: React.FC<Props> = ({ privacy }) => {
  return (
    <Wrapper>
      <Header>
        <Title />
        <img src={TypeFaceLogo_SingleLine} alt="logo" />
        <ODVSpace h={24} />
        <HeaderText>개인정보 수집 및 이용 동의</HeaderText>
        <ODVSpace h={16.7} />
      </Header>
      <ContentWrapper>
        <Frame src="https://medi-pangpang-cs.s3.ap-northeast-2.amazonaws.com/notice/%EA%B0%9C%EC%9D%B8%EC%A0%95%EB%B3%B4%EC%88%98%EC%A7%91%EB%B0%8F%EC%9D%B4%EC%9A%A9%EB%8F%99%EC%9D%98/common/index.html" />
      </ContentWrapper>
    </Wrapper>
  )
}
