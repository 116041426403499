import { ODIcon, ODIcons, ODModal, ODModalSize, ODHSpace, ODHSpread } from '@mdpp/od-react-belt'
import React from 'react'
import styled from 'styled-components/macro'
import { Button, BP } from '../../../common/RebeccaButton'


interface IRoomExitPopupProps {
  isOpen: boolean
  onClose: () => void
  onSubmit: () => Promise<void>
}

export const RoomExitPopup: React.FC<IRoomExitPopupProps> = props => {
  const { isOpen, onClose, onSubmit } = props

  return (
    <>
      <ODModal rounded={true} isOpen={isOpen} toggle={onClose} size={ODModalSize.Normal}>
        <ODHSpread style={{ padding: 20, alignItems: 'center', borderBottom: '1px solid #E1E3E5' }}>
          <ModalTitle>진료실 나가기</ModalTitle>
          <CloseButtonBox onClick={onClose}>
            <ODIcon icon={ODIcons.CoreX} style={CloseIconStyle} />
          </CloseButtonBox>
        </ODHSpread>
        <Wrapper>
          <Inner>
            {props.children}
          </Inner>
          <Footer style={{borderTop: '1px solid #E1E3E5'}}>
            <FooterGroupRight>
              <Button 
                size={BP.SIZE.M}
                onClick={(e: any) => {
                e.preventDefault()
                onClose()
              }}
              >나가지 않음</Button>
              <ODHSpace w={8} />
              <Button
                  size={BP.SIZE.M}
                  type={BP.TYPE.Primary}
                  onClick={(e: any) => {
                  e.preventDefault()
                  onSubmit()
                }}
              >진료실 나가기
              </Button>
            </FooterGroupRight>
          </Footer>
        </Wrapper>
      </ODModal>
    </>
  )
}


const CloseIconStyle = {
  fontSize: 18,
  color: '#dbdbdb',
}
const Wrapper = styled.div`
  background-color: #ffffff;
  position: relative;
`
const Inner = styled.div`
  display: flex;
  justify-content: flex-start;
  padding: 20px;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.3px;
  color: #202223;
`

const ModalTitle = styled.h1`
  font-size: 20px;
  font-weight: 500;
  text-align: left;
  margin: 0;
`

const CloseButtonBox = styled.div`
  right: 25px;
  cursor: pointer;
`

const Footer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
  height: 68px;
`

const FooterGroupRight = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  border-top: 1px solid #E1E3E5;
`
