import { IODListableEventContext, ODListableOption } from '@mdpp/od-react-belt'
import { GQLDoctor } from '@mdpp/common/lib/@types/server.schema'

export const DoctorPrimaryKey = 'doctorId'

// modifiable fields only.
export interface ICTDoctor extends Pick<GQLDoctor, 'doctorId' | 'name'> {
  // more fields, mapped (if needed)
}

export interface IDoctorListableOption extends ODListableOption {
  filter: string
}

export interface IDoctorEntityContext extends IODListableEventContext<GQLDoctor> {
  //
}
