//Library
import React, { useEffect, useState, useRef } from 'react'
import styled, { css } from 'styled-components'
import { BrowserRouter, Route, Switch, Link } from 'react-router-dom'
import { SiteUrls } from '../SiteUrls'
import { ODEntity, ODEntityInput, ODEntityLabeled, ODWebUtils } from '@mdpp/od-react-belt'
import Select from 'react-select'
import { Swiper, SwiperSlide } from 'swiper/react'
// import SwiperCore, { Pagination } from 'swiper'

import ReactDOM from 'react-dom'
import 'react-responsive-carousel/lib/styles/carousel.min.css' // requires a loader
import { Carousel } from 'react-responsive-carousel'

import 'swiper/swiper-bundle.css'

//Components
import { CenterWithFooterContainerForLanding } from '../common/layout/CenterWithFooterContainerForLanding'
import { LandingNav } from './LandingNav'

//Assets
import DisplayImage from '../assets/edited_for_web_charles-deluvio-6OF-Ly-5oJY-unsplash.png'
import GooglePlay from '../assets/google-play-badge.svg'
import AppStore from '../assets/Download_on_the_App_Store_Badge_KR_RGB_blk_100317.svg'
import ForPatient from '../assets/iPhone_12_Pro_Silver_Vertical.png'
import ForDoctor from '../assets/imac.png'
import ManWithMask from '../assets/illust_manWithMask.png'
import Bus from '../assets/illust_bus.png'
import Wheelchair from '../assets/illust_wheelchair.png'
import IconMail from '../assets/icMail_outline.svg'
import IconDownload from '../assets/icDownload.svg'
import { COLORS } from '../styles/colors'
import { BlockingLoadBox } from '@mdpp/od-react-belt'
import { LandingModal } from '../common/landing/modal/LandingModal'
import moment from 'moment'

import './css/index.scss'
import MdppText from '../assets/landing/mdpp-text.svg'
import MdppLogo from '../assets/mediPangPang_BI_v1.0_197_51@3x.png'
import badgeApple from '../assets/landing/badge-Apple.svg'
import badgeGoogle from '../assets/landing/badge-Google.svg'
import sec1Img from '../assets/landing/sec1/Illust@3x.png'

import sec2Img from '../assets/landing/sec2/sec2@3x.png'
import artwork from '../assets/landing/artwork.svg'
// import hospital from '../assets/landing/hospitals.png'

import iconMenu from '../assets/landing/icon-menu.svg'
import IconClose from '../assets/icClose.svg'
import IconContact from '../assets/icContact_white.svg'
import IconManual from '../assets/icManual.svg'
import IconDoctor from '../assets/icDoctor_white.svg'
import IconMedicalManager from '../assets/icMedical_manager.svg'
import IconArrow from './images/icon-arrow.png'
import b01 from '../assets/landing/sec3/b01.png'
import b02 from '../assets/landing/sec3/b02.png'
import b03 from '../assets/landing/sec3/b03.png'
import b04 from '../assets/landing/sec3/b04.png'
import b05 from '../assets/landing/sec3/b05.png'
import b06 from '../assets/landing/sec3/b06.png'
import b07 from '../assets/landing/sec3/b07.png'
import b08 from '../assets/landing/sec3/b08.png'
import b09 from '../assets/landing/sec3/b09.png'
import b10 from '../assets/landing/sec3/b10.png'
import b11 from '../assets/landing/sec3/b11.png'
import b12 from '../assets/landing/sec3/b12.png'
import b13 from '../assets/landing/sec3/b13.png'
import b14 from '../assets/landing/sec3/b14.png'
import b15 from '../assets/landing/sec3/b15.png'
import b16 from '../assets/landing/sec3/b16.png'
import b17 from '../assets/landing/sec3/b17.png'
import b18 from '../assets/landing/sec3/b18.png'
import b19 from '../assets/landing/sec3/b19.png'
import b20 from '../assets/landing/sec3/b20.png'
import _ from 'lodash'

// SwiperCore.use([Pagination])

const DEFAULT_DATA = {}

// 건강 기록 서비스
const cards = [
  {
    title: '코로나 비대면 진료',
    message:
      '초진 환자는 코로나 재택 치료가 어렵다는 점 알고 계셨나요? 메디팡팡은 초진 환자도 비대면 진료를 받을 수 있어요.',
    selected: true,
  },
  {
    title: '스마트 진료지원',
    message: '내 과거 건강기록의 손쉬운 공유로, 주치의의 정확한 진단과 치료를 돕는 스마트한 환자가 될 수 있어요.',
    selected: true,
  },
  {
    title: 'AI 건강분석',
    message: '국가건강검진 자료 뿐만 아니라 진료기록을 더해 내 건강상태를 분석하고 질병을 예측 해 보세요.',
    selected: false,
  },
  {
    title: 'AI 피부분석',
    message: '촬영 한번으로 내 피부상태를 분석하고 결과를 공유 해 보세요. 피부과 비대면진료도 걱정없어요!',
    selected: false,
  },
  {
    title: '간편 보험청구',
    message: '남들도 다 하는 간편보험청구? 건강기록을 확인하고 잊고있던 보험금까지 찾아보세요.',
    selected: false,
  },
  {
    title: '일상생활 건강관리',
    message: '홈 피트니스, 영양제 추천, 다이어트, 이제 내 건강기록으로 맞춤관리 하세요.',
    selected: false,
  },
]
const renderItem = (item: React.ReactNode, options: number) => (
  <div className="slide-wrap" key={_.uniqueId()}>
    <div className="slide-inner">
      <div className="overlay">
        <div className="slide-title">{cards[options].title}</div>
        <div className="slide-message">{cards[options].message}</div>
      </div>
      <div className={`img img${item}`} />
    </div>
  </div>
)
const carousel = <div>{[1, 2, 3, 4, 5, 6].map(renderItem)}</div>

// const tabItem = (item: React.ReactNode, options: number) => (
//   // <div className={`chip`} >{cards[options].title}</div>
//   <div className="chip">{cards[options].title}</div>
// );

type optionType = {
  label: string
  value: string
}

export const Landing: React.FC = props => {
  const [values, setValues] = useState({
    name: '',
    email: '',
    ph: '',
    field: '',
    inquiry: '',
    consent: true,
  })

  const options: optionType[] = [
    { value: '서비스제휴', label: '서비스제휴' },
    { value: '비대면진료', label: '비대면진료' },
    { value: '건강기록제공', label: '건강기록제공' },
  ]

  useEffect(() => {
    document.title = '메디팡팡'
    let noticeTime = localStorage.getItem('noticeTime')
    // console.log('noticeTime: ',noticeTime);
    let diff = 0

    //오늘 그만 띠우기를 누군적이 있음
    if (noticeTime != null) {
      const startdt = moment(noticeTime, 'YYYY-MM-DD HH:mm')
      const enddt = moment()
      // console.log('startdt: ',startdt);
      // console.log('enddt: ',enddt);
      diff = enddt.diff(startdt, 'hours')
      // console.log('diff: ',diff);
      if (isNaN(diff)) {
        // console.log('diff is NaN!');
        noticeTime = null
      }
      //1일 지나면 띠우기
      if (noticeTime == null || diff >= 24) {
        //3월 23일 기준 2주간만 띠움.음 4월 5일까자
        const maxDate = moment('2021-04-05', 'YYYY-MM-DD')
        diff = maxDate.diff(enddt, 'days')
        // console.log('diff2: ',diff);
        if (diff < 0) {
          //4월 5일 이후는 안띠움
          setLoading(false)
        } else {
          //하루가 지났고 4월 5일 이전엔 띠움
          setLoading(true)
        }
      } else {
        // 하루가 안지났으면 안띠움
        setLoading(false)
      }
    } else {
      //오늘 그만 띠우기 누룬적이 없는 경우
      const maxDate = moment('2021-04-05', 'YYYY-MM-DD')
      const current = moment()
      diff = maxDate.diff(current)
      if (diff < 0) {
        setLoading(false)
      } else {
        setLoading(true)
      }
    }
  }, [])

  const apis = {}

  const newMediRef = useRef<HTMLDivElement>(null)
  const pageRef = useRef<HTMLDivElement>(null)

  const onNewMediClick = () => {
    newMediRef.current?.scrollIntoView({ behavior: 'smooth' })
  }

  const onPageClick = () => {
    pageRef.current?.scrollIntoView({ behavior: 'smooth' })
  }

  const Icon = styled.img`
    object-fit: fill;
    opacity: 0.9;
    fill: white;
    height: ${props => props.height || 'none'};
    width: ${props => props.width || 'none'};
  `

  const [loading, setLoading] = React.useState(true)
  const handleClose = () => {
    setLoading(false)
  }

  const submitNoticeClose = () => {
    // console.log('오늘하루 닫기')
    setLoading(false)
    localStorage.setItem('noticeTime', JSON.stringify(moment.utc().local().format('YYYY-MM-DD HH:mm')))
  }

  const [click, setClick] = useState(false)
  const handleClick = () => setClick(!click)

  const goDockerHanderPharmacy = () => {
    document.location.replace('/pharmacy')
  }
  const goDockerHanderDoctor = () => {
    document.location.replace('/doctor')
  }
  const goDockerHanderManager = () => {
    document.location.replace('/manager')
  }

  const goDockerHanderDoctorSignUp = () => {
    document.location.replace('/doctor/signup')
  }

  const goDockerHanderPharmacySignUp = () => {
    document.location.replace('/pharmacy/signup')
  }

  const [currentSlide, setCurrentSlide] = useState(0)

  // slide와 우측 chip selected와 매핑
  const updateCurrentSlide = (index: any) => {
    if (currentSlide !== index) {
      setCurrentSlide(index)
    }
  }

  const slide0 = () => {
    setCurrentSlide(0)
  }
  const slide1 = () => {
    setCurrentSlide(1)
  }

  const slide2 = () => {
    setCurrentSlide(2)
  }
  const slide3 = () => {
    setCurrentSlide(3)
  }

  const slide4 = () => {
    setCurrentSlide(4)
  }

  const slide5 = () => {
    setCurrentSlide(5)
  }

  const sendMail = (info: any) => {
    if (info.name === '') {
      return ODWebUtils.showInfo('이름을 입력해주세요.')
    }
    if (info.email === '' && info.ph === '') {
      return ODWebUtils.showInfo('이메일 또는 휴대폰 번호를 입력해주세요.')
    }
    if (info.inquiry === '') {
      return ODWebUtils.showInfo('문의내용을 입력해주세요.')
    }
    if (info.consent === false) {
      return ODWebUtils.showInfo('개인정보 수집,이용에 동의해주세요.')
    }
    window.open(`mailto:mdpp_support@evidnet.co.kr?subject=[메디팡팡 문의]${
      info.field
    }관련 문의드립니다. &body=[이름]:${info.name}           %0D%0A [이메일]:${
      info.email
    }               %0D%0A [휴대폰 번호]:${info.ph}            %0D%0A [분야선택]:${
      info.field
    }           %0D%0A [문의내용]:${info.inquiry}                        %0D%0A [개인정보 수집,이용 동의여부]:${
      info.consent === true ? '동의' : '미동의'
    }             %0D%0A %0D%0A %0D%0A *빠른 시간안에 답변 드리겠습니다.%0D%0A 메디팡팡 (주)에비드넷
			서울특별시 강남구 강남대로 292 뱅뱅빌딩 3층 1566-8013`)

    setValues({
      name: '',
      email: '',
      ph: '',
      field: '',
      inquiry: '',
      consent: true,
    })
  }

  const [btnClick, setBtnClick] = useState('patient')
  const handlePatientBtnClick = () => setBtnClick('patient')
  const handleDoctorBtnClick = () => setBtnClick('doctor')
  const selectValueHandler = (obj: any) => {
    const newObj = _.cloneDeep(obj)
    setValues({
      ...values,
      field: newObj.value,
    })
  }

  return (
    <BrowserRouter>
      <Switch>
        <Route
          path={SiteUrls.Landing.Root}
          render={() => {
            return (
              <CenterWithFooterContainerForLanding>
                <div className="landing-wrap">
                  <BlockingLoadBox show={loading} />
                  {loading && (
                    <LandingModal
                      isOpen
                      onSubmit={submitNoticeClose}
                      onClose={() => handleClose()}
                      initialEmail={'email'}
                      initialInquirer={'inquirer'}
                      initialPhoneNumber={'phoneNumber'}
                    />
                  )}

                  <div className="section section1">
                    {/* 헤더  */}
                    <div className="header">
                      <div className="logo-wrap">
                        <img src={MdppLogo} className="symbol" alt="메디팡팡 로고 이미지" />
                      </div>
                      <button type="button" className="icon-menu" onClick={handleClick}>
                        <img src={click ? IconClose : iconMenu} alt={click ? '닫기 이미지' : '메뉴 이미지'} />
                      </button>
                      <div className={click ? 'menu-wrap  mobile' : 'menu-wrap'}>
                        <button
                          type="button"
                          onClick={onNewMediClick}
                          className="text-menu"
                          style={{ textDecorationLine: 'none' }}
                        >
                          {click ? <Icon src={IconManual} height="24px" alt="브로슈어" /> : null}
                          메디팡팡 서비스
                        </button>

                        <button
                          type="button"
                          onClick={onPageClick}
                          className="text-menu"
                          style={{ textDecorationLine: 'none' }}
                        >
                          {click ? <Icon src={IconContact} height="24px" alt="제휴문의" /> : null}
                          제휴문의
                        </button>
                        {/* <Link
                          to="/pharmacy"
                          onClick={goDockerHanderPharmacy}
                          className="btn-menu primary"
                          style={{ textDecorationLine: 'none' }}
                        >
                          {click ? <Icon src={IconMedicalManager} height="24px" alt="약국" /> : null}
                          약국
                        </Link> */}
                        <Link
                          to="/doctor"
                          onClick={goDockerHanderDoctor}
                          className="btn-menu primary"
                          style={{ textDecorationLine: 'none' }}
                        >
                          {click ? <Icon src={IconMedicalManager} height="24px" alt="의사" /> : null}
                          의사
                        </Link>
                        <Link
                          to="/manager"
                          onClick={goDockerHanderManager}
                          className="btn-menu black"
                          style={{ textDecorationLine: 'none', width: '120px' }}
                        >
                          {click ? <Icon src={IconDoctor} height="24px" alt="병원관리자" /> : null}
                          병원관리자
                        </Link>
                      </div>
                    </div>
                    {/* 내용  */}
                    <div className="inner" title="메디팡팡 홈화면 이미지">
                      <div className="video-wrap">
                        <div className="msg-wrap">
                          <div className="main-title">클릭 몇 번으로 비대면 진료</div>
                          <div className="main-msg">
                            아플 때, 힘들게 병원가는 대신 <br />
                            메디팡팡으로 바로 진료 받으세요
                          </div>

                          <div className="usage-wrap pc">
                            <div className="mobile-usage">
                              <div className="title">모바일 이용</div>
                              <div className="type">
                                <a
                                  href={'https://play.google.com/store/apps/details?id=com.evidnet.medi_pangpang'}
                                  target={'_blank'}
                                >
                                  <img src={badgeGoogle} alt="다운로드 구글플레이" />
                                </a>
                                <a
                                  href={
                                    'https://apps.apple.com/kr/app/%EB%A9%94%EB%94%94%ED%8C%A1%ED%8C%A1/id1550889868'
                                  }
                                  target={'_blank'}
                                >
                                  <img src={badgeApple} alt="다운로드 애플앱스토어" />
                                </a>
                              </div>
                            </div>

                            <div className="visual-wrap">
                              <img alt="메디팡팡 앱 메인화면 이미지" src={sec1Img} />
                            </div>
                          </div>
                        </div>

                        {/* 영상 */}
                        <div className="video-section">
                          <video controls className={btnClick === 'patient' ? 'video' : 'video hide'}>
                            <source
                              src={'https://medi-pangpang-cs.s3.ap-northeast-2.amazonaws.com/video/patient.mp4'}
                            ></source>
                          </video>

                          <video controls className={btnClick === 'doctor' ? 'video' : 'video hide'}>
                            <source
                              src={'https://medi-pangpang-cs.s3.ap-northeast-2.amazonaws.com/video/doctor.mp4'}
                            ></source>
                          </video>

                          <div className="btn-wrap">
                            <button
                              type="button"
                              className={btnClick === 'patient' ? 'chip selected' : 'chip'}
                              onClick={handlePatientBtnClick}
                            >
                              환자편
                            </button>
                            <button
                              type="button"
                              className={btnClick === 'doctor' ? 'chip selected' : 'chip'}
                              onClick={handleDoctorBtnClick}
                            >
                              의사편
                            </button>
                          </div>
                        </div>
                      </div>

                      <div className="usage-wrap mobile">
                        <div className="mobile-usage">
                          <div className="title">모바일 이용</div>
                          <div className="type">
                            <a
                              href={'https://play.google.com/store/apps/details?id=com.evidnet.medi_pangpang'}
                              target={'_blank'}
                            >
                              <img src={badgeGoogle} alt="다운로드 구글플레이" />
                            </a>
                            <a
                              href={'https://apps.apple.com/kr/app/%EB%A9%94%EB%94%94%ED%8C%A1%ED%8C%A1/id1550889868'}
                              target={'_blank'}
                            >
                              <img src={badgeApple} alt="다운로드 애플앱스토어" />
                            </a>
                          </div>
                        </div>

                        <div className="visual-wrap">
                          <img alt="메디팡팡 앱 메인화면 이미지" src={sec1Img} />
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* section2 */}
                  <div className="section section2" id="page-2" ref={newMediRef}>
                    <div className="msg-wrap">
                      <div className="main-title">
                        <span>차별화된</span> <span>비대면 진료 서비스를</span> <span>경험하세요</span>
                      </div>
                      <div className="main-msg">
                        메디팡팡은 나의 건강기록(진료기록, 약 처방 등)을 가져와 의사에게 공유할 수 있어요.
                        <br />
                        코로나 재택 치료가 필요한 초진 환자도 비대면 진료가 가능합니다.
                      </div>
                    </div>
                    <div className="inner">
                      <div className="inner-img-wrap">
                        <img
                          src={sec2Img}
                          alt={`메디팡팡 스마트진료 - 20개 제휴 의료기관의 진료, 투약, 검사, 시술 및 수술 기록 및 공공기관의 진료, 처방약, 건강검진 기록을 스마트하게 공유`}
                          title="메디팡팡 스마트진료"
                        />
                      </div>
                      <div className="inner-msg-wrap">
                        <div className="sub-title">
                          내 건강기록을 활용한
                          <br />
                          개인 맞춤형 비대면 진료
                        </div>
                        <div className="btn-wrap">
                          <button
                            type="button"
                            className="btn line"
                            onClick={() =>
                              window.open(
                                'https://medi-pangpang-cs.s3.ap-northeast-2.amazonaws.com/brochure/medi-pangpang-brochure.pdf',
                                '_blank'
                              )
                            }
                          >
                            서비스 소개
                          </button>
                          <button type="button" className="btn primary" onClick={onPageClick}>
                            회원병원 제휴문의
                          </button>
                        </div>
                        {/* <div className="sub-title last">
                          마이데이터와 함께하는 <br />
                          조금 더 특별한 비대면 진료
                        </div>
                        <div className="btn-wrap">
                          <button type="button" className="btn line">
                            비대면진료 브로슈어
                          </button>
                        </div> */}
                      </div>
                    </div>
                  </div>
                  {/* section3 */}
                  <div className="section section3">
                    <div className="msg-wrap">
                      <div className="main-title">
                        <span>메디팡팡은</span> <span>다양한 의료기관과</span> <span>협력하고 있어요</span>
                      </div>
                      <div className="main-msg">
                        이름만 마이데이터, 내 기록은 없었던 지금까지의 의료마이데이터는 잊으세요.
                      </div>
                    </div>
                    <div onClick={onPageClick} className="artwork-wrap" style={{ cursor: 'pointer' }}>
                      <img src={artwork} alt="협력병원 제휴신청 요청 물음표 이미지" />
                      <span>다음은 어디일까요?</span>
                    </div>

                    {/* 병원 로고 스와이퍼 */}
                    <div className="swiper-wrap">
                      <Swiper
                        slidesPerView={1}
                        spaceBetween={0}
                        pagination={{
                          clickable: true,
                        }}
                        breakpoints={{
                          '320': {
                            slidesPerView: 1.5,
                          },

                          '350': {
                            slidesPerView: 1.65,
                          },

                          '380': {
                            slidesPerView: 1.8,
                          },

                          '420': {
                            slidesPerView: 2,
                          },

                          '540': {
                            slidesPerView: 2.5,
                          },

                          '640': {
                            slidesPerView: 3,
                          },

                          '740': {
                            slidesPerView: 3.5,
                          },

                          '840': {
                            slidesPerView: 4,
                          },

                          '940': {
                            slidesPerView: 4.5,
                          },

                          '1040': {
                            slidesPerView: 5,
                          },

                          '1140': {
                            slidesPerView: 5.5,
                          },

                          '1240': {
                            slidesPerView: 6,
                          },

                          '1340': {
                            slidesPerView: 6.5,
                          },

                          '1440': {
                            slidesPerView: 7,
                          },

                          '1540': {
                            slidesPerView: 7.5,
                          },

                          '1640': {
                            slidesPerView: 8,
                          },

                          '1740': {
                            slidesPerView: 8.5,
                          },

                          '1840': {
                            slidesPerView: 9,
                          },

                          '1940': {
                            slidesPerView: 9.5,
                          },

                          '2040': {
                            slidesPerView: 10,
                          },
                        }}
                        className="mySwiper"
                      >
                        <SwiperSlide>
                          <div className="banner-wrap">
                            <div className="banner-top">
                              <div className="banner">
                                <span className="logo-bg">
                                  <img src={b01} alt="가천길병원" />
                                </span>
                                <span className="h-name">가천길병원</span>
                              </div>
                            </div>
                            <div className="banner-bottom">
                              <div className="banner mgt24">
                                <span className="logo-bg">
                                  <img src={b11} alt="분당차병원" />
                                </span>
                                <span className="h-name">분당차병원</span>
                              </div>
                            </div>
                          </div>
                        </SwiperSlide>
                        <SwiperSlide>
                          <div className="banner-wrap">
                            <div className="banner-top">
                              <div className="banner">
                                <span className="logo-bg">
                                  <img src={b02} alt="강동경희대학교병원" />
                                </span>
                                <span className="h-name">강동경희대학교병원</span>
                              </div>
                            </div>
                            <div className="banner-bottom">
                              <div className="banner mgt24">
                                <span className="logo-bg">
                                  <img src={b12} alt="순천향대학교부속서울병원" />
                                </span>
                                <span className="h-name">순천향대학교부속서울병원</span>
                              </div>
                            </div>
                          </div>
                        </SwiperSlide>
                        <SwiperSlide>
                          <div className="banner-wrap">
                            <div className="banner-top">
                              <div className="banner">
                                <span className="logo-bg">
                                  <img src={b03} alt="강동성심병원" />
                                </span>
                                <span className="h-name">강동성심병원</span>
                              </div>
                            </div>
                            <div className="banner-bottom">
                              <div className="banner mgt24">
                                <span className="logo-bg">
                                  <img src={b13} alt="순천향대학교부속천안병원" />
                                </span>
                                <span className="h-name">순천향대학교부속천안병원</span>
                              </div>
                            </div>
                          </div>
                        </SwiperSlide>
                        <SwiperSlide>
                          <div className="banner-wrap">
                            <div className="banner-top">
                              <div className="banner">
                                <span className="logo-bg">
                                  <img src={b04} alt="강원대학교병원" />
                                </span>
                                <span className="h-name">강원대학교병원</span>
                              </div>
                            </div>
                            <div className="banner-bottom">
                              <div className="banner mgt24">
                                <span className="logo-bg">
                                  <img src={b14} alt="아주대학교병원" />
                                </span>
                                <span className="h-name">아주대학교병원</span>
                              </div>
                            </div>
                          </div>
                        </SwiperSlide>
                        <SwiperSlide>
                          <div className="banner-wrap">
                            <div className="banner-top">
                              <div className="banner">
                                <span className="logo-bg">
                                  <img src={b05} alt="경희대학교병원" />
                                </span>
                                <span className="h-name">경희대학교병원</span>
                              </div>
                            </div>
                            <div className="banner-bottom">
                              <div className="banner mgt24">
                                <span className="logo-bg">
                                  <img src={b15} alt="원주세브란스기독병원" />
                                </span>
                                <span className="h-name">원주세브란스기독병원</span>
                              </div>
                            </div>
                          </div>
                        </SwiperSlide>
                        <SwiperSlide>
                          <div className="banner-wrap">
                            <div className="banner-top">
                              <div className="banner">
                                <span className="logo-bg">
                                  <img src={b06} alt="대구가톨릭대학교병원" />
                                </span>
                                <span className="h-name">대구가톨릭대학교병원</span>
                              </div>
                            </div>
                            <div className="banner-bottom">
                              <div className="banner mgt24">
                                <span className="logo-bg">
                                  <img src={b16} alt="원광대학교병원" />
                                </span>
                                <span className="h-name">원광대학교병원</span>
                              </div>
                            </div>
                          </div>
                        </SwiperSlide>
                        <SwiperSlide>
                          <div className="banner-wrap">
                            <div className="banner-top">
                              <div className="banner">
                                <span className="logo-bg">
                                  <img src={b07} alt="동국대학교일산병원" />
                                </span>
                                <span className="h-name">동국대학교일산병원</span>
                              </div>
                            </div>
                            <div className="banner-bottom">
                              <div className="banner mgt24">
                                <span className="logo-bg">
                                  <img src={b17} alt="인천세종병원(혜원의료재단)" />
                                </span>
                                <span className="h-name">인천세종병원(혜원의료재단)</span>
                              </div>
                            </div>
                          </div>
                        </SwiperSlide>
                        <SwiperSlide>
                          <div className="banner-wrap">
                            <div className="banner-top">
                              <div className="banner">
                                <span className="logo-bg">
                                  <img src={b08} alt="명지병원" />
                                </span>
                                <span className="h-name">명지병원</span>
                              </div>
                            </div>
                            <div className="banner-bottom">
                              <div className="banner mgt24">
                                <span className="logo-bg">
                                  <img src={b18} alt="한양대학교병원" />
                                </span>
                                <span>한양대학교병원</span>
                              </div>
                            </div>
                          </div>
                        </SwiperSlide>
                        <SwiperSlide>
                          <div className="banner-wrap">
                            <div className="banner-top">
                              <div className="banner">
                                <span className="logo-bg">
                                  <img src={b09} alt="부산대학교병원" />
                                </span>
                                <span className="h-name">부산대학교병원</span>
                              </div>
                            </div>
                            <div className="banner-bottom">
                              <div className="banner mgt24">
                                <span className="logo-bg">
                                  <img src={b19} alt="강릉아산병원" />
                                </span>
                                <span className="h-name">강릉아산병원</span>
                              </div>
                            </div>
                          </div>
                        </SwiperSlide>
                        <SwiperSlide>
                          <div className="banner-wrap">
                            <div className="banner-top">
                              <div className="banner">
                                <span className="logo-bg">
                                  <img src={b10} alt="부천세종병원(혜원의료재단)" />
                                </span>
                                <span className="h-name">부천세종병원(혜원의료재단)</span>
                              </div>
                            </div>
                            <div className="banner-bottom">
                              <div className="banner mgt24">
                                <span className="logo-bg">
                                  <img src={b20} alt="경상국립대학교병원" />
                                </span>
                                <span className="h-name">경상국립대학교병원</span>
                              </div>
                            </div>
                          </div>
                        </SwiperSlide>
                      </Swiper>
                    </div>
                  </div>

                  {/* section4 */}
                  <div className="section section4">
                    <div className="inner">
                      <div className="msg-wrapper">
                        <div className="msg-wrap">
                          <div className="main-title">
                            <span>내 건강기록을 활용한</span>
                            <span> 맞춤 건강 서비스는</span> <span>계속 추가될 예정이에요</span>
                          </div>
                          <div className="main-msg">
                            내 건강기록을 활용한 맞춤 건강 서비스 <br />
                            지금 바로 경험해보세요.
                          </div>
                        </div>
                        <div className="chip-wrap" title="메디팡팡 서비스 소개">
                          <button className={currentSlide === 0 ? 'chip selected' : 'chip'} onClick={slide0}>
                            {cards[0].title}
                          </button>
                          <button className={currentSlide === 1 ? 'chip selected' : 'chip'} onClick={slide1}>
                            {cards[1].title}
                          </button>
                          <button className={currentSlide === 2 ? 'chip selected' : 'chip'} onClick={slide2}>
                            {cards[2].title}
                          </button>
                          <button className={currentSlide === 3 ? 'chip selected' : 'chip'} onClick={slide3}>
                            {cards[3].title}
                          </button>
                          <button className={currentSlide === 4 ? 'chip selected' : 'chip'} onClick={slide4}>
                            {cards[4].title}
                          </button>
                          <button className={currentSlide === 5 ? 'chip selected' : 'chip'} onClick={slide5}>
                            {cards[5].title}
                          </button>
                          {/* <div onClick={slide1}>{[1,2,3,4,5].map(tabItem)}</div> */}
                        </div>
                      </div>
                      <Carousel
                        selectedItem={currentSlide}
                        emulateTouch={true}
                        onChange={updateCurrentSlide}
                        showArrows={false}
                        showStatus={false}
                        swipeable={true}
                        {...props}
                      >
                        {carousel.props.children}
                      </Carousel>
                    </div>
                  </div>

                  {/* banner */}
                  <div
                    className="blog-banner-wrap"
                    onClick={() => window.open('https://blog.naver.com/medi-pangpang', '_blank')}
                  >
                    <div className="blog-banner"></div>
                  </div>

                  {/* section5 */}
                  <div className="section section5" id="page-1" ref={pageRef}>
                    <div className="msg-wrap">
                      <div className="main-title">메디팡팡과 함께 하세요!</div>
                      <div className="main-msg">
                        의료 서비스의 디지털 전환은 선택이 아니라 필수입니다.
                        <br />
                        새로운 의료 시장 변화에 주도적인 참여를 원하시면, 지금 바로 문의하세요!
                      </div>
                    </div>
                    <div className="signup-wrap">
                      {/* <Link to='/pharmacy' onClick={goDockerHanderPharmacySignUp} className="btn-menu primary">
												<span>약국 회원가입</span>
												<Icon src={IconArrow} className='icon-arrow'  alt="약국 회원가입"/>
											</Link> */}
                      <Link to="/doctor/signup" onClick={goDockerHanderDoctorSignUp} className="btn-menu primary">
                        <span>의사 회원가입</span>
                        <Icon src={IconArrow} className="icon-arrow" alt="의사 회원가입" />
                      </Link>
                    </div>
                    <div className="letter-wrap">
                      <div>
                        <div className="letter-title">제휴문의 및 신청</div>
                        <div className="letter-inner">
                          <div className="input-wrap">
                            <div className="label">이름</div>
                            <input
                              type="text"
                              name="name"
                              placeholder={'연락받으실 분 성함'}
                              value={values.name}
                              onChange={e => {
                                setValues({ ...values, name: e.target.value })
                              }}
                              className="form-control"
                            />
                          </div>
                          <div className="input-wrap">
                            <div className="label">이메일</div>
                            <input
                              type="text"
                              name="email"
                              value={values.email}
                              placeholder={'답변드릴 이메일주소'}
                              onChange={e => {
                                setValues({ ...values, email: e.target.value })
                              }}
                              className="form-control"
                            />
                          </div>

                          <div className="input-wrap">
                            <div className="label">분야 선택</div>
                            <div className="input-block">
                              <Select
                                name="분야선택"
                                placeholder="원하는 분야를 선택하세요"
                                options={options}
                                value={options.find(op => {
                                  return op.value === values.field
                                })}
                                onChange={e => {
                                  selectValueHandler(e)
                                }}
                              />
                            </div>
                          </div>

                          <div className="input-wrap">
                            <div className="label">휴대폰 번호</div>
                            <input
                              type="text"
                              name="hp"
                              value={values.ph}
                              placeholder={'연락 가능한 휴대전화번호'}
                              onChange={e => {
                                setValues({ ...values, ph: e.target.value })
                              }}
                              className="form-control"
                            />
                          </div>

                          <div className="input-wrap">
                            <div className="label">문의 내용</div>
                            <div className="input-block input-style">
                              <textarea
                                value={values.inquiry}
                                maxLength={200}
                                onChange={e => {
                                  setValues({ ...values, inquiry: e.target.value })
                                }}
                                placeholder="소속되신 기관명/ 주소 /  연락가능한 시간 / 원하시는 연락방법 등을  입력 해 주시면 정확한 답변을  편안하게 받으실 수 있습니다"
                              />
                              <div className="count">{values.inquiry.length}/200</div>
                            </div>
                          </div>

                          <div className="checkbox-wrap">
                            <input
                              type="checkbox"
                              id="consent"
                              name="consent"
                              checked={values.consent}
                              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                setValues({ ...values, consent: e.target.checked })
                              }
                            />
                            <label htmlFor="consent">답변을 위한 개인정보 수집, 이용에 동의합니다.</label>
                          </div>

                          <div className="box">
                            <ul>
                              <li>1. 수집하는 개인정보 항목 : 회사/부서명, 성명, 전화번호, 이메일</li>
                              <li>2. 수집 및 이용 목적 : 문의 내용 확인, 접수 및 답변 회신</li>
                              <li>
                                3. 보유 및 이용 기간 : 문의에 대한 답변 및 요청사항의 처리 또는 업무 제휴 등 목적 달성
                                후 즉시 폐기(관련 법령에 따라 보존할 필요가 있는 경우에는 해당 보존기간)
                              </li>
                            </ul>
                          </div>
                        </div>

                        <div className="btn-wrap">
                          <button
                            className="btn primary"
                            onClick={() => {
                              sendMail(values)
                            }}
                            type="button"
                          >
                            문의 및 신청
                          </button>
                        </div>
                      </div>

                      {/* <div className="letter-title">제휴문의 및 신청</div> */}
                      {/* <div className="send-email">
											  <div className='copyright'><a
											    href={'mailto:mdpp_support@evidnet.co.kr?subject=[메디팡팡 문의] : &body=[성명]:           %0D%0A [소속]:               %0D%0A [이메일]:            %0D%0A [연락처]:           %0D%0A [문의내용]:             %0D%0A %0D%0A %0D%0A *빠른 시간안에 답변 드리겠습니다.%0D%0A 메디팡팡 (주)에비드넷 ' +
											    '서울특별시 강남구 강남대로 292 뱅뱅빌딩 3층 ' +
											    '1566-8013'}>mdpp_support@evidnet.co.kr</a>
											  </div>

											  <div className="email-btn">
											    <a
											      href={'mailto:mdpp_support@evidnet.co.kr?subject=[메디팡팡 문의] : &body=[성명]:           %0D%0A [소속]:               %0D%0A [이메일]:            %0D%0A [연락처]:           %0D%0A [문의내용]:             %0D%0A %0D%0A %0D%0A *빠른 시간안에 답변 드리겠습니다.%0D%0A 메디팡팡 주식회사 (주)' +
											      '서울특별시 강남구 강남대로 292 뱅뱅빌딩 3층 ' +
											      '1566-8013'}><Icon src={IconMail} height='24px' alt="메일 보내기"/></a>
											  </div>
											</div> */}
                    </div>
                  </div>
                </div>
              </CenterWithFooterContainerForLanding>
            )
          }}
        />
      </Switch>
    </BrowserRouter>
  )
}
