import classNames from 'classnames'
import { ODVSpace } from '@mdpp/od-react-belt'
import React, { HTMLAttributes, useState, useRef, forwardRef } from 'react'
import { InputType } from 'reactstrap/lib/Input'
import styled from 'styled-components/macro'
import { WarningMessageWrap } from './style/SignUpStyle'
import { COLORS } from '../../src/styles/colors'

interface IEvixInputProps {
  style?: object
  id?: string
  type?: InputType
  disabled?: boolean
  placeholder?: string
  value?: string
  onChangeRaw?: (e: React.FormEvent<HTMLInputElement>) => void
  onChange?: (data: any) => void
  onEnter?: () => void
  guideText?: string
  inputProps?: HTMLAttributes<HTMLInputElement>
  title?: string
  validators?: Function
  validatorMsg?: object
  error?: string
  warning?: string
  success?: string
  maxLength?: number
  ref?: any
  readOnly?: boolean
}

export const EvixInput: React.FC<IEvixInputProps> = forwardRef((props, ref: any) => {
  const {
    style,
    id,
    placeholder,
    value,
    onChangeRaw,
    onChange,
    onEnter,
    guideText,
    type,
    title,
    inputProps = {},
    validators,
    maxLength,
    readOnly,
    ...cnProps
  } = props
  const cn = classNames('od-input', cnProps)
  const cnWrapper = classNames('od-input-wrapper', {
    disabled: cnProps.disabled,
  })

  const [showFocus, setShowFocus] = React.useState(true)
  const [validatorMsg, setValidatorMsg] = useState({ errors: [], warnings: [] })
  const [errors, setErrors] = useState([])
  const [warnings, setWarnings] = useState([])
  const [success, setSuccess] = useState('')

  const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
    onChangeRaw?.(e)
    // @ts-ignore
    onChange?.(e.target.value)
  }

  const handleKeydown = (e: any) => {
    if (e.key === 'Enter') {
      onEnter?.()
      e.preventDefault()
    }
  }

  const validate = (value: any) => {
    if (validators) {
      const { success = '', errors, warnings } = validators(value)
      setErrors(errors)
      setWarnings(warnings)
      setSuccess(success)
    }
    setValidatorMsg({ errors: errors, warnings: warnings })
  }

  const handleFocus = (showFocus: React.FocusEvent<HTMLInputElement>) => {
    setShowFocus(!showFocus)
  }

  const ErrMsg = { color: COLORS.red100, fontSize: 13, fontWeight: 500 }
  const WarningMsg = { color: COLORS.GRAYAB, fontSize: 13, fontWeight: 500 }
  const SuccessMsg = { color: COLORS.blue200, fontSize: 13, fontWeight: 500 }

  React.useEffect(() => {
    validate(value)
  }, [value])

  return (
    <div style={style}>
      <InputWrap className={cnWrapper}>
        <input
          type={type}
          style={value ? undefined : placeholderCss}
          id={id}
          disabled={cnProps.disabled}
          className={cn}
          placeholder={placeholder}
          value={value}
          onChange={handleChange}
          onKeyPress={handleKeydown}
          onFocus={handleFocus}
          {...inputProps}
          title={title}
          maxLength={maxLength}
          ref={ref}
          readOnly={readOnly}
        />
      </InputWrap>

      <WarningMessageWrap className={errors || warnings || success ? '' : 'hide'}>
        {errors && showFocus === false && value?.length !== 0 && (
          <>
            {errors.map((err: string, index: number) => (
              <span key={index} style={ErrMsg}>
                {' '}
                {err}{' '}
              </span>
            ))}
          </>
        )}

        {warnings && showFocus === false && value?.length !== 0 && (
          <>
            {warnings.map((warning: string, index: number) => (
              <span key={index} style={WarningMsg}>
                {' '}
                {warning}{' '}
              </span>
            ))}
          </>
        )}

        {success && showFocus === false && value?.length !== 0 && <span style={SuccessMsg}> {success} </span>}
      </WarningMessageWrap>
    </div>
  )
})

const InputWrap = styled.div`
  .od-input {
    height: 42px;
    font-size: 15px;
    font-weight: 500;
    background-color: ${COLORS.GRAYEE};
    border-radius: 4px;
    color: ${COLORS.GRAY42} !important;
  }
`

const placeholderCss = { fontSize: 13, padding: '0.5rem', color: '#ababab !important' }
