import {
  BlockingLoadBox,
  ODHSpace,
  ODIcons,
  ODModalSize,
  useODModalConfirm,
  useODModalContext
} from '@mdpp/od-react-belt'
import React from 'react'
import { Redirect, useParams } from 'react-router-dom'
import styled from 'styled-components'
import { NumberParam, useQueryParam, withDefault } from 'use-query-params'
import moment from 'moment'
import {
  GQLUpdateReservationByDoctorInput,
  GQLRESERVATION_STATUS,
  GQLPAYMENT_STATE
} from '@mdpp/common/lib/@types/server.schema'
import { EvixErrorBox } from '../../../common/EvixErrorBox'
import { EvixTabBar, ITabBarIcon } from '../../../common/EvixTabBar'
import { IEvixPrescriptionFile } from '../../../common/EvixPrescriptionFileUpload'
import { useCommonAPIs } from '../../../common/hooks/useCommonAPIs'
import { useMutationAPI } from '../../../common/hooks/useMutationAPI'
import { MainContentWithInset } from '../../../common/layout/MainContentWithInset'
import { ReservationListableOfPatientTable } from '../../../common/reservation/list/ReservationListableOfPatientTable'
import { ReservationBasicInfoBoxStyle } from '../../../common/reservation/ReservationCommon'
import { SiteUrls } from '../../../SiteUrls'
import { WebUtils } from '../../../utils/webUtils'
import { useDoctorAPIs, useReservation } from '../../context/useDoctorAPIs'
import { ReservationBasicInfoBox } from './ReservationBasicInfoBox'
import { ReservationDiagnosisInfoBox } from './ReservationDiagnosisInfoBox'
import { ReservationFinishDetail } from './ReservationFinishDetail'
import { ReservationNoticeBox } from './ReservationNoticeBox'
import { ReservationPatientInfoBox } from './ReservationPatientInfoBox'
import { ReservationPaymentBox } from './ReservationPaymentBox'
import { DiagNameWithOption } from './infoCard/DiagnosisList'
import { IFileObject } from '../../../common/hooks/useFileMutationAPI'
import { ReservationCancelPopup } from './ReservationCancelPopup'
import { useSSE } from 'react-hooks-sse';
import { ReservationStatusEvent } from "@mdpp/common/lib/sse/common";
import { PopupModal } from '../../../common/layout/PopupModal'
import {Page, PageContainer, PageContent, PageHeader, PageTitle} from "../../../common/page/Page";
import { ReservationNoticeRight } from "./ReservationNoticeRight";


interface IReservationDetailPageProps {
  fromHospitalManager?: boolean
  fromAdmin?: boolean
}

enum TabType {
  ReservationInfo,
  PatientInfo,
  History,
  Diagnosis,
  Payment,
}

type ReservationTabBarIcon = ITabBarIcon & { type: TabType }

const Items: ReservationTabBarIcon[] = [
  {
    icon: ODIcons.SVGReservationDetail,
    text: '예약상세',
    type: TabType.ReservationInfo,
  },
  { icon: ODIcons.SVGBasement,
    text: '기초문진',
    type: TabType.PatientInfo
  },
  {
    icon: ODIcons.SVGReservationResult,
    text: '진료결과',
    type: TabType.Diagnosis,
  },
  {
    icon: ODIcons.SVGPayment,
    text: '진료비 청구',
    type: TabType.Payment,
  },
  {
    icon: ODIcons.SVGReservationHistory,
    text: '진료기록',
    type: TabType.History,
  },
]

const ItemsPatientHidden: ReservationTabBarIcon[] = [
  {
    icon: ODIcons.SVGReservationDetail,
    text: '예약상세',
    type: TabType.ReservationInfo,
  },
  {
    icon: ODIcons.SVGReservationResult,
    text: '진료결과',
    type: TabType.Diagnosis,
  },
  {
    icon: ODIcons.SVGPayment,
    text: '진료비 청구',
    type: TabType.Payment,
  },
]

const ItemsForAdmin: ReservationTabBarIcon[] = [
  {
    icon: ODIcons.SVGReservationDetail,
    text: '예약상세',
    type: TabType.ReservationInfo,
  },
  { icon: ODIcons.SVGBasement, text: '환자정보', type: TabType.PatientInfo },
]

export const ReservationDetailPage: React.FC<IReservationDetailPageProps> = props => {
  const { fromHospitalManager = false, fromAdmin = false } = props
  const fromManager = fromHospitalManager
  const items = fromHospitalManager ? ItemsPatientHidden : fromAdmin ? ItemsForAdmin : Items
  const { id } = useParams<{ id: string }>()
  const [tabRaw, setTab] = useQueryParam('tab', withDefault(NumberParam, 0))
  const tab = Math.min(Math.max(tabRaw, 0), items.length - 1)
  const [loading, setLoading] = React.useState(false)
  const [payLoading, setPayLoading] = React.useState(false)
  const { reservation, setReservation, error, refresh } = useReservation(id)
  const apis = useDoctorAPIs()
  const { updateReservationByManager: apiUpdateReservationByManager } = useCommonAPIs()
  const { finishReservation, requestPaymentForReservation, updateReservationDiagnosis,  } = useCommonAPIs()
  
  const cancelReservation = useMutationAPI(apis.cancelReservation, {
    onSuccess: '진료 예약을 취소하였습니다.',
    setLoading,
  })
  const updateReservation = useMutationAPI(apis.updateReservation, {
    onSuccess: '업데이트하였습니다.',
    setLoading,
  })
  const updateReservationByManager = useMutationAPI(apiUpdateReservationByManager, {
    onSuccess: '업데이트하였습니다.',
    setLoading,
  })

  const { confirm, confirmInput } = useODModalContext()
  const { Component: ConfirmFinishComponent, confirm: confirmFinish, props: confirmFinishProps } = useODModalConfirm({
    size: ODModalSize.Normal,
  })
  const [redirect, setRedirect] = React.useState('')
  const [showCancelPopup, setShowCancelPopup] = React.useState(false)
  const [updatePrescriptionInfo, setUpdatePrescriptionInfo] = React.useState(false)

  const reservationChanged = useSSE<ReservationStatusEvent> (
    'reservationStateChanged',
    {reservationId: null, status: null},
    {
      parser(input) {
        const event = JSON.parse(input)
        console.log('reservationStateChanged')
        console.log(event)
        if (event.id === reservation?.reservationId)
          refresh()
        return event
      },
    }
  );
  
  // @ts-ignore
  const [showFinishPopup, setShowFinishPopup] = React.useState(false)
  const [diagName, setDiagName] = React.useState<DiagNameWithOption[]>([])
  const [treatment, setTreatment] = React.useState('')
  const [prescriptionFiles, setPrescriptionFiles] = React.useState<IEvixPrescriptionFile[]>()
  const [saveOnly, setSaveOnly] = React.useState(false)

  // React.useEffect(() => {
  //   const handler = setInterval(refresh, 5000)
  //   return () => clearInterval(handler)
  // }, [refresh])

  const executeCancel = async (reason: string) => {
    if (!reservation) {
      return
    }

    setShowCancelPopup(false)

    await cancelReservation({
      reservationId: reservation.reservationId,
      cancelReason: reason,
      isPatientNoShow: false,
    })

    if (fromManager) {
      setRedirect(`${SiteUrls.Manager.Reservation.Main}?tab=0`)
    } else if (fromAdmin) {
      setRedirect(`${SiteUrls.Admin.Reservation.Main}?tab=2`)
    } else {
      setRedirect(`${SiteUrls.Doctor.Reservation.Main}?tab=2`)
    }
  }

  const handleCancel = async () => {
    if (!reservation) {
      return
    }
    setShowCancelPopup(true)
  }

  const [, setRenderToken] = React.useState(0)

	React.useEffect(() => {
		const handler = setInterval(() => {
			setRenderToken(t => t + 1)
		}, 1000)
		return () => {
			clearInterval(handler)
		}
	}, [])

	React.useEffect(() => {
		const TabHandler = setTimeout(() => {
			refresh()
		},500)
		return () => {
			clearInterval(TabHandler)
		}
	}, [tab])


	const nowTime = moment(new Date())
  const midnightTime = moment().add(1, 'days').startOf('day')
  const hourTime = moment().add(1, 'hours').startOf('hour')
  const nowToMidnightHours = Math.floor(moment.duration(midnightTime.diff(nowTime)).asHours())
  const nowToMidnightMinutes = Math.ceil(moment.duration(hourTime.diff(nowTime)).asMinutes())
  const nowToMidnightTime = `${nowToMidnightHours}시간 ${
    nowToMidnightMinutes === 60 ? '' : `${nowToMidnightMinutes}분`
  }`

  const nowToReservationTimeHours = moment(reservation?.reservationTime).diff(nowTime, 'hours')
  const nowToReservationTimeMinutes = moment(reservation?.reservationTime).diff(nowTime, 'minutes')
  const nowToReservationTimeSeconds = moment(reservation?.reservationTime).diff(nowTime, 'seconds')

  const nowToReservationTime = `${
    nowToReservationTimeHours !== 0 ? `${Math.abs(nowToReservationTimeHours)}:` : ''
  }${Math.abs(nowToReservationTimeMinutes % 60)}:${Math.abs(nowToReservationTimeSeconds % 60)}`

  const handleNoShow = async () => {
    if (!reservation) {
      return
    }

    const reason = await confirm({
      title: '환자 예약 부도',
      message: (
        <NoShowText>
          {nowToReservationTimeMinutes < 0 && (
            <>
              입장이 {nowToReservationTime} 지연되었습니다. <br />
            </>
          )}
          지금 예약부도 처리하시겠습니까?
          <br />
          <br />
          <small>({nowToMidnightTime} 경과 후 자동 환자 예약부도 처리 예정입니다.)</small>
        </NoShowText>
      ),
      yes: '예약 취소',
      no: '취소 안함',
    })
    if (reason) {
      await cancelReservation({
        reservationId: reservation.reservationId,
        cancelReason: null,
        isPatientNoShow: true,
      })

      if (fromManager) {
        setRedirect(`${SiteUrls.Manager.Reservation.Main}?tab=0`)
      } else {
        setRedirect(`${SiteUrls.Doctor.Reservation.Main}?tab=2`)
      }
    }
  }

  const updateFiles = async (f: IEvixPrescriptionFile[]): Promise<Array<IFileObject | null>> => {
    const results: Array<IFileObject | null> = []
    if (!reservation) {
      return results
    }

    for (const file of f) {
      if (file.isUploading && file.file) {
        try {
          const fileUploaded = await apis.addPrescriptionFile({
            reservationId: reservation.reservationId,
            file: file.file,
          })
          const obj: IFileObject = {
            id: fileUploaded.prescriptionFileId.toString(),
            fileName: fileUploaded.fileName,
            isDeleting: false,
            isUploading: false,
            link: fileUploaded.link,
          }
          results.push(obj)
        } catch (ex) {
          WebUtils.showError(ex)
          results.push(null)
        }
      } else if (file.isDeleting) {
        try {
          await apis.deletePrescriptionFile({id: parseInt(file.id, 10)})
        } catch (ex) {
          WebUtils.showError(ex)
        }
      }
    }
    return results
  }

  const handleFinish = async (selectedDiagNames: DiagNameWithOption[] = [], treatment: string = '', prescriptionFiles: IEvixPrescriptionFile[] = [], saveOnly: boolean = false) => {
    setDiagName(selectedDiagNames)
    setTreatment(treatment)
    setPrescriptionFiles(prescriptionFiles)
    setSaveOnly(saveOnly)
    setShowFinishPopup(true)
  }

  const finishTreat = async() => {
    if ( !reservation) {
      return;
    }
    try {
      await updateReservationDiagnosis({
        id: reservation.reservationId,
        diagnosis: diagName?.map(dn => ({
          diagNameCode: dn.diagName.code,
          isFinal: dn.isFinal,
        })),
      })
      await handleUpdateField('treatment', treatment)
      await updateFiles(prescriptionFiles || [] )
    } catch (e) {
      console.error(e)
    }

    try {
      if (!saveOnly) {
        WebUtils.showSuccess('진료결과를 입력 하였습니다.')
        refresh()
        if (!reservation.paymentState) {
          setTab(3)
        }
      } else {
        refresh()
        setUpdatePrescriptionInfo(!updatePrescriptionInfo)
        WebUtils.showSuccess('진료결과를 업데이트 하였습니다.')
      }
    } catch(e) {
      console.error(e)
      WebUtils.showError('진료결과 입력 중 문제가 발생했습니다.')
    }
    setShowFinishPopup(false)
  }

  if (redirect) {
    return <Redirect to={redirect} />
  }

  if (!reservation) {
    return <BlockingLoadBox show />
  }

  if (error) {
    return <EvixErrorBox message={error.message} />
  }

  const handleUpdateField = async (fieldName: keyof GQLUpdateReservationByDoctorInput, value: string | number) => {
    if (fromManager) {
      const updated = await updateReservationByManager({ id: reservation.reservationId, [fieldName]: value })
      // 업데이트된 내용을 현재 받아온 스냅샷에서 교체해준다.
      setReservation(updated)
    } else {
      const updated = await updateReservation({ id: reservation.reservationId, [fieldName]: value })
      // 업데이트된 내용을 현재 받아온 스냅샷에서 교체해준다.
      setReservation(updated)
    }
  }

  const handlePayRequest = async (value : number) => {
    const id = reservation.reservationId
    try {
      setPayLoading(true)
      WebUtils.showSuccess('결제 요청 중입니다.')
      const updated = await requestPaymentForReservation({ reservationId: id, paymentTotal: value })
      setReservation(updated)
      WebUtils.showSuccess('청구하였습니다.')
      if (updated.paymentState === GQLPAYMENT_STATE.SUCCESS) {
        await finishReservation({ id: id })
      }
      WebUtils.showInfo('진료를 종료 하였습니다.')
      refresh()
    } catch (ex) {
      const r = await apis.getReservation({ id: id })
      if (r.paymentState === GQLPAYMENT_STATE.FAILED) {
        await finishReservation({ id: id })
        refresh()
      }
      WebUtils.showError(ex)
    } finally {
      setPayLoading(false)
    }
  }

  const tabType = items[tab].type ?? TabType.ReservationInfo

  return (
    <ReservationPageContainer style={{flexDirection: 'column'}}>
      <BlockingLoadBox show={loading} />
      {showFinishPopup && (
        <PopupModal
          title={reservation.status === GQLRESERVATION_STATUS.FINISHED ? '진료결과 수정' : '진료 종료'}
          description={(<ReservationFinishDetail reservation={reservation} diagNames={diagName} treatment={treatment} prescriptionFiles={prescriptionFiles}/>)}
          onOkPress={finishTreat}
          warning={(<div>진료 결과는 진료 결과 등록 후 7일간 수정이 가능합니다.<br />등록 버튼을 누르면 환자에게 진료 결과가 전달됩니다.</div>)}
          onCancelPress={()=>{setShowFinishPopup(false)}}
        />
      )}
      <ConfirmFinishComponent {...confirmFinishProps} />


      

      <Page>
      {fromAdmin && (
        <PageHeader>
          <PageTitle style={{ width: '100%' }}>
            진료
            {reservation.proxyReservation && (
              <>
                <ODHSpace w={13} />
                <div className={`enum_tag_proxy`}>
                  <span>대리</span>
                </div>
              </>
            )}
          </PageTitle>
          <ReservationCancelPopup isOpen={showCancelPopup} onClose={() =>(setShowCancelPopup(false))} onSubmit={executeCancel} />
          {(reservation.status === GQLRESERVATION_STATUS.PENDING || reservation.status === GQLRESERVATION_STATUS.WAITING || reservation.status === GQLRESERVATION_STATUS.RECEIPTED) && (
            <ReservationNoticeRight reservation={reservation} fromManager={fromManager} onClickCancel={handleCancel} fromAdmin={true} />
          )}
        </PageHeader>
      )}
      {!fromAdmin && (
        <div style={{ borderBottom: '1px solid var(--gray200)', marginBottom: 8}}>

          <ReservationCancelPopup isOpen={showCancelPopup} onClose={() =>(setShowCancelPopup(false))} onSubmit={executeCancel} />
          <ReservationNoticeBox
            fromManager={fromManager}
            reservation={reservation}
            onClickCancel={handleCancel}
            onClickFinish={handleFinish}
            onClickPatientNoShow={handleNoShow}
          />
        </div>
      )}
        <EvixTabBarWapper>
          <EvixTabBar items={items} selectedIndex={tab} onClickTab={setTab}  />
        </EvixTabBarWapper>
      </Page>

      
        
      {tabType === TabType.ReservationInfo && (
      <Page>
        <ReservationBasicInfoBox
            reservation={reservation}
            infoStyle={ReservationBasicInfoBoxStyle.OutsideRoom}
            hidePatient={fromHospitalManager || fromAdmin}
            fromManager={fromHospitalManager}
            fromAdmin={fromAdmin}
        />
      </Page>
      )}

      {tabType === TabType.PatientInfo && (
      <Page>
        <ReservationPatientInfoBox patient={reservation.patient} hidePatient={fromAdmin || fromHospitalManager} />
      </Page>
      )}

      {tabType === TabType.History && (
      <Page>
        <ReservationListableOfPatientTable patientId={reservation.patientId} />
      </Page>
      )}

      {tabType === TabType.Diagnosis && (
      <ReservationDiagnosisInfoBoxPage>
        <ReservationDiagnosisInfoBox
            reservation={reservation}
            onUpdateField={handleUpdateField}
            handleFinish={handleFinish}
            reload={updatePrescriptionInfo}
            fromManager={fromManager}
        />
      </ReservationDiagnosisInfoBoxPage>
      )}
      {tabType === TabType.Payment && (
      <Page>
        <ReservationPaymentBox
              reservation={reservation}
              payLoading={payLoading}
              onUpdateField={async() => {}}
              onPayRequested={handlePayRequest}
          />
      </Page>
      )}
    </ReservationPageContainer>
  )
}

const NoShowText = styled.div`
  font-size: 14px;
  color: var(--gray800);

  & small {
    font-size: 12px;
    color: var(--mpPrimary500);
  }
`

const EvixTabBarWapper = styled.div`
  padding-bottom: 18px;
`

const ReservationPageContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
`

const ReservationDiagnosisInfoBoxPage = styled.div`
  max-width: 1280px;
  width: 100%;
  height: calc(100% - 200px);
  padding: 0 50px; 
  display: flex;
  flex-direction: column; 
  justify-items: center;

  @media(max-width: 992px) {
    padding: 0 20px; 
    min-width: 650px;
  }
`
