import React from 'react'
import {
  GQLReservation,
  GQLUpdateReservationByDoctorInput,
} from '@mdpp/common/lib/@types/server.schema'
import { ReservationBasicInfoBoxStyle } from '../../../../common/reservation/ReservationCommon'
import { ReservationBasicInfoBox } from '../../detail/ReservationBasicInfoBox'

interface IRoomReservationSectionProps {
  reservation: GQLReservation
  isFinishedMedical?: boolean
}

export const RoomReservationSection: React.FC<IRoomReservationSectionProps> = props => {
  const { reservation, isFinishedMedical } = props

  return (
    <div>
      <ReservationBasicInfoBox
        reservation={reservation}
        infoStyle={ReservationBasicInfoBoxStyle.InsideRoom}
        isFinishedMedical={isFinishedMedical}
      />
    </div>
  )
}
