import {
  TimeGridScheduler,
  classes,
  DefaultEventRootComponent,
  SchedulerContext
} from '@remotelock/react-week-scheduler';
import CustomProperties from 'react-custom-properties';
import { EventRootProps, ScheduleType } from "@remotelock/react-week-scheduler/src/types";
import "@remotelock/react-week-scheduler/index.css";
import Tippy from '@tippy.js/react';
import DeleteIcon from '../../../assets/outline-delete-24px.svg';
import styled from "styled-components/macro";
import LoadingOverlay from 'react-loading-overlay-ts';
import ko from 'date-fns/locale/ko/index.js';
import React, { Fragment, useState } from 'react'

interface IProps {
  schedule: ScheduleType
  setSchedule : (week: ScheduleType) => void
  isShowOverlay: boolean
}

// // mon-0 tue-1 wed-2 thu-3 fri-4 sat-5 sun-6
const getDayofDate = (d : Date, dayOfIndex : number) =>  {
  //const offset = dayOfIndex === 6 ? 0 : dayOfIndex + 1;
  d = new Date(d);
  const day = d.getDay()
  let diff = d.getDate() - day + (day === 0 ? -6  : 1 ); // adjust when day is sunday
  diff += dayOfIndex
  return new Date(d.setDate(diff));
}

const EventRoot = React.forwardRef<any, EventRootProps>(function EventRoot(
  {handleDelete, disabled, ...props},
  ref,
) {
  return (
    <Tippy
      arrow
      interactive
      enabled={!disabled}
      hideOnClick={false}
      //className={demoClasses.tooltip}
      content={
        <button disabled={disabled} onClick={handleDelete}>
          <Icon src={DeleteIcon} height='24px' alt="icon"/>
        </button>
      }
    >
      <DefaultEventRootComponent
        handleDelete={handleDelete}
        disabled={disabled}
        {...props}
        ref={ref}
      />
    </Tippy>
  );
});


export const WeekScheduler: React.FC<IProps> = props => {
  const { schedule, setSchedule, isShowOverlay} = props
  const [cellHeight] = useState(32);
  const [cellWidth] = useState(70); // min-width : 150px

  return (
    <SchedulerRoot>
      <StyledLoader
        classNamePrefix='MyLoader_'
        active={isShowOverlay}
        spinner={false}
        text='예약 기간을 먼저 설정하세요.'
      >
        <CustomProperties
          global={false}
          properties={{
            '--cell-height': `${cellHeight}px`,
            '--cell-width': `${cellWidth}px`,
          }}
        >
          <Fragment key={`${cellHeight},${cellWidth}`}>
            <SchedulerContext.Provider value={{locale: ko}}>
              <TimeGridScheduler
                classes={classes}
                originDate={getDayofDate(new Date(), -1)}
                style={{width: "100%", height: "650px"}}
                schedule={schedule}
                onChange={setSchedule}
                visualGridVerticalPrecision={15}
                verticalPrecision={15}
                cellClickPrecision={15}
                defaultHours={[8, 18]}
                eventRootComponent={EventRoot}
              />
            </SchedulerContext.Provider>
          </Fragment>
        </CustomProperties>
      </StyledLoader>
    </SchedulerRoot>
  )
}

const SchedulerRoot = styled.div`
  // background: red;

  > div > div > div {
    height: 520px !important;
    border: none;

    > div {
      > div:first-child {
        // time line
        width: 51px;
        border-right: 1px solid #F6F7F8;
      }

      > div:nth-child(2) {
        // 요일 헤더
        border-bottom: 1px solid #F6F7F8;

        > div {
          background: white;
          box-shadow: none;

        }
        > div > div {
          // 각 요일 헤더
          min-width: 30px;

          > div {
            font-size: 14px;
          }
        }
      }

      > div:nth-child(3) {
        // cell root

        > div > div {
          // cell wrapper common
          min-width: 30px;
        }
        > div > div > div {
          // cell wrapper
          // width: 70px;
          > div {
            // cell
            border-top: none;
          }
          > div:nth-child(2n-1) {
            background: #F6F7F8;
          }
        }

        > div > span {
          // schedule root
          > div {
            background: var(--mpPrimary500);
            border-radius: 4px;
            font-size: 14px;
            margin: 0 0 0 10px;
            text-align: center;
          }
        }
      }
    }
  }
`

const Icon = styled.img`
    object-fit: fill;
    opacity: 0.9;
    fill: white;
    height: ${props => props.height || 'none'};
    width: ${props => props.width || 'none'};
    filter: invert(100%);
    
  `
const StyledLoader = styled(LoadingOverlay)`
  width: 100%;
  height: 100%;
  overflow: auto;
  .MyLoader_overlay {
    background: rgba(255, 255, 255, 0.5);
  }
  .MyLoader_content{
    color: rgba(0, 0, 0, 1);
  }
  &.MyLoader_wrapper--active {
    overflow: hidden;
  }
`
