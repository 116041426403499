import { useDetectOutsideClick } from '@mdpp/od-react-belt'
import React from 'react'
import { Input } from 'reactstrap'
import { InputType } from 'reactstrap/lib/Input'
import { COLORS } from '../styles/colors'

interface IEvixClickToInputProps {
  type?: InputType
  initialValue?: any // string | number, ..
  nonText?: string
  saveOnEnter?: boolean
  saveOnAltEnter?: boolean // if has modifier, (shift, ctrl, cmd)
  onSave?: (text: any) => Promise<void> // string | number, ...
  onChangeCallback ?: (text: any) => Promise<void>
  rows?: number
  height?: number
  alignRight?: boolean
  numberToLocale?: boolean
  addPostfix?: string
  disabled?: boolean
  disableEdit?: boolean
  min?: number
  step?: number
}

const convertMultilineText = (str: string) => str.replace(/(?:\r\n|\r|\n)/g, '<br>')

/**
 * - 평상시에는 일반 텍스트로 rendering 하고, 편집 모드로 들어가면 textarea, text 등의 input 으로 편집합니다.
 * - props.type 이 textarea 인 경우와 아닌 경우 ui 관련 동작이 조금 다릅니다. (rows, height, saveOnEnter, saveOnAltEnter)
 * - props.type 이 number 인 경우 onSave 시에 parseInt 한 값으로 호출됩니다.
 */
export const EvixClickToInput: React.FC<IEvixClickToInputProps & React.HTMLAttributes<HTMLDivElement>> = props => {
  const {
    initialValue = '',
    nonText = '입력하세요',
    onSave,
    onChangeCallback = null,
    height: heightIn,
    rows = 3,
    type = 'text',
    saveOnAltEnter: saveOnAltEnterIn,
    saveOnEnter: saveOnEnterIn,
    alignRight,
    numberToLocale,
    addPostfix,
    disabled: disabledIn,
    disableEdit = false,
    min,
    step,
    ...others
  } = props

  const isTextArea = type === 'textarea'
  const height = heightIn || (!isTextArea ? 35 : 79)
  const saveOnEnter = saveOnEnterIn || !isTextArea
  const saveOnAltEnter = saveOnAltEnterIn || isTextArea

  const [editing, setEditing] = React.useState(false)
  const [saving, setSaving] = React.useState(false)
  const [text, setText] = React.useState(initialValue)
  const disabled = disabledIn || saving
  const ref = React.useRef<HTMLDivElement>(null)

  const handleSave = async () => {
    setEditing(false)

    if (text === initialValue) {
      return
    }
    setSaving(true)
    try {
      if (onSave) {
        if (type === 'number') {
          await onSave(parseInt(text, 10))
        } else {
          await onSave(text)
        }
      }
    } catch (ex) {
      setText(initialValue)
    } finally {
      setSaving(false)
    }
  }

  useDetectOutsideClick(() => handleSave(), ref, true)

  const textStyle = text ? { color: COLORS.GRAY900 } : {}

  if (!editing) {
    let finalText = text
    if (numberToLocale) {
      const numberValue = parseInt(text, 10)
      if (!isNaN(numberValue)) {
        finalText = numberValue.toLocaleString()
      }
    }
  }

  const handleKey = (e: any) => {
    if (!disabled && saveOnEnter && e.key === 'Enter') {
      // noinspection JSIgnoredPromiseFromCall
      handleSave()
    } else if (!disabled && saveOnAltEnter && e.key === 'Enter' && (e.shiftKey || e.altKey || e.metaKey || e.ctrlKey)) {
      // noinspection JSIgnoredPromiseFromCall
      handleSave()
    }
  }
  const handleOnChange = async (e: any) => {
    setText(e.target.value)
    if (onChangeCallback) {
      if (type === 'number') {
        await onChangeCallback(parseInt(e.target.value, 10))
      } else {
        await onChangeCallback(e.target.value)
      }
    }
  }
  
  return (
    <Input
      autoFocus
      type={type}
      placeholder={nonText}
      onChange={handleOnChange}
      value={text}
      onKeyDown={handleKey}
      disabled={disabled}
      style={{ padding: 7, margin: 0, textAlign: alignRight ? 'right' : undefined }}
      rows={rows}
      min={min}
      step={step}
    />
  )
}
