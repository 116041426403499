import { IODListableEventContext, ODListableOption } from '@mdpp/od-react-belt'
import { GQLReservation } from '@mdpp/common/lib/@types/server.schema'

export const ReservationPrimaryKey = 'reservationId'

export interface IReservationListableOption extends ODListableOption {
  filter: string
}

export interface IReservationEntityContext extends IODListableEventContext<GQLReservation> {
  //
}

export enum ReservationBasicInfoBoxStyle {
  OutsideRoom, // 진료실 밖에서 보여줄 때
  InsideRoom, // 진료실 안에서 보여줄 때
}
