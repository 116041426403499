import { ODEntity, ODEntityInput, ODEntityLabeled, ODVSpace } from '@mdpp/od-react-belt'
import gql from 'graphql-tag'
import React from 'react'
import { Card, CardBody } from 'reactstrap'
import styled from 'styled-components/macro'
import { GQLNotice, GQLNoticeCreationInput, GQLNoticeUpdateInput } from '@mdpp/common/lib/@types/server.schema'
import { BoldLabel } from '../../../doctor/mypage/BoldLabel'
import { SiteUrls } from '../../../SiteUrls'
import { COLORS } from '../../../styles/colors'
import { useODEntityAPI } from '../../../utils/odGQLEntityLoader'
import { EvixFormToggleButton } from '../../form/EvixFormToggleButton'
import { GQL_CREATE_NOTICE, GQL_DELETE_NOTICE, GQL_GET_NOTICE, GQL_UPDATE_NOTICE } from '../../gqls_common'
import { ICTNotice, NoticePrimaryKey } from '../NoticeCommon'
import { EvixCard } from '../../card/EvixCard'
import { TableList, TableListToggleButtonWrapper, TableListWrapper } from '../../list/TableList'

interface INoticeEditPageProps {
  idEditing: number | null
}

const DEFAULT_DATA: Partial<ICTNotice> = {
  noticeId: 0,
  title: ``,
  contents: '',
  link: '',
  showOnHospitalManager: false,
  showOnApp: false,
  showOnDoctor: false,
}

export const NoticeEditPage: React.FC<INoticeEditPageProps> = props => {
  const { idEditing } = props

  const apis = useODEntityAPI<GQLNotice, ICTNotice, GQLNoticeCreationInput, GQLNoticeUpdateInput>({
    createGQL: gql(GQL_CREATE_NOTICE),
    updateGQL: gql(GQL_UPDATE_NOTICE),
    readGQL: gql(GQL_GET_NOTICE),
    deleteGQL: gql(GQL_DELETE_NOTICE),
    primaryKeyName: NoticePrimaryKey,
  })

  return (
    <EvixCard>
      <ODEntity
        resourceId={idEditing}
        api={apis}
        saveButtonName="저장"
        titleCreation="추가"
        createSuccessTitle="성공"
        createSuccessMessage="공지사항이 추가되었습니다."
        titleUpdate="수정"
        updateSuccessTitle="성공"
        updateSuccessMessage="수정하였습니다."
        urlAfterDelete={(item: ICTNotice) => SiteUrls.Admin.Notice.Main}
        deleteSuccessTitle="성공"
        deleteSuccessMessage="삭제하였습니다."
        defaultCreateClientData={DEFAULT_DATA}
        urlAfterCreation={() => SiteUrls.Admin.Notice.Main}
        urlAfterUpdate={() => SiteUrls.Admin.Notice.Main}
        noCardWrap
        footerProps={{
          deleteButtonStyle: {
            borderColor: COLORS.DANGER,
            color: COLORS.DANGER,
            backgroundColor: COLORS.RED10,
          },
        }}
        deleteButtonName="삭제"
      >
        <TableListWrapper>
          <TableList name="title" label="제목">
            <ODEntityInput
              keyPath="title"
              label="제목"
              name="title"
              placeholder="제목을 입력해주세요."
              inputType="text"
            />
          </TableList>
          <TableList name="link" label="URL">
            <ODEntityInput keyPath="link" label="URL" name="link" placeholder="http:// 또는 https://를 포함하여 링크를 입력해주세요." inputType="text" />
          </TableList>
          <TableList name="exposure" label="공지사항 노출 범위">
            <TableListToggleButtonWrapper>
              <EvixFormToggleButton name="showOnApp" keyPath="showOnApp">
                환자
              </EvixFormToggleButton>
              <EvixFormToggleButton name="showOnDoctor" keyPath="showOnDoctor">
                의사
              </EvixFormToggleButton>
              <EvixFormToggleButton name="showOnHospitalManager" keyPath="showOnHospitalManager">
                병원 관리자
              </EvixFormToggleButton>
            </TableListToggleButtonWrapper>
          </TableList>
        </TableListWrapper>
        <ODVSpace h={24} />
        <hr />
      </ODEntity>
      <ODVSpace h={24} />
    </EvixCard>
  )
}

const ToggleButtonWrapper = styled.div`
  display: inline-flex;
  width: 400px;
`
