import { ODIcon, ODIcons, ODVLine, ODVSpace } from '@mdpp/od-react-belt'
import React from 'react'
import { Card } from 'reactstrap'
import styled from 'styled-components'
import { COLORS } from '../../../styles/colors'
import { EvixButton, EvixButtonTheme } from '../../EvixButton'
// import { EvixCard } from '../../../common/card/EvixCard'

interface IDoctorProfileHospitalCommonProps {
  name: string
  showChange?: boolean
  onChange?: () => void
}

const cardStyle = {
    display: 'flex', width: '100%', height: '500px', alignItems: 'center', justifyContent: 'center'
}
export const DoctorProfileHospitalCommon: React.FC<IDoctorProfileHospitalCommonProps> = props => {
  const { name, showChange, onChange } = props


  return (
    <Card style={cardStyle}>
      <IconWrapper>
        <ODIcon icon={ODIcons.CoreHospital} />
      </IconWrapper>
      <ODVSpace h={14} />
      {/*<HospitalText>병원</HospitalText>*/}
      {/*<ODVSpace h={29} />*/}
      <ODVLine height={1} width={50} color={COLORS.mpPrimary500} />
      <ODVSpace h={34} />
      <HospitalBigText>{name}</HospitalBigText>
      {showChange && (
        <ChangeButtonWrapper>
          <EvixButton theme={EvixButtonTheme.H34Selected} onClick={onChange}>
            변경
          </EvixButton>
        </ChangeButtonWrapper>
      )}
    </Card>
  )
}

export const IconWrapper = styled.div`
  font-size: 50px;
  color: var(--mpPrimary500);
`

export const HospitalText = styled.div`
  font-size: 15px;
  line-height: 1.33;
  color: var(--gray500);
`

export const HospitalBigText = styled.div`
  font-size: 26px;
  font-weight: 600;
  line-height: 1.33;
  color: var(--gray800);
`

export const ChangeButtonWrapper = styled.div`
  margin-top: 21px;
  text-align: center;
`
