import React from 'react'
import Select from 'react-select'
import styled from 'styled-components'
import {ODHSpace} from "@mdpp/od-react-belt";

interface IHolidayWorkingSelectorProps {
  value: number
  setValue: (value: number) => void
}
const OPTIONS = [
  { label: `진료하지 않음`, value: 0 },
  { label: `직접 입력`, value: 1 }]

export const HolidayWorkingSelector: React.FC<IHolidayWorkingSelectorProps> = props => {
  const { value, setValue } = props
  
  return (
    <Wrapper>
      <CustomSelect
        name="holidayWorkingSelector"
        maxMenuHeight={130}
        options={OPTIONS}
        value={OPTIONS.find(v => v.value === value)}
        onChange={(e: any) => {setValue(e.value)}}
      />
    </Wrapper>
  )
}

const CustomSelect = styled(Select)`
  width: 100%;
`
const Wrapper = styled.div`
  position: relative;
  display: flex;
  height: 100%;
  align-items: center;
  width: 100%; 

  // React Select Style
  > div:first-child {
    width: 100%;
    max-width: 300px;

    & svg {
      width: 24px;
      height: 22px;
      font-size: 24px;
      line-height: 0.92;
      color: var(--gray600);
    }
  }

  .css-1okebmr-indicatorSeparator {
    background-color: transparent;
  }

  .css-1uccc91-singleValue {
    font-size: 14px;
    color: var(--gray600);
  }
  .css-1laao21-a11yText {
    display: none;
  }

  // react-date css
  .DateInput_input {
    width: 130px;
    height: 34px;
    border-radius: 4px;
    border: solid 1px var(--gray300);
    font-size: 14px;
    line-height: 1.43;
    color: var(--gray900);
  }
  .DateRangePickerInput__withBorder {
    border: 0;
  }
  .DateRangePickerInput_arrow.DateRangePickerInput_arrow_1 svg {
    width: 9px;
    height: 16px;
    font-size: 14px;
    letter-spacing: 0.3px;
    color: var(--gray600);
    margin-left: 8px;
    margin-right: 8px;
  }
`
