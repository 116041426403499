import {
  ODButton,
  ODButtonSize,
  ODButtonTheme,
  ODListablePaginatedTable2,
  ODListablePagination,
  ODListableSearchBox,
  useODListableContext,
} from '@mdpp/od-react-belt'
import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components/macro'
import { GQLMedicalSubject } from '@mdpp/common/lib/@types/server.schema'
import { SiteUrls } from '../../../SiteUrls'
import { WebUtils } from '../../../utils/webUtils'
import { IMedicalSubjectListableOption, MedicalSubjectPrimaryKey } from '../MedicalSubjectCommon'
import { useMedicalSubjectListDataLoader } from './MedicalSubjectListDataLoader'
import { EvixCard } from '../../card/EvixCard'
import { PageContainer, Page, PageHeader, PageTitle, PageContent } from '../../page/Page'


interface IMedicalSubjectListableTableProps {}

export const MedicalSubjectListableTable: React.FC<IMedicalSubjectListableTableProps> = props => {
  const { Provider, Context } = useODListableContext<GQLMedicalSubject, IMedicalSubjectListableOption>()
  const dataLoader = useMedicalSubjectListDataLoader()

  const toolbarStyle = { display: 'flex', justifyContent: 'space-between', marginBottom: 15 }
  const searchBoxStyle = { flex: 1}
  const linkStyle = { textDecoration: 'none' }
  const addButtonStyle = { minWidth: 120 }
  const wrapperStyle = { display: 'flex', alignItems: 'center', justifyContent: 'center' }


  return (
    <Provider
      dataLoader={dataLoader}
      keyExtractor={v => v[MedicalSubjectPrimaryKey].toString()}
      pageSize={10}
      onDataLoaderError={WebUtils.showError}
      searchOnLoad
    >
      <PageContainer>
        <Page>
          <PageHeader>
            <PageTitle>진료과목 관리</PageTitle>
          </PageHeader>
          <SearchWrapper style={toolbarStyle}>
            <ODListableSearchBox listableContext={Context} placeholder="진료과목으로 검색" style={searchBoxStyle} />
            <Link to={SiteUrls.Admin.MedicalSubject.Create} style={linkStyle}>
              <ODButton theme={ODButtonTheme.Primary} size={ODButtonSize.Small} rounded style={addButtonStyle}>
                진료과목 추가
              </ODButton>
            </Link>
          </SearchWrapper>
          <PageContent>
            <EvixCard noPadding>
              <ODListablePaginatedTable2
              numColumns={4}
              listableContext={Context}
              renderHeader={() => (
                <tr>
                  <th style={{whiteSpace: 'nowrap', textAlign: 'center'}}>진료과목</th>
                  <th style={{whiteSpace: 'nowrap', textAlign: 'center'}}>등록된 의사 수</th>
                </tr>
              )}
              renderRow={(value: GQLMedicalSubject, context) => (
                <tr key={value[MedicalSubjectPrimaryKey]} style={{borderBottom: '1px solid #D1D5DA'}}>
                  <td align="center">
                    <Link to={SiteUrls.Admin.MedicalSubject.Edit(value.medicalSubjectId)}>{value.name}</Link>
                  </td>
                  <td align="center">{value.numDoctors.toLocaleString()}</td>
                </tr>
              )}
              eventParentContext={{}}
            />
            </EvixCard>
            <ODListablePagination hideIfSinglePage={false} listableContext={Context} wrapperStyle={wrapperStyle} />
          </PageContent>
        </Page>
      </PageContainer>
    </Provider>
  )
}

const SearchWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-bottom: 24px;
  > div > form > div {
    > div {
      border-radius: 8px;

      > div {
        border-radius: 8px;
        background-color: white;
        > span {
          border-radius: 8px;
        }
      }

      > input {
        border-radius: 0px 8px 8px 0px;
      }
    }

    @media (max-width: 992px) {
      width: 290px;
    }
  }
`
