import React from 'react'
import { ODHSpace } from '@mdpp/od-react-belt'
import styled from 'styled-components'
import Select from 'react-select'
import moment from 'moment'

import { DatePicker } from '../../common/DatePicker'
import { colourStyles } from './ReservationStatusSelector'

interface IDateRangeSelector {
  value: string[]
  onChange: (changedValue: string[]) => void
}

const Wrapper = styled.div`
  display: flex;

  // React Select Style
  .css-2b097c-container {
    & svg {
      font-size: 24px;
      color: var(--gray600);
    }
  }
  .css-1okebmr-indicatorSeparator {
    background-color: transparent;
  }

  .css-1laao21-a11yText {
    display: none;
  }
  
`

// // 오늘만 버튼
// const TodayButton = styled.button`
//   width: 115px;
//   height: 35px;
//   border: 1px solid var(--gray300);
//   border-left: none;
//   background: white;
// `


enum DATE_RANGE_STATUS {
  ALL = 'ALL',
  TODAY = 'TODAY',
  SELECT = 'SELECT',
}
const DATE_RANGE_TEXT = {
  [DATE_RANGE_STATUS.ALL]: '전체 기간',
  [DATE_RANGE_STATUS.TODAY]: '오늘',
  [DATE_RANGE_STATUS.SELECT]: '날짜 설정',
}
const options = [
  { value: DATE_RANGE_STATUS.ALL, label: DATE_RANGE_TEXT.ALL },
  { value: DATE_RANGE_STATUS.TODAY, label: DATE_RANGE_TEXT.TODAY },
  { value: DATE_RANGE_STATUS.SELECT, label: DATE_RANGE_TEXT.SELECT },
]

export const DateRangeSelector: React.FC<IDateRangeSelector> = props => {
  const { value, onChange } = props
  const [dateStatus, setDateStatus] = React.useState(DATE_RANGE_STATUS.ALL)

  const changeHandler = (e: any) => {
    if (e.value === DATE_RANGE_STATUS.ALL) {
      setDateStatus(DATE_RANGE_STATUS.ALL)
      return onChange(['', ''])
    }
    if (e.value === DATE_RANGE_STATUS.TODAY) {
      setDateStatus(DATE_RANGE_STATUS.TODAY)
      const today = moment(new Date()).format('YYYY-MM-DD')
      return onChange([today, today])
    }
    if (e.value === DATE_RANGE_STATUS.SELECT) {
      setDateStatus(DATE_RANGE_STATUS.SELECT)
    }
  }

  const updateToday = () => {
    setDateStatus(DATE_RANGE_STATUS.TODAY)
    const today = moment(new Date()).format('YYYY-MM-DD')
    onChange([today, today])
  }

  const wrapperStyle = {
    // position: 'absolute',
    left: 0,
    width: 285,
    // zIndex: 1,
    // top: 40,
  }

  return (
    <Wrapper>
      <ODHSpace w={0} />
      {/*<TodayButton onClick={updateToday}>오늘 진료만</TodayButton>*/}
      <Select
        name="DateRangeSelector"
        options={options}
        value={options.find(v => {
          return v.value === dateStatus
        })}
        onChange={changeHandler}
        styles={colourStyles}
      />
      <ODHSpace w={8} />
      {dateStatus === DATE_RANGE_STATUS.SELECT && <DatePicker dateRange={value} onChange={onChange} wrapperStyle={wrapperStyle} />}
    </Wrapper>
  )
}
