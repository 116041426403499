import { ODWebUtils } from '@mdpp/od-react-belt'
import React from 'react'
import { Redirect } from 'react-router-dom'
import { CenterWithFooterContainer } from '../common/layout/CenterWithFooterContainer'
import { LoginBox, LoginBoxDesignStyle } from '../login/LoginBox'
import { SiteUrls } from '../SiteUrls'
import { useAdminAppContext } from './context/AdminAppContext'

export const AdminLoginPage: React.FC = () => {
  const { login } = useAdminAppContext()

  const [redirect, setRedirect] = React.useState('')

  if (redirect) {
    return <Redirect to={redirect} />
  }

  const handleLogin = async (email: string, password: string) => {
    try {
      await login({ email, password })
      setTimeout(() => {
        setRedirect(SiteUrls.Admin.Dashboard.Main)
      }, 100)
      return true
    } catch (ex) {
      ODWebUtils.showError(ex)
      return false
    }
  }

  return (
    <CenterWithFooterContainer>
      <LoginBox designStyle={LoginBoxDesignStyle.Admin} onLogin={handleLogin} />
    </CenterWithFooterContainer>
  )
}
