import { ODIcons, ODVSpace } from '@mdpp/od-react-belt'
import React, { useEffect } from 'react'
import _ from 'lodash'
import styled from 'styled-components/macro'
import { MedicalSubjectTag } from '../../../doctor/mypage/MedicalSubjectTag'
import { ProfessionTag } from '../../../doctor/mypage/ProfessionTag'
import { useMedicalSubjectPickerModal } from '../../../doctor/mypage/useMedicalSubjectPickerModal'
import { WebUtils } from '../../../utils/webUtils'
import { EvixInputText } from '../../EvixInputText'
import { GQLDoctor } from '@mdpp/common/lib/@types/server.schema'
import { useCommonAPIs } from '../../../common/hooks/useCommonAPIs'
import { Button, BP } from '../../../common/RebeccaButton'
import { TableList } from '../../list/TableList'
import { RoomSectionDivider } from '../../../doctor/reservation/room/RoomCommon'
import Toggle from 'react-toggle'
import 'react-toggle/style.css'
import '../scss/table.scss'
import { ConfirmModal } from '../../layout/ConfirmModal'
import { MessageIcon, RowWrap, SwitchWrap, ToggleLabel, SwitchLabel } from '../../style/CommonStyle'
import IconWarningRed from '../../../assets/common/warning-red-super.svg'
import IconWarningBlue from '../../../assets/common/warning-blue.svg'
import { EvixButton, EvixButtonTheme } from '../../EvixButton'
import { Oval, RequiredText, RequiredWrap } from '../../style/SignUpStyle'
import { checkMessageWithPrefix } from '../../../utils/registerServerErrorMessages'
import { ErrorCode } from '@mdpp/common'

interface IDoctorProfileDiagnosisEditProps {
  profile?: GQLDoctor | null
  updateData: (input: {
    medicalSubjectIds: number[]
    profession: string
    availableFirstVisit: boolean
    untactTreatment: boolean
  }) => Promise<void>
}

let listMS: any = []
export const DoctorProfileDiagnosisEdit: React.FC<IDoctorProfileDiagnosisEditProps> = props => {
  const { profile, updateData } = props

  const initialMedicalSubjectIds =
    (profile && profile.medicalSubjects.map(m => m.medicalSubject.medicalSubjectId)) || []
  const initialMedicalSubjectNames = (profile && profile.medicalSubjects.map(m => m.medicalSubject.name)) || []
  const [medicalSubjectIds, setMedicalSubjectIds] = React.useState(initialMedicalSubjectIds)
  const [medicalSubjectNames, setMedicalSubjectNames] = React.useState(initialMedicalSubjectNames)
  const [firstVisit, setFirstVisit] = React.useState(true)
  const { listMedicalSubjects } = useCommonAPIs()

  const [update, setUpdate] = React.useState(false)
  const [checked, setChecked] = React.useState(false)
  const [showStartModal, setShowStartModal] = React.useState(false)
  const [showFinishModal, setShowFinishModal] = React.useState(false)
  const [untactToggle, setUntactToggle] = React.useState(false)

  const [notMedicalDataModal, setNotMedicalDataModal] = React.useState(false)
  const [notScheduleModal, setNotScheduleModal] = React.useState(false)

  const HandleToggle = () => {
    setUntactToggle(!untactToggle)
    !untactToggle ? setShowStartModal(true) : setShowFinishModal(true)
  }

  React.useEffect(() => {
    setMedicalSubjectIds(initialMedicalSubjectIds)
    setMedicalSubjectNames(initialMedicalSubjectNames)
    if (profile && profile.availableFirstVisit !== undefined) setFirstVisit(profile.availableFirstVisit)
    if (profile && profile.untactTreatment !== undefined) setUntactToggle(profile.untactTreatment)
  }, [profile])

  React.useEffect(() => {
    const getListMedicalSubjects = async () => {
      const listSubjects = await listMedicalSubjects({ page: 1, pageSize: 100, filter: null })
      return listSubjects
    }
    getListMedicalSubjects().then(r => {
      listMS = r.list
    })
  }, [])

  React.useEffect(() => {
    setUpdate(true)
  }, [untactToggle])

  React.useEffect(() => {
    if (listMS.length > 0) {
      let mss = medicalSubjectIds.map(msi => listMS.find((ms: any) => ms.medicalSubjectId === msi))
      mss = mss.filter((m: any) => m)
      const msNames = mss ? mss.map(m => m && m.name) : []
      setMedicalSubjectNames(msNames)
    }
  }, [medicalSubjectIds])

  const { props: pickerProps, Component: PickerComponent, pick } = useMedicalSubjectPickerModal()

  const [professionInput, setProfessionInput] = React.useState('')
  const elements: string[] = ((profile && profile.profession) || '')
    .split(',')
    .map((v: string) => v.trim())
    .filter((v: any) => v)
  const [professions, setProfessions] = React.useState(elements)

  React.useEffect(() => {
    setProfessions(elements)
  }, [profile])

  const handleManageMedicalSubject = async function (selectedItems: number[]) {
    const finalSelection = await pick({
      selectedItems,
      showSearch: true,
      searchPlaceholder: '진료과목으로 검색',
    })

    if (finalSelection) {
      setMedicalSubjectIds(finalSelection as number[])
    }
  }

  const updateDoctorData = async () => {
    try {
      if (profile) {
        const profession = professions.join(',')
        await updateData({
          medicalSubjectIds,
          profession,
          availableFirstVisit: firstVisit,
          untactTreatment: untactToggle,
        })
      }
      WebUtils.showSuccess('진료정보를 업데이트하였습니다.', '성공')
      setUpdate(false)
    } catch (ex) {
      if (checkMessageWithPrefix(ex, ErrorCode.ERROR_UNTACT_TREATMENT_UNAVAILABLE_SCHEDULE_NOT_EXIST)) {
        setNotScheduleModal(true)
      } else {
        setNotMedicalDataModal(true)
      }
      if (
        !checkMessageWithPrefix(ex, ErrorCode.INVALID_PARAM_DEFAULT) &&
        !checkMessageWithPrefix(ex, ErrorCode.ERROR_UNTACT_TREATMENT_UNAVAILABLE_SCHEDULE_NOT_EXIST)
      ) {
        WebUtils.showError(ex)
      }
    }
  }

  const goWebcam = () => {
    window.open('https://forms.gle/qofRU64u6w2Uymf16', '_blank')
  }

  useEffect(() => {
    const savedProfessions: string[] = ((profile && profile.profession) || '')
      .split(',')
      .map((v: string) => v.trim())
      .filter((v: any) => v)
    const savedMedicalSubjectIds =
      (profile && profile.medicalSubjects.map(m => m.medicalSubject.medicalSubjectId)) || []
    let diffExists =
      _.difference(professions, savedProfessions).length > 0 ||
      _.difference(medicalSubjectIds, savedMedicalSubjectIds).length > 0
    if (!diffExists) {
      diffExists =
        _.difference(savedProfessions, professions).length > 0 ||
        _.difference(savedMedicalSubjectIds, medicalSubjectIds).length > 0
    }
    setUpdate(diffExists)
  }, [professions, medicalSubjectIds])

  return (
    <Wrapper>
      <PickerComponent {...pickerProps} />
      <BoxWrap>
        <TextWrap>
          <b>비대면 진료를 진행하시겠습니까?</b>
          <MessageWrap>
            <MessageIcon src={IconWarningBlue} alt="warning icon" style={{ marginRight: 4 }} /> 웹캠 설치는 필수입니다.
          </MessageWrap>
        </TextWrap>
        <RowWrap style={{ marginLeft: 100 }}>
          <SwitchLabel>비대면 진료</SwitchLabel>
          <SwitchWrap>
            <Toggle checked={untactToggle} icons={false} onChange={HandleToggle} />
            <ToggleLabel>
              <span>No</span>
              <span>Yes</span>
            </ToggleLabel>
          </SwitchWrap>
        </RowWrap>
      </BoxWrap>

      <RequiredWrap style={{ justifyContent: 'flex-end' }}>
        <Oval></Oval>
        <RequiredText className="gray7c">환자에게 공개되는 정보입니다.</RequiredText>
      </RequiredWrap>

      <TableList
        name="workingClinics"
        label="가능한 진료과목"
        mandatoryInput
        caption
        captionLabel="(복수입력 가능)"
        labelMDSize={5}
        style="left"
      >
        <ODVSpace h={4} />
        <TagWrapperFlex>
          <TagWap>
            {medicalSubjectNames.length > 0
              ? medicalSubjectNames.map((name: string) => <MedicalSubjectTag key={name} name={name} />)
              : null}
          </TagWap>
          <Button
            icon={ODIcons.CoreChatBubble}
            type={BP.TYPE.Basic}
            size={BP.SIZE.M}
            onClick={() => handleManageMedicalSubject(medicalSubjectIds)}
          >
            진료과목 추가
          </Button>
        </TagWrapperFlex>
      </TableList>

      <ODVSpace h={32} />

      <TableList
        name="mySpecializedField"
        label="전문분야 입력"
        mandatoryInput
        caption
        captionLabel="(입력 후 Enter : 복수입력 가능)"
        labelMDSize={5}
        style="left"
      >
        <ODVSpace h={4} />
        <EvixInputText
          value={professionInput}
          onChange={value => setProfessionInput(value)}
          onEnter={() => {
            const v = professionInput.trim()
            if (v.length > 0) {
              // 중복 제거
              const delta = _.uniq([...professions, v])
              setProfessions(delta)
              setProfessionInput('')
            }
          }}
          placeholder="전문분야 입력 후 Enter 로 추가합니다."
        />
        <ODVSpace h={32} />
        <TagWrapper>
          {professions.map((e, index) => (
            <ProfessionTag
              key={index}
              onRemove={() => {
                const newArr = [...professions]
                newArr.splice(index, 1)
                setProfessions(newArr)
              }}
            >
              {e}
            </ProfessionTag>
          ))}
        </TagWrapper>
        {professions.map((e, index) =>
          index < 1 ? (
            <span>
              <RoomSectionDivider />
              <ODVSpace h={24} />
            </span>
          ) : (
            ''
          )
        )}
      </TableList>

      <TableList name="firstVisit" label="초진 가능 여부" mandatoryInput caption>
        <ODVSpace h={4} />
        <ControllerWrapper>
          <input
            type="radio"
            name="first"
            checked={firstVisit}
            onChange={() => {
              setFirstVisit(true)
              setUpdate(true)
            }}
          ></input>
          <CheckboxLabel>가능</CheckboxLabel>
        </ControllerWrapper>
        <ODVSpace h={16} />
        <ControllerWrapper>
          <input
            type="radio"
            name="first"
            checked={!firstVisit}
            onChange={() => {
              setFirstVisit(false)
              setUpdate(true)
            }}
          ></input>
          <CheckboxLabel>불가능</CheckboxLabel>
        </ControllerWrapper>
      </TableList>
      <ODVSpace h={40} />
      <RoomSectionDivider style={{ opacity: 0.5 }} />
      <ODVSpace h={24} />
      <ConfirmButtonWrapper>
        <Button type={BP.TYPE.Primary} size={BP.SIZE.M} onClick={updateDoctorData} disabled={!update}>
          저장
        </Button>
      </ConfirmButtonWrapper>

      {/* 비대면 진료여부: Yes 모달 */}
      {showStartModal && (
        <ConfirmModal
          style={{ width: 420, height: 303 }}
          isTitle={true}
          title={'비대면 진료 시작'}
          titleIcon={IconWarningBlue}
          titleIconSize={'L'}
          isDescriptionIcon={false}
          description={['의사 정보 및 진료 정보를 업데이트 하시고,', <br />, '선생님의 스케줄을 설정해주세요.']}
          descriptionIcon={''}
          hideCancel={true}
          onOkPress={() => setShowStartModal(false)}
          onCancelPress={() => setShowStartModal(false)}
        />
      )}

      {/* 비대면 진료여부: No 모달 */}
      {showFinishModal && (
        <ConfirmModal
          style={{ width: 420, height: 303 }}
          isTitle={true}
          title={'비대면 진료 해제'}
          titleIcon={IconWarningRed}
          titleIconSize={'L'}
          isDescriptionIcon={false}
          description={['비대면 진료 가능한, 의료진 리스트에서', <br />, '선생님의 정보가 숨겨집니다.']}
          descriptionIcon={''}
          hideCancel={true}
          onOkPress={() => setShowFinishModal(false)}
          onCancelPress={() => setShowFinishModal(false)}
        />
      )}

      {/* 진료정보가 모두 입력되지 않은 경우 실행되는 모달 */}
      {notMedicalDataModal && (
        <ConfirmModal
          style={{ width: 420, height: 280 }}
          isTitle={true}
          titleIcon={IconWarningRed}
          titleIconSize={'L'}
          isDescriptionIcon={false}
          description={['진료과목, 전문분야를 모두 입력 후 저장해야', <br />, '비대면 진료 진행이 가능합니다.']}
          descriptionIcon={''}
          hideCancel={true}
          onOkPress={() => setNotMedicalDataModal(false)}
          onCancelPress={() => setNotMedicalDataModal(false)}
        />
      )}

      {/* 스케줄 설정이 세팅되었는지 확인 후 안되어있으면 실행되는 모달 */}
      {notScheduleModal && (
        <ConfirmModal
          style={{ width: 420, height: 280 }}
          isTitle={true}
          // title={'비대면 진료 시작'}
          titleIcon={IconWarningRed}
          titleIconSize={'L'}
          isDescriptionIcon={false}
          description={['진료 스케줄을 설정해야', <br />, '비대면 진료 진행이 가능합니다.']}
          descriptionIcon={''}
          hideCancel={true}
          onOkPress={() => setNotScheduleModal(false)}
          onCancelPress={() => setNotScheduleModal(false)}
        />
      )}
    </Wrapper>
  )
}

const webCamBtn = { height: 34, fontSize: 12, paddingTop: 0, paddingBottom: 0, marginTop: 5 }

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  // padding: 10px;

  > div {
    padding-left: 30px;
    padding-right: 30px;
  }
`
const ConfirmButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-bottom: 30px;
`
const TagWrapperFlex = styled.div`
  display: block;
  clear: both;
`

const TagWap = styled.div`
  float: left;
`

const TagWrapper = styled.div`
  display: block;

  > :not(:first-child) {
    margin-left: 8px;
  }
`
const CheckboxLabel = styled.label`
  line-height: 18px;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.26px;
  color: var(--gray700);
  margin: 0;
  margin-left: 5px;
  margin-right: 10px;
`
const ControllerWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const BoxWrap = styled.p`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 38px 30px 31px 30px;
  border-bottom: 1px solid #dadddf;
`

const TextWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 322px;
  gap: 5px;

  img {
    margin-top: 1px;
    width: 13px;
    height: 13px;
  }

  b {
    font-weight: bold;
    font-size: 16px;
    color: #424242;
  }
`
const MessageWrap = styled.div`
  font-size: 13px;
  line-height: 20px;
  color: #7c818b;
  font-weight: 500;
  display: flex;
  flex-direction: row;
  align-items: center;

  b {
    font-size: 13px;
    line-height: 20px;
    color: #7c818b;
    font-weight: bold;
  }
`
