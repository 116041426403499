import {
  ODButton,
  ODButtonSize,
  ODButtonTheme,
  ODIcon,
  ODIcons,
  ODListablePaginatedTable2,
  ODListablePagination,
  ODListableSearchBox,
  useODListableContext,
} from '@mdpp/od-react-belt'
import moment from 'moment-timezone'
import React from 'react'
import { Link } from 'react-router-dom'

import styled from 'styled-components/macro'
import { GQLFaq } from '@mdpp/common/lib/@types/server.schema'
import { SiteUrls } from '../../../SiteUrls'
import { WebUtils } from '../../../utils/webUtils'
import { IFaqListableOption } from '../FaqCommon'
import { useFaqListDataLoader } from './useFaqListDataLoader'
import {Page, PageContainer, PageContent, PageHeader, PageTitle} from "../../page/Page";
import { EvixCard } from '../../card/EvixCard'

interface IFaqListableTableProps {}

export const FaqListableTable: React.FC<IFaqListableTableProps> = props => {
  const { Provider, Context } = useODListableContext<GQLFaq, IFaqListableOption>()

  const dataLoader = useFaqListDataLoader({})

  const searchBoxStyle = { flex: 1,}
  const wrapperStyle = { display: 'flex', alignItems: 'center', justifyContent: 'center' }
  const toolbarStyle = { display: 'flex', justifyContent: 'space-between', marginBottom: 15 }
  
  const linkStyle = { textDecoration: 'none' }
  const addButtonStyle = { minWidth: 100 }


  return (
    <Provider
      dataLoader={dataLoader}
      keyExtractor={v => v.faqId.toString()}
      pageSize={10}
      onDataLoaderError={WebUtils.showError}
      searchOnLoad
    >
      <PageContainer>
        <Page>
          <PageHeader>
            <PageTitle>자주하는 질문</PageTitle>
          </PageHeader>
        
          <SearchWrapper style={toolbarStyle}>
            <ODListableSearchBox listableContext={Context} placeholder="제목으로 검색" style={searchBoxStyle} />
            <Link to={SiteUrls.Admin.Faq.Create} style={linkStyle}>
              <ODButton theme={ODButtonTheme.Primary} size={ODButtonSize.Small} rounded style={addButtonStyle}>
                질문 추가
              </ODButton>
            </Link>
          </SearchWrapper>

          <PageContent>
            <EvixCard noPadding>
              <ODListablePaginatedTable2
                numColumns={4}
                listableContext={Context}
                renderHeader={() => (
                  <tr>
                    <th style={{textAlign: 'center', width: 180 }}>등록시각</th>
                    <th style={{textAlign: 'center' }}>질문</th>
                    <th style={{textAlign: 'center', width: 80 }}>우선순위</th>
                    <th style={{textAlign: 'center', width: 80 }}>수정</th>
                  </tr>
                )}
                renderRow={(value: GQLFaq, context) => (
                  <tr key={value.faqId} onClick={() => console.log(value)}  style={{ borderBottom: '1px solid #D1D5DA', textAlign: 'center' }}>
                    <td>
                      <span>{moment(value.createdAt).format('ll')}</span>
                    </td>
                    <td align='left'>
                      <a href={value.link} target="_blank" rel="noopener noreferrer">
                        <span>{value.title}</span>
                      </a>
                    </td>
                    <td>
                      <span>{value.priority}</span>
                    </td>
                    <td>
                      <Link to={SiteUrls.Admin.Faq.Edit(value.faqId)}>
                        <IconWrapper>
                          <ODIcon icon={ODIcons.CorePencil} />
                        </IconWrapper>
                      </Link>
                    </td>
                  </tr>
                )}
                eventParentContext={{}}
              />
            </EvixCard>
          </PageContent>
          <ODListablePagination hideIfSinglePage={false} listableContext={Context} wrapperStyle={wrapperStyle} />
        </Page>
      </PageContainer>
    </Provider>
  )
}

const SearchWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  margin-bottom: 24px;
  
  > div > form > div {
    > div {
      border-radius: 8px;

      > div {
        border-radius: 8px;
        background-color: white;
        > span {
          border-radius: 8px;
        }
      }

      > input {
        border-radius: 0px 8px 8px 0px;
      }
    }

    @media (max-width: 992px) {
      width: 290px;
    }
  }
`


const IconWrapper = styled.span`
  font-size: 14px;
  letter-spacing: 0.3px;
  color: var(--gray600);
`
