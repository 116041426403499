// 예약 수정 컴포넌트
import { SCHEDULE_TYPE, ScheduleUtil } from '@mdpp/common'
import { BlockingLoadBox, ODVSpace, SingleQueryWrapper, useODModalContext, ODHSpace } from '@mdpp/od-react-belt'
import moment from 'moment'
import 'moment/locale/ko'
import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components/macro'
import { GQLReservation, GQLSCHEDULE_TYPE } from '@mdpp/common/lib/@types/server.schema'
import { useDoctorAPIs } from '../../../doctor/context/useDoctorAPIs'
import { WebUtils } from '../../../utils/webUtils'
import { useCommonAPIs } from '../../hooks/useCommonAPIs'
import { useMutationAPI } from '../../hooks/useMutationAPI'
import { ISCHEDULE_DATA, ISCHEDULE_DATA_STATE } from '../ScheduleSettingCommon'
import { LinkStyle, ScheduleStatusPopOver } from '../ScheduleStatusPopOver'
import { fillInScheduleSettingTable } from './FillInScheduleSettingTable'
import { ScheduleStateCell } from './ScheduleStateCell'
import { debounce } from 'lodash'
import { ImageButton, Button, BP } from '../../RebeccaButton'
import SVGSchedule from '../../../assets/schedule_white.svg'
import SVGScheduleBlack from '../../../assets/schedule_black.svg'
import { SiteUrls } from '../../../SiteUrls'
import {Page, PageContainer, PageContent, PageHeader, PageTitle} from "../../page/Page";
import {EvixCard} from "../../card/EvixCard";

interface IScheduleEditing {
  doctorId: number
  linkToSetting: string
  compactMargin: boolean
  linkStyle: LinkStyle
  fromDoctor: boolean
  inTab: boolean & any
}

interface IScheduleLocalChange {
  id: number
  date: string
  time: string
  toAvailable: boolean
}

interface IUpdateErrorWithChange {
  error: Error
  change: IScheduleLocalChange
}

type IScheduleLocalChangeMap = { [settingId: number]: IScheduleLocalChange }

export const ScheduleEditing: React.FC<IScheduleEditing> = props => {
  const { doctorId, linkToSetting, compactMargin, linkStyle, fromDoctor } = props
  const { listScheduleSetting, updateScheduleSetting, getDoctor } = useCommonAPIs()
  const [date, setDate] = React.useState(() =>
    moment()
      .startOf('week')
      .format('YYYY-MM-DD')
  )
  const [slotTimes, setSlotTimes] = React.useState<string[]>([])
  const [slots, setSlots] = React.useState<ISCHEDULE_DATA[]>([])
  const [loading, setLoading] = React.useState(false)
  const [reloadToken, setReloadToken] = React.useState(0)

  const [localChanges, setLocalChanges] = React.useState<IScheduleLocalChangeMap>({})

  const [popoverOpenCellId, setPopoverOpenCellId] = React.useState<string | null>(null)
  const [popoverReservation, setPopoverReservation] = React.useState<GQLReservation | null>(null)
  const { confirmInput } = useODModalContext()
  const apis = useDoctorAPIs()
  const cancelReservation = useMutationAPI(apis.cancelReservation, {
    onSuccess: '진료 예약을 취소하였습니다.',
    setLoading,
  })
  const [dateRange, setDateRange] = React.useState<string[] | null>(null)
  const inTab = React.useState<boolean | null>(null)
  const togglePopover = (id: string | null) => {
    setPopoverOpenCellId(id)
  }

  let period = ''
  if (dateRange && dateRange.length >= 0) {
    const lastSchedule = moment(dateRange[1], 'YY-MM-DD')
    const now = moment()
    const remain = lastSchedule.month() - now.month()
    if (remain > 0) {
      period = '' + remain
    } else {
      period = (remain + 12) + ''
    }
  }

  React.useEffect(() => {
    if (popoverOpenCellId) {
      setPopoverOpenCellId(null)
      setPopoverReservation(null)
    }
  }, [date])

  const handlePrev = () => {
    setDate(v =>
      moment(v, 'YYYY-MM-DD')
        .add(-7, 'day')
        .startOf('week')
        .format('YYYY-MM-DD')
    )
  }

  const delayHandlePrev = React.useCallback(debounce(handlePrev, 200), [])

  const handleNext = () => {
    setDate(v =>
      moment(v, 'YYYY-MM-DD')
        .add(7, 'day')
        .startOf('week')
        .format('YYYY-MM-DD')
    )
  }

  const delayHandleNext = React.useCallback(debounce(handleNext, 200), [])

  const handleToday = () => {
    setDate(
      moment()
        .startOf('week')
        .format('YYYY-MM-DD')
    )
  }

  const handleCancel = () => {
    for (let localChangesKey in localChanges) {
      slots.map(v => {
        const item = v.state.find(s => localChanges[localChangesKey].id === s.id)
        if (item) {
          item.avail = item.avail === SCHEDULE_TYPE.AVAILABLE ? SCHEDULE_TYPE.UNAVAILABLE : SCHEDULE_TYPE.AVAILABLE
        }
      })
    }
    setLocalChanges({})
    setSlots(slots)
  }

  const handleSave = async () => {
    const changes = Object.keys(localChanges)
    if (changes.length === 0) {
      WebUtils.showError('변경된 데이터가 없습니다.')
      return
    }

    setLoading(true)
    const errors: IUpdateErrorWithChange[] = []
    let change: IScheduleLocalChange | null = null
    for (const changeId of changes) {
      // @ts-ignore
      change = localChanges[changeId]
      try {
        await updateScheduleSetting({
          id: change!.id,
          scheduleType: change!.toAvailable ? GQLSCHEDULE_TYPE.AVAILABLE : GQLSCHEDULE_TYPE.UNAVAILABLE,
        })
      } catch (ex) {
        errors.push({
          error: ex,
          change: change!,
        })
      }
    }

    if (errors.length === 0) {
      WebUtils.showSuccess('스케줄을 업데이트하였습니다.')
    } else {
      WebUtils.showError('일부 스케줄 업데이트에 오류가 발생했습니다. 이미 예약이 되어 있는 슬롯이 있습니다.')
    }
    setLocalChanges({})
    setReloadToken(t => t + 1)
    setLoading(false)
  }

  const start = moment(date, 'YYYY-MM-DD').startOf('week')
  const end = moment(date, 'YYYY-MM-DD').endOf('week')
  const now = moment()
  const isTodayButtonActive = now.isSameOrAfter(start) && now.isSameOrBefore(end)

  const handleCellClick = async (data: ISCHEDULE_DATA_STATE, divId: string, reservation: GQLReservation | null) => {
    const isPast = moment(`${data.date} ${data.time}`, 'YYYY-MM-DD HH:mm')
      .add(-30, 'minutes')
      .isSameOrBefore(moment())

    // -1 means that slot does not exists.
    if (data.id === -1) {
      return
    }

    if (!reservation && isPast) {
      return
    }

    if (reservation) {
      setPopoverOpenCellId(prev => {
        if (!prev) {
          setPopoverReservation(reservation)
        }
        return prev ? null : divId
      })
      return
    }

    const toAvailable = data.avail === SCHEDULE_TYPE.UNAVAILABLE
    if (localChanges[data.id]) {
      delete localChanges[data.id]
    } else {
      localChanges[data.id] = {
        id: data.id,
        date: data.date,
        time: data.time,
        toAvailable,
      }
    }

    const found = slots.find(v => {
      const item = v.state.find(s => s.id === data.id)
      if (item) {
        item.avail = toAvailable ? SCHEDULE_TYPE.AVAILABLE : SCHEDULE_TYPE.UNAVAILABLE
        return true
      }
      return false
    })

    if (found) {
      setSlots([...slots])
    }
  }

  const getWeekDayClickOperation = (date: string): boolean | null => {
    const reservableSlotsOfGivenDay = slots
      .find(v => v.date === date) // 해당 날짜의 슬롯데이터 중
      ?.state.filter(v => {
        // 예약이 가능한 슬롯데이터를 찾아서,
        const slotTime = moment(`${v.date} ${v.time}`, 'YYYY-MM-DD HH:mm')
        return WebUtils.isGivenTimeAvailableForReservation(slotTime.toDate())
      })
      // 예약이 없는 슬롯데이터를 기반으로,
      .filter(v => !v.reservation)
      // 실제 데이터 (로컬보여주기를 위한 슬롯이 아닌)
      .filter(v => v.id > 0)

    if (!reservableSlotsOfGivenDay || reservableSlotsOfGivenDay.length === 0) {
      return null // 아예 해당 날짜 슬롯이 없으니 클릭 불가.
    }

    // 예약 불가능한 슬롯이 1개라도 있으면 가능으로 변경
    return !!reservableSlotsOfGivenDay.find((v: ISCHEDULE_DATA_STATE) => {
      return v.avail === SCHEDULE_TYPE.UNAVAILABLE // || (localChanges[v.id] && localChanges[v.id].toAvailable)
    })
  }

  const getTimeClickOperation = (time: string): boolean | null => {
    const reservableSlotsOfGivenTime = slots
      .map(v => v.state.find(v => v.time === time)!) // 이번 주 슬롯의 해당 시각 슬롯들에 대하여,
      .filter(v => {
        // 예약이 가능한 슬롯데이터를 찾아서,
        const slotTime = moment(`${v.date} ${v.time}`, 'YYYY-MM-DD HH:mm')
        return WebUtils.isGivenTimeAvailableForReservation(slotTime.toDate())
      })
      // 예약이 없는 슬롯데이터를 기반으로,
      .filter(v => !v.reservation)
      // 실제 데이터 (로컬보여주기를 위한 슬롯이 아닌)
      .filter(v => v.id > 0)

    if (!reservableSlotsOfGivenTime || reservableSlotsOfGivenTime.length === 0) {
      return null // 아예 해당 날짜 슬롯이 없으니 클릭 불가.
    }

    // 예약 불가능한 슬롯이 1개라도 있으면 가능으로 변경
    return !!reservableSlotsOfGivenTime.find((v: ISCHEDULE_DATA_STATE) => {
      return v.avail === SCHEDULE_TYPE.UNAVAILABLE // || (localChanges[v.id] && localChanges[v.id].toAvailable)
    })
  }

  const handleClickTime = async (time: string) => {
    const toAvailableSlot = getTimeClickOperation(time)
    console.log(176, `Changing ${time} slots to => ${toAvailableSlot}`)
    if (toAvailableSlot === null) {
      return
    }

    ;[0, 1, 2, 3, 4, 5, 6].forEach(i => {
      const d = moment(date, 'YYYY-MM-DD').add(i, 'day')
      const dateToProcess = d.format('YYYY-MM-DD')
      const actualTime = moment(`${dateToProcess} ${time}`, 'YYYY-MM-DD HH:mm')
      if (!WebUtils.isGivenTimeAvailableForReservation(actualTime.toDate())) {
        return
      }

      const slot = slots.find(v => v.date === dateToProcess)?.state.find(slot => slot.time === time)
      if (!slot || slot.reservation) {
        return
      }

      if (slot.avail === SCHEDULE_TYPE.AVAILABLE && toAvailableSlot) {
        return
      }
      if (slot.avail === SCHEDULE_TYPE.UNAVAILABLE && !toAvailableSlot) {
        return
      }

      if (localChanges[slot.id]) {
        // toggle.
        delete localChanges[slot.id]
      } else {
        localChanges[slot.id] = {
          time,
          id: slot.id,
          date: slot.date,
          toAvailable: toAvailableSlot,
        }
      }
      slot.avail = toAvailableSlot ? SCHEDULE_TYPE.AVAILABLE : SCHEDULE_TYPE.UNAVAILABLE
    })

    setSlots([...slots])
  }

  const handleClickWeekDay = async (date: string) => {
    const toAvailableSlot = getWeekDayClickOperation(date)
    console.log(176, `Changing ${date} slots to => ${toAvailableSlot}`)
    if (toAvailableSlot === null) {
      return
    }

    const dataOfGivenDay = slots.find(v => v.date === date)!.state
    for (const slotTime of slotTimes) {
      const m = moment(`${date} ${slotTime}`, 'YYYY-MM-DD HH:mm')
      if (WebUtils.isGivenTimeAvailableForReservation(m.toDate())) {
        // console.log(185, `Slot ${m.format('HH:mm')} is available for changes.`)
        const slot = dataOfGivenDay.find(v => v.time === slotTime)
        if (!slot) {
          console.warn(`Slot of ${date} ${slotTime} is not found.`)
          continue
        }

        if (localChanges[slot.id]) {
          if (localChanges[slot.id].toAvailable) {
            if (toAvailableSlot) {
              continue
            } else {
              delete localChanges[slot.id]
              slot.avail = SCHEDULE_TYPE.UNAVAILABLE
            }
          } else {
            if (toAvailableSlot) {
              delete localChanges[slot.id]
              slot.avail = SCHEDULE_TYPE.AVAILABLE
            } else {
              continue
            }
          }
        } else if (slot.reservation) {
          // cannot operate on reserved slot.
          continue
        } else {
          if (slot.avail === SCHEDULE_TYPE.UNAVAILABLE) {
            if (toAvailableSlot) {
              localChanges[slot.id] = {
                toAvailable: true,
                date,
                id: slot.id,
                time: slotTime,
              }
              slot.avail = SCHEDULE_TYPE.AVAILABLE
            }
          } else {
            if (!toAvailableSlot) {
              localChanges[slot.id] = {
                toAvailable: false,
                date,
                id: slot.id,
                time: slotTime,
              }
              slot.avail = SCHEDULE_TYPE.UNAVAILABLE
            }
          }
        }
      }
    }

    // console.log(234, localChanges)
    // reload.
    setSlots([...slots])
  }

  const handleCancelReservation = async () => {
    if (!popoverReservation) {
      return
    }
    const reservation = popoverReservation
    setPopoverReservation(null)
    setPopoverOpenCellId(null)

    const reason = await confirmInput({
      title: '예약 취소',
      message: '취소 사유를 입력하세요. 환자에게 전달됩니다.',
      yes: '예약 취소',
      no: '취소 안함',
    })

    if (reason !== undefined) {
      await cancelReservation({
        reservationId: reservation.reservationId,
        cancelReason: reason,
        isPatientNoShow: false,
      })

      setReloadToken(t => t + 1)
    }
  }

  const dirty = Object.keys(localChanges).length > 0

  return (
    <>
      <BlockingLoadBox show={loading} />
      <SingleQueryWrapper
        ignoreReloadLoading
        deps={[doctorId, date, listScheduleSetting, reloadToken, getDoctor]}
        getter={async () => {
          const doctor = await getDoctor({ id: doctorId })
          const r = await listScheduleSetting({
            doctorId,
            startDateToken: start.format('YYYY-MM-DD 00:00:00'),
            endDateToken: end.format('YYYY-MM-DD 23:59:59'),
            page: 1,
            pageSize: 1000,
          })
          if (doctor.scheduleAvailableStartDay && doctor.scheduleAvailableEndDay) {
            setDateRange([doctor.scheduleAvailableStartDay, doctor.scheduleAvailableEndDay])
          } else if(doctor.lastScheduleToken){
            setDateRange([moment().format('YYYY-MM-DD'), moment(doctor.lastScheduleToken).format('YYYY-MM-DD')])
          }

          const {
            schedule,
            hospital: { slotSize },
          } = r.doctor
          const s = new ScheduleUtil(JSON.parse(schedule))

          const time = Array.from(new Set(r.list.map(v => v.timeToken.substr('2020-12-19 '.length)))).sort((a, b) =>
            a < b ? -1 : 1
          )

          let calculatedTimes: string[] = []
          if (time.length === 0) {
            const times: string[] = []
            // 해당 의사의 스케줄 데이터가 없다. 설정으로부터 데이터를 만든다.
            const startTime = moment(s.getPossibleStartTimeOfTheWeek() ?? '09:00', 'HH:mm')
            const endTime = moment(s.getLatestEndTime() ?? '18:00', 'HH:mm')
            const t = moment(startTime, 'HH:mm')
            while (t.isBefore(endTime)) {
              // 14:00 ~ 20:00 60일간 스케줄 설정
              times.push(t.format('HH:mm'))
              t.add(slotSize, 'minute')
            }
            setSlotTimes(times)
            calculatedTimes = times
          } else {
            const times: string[] = []
            const startTime = moment(s.getPossibleStartTimeOfTheWeek(), 'HH:mm')
            const endTime = moment(s.getLatestEndTime(), 'HH:mm')
            const t = moment(startTime, 'HH:mm')
            while (t.isBefore(endTime)) {
              times.push(t.format('HH:mm'))
              t.add(slotSize, 'minute')
            }
            const updatedTime = Array.from(new Set([...times, ...time])).sort((a, b) => (a < b ? -1 : 1))

            setSlotTimes(updatedTime)
            calculatedTimes = updatedTime
          }

          const rr: ISCHEDULE_DATA[] = []
          let dateToken = ''
          let items: ISCHEDULE_DATA_STATE[] = []

          r.list.forEach((v, index) => {
            const [d, t] = v.timeToken.split(' ')
            if (dateToken !== d) {
              if (dateToken) {
                rr.push({
                  date: dateToken,
                  state: items,
                })
              }
              items = []
              dateToken = d
            }

            items.push({
              id: v.scheduleSettingId,
              date: d,
              // @ts-ignore
              avail: v.scheduleType,
              time: t,
              reservation: v.reservation,
            })

            if (index === r.list.length - 1) {
              rr.push({
                date: dateToken,
                state: items,
              })
            }
          })

          fillInScheduleSettingTable(
            schedule,
            rr,
            start.format('YYYY-MM-DD'),
            end.format('YYYY-MM-DD'),
            calculatedTimes
          )
          setSlots(rr)

          return r.list
        }}
      >
        {({ data }) => {
          return (
            <PageContainer>
              {!inTab?
                  <Page>
                    <PageHeader>
                      {!inTab && <PageTitle>스케줄</PageTitle> }
                      
                      {dateRange && (
                        <>
                          <Link to={linkToSetting} style={{textDecoration: 'none', backgroundColor: '#fff'}}>
                            <ImageButton size={BP.SIZE.L} type={BP.TYPE.Outline} images={{default: SVGScheduleBlack}}>기본 스케줄 설정</ImageButton>
                          </Link>
                        </>)
                      }
                    </PageHeader>
                    <PageContent>
                      <EvixCard>
                        {popoverOpenCellId && popoverReservation && (
                            <ScheduleStatusPopOver
                                reservation={popoverReservation}
                                popoverOpen={!!popoverOpenCellId}
                                popoverTargetId={popoverOpenCellId}
                                togglePopover={() => togglePopover(null)}
                                onClickCancel={handleCancelReservation}
                                linkStyle={linkStyle}
                            />
                        )}
                        <div style={{ display: 'flex', flexDirection: 'column'}}>
                          {!dateRange ?
                              <div style={{width: '100%'}}>
                                <DefaultScheduleText>기본 스케줄</DefaultScheduleText>
                                <ScheduleRangeText>요일별 근무시간, 당일예약, 공휴일 진료를 설정할 수 있어요.</ScheduleRangeText>
                                <div style={{display: 'flex', justifyContent: 'center'}}>
                                  <Link to={linkToSetting} style={{textDecoration: 'none'}}>
                                    <ImageButton type={BP.TYPE.Primary} size={BP.SIZE.L} images={{default: SVGSchedule}}>기본 스케줄 설정하기</ImageButton>
                                  </Link>
                                </div>
                              </div>
                              :
                              dateRange && (
                                  <>
                                    <ScheduleRangeHeaderText>스케줄 설정</ScheduleRangeHeaderText>
                                    <ODVSpace h={36} />
                                    <ScheduleEditingHeader>
                                      <HeadColumn>
                                        <PeriodWrapper>
                                          <PeriodLabel>예약 가능기간</PeriodLabel>
                                          <RangeWrapper>
                                            <Range>오늘부터 {period}개월간</Range><Seperator></Seperator>
                                            <Link to={linkToSetting}>
                                              <ChangeBtn>변경</ChangeBtn>
                                            </Link>
                                          </RangeWrapper>
                                        </PeriodWrapper>
                                      </HeadColumn>
                                      <HeadColumn>
                                        <Button disabled={isTodayButtonActive} onClick={handleToday}>
                                          오늘
                                        </Button>
                                        <ODHSpace w={33}/>
                                        <SchedulePagination>
                                          <PrevBtn onClick={delayHandlePrev}>
                                            <ArrowIconTop />
                                            <ArrowIconBtm />
                                          </PrevBtn>
                                          <ScheduleWeekPeriod>
                                            {start.format('YYYY년 M월 D일')} ~{' '}
                                            {start
                                                .clone()
                                                .add(6, 'day')
                                                .format('M월 D일')}
                                          </ScheduleWeekPeriod>
                                          <NextBtn onClick={delayHandleNext}>
                                            <ArrowIconTop />
                                            <ArrowIconBtm />
                                          </NextBtn>
                                        </SchedulePagination>
                                      </HeadColumn>
                                    </ScheduleEditingHeader>
                                    {dirty && (
                                        <ConfirmArea>
                                          <LeftWrapper>
                                            <UpperText>변경된 일정이 있습니다. 업데이트 하시겠습니까?</UpperText>
                                            <LowerText>예약된 진료는 일정이 변경되어도 취소되지 않습니다.</LowerText>
                                          </LeftWrapper>
                                          <RightWrapper>
                                            <Link to={SiteUrls.Doctor.Schedule.Main}>
                                              <Button size={BP.SIZE.M} onClick={handleCancel}>취소</Button>
                                            </Link>
                                            <ODHSpace w={12} />
                                            <Button onClick={handleSave} size={BP.SIZE.M} type={BP.TYPE.Primary}>업데이트</Button>
                                          </RightWrapper>

                                        </ConfirmArea>
                                    )}
                                    <ScheduleEditingTable>
                                      <ScheduleTimeColumn>
                                        <ScheduleTopCellWrapper title={true}></ScheduleTopCellWrapper>
                                        {slotTimes.map((time: string, index: number) => {
                                          return (
                                              <ScheduleTimeCell key={index} onClick={() => handleClickTime(time)} style={{ cursor: 'pointer' }}>
                                                {time}
                                              </ScheduleTimeCell>
                                          )
                                        })}
                                      </ScheduleTimeColumn>
                                      {slots.map((daily: ISCHEDULE_DATA, index: number) => (
                                          <ScheduleColumnWrapper
                                              key={index}
                                              daily={daily}
                                              onClick={(data, divId, reservation) => handleCellClick(data, divId, reservation)}
                                              onClickHeader={date => handleClickWeekDay(date)}
                                          />
                                      ))}
                                    </ScheduleEditingTable>
                                    <ODVSpace h={64} />
                                  </>
                              )
                          }
                        </div>
                      </EvixCard>
                    </PageContent>
                  </Page>
                :
                  // <InTabPage>/
                  <Page>
                    {dateRange && (
                    <PageHeader>
                        <>
                          <PageTitle>스케줄 설정</PageTitle>
                          <Link to={linkToSetting} style={{textDecoration: 'none', backgroundColor: '#fff'}}>
                            <ImageButton size={BP.SIZE.L} type={BP.TYPE.Outline} images={{default: SVGScheduleBlack}}>기본 스케줄 설정</ImageButton>
                          </Link>
                        </>
                     
                    </PageHeader>
                     )}
                     {!dateRange && (<ODVSpace h={36} />)}
                    <PageContent>
                      <EvixCard>
                        {popoverOpenCellId && popoverReservation && (
                            <ScheduleStatusPopOver
                                reservation={popoverReservation}
                                popoverOpen={!!popoverOpenCellId}
                                popoverTargetId={popoverOpenCellId}
                                togglePopover={() => togglePopover(null)}
                                onClickCancel={handleCancelReservation}
                                linkStyle={linkStyle}
                            />
                        )}
                        <div style={{ display: 'flex', flexDirection: 'column'}}>
                          {!dateRange ?
                              <div style={{width: '100%'}}>
                                <DefaultScheduleText>기본 스케줄</DefaultScheduleText>
                                <ScheduleRangeText>요일별 근무시간, 당일예약, 공휴일 진료를 설정할 수 있어요.</ScheduleRangeText>
                                <div style={{display: 'flex', justifyContent: 'center'}}>
                                  <Link to={linkToSetting} style={{textDecoration: 'none'}}>
                                    <ImageButton type={BP.TYPE.Primary} size={BP.SIZE.L} images={{default: SVGSchedule}}>기본 스케줄 설정하기</ImageButton>
                                  </Link>
                                </div>
                              </div>
                              :
                              dateRange && (
                                  <>
                                    {/* <ScheduleRangeHeaderText>스케줄 설정</ScheduleRangeHeaderText>
                                    <ODVSpace h={36} /> */}
                                    <ScheduleEditingHeader>
                                      <HeadColumn>
                                        <SchedulePagination>
                                          <PrevBtn onClick={delayHandlePrev}>
                                            <ArrowIconTop />
                                            <ArrowIconBtm />
                                          </PrevBtn>
                                          <ScheduleWeekPeriod>
                                            {start.format('YYYY년 M월 D일')} ~{' '}
                                            {start
                                                .clone()
                                                .add(6, 'day')
                                                .format('M월 D일')}
                                          </ScheduleWeekPeriod>
                                          <NextBtn onClick={delayHandleNext}>
                                            <ArrowIconTop />
                                            <ArrowIconBtm />
                                          </NextBtn>
                                        </SchedulePagination>
                                        <ODHSpace w={5}/>
                                        <Button disabled={isTodayButtonActive} onClick={handleToday}>
                                          오늘
                                        </Button>
                                      </HeadColumn>
                                      <HeadColumn>
                                        <PeriodWrapper>
                                          <PeriodLabel>예약 가능기간</PeriodLabel>
                                          <RangeWrapper>
                                            <Range>오늘부터 {period}개월간</Range><Seperator></Seperator>
                                            <Link to={linkToSetting}>
                                              <ChangeBtn>변경</ChangeBtn>
                                            </Link>
                                          </RangeWrapper>
                                        </PeriodWrapper>
                                      </HeadColumn>
                                    </ScheduleEditingHeader>
                                    {dirty && (
                                        <ConfirmArea>
                                          <LeftWrapper>
                                            <UpperText>변경된 일정이 있습니다. 업데이트 하시겠습니까?</UpperText>
                                            <LowerText>예약된 진료는 일정이 변경되어도 취소되지 않습니다.</LowerText>
                                          </LeftWrapper>
                                          <RightWrapper>
                                            <Link to={SiteUrls.Doctor.Schedule.Main}>
                                              <Button size={BP.SIZE.M} onClick={handleCancel}>취소</Button>
                                            </Link>
                                            <ODHSpace w={12} />
                                            <Button onClick={handleSave} size={BP.SIZE.M} type={BP.TYPE.Primary}>업데이트</Button>
                                          </RightWrapper>

                                        </ConfirmArea>
                                    )}
                                    <ScheduleEditingTable>
                                      <ScheduleTimeColumn>
                                        <ScheduleTopCellWrapper title={true}></ScheduleTopCellWrapper>
                                        {slotTimes.map((time: string, index: number) => {
                                          return (
                                              <ScheduleTimeCell key={index} onClick={() => handleClickTime(time)} style={{ cursor: 'pointer' }}>
                                                {time}
                                              </ScheduleTimeCell>
                                          )
                                        })}
                                      </ScheduleTimeColumn>
                                      {slots.map((daily: ISCHEDULE_DATA, index: number) => (
                                          <ScheduleColumnWrapper
                                              key={index}
                                              daily={daily}
                                              onClick={(data, divId, reservation) => handleCellClick(data, divId, reservation)}
                                              onClickHeader={date => handleClickWeekDay(date)}
                                          />
                                      ))}
                                    </ScheduleEditingTable>
                                    <ODVSpace h={64} />
                                  </>
                              )
                          }
                        </div>
                      </EvixCard>
                    </PageContent>
                  {/* </InTabPage> */}
                  </Page>
              }
            </PageContainer>
          )
        }}
      </SingleQueryWrapper>
    </>
  )
}

interface IScheduleColumnWrapper {
  daily: ISCHEDULE_DATA
  onClick: (data: ISCHEDULE_DATA_STATE, divId: string, reservation: GQLReservation | null) => void
  onClickHeader: (date: string) => void
}

const ScheduleColumnWrapper: React.FC<IScheduleColumnWrapper> = props => {
  const { daily, onClick, onClickHeader, ...restProps } = props
  const dayValue = moment(daily.date).day()
  const date = moment(daily.date).format('dddd M/D')
  const day = moment(daily.date).format('dddd')
  const day2 = moment(daily.date).format('D')

  return (
    <ScheduleColumn {...restProps}>
      <ScheduleTopCellWrapper title={false} dayValue={dayValue} onClick={() => onClickHeader(daily.date)}>
        <ColumnDay>{day}</ColumnDay>
        <ColumnDate>{day2}</ColumnDate>
      </ScheduleTopCellWrapper>
      {daily.state.map((dailyState: ISCHEDULE_DATA_STATE, index: number) => (
        <ScheduleStateCell
          key={index}
          state={dailyState}
          onClick={(divId: string) => onClick(dailyState, divId, null)}
          onClickReservationCell={(divId, r) => onClick(dailyState, divId, r)}
        />
      ))}
    </ScheduleColumn>
  )
}

interface IScheduleTopCellWrapper {
  title?: boolean
  dayValue?: number
  onClick?: () => void
}

const ScheduleTopCellWrapper: React.FC<IScheduleTopCellWrapper> = props => {
  const { title, dayValue, children, onClick, ...restProps } = props

  const styleTitle = {
    // textDecoration: title ? 'none' : 'underline',
  }

  const styleWeekend = {
    color: dayValue === 6 ? 'var(--second)' : dayValue === 0 ? 'var(--danger)' : ' var(--gray800)',
  }

  return (
    <ScheduleTopCell onClick={onClick} style={{ ...styleTitle, ...styleWeekend, cursor: 'pointer', display: 'flex', flexDirection: 'column', }} {...restProps}>
      {children}
    </ScheduleTopCell>
  )
}

const ScheduleEditingHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 20px;
  width: 100%;
`

const HeadColumn = styled.div`
  display: flex;
  align-items: center;
`

const SchedulePagination = styled.div`
  display: flex;
  align-items: center;
  /* width: 360px; */
`

const ArrowBtn = styled.div`
  width: 17px;
  height: 17px;
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`

const PrevBtn = styled(ArrowBtn)``
const NextBtn = styled(ArrowBtn)`
  transform: rotateY(180deg);
`

const ArrowIcon = styled.span`
  display: inline-block;
  background: #000;
  width: 6px;
  height: 1.5px;
  margin: 1px;
  position: relative;
  border-radius: 1px;
`

const ArrowIconTop = styled(ArrowIcon)`
  transform: rotate(-45deg);
`

const ArrowIconBtm = styled(ArrowIcon)`
  transform: rotate(45deg);
`

const ScheduleWeekPeriod = styled.p`
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 30px;  
  width: 300px;
  text-align: center;
  margin: 0px 15px;
`

const ScheduleRangeText = styled.p`
  font-size: 13px;
  margin-top: 16px;
  font-weight: normal;
  font-style: normal;
  text-align: left;
  color: #4F5356;
`

const ScheduleEditingTable = styled.div`
  font-size: 14px;
  display: flex;
  width: 100%;
  border-top: 1px solid #ddd;
`

const ScheduleColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const ScheduleTimeColumn = styled(ScheduleColumn)`
  width: 51px;
  background: white;
  /* border-bottom: 1px solid #ddd; */
  border-left: none;
`

const ScheduleCell = styled.div`
  width: 100%;
  max-width: 200px;
  height: 67px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-bottom: 1px solid #ddd;
  border-top: none;
  background: white;
`

const ScheduleTopCell = styled(ScheduleCell)`
  font-weight: 700;
`

const ScheduleTimeCell = styled(ScheduleCell)`
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;

  display: flex;
  align-items: center;
  text-align: right;
  letter-spacing: -0.3px;

  color: #4F5356;
  width: 51px;
  height: 32px;
  border-bottom: 1px solid #F5F5F5;
`

const ScheduleRangeHeaderText = styled.span`
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #000000;
`

const DefaultScheduleText = styled.p`
  font-size: 18px;
  font-weight: 500;
  color: #000000;
  line-height: 24px;
  text-align: left;
`

const PeriodWrapper = styled.div`
  display: flex;
  /* min-width: 275px; */
`
const PeriodLabel = styled.span`
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  margin-right: 8px;
  color: #4F5356;
  white-space: nowrap;
  display: flex;
  align-items: center;
  letter-spacing: -0.3px;
`
const RangeWrapper = styled.div`
  display: flex;
  align-items: center;
  background: #FFFFFF;
  border: 1px solid #E7E8E9;
  box-sizing: border-box;
  border-radius: 80px;
  width: 182px;
  height: 38px;
  align-items: center;
  padding: 8px 12px;
`
const Range = styled.div`
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 18px;
  letter-spacing: -0.3px;
  color: #4F5356;
  white-space: nowrap;
`
const Seperator = styled.div`
  width: 1px;
  height: 22px;
  border-left: 1px solid #E7E8E9;
  margin: 0px 12px 0px 10px;
`
const ChangeBtn = styled.div`
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 18px;
  text-align: center;
  letter-spacing: -0.3px;
  color: #0085FF;
  background: rgba(0, 0, 0, 0);
  cursor: pointer;
  white-space: nowrap;

  &:hover {
    color: #0072DB;
  }

  &:focus {

  }

  &:active {
    color: #005EB5;
  }

  &:disabled {
    color: #C9CCCE;
  }
`

const ColumnDay = styled.div`
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;
`
const ColumnDate = styled.div`
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 30px;
`

const ConfirmArea = styled.div`
  // width: 100%;
  width: 1060px;
  max-width: 1600px;
  position: fixed;
  bottom: 20px;
  z-index: 1;
  height: 76px;
  padding: 24px;
  background: white;
  border: 1px solid #E7E8E9;
  box-sizing: border-box;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const LeftWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const RightWrapper = styled.div`
  display: flex;
`

const UpperText = styled.span`
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.3px;
  color: #131415;
`
const LowerText = styled.span`
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;
  letter-spacing: -0.3px;
  color: #9CA0A3;
`

export const InTabPage = styled.div`
  /* max-width: 1280px;
  width: 100%;
  padding: '0';
  display: flex;
  flex-direction: column; 
  justify-items: center;

  @media(max-width: 992px) {
    min-width: 874px;
  } */
`