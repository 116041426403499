import { useODModalContext } from '@mdpp/od-react-belt'
import React from 'react'
import styled from 'styled-components'
// tslint:disable-next-line:no-implicit-dependencies
import uuid from 'uuid'
import { GQLReservation, GQLRESERVATION_STATUS } from '@mdpp/common/lib/@types/server.schema'
import { EvixCardLabel } from '../../../../common/card/EvixCardLabel'
import { EvixCardRow } from '../../../../common/card/EvixCardRow'
import { EvixPrescriptionFileUpload, IEvixPrescriptionFile } from '../../../../common/EvixPrescriptionFileUpload'
import { IFileObject, IFileServer } from '../../../../common/hooks/useFileMutationAPI'
import { WebUtils } from '../../../../utils/webUtils'
import { useDoctorAPIs } from '../../../context/useDoctorAPIs'
import { ReservationBasicInfoBoxStyle } from '../../../../common/reservation/ReservationCommon'

interface IPatientDiagnosisPrescriptionInfoRowProps {
  reservation: GQLReservation // 구현 특성상 initial value 로만 사용되므로 유의.
  infoStyle: ReservationBasicInfoBoxStyle
  setFiles: (setPrescriptionFiles: IEvixPrescriptionFile[]) => void
  files: IEvixPrescriptionFile[]
  disabled: boolean
}

const FileTypeText = styled.div`
  font-size: 11px;
  color: var(--gray400);
`

export const PatientDiagnosisPrescriptionInfoRow: React.FC<IPatientDiagnosisPrescriptionInfoRowProps> = props => {
  const { reservation, setFiles, files, disabled } = props
  const { addPrescriptionFile, deletePrescriptionFile } = useDoctorAPIs()
  const [fs] = React.useState(() => {
    const fileServer: IFileServer = {
      async addFiles(f: File[]): Promise<Array<IFileObject | null>> {
        const results: Array<IFileObject | null> = []
        for (const file of f) {
          try {
            const fileUploaded = await addPrescriptionFile({
              reservationId: reservation.reservationId,
              file,
            })
            const obj: IFileObject = {
              id: fileUploaded.prescriptionFileId.toString(),
              fileName: fileUploaded.fileName,
              isDeleting: false,
              isUploading: false,
              link: fileUploaded.link,
            }
            results.push(obj)
          } catch (ex) {
            WebUtils.showError(ex)
            results.push(null)
          }
        }
        return results
      },
      async deleteFile(f: IFileObject): Promise<boolean> {
        try {
          await deletePrescriptionFile({ id: parseInt(f.id, 10) })
          return true
        } catch (ex) {
          WebUtils.showError(ex)
          return false
        }
      },
    }
    return fileServer
  })

  const handleFileDeleteUndo = (file: IEvixPrescriptionFile) => {
    const index = files.findIndex(f => f.id === file.id)
    if (index >= 0) {
      files[index].isDeleting = false
      setFiles([...files])
    }
  }

  const handleFileDelete = async (file: IEvixPrescriptionFile) => {
    try {
      const index = files.findIndex(f => f.id === file.id)
      if (index >= 0) {
        console.log('files: ', files)
        console.log('index: ', index)
        if (files[index].isUploading) {
          files.splice(index, 1)
        } else {
          files[index].isDeleting = true
        }
      }
      setFiles([...files])
    } catch (ex) {
      WebUtils.showError(ex)
    }
  }

  const handleFileAdd = async (filesToAdd: File[]) => {
    try {
      const fakeList: IEvixPrescriptionFile[] = filesToAdd.map(f => ({
        id: uuid.v4(),
        fileName: f.name,
        link: '',
        isDeleting: false,
        isUploading: true,
        file: f,
      }))

      const fileList: IEvixPrescriptionFile[] = [...files, ...fakeList]
      setFiles(fileList)
    } catch (ex) {
      WebUtils.showError(ex)
    }
  }

  const block = (reservation.status !== GQLRESERVATION_STATUS.FINISHED && reservation.status !== GQLRESERVATION_STATUS.IN_PROGRESS && reservation.status !== GQLRESERVATION_STATUS.PRESCRIPTION)

  return (
    <EvixCardRow style={{ alignItems: 'flex-start' }}>
      <EvixCardLabel>
        처방전
        <br />
        {!disabled && (
          <FileTypeText>pdf, jpg 가능</FileTypeText>
        )}
      </EvixCardLabel>
      <EvixPrescriptionFileUpload
        files={files}
        onClickDeleteFile={handleFileDelete}
        onClickDeleteUndo={handleFileDeleteUndo}
        onAddFiles={handleFileAdd}
        disabled={ disabled || block }
      />
    </EvixCardRow>
  )
}
