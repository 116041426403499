import React from 'react'
import { MainContentWithInset } from '../../common/layout/MainContentWithInset'
import { PharmacyEditPage } from '../../common/pharmacy/edit/PharmacyEditPage'

interface IAdminPharmacyEditPageProps {
  idEditing: number | null
}

export const AdminPharmacyEditPage: React.FC<IAdminPharmacyEditPageProps> = props => {
  return (
    <div>
      <MainContentWithInset>
        <PharmacyEditPage idEditing={props.idEditing} />
      </MainContentWithInset>
    </div>
  )
}
