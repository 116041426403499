import { ODIcon, ODIcons } from '@mdpp/od-react-belt'
import React from 'react'
import styled from 'styled-components'
import { COLORS } from '../../styles/colors'

export enum EvixChatMessageType {
  Normal = 'Normal',
  Diagnosis = 'Diagnosis',
  Prescription = 'Prescription',
}

export interface IEvixChatMessageCommon {
  messageType: EvixChatMessageType
  isMine: boolean
  name: string
  time: string
}

export interface IEvixChatMessageNormal extends IEvixChatMessageCommon {
  messageType: EvixChatMessageType.Normal
  message: string
}

export interface IEvixChatMessageDiagnosis extends IEvixChatMessageCommon {
  messageType: EvixChatMessageType.Diagnosis
  diagnosis: string
}

export interface IEvixChatMessagePrescription extends IEvixChatMessageCommon {
  messageType: EvixChatMessageType.Prescription
  fileName: string
  size: string
  untilTime: string
  link: string
}

export type EvixChatMessage = IEvixChatMessageNormal | IEvixChatMessageDiagnosis | IEvixChatMessagePrescription

interface IEvixChatMessageWrapperProps {
  message: EvixChatMessage
}

const Wrapper = styled.div`
  display: flex;
  border-radius: 1px;
`

const NameTimeWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 5px;
  margin-left: 2px;
`

const Name = styled.div`
  font-size: 12px;
  line-height: 1.33;
  color: #313f54;
`

const Time = styled.div`
  font-size: 11px;
  line-height: 1.45;
  color: #aaafb6;
  margin-left: 3px;
`

interface IMsgProps {
  isMine: boolean,
}

const MessageBox = styled.div`
  padding: 8px;
  word-wrap: break-word;
  border-radius: 8px;
  max-width: 264px;
  background-color: ${(c: { whos: IMsgProps}) => (c.whos.isMine ? '#d0f1ff' : '#F9FAFB')};
  color: ${(c: { whos: IMsgProps}) => (c.whos.isMine ? '#1061b4' : '#131415')};
  font-size: 14px;
`

const DiagnosisEntered = styled.div`
  color: var(--gray900);
  margin-bottom: 20px;
`

const DiagnosisMessage = styled.div`
  color: var(--mpPrimary500);
`

const EvixDiagnosisMessage: React.FC<{ message: IEvixChatMessageDiagnosis }> = props => {
  return (
    <div>
      <DiagnosisEntered>진단이 입력되었습니다.</DiagnosisEntered>
      <DiagnosisMessage>“{props.message.diagnosis}”</DiagnosisMessage>
    </div>
  )
}

const PrescriptionMeta = styled.div`
  font-size: 12px;
  line-height: 1.25;
  color: #aaafb6;
`

const DownloadText = styled.div`
  font-size: 13px;
  line-height: 1.23;
  cursor: pointer;
  color: var(--mpPrimary500);
`

const EvixPrescriptionMessage: React.FC<{ message: IEvixChatMessagePrescription }> = ({ message }) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <ODIcon icon={ODIcons.CoreFile} style={{ marginTop: 3, marginRight: 8 }} />
        <span>{message.fileName}</span>
      </div>
      <PrescriptionMeta style={{ marginTop: 9, marginBottom: 6 }}>
        크기 : {message.size}
        <br />
        유효기간 : ~ {message.untilTime}
      </PrescriptionMeta>
      <a href={message.link} target="_blank" rel="noopener noreferrer">
        <DownloadText>다운로드</DownloadText>
      </a>
    </div>
  )
}

export const EvixChatMessageWrapper: React.FC<IEvixChatMessageWrapperProps> = props => {
  const { message } = props

  const msg = ((m: EvixChatMessage) => {
    switch (m.messageType) {
      case EvixChatMessageType.Normal:
        return <span>{m.message}</span>
      case EvixChatMessageType.Diagnosis:
        return <EvixDiagnosisMessage message={m} />
      case EvixChatMessageType.Prescription:
        return <EvixPrescriptionMessage message={m} />
    }
  })(message)

  return (
    <Wrapper style={{ justifyContent: message.isMine ? 'flex-end' : 'flex-start' }}>
      <Inner style={{ alignItems: message.isMine ? 'flex-end' : 'flex-start' }}>
        <NameTimeWrapper>
          <Name>{message.name}</Name>
          <Time>{message.time}</Time>
        </NameTimeWrapper>
        <MessageBox whos={{isMine: message.isMine}}>
          {msg}
        </MessageBox>
      </Inner>
    </Wrapper>
  )
}

const Inner = styled.div`
  display: flex;
  flex-direction: column;
`
