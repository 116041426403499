import { ODEntity, ODEntityInput, ODEntityLabeled } from '@mdpp/od-react-belt'
import gql from 'graphql-tag'
import React from 'react'
import { Card, CardBody } from 'reactstrap'
import styled from 'styled-components'
import {
  GQLHospitalManager,
  GQLHospitalManagerCreationInput,
  GQLHospitalManagerUpdateInput,
} from '@mdpp/common/lib/@types/server.schema'
import { EvixCard } from '../../../common/card/EvixCard'
import { SiteUrls } from '../../../SiteUrls'
import { COLORS } from '../../../styles/colors'
import { useODEntityAPI } from '../../../utils/odGQLEntityLoader'
import {
  GQL_CREATE_HOSPITAL_MANAGER,
  GQL_DELETE_HOSPITAL_MANAGER,
  GQL_GET_HOSPITAL_MANAGER,
  GQL_UPDATE_HOSPITAL_MANAGER,
} from '../../context/gqls_evix_admin'
import { HospitalManagerPrimaryKey, ICTHospitalManager } from '../HospitalManagerCommon'
import {TableList, TableListWrapper} from "../../../common/list/TableList";

interface IHospitalManagerEditProps {
  hospitalId: number
  idEditing: number | null
}

const DEFAULT_DATA: Partial<ICTHospitalManager> = {
  hospitalManagerId: 0,
  name: '',
  email: '',
  hospitalId: 0,
}

const createDefaultData = (hospitalId: number) => ({ ...DEFAULT_DATA, hospitalId })

export const HospitalManagerEdit: React.FC<IHospitalManagerEditProps> = props => {
  const { idEditing, hospitalId } = props
  const apis = useODEntityAPI<
    GQLHospitalManager,
    ICTHospitalManager,
    GQLHospitalManagerCreationInput,
    GQLHospitalManagerUpdateInput
  >({
    createGQL: gql(GQL_CREATE_HOSPITAL_MANAGER),
    readGQL: gql(GQL_GET_HOSPITAL_MANAGER),
    updateGQL: gql(GQL_UPDATE_HOSPITAL_MANAGER),
    deleteGQL: gql(GQL_DELETE_HOSPITAL_MANAGER),
    primaryKeyName: HospitalManagerPrimaryKey,
  })

  return (
   
      <EvixCard>
        <ODEntity
          resourceId={idEditing}
          api={apis}
          saveButtonName="저장"
          titleCreation="추가"
          createSuccessTitle="성공"
          createSuccessMessage="병원관리자 계정을 추가하였습니다."
          updateSuccessTitle="성공"
          updateSuccessMessage="저장하였습니다."
          defaultCreateClientData={createDefaultData(hospitalId)}
          urlAfterCreation={() => SiteUrls.Admin.Hospital.Manager.List(hospitalId)}
          urlAfterUpdate={() => SiteUrls.Admin.Hospital.Manager.List(hospitalId)}
          urlAfterDelete={() => SiteUrls.Admin.Hospital.Manager.List(hospitalId)}
          noCardWrap
          footerProps={{
            deleteButtonStyle: {
              borderColor: COLORS.DANGER,
              color: COLORS.DANGER,
              backgroundColor: COLORS.RED10,
            },
          }}
        >
          <TableListWrapper>
            <TableList name="name" label="이름">
              <ODEntityInput keyPath="name" label="이름" name="name" placeholder="이름을 입력해 주세요." inputType="text" />
            </TableList>
            <TableList name="name" label="이메일">
              <ODEntityInput
                keyPath="email"
                label="이메일"
                name="email"
                placeholder="이메일을 입력해 주세요."
                inputType="text"
              />
              <TextAreaHelpText>비밀번호는 자동생성 후 이메일로 발송됩니다.</TextAreaHelpText>
            </TableList>
          </TableListWrapper>
          <div style={{ marginTop: 30, marginBottom: 30 }}>
            <hr />
          </div>
        </ODEntity>
    
    </EvixCard>
  )
}

const TextAreaHelpText = styled.div`
  position: relative;
  padding-left: 25px;
  padding-top: 10px;
  font-size: 12px;
  line-height: 1.17;
  color: var(--gray600);

  ::before {
    content: '✳︎';
    position: absolute;
    left: 13px;
  }
`