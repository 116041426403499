import React from 'react'
import { MainContentWithInset } from '../../common/layout/MainContentWithInset'
import { MedicalSubjectListableTable } from '../../common/medicalSubject/list/MedicalSubjectListableTable'

interface IAdminMedicalSubjectPageProps {}

export const AdminMedicalSubjectListPage: React.FC<IAdminMedicalSubjectPageProps> = props => {
  return (
    <div>
      <MainContentWithInset>
        <MedicalSubjectListableTable />
      </MainContentWithInset>
    </div>
  )
}
