import { AccountType } from '@mdpp/common'
import React from 'react'
import { useLocation } from 'react-router'
import styled from 'styled-components'
import { COLORS } from '../../styles/colors'
import { Footer } from '../Footer'

export const CenterWithFooterContainer: React.FC<React.HTMLAttributes<HTMLDivElement>> = props => {
  const { children, className, style = {}, ...others } = props
  const l = useLocation()
  const accountType: AccountType = (() => {
    const comp = l.pathname.split('/')[1] ?? ''
    switch (comp) {
      case 'doctor':
        return 'Doctor'
      case 'admin':
        return 'EvixAdmin'
      case 'manager':
        return 'HospitalManager'
      case 'pharmacy':
        return 'Pharmacist' // 약국 주석 해제
      default:
        return 'EvixAdmin'
    }
  })()

  return (
    <Wrap>
      <ChildrenWrap>{children}</ChildrenWrap>
      <Footer accountType={accountType} />
    </Wrap>
    // <div
    //   className={classNames(
    //     'flex align-items-center justify-center min-vh-100 min-vw-100 position-relative',
    //     className
    //   )}
    //   style={{ backgroundColor: COLORS.WHITE, ...style }}
    //   {...others}
    // >
    //   {children}
    //   <Footer accountType={accountType} />
    // </div>
  )
}

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: ${COLORS.WHITE};
`

const ChildrenWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100% - 100px);
`
