import React from 'react'
import logo from '../assets/mydata/logo-mdpp.svg'
import icClose from '../assets/mydata/ic_close.svg'
import icAlert from '../assets/mydata/ic_alert.svg'

interface PopupProps {
  isOpen: boolean
  onClose: () => void
  onSubmit: (patientName: string) => void
  errCode?: string
}

export const Popup: React.FC<PopupProps> = props => {
  const { isOpen, onClose, onSubmit, errCode } = props

  const [patientName, setPatientName] = React.useState('')

  return (
    <div className={isOpen ? 'popup-bg' : 'popup-bg hide'}>
      <div className="popup-header">
        <div className="logo-wrap">
          <img src={logo} />
          <label>스마트 진료지원 서비스</label>
        </div>
      </div>
      <div className="popup-container">
        <div className="popup-inner">
          <h2 className="title">기록공유요청</h2>
          <div className="message">
            기록 공유요청에 응해주셔서 감사드립니다. <br />
            환자분의 건강기록은 <u>아래 질문에 답하신 후 1시간 동안 조회</u> 가능합니다.
          </div>
          <div className="input-wrap margin">
            <label className="label">기록을 공유한 환자분 성함은?</label>
            <div className="input">
              <input type="text" id="name" placeholder="환자 이름" value={patientName} onChange={e => {setPatientName(e.target.value)}} />
            </div>
          </div>
          <div className="btn-wrap">
            <button type="button" className="btn primary" onClick={e => {onSubmit(patientName)}} disabled={patientName?.length === 0}>
              확인
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
