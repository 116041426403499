import {
  ODDateTimeRenderer,
  ODEnumRenderer,
  ODIcon,
  ODIcons,
  ODListablePaginatedTable2,
  ODListableResponseType,
  ODStringRenderer,
  useODListableContext,
} from '@mdpp/od-react-belt'
import { ODEnumRendererStyle } from '@mdpp/od-belt'
import React, { CSSProperties } from 'react'
import { Link } from 'react-router-dom'
import { GQLTransactionLog } from '@mdpp/common/lib/@types/server.schema'
import { SiteUrls } from '../../../SiteUrls'
import { COLORS } from '../../../styles/colors'
import { RESERVATION_STATUS_TEXT, WebUtils } from '../../../utils/webUtils'
import { ITransactionLogListableOption, TransactionLogPrimaryKey } from '../TransactionLogCommon'

type TransactionLogListableDataLoader = (
  page: number,
  pageSize: number,
  afterKey: string | null,
  options: ITransactionLogListableOption
) => Promise<ODListableResponseType<GQLTransactionLog>>

interface ITransactionLogListableTableProps {
  dataLoader: TransactionLogListableDataLoader
}

enum RT {
  Status,
  ReservationTime,
  PatientNameHidden,
  DoctorName,
  MedicalSubject,
  PayRequestedAt,
  PayStatus,
  PaymentAmount,
  View,
}

interface IColumnInfo {
  headerName: string
  render: (value: GQLTransactionLog, context: any) => React.ReactNode
  thStyle?: CSSProperties
}

const COLUMNS_PER_STYLE = [
  RT.Status,
  RT.ReservationTime,
  RT.PatientNameHidden,
  RT.DoctorName,
  RT.MedicalSubject,
  RT.PayRequestedAt,
  RT.PayStatus,
  RT.PaymentAmount,
  RT.View,
]

export const TransactionLogListableTable: React.FC<ITransactionLogListableTableProps> = props => {
  const { dataLoader } = props
  const { Provider, Context } = useODListableContext<GQLTransactionLog, ITransactionLogListableOption>()
  const linkToDetail = SiteUrls.Manager.Reservation.Detail

  const COLUMN_INFO: { [key: number]: IColumnInfo } = {
    [RT.Status]: {
      headerName: '상태',
      render: (value, context) => (
        <td key={value.transactionId + 'status'}>
          <ODEnumRenderer
            enumRendererType={ODEnumRendererStyle.Tag}
            textMap={RESERVATION_STATUS_TEXT}
            context={context}
            value={value.reservation?.status}
            originalValue={value}
            eventKey="status"
          />
        </td>
      ),
    },
    [RT.ReservationTime]: {
      headerName: '진료일시',
      render: (value, context) => (
        <td key={value.transactionId + 'reservation_time'}>
          <ODDateTimeRenderer
            format="YYYY.MM.DD (ddd) / HH:mm"
            context={context}
            value={value.reservation?.reservationTime}
            originalValue={value}
            eventKey="reservationTime"
          />
        </td>
      ),
    },
    [RT.PatientNameHidden]: {
      headerName: '환자이름',
      render: (value, context) => (
        <td key={value.transactionId + 'patient_name'}>
          <ODStringRenderer
            context={context}
            // value={WebUtils.formatPatientNameForReview(value.reservation.patient.name ?? '-')}
            value={value.reservation?.patient.name ?? '-'}
            originalValue={value}
            eventKey="patient.name"
          />
        </td>
      ),
    },
    [RT.DoctorName]: {
      headerName: '의사이름',
      render: (value, context) => (
        <td key={value.transactionId + 'doctor_name'}>
          <ODStringRenderer
            context={context}
            value={value.reservation?.doctor.name ?? '-'}
            originalValue={value}
            eventKey="doctor.name"
          />
        </td>
      ),
    },
    [RT.MedicalSubject]: {
      headerName: '진료과목',
      render: (value, context) => (
        <td key={value.transactionId + 'ms_name'}>
          <ODStringRenderer
            context={context}
            value={value.reservation?.medicalSubject.name}
            originalValue={value}
            eventKey="medicalSubject.name"
          />
        </td>
      ),
    },
    [RT.PayRequestedAt]: {
      headerName: '결제청구일시',
      render: (value, context) => (
        <td key={value.transactionId + 'pay_requested_at'}>
          <ODDateTimeRenderer
            format="YYYY.MM.DD (ddd) / HH:mm"
            context={context}
            value={value.createdAt}
            originalValue={value}
            eventKey="payRequestedAt"
          />
        </td>
      ),
    },
    [RT.PayStatus]: {
      headerName: '결제 상태',
      render: (value, context) => {
        switch (value.status) {
          case 'paid':
            return (
              <td key={value.transactionId + 'payment_state'} style={{ color: COLORS.SECOND }}>
                결제 성공
              </td>
            )
          case 'fail':
            return (
              <td key={value.transactionId + 'payment_state'} style={{ color: COLORS.DANGER }}>
                결제 실패
              </td>
            )
          case 'refund':
            return (
              <td key={value.transactionId + 'payment_state'} style={{ color: COLORS.BLACK }}>
                결제 취소
              </td>
            )
          default:
            // 이 데이터가 나올 수 없을 것이지만, 일단 넣도록 함.
            return <td key={value.transactionId + 'payment_state'}>{value.status}</td>
        }
      },
    },
    [RT.PaymentAmount]: {
      headerName: '환자 부담금',
      render: (value, context) => (
        <td key={value.transactionId + 'payment_total'}>
          {value.reservation!.paymentTotal ? value.reservation?.paymentTotal.toLocaleString() : '-'}
        </td>
      ),
    },
    [RT.View]: {
      headerName: '조회',
      render: (value, context) => (
        <td style={{ textAlign: 'center' }} key={value.transactionId + '_view'}>
          <Link to={linkToDetail(value.reservationId)}>
            {/*조회*/}
            <ODIcon icon={ODIcons.CoreMagnifyingGlass}>조회</ODIcon>
          </Link>
        </td>
      ),
      thStyle: { textAlign: 'center' },
    },
  }

  const columns = COLUMNS_PER_STYLE

  return (
    <Provider
      dataLoader={dataLoader}
      keyExtractor={v => v[TransactionLogPrimaryKey].toString()}
      pageSize={5}
      onDataLoaderError={WebUtils.showError}
      searchOnLoad
    >
      <ODListablePaginatedTable2
        numColumns={columns.length}
        renderEmpty={() => <span>진료 정보가 없습니다.</span>}
        listableContext={Context}
        renderHeader={() => (
          <>
            <caption style={{ display: 'none' }}>
              최근 청구 내역: 상태/진료일시/환자이름/의사이름/진료과목/결제청구일시/결제상태/환자부담금/상세조회 항목
            </caption>
            <tr>
              {columns
                .map(c => {
                  return COLUMN_INFO[c]!
                })
                .map((c, index) => (
                  <th key={index} style={c.thStyle}>
                    {c.headerName}
                  </th>
                ))}
            </tr>
          </>
        )}
        renderRow={(value: GQLTransactionLog, context) => (
          <tr key={value[TransactionLogPrimaryKey]} style={{ borderBottom: '1px solid #D1D5DA' }}>
            {columns.map(c => COLUMN_INFO[c]!).map((c, index) => c.render(value, context))}
          </tr>
        )}
        onEvent={() => null}
        eventParentContext={{}}
      />
    </Provider>
  )
}
