import { ODIcon, ODIcons, useDetectOutsideClick } from '@mdpp/od-react-belt'
import React, { useState } from 'react'
import { useDropzone } from 'react-dropzone'
import styled from 'styled-components'
import { EvixFileLink } from './EvixFileLink'
import { Button, BP } from '../common/RebeccaButton'

export interface IEvixPrescriptionFile {
  id: string
  fileName: string
  link: string
  isDeleting: boolean
  isUploading: boolean
  file?: File
}

interface IEvixPrescriptionFileUploadProps {
  files: IEvixPrescriptionFile[]
  onClickDeleteFile: (file: IEvixPrescriptionFile) => void
  onAddFiles: (files: File[]) => void
  onClickDeleteUndo?: (file: IEvixPrescriptionFile) => void
  disabled: boolean
  acceptFileType?: string | string[]
}

const OuterWrapper = styled.div`
  width: 100%;
  flex-direction: row;
  display: flex;
`

const FileLinkWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 0 5px 0 5px;
`

const FileLinkRow = styled.div`
  display: flex;
  flex-direction: row;
`

const IconWrapper = styled.div`
  margin-top: 1px;
  cursor: pointer;
  margin-left: 14px;
  font-size: 14px;
  color: var(--danger);
`

const EmptyContent = styled.div`
  display: flex;
  flex-grow: 1;
  color: #9ca0a3;
`

const ButtonText = styled.span`
  font-size: 14px;
  font-weight: 500
`

export const EvixPrescriptionFileUpload: React.FC<IEvixPrescriptionFileUploadProps> = props => {
  const { files, onClickDeleteFile, onClickDeleteUndo, onAddFiles, disabled, acceptFileType } = props
  const defaultAcceptType = 'image/jpeg, application/pdf'

  const dndRef = React.useRef<HTMLDivElement>(null)
  const [onDrag, setOnDrag] = useState(false)
  const { getRootProps, getInputProps } = useDropzone({
    multiple: true,
    disabled,
    accept: acceptFileType ?? defaultAcceptType,
    onDragOver: () => {
      setOnDrag(true)
    },
    onDragLeave: () => {
      setOnDrag(false)
    },
    onDrop: (fileList: File[]) => {
      setOnDrag(false)
      if (fileList.length === 0) {
        return
      }

      onAddFiles(fileList)
    },
  })


  return (
    <OuterWrapper ref={dndRef}>
      {files.length > 0 && (
        <FileLinkWrapper>
          {files.map(f => (
            <FileLinkRow key={f.id}>
              <EvixFileLink
                name={f.fileName}
                link={f.link}
                isDelete={f.isDeleting}
              />
              {!(f.isDeleting || f.isUploading) && (
                <IconWrapper onClick={() => onClickDeleteFile(f)}>
                  {!disabled && <ODIcon icon={ODIcons.CoreTrash} />}
                </IconWrapper>
              )}
              {f.isDeleting && onClickDeleteUndo && (
                <IconWrapper onClick={() => onClickDeleteUndo(f)}>
                  {!disabled && <ODIcon icon={ODIcons.CoreReload} />}
                </IconWrapper>
              )}
              {f.isUploading && (
                <IconWrapper onClick={() => onClickDeleteFile(f)}>
                  {!disabled && <ODIcon icon={ODIcons.CoreTrash} />}
                </IconWrapper>
              )}
            </FileLinkRow>
          ))}
          <FileLinkRow style={{ marginTop: 11, marginBottom: 5 }}>
          </FileLinkRow>
        </FileLinkWrapper>
      )}
      {files.length === 0 && (
        <EmptyContent>첨부파일이 없습니다</EmptyContent>
      )}
      {!disabled && (
        <>
          <Button size={BP.SIZE.S} type={BP.TYPE.Basic} {...(getRootProps() as any)}>업로드 파일</Button>
          <input {...(getInputProps() as any)} />
        </>
      )}
    </OuterWrapper>
  )
}