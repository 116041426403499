import React from 'react'
import { Modal } from "reactstrap";
import { EvixButton, EvixButtonTheme } from '../../EvixButton'
import styled, { css } from 'styled-components'
import IconClose from '../../../assets/common/icon-close.svg'
import {Wrapper, Header, Container, Content, DescriptionContainer, SimpleDescription, ButtonWrapper, ConfirmTitleWrap, ConfirmTitle } from '../../style/ModalStyle'
import { COLORS } from '../../../styles/colors';
import IconWarningBlue from '../../../assets/common/warning-blue-super.svg'
import IconWarningBlueSmall from '../../../assets/common/warning-blue.svg'

interface WithdrawalModalProps {
  style?: object
  onOkPress: () => void
  onCancelPress: () => void
}

export const WithdrawalModal: React.FC<WithdrawalModalProps> = props => {
  const { style, onOkPress, onCancelPress } = props

  return (
    <Modal isOpen={true} toggle={() => {}} style={style}>
      <Wrapper>
        <Header>
          <img src={IconClose} onClick={onCancelPress} alt='close icon'/>
        </Header>

        <Container className='start'>
          <Content>
            <ConfirmTitleWrap className='icon-large'>
              <img src={IconWarningBlue} alt='warning bullet' />
              <ConfirmTitle>탈퇴하시기 전에 반드시 확인하세요.</ConfirmTitle>
            </ConfirmTitleWrap>

            <DescriptionContainer className='top'>
              <Badge color='red'><span>주의</span></Badge>
              <SimpleDescription>
                비대면진료 미결제 건이 남아있는 경우,<br/> 
                해당 비용의 정산이 불가합니다.    
              </SimpleDescription>
            </DescriptionContainer> 
          </Content>

          {/* 버튼 영역 */}
          <ButtonWrapper className='pdt40'>
            <EvixButton theme={EvixButtonTheme.H48WhiteInline} style={{flex: 1}} onClick={onOkPress} disabled={false}>{'탈퇴하기'}</EvixButton>
            <EvixButton theme={EvixButtonTheme.H48BlackInline} style={{flex: 1}} onClick={onCancelPress} disabled={false}>{'취소'}</EvixButton>
          </ButtonWrapper>

          {/* 공지 영역 */}
          <InfoWrap>
            <span>
              <Badge color='blue'><span>탈퇴 안내</span></Badge>
            </span>
            
            <InfoMessage>
              <span></span>
              <b>탈퇴일로부터 5일 간</b>
            </InfoMessage>
            <ul>
              <li>고객 서비스 응대를 위해 일부 정보가 보관됩니다. </li>
              <li>이 기간 내에는 탈퇴 취소가 가능합니다.</li>
              <li>단, 비대면진료 예약건은 취소 처리됩니다.</li>
            </ul>

            <InfoMessage>
              <span></span>
              <b>탈퇴일로부터 5일 경과 후</b>
            </InfoMessage>
            <ul>
              <li>재가입은 가능하며, 모든 기록은 삭제됩니다.</li>
            </ul>
          </InfoWrap>

          <WarningMessageWrap>
            <WarningIcon src={IconWarningBlueSmall} alt='warning icon' />
            <span>비대면진료 기록은 관련법령에서 정한 기준에 따라 5년간 보관됩니다.</span>
          </WarningMessageWrap>

        </Container>
      </Wrapper>
    </Modal>
  )
}

const WarningMessageWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 11px 40px 0 40px;
  gap: 4px;

  > span {
    font-size: 13px;
    font-weight: 500;
    color: ${COLORS.GRAY42};
  }
`

const WarningIcon = styled.img`
  width: 13px;
  height: 13px;
`


const InfoWrap = styled.div`
  display: flex;
  flex-direction: column;
  border: solid 1px ${COLORS.GRAYE0};
  border-radius: 4px;
  padding: 20px;
  margin: 0 40px;

  > ul {
    margin: 5px 0 0 6px;
    
    li {
      position: relative;
      font-size: 13px;
      font-weight: 500;
      line-height: 1.54;
      padding-left: 5px;
      color: ${COLORS.GRAY42};
    }
  }
`

const InfoMessage = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;
  padding-top: 15px;

  > span {
    width: 4px;
    height: 4px;
    margin: 3px 0 0 0;
    transform: rotate(-45deg);
    background-color: ${COLORS.blue200};
  }

  > b {
    font-size: 15px;
    font-weight: bold;
    color: ${COLORS.GRAY42};
    line-height: 1.4;
  }
`
interface BadgeProps {
  children: any
  color: string
}

const Badge: React.FC<BadgeProps> = props => {
  const { children, color, ...rest } = props
  return (
    <BadgeSpan color={color} {...rest}>
      {children}
    </BadgeSpan>
  );
}

const BadgeStyles = css<BadgeProps>`
  ${(props) =>
    props.color === 'red' &&
    css`
      background-color: ${COLORS.redFE};
    
      > span {
        color: ${COLORS.red100};
      }
    `}

  ${(props) =>
    props.color === 'blue' &&
    css`
      background-color: ${COLORS.blueD5};

      > span {
        color: ${COLORS.blue200};
      }
    `}
`

const BadgeSpan = styled.span`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  margin: 2px 6px 0 0;

  > span {
    padding: 3px 6px 5px 8px;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.08;
    
  }

  ${BadgeStyles}
`





