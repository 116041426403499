import React, { useEffect } from 'react'
import { ODVSpace } from '@mdpp/od-react-belt'
import { useHistory, useLocation } from 'react-router-dom'
import { EvixButton, EvixButtonTheme } from '../../common/EvixButton'
import styled from 'styled-components/macro'
import { COLORS } from '../../styles/colors'

//asset
import {
  Wrap,
  SignUpWrap,
  Header,
  PageTitleWrap,
  ProcessWrap,
  DoctorLogo,
  PageTitle,
  Step,
  StepCircle,
  StepText,
  Container,
  TitleWrap,
  Title,
  WarningMessageWrap,
} from '../../common/style/SignUpStyle'
import WarningBlue from '../../assets/common/warning-blue.svg'
import IconDoctor from '../../assets/common/icon-doctor.png'
import { EvixInput } from '../../common/EvixInput'
import { validator } from '../../validator'
import { useCommonAPIs } from '../../common/hooks/useCommonAPIs'
import { IState } from './EmailAuthentication'
import { ConfirmModal } from '../../common/layout/ConfirmModal'
import IconWarningBlue from '../../assets/common/warning-blue.svg'
import { ErrorCode } from '@mdpp/common'
import { AppOptions } from '../../AppOptions'
import { checkMessageWithPrefix } from '../../utils/registerServerErrorMessages'

export const ChangeEmail: React.FC = () => {
  const location = useLocation<IState>()
  const [email, setEmail] = React.useState('')
  const [reEmail, setReEmail] = React.useState('')
  const [errEmailMsg, setErrEmailMsg] = React.useState('')
  const [showSendEmail, setShowSendEmail] = React.useState(false)
  const [showCertificationCompleteModal, setShowCertificationCompleteModal] = React.useState(false)
  const [showJoinSuccessModal, setShowJoinSuccessModal] = React.useState(false)
  const [userData, setUserData] = React.useState(location.state)
  const { updateEmailAndSendAuthMail } = useCommonAPIs()

  // 다음 버튼 클릭 시, 이동
  const history = useHistory()

  React.useEffect(() => {
    if (userData === undefined) {
      return history.push('/')
    }
    setUserData(userData)
  }, [userData])

  React.useEffect(() => {
    let path = `/doctor/emailAuthentication`
    const unBlock = history.block((loc, action) => {
      if (action === 'POP') {
        setReEmail(reEmail)
        history.push({
          pathname: path,
          state: {
            join: {
              licenseName: userData?.join?.licenseName,
              name: userData?.join?.name,
              email: reEmail ? reEmail : userData?.join?.email,
              password: userData?.join?.password,
              cellPhone: userData?.join?.cellPhone,
              birthDate: userData?.join?.birthDate,
              phone: userData?.join?.phone,
              hospitalName: userData?.join?.hospitalName,
              hospitalAddress: userData?.join?.hospitalAddress,
              hospitalManagerEmail: userData?.join?.hospitalManagerEmail,
              recommender: userData?.join?.recommender,
              waitingDoctorId: userData?.join?.waitingDoctorId,
              businessLicenseFile: userData?.join?.businessLicenseFile,
              bankbookFile: userData?.join?.bankbookFile,
            },
          },
        })
      }
    })
    return () => unBlock()
  }, [history, email, reEmail])

  const reSentEmail = async (userData: IState, email: string) => {
    const userId = userData?.join?.waitingDoctorId

    if (email.length === 0) {
      return setErrEmailMsg('이메일을 입력해 주세요.')
    }

    try {
      const r = await updateEmailAndSendAuthMail({ id: userId, email: email })
      if (r.ok === 1) {
        setReEmail(r.email)
        setErrEmailMsg('')
        history.goBack()
      }
    } catch (err) {
      console.log(err)
      if (checkMessageWithPrefix(err, ErrorCode.EMAIL_FORMAT_INVALID)) {
        return console.log('서버 메일 형식 오류')
      }
      if (checkMessageWithPrefix(err, ErrorCode.EMAIL_ALREADY_EXIST)) {
        return setErrEmailMsg('이미 가입한 이메일 아이디입니다.')
      }
      // 회원가입 자체가 최종완료 되었을 시
      if (checkMessageWithPrefix(err, ErrorCode.ERROR_ACCOUNT_EMAIL_NOT_FOUND)) {
        setShowJoinSuccessModal(true)
      }
      // 이메일 인증을 완료했을 시
      if (checkMessageWithPrefix(err, ErrorCode.DOCTOR_ALREADY_AUTHENTICATED)) {
        setShowJoinSuccessModal(true)
      }
    }
  }

  const mainHome = () => {
    let path = `/landing`
    history.push(path)
  }

  useEffect(() => {
    setErrEmailMsg('')
  }, [email])

  return (
    <Wrap>
      <SignUpWrap>
        <Header>
          <PageTitleWrap>
            <img src={IconDoctor} style={DoctorLogo} alt="의사 아이콘" />
            <PageTitle>회원가입</PageTitle>
          </PageTitleWrap>
          <ProcessWrap>
            <Step>
              <StepCircle>
                <span>1</span>
              </StepCircle>
              <StepText>약관 동의</StepText>
            </Step>
            <Step>
              <StepCircle>
                <span>2</span>
              </StepCircle>
              <StepText>가입정보 입력</StepText>
            </Step>
            <Step className="active">
              <StepCircle>
                <span>3</span>
              </StepCircle>
              <StepText>이메일 인증</StepText>
            </Step>
            <Step>
              <StepCircle>
                <span>4</span>
              </StepCircle>
              <StepText>가입 승인 요청</StepText>
            </Step>
          </ProcessWrap>
        </Header>

        {/* 메일 전송 확인 Modal */}
        {showSendEmail && (
          <ConfirmModal
            style={{ width: 420, height: 274 }}
            isTitle={true}
            title={'인증 메일 발송'}
            titleIcon={IconWarningBlue}
            isDescriptionIcon={false}
            description={[
              '발송 후 최대 5분의 시간이 걸릴 수 있습니다.',
              <br />,
              '연속으로 인증 메일을 못 받은 경우',
              <br />,
              '이메일 아이디를 변경하거나 관리자에 문의해주세요.',
              <br />,
              '(문의 : 1566-8013)',
            ]}
            descriptionIcon={''}
            hideCancel={true}
            onOkPress={() => setShowSendEmail(false)}
            onCancelPress={() => setShowSendEmail(false)}
          />
        )}

        {/* 인증 완료 안내 Modal */}
        {showCertificationCompleteModal && (
          <ConfirmModal
            style={{ width: 420, height: 274 }}
            isTitle={true}
            title={'이메일 인증 완료'}
            titleIcon={IconWarningBlue}
            isDescriptionIcon={false}
            description={[
              '관리자의 승인 후 로그인이 가능합니다.',
              <br />,
              '승인 완료 시 입력하신 이메일로 안내될 예정입니다.',
              <br />,
              '참고 부탁드립니다. 감사합니다.',
            ]}
            descriptionIcon={''}
            hideCancel={true}
            onOkPress={mainHome}
            onCancelPress={() => setShowCertificationCompleteModal(false)}
          />
        )}

        {showJoinSuccessModal && (
          <ConfirmModal
            style={{ width: 420, height: 230 }}
            isTitle={true}
            title={'이메일 인증 완료'}
            titleIcon={IconWarningBlue}
            isDescriptionIcon={false}
            description={['이미 인증이 완료되어 이메일 아이디 변경이 불가능합니다.']}
            descriptionIcon={''}
            hideCancel={true}
            onOkPress={mainHome}
            onCancelPress={() => setShowCertificationCompleteModal(false)}
          />
        )}

        <Container>
          <InnerBox>
            <Inner>
              <TitleWrap style={{ justifyContent: 'flex-start' }}>
                <img
                  src={WarningBlue}
                  style={{ width: 23, height: 23, marginRight: 5, objectFit: 'contain' }}
                  alt="warning icon"
                />
                <Title style={{ paddingBottom: 0 }}>이메일 아이디 변경</Title>
              </TitleWrap>
              <ODVSpace h={16} />

              <EvixInput
                style={{ width: '100%' }}
                value={email}
                validators={validator.email}
                onChange={value => setEmail(value)}
                disabled={false}
              />
              <ODVSpace h={!errEmailMsg && Object.keys(validator.email(email)).length === 0 ? 34 : 0} />
              {errEmailMsg && <ErrorEmailMsg>{errEmailMsg}</ErrorEmailMsg>}
              <EvixButton
                theme={EvixButtonTheme.H48White}
                onClick={() => reSentEmail(userData, email)}
                disabled={false}
              >
                인증 메일 보내기
              </EvixButton>
            </Inner>
          </InnerBox>
        </Container>
      </SignUpWrap>
    </Wrap>
  )
}

const InnerBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 611px;
  border-radius: 6px;
  border: solid 1px ${COLORS.GRAYD5};
`

const Inner = styled.div`
  width: 340px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`
const ErrorEmailMsg = styled.span`
  display: block;
  line-height: 19px;
  font-size: 13px;
  font-weight: 500;
  padding: 5px 0 10px 0;
  min-height: 34px;
  color: ${COLORS.red100};
`
