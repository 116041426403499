import moment from 'moment-timezone'
import React from 'react'
import { Card } from 'reactstrap'
import styled from 'styled-components/macro'
import { GQLNotice } from '@mdpp/common/lib/@types/server.schema'
import { COLORS } from '../../../styles/colors'
import {EvixCard} from "../../card/EvixCard";

interface INoticeItemProps {
  notice: GQLNotice
}

export const NoticeItem: React.FC<INoticeItemProps> = props => {
  const { notice } = props

  const { title, createdAt: date, link } = notice
  // const htmlContents = (contents ?? '').replaceAll('\n', '<br/>')

  return (
    <EvixCard>
      <NoticeItemHeader>
        <NoticeItemTitle>
          <a href={link} target="_blank" rel="noopener noreferrer">
            {title}
          </a>
        </NoticeItemTitle>
        <NoticeItemDate>{moment(date).format('ll')}</NoticeItemDate>
      </NoticeItemHeader>
      <NoticeItemBody>
        {/*<div dangerouslySetInnerHTML={{ __html: htmlContents }} />*/}
        <iframe
          src={link}
          title={title}
          style={{ position: 'relative', width: '100%', minHeight: 'calc(100vh - 330px)' }}
        />
      </NoticeItemBody>
    </EvixCard>
  )
}



const NoticeItemHeader = styled.div`
  padding: 10px 10px 25px 10px;`

const NoticeItemTitle = styled.div`
  font-size: 24px;
  font-weight: 600;
  color: ${COLORS.GRAY800};
  padding-bottom: 6px;
`
const NoticeItemDate = styled.div`
  font-size: 14px;
  color: ${COLORS.GRAY400};
`
const NoticeItemBody = styled.div`
  font-size: 14px;
  letter-spacing: 0.3px;
  color: ${COLORS.GRAY600};
  border-top: 1px solid ${COLORS.GRAY300};
  padding: 25px 0 0 0; 
  min-height: 570px;
`
