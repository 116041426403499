import { IODListableEventContext, ODListableOption } from '@mdpp/od-react-belt'
import { GQLHospital } from '@mdpp/common/lib/@types/server.schema'

export const HospitalPrimaryKey = 'hospitalId'

// modifiable fields only.
export interface ICTHospital
  extends Pick<
    GQLHospital,
    'hospitalId' | 'name' | 'email' | 'address' | 'zipCode' | 'telephone' | 'isHidden' | 'profileImage'
  > {
  // more fields, mapped (if needed)
}

export interface IHospitalListableOption extends ODListableOption {
  filter: string
}

export interface IHospitalEntityContext extends IODListableEventContext<GQLHospital> {
  //
}
