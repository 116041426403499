import React from 'react'
import { AppVersionListableTable } from './AppVersionListableTable'
import { PageContainer, Page, PageHeader, PageTitle, PageContent } from '../../../common/page/Page'

interface IAdminAppVersionPageProps {}

export const AdminAppVersionListPage: React.FC<IAdminAppVersionPageProps> = props => {
  return (
    <PageContainer>
      <Page>
        <PageHeader>
          <PageTitle>앱 버전</PageTitle>
        </PageHeader>
        <PageContent>
          <AppVersionListableTable />
        </PageContent>
      </Page>
    </PageContainer>
  )
}
