import {ODIcons, ODVSpace, useODQuery} from '@mdpp/od-react-belt'
import moment from 'moment-timezone'
import React, { useEffect } from 'react'
import styled, { css } from 'styled-components'
import {
  GQLChats,
  GQLListableChatsInput,
  GQLReservation,
  GQLRESERVATION_STATUS,
} from '@mdpp/common/lib/@types/server.schema'
import {
  EvixChatMessageType,
  EvixChatMessageWrapper,
  IEvixChatMessageNormal,
} from '../../../../common/chat/EvixChatMessageWrapper'
import { GQL_GET_CHATS } from '../../../../common/gqls_common'
import { ChatModal } from './CahtModal'
import { EvixCardHeader } from '../../../../common/card/EvixCardHeader'
import { Button, BP } from '../../../../common/RebeccaButton'

interface IReservationChatCardProps {
  reservation: GQLReservation
  chatRoomId: number | null
}

const Wrapper = styled.div`
  bottom: 0;
  background-color: white;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;

  ${(p: { finishedMedical?: boolean }) =>
    p.finishedMedical &&
    css`
      position: static;
      flex: 1;
      width: auto;
      border: 0;
      box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.15);
      border-radius: 5px;
    `}

  > :not(:first-child) {
    margin-top: 10px;
  }
`

const ChatScrollArea = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  width: 100%;
  padding-right: 10px;


  > :not(:first-child) {
    margin-top: 30px;
  }
`
const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  
`

type ListChatsResponse = ODListableResponseType<GQLChats>

type ODListableResponseType<T> = {
  list: Array<T>
  totalCount: number
  page: number
  pageSize: number
}

export const ReservationChatCard: React.FC<IReservationChatCardProps> = props => {
  const { reservation, chatRoomId } = props
	const scrollTarget = React.useRef<HTMLDivElement | null>(null)
	
	const isChatAvailable = reservation?.status === GQLRESERVATION_STATUS.IN_PROGRESS || reservation?.status === GQLRESERVATION_STATUS.PRESCRIPTION || reservation?.status === GQLRESERVATION_STATUS.FINISHED
  const apiGetChats = useODQuery<GQLListableChatsInput, ListChatsResponse>(GQL_GET_CHATS)
  
  const [chats, setChats] = React.useState<GQLChats[]>([])
  const [allChats, setAllChats] = React.useState<GQLChats[]>([])
  const [showModal, setShowModal] = React.useState<boolean>(false)

    const callApiGetChats = async () => {
    if (!chatRoomId) {
      return
    }
    const result = await apiGetChats({ chatRoomId, lastChatsId: null, page: null, pageSize: null, filter: null })
    setAllChats(result.list)
    setChats(result.list)
	
    if (result.list.length > 0){
	    scrollToBottom()
    }
  }
	
	const scrollToBottom = () => {
		const node: HTMLDivElement | null = scrollTarget.current
		
		if (node) {
			node.scrollTop = node.scrollHeight
		}
	}

  useEffect(() => {
    (async () => {
      if (isChatAvailable) {
        await callApiGetChats()
      }
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatRoomId])

  return (
    <>
    <ChatModal chats={allChats} isOpen={showModal} onClose={() => (setShowModal(false))} />
    <Wrapper> 
      <HeaderWrapper>
        <EvixCardHeader title={'채팅'} icon={ODIcons.CoreChatBubble} />
      </HeaderWrapper>
      <ODVSpace h={10} />
      <ChatScrollArea ref={scrollTarget}>
        {chats.map(chat => {
          if (chat.sender === 'DOCTOR') {
            const message: IEvixChatMessageNormal = {
              messageType: EvixChatMessageType.Normal,
              isMine: true,
              name: chat.reservation.doctor.name,
              // name: 'Doctor',
              time: moment(chat.createdAt).format('YYYY.MM.DD HH:mm'),
              message: chat.message,
            }
            return <EvixChatMessageWrapper key={`${chat.chatId}`} message={message} />
          } else {
            const message: IEvixChatMessageNormal = {
              messageType: EvixChatMessageType.Normal,
              isMine: false,
              name: chat.reservation.patient.name ?? 'patient',
              time: moment(chat.createdAt).format('YYYY.MM.DD HH:mm'),
              message: chat.message,
            }
            return <EvixChatMessageWrapper key={`${chat.chatId}`} message={message} />
          }
        })}
	      {/*{chats.length >= 5 ?*/}
			  {/*    <Button type={BP.TYPE.InvisibleInteractive} onClick={() =>(setShowModal(true))} disabled={!isChatAvailable || chats.length === 0}>*/}
				{/*      전체내용 보기*/}
			  {/*    </Button> : ''*/}
	      {/*}*/}
      </ChatScrollArea>
    </Wrapper>
    </>
  )
}
