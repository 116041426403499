import { ODListableResponseType, useODQuery } from '@mdpp/od-react-belt'
import React from 'react'
import {
  GQLListableDoctorReservationInput,
  GQLListableReservation,
  GQLListableReservationInput,
  GQLReservation,
  GQLRESERVATION_STATUS,
} from '@mdpp/common/lib/@types/server.schema'
import { GQL_LIST_DOCTOR_RESERVATION, GQL_LIST_RESERVATION } from '../../gqls_common'
import { IReservationListableOption } from '../ReservationCommon'

export function useReservationListDataLoader(
  byDoctor: boolean,
  patientId?: number,
  statusFilter?: GQLRESERVATION_STATUS[],
  startDate?: string,
  endDate?: string
) {
  // @ts-ignore
  const listApi = useODQuery<Partial<GQLListableDoctorReservationInput>, GQLListableReservation>(
    GQL_LIST_DOCTOR_RESERVATION
  )

  const listApi2 = useODQuery<Partial<GQLListableReservationInput>, GQLListableReservation>(
    GQL_LIST_RESERVATION
  )

  const apiByDoctor = React.useCallback(
    async function dataLoader(
      page: number,
      pageSize: number,
      afterKey: string | null,
      options: IReservationListableOption
    ): Promise<ODListableResponseType<GQLReservation>> {
      const r = await listApi({
        page,
        pageSize,
        ...options,
        patientId,
        startDate,
        endDate,
        status: statusFilter,
      })
      return r as ODListableResponseType<GQLReservation>
    },
    [patientId, statusFilter, listApi, startDate, endDate]
  )

  const apiByManagerOrAdmin = React.useCallback(
    async function dataLoader(
      page: number,
      pageSize: number,
      afterKey: string | null,
      options: IReservationListableOption
    ): Promise<ODListableResponseType<GQLReservation>> {
      const r = await listApi2({
        page,
        pageSize,
        ...options,
        patientId,
        startDate,
        endDate,
        status: statusFilter,
      })
      return r as ODListableResponseType<GQLReservation>
    },
    [patientId, statusFilter, listApi2, startDate, endDate]
  )
  return byDoctor ? apiByDoctor : apiByManagerOrAdmin
}
