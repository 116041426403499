import { ODListableResponseType, ODModalSize, useODQuery, usePickerTableModal } from '@mdpp/od-react-belt'
import React from 'react'
import { GQLHospital, GQLListableHospital, GQLListableHospitalInput } from '@mdpp/common/lib/@types/server.schema'
import { GQL_LIST_HOSPITAL } from '../../gqls_common'
import { IHospitalListableOption } from '../../hospital/HospitalCommon'

const THeads = ['병원명', '주소', '연락처']

export function useHospitalPickerModal() {
  const listApi = useODQuery<Partial<GQLListableHospitalInput>, GQLListableHospital>(GQL_LIST_HOSPITAL)

  const dataLoader = React.useCallback(
    async function dataLoader(
      page: number,
      pageSize: number,
      afterKey: string | null,
      options: IHospitalListableOption
    ): Promise<ODListableResponseType<GQLHospital>> {
      const r = await listApi({
        page,
        pageSize,
        ...options,
      })
      return r as ODListableResponseType<GQLHospital>
    },
    [listApi]
  )

  return usePickerTableModal<GQLHospital, IHospitalListableOption>({
    size: ODModalSize.XLarge,
    rounded: false,
    pickDefaultOptions: {
      confirmButtonTitle: '저장',
      title: '병원 변경',
    },
    dataLoader,
    singleSelection: true,
    keyExtractor: v => v.hospitalId,
    numColumns: THeads.length,
    renderTH: (index: number) => THeads[index],
    renderTD: (index: number, value) => {
      switch (index) {
        case 0:
          return value.name
        case 1:
          return value.address ?? '-'
        case 2:
          return value.telephone ?? '-'
        default:
          return '-'
      }
    },
  })
}
