import styled from "styled-components/macro";
import {COLORS} from '../../styles/colors'

export const TooltipWrap = styled.div`
  .custom-color {
    max-width: 278px;
    /* max-height: 98px; */
    font-size: 13px;
    font-weight: 500;
    line-height: 1.69;
    letter-spacing: normal;
    color: ${COLORS.GRAY42};
    text-align: left;
    padding: 15px 30px 15px 20px;
    filter: drop-shadow(2px 3px 0 rgba(0, 0, 0, 0.13));
    border: solid 1px #a1a8af;
    border-radius:3px;
    visibility: visible !important;
    opacity: 1 !important;
    display: block;
    white-space: normal;
    
    &:after {
      right: -11px !important;
      border-top: 9px solid transparent !important;
      border-bottom: 9px solid transparent !important;
      border-left: 11px solid #ffffff !important;
      margin-top: -8px !important;
    }

    &:before {
      right: -12px !important;
      border-top: 10px solid transparent !important;
      border-bottom: 10px solid transparent !important;
      border-left: 12px solid #c8ced3 !important;
      margin-top: -9px !important;
    }
  }
`
