import { ODIcon, ODIcons, ODVSpace } from '@mdpp/od-react-belt'
import React from 'react'
import styled from 'styled-components'
import { COLORS } from '../styles/colors'

export interface ITabBarIcon {
  icon?: ODIcons
  text: string
}

interface IEvixTabBarProps {
  items: ITabBarIcon[]
  selectedIndex: number
  onClickTab: (index: number) => void
  isRoom?: boolean
}

interface ITabBarWrapperProps {
  selected: boolean
}

const TabBarWrapper = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  gap: 4px;
  align-items: center;
  justify-content: center;
  font-size: 22px;
  font-weight: 500;
  padding: 12px 8px 6px 3px;
  color: ${(p: ITabBarWrapperProps) => (p.selected ? COLORS.GRAY800 : COLORS.GRAY500)};
  border-bottom: ${(p: ITabBarWrapperProps) => (p.selected ? `2px solid ${COLORS.mpPrimary500}` : `2px solid transparent`)};
  :hover {
    border-bottom: 2px solid ${COLORS.GRAY300};
    color: ${COLORS.GRAY800};
  }
`

const TabBarText = styled.button`
  font-size: 15px;
  font-weight: 400;
  line-height: 1.33;
`

const EvixTabBarItem: React.FC<ITabBarIcon & { selected: boolean; onClick: () => void }> = props => {
  return (
    <TabBarWrapper selected={props.selected} onClick={props.onClick}>
      {props.icon && (
        <>
          <ODIcon icon={props.icon} />
          <ODVSpace h={7} />
        </>
      )}
      <TabBarText>{props.text}</TabBarText>
    </TabBarWrapper>
  )
}

const Wrapper = styled.div`
  gap: 32px;

  > :not(:first-child) {
    margin-left: ${(p: { room: boolean }) => (p.room ? 0 : '0px')};
  }
`

export const EvixTabBar: React.FC<IEvixTabBarProps> = props => {
  const { items, selectedIndex, onClickTab, isRoom } = props

  const isRoomWrapperStyle = isRoom ? { height: 52, boxShadow: 'none', marginLeft: 0, paddingLeft: 0 } : {}
  return (
    <Wrapper className="w-100 flex flex-row" style={isRoomWrapperStyle} room={!!isRoom}>
      {items.map((item, index) => {
        return (
          <EvixTabBarItem key={index} selected={index === selectedIndex} {...item} onClick={() => onClickTab(index)} />
        )
      })}
    </Wrapper>
  )
}
