import { ODListableResponseType } from '@mdpp/od-react-belt'
import React from 'react'
import { GQLInquiry, GQLListableInquiryInput } from '@mdpp/common/lib/@types/server.schema'
import { useCommonAPIs } from '../../hooks/useCommonAPIs'
import { IInquiryListableOption } from '../InquiryCommon'

export function useInquiryListDataLoader(defaultOptions: Partial<GQLListableInquiryInput>) {
  const { listInquiry } = useCommonAPIs()
  return React.useCallback(
    async function dataLoader(
      page: number,
      pageSize: number,
      afterKey: string | null,
      options: IInquiryListableOption
    ): Promise<ODListableResponseType<GQLInquiry>> {
      // @ts-ignore
      const r = await listInquiry({
        page,
        pageSize,
        ...options,
        ...defaultOptions,
      })
      return r as ODListableResponseType<GQLInquiry>
    },
    [listInquiry, defaultOptions]
  )
}
