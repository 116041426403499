import styled from 'styled-components'
import { COLORS } from '../../../styles/colors'

export const RoomSectionDivider = styled.div`
  height: 1px;
  background-color: #E4E5E7;
`

export enum VIDEO_TAG_ID {
  Doctor = 'video_doctor',
  Patient = 'video_patient',
  Screen = 'video_screen',
}

export enum ROOM_TYPE {
  INIT,
  READY,
  START_TREATMENT,
  WAITING_PATIENT,
  DOCTOR_EXIT,
  PATIENT_EXIT,
}