import { ODModalSize, ODVSpace } from '@mdpp/od-react-belt'
import React from 'react'
import styled from 'styled-components/macro'
import { BP, Button } from '../RebeccaButton'
import { Modal } from "reactstrap";
import { ODHSpace } from "@mdpp/od-react-belt";
import icon from '../../assets/pending_icon.svg'
import closeImage from '../../assets/icCloseBlack.png'

interface PopupModalProps {
  title: string
  description: any
  warning?: any
  hideCancel?: boolean
  okTitle?: string
  cancelTitle?: string
  onOkPress: () => void
  onCancelPress: () => void
}

export const PopupModal: React.FC<PopupModalProps> = props => {
  const { title, description, warning, hideCancel, okTitle, cancelTitle, onOkPress, onCancelPress } = props

  return (
    <Modal isOpen={true} toggle={() => {}} size={ODModalSize.Normal}>
      <Wrapper>
        <TitleBar>
          <Title>{title}</Title>
          <CloseImage src={closeImage} onClick={onCancelPress}/>
        </TitleBar>


        {!warning && (
          <>
            <DescriptionContainer>
              <SimpleDescription>{description}</SimpleDescription>
            </DescriptionContainer>
          </>

        )}

        {warning && (
          <>
            <DescriptionContainer>
              <Description>{description}</Description>
              <ODVSpace h={20} />
              <WarningContainer>
                <img src={icon}/>
                <ODHSpace w={16} />
                {warning}
              </WarningContainer>
            </DescriptionContainer>
          </>
        )}

        <ButtonWrapper>
          <Button type={BP.TYPE.Primary} size={BP.SIZE.M} onClick={onOkPress}>{okTitle ? okTitle : '확인'}</Button>
          {!hideCancel && (
            <>
              <ODHSpace w={8} />
              <Button type={BP.TYPE.Basic} size={BP.SIZE.M}
                      onClick={onCancelPress}>{cancelTitle ? cancelTitle : '취소'}</Button>
            </>
          )}
        </ButtonWrapper>
      </Wrapper>


    </Modal>
  )
}

const Wrapper = styled.div`
  background-color: var(--white);
  position: relative;
  border-radius: 8px;
`
const TitleBar = styled.div`
  display: flex;
  flex-direction: row;
  padding: 12px 16px;
  height: 66px;
  align-items: center;
`
const Title = styled.div`
  font-size: 16px;
  color: #000000;
  font-weight: 500;
  display: flex;
  flex-grow: 1;
`
const Description = styled.div`
  font-size: 14px;
  color: #000000;
  font-weight: 500;
`
const SimpleDescription = styled.div`
  font-size: 16px;
  color: #000000;
  font-weight: 400;
`
const WarningContainer = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 16px;
  background-color: #f6f6f7;
  border: #babfc3 1px solid;
  border-radius: 8px;
  font-size: 14px;
  padding: 20px;
`
const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row-reverse;
  padding: 16px;
  height: 68px;
`
const DescriptionContainer = styled.div`
  border-top-width: 1px;
  border-bottom-width: 1px;
  border-color: #e1e3d5;
  padding: 20px;
`
const CloseImage = styled.img`
  width: 19.2px;
  height: 19.2px;
  margin-right: 7.4px;
`