import { BlockingLoadBox, ODWebUtils, useSingleQueryAPI, useWrappedAPI } from '@mdpp/od-react-belt'
import React from 'react'
import { DoctorProfileHospitalCommon } from '../../../common/doctor/detail/DoctorProfileHospitalCommon'
import { useHospitalPickerModal } from '../../../common/doctor/detail/useHospitalPickerModal'
import { useCommonAPIs } from '../../../common/hooks/useCommonAPIs'
import { useAdminAPIs } from '../../context/useAdminAPIs'

interface IAdminDoctorProfileHospitalProps {
  doctorId: number
}

export const AdminDoctorProfileHospital: React.FC<IAdminDoctorProfileHospitalProps> = props => {
  const { doctorId } = props
  const { getDoctor } = useCommonAPIs()
  const { changeDoctorHospital } = useAdminAPIs()
  const [loading2, setLoading] = React.useState(false)

  const { data, loading, reload } = useSingleQueryAPI(() => getDoctor({ id: doctorId }), [doctorId])
  const { Component: PickerComponent, props: pickerProps, pick } = useHospitalPickerModal()
  const changeHospital = useWrappedAPI(
    async (hospitalId: number) => {
      return changeDoctorHospital({ id: doctorId, hospitalId })
    },
    loading2,
    setLoading,
    {
      onSuccess: async () => {
        ODWebUtils.showSuccess('병원 정보가 변경되었습니다.')
        return reload()
      },
      deps: [doctorId],
    }
  )

  if (loading) {
    return <BlockingLoadBox show />
  }

  const handleChangeHospital = async () => {
    const ret = await pick({
      showSearch: true,
      searchPlaceholder: '병원명,주소,연락처로 검색',
      confirmButtonTitle: '변경',
      title: '병원 변경',
    })

    if (!ret) {
      return
    }

    const [hospitalId] = ret
    return changeHospital(hospitalId as number)
  }

  return (
    <>
      {(loading || loading2) && <BlockingLoadBox show />}
      <PickerComponent {...pickerProps} />
      <DoctorProfileHospitalCommon name={data?.hospital.name ?? '-'} showChange onChange={handleChangeHospital} />
    </>
  )
}
