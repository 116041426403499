import { ODEntity, ODVSpace } from '@mdpp/od-react-belt'
import gql from 'graphql-tag'
import React from 'react'
import styled from 'styled-components'
import { GQLDoctor, GQLDoctorCreationInput, GQLDoctorUpdateInput } from '@mdpp/common/lib/@types/server.schema'
import { DoctorProfileEdit } from '../../../common/doctor/detail/DoctorProfileEdit'
import { GQL_READ_DOCTOR, GQL_UPDATE_DOCTOR } from '../../../common/gqls_common'
import { DoctorPrimaryKey } from '../../../common/types/DoctorCommon'
import { useODEntityAPI } from '../../../utils/odGQLEntityLoader'
import {EvixCard} from "../../../common/card/EvixCard";
import { useCommonAPIs } from "../../../common/hooks/useCommonAPIs";
import { WebUtils } from "../../../utils/webUtils";
import { doctorDiffFuction } from "./DoctorEditValidator";
import { ICTDoctor } from "../../../common/doctor/DoctorCommon";

interface IAdminDoctorProfilePageProps {
  doctorId: number
  fromManager?: boolean
  fromAdmin?:boolean
}

const DEFAULT_DOCTOR_DATA: Partial<ICTDoctor> = {
  doctorId: 0,
  name: ``,
}

export const AdminDoctorProfilePage: React.FC<IAdminDoctorProfilePageProps> = props => {
  const { doctorId, fromManager = false, fromAdmin = true } = props
  const apis = useODEntityAPI<GQLDoctor, ICTDoctor, GQLDoctorCreationInput, GQLDoctorUpdateInput>({
    readGQL: gql(GQL_READ_DOCTOR),
    updateGQL: gql(GQL_UPDATE_DOCTOR),
    primaryKeyName: DoctorPrimaryKey,
  })

  const { checkLicense } = useCommonAPIs()

  const diffFuction = async (updated: ICTDoctor, original: ICTDoctor) => {
    let ret: Partial<ICTDoctor> | null = doctorDiffFuction(updated, original)

    if ( !ret ) {
      return null
    }

    if (updated.licenseName !== original.licenseName) {
      try {
        const r = await checkLicense({ licenseName: ret.licenseName || '' })
        if (r.ok !== 1) { // 이미 등록된 면허
          WebUtils.showError('이미 등록된 면허번호입니다.')
          return null
        }
      } catch {
        return null
      }
    }

    return ret
  }


  return (
  
    <EvixCard>
      <Wrapper>
        <ODEntity
          resourceId={doctorId}
          api={apis}
          saveButtonName="저장"
          titleCreation="추가"
          createSuccessTitle="성공"
          createSuccessMessage="추가하였습니다."
          titleUpdate="수정"
          updateSuccessTitle="성공"
          updateSuccessMessage="수정하였습니다."
          urlAfterDelete={(item: ICTDoctor) => `/admin/doctor`}
          deleteSuccessTitle="성공"
          deleteSuccessMessage="삭제하였습니다."
          defaultCreateClientData={DEFAULT_DOCTOR_DATA}
          urlAfterCreation={() => ``}
          urlAfterUpdate={() => null}
          noCardWrap
          diffClientValue={diffFuction}
        >
          <DoctorProfileEdit fromManager={fromManager} fromAdmin={fromAdmin} />
          <ODVSpace h={24} /> 
        </ODEntity>
      </Wrapper>
    </EvixCard>
  )
}

const Wrapper = styled.div`
  padding: 10px;
`
