import { STAT_SUM_BY_TYPE as BY_DATE } from '@mdpp/common'
import React, { FC } from 'react'
import { Bar } from 'react-chartjs-2'
import Select from 'react-select'
import styled from 'styled-components'
import { GQLReservationChartDataResponse } from '@mdpp/common/lib/@types/server.schema'
import { colourStyles } from '../../doctor/reservation/ReservationStatusSelector'
import {EvixCard} from "../../common/card/EvixCard";
import {EvixCardHeader} from "../../common/card/EvixCardHeader";
import {ODVSpace} from "@mdpp/od-react-belt";

export interface IReservationChartData {
  date: string // x-axis label 에 해당
  numReservation: number
  numFinished: number
  numNoShow: number
  numCancelled: number
}

export interface IManagerRecentReservationChartState {
  byDate: BY_DATE
  data: GQLReservationChartDataResponse
}

interface IManagerRecentReservationChart {
  value: IManagerRecentReservationChartState
  onChange: (byDate: BY_DATE) => void
}

const BY_DATE_TEXT = {
  [BY_DATE.DAY]: '일별',
  [BY_DATE.WEEK]: '주별',
  [BY_DATE.MONTH]: '월별',
}

const BAR_CHART_OPTIONS = {
  scales: {
    yAxes: [
      {
        stacked: true,
        ticks: {
          beginAtZero: true,
        },
      },
    ],
    xAxes: [
      {
        stacked: true,
      },
    ],
  },
  tooltips: {
    mode: 'index',
    axis: 'y',
  },
  responsive: true,
  animation: false,
}

const BY_DATE_SELECT_OPTIONS = [
  { value: BY_DATE.DAY, label: BY_DATE_TEXT[BY_DATE.DAY] },
  { value: BY_DATE.WEEK, label: BY_DATE_TEXT[BY_DATE.WEEK] },
  { value: BY_DATE.MONTH, label: BY_DATE_TEXT[BY_DATE.MONTH] },
]

export const ManagerRecentReservationChart: FC<IManagerRecentReservationChart> = props => {
  const { value, onChange } = props

  const data = {
    labels: value.data.chartData.map(item => item.label),
    datasets: [
      {
        label: '예약취소',
        data: value.data.chartData.map(item => item.numCancelled),
        backgroundColor: 'rgb(255, 99, 132)',
      },
      {
        label: '예약부도',
        data: value.data.chartData.map(item => item.numNoShow),
        backgroundColor: 'rgb(54, 162, 235)',
      },
      {
        label: '진료완료',
        data: value.data.chartData.map(item => item.numFinished),
        backgroundColor: 'rgb(75, 192, 192)',
      },
      {
        label: '진료예약',
        data: value.data.chartData.map(item => item.numReservation),
        backgroundColor: 'rgb(125, 125, 125)',
      },
    ],
  }

  return (
    <EvixCard style={cardStyle}>
      <CardHeaderWrapper>
        <EvixCardHeader title="최근 진료예약 현황" />
        <Select
            name="byDate"
            options={BY_DATE_SELECT_OPTIONS}
            value={BY_DATE_SELECT_OPTIONS.find(v => v.value === value.byDate)}
            onChange={(e: any) => {
              onChange(e.value)
            }}
            styles={colourStyles}
        />
      </CardHeaderWrapper>
      <ODVSpace h={24} />
      <ChartWrapper>
        <Bar data={data} options={BAR_CHART_OPTIONS} height={120} />
      </ChartWrapper>
    </EvixCard>
  )
}
const cardStyle = {
  display: 'flex',
  minWidth: 700,
  background: '#FFFFFF',
  boxShadow: '0px 0px 5px rgba(23, 24, 24, 0.05), 0px 1px 2px rgba(0, 0, 0, 0.15)',
  borderRadius: 8,
  border: 0,
}

const ChartWrapper = styled.div`
  // flex-grow: 9;
  // max-width: 800px;
  width: 100%;
`

const CardHeaderWrapper = styled.div`
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`
