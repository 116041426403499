import React from 'react'
import { AdminDoctorProfilePage } from '../../../admin/doctor/detail/AdminDoctorProfilePage'

interface IManagerDoctorProfilePageProps {
  doctorId: number
}

export const ManagerDoctorProfilePage: React.FC<IManagerDoctorProfilePageProps> = props => {
  const { doctorId } = props
  return <AdminDoctorProfilePage doctorId={doctorId} fromManager />
}
