////////////////////////////////////////

import * as Sentry from '@sentry/react'
import {Integrations} from '@sentry/tracing'

////////////////////////////////////////

const init = () => {
  Sentry.init({
    dsn: "https://8d14a9d67e3c45f480097cc91a855a70@o1063228.ingest.sentry.io/6056058",
    integrations: [new Integrations.BrowserTracing()],
    environment: process.env.NODE_ENV,
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  })
}

const setUser = (id: string) => {
  Sentry.setUser({id})
  // Sentry.setUser({
  //   id: "",
  //   email: "",
  //   username: "",
  // })
}

const sendMessage = (message: string) => {
  Sentry.captureMessage(message)
}

const sendException = (exception: any) => {
  Sentry.captureException(exception)
}

////////////////////////////////////////

export default {
  init, setUser,
  sendMessage, sendException
}
