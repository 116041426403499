import { ODListableResponseType, useODQuery } from '@mdpp/od-react-belt'
import React from 'react'
import {
  GQLListableTransactionLog,
  GQLListableTransactionLogInput,
  GQLTransactionLog,
} from '@mdpp/common/lib/@types/server.schema'
import { GQL_LIST_TRANSACTION_LOG } from '../../gqls_common'
import { ITransactionLogListableOption } from '../TransactionLogCommon'

export function useTransactionLogListDataLoader() {
  const listApi = useODQuery<Partial<GQLListableTransactionLogInput>, GQLListableTransactionLog>(
    GQL_LIST_TRANSACTION_LOG
  )

  return React.useCallback(
    async function dataLoader(
      page: number,
      pageSize: number,
      afterKey: string | null,
      options: ITransactionLogListableOption
    ): Promise<ODListableResponseType<GQLTransactionLog>> {
      const r = await listApi({
        page,
        pageSize,
        ...options,
      })
      return r as ODListableResponseType<GQLTransactionLog>
    },
    [listApi]
  )
}
