import { ODEntityInput, ODVSpace } from '@mdpp/od-react-belt'
import React, { useEffect } from 'react'
import styled from 'styled-components'
import { TableList } from '../../list/TableList'
import { GQLDoctor, GQLWaitingDoctor } from '@mdpp/common/lib/@types/server.schema'
import { FileLabel, FileInput, WarningMessageWrap } from '../../style/SignUpStyle'
import { MessageIcon } from '../../style/CommonStyle'
import { COLORS } from '../../../styles/colors'
import IconWarningBlue from '../../../assets/common/warning-blue.svg'
import { WebUtils } from '../../../utils/webUtils'
import { saveAs } from 'file-saver'
import { useCommonAPIs } from '../../hooks/useCommonAPIs'

interface IDoctorProfileEditProps {
  doctorId?: number
  fromManager: boolean
  fromAdmin: boolean
  profile?: GQLWaitingDoctor | null
}

export const WaitingDoctorProfileEdit: React.FC<IDoctorProfileEditProps> = props => {
  const { doctorId, fromManager, fromAdmin, profile } = props
  const { getWaitingDoctor, updateWaitDoctor } = useCommonAPIs()
  const [fileName, setFileName] = React.useState('')
  const [fileLink, setFileLink] = React.useState('')
  const [bankBook, setBankBook] = React.useState('')
  const [bankBookFileLink, setBankBookFileLink] = React.useState('')
  const [hospitalName, setHospitalName] = React.useState('')
  const [hospitalManagerEmail, setHospitalManagerEmail] = React.useState<string | null>('')

  useEffect(() => {
    getWaitingDoctorData()
  }, [doctorId])

  const getWaitingDoctorData = async () => {
    try {
      const r = await getWaitingDoctor({ id: doctorId! })
      setHospitalName(r.hospitalName)
      setHospitalManagerEmail(r.hospitalManagerEmail)
      setFileName(r.businessLicenseFile?.fileName!)
      setFileLink(r.businessLicenseFile?.link!)
      setBankBook(r.bankbookFile?.fileName!)
      setBankBookFileLink(r.bankbookFile?.link!)
    } catch (e) {
      console.log(e)
    }
  }

  const handleFileAdd = async (files: any, bankFiles: any) => {
    const postData: any = { id: doctorId! }
    if (files !== null) {
      postData!['businessLicenseFile'] = files[0]
    }
    if (bankFiles !== null) {
      postData!['bankbookFile'] = bankFiles[0]
    }

    try {
      const fileUploaded = await updateWaitDoctor(postData)
      setFileName(fileUploaded.businessLicenseFile?.fileName!)
      setFileLink(fileUploaded!.businessLicenseFile!.link)
      setBankBook(fileUploaded.bankbookFile?.fileName!)
      setBankBookFileLink(fileUploaded!.bankbookFile!.link)
      WebUtils.showSuccess('파일이 변경되었습니다.')
    } catch (e) {
      console.log(e)
    }
  }

  const fileChangedHandler = async (e: any) => {
    const files = e.target.files
    const fileSize = files[0]?.size
    const fileChangeName = files[0]?.name

    if (files[0]?.name === null) {
      return console.log('파일없음')
    }
    if (fileSize > 50000000) {
      return WebUtils.showError('첨부파일 사이즈는 50MB 이내로 등록 가능합니다.')
    }

    setFileName(fileChangeName ? fileChangeName : fileName)
    setFileLink(files[0] ? files[0] : fileLink)
    await handleFileAdd(files, null)
  }

  const bankBookFileChangedHandler = async (e: any) => {
    const bankFiles = e.target.files
    const fileSize = bankFiles[0]?.size
    const fileChangeName = bankFiles[0]?.name

    if (bankFiles[0]?.name === null) {
      return console.log('파일없음')
    }
    if (fileSize > 50000000) {
      return WebUtils.showError('첨부파일 사이즈는 50MB 이내로 등록 가능합니다.')
    }
    setBankBook(fileChangeName ? fileChangeName : bankBook)
    setBankBookFileLink(bankFiles[0] ? bankFiles[0] : bankBookFileLink)
    await handleFileAdd(null, bankFiles)
  }

  const onDownloadBtn = (fileLink: string, hospitalName: string) => {
    saveAs(fileLink, `${hospitalName}_파일`)
    return WebUtils.showSuccess('파일이 다운로드 되었습니다.')
  }

  return (
    <>
      <Wrapper>
        <TableList name="profileImage" label="프로필" mandatoryInput>
          <ProfileImageRow className="profile-uploader">
            <DoctorAddInfoWrap>
              <DoctorAddInfo>
                <DoctorAddInfoBlock className="block email">
                  <div className="label">이메일 아이디</div>
                  {fromAdmin ? (
                    <ODEntityInput keyPath="email" label="" placeholder="" name="email" inputType="text" readOnly />
                  ) : (
                    <div className="value">{profile?.email}</div>
                  )}
                </DoctorAddInfoBlock>

                <DoctorAddInfoBlock className="block email">
                  <div className="label">병원명</div>
                  {fromAdmin ? (
                    <ODEntityInput
                      keyPath="hospitalName"
                      label=""
                      placeholder=""
                      name="hospitalName"
                      inputType="text"
                      readOnly
                    />
                  ) : (
                    <div className="value">{profile?.hospitalName}</div>
                  )}
                </DoctorAddInfoBlock>

                <DoctorAddInfoBlock className="block">
                  <div className="label">생년월일</div>
                  {fromAdmin ? (
                    <ODEntityInput keyPath="birthDate" label="" placeholder="" name="birthDate" inputType="text" />
                  ) : (
                    <div className="value">{profile?.birthDate}</div>
                  )}
                </DoctorAddInfoBlock>
                <DoctorAddInfoBlock className="block">
                  <div className="label">의사면허번호</div>
                  {fromAdmin ? (
                    <ODEntityInput keyPath="licenseName" label="" placeholder="" name="licenseName" inputType="text" />
                  ) : (
                    <div className="value">{profile?.licenseName}</div>
                  )}
                </DoctorAddInfoBlock>
              </DoctorAddInfo>
            </DoctorAddInfoWrap>
          </ProfileImageRow>
        </TableList>

        {!fromManager && (
          <TableList name="name" label="이름(한글)" mandatoryInput>
            <ODEntityInput keyPath="name" label="" name="name" placeholder="이름을 입력해 주세요." inputType="text" />
          </TableList>
        )}

        <TableList name="cellPhone" label="휴대전화번호">
          <ODEntityInput
            keyPath="cellPhone"
            label=""
            name="cellPhone"
            placeholder="휴대전화번호를 입력해 주세요."
            inputType="text"
          />
        </TableList>

        {hospitalManagerEmail && (
          <>
            <TableList name="fileName" label="사업자등록증" labelMDSize={12} mandatoryInput>
              <FileWrap>
                <FileInput onClick={() => onDownloadBtn(fileLink, hospitalName)}>
                  {fileName ? (
                    fileName
                  ) : (
                    <>
                      <span className="placeholer">PDF, JPG, PNG 파일만 첨부 가능</span>
                    </>
                  )}
                </FileInput>
                <FileLabel htmlFor="input-file">
                  {' '}
                  사업자등록증 첨부
                  <input
                    name="businessLicenseFile"
                    type="file"
                    id="input-file"
                    style={{ display: 'none' }}
                    accept=".pdf, image/png, image/jpeg, image/jpg"
                    onChange={e => fileChangedHandler(e)}
                  />
                </FileLabel>
              </FileWrap>
              <WarningMessageWrap>
                <ul>
                  <li className="align-center">
                    <MessageIcon src={IconWarningBlue} alt="warning icon" />
                    비대면 진료 정산을 위해 필요합니다.
                  </li>
                </ul>
              </WarningMessageWrap>
            </TableList>

            <TableList name="bankBook" label="통장사본" labelMDSize={12} mandatoryInput>
              <FileWrap>
                <FileInput onClick={() => onDownloadBtn(bankBookFileLink, hospitalName)}>
                  {bankBook ? (
                    bankBook
                  ) : (
                    <>
                      <span className="placeholer">PDF, JPG, PNG 파일만 첨부 가능</span>
                    </>
                  )}
                </FileInput>
                <FileLabel htmlFor="input-bank-file">
                  {' '}
                  통장사본 첨부
                  <input
                    name="bankBookFile"
                    type="file"
                    id="input-bank-file"
                    style={{ display: 'none' }}
                    accept=".pdf, image/png, image/jpeg, image/jpg"
                    onChange={e => bankBookFileChangedHandler(e)}
                  />
                </FileLabel>
              </FileWrap>
              <WarningMessageWrap>
                <ul>
                  {/* <li>
                  <OvalGray></OvalGray> PDF, JPG, PNG 파일만 첨부 가능합니다. */}
                  <li className="align-center">
                    <MessageIcon src={IconWarningBlue} alt="warning icon" />
                    비대면 진료 정산을 위해 필요합니다.
                  </li>
                </ul>
              </WarningMessageWrap>
            </TableList>
          </>
        )}

        <ODVSpace h={18} />
      </Wrapper>
    </>
  )
}

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 32px;
`

const ProfileImageRow = styled.div`
  display: flex;
  flex-direction: column;
`

const DoctorAddInfoWrap = styled.div`
  display: flex;
  flex-direction: row;
  gap: 38px;
`
const DoctorAddInfo = styled.p`
  /* flex: 1; */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-radius: 0 !important;
  width: 920px;
  padding: 18px 50px 18px 30px;
  background-color: #f4f6f8;
  gap: 50px;
`

const DoctorAddInfoBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;

  &.email {
    width: 318px;

    input {
      width: 100%;
      border-color: #f4f6f8 !important;
      background-color: #f4f6f8 !important;
      outline: none !important;
      padding-left: 0 !important;
      padding-right: 0 !important;

      &:focus {
        border-color: #f4f6f8 !important;
        outline: none !important;
      }
    }
  }

  .label {
    font-size: 12px;
    font-weight: 500;
    color: #909ca7;
  }

  .label ~ div,
  .label ~ div > div {
    border-radius: 1px !important;
  }

  .value {
    font-size: 15px;
    font-weight: 500;
    color: #424242;
  }

  input {
    width: 120px;
    height: 35px;
    font-size: 15px;
    font-weight: 500;
    color: #424242;
    display: inline-block !important;
    border-radius: 1px !important;
    background-color: #fff !important;
  }
`

const FileWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  gap: 10px;

  .od-input-wrapper {
    border: none;

    .od-input {
      &::placeholder {
        color: ${COLORS.GRAYAB};
        font-size: 13px;
      }
    }
  }

  [class*='SignUpStyle__WarningMessageWrap'] {
    display: none;
  }
`
