import { ODIcons, ODVSpace } from '@mdpp/od-react-belt'
import React from 'react'
import { NumberParam, useQueryParam, withDefault } from 'use-query-params'
import { EvixTabBar, ITabBarIcon } from '../../../common/EvixTabBar'
import styled from 'styled-components/macro'
import { Page, PageContainer, PageHeader, PageTitle } from '../../../common/page/Page'
import { AdminWaitingPhramacyProfilePage } from './AdminWaitingPhramacyProfilePage'

interface IAdminPharmacyDetailPageContainerProps {
  waitingPharmacistId: number
}

const Items: ITabBarIcon[] = [
  {
    icon: ODIcons.Doctor,
    text: '약국정보',
  },
]

export const AdminWaitingPharmaDetailPageContainer: React.FC<IAdminPharmacyDetailPageContainerProps> = props => {
  const { waitingPharmacistId } = props
  const [tabRaw, setTab] = useQueryParam('tab', withDefault(NumberParam, 0))
  const tab = Math.min(Math.max(tabRaw, 0), Items.length - 1)

  return (
    <PageContainer>
      <Page>
        <PageHeader>
          <PageTitle>가입승인 대기 약국</PageTitle>
        </PageHeader>
        {/*<EvixTabBar items={Items} selectedIndex={tab} onClickTab={setTab} />*/}
        {/*<ODVSpace h={18} />*/}
        <PageContent>
          {tab === 0 && <AdminWaitingPhramacyProfilePage waitingPharmacistId={waitingPharmacistId} />}
        </PageContent>
      </Page>
    </PageContainer>
  )
}

export const PageContent = styled.div`
  width: 100%;
  padding-bottom: 48px;
  display: flex;
  flex-direction: column;
  /* gap: 24px; */
`
