import React from 'react'
import { BlockingLoadBox, ODIcon, ODIcons, ODVLine, ODVSpace } from '@mdpp/od-react-belt'
import { Redirect } from 'react-router-dom'
import { EvixButton, EvixButtonTheme } from '../common/EvixButton'
import { CenterWithFooterContainer } from '../common/layout/CenterWithFooterContainer'
import { SiteUrls } from '../SiteUrls'
import {
  EvixInputTextWrap,
  InfoMessageWrap,
  LoginInner,
  LoginTitle,
  LoginWrap,
  Logo,
  Logo2,
  LogoStyle,
  MarketingLine,
  MessageText1,
  MessageText2,
  MessageTextWrap,
} from '../common/style/CommonStyle'
import LogoDoctor from '../assets/login/bi-mdpp-doctor.png'
import LogoHospital from '../assets/login/bi-mdpp-hospital.png'
import LogoAdmin from '../assets/login/bi-mdpp-admin.png'
import { EvixInputText } from '../common/EvixInputText'
import IconResult from '../assets/common/icon-result.svg'
import WarningBlue from '../assets/common/warning-blue.svg'
import LogoPharmacy from '../assets/login/bi-mdpp-pharma.png'

interface IForgotPasswordPageProps {
  email: string
  accountType: string
}

export const PasswordResetConfirmedPage: React.FC<IForgotPasswordPageProps> = props => {
  const { email, accountType } = props
  const [loading, setLoading] = React.useState(false)
  const [redirect, setRedirect] = React.useState('')

  const LoginPage = async () => {
    setLoading(true)
    try {
      switch (accountType) {
        case 'doctor':
          // @ts-ignore
          await setRedirect(SiteUrls.Doctor.Login)
          break
        case 'manager':
          // @ts-ignore
          await setRedirect(SiteUrls.Manager.Login)
          break
        case 'admin':
          // @ts-ignore
          await setRedirect(SiteUrls.Admin.Login)
          break
        case 'pharmacist':
          // @ts-ignore
          await setRedirect(SiteUrls.Pharmacy.Login)
          break
      }
    } catch (ex) {
      console.log(ex)
    } finally {
      setLoading(false)
    }
  }

  if (redirect) {
    return <Redirect to={redirect} />
  }

  return (
    <CenterWithFooterContainer style={{ display: 'flex', flexDirection: 'column' }}>
      <BlockingLoadBox show={loading} />

      <LoginWrap>
        <LogoStyle>
          {/* 의사일 경우 */}
          {accountType === 'doctor' && <img src={LogoDoctor} style={Logo} alt="MediPangPang Logo" />}

          {/* 병원 관리자일 경우 */}
          {accountType === 'manager' && <img src={LogoHospital} style={Logo} alt="MediPangPang Logo" />}

          {/* 관리자일 경우 */}
          {accountType === 'admin' && <img src={LogoAdmin} style={Logo2} alt="MediPangPang Logo" />}

          {/* 약국일 경우 */}
          {accountType === 'pharmacist' && <img src={LogoPharmacy} style={Logo} alt="MediPangPang Logo" />}
        </LogoStyle>

        {/* 박스 영역 */}
        <LoginInner>
          <LoginTitle>비밀번호 찾기</LoginTitle>
          <MarketingLine>
            이메일 아이디의 주소로,
            <br />
            비밀번호 재설정 안내 메일이 발송됩니다.
          </MarketingLine>
          <ODVSpace h={27} />

          <EvixInputTextWrap className="result-input">
            <img
              src={IconResult}
              style={{
                position: 'absolute',
                left: 12,
                top: 17,
                width: 20,
                height: 14,
                marginRight: 6,
                objectFit: 'contain',
              }}
              alt="reault icon"
            />
            <EvixInputText children value={email} />
          </EvixInputTextWrap>
          <ODVSpace h={14} />
          <InfoMessageWrap>
            <img
              src={WarningBlue}
              style={{ width: 19, height: 19, marginRight: 5, marginTop: 2, objectFit: 'contain' }}
              alt="warning icon"
            />
            <MessageTextWrap>
              <MessageText1>비밀번호가 초기화 되었습니다. </MessageText1>
              <MessageText2>발송된 이메일 주소에서 확인해주세요.</MessageText2>
            </MessageTextWrap>
          </InfoMessageWrap>

          <EvixButton theme={EvixButtonTheme.H48White} onClick={LoginPage}>
            로그인 바로가기
          </EvixButton>
        </LoginInner>
      </LoginWrap>
    </CenterWithFooterContainer>
  )
}
