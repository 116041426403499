import classNames from 'classnames'
import React from 'react'
import { COLORS } from '../../styles/colors'
import { FooterForLanding } from '../FooterForLanding'


export const CenterWithFooterContainerForLanding: React.FC<React.HTMLAttributes<HTMLDivElement>> = props => {
	const { children, className, style = {}, ...others } = props

	return (
		<div
			className={classNames(
				'flex  justify-center min-vh-100 min-vw-100 position-relative',
				className
			)}
			style={{ backgroundColor: COLORS.WHITE, ...style }}
			{...others}
		>
			{children}
			<FooterForLanding />
		</div>
	)
}