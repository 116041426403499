import { ODIcons, ODVSpace } from '@mdpp/od-react-belt'
import React from 'react'
import styled from 'styled-components'
import { GQLReservation } from '@mdpp/common/lib/@types/server.schema'
import { SiteUrls } from '../../SiteUrls'
import { TitleWithViewAll } from './TitleWithViewAll'
import { UpcomingReservationCard } from './UpcomingReservationCard'
import { ODHSpace } from "@mdpp/od-react-belt"

interface IUpcomingReservationProps {
  reservations: GQLReservation[]
}

export const UpcomingReservation: React.FC<IUpcomingReservationProps> = props => {
  const { reservations } = props
  if (reservations.length === 0) {
    return null
  }

  return (
    <Wrapper>
      <TitleWithViewAll icon={ODIcons.Patient} title="다가오는 진료" link={SiteUrls.Doctor.Reservation.Main} />
      <ODVSpace h={16} />
      <CardsWrapper>
        {reservations.map( (reservation, index) => (
          <>
            <UpcomingReservationCard key={reservation.reservationId} reservation={reservation} />
            {index !== reservations.length - 1 && (
              <ODHSpace w={16} />
            )}
          </>
        ))}
      </CardsWrapper>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const CardsWrapper = styled.div`
  display: flex;
  flex-direction: row;
`
