import React from 'react'
import styled from 'styled-components'
import { NumberParam, useQueryParam, withDefault } from 'use-query-params'
import { GQLMEDICATION_DISPENSING_STATUS, GQLRESERVATION_STATUS } from '@mdpp/common/lib/@types/server.schema'
import {
  DoctorReservationStatus,
  IReservationToolbarValue,
  ReservationToolBar,
} from '../../../doctor/reservation/ReservationToolBar'
import { SiteUrls } from '../../../SiteUrls'
import { ReservationListableTable } from './ReservationListableTable'
import { useReservationListDataLoader } from './ReservationListDataLoader'
import { Page, PageContainer, PageContent, PageHeader, PageTitle } from '../../page/Page'
import { ReservationListableOfPharmacyTable } from '../../../pharmacy/reservation/list/ReservationListableOfPharmacyTable'
import {
  IPharmacyReservationToolbarValue,
  PharmacyReservationStatus,
} from '../../../pharmacy/reservation/PharmacyReservationToolBar'
import { useReservationPharmacyListDataLoader } from '../../../pharmacy/reservation/list/ReservationPharmacyListDataLoader'

export enum ReservationListStyle {
  Doctor,
  Manager,
  Admin,
  Pharmacy,
}

interface ICommonReservationListPageProps {
  listStyle: ReservationListStyle
}

const LINK_BY_STYLE = {
  [ReservationListStyle.Doctor]: SiteUrls.Doctor.Reservation.Detail,
  [ReservationListStyle.Admin]: SiteUrls.Admin.Reservation.Detail,
  [ReservationListStyle.Manager]: SiteUrls.Manager.Reservation.Detail,
  [ReservationListStyle.Pharmacy]: SiteUrls.Pharmacy.Reservation.Detail,
}

export const CommonReservationListPage: React.FC<ICommonReservationListPageProps> = props => {
  const { listStyle } = props
  const [finished] = useQueryParam('finished', withDefault(NumberParam, null))
  const [rangeSelected, setRangeSelected] = React.useState(false)
  // 의사
  const [reservationValue, setReservationValue] = React.useState<IReservationToolbarValue>({
    status: finished ? DoctorReservationStatus.FINISHED : DoctorReservationStatus.ALL,
    startDate: '',
    endDate: '',
  })
  const [status, setStatus] = React.useState<GQLRESERVATION_STATUS[]>([])
  // 약국
  const [reservationPharmactistValue, setReservationPharmactistValue] = React.useState<
    IPharmacyReservationToolbarValue
  >({
    status: finished ? PharmacyReservationStatus.FINISHED : PharmacyReservationStatus.ALL,
    startDate: '',
    endDate: '',
  })
  const [pharmactistStatus, setPharmactistStatus] = React.useState<GQLMEDICATION_DISPENSING_STATUS[]>([])

  // 의사
  React.useEffect(() => {
    setStatus(() => {
      switch (reservationValue.status) {
        case DoctorReservationStatus.PENDING:
          return [GQLRESERVATION_STATUS.PENDING]
        case DoctorReservationStatus.WAITING:
          return [GQLRESERVATION_STATUS.WAITING, GQLRESERVATION_STATUS.RECEIPTED, GQLRESERVATION_STATUS.READY]
        case DoctorReservationStatus.IN_PROGRESS:
          return [GQLRESERVATION_STATUS.PRESCRIPTION, GQLRESERVATION_STATUS.IN_PROGRESS]
        case DoctorReservationStatus.FINISHED:
          return [GQLRESERVATION_STATUS.FINISHED]
        case DoctorReservationStatus.CANCELED:
          return [
            GQLRESERVATION_STATUS.PATIENT_NO_SHOW,
            GQLRESERVATION_STATUS.DOCTOR_NO_SHOW,
            GQLRESERVATION_STATUS.DOCTOR_CANCEL,
            GQLRESERVATION_STATUS.PATIENT_CANCEL,
          ]
        case DoctorReservationStatus.ALL:
        default:
          return []
      }
    })
  }, [reservationValue.status])

  const dataLoader = useReservationListDataLoader(
    listStyle === ReservationListStyle.Doctor,
    undefined,
    status,
    reservationValue.startDate,
    reservationValue.endDate
  )

  const onChangeReservationValue = React.useCallback(
    (value: IReservationToolbarValue) => {
      setReservationValue(() => ({
        ...value,
      }))

      if (value.startDate && value.startDate === value.endDate) {
        setRangeSelected(true)
      } else {
        setRangeSelected(false)
      }
    },
    [setReservationValue]
  )

  // 약국
  React.useEffect(() => {
    setPharmactistStatus(() => {
      switch (reservationPharmactistValue.status) {
        case PharmacyReservationStatus.REQUESTED: // 조제 신청
          return [GQLMEDICATION_DISPENSING_STATUS.REQUESTED]
        case PharmacyReservationStatus.ACCEPTED: // 조제 승인
          return [GQLMEDICATION_DISPENSING_STATUS.ACCEPTED]
        case PharmacyReservationStatus.REFUSED: // 조제 거절
          return [GQLMEDICATION_DISPENSING_STATUS.REFUSED]
        case PharmacyReservationStatus.PAIED: // 결제 완료
          return [GQLMEDICATION_DISPENSING_STATUS.PAIED, GQLMEDICATION_DISPENSING_STATUS.FINISHED]
        case PharmacyReservationStatus.NOT_PAIED: // 결제 실패
          return [GQLMEDICATION_DISPENSING_STATUS.NOT_PAIED]
        case PharmacyReservationStatus.FINISHED: // 발송 완료 (결제 완료)
          return [GQLMEDICATION_DISPENSING_STATUS.FINISHED, GQLMEDICATION_DISPENSING_STATUS.PAIED]
        case PharmacyReservationStatus.FAX_FINISHED: // FAX 발송 완료
          return [GQLMEDICATION_DISPENSING_STATUS.FAX_FINISHED]
        case PharmacyReservationStatus.CANCELED: // 조제 취소
          return [GQLMEDICATION_DISPENSING_STATUS.CANCELED]
        case PharmacyReservationStatus.ALL:
        default:
          return []
      }
    })
  }, [reservationPharmactistValue.status])

  const dataLoaderPharmactist = useReservationPharmacyListDataLoader(
    listStyle === ReservationListStyle.Pharmacy,
    undefined,
    pharmactistStatus,
    reservationPharmactistValue.startDate,
    reservationPharmactistValue.endDate
  )

  const onChangeReservationPharmactistValue = React.useCallback(
    (value: IPharmacyReservationToolbarValue) => {
      setReservationPharmactistValue(() => ({
        ...value,
      }))

      if (value.startDate && value.startDate === value.endDate) {
        setRangeSelected(true)
      } else {
        setRangeSelected(false)
      }
    },
    [setReservationPharmactistValue]
  )

  // @ts-ignore
  const linkToDetail: any = LINK_BY_STYLE[listStyle]

  const isAdmin = listStyle === ReservationListStyle.Admin
  const isManager = listStyle === ReservationListStyle.Manager
  const isPharmacy = listStyle === ReservationListStyle.Pharmacy

  if (isAdmin || isManager) {
    return (
      <PageContainer>
        <Page>
          <PageHeader>
            <PageTitle>진료{isAdmin ? '' : '관리'}</PageTitle>
          </PageHeader>
          <PageContent>
            <ReservationListableTable
              dataLoader={dataLoader}
              linkToDetail={linkToDetail}
              // showSearch
              fromAdmin={isAdmin}
              fromHospitalManager={isManager}
              toolbox
              value={reservationValue}
              onChange={onChangeReservationValue}
            />
          </PageContent>
        </Page>
      </PageContainer>
    )
  }

  if (isPharmacy) {
    return (
      <PageContainer>
        <Page>
          <PageHeader>
            <PageTitle>조제 현황</PageTitle>
          </PageHeader>
          <PageContent>
            <ReservationListableOfPharmacyTable
              dataLoader={dataLoaderPharmactist}
              linkToDetail={linkToDetail}
              // showSearch
              fromAdmin={false}
              fromHospitalManager={false}
              toolbox
              value={reservationPharmactistValue}
              onChange={onChangeReservationPharmactistValue}
            />
          </PageContent>
        </Page>
      </PageContainer>
    )
  }

  return (
    <>
      <PageContainer>
        <Page>
          <PageHeader>
            <PageTitle>진료</PageTitle>
          </PageHeader>
          <PageContent>
            <ReservationListableTable
              dataLoader={dataLoader}
              linkToDetail={linkToDetail}
              // showSearch
              fromAdmin={false}
              fromHospitalManager={false}
              toolbox
              value={reservationValue}
              onChange={onChangeReservationValue}
            />
          </PageContent>
        </Page>
      </PageContainer>
    </>
  )
}
