import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { SiteUrls } from '../SiteUrls'
import { PharmacyLoginPage } from './PharmacyLoginPage'
import { ChangeEmail } from './signup/ChangeEmail'
import { CompletePharmacySignUp } from './signup/CompletePharmacySignUp'
import { EmailAuthentication } from './signup/EmailAuthentication'
import { PharmacySignUpInfoPage } from './signup/PharmacySignUpInfoPage'
import { PharmacySignUpPage } from './signup/PharmacySignUpPage'
import { ODCommonMainLayoutWithoutSideBar } from '@mdpp/od-react-belt'
import { ChangePasswordContainer } from '../common/ChangePasswordContainer'
import { RESET_ACCOUNT_TYPE } from '@mdpp/common'
import { SSEProvider } from 'react-hooks-sse'
import { PharmacyMainLayout } from './PharmacyMainLayout'
import { AppOptions, LOCAL_STORAGE_KEY_TOKEN } from '../AppOptions'
import { PharmacyAuthorized } from './signup/PharmacyAuthorized'
import { PharmacyLogoutPage } from './PharmacyLogoutPage'
import { PharmacyHeader } from './gnb/PharmacyHeader'

export const PharmacyRoot: React.FC = () => {
  const sseEndpoint = `${AppOptions.SERVER_ADDRESS}/events?token=${localStorage.getItem(
    LOCAL_STORAGE_KEY_TOKEN
  )}&clientType=Pharmacy`
  return (
    <Switch>
      <Route path={SiteUrls.Pharmacy.Login} component={PharmacyLoginPage} />
      <Route path={SiteUrls.Pharmacy.Logout} component={PharmacyLogoutPage} />

      <Route path={SiteUrls.Pharmacy.SignUp} component={PharmacySignUpPage} />
      <Route path={SiteUrls.Pharmacy.SignUpInfo} component={PharmacySignUpInfoPage} />
      <Route path={SiteUrls.Pharmacy.EmailAuthentication} component={EmailAuthentication} />

      <Route path={SiteUrls.Pharmacy.ChangeEmail} component={ChangeEmail} />
      <Route path={SiteUrls.Pharmacy.CompleteSignUp} component={CompletePharmacySignUp} />

      <Route
        path={SiteUrls.Pharmacy.ChangePassword}
        render={(props: any) => (
          <PharmacyAuthorized>
            <ODCommonMainLayoutWithoutSideBar renderHeader={() => <PharmacyHeader />}>
              <ChangePasswordContainer accountType={RESET_ACCOUNT_TYPE.PHARMACIST} />
            </ODCommonMainLayoutWithoutSideBar>
          </PharmacyAuthorized>
        )}
      />

      <Route
        path={SiteUrls.Pharmacy.Root}
        render={() => {
          return (
            <PharmacyAuthorized>
              <SSEProvider endpoint={sseEndpoint}>
                <PharmacyMainLayout />
              </SSEProvider>
            </PharmacyAuthorized>
          )
        }}
      />

      <Route
        path={SiteUrls.Pharmacy.Reservation.Main}
        exact
        render={props => {
          return <PharmacyMainLayout />
        }}
      />

      <Redirect to={SiteUrls.Pharmacy.Reservation.Main} />
    </Switch>
  )
}
