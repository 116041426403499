import { ODCommonMainLayout, SingleQueryWrapper } from '@mdpp/od-react-belt'
import * as React from 'react'
import { SiteUrls } from '../SiteUrls'
import { COLORS } from '../styles/colors'
import { ManagerHeader } from './gnb/ManagerHeader'
import { ManagerRoutes } from './ManagerRoutes'
import { ManagerSiteNavs } from './ManagerSiteNavs'
import { useCommonAPIs } from "../common/hooks/useCommonAPIs";
import { GQLPopupInfo } from '@mdpp/common/lib/@types/server.schema';
import { useManagerAppContext } from "./context/ManagerAppContext";
import { LocalStorageWithExpiry } from "../utils/LocalStorageWithExpiry";
import moment from "moment/moment";
import { useEffect } from "react";
import { NoticePopupModal } from "../common/notice/modal/NoticePopupModal";
import { AppOptions } from "../AppOptions";

export const ManagerMainLayout: React.FC = () => {
  const { listPopupInfo } = useCommonAPIs()
  const [listPopup, setListPopup] = React.useState<GQLPopupInfo[]>([])
  const [showNoticePopup, setShowNoticePopup] = React.useState<boolean>(true)
  const [linkTo, setLinkTo] = React.useState<string>('')
  const {
    state: { profile },
  } = useManagerAppContext()
  
  const cancelNoticePopup = React.useCallback(async (onetime : boolean) => {
    if (onetime || listPopup[0].onetime) {
      await LocalStorageWithExpiry.save('notice' + '_manager'+ profile?.hospitalManagerId + listPopup[0]?.notice?.noticeId, '1',
        moment((listPopup[0].postingEnd)).add(10, 'second'))
    }
    setListPopup(listPopup.slice(1))
  }, [listPopup])
  
  useEffect(() => {
    if (listPopup[0]?.notice?.noticeId)
      setLinkTo(AppOptions.WS_SERVER_ADDRESS + '/manager'
        + '/notice/' + listPopup[0]?.notice?.noticeId )
    
    listPopup && listPopup.length > 0 ?
      setShowNoticePopup(true) : setShowNoticePopup(false)
  }, [listPopup])
  
  return (
    <>
      <ODCommonMainLayout
        renderHeader={() => <ManagerHeader />}
        defaultRoute={SiteUrls.Manager.Dashboard.Main}
        navs={ManagerSiteNavs}
        routes={ManagerRoutes}
        style={{
          padding: 0,
          marginLeft: 0,
          marginTop: 0,
          marginBottom: 0,
          marginRight: 0,
          background: COLORS.GRAY100,
        }}
      />
      <SingleQueryWrapper deps={[listPopupInfo]} getter={async() => {
        if (window.location.href.match('notice'))
          return []
        
        // @ts-ignore
        const listablePopupInfo = await listPopupInfo({showOnHospitalManager: 1})
        const list = listablePopupInfo.list
        // 이미 확인한 공지인지 체크
        for (let i = list.length - 1; i >= 0; i--) {
          const data = await LocalStorageWithExpiry.load('notice' + '_manager'+ profile?.hospitalManagerId + list[i].notice?.noticeId)
          if (data) {
            list.splice(i, 1)
          }
        }
        setListPopup(list)
        return listablePopupInfo.list
      }}>
        {({ data }) => {
          return (
            <NoticePopupModal isOpen={showNoticePopup}
                              showDetailCallback={ async() => {
                                console.log('show Detail')
                              }}
                              onClose={cancelNoticePopup}
                              popupInfo={listPopup[0]}
                              isManager={true}
                              linkTo={linkTo}
            />
          )
        }}
      </SingleQueryWrapper>
    </>
  )
}
