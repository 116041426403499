// @ts-ignore
import { AppSidebarToggler } from '@coreui/react'
import { ODHSpace } from '@mdpp/od-react-belt'
import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { SiteUrls } from '../../SiteUrls'
import { AdminDropdown } from './AdminDropdown' 
import LogoAdmin from '../../assets/common/bi-mdpp-admin-small.png'

interface IEvixHeaderProps {}

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0 20px;

  @media only screen and (max-width: 360px) {
    padding: 0 10px;
  }
`

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
`


export const AdminHeader: React.FC<IEvixHeaderProps> = props => {
  return (
    <Wrapper>
      <div style={{ display: 'flex'}}>
        <AppSidebarToggler className="d-lg-none" display="md" mobile />
        <TitleWrapper>
          <Link to={SiteUrls.Admin.Root} style={{ textDecoration: 'none' }}>
            <img src={LogoAdmin} alt="Medi PangPang Logo" />
          </Link>
        </TitleWrapper>
      </div>

      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <AdminDropdown />
      </div>
    </Wrapper>
  )
}
