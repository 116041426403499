import { BlockingLoadBox, ODLoading } from '@mdpp/od-react-belt'
import React from 'react'
import { Redirect } from 'react-router-dom'
import {
  GQLNOTIFICATION_TYPE,
  GQLReservation,
  GQLRESERVATION_STATUS,
  GQLUpdateReservationByDoctorInput
} from '@mdpp/common/lib/@types/server.schema'
import { EvixErrorBox } from '../../../common/EvixErrorBox'
import { useCommonAPIs } from '../../../common/hooks/useCommonAPIs'
import { useMutationAPI } from '../../../common/hooks/useMutationAPI'
import { SiteUrls } from '../../../SiteUrls'
import { WebUtils } from '../../../utils/webUtils'
import { useDoctorAPIs, useReservation } from '../../context/useDoctorAPIs'
import { FinishedMedicalPage } from '../detail/FinishedMedicalPage'
import { RoomMainLayout } from './RoomMainLayout'

interface IRoomMainLayoutWrapperProps {
  reservationId: number
}

function isReservationAvailableForEnter(reservation: GQLReservation) {
  return [GQLRESERVATION_STATUS.RECEIPTED, GQLRESERVATION_STATUS.IN_PROGRESS, GQLRESERVATION_STATUS.READY, GQLRESERVATION_STATUS.PRESCRIPTION].includes(reservation.status)
}

export const RoomMainLayoutWrapper: React.FC<IRoomMainLayoutWrapperProps> = props => {
  const { reservationId } = props
  const { reservation, setReservation, error, refresh } = useReservation(reservationId)
  const { updateReservation, enterReservationRoom, sendRequestNotificationToPatient, setDoctorReady, startTreatment, setDoctorReceipt, setPrescriptionState } = useDoctorAPIs()
  const { startReservation } = useCommonAPIs()

  const updateReservationWrapped = useMutationAPI(updateReservation, {
    onSuccess: '업데이트하였습니다.',
  })

  const handleUpdateField = React.useCallback(
    async (fieldName: keyof GQLUpdateReservationByDoctorInput, value: string | number) => {
      const updated = await updateReservationWrapped({ id: reservation!.reservationId, [fieldName]: value })
      setReservation(updated)
    },
    [reservation, updateReservationWrapped, setReservation]
  )

  const enterReservationRoomIfRequired = React.useCallback(
    async (r: GQLReservation) => {
      if (!r.agoraToken) {
        const updated = await enterReservationRoom({ id: r.reservationId })
        setReservation(updated)
      }
    },
    [enterReservationRoom, setReservation]
  )

  const handleStartTreatment = React.useCallback(async () => {
    try {
      const reservation = await startTreatment({ id: reservationId })
      setReservation(reservation)
    } catch (ex) {
      WebUtils.showError(ex)
    }
  }, [setReservation, startTreatment, reservationId])

  const handleDoctorOut = React.useCallback(async () => {
    try {
      const reservation = await setDoctorReceipt({ id: reservationId })
      setReservation(reservation)
    } catch (ex) {
      WebUtils.showError(ex)
    }
  }, [setReservation, setDoctorReceipt, reservationId])

  const handleRequestPatientEnter = React.useCallback(async (reservation) => {
    try {
      await sendRequestNotificationToPatient({ reservationId: reservationId,
        notificationType: GQLNOTIFICATION_TYPE.REQUEST_PATIENT_ENTER })
      if (reservation.status !== GQLRESERVATION_STATUS.READY) {
        const r = await setDoctorReady({ id: reservationId })
        setReservation(r)
      }
    } catch (ex) {
      WebUtils.showError(ex)
    }
  }, [setReservation, startReservation, reservationId])

  const handleExitRoom = React.useCallback(async () => {
    try {
      const r = await setPrescriptionState({id: reservationId})
      setReservation(r)
    } catch (e) {
      WebUtils.showError(e)
    }
  }, [setReservation, setPrescriptionState, reservationId])

  React.useEffect(() => {
    if (reservation && !reservation.agoraToken && isReservationAvailableForEnter(reservation)) {
      // noinspection JSIgnoredPromiseFromCall
      enterReservationRoomIfRequired(reservation!)
    }
  }, [enterReservationRoomIfRequired, reservation])

  if (error) {
    return <EvixErrorBox message={error} />
  }

  if (!reservation) {
    return <ODLoading />
  }

  if (!isReservationAvailableForEnter(reservation)) {
    if (reservation.status === GQLRESERVATION_STATUS.PENDING) {
      WebUtils.showError('아직 진료실에 입장할 수 있는 상태가 아닙니다.')
      return <Redirect to={SiteUrls.Doctor.Reservation.Detail(reservationId)} />
    }

    return <FinishedMedicalPage reservation={reservation} onUpdateField={handleUpdateField} />
  }

  if (!reservation.agoraToken) {
    return <BlockingLoadBox show />
    // WebUtils.showError('진료실 입장 화상채팅방을 찾을 수 없습니다.')
    // return <Redirect to={SiteUrls.Doctor.Reservation.Detail(reservationId)} />
  }

  return (
    <RoomMainLayout
      reservation={reservation}
      onUpdateField={handleUpdateField}
      onStartReservation={handleStartTreatment}
      onRequestPatientEnter={handleRequestPatientEnter}
      onDoctorOut={handleDoctorOut}
      onExitRoom={handleExitRoom}
      refresh={refresh}
    />
  )
}
