import React from 'react'
import { AdminDoctorProfileDiagnosisPage } from '../../../admin/doctor/detail/AdminDoctorProfileDiagnosisPage'

interface IManagerDoctorProfileDiagnosisPageProps {
  doctorId: number
}

export const ManagerDoctorProfileDiagnosisPage: React.FC<IManagerDoctorProfileDiagnosisPageProps> = props => {
  const { doctorId } = props
  return <AdminDoctorProfileDiagnosisPage doctorId={doctorId} />
}
