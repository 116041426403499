import {
  ODIcon,
  ODIcons,
  ODListablePaginatedTable2,
  ODListablePagination,
  ODListableSearchBox,
  ODWebUtils,
  useODListableContext,
} from '@mdpp/od-react-belt'
import { range } from 'lodash'
import React from 'react'
import { Card, CardBody } from 'reactstrap'
import styled from 'styled-components'
import { GQLListableReservationInput, GQLReservation } from '@mdpp/common/lib/@types/server.schema'
import { WebUtils } from '../../../utils/webUtils'
import { IReservationListableOption, ReservationPrimaryKey } from '../../reservation/ReservationCommon'
import { useReservationReviewListDataLoader } from './ReservationReviewListDataLoader'
import { EvixCard } from '../../card/EvixCard'

interface IReservationListableTableProps {
  dataLoaderOptions?: Partial<GQLListableReservationInput>
  noSearch?: boolean
  simpleColumns?: boolean // 단일 의사 디자인 (병원이름, 의사이름 없음)
}

export const ReviewListableTable: React.FC<IReservationListableTableProps> = props => {
  const { dataLoaderOptions, noSearch = false, simpleColumns = false } = props
  const { Provider, Context } = useODListableContext<GQLReservation, IReservationListableOption>()
  const dataLoader = useReservationReviewListDataLoader(dataLoaderOptions ?? {})
  const toolbarStyle = { display: 'flex', justifyContent: 'space-between', marginBottom: 15 }
  const searchBoxStyle = { width: '100%' }
  const wrapperStyle = { display: 'flex', alignItems: 'center', justifyContent: 'center' , marginTop: 24}


  return (
    <Provider
      dataLoader={dataLoader}
      keyExtractor={v => v.reservationId.toString()}
      pageSize={10}
      // refreshToken={token.toString()}
      onDataLoaderError={WebUtils.showError}
      searchOnLoad
    >
     {noSearch && (
        <SearchWrap style={toolbarStyle}>
          <ODListableSearchBox
            listableContext={Context}
            placeholder="병원, 의사이름으로 검색"
            style={searchBoxStyle}
          />
        </SearchWrap>   
      )}

      <EvixCard noPadding>
        <ODListablePaginatedTable2
          numColumns={7}
          listableContext={Context}
          renderHeader={() => (
            <tr>
              <th style={{ textAlign: 'center'}}>등록일</th>
              {!simpleColumns && <th style={{ textAlign: 'center'}}>병원</th>}
              {!simpleColumns && <th style={{ textAlign: 'center'}}>의사이름</th>}
              <th style={{ textAlign: 'center'}}>환자이름</th>
              <th style={{ textAlign: 'center'}}>진료 평가</th>
            </tr>
          )}
          renderRow={(value: GQLReservation, context) => (
            <tr key={value[ReservationPrimaryKey]} style={{borderBottom: '1px solid #D1D5DA'}}>
              <td align='center'>{WebUtils.formatDateTime(value.ratedAt)}</td>
              {!simpleColumns && <td align='center'>{value.hospital.name ?? '-'}</td>}
              {!simpleColumns && <td align='center'>{value.doctor.name ?? '-'}</td>}
              {/*<td>{WebUtils.formatPatientNameForReview(value.patient.name ?? '-')}</td>*/}
              <td align='center'>{value.patient.name ?? '-'}</td>
              <td align='center'>
                {range(0, 5).map(index => {
                  const isBlack = (value.rate ?? 0) > index
                  if (isBlack) {
                    return (
                      <BlackIcon key={index}>
                        <ODIcon icon={ODIcons.MaterialStar} />
                      </BlackIcon>
                    )
                  }
                  return (
                    <GrayIcon key={index}>
                      <ODIcon icon={ODIcons.MaterialStar} />
                    </GrayIcon>
                  )
                })}
              </td>
            </tr>
          )}
          eventParentContext={{}}
        />
      </EvixCard>
      <ODListablePagination hideIfSinglePage={false} listableContext={Context} wrapperStyle={wrapperStyle} />
    </Provider>
  )
}

const SearchWrap = styled.div`
  display: flex;
`

const BlackIcon = styled.span`
  font-size: 14px;
  color: var(--mpPrimary400);
`

const GrayIcon = styled.span`
  font-size: 14px;
  color: var(--gray300);
`
